import {ITooltipHostProps, TooltipHost} from '@fluentui/react';
import React, {useContext} from 'react';
import {
	TooltipTranslationContext,
	TooltipTranslationContextValue,
	ValidTooltipTranslationContextValue,
} from './contexts/TooltipTranslationContext';
import {
	TooltipStatusContextValue,
	useTooltipStatusContext,
} from './contexts/TooltipStatusContext';
import {throwErrorInDevAndLogInProd} from './tooltipErrorUtils';

export interface LocalizedTooltipHostProps extends ITooltipHostProps {
	/**
	 * Note: You can also wrap this component with
	 * ProviderThatEnablesGettingTooltipsFromContext to enable this option
	 * automatically.
	 *
	 * * Implementation notes
	 *
	 * This key is necessary in addition to translationKey because this component
	 * wraps many form inputs. Form inputs might automatically pass their names as
	 * the "translationKey," but we do not always need to display a tooltip. Since
	 * we throw errors whenever a translation is missing, I added this property to
	 * control whether we actually try to get the translation.
	 */
	shouldGetTextFromContext?: boolean;
	/**
	 * The key, which should be a non-empty string, to use with the provider's
	 * translation function to get the tooltips' text. Alternatively, you can wrap
	 * this component with a provider for the Tooltip Translation Context to get
	 * this key automatically.
	 *
	 * * Note
	 *
	 * Providing this key does not automatically show the tooltip's text. See
	 * shouldGetTextFromContext for more info.
	 */
	translationKey?: string;
	/**
	 * Overwrites the tooltip's content. Please note that this might not
	 * overwrite the tooltip's content if it is undefined and
	 * shouldGetTextFromContext is enabled.
	 */
	content?: ITooltipHostProps['content'];
}

export interface TooltipOptionsForComponentOrColumn<
	Props = LocalizedTooltipHostProps,
> {
	tooltipHostProps?: Props;
}

/**
 * This is the component that shows tooltips throughout different parts of the
 * application. For example, this component is used to show tooltips in form
 * fields' labels and Entity Lists' columns. You can set the tooltip's text
 * using the component's props. Furthermore, this component can automatically
 * get the tooltip's text from a translation function. See the props for more
 * info.
 *
 * * Implementing tooltips support
 *
 * If you need to add tooltips support to a component, you probably should not
 * use this component directly. Instead, you will likely need to use one of the
 * other components or services that are in this feature's folder.
 *
 * * Less important notes
 *
 * Note that this component will only throw certain errors in development. It
 * will still log all errors to the console, though.
 */
export default function LocalizedTooltipHost({
	shouldGetTextFromContext: propToGetTextFromContext,
	content,
	translationKey,
	...other
}: LocalizedTooltipHostProps) {
	const tooltipsContext: TooltipTranslationContextValue = useContext(
		TooltipTranslationContext,
	);

	const statusContext: TooltipStatusContextValue = useTooltipStatusContext();

	const handleInvalidFieldName = (): null => {
		const error = new Error(
			`"${translationKey}" is not a valid translation name.`,
		);
		throwErrorInDevAndLogInProd(error);
		return null;
	};

	type PossibleTranslation = string | null;

	const getPossibleTextFromContext = (
		context: ValidTooltipTranslationContextValue,
	): PossibleTranslation => {
		if (translationKey) return context.getTooltipText(translationKey);
		return handleInvalidFieldName();
	};

	const handleMissingTranslationContext = (): null => {
		const error = new Error(
			'Tooltips context is missing. Please add a Tooltips Translation Provider. See docs for more info.',
		);
		throwErrorInDevAndLogInProd(error);
		return null;
	};

	const validateContextExistsAndGetPossibleText = (): PossibleTranslation => {
		if (tooltipsContext) return getPossibleTextFromContext(tooltipsContext);
		return handleMissingTranslationContext();
	};

	type Content = ITooltipHostProps['content'];

	const getContentFromContext = (): Content => {
		return validateContextExistsAndGetPossibleText() ?? undefined;
	};

	const getOptionToGetTextFromContent = (): boolean => {
		/**
		 * This option should always overwrite the context.
		 */
		if (typeof propToGetTextFromContext !== 'undefined')
			return propToGetTextFromContext;
		return Boolean(statusContext?.shouldGetTooltipTextFromContext);
	};

	const getIfShouldGetTextFromContext = (): boolean => {
		/**
		 * We do this because we want the content to overwrite anything else. Some
		 * components automatically provide a translation provider. If we need to
		 * use another one for a tooltip, we need a way of overwriting the
		 * tooltips's text. The "content" property allows us to do this.
		 *
		 * We check if the content is not undefined instead of checking if it's
		 * truthy. By doing so, we allow empty strings. Setting a tooltips' text to
		 * an empty string in a translation file is something a developer might do
		 * to disable a translation since that action is easier than disabling it
		 * through the code, so we should support empty strings. We shouldn't throw
		 * an error because it's likely that it would have been done intentionally.
		 */
		if (typeof content === 'undefined') return getOptionToGetTextFromContent();
		return false;
	};

	/**
	 * Note that it is normal for this component not to have any text. Form
	 * fields with tooltips sometimes do not have any tooltip text by default.
	 */
	const getPossibleTooltipText = (): Content => {
		const getFromContext: boolean = getIfShouldGetTextFromContext();
		return getFromContext ? getContentFromContext() : content;
	};

	return <TooltipHost content={getPossibleTooltipText()} {...other} />;
}
