import {
	IScrollablePaneProps,
	IStackStyles,
	IStackTokens,
	ScrollablePane,
	Stack,
} from '@fluentui/react';
import {AppCommandBar, NotificationBar} from 'components';
import * as React from 'react';
import {Outlet} from 'react-router-dom';
import {Navigation} from './Navigation';
import {SideNavigation} from './SideNavigation';
import {SideNavigationProvider} from './SideNavigation/SideNavigationProvider';

const containerStackTokens: IStackTokens = {childrenGap: 0};

const stackStyles: IStackStyles = {
	root: {
		marginTop: '0',
		overflow: 'hidden',
	},
};

const stackItemStyles: IStackStyles = {
	root: {
		height: '100%',
		position: 'relative',
	},
};

const stackItemStylesMain: IStackStyles = {
	root: {
		width: 'calc(100% - 240px)',
	},
};

const getHeight = (): string => {
	const commandBarHeight = 45;
	const navHeight = 70;
	return `${commandBarHeight + navHeight}px`;
};

export const layoutTopHeight: string = getHeight();
export const idOfScrollablePane = 'main-scrollable-pane';

interface Props {
	scrollablePaneProps?: IScrollablePaneProps;
	/**
	 * If this is nullable, the layout will try displaying the outlet instead.
	 */
	children?: React.ReactNode;
}

export const Layout: React.FC<Props> = ({
	scrollablePaneProps,
	children,
}: Props) => {
	/**
	 * If we ever remove the notification bar, update the comment in
	 * NotificationBar because it mentions this component's notification bar.
	 */
	return (
		<Stack tokens={containerStackTokens}>
			<Navigation />
			<Stack horizontal styles={stackStyles}>
				<SideNavigationProvider>
					<SideNavigation />
					<Stack styles={stackItemStylesMain} grow={1}>
						<AppCommandBar />
						<NotificationBar timeout={1000} />
						<Stack.Item styles={stackItemStyles}>
							<ScrollablePane {...scrollablePaneProps} id={idOfScrollablePane}>
								{children ?? <Outlet />}
							</ScrollablePane>
						</Stack.Item>
					</Stack>
				</SideNavigationProvider>
			</Stack>
		</Stack>
	);
};

export default Layout;
