import {GetActivityItemsQuery} from './hooks/activityItems.generated';

export class ActivityListHelper {
	public static getRegulationLink = (regulation: Regulation): string => {
		return `/regulations/${regulation.id}`;
	};

	public static getRegulatoryDocumentLink = (
		regulatoryDocument: RegulatoryDocument,
	): string => {
		return `/regulations/${regulatoryDocument.regulation?.id}/${regulatoryDocument.id}/paragraphs`;
	};

	/** Every regulation shows it's regulationNumber as title */
	public static getRegulationTitle = (regulation: Regulation): string => {
		return regulation.regulationNumber;
	};

	/** Every regulatoryDocument uses this title-pattern: NAME [REGULATION.regulationNumber] */
	public static getRegulatoryDocumentTitle = (
		regulatoryDocument: RegulatoryDocument,
	): string => {
		return `${regulatoryDocument.name} [${regulatoryDocument.regulation?.regulationNumber}]`;
	};
}

type Regulation = GetActivityItemsQuery['userCreatedRegulations'][number];

type RegulatoryDocumentNodes = NonNullable<
	GetActivityItemsQuery['regulatoryDocuments']
>['nodes'];
type RegulatoryDocument = NonNullable<RegulatoryDocumentNodes>[number];
