import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetReferencePickerRegulationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetReferencePickerRegulationsQuery = { __typename?: 'Query', regulations: Array<{ __typename?: 'Regulation', id: string, regulationNumber: string, name: string }> };

export type GetReferencePickerRequirementsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetReferencePickerRequirementsQuery = { __typename?: 'Query', requirements: Array<{ __typename?: 'Requirement', id: string, name: string }> };

export type GetReferencePickerRegulatoryDocumentsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetReferencePickerRegulatoryDocumentsQuery = { __typename?: 'Query', regulatoryDocuments?: { __typename?: 'RegulatoryDocumentsConnection', edges?: Array<{ __typename?: 'RegulatoryDocumentsEdge', node: { __typename?: 'RegulatoryDocument', id: string, name: string } }> | null } | null };


export const GetReferencePickerRegulationsDocument = gql`
    query GetReferencePickerRegulations {
  regulations {
    id
    regulationNumber
    name
  }
}
    `;

/**
 * __useGetReferencePickerRegulationsQuery__
 *
 * To run a query within a React component, call `useGetReferencePickerRegulationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReferencePickerRegulationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferencePickerRegulationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReferencePickerRegulationsQuery(baseOptions?: Apollo.QueryHookOptions<GetReferencePickerRegulationsQuery, GetReferencePickerRegulationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReferencePickerRegulationsQuery, GetReferencePickerRegulationsQueryVariables>(GetReferencePickerRegulationsDocument, options);
      }
export function useGetReferencePickerRegulationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReferencePickerRegulationsQuery, GetReferencePickerRegulationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReferencePickerRegulationsQuery, GetReferencePickerRegulationsQueryVariables>(GetReferencePickerRegulationsDocument, options);
        }
export type GetReferencePickerRegulationsQueryHookResult = ReturnType<typeof useGetReferencePickerRegulationsQuery>;
export type GetReferencePickerRegulationsLazyQueryHookResult = ReturnType<typeof useGetReferencePickerRegulationsLazyQuery>;
export type GetReferencePickerRegulationsQueryResult = Apollo.QueryResult<GetReferencePickerRegulationsQuery, GetReferencePickerRegulationsQueryVariables>;
export const GetReferencePickerRequirementsDocument = gql`
    query GetReferencePickerRequirements {
  requirements {
    id
    name
  }
}
    `;

/**
 * __useGetReferencePickerRequirementsQuery__
 *
 * To run a query within a React component, call `useGetReferencePickerRequirementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReferencePickerRequirementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferencePickerRequirementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReferencePickerRequirementsQuery(baseOptions?: Apollo.QueryHookOptions<GetReferencePickerRequirementsQuery, GetReferencePickerRequirementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReferencePickerRequirementsQuery, GetReferencePickerRequirementsQueryVariables>(GetReferencePickerRequirementsDocument, options);
      }
export function useGetReferencePickerRequirementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReferencePickerRequirementsQuery, GetReferencePickerRequirementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReferencePickerRequirementsQuery, GetReferencePickerRequirementsQueryVariables>(GetReferencePickerRequirementsDocument, options);
        }
export type GetReferencePickerRequirementsQueryHookResult = ReturnType<typeof useGetReferencePickerRequirementsQuery>;
export type GetReferencePickerRequirementsLazyQueryHookResult = ReturnType<typeof useGetReferencePickerRequirementsLazyQuery>;
export type GetReferencePickerRequirementsQueryResult = Apollo.QueryResult<GetReferencePickerRequirementsQuery, GetReferencePickerRequirementsQueryVariables>;
export const GetReferencePickerRegulatoryDocumentsDocument = gql`
    query GetReferencePickerRegulatoryDocuments {
  regulatoryDocuments {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetReferencePickerRegulatoryDocumentsQuery__
 *
 * To run a query within a React component, call `useGetReferencePickerRegulatoryDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReferencePickerRegulatoryDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferencePickerRegulatoryDocumentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReferencePickerRegulatoryDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<GetReferencePickerRegulatoryDocumentsQuery, GetReferencePickerRegulatoryDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReferencePickerRegulatoryDocumentsQuery, GetReferencePickerRegulatoryDocumentsQueryVariables>(GetReferencePickerRegulatoryDocumentsDocument, options);
      }
export function useGetReferencePickerRegulatoryDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReferencePickerRegulatoryDocumentsQuery, GetReferencePickerRegulatoryDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReferencePickerRegulatoryDocumentsQuery, GetReferencePickerRegulatoryDocumentsQueryVariables>(GetReferencePickerRegulatoryDocumentsDocument, options);
        }
export type GetReferencePickerRegulatoryDocumentsQueryHookResult = ReturnType<typeof useGetReferencePickerRegulatoryDocumentsQuery>;
export type GetReferencePickerRegulatoryDocumentsLazyQueryHookResult = ReturnType<typeof useGetReferencePickerRegulatoryDocumentsLazyQuery>;
export type GetReferencePickerRegulatoryDocumentsQueryResult = Apollo.QueryResult<GetReferencePickerRegulatoryDocumentsQuery, GetReferencePickerRegulatoryDocumentsQueryVariables>;