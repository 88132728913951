import {Editor, Node, NodeEntry} from 'slate';
import {ReactEditor} from 'slate-react';

class DocumentEditorService {
	private scrollNodeIntoView = (
		editor: Editor,
		[node]: NodeEntry<Node>,
	): void => {
		const element: HTMLElement = ReactEditor.toDOMNode(editor, node);
		element.scrollIntoView({behavior: 'smooth', block: 'nearest'});
	};

	public scrollNodeIntoViewIfPossible = (
		editor: Editor,
		nodeEntry: NodeEntry<Node> | null | void,
	): void => {
		if (!nodeEntry) return;
		this.scrollNodeIntoView(editor, nodeEntry);
	};
}

export const documentEditorService = new DocumentEditorService();
