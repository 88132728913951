import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMyPendingApprovalsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMyPendingApprovalsQuery = { __typename?: 'Query', myPendingApprovals: Array<{ __typename?: 'Requirement', name: string, id: string }> };


export const GetMyPendingApprovalsDocument = gql`
    query GetMyPendingApprovals {
  myPendingApprovals {
    name
    id
  }
}
    `;

/**
 * __useGetMyPendingApprovalsQuery__
 *
 * To run a query within a React component, call `useGetMyPendingApprovalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyPendingApprovalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyPendingApprovalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyPendingApprovalsQuery(baseOptions?: Apollo.QueryHookOptions<GetMyPendingApprovalsQuery, GetMyPendingApprovalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyPendingApprovalsQuery, GetMyPendingApprovalsQueryVariables>(GetMyPendingApprovalsDocument, options);
      }
export function useGetMyPendingApprovalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyPendingApprovalsQuery, GetMyPendingApprovalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyPendingApprovalsQuery, GetMyPendingApprovalsQueryVariables>(GetMyPendingApprovalsDocument, options);
        }
export type GetMyPendingApprovalsQueryHookResult = ReturnType<typeof useGetMyPendingApprovalsQuery>;
export type GetMyPendingApprovalsLazyQueryHookResult = ReturnType<typeof useGetMyPendingApprovalsLazyQuery>;
export type GetMyPendingApprovalsQueryResult = Apollo.QueryResult<GetMyPendingApprovalsQuery, GetMyPendingApprovalsQueryVariables>;