import React, {useMemo} from 'react';
import {
	BaselineBreadcrumbs,
	ExtraBreadcrumbFields,
	PropsOfBaselineBreadcrumbs,
} from '../baselines/BaselineBreadcrumbs';
import {
	NavigateToBaselineDetailsPage,
	useNavigateToBaselineDetailsPage,
} from '../BaselineDetailsPage/BaselineDetailsPage.utils';
import {BaselineComparisonPageParams} from './BaselineComparisonPage.types';

interface Props extends Pick<PropsOfBaselineBreadcrumbs, 'baselineName'> {
	params: BaselineComparisonPageParams;
}

export function PageBreadcrumbs(props: Props): JSX.Element {
	const {params} = props;
	const navToBaselineDetailsPageFromParams: NavigateToBaselineDetailsPage =
		useNavigateToBaselineDetailsPage(params);

	const pageBreadcrumbFields = useMemo((): ExtraBreadcrumbFields => {
		const navToDetailsPage = (): void => {
			navToBaselineDetailsPageFromParams(params.id);
		};

		return {onClick: navToDetailsPage};
	}, [navToBaselineDetailsPageFromParams, params]);

	return (
		<BaselineBreadcrumbs
			{...props}
			pageBreadcrumbFields={pageBreadcrumbFields}
		/>
	);
}
