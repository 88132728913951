import {EntityListColumn} from 'components';
import {useSubscriptionsTranslation} from './useSubscriptionsTranslation';
import {useCallback, useMemo} from 'react';
import {
	renderArrayField,
	renderDate,
	renderRichtext,
} from 'components/EntityList/ColumnRenderers';
import {renderKeywordsCell} from 'components/Keywords/Keywords';

type OptionalColumnFieldNames = 'minWidth' | 'maxWidth' | 'filterable';
type OptionalColumnFields = Partial<
	Pick<EntityListColumn, OptionalColumnFieldNames>
>;
type Column = Omit<EntityListColumn, OptionalColumnFieldNames> &
	OptionalColumnFields;

const createColumn = ({
	key,
	name,
	fieldName,
	filterable = false,
	minWidth = 150,
	maxWidth = 200,
	onRender,
	getFilterLabel,
	...other
}: Column): EntityListColumn => ({
	key,
	name,
	fieldName,
	filterable,
	minWidth,
	maxWidth,
	isResizable: true,
	onRender,
	getFilterLabel,
	...other,
});

export const useSubscriptionColumns = (): EntityListColumn[] => {
	const {t} = useSubscriptionsTranslation();

	const createCustomColumn = useCallback(
		({name, filterable = true, ...other}: Column): EntityListColumn => {
			return createColumn({name: t(name), filterable, ...other});
		},
		[t],
	);

	const createCustomColumnFromParams = useCallback(
		(
			key: string,
			name: string,
			fieldName: string,
			filterable: boolean,
			onRender?: EntityListColumn['onRender'],
			getFilterLabel?: any,
			sortable?: boolean,
			minWidth?: number,
			isMultiline?: boolean,
		): EntityListColumn =>
			createCustomColumn({
				key,
				name,
				fieldName,
				filterable,
				onRender,
				getFilterLabel,
				sortable,
				minWidth,
				isMultiline,
			}),
		[createCustomColumn],
	);

	return useMemo(
		() => [
			createCustomColumnFromParams(
				'column1',
				'Name',
				'name',
				false,
				undefined,
				undefined,
				true,
				250,
				true,
			),
			createCustomColumnFromParams(
				'column2',
				'Markets',
				'markets',
				true,
				renderArrayField(),
			),
			createCustomColumnFromParams(
				'column3',
				'Keywords',
				'keywords',
				true,
				renderKeywordsCell,
			),
			createCustomColumnFromParams(
				'column4',
				'StandardPlusPcmsClusters',
				'standardPlusPcmsClusters',
				true,
				renderArrayField(),
			),
			createCustomColumnFromParams(
				'column5',
				'CreatedOn',
				'createdAt',
				false,
				renderDate(),
				undefined,
				true,
			),
		],
		[createCustomColumnFromParams, t, createCustomColumn],
	);
};
