import {DialogInfo, useDialogState} from 'components/Dialogs';
import React from 'react';
import {RegulationForm, RegulationFormProps} from './RegulationsForm';
import {GetRegulationDocument} from '../hooks';
import {QueryResult} from '@apollo/client';
import {useRegulatoryDocumentsContext} from 'features/RegulatoryDocuments/context';

interface Props
	extends Pick<RegulationFormProps, 'mode' | 'setMode' | 'regulation'> {
	startPolling: QueryResult['startPolling'];
}

export default function RegulationDetailRegulationForm(props: Props) {
	const [dialogState, , hideDialog]: DialogInfo = useDialogState();
	const {allSelectedRegulatoryDocuments} = useRegulatoryDocumentsContext();

	/**
	 * We must not start polling if reg docs are selected because polling will
	 * reset the selection data once the app detects a change to the regulation.
	 */
	return (
		<RegulationForm
			dialogState={dialogState}
			hideDialog={hideDialog}
			queriesToRefetchAfterSaveOrDelete={[GetRegulationDocument]}
			shouldStartPolling={!allSelectedRegulatoryDocuments?.length}
			{...props}
		/>
	);
}
