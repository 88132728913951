import React, {useState} from 'react';

export type ParagraphsListContextProps = {
	showPDFDialog: boolean;
	setShowPDFDialog: (showPDFDialog: boolean) => void;
	uriPDFDoc: string;
	setUriPDFDoc: (uriPDFDoc: string) => void;
};

export const ParagraphsListContext =
	React.createContext<ParagraphsListContextProps>({
		showPDFDialog: false,
		uriPDFDoc: '',
	} as any);

export const ParagraphsListContextProvider: React.FC<{
	children: React.ReactNode;
}> = ({children}) => {
	const [showPDFDialog, setShowPDFDialog] = useState<boolean>(false);
	const [uriPDFDoc, setUriPDFDoc] = useState<string>('');

	return (
		<ParagraphsListContext.Provider
			value={{
				showPDFDialog,
				setShowPDFDialog,
				uriPDFDoc,
				setUriPDFDoc,
			}}
		>
			{children}
		</ParagraphsListContext.Provider>
	);
};

export const useParagraphsListContext = () =>
	React.useContext(
		ParagraphsListContext,
	) as unknown as ParagraphsListContextProps;
