import * as Types from '../types/types.generated';

import { gql } from '@apollo/client';
import { EditableParagraphFieldsFragmentDoc } from './editableParagraphFields.generated';
export type ParagraphFieldsOfUpdateResponseFragment = { __typename?: 'RegulatoryDocumentParagraph', id: string, comprehensive?: any | null, dateNewRegistration?: any | null, dateNewTypes?: any | null, modelYear?: number | null, summary?: string | null, categories: Array<{ __typename?: 'Category', id: string, name: string }>, driveVariants: Array<{ __typename?: 'DriveVariant', id: string, name: string }>, keywords: Array<{ __typename?: 'Keyword', id: string, name: string }>, phaseIn?: Array<{ __typename?: 'PhaseDate', date?: any | null, status?: string | null }> | null, phaseOut?: Array<{ __typename?: 'PhaseDate', date?: any | null, status?: string | null }> | null, tags: Array<{ __typename?: 'Tag', id: string, name: string }>, vehicleCategories: Array<{ __typename?: 'VehicleCategory', id: string, name: string }>, keywordAssignments?: Array<{ __typename?: 'KeywordAssignment', keyword: { __typename?: 'Keyword', id: string, name: string }, vexClusterAssignments: Array<{ __typename?: 'VexClusterAssignment', keywordStatus: Types.KeywordStatus, vexCluster: { __typename?: 'VexCluster', id: string, name: string } }> }> | null };

export const ParagraphFieldsOfUpdateResponseFragmentDoc = gql`
    fragment ParagraphFieldsOfUpdateResponse on RegulatoryDocumentParagraph {
  ...EditableParagraphFields
  id
}
    ${EditableParagraphFieldsFragmentDoc}`;