import React from 'react';
import {
	PropsOfTextFieldWithTooltip,
	TextFieldWithTooltip,
} from 'features/localizedTooltips';

type Props = Omit<PropsOfTextFieldWithTooltip, 'tooltipHostProps'>;

export function ReasonField(props: Props): JSX.Element {
	return (
		<TextFieldWithTooltip
			{...props}
			tooltipHostProps={{translationKey: 'reason'}}
		/>
	);
}
