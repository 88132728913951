import React from 'react';
import {ITextStyles, Text} from '@fluentui/react';
import {useKeywordsTranslations} from '../keywords.commonHooks';

interface Props {
	children: JSX.Element;
	list: unknown[];
}

const textStyles: ITextStyles = {
	root: {
		display: 'block',
		marginTop: 0,
		marginBottom: '0.8rem',
	},
};

export function FieldWrapper({children, list}: Props): JSX.Element | null {
	const {t} = useKeywordsTranslations();

	if (list.length) return children;
	return (
		<Text as='p' styles={textStyles}>
			{t('EmptyField')}
		</Text>
	);
}
