import React, {useContext} from 'react';
import {CommonLoadWrapperProps} from 'components/LoadWrapper';
import {
	RegDocDetailsPageInfo,
	RegDocDetailsPageInfoContext,
} from '../RegDocDetailsPage.context';
import {CssLoadWrapper} from 'components/CssLoadWrapper';

export type RefetchLoadWrapperProps = Pick<CommonLoadWrapperProps, 'children'>;

/**
 * This component preserves the user's scroll position.
 */
export function RefetchLoadWrapper({
	children,
}: RefetchLoadWrapperProps): JSX.Element {
	/**
	 * This component only gets rendered when the resources' initial data finish
	 * loading. Therefore, if the page is still loading, it must be due to a
	 * refetch.
	 */
	const {shouldShowRefetchIndicator} = useContext(
		RegDocDetailsPageInfoContext,
	) as RegDocDetailsPageInfo;

	return (
		<CssLoadWrapper loading={shouldShowRefetchIndicator}>
			{children}
		</CssLoadWrapper>
	);
}
