import React from 'react';
import {ICheckboxProps} from '@fluentui/react';
import {
	LocalizedTooltipHostWithId,
	RenderChildrenFromTooltipId,
} from 'features/localizedTooltips/LocalizedTooltipHostWithId';
import {BasePropsOfComponentForRenderer} from 'features/localizedTooltips/TooltipRendererCreator.service';
import {LocalizedTooltipHostProps} from 'features/localizedTooltips/LocalizedTooltipHost';

type Props = BasePropsOfComponentForRenderer<ICheckboxProps> &
	LocalizedTooltipHostProps;

export function TooltipWithCheckboxLabel({
	defaultRender,
	originalComponentProps,
	...other
}: Props) {
	const renderChildren: RenderChildrenFromTooltipId = id => {
		return defaultRender({...originalComponentProps, ariaDescribedBy: id});
	};

	/**
	 * We pass the translation key here because most other form fields support
	 * auto inferring the translation key.
	 */
	return (
		<LocalizedTooltipHostWithId
			translationKey={originalComponentProps?.name}
			{...other}
		>
			{renderChildren}
		</LocalizedTooltipHostWithId>
	);
}
