import React from 'react';
import {ITextFieldProps} from '@fluentui/react';
import {useTranslation} from 'react-i18next';
import {SetAddReason} from './AssignVehicleProjectRequirementsForm';
import {Requirement} from 'types';
import {
	TextFieldWithTooltip,
	useTooltipTranslation,
} from 'features/localizedTooltips';

interface Props {
	value: string;
	setValue: SetAddReason;
	selectedRequirements: Requirement[];
}

export default function FieldForReasonToAddRequirements({
	value,
	setValue,
	selectedRequirements,
}: Props) {
	const {t} = useTranslation('features/vehicleprojects', {
		keyPrefix: 'FieldForReasonToAddRequirements',
	});

	const {t: translateTooltipsText} = useTooltipTranslation(
		'features/VehicleProjects/assignVehicleProjectRequirementsForm',
	);

	type OnChange = Exclude<ITextFieldProps['onChange'], undefined>;

	const handleChange: OnChange = (_event, newValue): void => {
		setValue(newValue ?? '');
	};

	const isEnabled = Boolean(selectedRequirements.length);

	/**
	 * It is only required when it's enabled. Otherwise, we might confuse users
	 * because they would think they need to fill in the input, but they would
	 * have no way of doing so.
	 */
	return (
		<TextFieldWithTooltip
			multiline
			label={t('Label')}
			placeholder={t('Placeholder')}
			onChange={handleChange}
			value={value}
			required={isEnabled}
			disabled={!isEnabled}
			styles={{root: {margin: '1rem 0 0.5rem'}}}
			autoAdjustHeight
			tooltipHostProps={{content: translateTooltipsText('reason')}}
		/>
	);
}
