import {ArrayChange, diffArrays, diffWords} from 'diff';
import {ParagraphElementType} from 'types';
import {RegDoc} from '../CompareVersionPanel/ComparisonComponent/ComparisonComponent.types';

type QueryParagraph = RegDoc['paragraphs'][number];

type QueryElement = QueryParagraph['elements'][number];

interface Element extends QueryElement {
	// This type is more specific than that of the query
	asset?: {
		uri: string;
	} | null;
}

export interface IParagraphCompareInput
	extends Pick<QueryParagraph, 'elements'> {
	elements: Element[];
	enumeration: string;
	level: number;
	page: number;
}

export interface ComparableAsset {
	assetId: string;
	uri: string;
	/**
	 * The paragraph text elements' text will be aggregated into a single
	 * string. This is the character index that the asset should be placed after.
	 */
	index: number;
}

export interface IComparable extends Pick<IParagraphCompareInput, 'elements'> {
	enumeration: string;
	text: string;
	assets: Array<ComparableAsset>;
	[key: string]: any;
}

export enum CompareResult {
	Equal,
	New,
	Deleted,
	Changed,
	InReview,
}

export interface IComparedParagraph {
	paragraph: IComparable;
	textElements: ITextCompareElement[];
}

export interface IComparedItem {
	oldParagraph?: IComparedParagraph;
	newParagraph?: IComparedParagraph;
	resultType: CompareResult;
}

export interface ITextCompareElement {
	text: string;
	/**
	 * Whether or not the text exists in the current paragraph while not existing
	 * in the other paragraph.
	 */
	isChange: boolean;
}

export interface ITextCompareResult {
	textElementsOld: ITextCompareElement[];
	textElementsNew: ITextCompareElement[];
}

export function cmpText(
	textOld: string,
	textNew: string,
): ITextCompareResult | undefined {
	/**
	 * Unlike the function's name implies, the results include words that were
	 * changed and words that were not changed. You must use each array item's
	 * props to determine if a change occurred.
	 */
	const diffRes = diffArrays(
		textOld.replace(/\s{2,}/g, ' ').split(' '),
		textNew.replace(/\s{2,}/g, ' ').split(' '),
	);

	const hasNoChanges = diffRes.every(diff => !diff.added && !diff.removed);

	if (hasNoChanges) {
		return undefined;
	}
	/**
	 * The comparison result includes all words in the two compared strings. Here,
	 * we exclude the comparison results related to the new string to get those
	 * related to the old string.
	 */
	const changesOldText = diffRes.filter(diff => !diff.added);
	const changesNewText = diffRes.filter(diff => !diff.removed);

	return {
		textElementsOld: changesOldText.map(c => ({
			text: c.value.join(' '),
			isChange: Boolean(c.removed),
		})),
		textElementsNew: changesNewText.map(c => ({
			text: c.value.join(' '),
			isChange: Boolean(c.added),
		})),
	};
}

export function convertToComparables(paragraphs: IParagraphCompareInput[]) {
	const comparables: IComparable[] = paragraphs.map(p => {
		let text = '';
		const assets = [];

		for (const e of p.elements) {
			/**
			 * This might be here to prevent the text comparison algorithm from
			 * comparing images' text. It appears that image elements have a "text"
			 * property set to their file names by default.
			 */
			if (!e.isHeader && !e.assetId) {
				text += e.text ?? '';
			}

			if (e.asset && e.assetId) {
				assets.push({
					assetId: e.assetId,
					uri: e.asset.uri,
					index: text.length,
				});
			}
		}

		return {
			...p,
			enumeration: p.enumeration.trim(),
			text,
			assets,
		};
	});

	return comparables;
}

export function compareParagraphs(
	oldDocumentParagraphs: IParagraphCompareInput[],
	newDocumentParagraphs: IParagraphCompareInput[],
): IComparedItem[] {
	const oldVersion = convertToComparables(oldDocumentParagraphs);
	const newVersion = convertToComparables(newDocumentParagraphs);

	return compareVersions(oldVersion, newVersion);
}

const getTableOrImageElementTypes = (): ParagraphElementType[] => {
	return [
		ParagraphElementType.HtmlTable,
		ParagraphElementType.Image,
		ParagraphElementType.Table,
	];
};

const getIfIsTableOrImageElement = (element: Element): boolean => {
	const tableOrImageTypes: ParagraphElementType[] =
		getTableOrImageElementTypes();
	return tableOrImageTypes.includes(element.type);
};

const getIfElementHasTableOrImage = (element: Element): boolean => {
	const isTableOrImgElement: boolean = getIfIsTableOrImageElement(element);
	/**
	 * We check if it has an asset because legacy paragraphs can have text
	 * elements that have assets, which means they are displayed as images
	 * instead. Whether or not the asset's URI is empty does not matter because
	 * EditorContent will render it as an image anyways. So, we must consider it
	 * an image here, too.
	 */
	return isTableOrImgElement || Boolean(element.asset);
};

type PossibleElement = Element | undefined;

const findTableOrImageElement = (elements: Element[]): PossibleElement => {
	return elements.find(getIfElementHasTableOrImage);
};

const getIfComparableHasTableOrImg = ({elements}: IComparable): boolean => {
	const elementForReview: PossibleElement = findTableOrImageElement(elements);
	return Boolean(elementForReview);
};

const getIfComparablesBothHaveTablesOrImages = (
	comparables: IComparable[],
): boolean => {
	return comparables.every(getIfComparableHasTableOrImg);
};

const getStatusForInReviewOrDefaultStatus = (
	areBothParagraphsInReview: boolean,
	defaultResultType: CompareResult,
): CompareResult => {
	return areBothParagraphsInReview ? CompareResult.InReview : defaultResultType;
};

/**
 * Notes:
 *
 * - idx = index
 */
export function compareVersions(
	oldVersion: IComparable[],
	newVersion: IComparable[],
): IComparedItem[] {
	let idxOld = 0;
	let idxNew = 0;

	const result: IComparedItem[] = [];

	const arrEnumerationsOld = oldVersion.map(x =>
		x.enumeration.toLowerCase().replace(/[^a-z0-9]/gi, ''),
	);
	const arrEnumerationsNew = newVersion.map(x =>
		x.enumeration.toLowerCase().replace(/[^a-z0-9]/gi, ''),
	);

	const arrCompareResult = diffArrays(arrEnumerationsOld, arrEnumerationsNew);

	const funcGenerateResults = (meyerDiff: ArrayChange<string>) => {
		if (meyerDiff.added === false && meyerDiff.removed === false) {
			meyerDiff.value.forEach(x => {
				const paragraphOld = oldVersion[idxOld];
				const paragraphNew = newVersion[idxNew];

				const textCmpResult = cmpText(paragraphOld.text, paragraphNew.text);

				const comparables: IComparable[] = [paragraphOld, paragraphNew];
				const areBothParagraphsInReview: boolean =
					getIfComparablesBothHaveTablesOrImages(comparables);

				result.push({
					oldParagraph: {
						paragraph: paragraphOld,
						textElements: textCmpResult
							? textCmpResult.textElementsOld
							: [{text: paragraphOld.text, isChange: false}],
					},
					newParagraph: {
						paragraph: paragraphNew,
						textElements: textCmpResult
							? textCmpResult.textElementsNew
							: [{text: paragraphNew.text, isChange: false}],
					},
					resultType: getStatusForInReviewOrDefaultStatus(
						areBothParagraphsInReview,
						textCmpResult ? CompareResult.Changed : CompareResult.Equal,
					),
				});

				idxOld++;
				idxNew++;
			});
		}

		if (meyerDiff.removed === true) {
			meyerDiff.value.forEach(x => {
				const paragraphOld = oldVersion[idxOld];
				result.push({
					oldParagraph: {
						paragraph: paragraphOld,
						textElements: [{text: paragraphOld.text, isChange: false}],
					},
					resultType: CompareResult.Deleted,
				});
				idxOld++;
			});
		}

		if (meyerDiff.added === true) {
			meyerDiff.value.forEach(x => {
				const paragraphNew = newVersion[idxNew];
				result.push({
					newParagraph: {
						paragraph: paragraphNew,
						textElements: [{text: paragraphNew.text, isChange: false}],
					},
					resultType: CompareResult.New,
				});
				idxNew++;
			});
		}
	};

	arrCompareResult.forEach(funcGenerateResults);

	return result;
}
