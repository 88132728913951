import {EntityPage} from 'components';
import {LoadWrapper} from 'components/LoadWrapper';
import {ViewAuthorizer} from 'components/ViewAuthorizer';
import {EngineVariantsTooltipTranslationProvider} from 'features/localizedTooltips';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {EngineVariant, UserRole} from 'types';
import {
	GetAllEngineVariantsDocument,
	useCreateEngineVariantMutation,
	useDeleteEngineVariantMutation,
	useGetAllEngineVariantsQuery,
	useUpdateEngineVariantMutation,
} from './hooks/engineVariants.generated';

const AdminEngineVariantsPage: React.FC = () => {
	const {t} = useTranslation('features/adminsection', {
		keyPrefix: 'AdminEngineVariantsPage',
	});

	const {loading, data} = useGetAllEngineVariantsQuery();
	const [createEngineVariantMutation] = useCreateEngineVariantMutation();
	const [updateEngineVariantMutation] = useUpdateEngineVariantMutation();
	const [deleteEngineVariantMutation] = useDeleteEngineVariantMutation();

	const engineVariants = React.useMemo(
		() => data?.engineVariants ?? [],
		[data],
	);

	const refetchQueries = [GetAllEngineVariantsDocument];

	const createEngineVariant = React.useCallback(
		(engineVariant: EngineVariant) => {
			createEngineVariantMutation({
				variables: {
					input: {
						name: engineVariant.name,
					},
				},
				refetchQueries,
			});
		},
		[],
	);

	const updateEngineVariant = React.useCallback(
		(engineVariant: EngineVariant) => {
			updateEngineVariantMutation({
				variables: {
					input: {id: engineVariant.id, name: engineVariant.name},
				},
				refetchQueries,
			});
		},
		[],
	);

	const deleteEngineVariant = React.useCallback((id: string) => {
		deleteEngineVariantMutation({
			variables: {
				input: {id},
			},
			refetchQueries,
		});
	}, []);

	return (
		<ViewAuthorizer roles={[UserRole.SystemAdministrator]}>
			<LoadWrapper loading={loading}>
				<EngineVariantsTooltipTranslationProvider>
					<EntityPage
						items={engineVariants}
						entityDisplayName={t('EngineVariants')}
						createEntity={createEngineVariant}
						updateEntity={updateEngineVariant}
						deleteEntity={deleteEngineVariant}
					/>
				</EngineVariantsTooltipTranslationProvider>
			</LoadWrapper>
		</ViewAuthorizer>
	);
};

export default AdminEngineVariantsPage;
