import {Node} from 'react-checkbox-tree';
import {treeService} from './ParagraphTableOfContents.tree.service';
import {
	ParagraphIds as Ids,
	ParagraphId as Id,
} from './ParagraphTableOfContents.types';
import _ from 'lodash';

type IdMatch = Id | undefined;

interface Fields {
	checkedItemsFromCheckHandler: Ids;
	checkedItemsFromState: Ids;
	tree: Node[];
}

/**
 * By default, parent checkboxes do not cascade their status to their
 * descendants. This means that if a user toggles a checkbox, it will not change
 * its nested checkboxes. This class adds the cascading feature.
 */
export class CheckedItemsService {
	private checkedItemsFromCheckHandler: Fields['checkedItemsFromCheckHandler'];
	private checkedItemsFromState: Fields['checkedItemsFromState'];
	private tree: Fields['tree'];

	constructor({
		checkedItemsFromCheckHandler,
		checkedItemsFromState,
		tree,
	}: Fields) {
		this.checkedItemsFromCheckHandler = checkedItemsFromCheckHandler;
		this.checkedItemsFromState = checkedItemsFromState;
		this.tree = tree;
	}

	private getDescendantIds = (paragraphId: Id): Ids => {
		return treeService.getNodeAndGetDescendantIds(paragraphId, this.tree);
	};

	private getCheckedItemsWithIdsOfDescendants = (
		checkedParagraphId: Id,
	): Ids => {
		const descendantIds: Ids = this.getDescendantIds(checkedParagraphId);
		return [...this.checkedItemsFromCheckHandler, ...descendantIds];
	};

	private cascadeCheckDescendants = (checkedParagraphId: Id): Ids => {
		const newCheckedItems: Ids =
			this.getCheckedItemsWithIdsOfDescendants(checkedParagraphId);
		return _.uniq(newCheckedItems);
	};

	private getIfParagraphIdWasJustChecked = (paragraphId: Id): boolean => {
		/**
		 * If the previous checked items do not have the paragraph, then it was just
		 * added.
		 */
		return !this.checkedItemsFromState.includes(paragraphId);
	};

	private find1stAddedItem = (): IdMatch => {
		return this.checkedItemsFromCheckHandler.find(
			this.getIfParagraphIdWasJustChecked,
		);
	};

	private getIfParagraphIdWasUnchecked = (
		paragraphIdFromState: Id,
	): boolean => {
		/**
		 * If it was in the state but is no longer in the checked items we get
		 * from the handler, then the paragraph was unchecked.
		 */
		return !this.checkedItemsFromCheckHandler.includes(paragraphIdFromState);
	};

	private findParagraphIdThatWasUnchecked = (): IdMatch => {
		return this.checkedItemsFromState.find(this.getIfParagraphIdWasUnchecked);
	};

	private cascadeUncheckDescendants = (uncheckedParagraphId: Id): Ids => {
		const descendantIds: Ids = this.getDescendantIds(uncheckedParagraphId);
		/**
		 * Note that the items from the check handler should not have the unchecked
		 * item, so we only need to remove its descendants.
		 */
		return _.difference(this.checkedItemsFromCheckHandler, descendantIds);
	};

	public getCheckedItems = (): Ids => {
		/**
		 * We only find the 1st paragraph that was added/removed because there
		 * should only be one.
		 */
		const addedItem: IdMatch = this.find1stAddedItem();
		const removedItem: IdMatch = this.findParagraphIdThatWasUnchecked();
		if (addedItem) return this.cascadeCheckDescendants(addedItem);
		if (removedItem) return this.cascadeUncheckDescendants(removedItem);
		return this.checkedItemsFromCheckHandler;
	};

	static getCheckedItems = (fields: Fields): Ids => {
		const service = new CheckedItemsService(fields);
		return service.getCheckedItems();
	};
}
