import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UploadRegulatoryDocumentAssetMutationVariables = Types.Exact<{
  input: Types.UploadRegulatoryDocumentAssetInput;
}>;


export type UploadRegulatoryDocumentAssetMutation = { __typename?: 'Mutation', uploadRegulatoryDocumentAsset: { __typename?: 'RegulatoryDocumentAssetPayload', regulatoryDocumentAsset?: { __typename?: 'BlobRef', contentType: string, fileName: string, uri: any } | null } };


export const UploadRegulatoryDocumentAssetDocument = gql`
    mutation UploadRegulatoryDocumentAsset($input: UploadRegulatoryDocumentAssetInput!) {
  uploadRegulatoryDocumentAsset(input: $input) {
    regulatoryDocumentAsset {
      contentType
      fileName
      uri
      fileName
    }
  }
}
    `;
export type UploadRegulatoryDocumentAssetMutationFn = Apollo.MutationFunction<UploadRegulatoryDocumentAssetMutation, UploadRegulatoryDocumentAssetMutationVariables>;

/**
 * __useUploadRegulatoryDocumentAssetMutation__
 *
 * To run a mutation, you first call `useUploadRegulatoryDocumentAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadRegulatoryDocumentAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadRegulatoryDocumentAssetMutation, { data, loading, error }] = useUploadRegulatoryDocumentAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadRegulatoryDocumentAssetMutation(baseOptions?: Apollo.MutationHookOptions<UploadRegulatoryDocumentAssetMutation, UploadRegulatoryDocumentAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadRegulatoryDocumentAssetMutation, UploadRegulatoryDocumentAssetMutationVariables>(UploadRegulatoryDocumentAssetDocument, options);
      }
export type UploadRegulatoryDocumentAssetMutationHookResult = ReturnType<typeof useUploadRegulatoryDocumentAssetMutation>;
export type UploadRegulatoryDocumentAssetMutationResult = Apollo.MutationResult<UploadRegulatoryDocumentAssetMutation>;
export type UploadRegulatoryDocumentAssetMutationOptions = Apollo.BaseMutationOptions<UploadRegulatoryDocumentAssetMutation, UploadRegulatoryDocumentAssetMutationVariables>;