import React, {Dispatch, SetStateAction} from 'react';

export enum RegulationFormMode {
	/**
	 * Prevents the panel from showing.
	 */
	None,
	Create,
	Update,
}

export type SetRegulationFormMode = Dispatch<
	SetStateAction<RegulationFormMode>
>;

export type RegulationFormModeStateInfo = [
	RegulationFormMode,
	SetRegulationFormMode,
];

export const useRegulationFormMode = (): RegulationFormModeStateInfo => {
	return React.useState<RegulationFormMode>(RegulationFormMode.None);
};
