import * as Types from '../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MyFavoritesIdsOnlyQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MyFavoritesIdsOnlyQuery = { __typename?: 'Query', myFavorites: { __typename?: 'Favorites', regulations: Array<{ __typename?: 'Regulation', id: string }>, regulatoryDocuments: Array<{ __typename?: 'RegulatoryDocument', id: string }>, paragraphs: Array<{ __typename?: 'RegulatoryDocumentParagraph', id: string }>, requirements: Array<{ __typename?: 'Requirement', id: string }> } };


export const MyFavoritesIdsOnlyDocument = gql`
    query MyFavoritesIdsOnly {
  myFavorites {
    regulations {
      id
    }
    regulatoryDocuments {
      id
    }
    paragraphs {
      id
    }
    requirements {
      id
    }
  }
}
    `;

/**
 * __useMyFavoritesIdsOnlyQuery__
 *
 * To run a query within a React component, call `useMyFavoritesIdsOnlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyFavoritesIdsOnlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyFavoritesIdsOnlyQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyFavoritesIdsOnlyQuery(baseOptions?: Apollo.QueryHookOptions<MyFavoritesIdsOnlyQuery, MyFavoritesIdsOnlyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyFavoritesIdsOnlyQuery, MyFavoritesIdsOnlyQueryVariables>(MyFavoritesIdsOnlyDocument, options);
      }
export function useMyFavoritesIdsOnlyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyFavoritesIdsOnlyQuery, MyFavoritesIdsOnlyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyFavoritesIdsOnlyQuery, MyFavoritesIdsOnlyQueryVariables>(MyFavoritesIdsOnlyDocument, options);
        }
export type MyFavoritesIdsOnlyQueryHookResult = ReturnType<typeof useMyFavoritesIdsOnlyQuery>;
export type MyFavoritesIdsOnlyLazyQueryHookResult = ReturnType<typeof useMyFavoritesIdsOnlyLazyQuery>;
export type MyFavoritesIdsOnlyQueryResult = Apollo.QueryResult<MyFavoritesIdsOnlyQuery, MyFavoritesIdsOnlyQueryVariables>;