import {
	DefaultButton,
	IDropdownOption,
	Panel,
	PrimaryButton,
} from '@fluentui/react';
import {useEntityContext} from 'components/EntityPage/EntityContext';
import {ControlledDropdown} from 'components/hookForms';
import {LoadWrapper} from 'components/LoadWrapper';
import {useCommand} from 'hooks';
import React from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {Convolute} from 'types';
import {
	GetConvolutesDocument,
	useUpdateConvoluteMutation,
} from '../hooks/convolutes.generated';
import {useGetGdprRulesQuery} from '../hooks/gdprRules.generated';
import {GdrpRulesTooltipTranslationProvider} from 'features/localizedTooltips';

export enum ConvoluteFormMode {
	None,
	Update,
}

export const ConvoluteForm: React.FC = () => {
	const {t} = useTranslation('features/adminsection', {
		keyPrefix: 'AdminDataProtectionPage',
	});

	const [mode, setMode] = React.useState(ConvoluteFormMode.None);

	const {selectedItems} = useEntityContext<Convolute>();

	const [updateConvolute] = useUpdateConvoluteMutation();

	const {handleSubmit, control, reset} = useForm<Convolute>({
		reValidateMode: 'onSubmit',
		mode: 'all',
	});

	const {data, loading} = useGetGdprRulesQuery();

	const ruleOptions = React.useMemo(
		() =>
			data?.gdprRules?.map(r => {
				return {
					key: r.id,
					text: r.name,
				} as IDropdownOption;
			}) || [],
		[data],
	);

	const handleCancelClick = React.useCallback(() => {
		setMode(ConvoluteFormMode.None);
		reset({});
	}, [mode]);

	useCommand(
		{
			key: 'updateConvolute',
			iconProps: {iconName: 'Link'},
			text: t('UpdateConvolute'),
			onClick() {
				setMode(ConvoluteFormMode.Update);
			},
			title: t('UpdateConvolute'),
			priority: 2,
			disabled: selectedItems.length !== 1,
		},
		[mode, selectedItems],
	);

	React.useEffect(() => {
		reset(selectedItems[0] ?? {});
	}, [selectedItems]);

	const onRenderFooterContent: any = () => (
		<form>
			<PrimaryButton styles={buttonStyles} onClick={handleSaveClick}>
				{t('AssignButton')}
			</PrimaryButton>
			<DefaultButton onClick={handleCancelClick}>
				{t('CancelButton')}
			</DefaultButton>
		</form>
	);

	const handleSaveClick = React.useCallback(() => {
		handleSubmit(input => {
			updateConvolute({
				variables: {
					input: {
						convoluteType: selectedItems[0].convoluteType,
						gdprRuleRef: {id: (input as any).gdprRuleId},
						id: selectedItems[0].id,
						name: selectedItems[0].name,
					},
				},
				refetchQueries: [GetConvolutesDocument],
			});

			setMode(ConvoluteFormMode.None);
		})();
	}, [selectedItems, mode]);

	return (
		<LoadWrapper loading={loading}>
			<Panel
				isOpen={mode !== ConvoluteFormMode.None}
				isLightDismiss
				onDismiss={handleCancelClick}
				isFooterAtBottom={true}
				onRenderFooterContent={onRenderFooterContent}
				headerText={t('UpdateConvoluteHeader')}
			>
				<GdrpRulesTooltipTranslationProvider>
					<ControlledDropdown
						label={t('GdprRule')}
						control={control}
						name={'gdprRuleId'}
						options={ruleOptions}
						tooltipHostProps={{
							translationKey: 'id',
							shouldGetTextFromContext: true,
						}}
					/>
				</GdrpRulesTooltipTranslationProvider>
			</Panel>
		</LoadWrapper>
	);
};

const buttonStyles = {
	root: {
		marginRight: 8,
	},
};
