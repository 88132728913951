import React from 'react';
import {mergeStyleSets, mergeStyles} from '@fluentui/merge-styles';
import {Stack, PrimaryButton, IButtonStyles} from '@fluentui/react';
import {useCommand} from 'hooks';

// Styling of a regular HTML element
const divStyle = mergeStyles({background: 'red'});

// Styling of a fluentui component
// The type annotation yields autcomplete suggestions for
const buttonStyles = mergeStyleSets({
	root: {
		color: 'violet',
	},
} as IButtonStyles);

// Use an interface to declare prop type for your component
export type SampleComponentProps = {
	prop1: string;
	prop2: number;
	prop3: 'a' | 'b' | 'c';
	functionProp: () => void;
	optionalProp?: string;
};

export const SampleComponent: React.FC<SampleComponentProps> = ({
	prop1,
	prop2,
	prop3,
	functionProp,
	optionalProp,
}) => {
	useCommand(
		{
			key: 'TestCommand',
			text: 'Click me!',
			priority: 1,
			onClick: () => console.log('Command executed!'),
		},
		[],
	);
	return (
		<Stack tokens={{childrenGap: 8}}>
			<div className={divStyle}>prop1: {prop1}</div>
			<div>prop2: {prop2}</div>
			<div>prop3: {prop3}</div>
			<div>
				<PrimaryButton
					styles={buttonStyles}
					type={'button'}
					onClick={functionProp}
				>
					Click me!
				</PrimaryButton>
			</div>
			{optionalProp && <div>optionalProp: {optionalProp}</div>}
		</Stack>
	);
};
