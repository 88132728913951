import React from 'react';

type EntityContextProps<T> = {
	selectedItems: T[];
	setSelectedItems: (items: T[]) => void;
};

const EntityContext = React.createContext<EntityContextProps<any>>({
	selectedItems: [],
} as any);

export function EntityContextProvider<T>({
	items,
	children,
}: {
	items?: T[];
	children: React.ReactNode;
}) {
	const [selectedItems, setSelectedItems] = React.useState<T[]>(items || []);

	return (
		<EntityContext.Provider
			value={{
				selectedItems,
				setSelectedItems,
			}}
		>
			{children}
		</EntityContext.Provider>
	);
}

export function useEntityContext<T>() {
	return React.useContext<EntityContextProps<T>>(
		EntityContext as unknown as React.Context<EntityContextProps<T>>,
	);
}
