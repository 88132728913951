export const getConfig = () => {
	const REACT_APP_AUTHORITY =
		process.env.REACT_APP_AUTHORITY ||
		'https://login.microsoftonline.com/7b88386b-c610-47bc-ba98-c3e2421c231f/';
	const REACT_APP_CLIENT_ID =
		process.env.REACT_APP_CLIENT_ID || 'b1f6fb2f-010a-4d8f-911f-cc407834c78a';
	const REACT_APP_SERVICE_URL =
		process.env.REACT_APP_SERVICE_URL || 'http://localhost:4000/graphql';
	const REACT_APP_REDIRECT_URI =
		process.env.REACT_APP_REDIRECT_URI || 'http://localhost:3000';
	const REACT_APP_ADOBE_CLIENT_ID =
		process.env.REACT_APP_ADOBE_CLIENT_ID || '8c0cd670273d451cbc9b351b11d22318';
	const REACT_APP_MSAL_SCOPE =
		process.env.REACT_APP_MSAL_SCOPE ||
		'api://b1f6fb2f-010a-4d8f-911f-cc407834c78a/access';

	return {
		REACT_APP_AUTHORITY,
		REACT_APP_CLIENT_ID,
		REACT_APP_SERVICE_URL,
		REACT_APP_REDIRECT_URI,
		REACT_APP_ADOBE_CLIENT_ID,
		REACT_APP_MSAL_SCOPE,
	};
};
