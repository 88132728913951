import * as Types from '../types/types.generated';

import { gql } from '@apollo/client';
export type FieldsForRequirementColumnFragment = { __typename?: 'Requirement', id: string, status: Types.RequirementStatus, name: string, requirementId?: string | null, definition?: string | null, vexClusters: Array<{ __typename?: 'VexCluster', id: string, name: string }> };

export type FieldsForAttachmentColumnFragment = { __typename?: 'AttachmentRef', attachmentId: string, file: { __typename?: 'BlobRef', uri: any }, category?: { __typename?: 'AttachmentCategory', id: string, name: string } | null };

export const FieldsForRequirementColumnFragmentDoc = gql`
    fragment FieldsForRequirementColumn on Requirement {
  id
  status
  name
  requirementId
  definition
  vexClusters {
    id
    name
  }
}
    `;
export const FieldsForAttachmentColumnFragmentDoc = gql`
    fragment FieldsForAttachmentColumn on AttachmentRef {
  attachmentId
  file {
    uri
  }
  category {
    id
    name
  }
}
    `;