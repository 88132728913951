import * as Types from '../../../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDocumentUriQueryVariables = Types.Exact<{
  regulatoryDocumentId: Types.Scalars['ID'];
}>;


export type GetDocumentUriQuery = { __typename?: 'Query', regulatoryDocument?: { __typename?: 'RegulatoryDocument', document?: { __typename?: 'BlobRef', contentType: string, fileName: string, uri: any } | null } | null };


export const GetDocumentUriDocument = gql`
    query GetDocumentURI($regulatoryDocumentId: ID!) {
  regulatoryDocument(id: $regulatoryDocumentId) {
    document {
      contentType
      fileName
      uri
    }
  }
}
    `;

/**
 * __useGetDocumentUriQuery__
 *
 * To run a query within a React component, call `useGetDocumentUriQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentUriQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentUriQuery({
 *   variables: {
 *      regulatoryDocumentId: // value for 'regulatoryDocumentId'
 *   },
 * });
 */
export function useGetDocumentUriQuery(baseOptions: Apollo.QueryHookOptions<GetDocumentUriQuery, GetDocumentUriQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentUriQuery, GetDocumentUriQueryVariables>(GetDocumentUriDocument, options);
      }
export function useGetDocumentUriLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentUriQuery, GetDocumentUriQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentUriQuery, GetDocumentUriQueryVariables>(GetDocumentUriDocument, options);
        }
export type GetDocumentUriQueryHookResult = ReturnType<typeof useGetDocumentUriQuery>;
export type GetDocumentUriLazyQueryHookResult = ReturnType<typeof useGetDocumentUriLazyQuery>;
export type GetDocumentUriQueryResult = Apollo.QueryResult<GetDocumentUriQuery, GetDocumentUriQueryVariables>;