import {Node} from 'react-checkbox-tree';
import {ParagraphId} from './ParagraphTableOfContents.types';

type NodeMatch = Node | undefined;

class TreeService {
	private findNodeFromNode = (
		paragraphId: ParagraphId,
		node: Node,
	): NodeMatch => {
		if (node.value === paragraphId) return node;
		if (!node.children) return undefined;
		return this.recursivelyFindNodeInNodes(paragraphId, node.children);
	};

	private recursivelyFindNodeInNodes = (
		paragraphId: ParagraphId,
		nodes: Node[],
	): NodeMatch => {
		for (const node of nodes) {
			const match: NodeMatch = this.findNodeFromNode(paragraphId, node);
			if (match) return match;
		}

		return undefined;
	};

	private addNodeIds = (nodeIds: ParagraphId[], node: Node): ParagraphId[] => {
		const {children = []} = node;
		const descendantIds: ParagraphId[] = this.recursivelyGetNodeIds(children);
		const nodeAndDescendantIds: ParagraphId[] = [node.value, ...descendantIds];
		return [...nodeIds, ...nodeAndDescendantIds];
	};

	private recursivelyGetNodeIds = (nodes: Node[]): ParagraphId[] => {
		return nodes.reduce(this.addNodeIds, []);
	};

	private handleMissingNode = (paragraphId: ParagraphId): never => {
		throw new Error(`Failed to find node with id '${paragraphId}' in tree.`);
	};

	public getNodeAndGetDescendantIds = (
		paragraphId: ParagraphId,
		nodes: Node[],
	): ParagraphId[] => {
		const node: NodeMatch = this.recursivelyFindNodeInNodes(paragraphId, nodes);
		if (!node) return this.handleMissingNode(paragraphId);
		return this.recursivelyGetNodeIds(node.children ?? []);
	};
}

export const treeService = new TreeService();
