import {mergeStyles} from '@fluentui/react';
import React from 'react';
import {FieldError} from 'react-hook-form';

export type ErrorMessageProps = {
	error?: FieldError | string;
};

/**
 * ! Important
 *
 * This does not work when you do not set a custom message in the corresponding
 * rule in "rules."
 */
export const ErrorMessage: React.FC<ErrorMessageProps> = ({error}) => {
	let message: string | undefined;

	if (typeof error === 'string') {
		message = error;
	} else if (error?.message) {
		message = error.message;
	}

	return message ? (
		<span>
			<div role='alert'>
				<p className={errorClassName}>
					<span data-automation-id='error-message'>{message}</span>
				</p>
			</div>
		</span>
	) : null;
};

// Copied from Fluent UI text field
const errorClassName = mergeStyles({
	animationName: 'css-57, css-70',
	animationDuration: '0.367s',
	animationTimingFunction: 'cubic-bezier(0.1, 0.9, 0.2, 1)',
	animationFillMode: 'both',
	fontFamily:
		'"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
	WebkitFontSmoothing: 'antialiased',
	fontSize: '12px',
	fontWeight: 400,
	color: 'rgb(164, 38, 44)',
	margin: '0px',
	paddingTop: '5px',
	display: 'flex',
	alignItems: 'center',
});
