import React from 'react';
import {Breadcrumb, IBreadcrumbItem} from '@fluentui/react';
import {
	GenerationBreadcrumbFields,
	useGenerationBreadcrumbs,
} from './breadcrumbs.utils';

/**
 * We are accepting the breadcrumb's key and text as strings instead of passing
 * them in an object so we don't have to use useMemo on the object.
 */
interface Props extends Pick<GenerationBreadcrumbFields, 'params'> {
	textOfExtraBreadcrumb: IBreadcrumbItem['text'];
	keyOfExtraBreadcrumb: IBreadcrumbItem['key'];
}

const getHeight = (): string => {
	const margin = 11;
	const baseHeight = 37;
	return `${margin + baseHeight}px`;
};

export const heightOfBreadcrumbsOfGenerationSubpage: string = getHeight();

/**
 * These are the breadcrumbs for either a single or multiple active type markets.
 */
export function BreadcrumbsOfGenerationSubpage({
	params,
	textOfExtraBreadcrumb,
	keyOfExtraBreadcrumb,
}: Props): JSX.Element {
	const extraBreadcrumb: IBreadcrumbItem = {
		text: textOfExtraBreadcrumb,
		key: keyOfExtraBreadcrumb,
	};

	const breadcrumbs: IBreadcrumbItem[] = useGenerationBreadcrumbs({
		params,
		extraItems: [extraBreadcrumb],
	});

	return <Breadcrumb items={breadcrumbs} />;
}
