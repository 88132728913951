import {
	DialogInfo,
	DialogState,
	HideDialog,
	ShowDialog,
	useDialogState,
} from 'components/Dialogs';
import {
	DocumentChangeStateInfo,
	useDidDocumentChange,
} from '../useDidDocumentChange';
import {
	RegulatoryDocumentFormModeInfo,
	useRegulatoryDocumentFormMode,
} from './useRegulatoryDocumentFormMode';

interface DialogStateInfo {
	dialogState: DialogState;
	showDialog: ShowDialog;
	hideDialog: HideDialog;
}

export interface RegulatoryDocumentFormInfo {
	dialogStateInfo: DialogStateInfo;
	documentChangeStateInfo: DocumentChangeStateInfo;
	modeStateInfo: RegulatoryDocumentFormModeInfo;
}

export const useRegulatoryDocumentForm = (): RegulatoryDocumentFormInfo => {
	const [dialogState, showDialog, hideDialog]: DialogInfo = useDialogState();

	return {
		dialogStateInfo: {dialogState, showDialog, hideDialog},
		documentChangeStateInfo: useDidDocumentChange(),
		modeStateInfo: useRegulatoryDocumentFormMode(),
	};
};
