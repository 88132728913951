import {EntityPage} from 'components';
import {LoadWrapper} from 'components/LoadWrapper';
import {ViewAuthorizer} from 'components/ViewAuthorizer';
import {VehicleCategoriesTooltipTranslationProvider} from 'features/localizedTooltips';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {UserRole, VehicleCategory} from 'types';
import {
	GetAllVehicleCategoriesDocument,
	useCreateVehicleCategoryMutation,
	useDeleteVehicleCategoryMutation,
	useGetAllVehicleCategoriesQuery,
	useUpdateVehicleCategoryMutation,
} from './hooks/vehicleCategories.generated';

const AdminVehicleCategoriesPage: React.FC = () => {
	const {t} = useTranslation('features/adminsection', {
		keyPrefix: 'AdminVehicleCategoriesPage',
	});
	const {loading, data} = useGetAllVehicleCategoriesQuery();
	const [createVehicleCategoryMutation] = useCreateVehicleCategoryMutation();
	const [updateVehicleCategoryMutation] = useUpdateVehicleCategoryMutation();
	const [deleteVehicleCategoryMutation] = useDeleteVehicleCategoryMutation();

	const vehicleCategories = React.useMemo(
		() => data?.vehicleCategories ?? [],
		[data],
	);

	const refetchQueries = [GetAllVehicleCategoriesDocument];

	const createVehicleCategory = React.useCallback(
		(vehicleCategory: VehicleCategory) => {
			createVehicleCategoryMutation({
				variables: {
					input: {
						name: vehicleCategory.name,
					},
				},
				refetchQueries,
			});
		},
		[],
	);
	const updateVehicleCategory = React.useCallback(
		(vehicleCategory: VehicleCategory) => {
			updateVehicleCategoryMutation({
				variables: {
					input: {
						id: vehicleCategory.id,
						name: vehicleCategory.name,
					},
				},
				refetchQueries,
			});
		},
		[],
	);
	const deleteVehicleCategory = React.useCallback((id: string) => {
		deleteVehicleCategoryMutation({
			variables: {
				input: {id},
			},
			refetchQueries,
		});
	}, []);

	return (
		<ViewAuthorizer roles={[UserRole.SystemAdministrator]}>
			<LoadWrapper loading={loading}>
				<VehicleCategoriesTooltipTranslationProvider>
					<EntityPage
						items={vehicleCategories}
						entityDisplayName={t('VehicleCategory')}
						createEntity={createVehicleCategory}
						updateEntity={updateVehicleCategory}
						deleteEntity={deleteVehicleCategory}
					/>
				</VehicleCategoriesTooltipTranslationProvider>
			</LoadWrapper>
		</ViewAuthorizer>
	);
};

export default AdminVehicleCategoriesPage;
