import React, {useCallback} from 'react';
import {BaselineDetailsPageParams} from '../BaselineDetailsPage.types';
import {PossibleSelectedBaseline} from './BaselinesSelectionDialog.types';
import {createPathOfBaselinesComparisonPage} from 'features/VehicleProjects/BaselineComparisonPage/BaselineComparisonPage.utils';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import {PrimaryButton} from '@fluentui/react';
import {useTranslationsOfBaselinesSelectionDialog} from './BaselinesSelectionDialog.utils';
import {
	ProjectDetailsPageParams,
	encodeGenAndModelSeries,
} from 'features/VehicleProjects/vehicleProjectPagePaths';

export interface PropsOfBtnToCompareBaselines {
	params: BaselineDetailsPageParams;
	possibleSelectedBaseline: PossibleSelectedBaseline | null;
}

export function BtnToCompareBaselines({
	params,
	possibleSelectedBaseline,
}: PropsOfBtnToCompareBaselines): JSX.Element {
	const navigate: NavigateFunction = useNavigate();
	const {t} = useTranslationsOfBaselinesSelectionDialog();

	const navigateToComparisonPage = useCallback((): void => {
		const createPath = (selectedBaseline: PossibleSelectedBaseline): string => {
			const {id, ...other} = params;

			const encodedGenAndModel: ProjectDetailsPageParams =
				encodeGenAndModelSeries(other);

			return createPathOfBaselinesComparisonPage({
				baselineId: id,
				idOfBaselineToCompareTo: selectedBaseline.id,
				...encodedGenAndModel,
			});
		};

		if (!possibleSelectedBaseline) throw new Error('No baseline was selected');
		const path: string = createPath(possibleSelectedBaseline);
		navigate(path);
	}, [params, navigate, possibleSelectedBaseline]);

	return (
		<PrimaryButton
			onClick={navigateToComparisonPage}
			disabled={!possibleSelectedBaseline}
		>
			{t('SaveBtn')}
		</PrimaryButton>
	);
}
