import {
	DirectionalHint,
	Icon,
	IDetailsListProps,
	ITooltipProps,
	SelectionMode,
	TooltipDelay,
	TooltipHost,
} from '@fluentui/react';
import {useId} from '@fluentui/react-hooks';
import {useUserContext} from 'authentication/UserContext';
import {EntityList, EntityListColumn, EntityListProps} from 'components';
import {
	renderAttachments,
	renderDocument,
	renderReferenceField,
} from 'components/EntityList/ColumnRenderers';
import {PageRegulatoryDocs} from 'features/Regulations/RegulationDetail/RegulationDetail.types';
import {useSelection} from 'hooks';
import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {RegulatoryDocument} from 'types';
import {useRegulatoryDocumentsContext} from '../../../RegulatoryDocuments/context';
import {REG_DOC_TRANSLATION_NAMESPACE} from '../../../RegulatoryDocuments/components/regDocConstants';
import {SplitDocumentLegend} from '../../../RegulatoryDocuments/components/SplitDocumentLegend';
import {RegDocsListDetailsRow} from '../../../RegulatoryDocuments/components/RegDocsList/RegDocsList.DetailsRow';
import {RegDocsListRowRendererProps} from '../../../RegulatoryDocuments/components/RegDocsList/RegDocsList.types';
import {SetRequired} from 'type-fest';
import {getPogisDocumentsListBaseColumns} from '../PogisDocumentDetails/PogisDocumentsListBaseColumns';

type PogisDocsListProps = Omit<
	EntityListProps,
	| 'columns'
	| 'items'
	| 'aria-rowcount'
	| 'onRenderRow'
	| 'selectionMode'
	| 'selection'
	| 'onItemInvoked'
> & {
	pogisDocuments: PageRegulatoryDocs;
	regulationId?: string;
};

export const PogisDocsList: React.FC<PogisDocsListProps> = ({
	regulationId,
	pogisDocuments,
	...entityLstProps
}) => {
	const {t} = useTranslation(REG_DOC_TRANSLATION_NAMESPACE, {
		keyPrefix: 'RegulatoryDocumentsList',
	});
	const {t: tEnum} = useTranslation('common/enums');
	const {isVex} = useUserContext();

	const {setSelectedRegulatoryDocument, setAllSelectedRegulatoryDocuments} =
		useRegulatoryDocumentsContext();
	const navigate = useNavigate();

	const onRenderRow: IDetailsListProps['onRenderRow'] = React.useCallback(
		(props: RegDocsListRowRendererProps) => {
			return <RegDocsListDetailsRow propsFromRenderer={props} />;
		},
		[],
	);

	type ListRegDoc = SetRequired<Partial<RegulatoryDocument>, 'id'>;

	const getKey = useCallback(
		(item: ListRegDoc): ListRegDoc['id'] => item.id,
		[],
	);

	const [selection] = useSelection<ListRegDoc>({
		onSelectionChanged() {
			setSelectedRegulatoryDocument(
				selection.getSelection()[0] as Partial<RegulatoryDocument>,
			);
			setAllSelectedRegulatoryDocuments(
				selection.getSelection() as Partial<RegulatoryDocument>[],
			);
		},
		getKey,
	});

	const onRenderDocumentHeader = () => <DocumentHeader />;

	const columns = React.useMemo(
		() =>
			[
				{
					key: 'document',
					isIconOnly: true,
					fieldName: 'documentStatus',
					minWidth: 16,
					maxWidth: 16,
					filterable: false,
					onRenderHeader: onRenderDocumentHeader,
					onRender: renderDocument(),
				},
				{
					name: 'attachments',
					key: 'attachments',
					filterable: false,
					isIconOnly: true,
					iconName: 'Attach',
					fieldName: 'attachments',
					minWidth: 16,
					maxWidth: 16,
					onRender: renderAttachments(),
				},
				...getPogisDocumentsListBaseColumns(t, tEnum, true),
				{
					key: 'references',
					name: t('References'),
					filterable: false,
					iconName: 'Link',
					isIconOnly: true,
					fieldName: 'documentReferences',
					minWidth: 300,
					onRender: renderReferenceField(),
				},
			] as EntityListColumn[],
		[t, tEnum],
	);

	const onItemInvoked = React.useCallback(
		(item: RegulatoryDocument) => {
			const regId = regulationId ?? item.regulation?.id;
			navigate(`/regulations/${regId}/${item?.id ?? ''}/paragraphs`);
		},
		[navigate, isVex, regulationId],
	);

	return (
		<div>
			<EntityList
				{...entityLstProps}
				items={pogisDocuments}
				columns={columns}
				aria-rowcount
				onRenderRow={onRenderRow}
				selectionMode={SelectionMode.multiple}
				selection={selection}
				onItemInvoked={onItemInvoked}
				getKey={getKey}
			/>
		</div>
	);
};

const documentTooltipProps: ITooltipProps = {
	onRenderContent: () => <SplitDocumentLegend />,
};

const DocumentHeader = () => {
	const documentTooltipId = useId('documentTooltip');

	return (
		<TooltipHost
			tooltipProps={documentTooltipProps}
			delay={TooltipDelay.zero}
			id={documentTooltipId}
			directionalHint={DirectionalHint.leftCenter}
		>
			<Icon iconName='Page' />
		</TooltipHost>
	);
};
