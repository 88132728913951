import React from 'react';
import {ITextProps, ITextStyles, Text} from '@fluentui/react';

const styles: ITextStyles = {
	root: {fontWeight: 500},
};

export function KeywordTooltipHeading({children}: ITextProps): JSX.Element {
	return (
		<Text as='h2' styles={styles}>
			{children}:
		</Text>
	);
}
