import React from 'react';
import {ControlledTextField} from 'components/hookForms';
import {Control} from 'react-hook-form';
import {PogisDocument} from 'types';

type PogisDocumentFormElementProps = {
	control: Control<PogisDocument>;
};

const PogisDocumentFormelements = ({
	control,
}: PogisDocumentFormElementProps): JSX.Element => {
	const renderFormElements = (): JSX.Element[] => {
		const items: JSX.Element[] = [
			<ControlledTextField
				required={true}
				label='LinkVersion'
				control={control}
				name={'linkVersion'}
				key={1}
			/>,
		];
		return items;
	};

	const formElements: JSX.Element[] = renderFormElements();
	return <>{formElements}</>;
};

export default PogisDocumentFormelements;
