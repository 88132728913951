/**
 * The names of the fields that might have to be merged with the existing values.
 * This happens in bulk edit mode.
 */
export const namesOfMergeableArrayFields = [
	'keywords',
	'driveVariants',
	'vehicleCategories',
	'categories',
	'tags',
] as const;

export type NameOfMergeableArrayField =
	(typeof namesOfMergeableArrayFields)[number];
