import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReassignAllRegulatoryDocumentsForClonedRequirementMutationVariables = Types.Exact<{
  input: Types.ReassignAllRegulatoryDocumentsForClonedRequirementInput;
}>;


export type ReassignAllRegulatoryDocumentsForClonedRequirementMutation = { __typename?: 'Mutation', reassignAllRegulatoryDocumentsForClonedRequirement: { __typename?: 'ReassignAllRegulatoryDocumentsForClonedRequirementPayload', boolean?: boolean | null } };


export const ReassignAllRegulatoryDocumentsForClonedRequirementDocument = gql`
    mutation ReassignAllRegulatoryDocumentsForClonedRequirement($input: ReassignAllRegulatoryDocumentsForClonedRequirementInput!) {
  reassignAllRegulatoryDocumentsForClonedRequirement(input: $input) {
    boolean
  }
}
    `;
export type ReassignAllRegulatoryDocumentsForClonedRequirementMutationFn = Apollo.MutationFunction<ReassignAllRegulatoryDocumentsForClonedRequirementMutation, ReassignAllRegulatoryDocumentsForClonedRequirementMutationVariables>;

/**
 * __useReassignAllRegulatoryDocumentsForClonedRequirementMutation__
 *
 * To run a mutation, you first call `useReassignAllRegulatoryDocumentsForClonedRequirementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReassignAllRegulatoryDocumentsForClonedRequirementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reassignAllRegulatoryDocumentsForClonedRequirementMutation, { data, loading, error }] = useReassignAllRegulatoryDocumentsForClonedRequirementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReassignAllRegulatoryDocumentsForClonedRequirementMutation(baseOptions?: Apollo.MutationHookOptions<ReassignAllRegulatoryDocumentsForClonedRequirementMutation, ReassignAllRegulatoryDocumentsForClonedRequirementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReassignAllRegulatoryDocumentsForClonedRequirementMutation, ReassignAllRegulatoryDocumentsForClonedRequirementMutationVariables>(ReassignAllRegulatoryDocumentsForClonedRequirementDocument, options);
      }
export type ReassignAllRegulatoryDocumentsForClonedRequirementMutationHookResult = ReturnType<typeof useReassignAllRegulatoryDocumentsForClonedRequirementMutation>;
export type ReassignAllRegulatoryDocumentsForClonedRequirementMutationResult = Apollo.MutationResult<ReassignAllRegulatoryDocumentsForClonedRequirementMutation>;
export type ReassignAllRegulatoryDocumentsForClonedRequirementMutationOptions = Apollo.BaseMutationOptions<ReassignAllRegulatoryDocumentsForClonedRequirementMutation, ReassignAllRegulatoryDocumentsForClonedRequirementMutationVariables>;