import {
	ActivityItem,
	Icon,
	IconButton,
	Link,
	Stack,
	Theme,
	useTheme,
} from '@fluentui/react';
import {useUserContext} from 'authentication/UserContext';
import {GetRegulationDocument} from 'features/Regulations/hooks';
import {GetRegulatoryDocumentDetailsDocument} from 'features/RegulatoryDocuments/hooks/useGetRegulatoryDocumentDetails.generated';
import {DateTimeFormat, formatDateTime} from 'i18n/localeDateFormat';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Commentary} from 'types';
import {
	useDeleteRegulationCommentaryMutation,
	useDeleteRegulatoryDocumentCommentaryMutation,
	useDeleteRegulatoryDocumentParagraphCommentaryMutation,
} from './hooks';

export enum CommentLevel {
	Regulation,
	RegulatoryDocument,
	Paragraph,
}

type CommentListProps = {
	items: Commentary[];
	regulationId: string;
	regulatoryDocumentId: string;
	level: CommentLevel;
	paragraphId?: string;
};

export const CommentList: React.FC<CommentListProps> = ({
	items,
	regulationId,
	regulatoryDocumentId,
	level,
	paragraphId,
}) => {
	const {i18n} = useTranslation('common/enums', {
		keyPrefix: 'RegulatoryDocumentStatus',
	});

	const {t} = useTranslation('components/commentlist');
	const theme = useTheme();
	const isAdmin = useUserContext();

	const {iconStyles, activityItemStyles, deleteStyles} = getStyles(theme);
	const [deleteRegulationCommentary] = useDeleteRegulationCommentaryMutation();
	const [deleteRegulatoryDocumentCommentary] =
		useDeleteRegulatoryDocumentCommentaryMutation();
	const [deleteRegulatoryDocumentParagraphCommentary] =
		useDeleteRegulatoryDocumentParagraphCommentaryMutation();

	const handleRegulationCommentaryDelete = React.useCallback(
		(item: Commentary) =>
			deleteRegulationCommentary({
				variables: {
					input: {
						id: regulationId,
						commentId: item.id,
					},
				},
				refetchQueries: [GetRegulationDocument],
			}),
		[deleteRegulationCommentary, regulationId],
	);

	const handleRegulatoryDocumentCommentaryDelete = React.useCallback(
		(item: Commentary) =>
			deleteRegulatoryDocumentCommentary({
				variables: {
					input: {
						id: regulatoryDocumentId,
						commentId: item.id,
					},
				},
				refetchQueries: [GetRegulatoryDocumentDetailsDocument],
			}),
		[deleteRegulatoryDocumentCommentary, regulatoryDocumentId],
	);

	const handleParagraphCommentaryDelete = React.useCallback(
		(item: Commentary) =>
			deleteRegulatoryDocumentParagraphCommentary({
				variables: {
					input: {
						regulatoryDocumentId,
						commentId: item.id,
						paragraphId: paragraphId || '',
					},
				},
				refetchQueries: [GetRegulatoryDocumentDetailsDocument],
			}),
		[
			deleteRegulatoryDocumentParagraphCommentary,
			regulatoryDocumentId,
			paragraphId,
		],
	);

	const comments = items.map((item: Commentary, index: number) => (
		<ActivityItem
			key={index}
			activityDescription={
				<Stack horizontal horizontalAlign='space-between'>
					<Stack.Item>
						<Link>{item?.createdBy?.name ?? t('Unknown')}</Link>
						{t('Comment')}
					</Stack.Item>
					{isAdmin && (
						<Stack.Item>
							<IconButton
								iconProps={{iconName: 'StatusCircleErrorX'}}
								styles={deleteStyles}
								onClick={() => {
									if (level === CommentLevel.Regulation) {
										handleRegulationCommentaryDelete(item);
									}

									if (level === CommentLevel.RegulatoryDocument) {
										handleRegulatoryDocumentCommentaryDelete(item);
									}

									if (level === CommentLevel.Paragraph) {
										handleParagraphCommentaryDelete(item);
									}
								}}
							/>
						</Stack.Item>
					)}
				</Stack>
			}
			activityIcon={<Icon iconName={'Message'} styles={iconStyles} />}
			comments={item.comment}
			timeStamp={formatDateTime(
				new Date(item.createdAt),
				i18n,
				DateTimeFormat.DateTimeMonth,
			)}
			styles={activityItemStyles}
		/>
	));

	return <div>{comments}</div>;
};

const getStyles = (theme: Theme) => ({
	iconStyles: {
		root: {
			color: theme.palette.themePrimary,
		},
	},
	activityItemStyles: {
		root: {marginTop: 20},
	},
	deleteStyles: {
		root: {
			height: 20,
			width: 20,
		},
		rootHovered: {
			background: theme.palette.whiteTranslucent40,
		},
		rootPressed: {
			background: theme.palette.whiteTranslucent40,
		},
	},
});
