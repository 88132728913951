import React from 'react';
import {Panel, PanelType, SelectionMode} from '@fluentui/react';
import {TFunction} from 'i18next';
import {EntityList} from 'components/EntityList';
import {RegDocsTooltipTranslationProvider} from 'features/RegulatoryDocuments/components/RegDocTooltipTranslationProvider';
import {
	renderDate,
	renderRichtext,
} from 'components/EntityList/ColumnRenderers';
import {DateTimeFormat} from 'i18n/localeDateFormat';
import {DATE_TYPE} from 'components/EntityList/EntityUtils';

export const HistoryFieldSidePanel: React.FC<{
	label: string;
	isOpen: boolean;
	onClose: () => void;
	historyEntries: {date: Date; value: string}[];
	t: TFunction;
}> = ({label, isOpen, onClose, historyEntries, t}) => {
	return (
		<Panel
			type={PanelType.large}
			isOpen={isOpen}
			isLightDismiss
			onDismiss={onClose}
			headerText={label}
		>
			<RegDocsTooltipTranslationProvider>
				<EntityList
					columns={[
						{
							key: 'date',
							name: 'Date',
							fieldName: 'date',
							minWidth: 100,
							maxWidth: 200,
							sortable: true,
							filterable: true,
							isResizable: true,
							dataType: DATE_TYPE,
							onRender: renderDate(DateTimeFormat.DateTimeMonth),
						},
						{
							key: 'value',
							name: 'Value',
							fieldName: 'value',
							minWidth: 600,
							sortable: true,
							isResizable: true,
							filterable: true,
							onRender: renderRichtext(600),
						},
					]}
					items={historyEntries}
					selectionMode={SelectionMode.none}
				/>
			</RegDocsTooltipTranslationProvider>
		</Panel>
	);
};
