import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllSystemLevelsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllSystemLevelsQuery = { __typename?: 'Query', systemLevels?: Array<{ __typename?: 'SystemLevel', id: string, name: string, createdAt?: any | null, modifiedAt?: any | null, createdBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null }, modifiedBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null } }> | null };

export type CreateSystemLevelMutationVariables = Types.Exact<{
  input: Types.CreateSystemLevelInput;
}>;


export type CreateSystemLevelMutation = { __typename?: 'Mutation', createSystemLevel: { __typename?: 'CreateSystemLevelPayload', systemLevel?: { __typename?: 'SystemLevel', id: string } | null } };

export type UpdateSystemLevelMutationVariables = Types.Exact<{
  input: Types.UpdateSystemLevelInput;
}>;


export type UpdateSystemLevelMutation = { __typename?: 'Mutation', updateSystemLevel: { __typename?: 'UpdateSystemLevelPayload', systemLevel?: { __typename?: 'SystemLevel', id: string } | null } };

export type DeleteSystemLevelMutationVariables = Types.Exact<{
  input: Types.DeleteSystemLevelInput;
}>;


export type DeleteSystemLevelMutation = { __typename?: 'Mutation', deleteSystemLevel: { __typename?: 'DeleteSystemLevelPayload', systemLevel?: { __typename?: 'SystemLevel', id: string } | null } };


export const GetAllSystemLevelsDocument = gql`
    query GetAllSystemLevels {
  systemLevels {
    id
    name
    createdAt
    createdBy {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
    modifiedAt
    modifiedBy {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
  }
}
    `;

/**
 * __useGetAllSystemLevelsQuery__
 *
 * To run a query within a React component, call `useGetAllSystemLevelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSystemLevelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSystemLevelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllSystemLevelsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllSystemLevelsQuery, GetAllSystemLevelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllSystemLevelsQuery, GetAllSystemLevelsQueryVariables>(GetAllSystemLevelsDocument, options);
      }
export function useGetAllSystemLevelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllSystemLevelsQuery, GetAllSystemLevelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllSystemLevelsQuery, GetAllSystemLevelsQueryVariables>(GetAllSystemLevelsDocument, options);
        }
export type GetAllSystemLevelsQueryHookResult = ReturnType<typeof useGetAllSystemLevelsQuery>;
export type GetAllSystemLevelsLazyQueryHookResult = ReturnType<typeof useGetAllSystemLevelsLazyQuery>;
export type GetAllSystemLevelsQueryResult = Apollo.QueryResult<GetAllSystemLevelsQuery, GetAllSystemLevelsQueryVariables>;
export const CreateSystemLevelDocument = gql`
    mutation CreateSystemLevel($input: CreateSystemLevelInput!) {
  createSystemLevel(input: $input) {
    systemLevel {
      id
    }
  }
}
    `;
export type CreateSystemLevelMutationFn = Apollo.MutationFunction<CreateSystemLevelMutation, CreateSystemLevelMutationVariables>;

/**
 * __useCreateSystemLevelMutation__
 *
 * To run a mutation, you first call `useCreateSystemLevelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSystemLevelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSystemLevelMutation, { data, loading, error }] = useCreateSystemLevelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSystemLevelMutation(baseOptions?: Apollo.MutationHookOptions<CreateSystemLevelMutation, CreateSystemLevelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSystemLevelMutation, CreateSystemLevelMutationVariables>(CreateSystemLevelDocument, options);
      }
export type CreateSystemLevelMutationHookResult = ReturnType<typeof useCreateSystemLevelMutation>;
export type CreateSystemLevelMutationResult = Apollo.MutationResult<CreateSystemLevelMutation>;
export type CreateSystemLevelMutationOptions = Apollo.BaseMutationOptions<CreateSystemLevelMutation, CreateSystemLevelMutationVariables>;
export const UpdateSystemLevelDocument = gql`
    mutation UpdateSystemLevel($input: UpdateSystemLevelInput!) {
  updateSystemLevel(input: $input) {
    systemLevel {
      id
    }
  }
}
    `;
export type UpdateSystemLevelMutationFn = Apollo.MutationFunction<UpdateSystemLevelMutation, UpdateSystemLevelMutationVariables>;

/**
 * __useUpdateSystemLevelMutation__
 *
 * To run a mutation, you first call `useUpdateSystemLevelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSystemLevelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSystemLevelMutation, { data, loading, error }] = useUpdateSystemLevelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSystemLevelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSystemLevelMutation, UpdateSystemLevelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSystemLevelMutation, UpdateSystemLevelMutationVariables>(UpdateSystemLevelDocument, options);
      }
export type UpdateSystemLevelMutationHookResult = ReturnType<typeof useUpdateSystemLevelMutation>;
export type UpdateSystemLevelMutationResult = Apollo.MutationResult<UpdateSystemLevelMutation>;
export type UpdateSystemLevelMutationOptions = Apollo.BaseMutationOptions<UpdateSystemLevelMutation, UpdateSystemLevelMutationVariables>;
export const DeleteSystemLevelDocument = gql`
    mutation DeleteSystemLevel($input: DeleteSystemLevelInput!) {
  deleteSystemLevel(input: $input) {
    systemLevel {
      id
    }
  }
}
    `;
export type DeleteSystemLevelMutationFn = Apollo.MutationFunction<DeleteSystemLevelMutation, DeleteSystemLevelMutationVariables>;

/**
 * __useDeleteSystemLevelMutation__
 *
 * To run a mutation, you first call `useDeleteSystemLevelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSystemLevelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSystemLevelMutation, { data, loading, error }] = useDeleteSystemLevelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSystemLevelMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSystemLevelMutation, DeleteSystemLevelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSystemLevelMutation, DeleteSystemLevelMutationVariables>(DeleteSystemLevelDocument, options);
      }
export type DeleteSystemLevelMutationHookResult = ReturnType<typeof useDeleteSystemLevelMutation>;
export type DeleteSystemLevelMutationResult = Apollo.MutationResult<DeleteSystemLevelMutation>;
export type DeleteSystemLevelMutationOptions = Apollo.BaseMutationOptions<DeleteSystemLevelMutation, DeleteSystemLevelMutationVariables>;