import {
	DefaultButton,
	Dialog,
	DialogFooter,
	DialogType,
	IDialogContentProps,
	IDialogProps,
	PrimaryButton,
} from '@fluentui/react';
import {useTranslation} from 'react-i18next';
import React from 'react';
import {ReasonField} from 'features/RegulatoryDocuments/components/DocumentDetails/WorkflowDialog/ReasonField';

export type DeclineDialogProps = Omit<IDialogProps, 'modalProps'> & {
	onConfirm: () => void;
	requirementName: string;
	reason: string;
	setReason: (value: string) => void;
};

export const DeclineDialog: React.FC<DeclineDialogProps> = ({
	requirementName,
	reason,
	setReason,
	...props
}) => {
	const {t} = useTranslation('features/requirements', {
		keyPrefix: 'WorkflowDialog',
	});

	const dialogContentProps: IDialogContentProps = React.useMemo(
		() => ({
			type: DialogType.normal,
			title: t('ApprovalNOKHeader'),
			subText: t('ApprovalNOKText', {name: requirementName}),
		}),
		[],
	);
	const modalProps = {
		isBlocking: true,
		styles: {main: {maxWidth: 450}},
	};
	const onInputChange = React.useCallback(
		(_: any, val?: string) => setReason(val ?? ''),
		[reason],
	);
	return (
		<Dialog
			dialogContentProps={dialogContentProps}
			{...props}
			modalProps={modalProps}
		>
			<ReasonField
				onChange={onInputChange}
				required
				multiline
				autoAdjustHeight
				label={t('ReasonLabel')}
			/>
			<DialogFooter>
				<PrimaryButton onClick={props.onConfirm}>
					{t('ApprovalConfirmButton')}
				</PrimaryButton>
				<DefaultButton
					onClick={props.onDismiss as any}
					text={t('CancelButton')}
				/>
			</DialogFooter>
		</Dialog>
	);
};
