import {FormMode} from 'components/EntityPage/EntityPage.types';
import React from 'react';
import {ReferenceChoice} from './ReferenceChoice';
import {ControlOfSimulationsPage} from '../SimulationsPage.types';
import {
	PropsOfReferencePicker,
	VehicleProjectsReferencePicker,
} from './VehicleProjectsReferencePicker/VehicleProjectsReferencePicker';

interface Props extends Pick<PropsOfReferencePicker, 'setValue'> {
	control: ControlOfSimulationsPage;
	mode: FormMode;
}

export function FormFieldsOfSimulationsPage({
	control,
	mode,
	setValue,
}: Props): JSX.Element | null {
	if (mode !== FormMode.Create) return null;

	return (
		<div>
			<ReferenceChoice control={control} />
			<VehicleProjectsReferencePicker control={control} setValue={setValue} />
		</div>
	);
}
