import {DocumentNode} from '@apollo/client';
import {
	DefaultButton,
	Dialog,
	DialogFooter,
	PrimaryButton,
} from '@fluentui/react';

import {EnumDropdown} from 'components/hookForms';
import {PeoplePicker} from 'components/hookForms/ControlledPeoplePicker';
import {LabelWithTooltip} from 'features/localizedTooltips';
import {convertToRef} from 'helpers';
import omitDeep from 'omit-deep-lodash';
import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {Market, UserRole, WorkflowStatus} from 'types';
import {evictAuditLogs} from '../../RegDocDetailsPage/evictAuditLogs';
import {RegDocAuditLogsInfo} from '../../RegDocDetailsPage/useGetRegDocAuditLogs';
import {useUpdateRegulatoryDocumentMutation} from '../../hooks';
import {useRegDocsFormTranslations} from '../../hooks/RegulatoryDocuments.commonHooks';
import {
	BasicDocFields,
	FormRegulatoryDoc,
} from './RegulatoryDocumentsForm.types';
import {RegulatoryDocumentFormInfo} from './useRegulatoryDocumentForm';
import {RegulatoryDocumentFormMode} from './useRegulatoryDocumentFormMode';
import {useNavigate} from 'react-router-dom';
import {
	getValueForPredecessorOrSuccessor,
	mapDocumentReferences,
} from 'features/RegulatoryDocuments/regulatoryUtils';
import {useCloneRegulatoryDocumentMutation} from 'features/RegulatoryDocuments/hooks/useCloneRegulatoryDocument.generated';
type StartPolling = (pollInterval: number) => void;

export interface RegulatoryDocumentsFormProps
	extends RegulatoryDocumentFormInfo {
	regulatoryDocuments: BasicDocFields[];
	regulatoryDocument: FormRegulatoryDoc;
	markets: Market[];
	regulationNumber: string;
	regulationId: string | null;
	startPolling: StartPolling | null;
	queriesToRefetchAfterSave: DocumentNode[];
	fetchAndSetAuditLogs?: RegDocAuditLogsInfo['refetchAuditLogsAndUpdateIsLoading'];
}

enum ChangeType {
	NONE = 'NONE',
	CONTENT = 'CONTENT',
	EDITORIAL = 'EDITORIAL',
}
export const RegulationChangeDialog = ({
	data,
	showConfirmationDialog,
	setShowConfirmationDialog,
	regulatoryDocument,
	regulationId,
	fetchAndSetAuditLogs,
	showTopicManagementSummaryState,
	onRegulatoryDocumentSaved,
	setMode,
}: any) => {
	const [changeType, setChangeType] = useState(ChangeType.NONE);
	const [confirmDisabled, setConfirmDisabled] = useState(false);
	const navigate = useNavigate();
	const [updateRegulatoryDocument] = useUpdateRegulatoryDocumentMutation();
	const [cloneRegulatoryDocumentMutation] =
		useCloneRegulatoryDocumentMutation();

	const [users, setUsers] = React.useState([]);

	const onUserInputChange = React.useCallback(
		(items: any) => setUsers(items || []),
		[users],
	);

	const onDismiss = () => {
		setChangeType(ChangeType.NONE);
		setShowConfirmationDialog(false);
	};

	const getUpdateInput = (
		id: string,
		isFinalized: boolean,
		changeType: ChangeType,
	) => {
		let workflow = {
			status: WorkflowStatus.Modified,
			message:
				changeType === ChangeType.EDITORIAL
					? regulatoryDocument?.workflow?.message
					: undefined,
		};

		if (isFinalized) {
			workflow = omitDeep(data.workflow, '__typename') as any;
			if (changeType === ChangeType.CONTENT) {
				workflow.status = WorkflowStatus.Modified;
			}
		}

		return {
			id,
			regulationRef: regulationId ? {id: regulationId} : undefined,
			name: data.name,
			status: data.status,
			summary: data.summary,
			workflow,
			finalVersionRef: isFinalized
				? undefined
				: convertToRef(regulatoryDocument.finalVersionRef),
			topicManagementSummary:
				data.topicManagementSummary?.quarter &&
				data.topicManagementSummary?.year &&
				showTopicManagementSummaryState
					? {
							quarter: data.topicManagementSummary?.quarter,
							year: data.topicManagementSummary?.year,
					  }
					: null,
			predecessorRef: getValueForPredecessorOrSuccessor(data.predecessor),
			successorRef: getValueForPredecessorOrSuccessor(data.successor),
			documentSourceRef: convertToRef(data.documentSource),
			dateEffective: data.dateEffective,
			dateExpiration: data.dateExpiration,
			documentStatus: regulatoryDocument.documentStatus,
			documentReferences: mapDocumentReferences(data.documentReferences),
		};
	};

	const onClearingClick = async () => {
		if (!regulatoryDocument?.id) {
			return;
		}

		setConfirmDisabled(true);
		const isFinalized = data?.workflow?.status === WorkflowStatus.Finalized;

		if (isFinalized && changeType === ChangeType.EDITORIAL) {
			await updateRegulatoryDocument({
				variables: {
					input: getUpdateInput(regulatoryDocument.id, isFinalized, changeType),
				},
				update: evictAuditLogs,
			}).then(result => {
				const regDocId =
					result.data?.updateRegulatoryDocument?.regulatoryDocument?.id;
				onRegulatoryDocumentSaved(regDocId);
			});
			await fetchAndSetAuditLogs?.();
		} else if (isFinalized && changeType === ChangeType.CONTENT) {
			const cloneResult = await cloneRegulatoryDocumentMutation({
				variables: {
					input: {
						originalRegulatoryDocumentId: regulatoryDocument.id,
						workflowStatus: WorkflowStatus.Modified,
					},
				},
			});
			const clonedRegDoc =
				cloneResult?.data?.cloneRegulatoryDocument?.regulatoryDocument;

			if (!clonedRegDoc) {
				return;
			}

			await updateRegulatoryDocument({
				variables: {
					input: getUpdateInput(clonedRegDoc.id, isFinalized, changeType),
				},
				update: evictAuditLogs,
			}).then(async result => {
				const regDocId =
					result.data?.updateRegulatoryDocument?.regulatoryDocument?.id;
				await onRegulatoryDocumentSaved(regDocId);
			});

			navigate(`/regulations/${regulationId}`);
		} else if (!isFinalized && changeType === ChangeType.CONTENT) {
			await updateRegulatoryDocument({
				variables: {
					input: getUpdateInput(regulatoryDocument.id, isFinalized, changeType),
					reapprovingVKO: convertToRef(users[0]),
				},
				update: evictAuditLogs,
			}).then(async result => {
				const regDocId =
					result.data?.updateRegulatoryDocument?.regulatoryDocument?.id;
				await onRegulatoryDocumentSaved(regDocId);
			});
			await fetchAndSetAuditLogs?.();
		} else if (!isFinalized && changeType === ChangeType.EDITORIAL) {
			await updateRegulatoryDocument({
				variables: {
					input: getUpdateInput(regulatoryDocument.id, isFinalized, changeType),
				},
				update: evictAuditLogs,
			}).then(async result => {
				const regDocId =
					result.data?.updateRegulatoryDocument?.regulatoryDocument?.id;
				await onRegulatoryDocumentSaved(regDocId);
			});
			await fetchAndSetAuditLogs?.();
		}

		setConfirmDisabled(false);
		setChangeType(ChangeType.NONE);
		setShowConfirmationDialog(false);
		setMode(RegulatoryDocumentFormMode.None);
	};

	const {control, getValues} = useForm<any>({
		reValidateMode: 'onSubmit',
		mode: 'all',
	});
	const onChangeTypeChange = () => {
		const changeType = getValues('changeType');

		setChangeType(changeType);
	};

	const {t} = useRegDocsFormTranslations();

	return (
		<Dialog
			hidden={!showConfirmationDialog}
			title={t('AcceptChangesTitle')}
			modalProps={{
				isBlocking: true,
				isDarkOverlay: true,
				styles: {main: {maxWidth: 650}},
			}}
			onDismiss={onDismiss}
		>
			<EnumDropdown
				required={true}
				control={control}
				name={'changeType'}
				label={t('ChangeTypeLabel')}
				placeholder={t('ChooseChangeType')}
				defaultValue={changeType}
				enumName={'ChangeType'}
				enumType={ChangeType}
				exclude={[ChangeType.NONE]}
				onChange={onChangeTypeChange}
			/>

			{changeType === ChangeType.CONTENT && (
				<>
					{' '}
					<LabelWithTooltip translationKey='users'>
						{t('ChooseApprover')}
					</LabelWithTooltip>
					<PeoplePicker
						onChange={items => onUserInputChange(items ?? [])}
						itemLimit={1}
						excludeCurrentUser
						roles={[UserRole.Vko]}
					/>
				</>
			)}
			<br />

			<DialogFooter>
				<PrimaryButton
					onClick={onClearingClick}
					disabled={
						confirmDisabled ||
						changeType === ChangeType.NONE ||
						(changeType === ChangeType.CONTENT && users?.length < 1)
					}
				>
					{t('Update')}
				</PrimaryButton>
				<DefaultButton onClick={onDismiss} text={t('CancelButton')} />
			</DialogFooter>
		</Dialog>
	);
};
