import {EntityPage} from 'components';
import {ViewAuthorizer} from 'components/ViewAuthorizer';
import {LoadWrapper} from 'components/LoadWrapper';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {DriveVariant, UserRole} from 'types';
import {
	GetAllDriveVariantsDocument,
	useCreateDriveVariantMutation,
	useDeleteDriveVariantMutation,
	useGetAllDriveVariantsQuery,
	useUpdateDriveVariantMutation,
} from './hooks/driveVariants.generated';
import {DriveVariantsTooltipTranslationProvider} from 'features/localizedTooltips';

const AdminDriveVariantsPage: React.FC = () => {
	const {t} = useTranslation('features/adminsection', {
		keyPrefix: 'AdminDriveVariantsPage',
	});

	const {loading, data} = useGetAllDriveVariantsQuery();
	const [createDriveVariantMutation] = useCreateDriveVariantMutation();
	const [updateDriveVariantMutation] = useUpdateDriveVariantMutation();
	const [deleteDriveVariantMutation] = useDeleteDriveVariantMutation();

	const driveVariants = React.useMemo(() => data?.driveVariants ?? [], [data]);

	const refetchQueries = [GetAllDriveVariantsDocument];

	const createDriveVariant = React.useCallback((driveVariant: DriveVariant) => {
		createDriveVariantMutation({
			variables: {
				input: {
					name: driveVariant.name,
				},
			},
			refetchQueries,
		});
	}, []);

	const updateDriveVariant = React.useCallback((driveVariant: DriveVariant) => {
		updateDriveVariantMutation({
			variables: {
				input: {id: driveVariant.id, name: driveVariant.name},
			},
			refetchQueries,
		});
	}, []);

	const deleteDriveVariant = React.useCallback((id: string) => {
		deleteDriveVariantMutation({
			variables: {
				input: {id},
			},
			refetchQueries,
		});
	}, []);

	return (
		<ViewAuthorizer roles={[UserRole.SystemAdministrator]}>
			<LoadWrapper loading={loading}>
				<DriveVariantsTooltipTranslationProvider>
					<EntityPage
						items={driveVariants}
						entityDisplayName={t('DriveVariants')}
						createEntity={createDriveVariant}
						updateEntity={updateDriveVariant}
						deleteEntity={deleteDriveVariant}
					/>
				</DriveVariantsTooltipTranslationProvider>
			</LoadWrapper>
		</ViewAuthorizer>
	);
};

export default AdminDriveVariantsPage;
