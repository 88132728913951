import {
	DefaultButton,
	Dialog,
	DialogFooter,
	DialogType,
	IDialogContentProps,
} from '@fluentui/react';
import React from 'react';
import {PdfViewer} from '../../../../../components/PdfViewer/PdfViewer';
import {useParagraphsListContext} from '../ParagraphsList.context';

export const ViewPDFDialog: React.FC = props => {
	const dialogContentProps: IDialogContentProps = React.useMemo(
		() => ({
			type: DialogType.normal,
		}),
		[],
	);

	const {showPDFDialog, setShowPDFDialog, uriPDFDoc} =
		useParagraphsListContext();

	const [pdfHeight, setPDFHeight] = React.useState<number>(
		window.innerHeight * 0.76,
	);

	const onDismiss = React.useCallback(() => {
		setShowPDFDialog(false);
	}, []);

	React.useEffect(() => {
		const handleResize = () => setPDFHeight(window.innerHeight * 0.76);
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [showPDFDialog]);

	return (
		<Dialog
			hidden={!showPDFDialog}
			onDismiss={onDismiss}
			dialogContentProps={dialogContentProps}
			modalProps={{
				isBlocking: true,
				styles: {
					main: {
						selectors: {
							'@media (min-width: 0px)': {
								height: '90%',
								maxHeight: '95%',
								maxWidth: '95%',
								width: '90%',
							},
						},
					},
				},
			}}
		>
			<div style={{height: pdfHeight}}>
				<PdfViewer url={uriPDFDoc} />
			</div>
		</Dialog>
	);
};
