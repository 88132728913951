import {
	ContextualMenu,
	DirectionalHint,
	IContextualMenuItem,
	IDetailsRowProps,
	IRenderFunction,
} from '@fluentui/react';
import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {CreateEntityHref} from '../EntityList.types';

export type DefaultRenderer = IRenderFunction<IDetailsRowProps>;

interface Props {
	props: IDetailsRowProps;
	renderDefault: DefaultRenderer;
	createEntityHref: CreateEntityHref;
}

export const getIfIsAnchor = (element: Partial<HTMLElement>): boolean => {
	type PossibleTagName = string | undefined;
	const tagName: PossibleTagName = element.tagName?.toLowerCase();
	return tagName === 'a';
};

function RowWithContextualMenu({
	props: propsFromRenderer,
	renderDefault,
	createEntityHref,
}: Props): JSX.Element {
	const [isVisible, setIsVisible] = useState<boolean>(false);
	const [mouseEvent, setMouseEvent] = useState<React.MouseEvent | null>(null);
	const {t} = useTranslation('components/EntityList/RowWithContextualMenu');

	const close = useCallback((): void => {
		setIsVisible(false);
	}, []);

	const handleContextMenuEvent = useCallback(
		(event: React.MouseEvent): void => {
			event.preventDefault();
			setIsVisible(true);
			setMouseEvent(event);
		},
		[],
	);

	const createEntityHrefUsingItem = useCallback((): string => {
		return createEntityHref(propsFromRenderer.item);
	}, [propsFromRenderer, createEntityHref]);

	const windowTarget = '_blank';

	const openEntityDetailsPageInNewTab = useCallback((): void => {
		const href: string = createEntityHrefUsingItem();
		window.open(href, windowTarget);
	}, [createEntityHrefUsingItem]);

	const getIfIsMiddleBtn = (event: React.MouseEvent): boolean => {
		return event.button === 1;
	};

	const getIfClickedOnAnchor = ({
		target: targetWithoutType,
	}: React.MouseEvent): boolean => {
		const target: Partial<HTMLElement> = targetWithoutType;
		return getIfIsAnchor(target);
	};

	const getIfShouldOpenInNewTab = useCallback(
		(event: React.MouseEvent): boolean => {
			const middleBtnClicked: boolean = getIfIsMiddleBtn(event);
			const clickedOnAnchor: boolean = getIfClickedOnAnchor(event);
			/**
			 * Sometimes columns will have links. If the user clicked on a link with the
			 * middle btn, it would open the entity's page and that link. To prevent
			 * that, we check that the user didn't click on an anchor.
			 */
			return middleBtnClicked && !clickedOnAnchor;
		},
		[],
	);

	const openInNewTabIfMiddleBtnClicked = useCallback(
		(event: React.MouseEvent): void | boolean => {
			const openNewTab: boolean = getIfShouldOpenInNewTab(event);
			if (!openNewTab) return;
			event.preventDefault();
			openEntityDetailsPageInNewTab();
		},
		[getIfShouldOpenInNewTab, openEntityDetailsPageInNewTab],
	);

	const preventMiddleBtnFromEnablingScrolling = useCallback(
		(event: React.MouseEvent): boolean | void => {
			const middleBtnClicked: boolean = getIfIsMiddleBtn(event);
			if (!middleBtnClicked) return;
			event.preventDefault();
			/**
			 * This prevents the middle btn from enabling scrolling
			 */
			return false;
		},
		[],
	);

	const getPropsOfDefaultRow = useCallback((): IDetailsRowProps => {
		return {
			...propsFromRenderer,
			focusZoneProps: {
				...propsFromRenderer.focusZoneProps,
				onContextMenu: handleContextMenuEvent,
				onMouseUp: openInNewTabIfMiddleBtnClicked,
				onMouseDown: preventMiddleBtnFromEnablingScrolling,
			},
		};
	}, [
		propsFromRenderer,
		handleContextMenuEvent,
		openInNewTabIfMiddleBtnClicked,
		preventMiddleBtnFromEnablingScrolling,
	]);

	type RenderedRow = JSX.Element | null;

	const createPropsAndRenderRow = (): RenderedRow => {
		const props: IDetailsRowProps = getPropsOfDefaultRow();
		return renderDefault(props);
	};

	const defaultRow: RenderedRow = createPropsAndRenderRow();

	const menuItems = useMemo((): IContextualMenuItem[] => {
		return [
			{
				key: 'openInNewTab',
				text: t('BtnToOpenInNewTab'),
				target: windowTarget,
				href: createEntityHrefUsingItem(),
			},
		];
	}, [t, createEntityHrefUsingItem]);

	return (
		<>
			{defaultRow}
			<ContextualMenu
				hidden={!isVisible}
				items={menuItems}
				target={mouseEvent ? mouseEvent.nativeEvent : null}
				onDismiss={close}
				directionalHint={DirectionalHint.bottomLeftEdge}
				gapSpace={16}
			/>
		</>
	);
}

export const getRenderRowWithContextualMenu = (
	createEntityHref: Props['createEntityHref'],
): DefaultRenderer => {
	// eslint-disable-next-line react/display-name
	return (props, renderDefault) => {
		/**
		 * We need the renderer's default props because they contain some required
		 * properties.
		 */
		if (!props || !renderDefault) return null;
		return (
			<RowWithContextualMenu
				props={props}
				renderDefault={renderDefault}
				createEntityHref={createEntityHref}
			/>
		);
	};
};
