import {
	ActionButton,
	Checkbox,
	IButtonStyles,
	ICheckboxStyles,
	IDropdownOption,
	IImageStyles,
	IRawStyle,
	IStackStyles,
	IStyle,
	IconButton,
	Image,
	Stack,
	Theme,
	memoizeFunction,
	mergeStyleSets,
	mergeStyles,
	useTheme,
} from '@fluentui/react';
import {useBoolean} from '@fluentui/react-hooks';
import {HierarchyListIndicator, TableComponent} from 'components';
import {useEditorSelectionContext} from 'features/RegulatoryDocuments/context';
import {usePdfPageContext} from 'features/RegulatoryDocuments/context/usePdfPageContext';
import {useEditorCommands} from 'features/RegulatoryDocuments/hooks';
import {parse} from 'papaparse';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Editor, NodeEntry, Transforms, Node, Range} from 'slate';
import {
	Editable,
	ReactEditor,
	RenderElementProps,
	RenderLeafProps,
	useSlate,
} from 'slate-react';
import Tesseract from 'tesseract.js';
import {ParagraphElementType, TextPosition} from 'types';
import {v4 as uuidv4} from 'uuid';
import {useEditorContext} from './EditorContext';
import {DocumentTextLine} from './slate';
import {ApplyOcrDialog, DeleteImageDialog} from './components/Dialogs';
import {TableImageSplitter} from './components/TableImageSplitter';
import {HtmlTableEditor} from './components/HtmlTableEditor';
import {documentEditorService} from './DocumentEditor.service';
import {NonSelectable} from './NonSelectable';

/**
 * These variables allow the user to select paragraphs' text more easily
 */
const extraTextSpacing = '0.5rem';
const headingMarginBottom = '0.75rem';

const PageLine = ({page}: {page: number}) => {
	const theme = useTheme();
	const classNames = getClassNames(theme);

	return (
		<NonSelectable className={classNames.pageLine}>
			<strong>{page}</strong>
		</NonSelectable>
	);
};

const stylesOfContainerOfHeadingInMergedView: IStackStyles = {
	root: {marginBottom: `calc(${headingMarginBottom} + ${extraTextSpacing})`},
};

/**
 * * Implementation notes
 *
 * To allow the user to select text correctly, we must add certain properties to
 * HTML elements (such as images, non-editable headings, etc) that should not be
 * editable through the text editor. Specifically, we must set contentEditable
 * to false and style the element with "user-select: none". See
 * https://github.com/ianstormtaylor/slate/issues/3421#issuecomment-573326794 if
 * you want to learn more.
 *
 * You should also note that custom components within leaf/element renderers are
 * not necessarily supported. To learn why, see
 * https://github.com/ianstormtaylor/slate/issues/3421#issuecomment-866700294.
 * If the user selects a custom component when selecting text, the selection
 * might not work correctly. To prevent this, wrap custom components with @see
 * NonSelectable in renderers.
 *
 * Note that we must render leaves' children to prevent bugs.
 *
 * Tip: A good way to test if text selection works correctly is to select all of
 * the editor's text (CTRL/CMD + A) and see if pressing BACKSPACE deletes all of
 * the text.
 */
export const EditorContent: React.FC = () => {
	const {t} = useTranslation('features/regulatorydocuments', {
		keyPrefix: 'DocumentEditor.EditorContent',
	});
	const editor = useSlate();
	const theme = useTheme();
	const classNames = getClassNames(theme);
	const {isMergedView, deleteParagraphElement} = useEditorContext();

	const {setSelectedTextLine} = useEditorSelectionContext();
	const {currentPage} = usePdfPageContext();

	const [TableImageSplitterState, setTableImageSplitterState] = React.useState({
		isOpen: false,
		imgSrc: '',
		textHTML: '',
		leaf: undefined as DocumentTextLine | undefined,
		tableNodePath: [],
		assetId: '',
	});

	const [HtmlTableEditorState, setHtmlTableEditorState] = React.useState({
		isOpen: false,
		htmlTableText: '',
		leaf: undefined as DocumentTextLine | undefined,
		tableNodePath: [],
		assetId: '',
	});

	const findFirstNodeAndScrollItIntoView = React.useCallback((): void => {
		type NodeGenerator = Generator<NodeEntry<Node>, void, undefined>;

		const findNodesOnCurrentPage = (): NodeGenerator => {
			return Editor.nodes(editor, {
				at: [],
				match: (node, path) =>
					(node as any).page === currentPage && path.length === 1,
				mode: 'highest',
			});
		};

		type PossibleNodeEntry = void | NodeEntry<Node>;

		const findEntryOfFirstNodeOnPage = (): PossibleNodeEntry => {
			const nodeGenerator: NodeGenerator = findNodesOnCurrentPage();
			return nodeGenerator.next().value;
		};

		const tryScrollingNodeIntoView = (
			entryOfFirstNodeOnPage: PossibleNodeEntry,
		): void => {
			const {scrollNodeIntoViewIfPossible} = documentEditorService;
			/**
			 * We catch errors here because that's what the original code did before
			 * I refactored it.
			 */
			try {
				scrollNodeIntoViewIfPossible(editor, entryOfFirstNodeOnPage);
			} catch (error: unknown) {
				console.error(error);
			}
		};

		const entryOfFirstNodeOnPage: PossibleNodeEntry =
			findEntryOfFirstNodeOnPage();
		tryScrollingNodeIntoView(entryOfFirstNodeOnPage);
	}, [currentPage, editor]);

	/**
	 * We're only using the dependency currentPage here because we only want it to
	 * run when the currentPage changes, and not when any other dependencies
	 * change.
	 */
	React.useEffect(findFirstNodeAndScrollItIntoView, [currentPage]);

	const [imgToDelete, setImgToDelete] = React.useState<string | undefined>(
		undefined,
	);
	const [hideDeleteImageDialog, {toggle: toggleHideDeleteImageDialog}] =
		useBoolean(true);

	const [imgToOcr, setImgtoOcr] = React.useState<{
		img?: HTMLImageElement;
		leaf?: DocumentTextLine;
	}>({img: undefined, leaf: undefined});
	const [ocrLanguage, setOcrLanguage] = React.useState('eng');
	const [hideOcrDialog, {toggle: toggleHideOcrDialog}] = useBoolean(true);

	React.useEffect(() => {
		if (editor.selection) {
			const node = Editor.node(editor, editor.selection.anchor.path);
			const nodeData = node[0] as DocumentTextLine;
			setSelectedTextLine({
				page: nodeData.page || 1,
				bounds: nodeData.bounds || [],
			});
		}
	}, [editor.selection]);

	const onCheckboxChange = React.useCallback(
		(leaf: any) => (_: any, checked: boolean | undefined) => {
			const leafNode = Editor.nodes(editor, {
				at: [],
				match: node => (node as any)._guid === leaf._guid,
				mode: 'lowest',
			}).next().value;

			if (leafNode) {
				Transforms.setNodes(editor, {selected: Boolean(checked)} as any, {
					at: leafNode[1],
				});
				if (checked) {
					const leafNodeData: any = leafNode[0];
					setSelectedTextLine({
						page: leafNodeData.page,
						bounds: leafNodeData.bounds || [],
					});
				}
			}

			ReactEditor.deselect(editor);
		},
		[editor],
	);

	const onOcrLanguageChange = React.useCallback(
		(_ev: any, option?: IDropdownOption) => {
			if (option) {
				setOcrLanguage(`${option.key}`);
			}
		},
		[],
	);

	const onApplyOcrClick = React.useCallback(async () => {
		const {img, leaf} = imgToOcr;

		if (img && leaf) {
			const tableNodePath = (
				Editor.nodes(editor, {
					at: [],
					match: (node, _) => (node as any)._guid === leaf._guid,
					mode: 'lowest',
				}).next().value as any
			)[1];

			await Tesseract.recognize(img, ocrLanguage, {
				logger: m => console.log(m),
			}).then(({data: {lines}}) => {
				Transforms.removeNodes(editor, {at: tableNodePath});
				lines.forEach((line, i) => {
					const {text} = line;
					Transforms.insertNodes(
						editor,
						{
							type: ParagraphElementType.Text,
							_guid: uuidv4(),
							isHeader: false,
							page: leaf.page,
							selected: false,
							text,
							bounds: leaf.bounds,
						} as DocumentTextLine,
						{
							at: [tableNodePath[0], tableNodePath[1] + i],
						},
					);
				});
			});
		}
	}, [ocrLanguage, imgToOcr]);

	const changeTableToText = React.useCallback(
		(leaf: DocumentTextLine) => {
			const tableNodePath = (
				Editor.nodes(editor, {
					at: [],
					match: (node, _) => (node as any)._guid === leaf._guid,
					mode: 'lowest',
				}).next().value as any
			)[1];

			if (leaf.csvContent) {
				const lines = parse(leaf.csvContent.trim(), {
					skipEmptyLines: true,
				}).data;
				Transforms.removeNodes(editor, {at: tableNodePath});
				lines.forEach((line: any, i) => {
					const text = (line as string[]).map(t => t.trim()).join(' ');
					Transforms.insertNodes(
						editor,
						{
							type: ParagraphElementType.Text,
							_guid: uuidv4(),
							isHeader: false,
							page: leaf.page,
							selected: false,
							text,
							bounds: leaf.bounds,
						} as DocumentTextLine,
						{
							at: [tableNodePath[0], tableNodePath[1] + i],
						},
					);
				});
			}
		},
		[editor],
	);

	const showHtmlTableEditor = React.useCallback(
		(leaf: DocumentTextLine) => {
			const tableNodePath = (
				Editor.nodes(editor, {
					at: [],
					match: (node, _) => (node as any)._guid === leaf._guid,
					mode: 'lowest',
				}).next().value as any
			)[1];

			setHtmlTableEditorState({
				isOpen: true,
				htmlTableText: leaf.text,
				leaf,
				tableNodePath,
				assetId: leaf.assetId ?? '',
			});
		},
		[setHtmlTableEditorState],
	);

	const showTableImageSplitter = React.useCallback(
		(leaf: DocumentTextLine) => {
			const tableNodePath = (
				Editor.nodes(editor, {
					at: [],
					match: (node, _) => (node as any)._guid === leaf._guid,
					mode: 'lowest',
				}).next().value as any
			)[1];

			setTableImageSplitterState({
				isOpen: true,
				imgSrc: leaf.asset?.uri ?? '',
				textHTML: leaf.text,
				leaf,
				tableNodePath,
				assetId: leaf.assetId ?? '',
			});
		},
		[setTableImageSplitterState],
	);

	const renderImageOrTable = (
		leaf: DocumentTextLine,
		attributes: any,
		children: any,
	) => {
		const onConvertClick = () => changeTableToText(leaf);
		const onDeleteClick = () => {
			setImgToDelete(leaf._guid);
			toggleHideDeleteImageDialog();
		};

		const onEditHtmlTable = () => {
			showHtmlTableEditor(leaf);
			onCheckboxChange(leaf);
		};

		const onSplitTableImage = () => {
			showTableImageSplitter(leaf);
			onCheckboxChange(leaf);
		};

		const onOcrClick = () => {
			const uri = leaf.asset?.uri;

			if (!uri) {
				return;
			}

			const img = document.createElement('img');
			img.crossOrigin = 'anonymous';
			img.onload = () => {
				setImgtoOcr({
					img,
					leaf,
				});
				toggleHideOcrDialog();
			};

			const cacheBustedUrl = `${uri}?cache_bust=${Date.now()}`;
			img.src = cacheBustedUrl;
		};

		return (
			<div {...attributes} className={classNames.nonTextContainer}>
				<Stack horizontal>
					<NonSelectable>
						<Checkbox
							checked={leaf.selected}
							onChange={onCheckboxChange(leaf)}
							styles={getCheckboxStyles(false)}
						/>
					</NonSelectable>
					<NonSelectable className={classNames.imageContainer}>
						<div className={tableButtonsWrapperStyle}>
							<IconButton
								iconProps={{iconName: 'Delete'}}
								onClick={onDeleteClick}
							/>
							<Stack horizontal tokens={{childrenGap: 2}}>
								{leaf.csvContent && (
									<ActionButton
										iconProps={{iconName: 'InsertTextBox'}}
										styles={imageIconStyles}
										onClick={onConvertClick}
									>
										{t('ConvertToText')}
									</ActionButton>
								)}
								{(leaf.type === ParagraphElementType.Image ||
									(leaf.type === ParagraphElementType.Table &&
										!leaf.csvContent)) && (
									<ActionButton
										iconProps={{iconName: 'InsertTextBox'}}
										styles={imageOcrIconStyles}
										onClick={onOcrClick}
									>
										{t('ApplyOcr')}
									</ActionButton>
								)}

								{leaf.type === ParagraphElementType.HtmlTable && (
									<ActionButton
										iconProps={{iconName: 'Edit'}}
										styles={imageIconStyles}
										onClick={onEditHtmlTable}
									>
										{t('EditHtmlTable')}
									</ActionButton>
								)}

								<ActionButton
									iconProps={{iconName: 'Cut'}}
									styles={imageIconStyles}
									onClick={onSplitTableImage}
								>
									{t('SplitTableOrImage')}
								</ActionButton>
							</Stack>
						</div>
						<Image src={leaf.asset?.uri} styles={imageStyles} />
						{leaf.type === ParagraphElementType.HtmlTable && (
							<TableComponent htmlTable={leaf.text} />
						)}
						<div className={classNames.hiddenChildren}>fdfd{children}</div>
					</NonSelectable>
				</Stack>
			</div>
		);
	};

	const renderNewLine = (
		leaf: DocumentTextLine,
		attributes: any,
		children: any,
	) => {
		return (
			<div {...attributes}>
				<div className={classNames.flex}>
					<NonSelectable>
						<Checkbox
							checked={leaf.selected}
							onChange={onCheckboxChange(leaf)}
							styles={getCheckboxStyles(false)}
						/>
					</NonSelectable>
					<strong className={strongTextStyle}>{children}</strong>
				</div>
			</div>
		);
	};

	const renderNewLineMerged = (attributes: any, children: any) => {
		return (
			<div {...attributes}>
				<NonSelectable>
					<div className={classNames.hiddenChildren}>{children}</div>
					<br />
				</NonSelectable>
			</div>
		);
	};

	const renderLeafText = (
		children: any,
		textPosition?: TextPosition | null,
	) => {
		switch (textPosition) {
			case TextPosition.Subscript:
				return <sub>{children}</sub>;
			case TextPosition.Superscript:
				return <sup>{children}</sup>;

			default:
				return children;
		}
	};

	const renderHeaderContents = (
		isFootnoteHeader: boolean,
		children: RenderLeafProps['children'],
	): JSX.Element => {
		if (!isFootnoteHeader) return children;
		return (
			<div>
				<span>{t('Footnote')}</span>
				{/* We render this to prevent the editor from crashing when the user
				selects right above the footnote and there is an empty paragraph above it.
				*/}
				<div className={classNames.invisibleChildren} aria-hidden='true'>
					{children}
				</div>
			</div>
		);
	};

	const renderHeader = (
		isFootnoteHeader: boolean,
		children: RenderLeafProps['children'],
	): JSX.Element => {
		return (
			<NonSelectable className={classNames.headerText}>
				{renderHeaderContents(isFootnoteHeader, children)}
			</NonSelectable>
		);
	};

	const renderLeafMerged = ({attributes, leaf, children}: RenderLeafProps) => {
		const isFootnoteHeader = leaf.isHeader && children.props.parent.isFootnote;

		return (
			<span {...attributes}>
				{leaf.isHeader ? (
					<Stack horizontal styles={stylesOfContainerOfHeadingInMergedView}>
						<NonSelectable>
							<Checkbox
								checked={leaf.selected}
								onChange={onCheckboxChange(leaf)}
								styles={getCheckboxStyles(true)}
							/>
						</NonSelectable>
						{renderHeader(isFootnoteHeader, children)}
					</Stack>
				) : (
					<span>{renderLeafText(children, leaf.textPosition)}</span>
				)}
			</span>
		);
	};

	const renderLeafLines = ({attributes, leaf, children}: RenderLeafProps) => {
		const isFootnoteHeader = leaf.isHeader && children.props.parent.isFootnote;

		return (
			<div {...attributes} className={classNames.textContainer}>
				<div className={classNames.flex}>
					<NonSelectable>
						<Checkbox
							checked={leaf.selected}
							onChange={onCheckboxChange(leaf)}
							styles={getCheckboxStyles(leaf.isHeader)}
						/>
					</NonSelectable>
					{leaf.isHeader ? (
						renderHeader(isFootnoteHeader, children)
					) : (
						<div className={leafTextStyleDiv}>
							{renderLeafText(children, leaf.textPosition)}
						</div>
					)}
				</div>
			</div>
		);
	};

	const renderElement = React.useCallback(
		(props: RenderElementProps) => {
			const renderElementMerged = ({
				attributes,
				children,
				element,
			}: RenderElementProps) => {
				return (
					<div
						{...attributes}
						className={getMergedElementClassName(element.isFootnote, theme)}
					>
						{element.isNewPage && <PageLine page={element.page || 1} />}
						<Stack horizontal>
							<HierarchyListIndicator
								level={element.level}
								horizontalLineY={25}
							/>
							<Stack styles={elementChildrenStackStyles}>{children}</Stack>
						</Stack>
					</div>
				);
			};

			const renderElementLines = ({
				attributes,
				children,
				element,
			}: RenderElementProps) => {
				return (
					<div
						{...attributes}
						className={getElementClassName(element.isFootnote, theme)}
					>
						{element.isNewPage && <PageLine page={element.page || 1} />}
						<div className={classNames.flex}>
							<HierarchyListIndicator
								level={element.level}
								horizontalLineY={25}
							/>
							<Stack styles={elementChildrenStackStyles}>
								{children.map((c: any) => (
									<div
										key={c.props.text._guid}
										className={getLineStyle(c.props.text.isHeader, theme)}
									>
										{c}
									</div>
								))}
							</Stack>
						</div>
					</div>
				);
			};

			if (isMergedView) {
				return renderElementMerged(props);
			}

			return renderElementLines(props);
		},
		[isMergedView, classNames.flex, theme],
	);

	const renderLeaf = React.useCallback(
		({leaf, attributes, children, text}: RenderLeafProps) => {
			const {type} = leaf;

			/**
			 * We must provide the attributes so that Slate can identify the children.
			 * Otherwise, certain features, such as deleting text, might not work
			 * correctly.
			 */
			const wrapChildren = (): JSX.Element => {
				return <div {...attributes}>{children}</div>;
			};

			switch (type) {
				case ParagraphElementType.Text: {
					// Fallback for older imports
					if (leaf.asset) {
						return renderImageOrTable(leaf, attributes, children);
					}

					if (isMergedView) {
						return renderLeafMerged({leaf, attributes, children, text});
					}

					return renderLeafLines({leaf, attributes, children, text});
				}

				case ParagraphElementType.Image: {
					const uri = leaf.asset?.uri;

					if (uri) {
						return renderImageOrTable(leaf, attributes, children);
					}

					return wrapChildren();
				}

				case ParagraphElementType.Table: {
					return renderImageOrTable(leaf, attributes, children);
				}

				case ParagraphElementType.HtmlTable: {
					return renderImageOrTable(leaf, attributes, children);
				}

				case ParagraphElementType.NewLine: {
					if (isMergedView) {
						return renderNewLineMerged(attributes, children);
					}

					return renderNewLine(leaf, attributes, children);
				}

				default:
					return wrapChildren();
			}
		},
		[isMergedView],
	);

	const onDeleteImageClick = React.useCallback(() => {
		deleteParagraphElement(editor, imgToDelete);
		toggleHideDeleteImageDialog();
	}, [editor, imgToDelete]);

	const {
		declareHeader,
		disableDeclareHeader,
		removeHeader,
		disableRemoveHeader,
		declareFootnote,
		disableDeclateFootnote,
		splitText,
		disableSplitText,
		mergeLines,
		disableMergeLines,
		decrementLevel,
		incrementLevel,
		disableLevelChange,
		toSubscript,
		toSuperscript,
		resetTextPosition,
		disableTextPositionChange,
		moveElementUp,
		moveElementDown,
		disableMoveElement,
		addNewLine,
		disableAddNewLine,
		addTextline,
		disableAddTextline,
		resetSelection,
		cursorIsInNewLine,
	} = useEditorCommands(editor);

	const onEditorKeyDown = React.useCallback(
		(event: React.KeyboardEvent<HTMLDivElement>) => {
			switch (event.key) {
				case 'Enter': {
					event.preventDefault();
					if (!disableSplitText) {
						splitText();
						return;
					}

					if (event.shiftKey) {
						if (!disableAddTextline) {
							addTextline(false);
							return;
						}
					}

					if (!disableAddNewLine) {
						addNewLine();
					}

					break;
				}

				case 'Backspace': {
					const {selection} = editor;

					/**
					 * Prevents the user from trying to delete the block, which is not
					 * supported behavior, by pressing the backspace key at the beginning
					 * of the text. If we do not do this, the wrong text will be deleted.
					 */
					if (selection?.anchor.offset === 0 && Range.isCollapsed(selection)) {
						event.preventDefault();
					}

					if (cursorIsInNewLine) {
						return;
					}

					break;
				}

				case 'Tab': {
					event.preventDefault();
					const tabCharacter = '\u0009'.toString();
					Transforms.insertText(editor, tabCharacter);

					break;
				}

				default:
			}

			if (cursorIsInNewLine) {
				event.preventDefault();
			}
		},
		[editor, disableSplitText, disableAddNewLine],
	);

	const onKeyDown = React.useCallback(
		(event: React.KeyboardEvent<HTMLDivElement>) => {
			if (event.key === 'Escape') {
				event.preventDefault();
				resetSelection();
			}

			if (event.key === 'Tab' && !disableLevelChange) {
				event.preventDefault();
				if (event.getModifierState('Shift')) {
					decrementLevel();
				} else {
					incrementLevel();
				}
			}

			if (event.getModifierState('Control')) {
				if (event.shiftKey) {
					if (!disableTextPositionChange) {
						if (event.key === 'B') {
							event.preventDefault();
							toSubscript();
						}

						if (event.key === 'P') {
							event.preventDefault();
							toSuperscript();
						}

						if (event.key === 'T') {
							event.preventDefault();
							resetTextPosition();
						}
					}

					if (!disableMoveElement) {
						if (event.key === 'ArrowUp') {
							event.preventDefault();
							moveElementUp();
						}

						if (event.key === 'ArrowDown') {
							event.preventDefault();
							moveElementDown();
						}
					}
				}

				switch (event.key) {
					case 'a': {
						if (!disableDeclareHeader) {
							event.preventDefault();
							declareHeader();
						}

						break;
					}

					case 'f': {
						if (!disableDeclateFootnote) {
							event.preventDefault();
							declareFootnote();
						}

						break;
					}

					case 'z': {
						if (!disableMergeLines) {
							event.preventDefault();
							mergeLines();
						}

						break;
					}

					case 'q': {
						if (!disableRemoveHeader) {
							event.preventDefault();
							removeHeader();
						}

						break;
					}

					default:
				}
			}
		},
		[
			disableDeclareHeader,
			disableRemoveHeader,
			disableMergeLines,
			disableMoveElement,
			disableTextPositionChange,
		],
	);

	const onTableImageSplitterDismiss = React.useCallback(() => {
		setTableImageSplitterState({
			leaf: undefined,
			imgSrc: '',
			textHTML: '',
			isOpen: false,
			tableNodePath: [],
			assetId: '',
		});
	}, []);

	const onHtmlTableEditorDismiss = React.useCallback(() => {
		setHtmlTableEditorState({
			leaf: undefined,
			htmlTableText: '',
			isOpen: false,
			tableNodePath: [],
			assetId: '',
		});
	}, []);

	return (
		<div>
			<TableImageSplitter
				{...TableImageSplitterState}
				onDismiss={onTableImageSplitterDismiss}
			/>
			<HtmlTableEditor
				editable={true}
				{...HtmlTableEditorState}
				onDismiss={onHtmlTableEditorDismiss}
			/>

			<ApplyOcrDialog
				hidden={hideOcrDialog}
				onDismiss={toggleHideOcrDialog}
				onConvertImageClick={onApplyOcrClick}
				onLanguageChange={onOcrLanguageChange}
			/>

			<DeleteImageDialog
				hidden={hideDeleteImageDialog}
				onDismiss={toggleHideDeleteImageDialog}
				onDeleteImageClick={onDeleteImageClick}
			/>
			<div onKeyDown={onKeyDown}>
				<Editable
					renderElement={renderElement}
					renderLeaf={renderLeaf}
					onKeyDown={onEditorKeyDown}
				/>
			</div>
		</div>
	);
};

const getClassNames = (theme: Theme) => {
	return mergeStyleSets({
		imageContainer: {
			position: 'relative',
		},
		headerText: {
			fontWeight: 'bold',
		},
		pageLine: {
			borderTop: `1px solid ${theme.palette.neutralPrimary}`,
			textAlign: 'right',
			marginBottom: 10,
		},
		hiddenChildren: {
			display: 'none',
		},
		flex: {display: 'flex'},
		textContainer: {
			padding: `${extraTextSpacing} 0`,
		},
		nonTextContainer: {
			/**
			 * This does not give users more room to select paragraphs' text. However,
			 * the UI looks more consistent this way.
			 */
			margin: `1rem 0`,
		},
		/**
		 * This is necessary to avoid the cursor jumping to the top when the user
		 * clicks on an empty space above the children and the user is not already
		 * at the top of the page.
		 */
		invisibleChildren: {
			width: 0,
			height: 0,
			visibility: 'hidden',
		},
	});
};

const getCheckboxStyles = memoizeFunction((isHeader: boolean) => {
	const lineCheckbox: IStyle = {
		visibility: 'hidden',
		'[data-slate-leaf]:hover &': {
			visibility: 'visible',
		},
		'.is-checked &': {
			visibility: 'visible',
		},
	};
	const headerCheckbox: IStyle = {
		visibility: 'visible',
	};
	return {
		root: {
			margin: 4,
		},
		checkbox: {
			borderRadius: '50%',
			...(isHeader ? headerCheckbox : lineCheckbox),
		},
	} as ICheckboxStyles;
});

const getElementClassName = memoizeFunction(
	(isFootnote: boolean, theme: Theme) =>
		mergeStyles({
			background: isFootnote ? theme.palette.neutralLight : undefined,
		}),
);

const getMergedElementClassName = memoizeFunction(
	(isFootnote: boolean, theme: Theme) =>
		mergeStyles({
			padding: '5px 7px',
			fontSize: 14,
			background: isFootnote ? theme.palette.neutralLight : undefined,
		}),
);

const getLineStyle = memoizeFunction((isHeader: boolean, theme: Theme) => {
	const baseStyles: IRawStyle = {
		borderTop: isHeader ? undefined : `1px solid ${theme.palette.neutralLight}`,
		padding: '5px 7px',
		alignSelf: 'center',
		fontSize: 14,
	};

	if (isHeader) baseStyles.marginBottom = headingMarginBottom;

	return mergeStyles(baseStyles);
});

const elementChildrenStackStyles: IStackStyles = {
	root: {
		display: 'block',
		marginBottom: 20,
		width: '100%',
	},
};

const strongTextStyle = mergeStyles({
	display: 'flex',
	alignItems: 'center',
});
const imageIconStyles: IButtonStyles = {
	root: {
		background: 'white',
		border: '1px solid black',
		marginLeft: 8,
		opacity: 0.5,
		':hover': {
			opacity: 1,
		},
	},
};

const imageOcrIconStyles: IButtonStyles = {
	root: {
		background: 'white',
		border: '1px solid black',
		marginLeft: 8,
		opacity: 0.5,
		':hover': {
			opacity: 1,
		},
		float: 'right',
	},
};

const tableButtonsWrapperStyle = mergeStyles({
	top: 5,
	right: 5,
	position: 'absolute',
	zIndex: 100,
	visibility: 'hidden',
	'[data-slate-leaf]:hover &': {
		visibility: 'visible',
	},
	width: '100%',
	display: 'flex',
	justifyContent: 'space-between',
});

const imageStyles: IImageStyles = {
	root: {paddingLeft: 0, zIndex: 10},
	image: {maxWidth: '100%'},
};

const leafTextStyleDiv = mergeStyles({
	display: 'flex',
	alignItems: 'center',
	/**
	 * This gives the user more room to select text.
	 */
	marginLeft: '1rem',
});
