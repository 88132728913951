import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AssignRegulatoryDocumentParagraphsTagsMutationVariables = Types.Exact<{
  input: Types.AssignRegulatoryDocumentParagraphsTagsInput;
}>;


export type AssignRegulatoryDocumentParagraphsTagsMutation = { __typename?: 'Mutation', assignRegulatoryDocumentParagraphsTags: { __typename?: 'AssignRegulatoryDocumentParagraphsTagsPayload', regulatoryDocument?: { __typename?: 'RegulatoryDocument', id: string } | null } };


export const AssignRegulatoryDocumentParagraphsTagsDocument = gql`
    mutation AssignRegulatoryDocumentParagraphsTags($input: AssignRegulatoryDocumentParagraphsTagsInput!) {
  assignRegulatoryDocumentParagraphsTags(input: $input) {
    regulatoryDocument {
      id
    }
  }
}
    `;
export type AssignRegulatoryDocumentParagraphsTagsMutationFn = Apollo.MutationFunction<AssignRegulatoryDocumentParagraphsTagsMutation, AssignRegulatoryDocumentParagraphsTagsMutationVariables>;

/**
 * __useAssignRegulatoryDocumentParagraphsTagsMutation__
 *
 * To run a mutation, you first call `useAssignRegulatoryDocumentParagraphsTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignRegulatoryDocumentParagraphsTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignRegulatoryDocumentParagraphsTagsMutation, { data, loading, error }] = useAssignRegulatoryDocumentParagraphsTagsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignRegulatoryDocumentParagraphsTagsMutation(baseOptions?: Apollo.MutationHookOptions<AssignRegulatoryDocumentParagraphsTagsMutation, AssignRegulatoryDocumentParagraphsTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignRegulatoryDocumentParagraphsTagsMutation, AssignRegulatoryDocumentParagraphsTagsMutationVariables>(AssignRegulatoryDocumentParagraphsTagsDocument, options);
      }
export type AssignRegulatoryDocumentParagraphsTagsMutationHookResult = ReturnType<typeof useAssignRegulatoryDocumentParagraphsTagsMutation>;
export type AssignRegulatoryDocumentParagraphsTagsMutationResult = Apollo.MutationResult<AssignRegulatoryDocumentParagraphsTagsMutation>;
export type AssignRegulatoryDocumentParagraphsTagsMutationOptions = Apollo.BaseMutationOptions<AssignRegulatoryDocumentParagraphsTagsMutation, AssignRegulatoryDocumentParagraphsTagsMutationVariables>;