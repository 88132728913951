import {IDialogProps} from '@fluentui/react';
import {useState, useCallback} from 'react';

export type DialogState = Pick<IDialogProps, 'hidden' | 'onDismiss'>;

export type ShowDialog = () => void;

export type HideDialog = () => void;

export type DialogInfo = [DialogState, ShowDialog, HideDialog];

export function useDialogState(initialState: DialogState = {hidden: true}) {
	const [dialogState, setDialogState] = useState<DialogState>(initialState);

	const hideDialog: HideDialog = useCallback(
		() => setDialogState({hidden: true}),
		[setDialogState],
	);

	const showDialog: ShowDialog = useCallback(
		() => setDialogState({hidden: false}),
		[setDialogState],
	);

	return [
		{...dialogState, onDismiss: hideDialog},
		showDialog,
		hideDialog,
	] as DialogInfo;
}
