import {Keywords} from 'components/Keywords/Keywords';
import {KeywordFields} from 'components/Keywords/keywordItem.types';
import React, {useCallback} from 'react';
import {Keyword} from 'types';

type MyKeywords = Keyword[] | undefined;

interface Props {
	myKeywords: MyKeywords;
	paragraphKeywords: KeywordFields[];
}

export function ParagraphListKeywords({
	myKeywords,
	paragraphKeywords,
}: Props): JSX.Element {
	const getStyles = useCallback(
		(keyword: KeywordFields) => {
			if (myKeywords?.some(k => k.id === keyword.id)) {
				return {
					root: {
						border: '1px solid black',
					},
				};
			}

			return undefined;
		},
		[myKeywords],
	);

	return <Keywords keywords={paragraphKeywords} getTextStyles={getStyles} />;
}
