import {DetailsRow, IDetailsRowStyles} from '@fluentui/react';
import React, {useMemo} from 'react';
import {RegDocsListRowRendererProps} from './RegDocsList.types';

export type RegDocsListDetailsRowStyles = Partial<IDetailsRowStyles>;

export interface RegDocsListDetailsRowProps {
	propsFromRenderer: RegDocsListRowRendererProps;
	extraStyles?: RegDocsListDetailsRowStyles;
}

export function RegDocsListDetailsRow({
	propsFromRenderer,
	extraStyles,
}: RegDocsListDetailsRowProps) {
	const styles: RegDocsListDetailsRowStyles = useMemo(() => {
		return {fields: {display: 'flex', alignItems: 'start'}, ...extraStyles};
	}, [extraStyles]);
	if (!propsFromRenderer) return null;
	return <DetailsRow {...propsFromRenderer} styles={styles} />;
}
