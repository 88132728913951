import {List, Stack, Text, TooltipHost, useTheme} from '@fluentui/react';
import {LoadWrapper} from 'components/LoadWrapper';
import {DateTimeFormat, formatDateTime} from 'i18n/localeDateFormat';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {getListClassNames} from 'styles';
import {WorkflowStatus} from 'types';
import {
	GetWorkbenchItemsVexQuery,
	useGetWorkbenchItemsVexQuery,
} from '../hooks/workbenchItems.generated';
import {WorkbenchHeader} from './Headers';

type VexWorkbenchItemsProps = {halfHeight?: boolean; inProcess?: boolean};
type VexWorkbenchItem =
	GetWorkbenchItemsVexQuery['workbenchRegulatoryDocumentParagraphs'][0];

export const VexWorkbenchItems: React.FC<VexWorkbenchItemsProps> = ({
	halfHeight = false,
	inProcess = false,
}) => {
	const {i18n} = useTranslation('common/enums', {
		keyPrefix: 'WorkflowStatus',
	});

	const {t} = useTranslation('features/dashboard', {
		keyPrefix: 'VexWorkbenchItems',
	});

	const {data, loading} = useGetWorkbenchItemsVexQuery({
		variables: {
			workflowStatus: [WorkflowStatus.Finalized],
			inProcess,
		},
	});

	const items: VexWorkbenchItem[] = React.useMemo(
		() => data?.workbenchRegulatoryDocumentParagraphs ?? [],
		[data],
	);

	const theme = useTheme();
	const classNames = getListClassNames(theme, halfHeight);

	const getDueDate = React.useCallback(
		(item: VexWorkbenchItem) => {
			let dueDate;

			if (inProcess) {
				dueDate = item.regulatoryDocument.workflow.dueDateInProcess;
			} else {
				dueDate = item.regulatoryDocument.workflow.dueDate;
			}

			return dueDate
				? formatDateTime(
						new Date(dueDate),
						i18n,
						DateTimeFormat.DateMonthWeekday,
				  )
				: '';
		},
		[inProcess],
	);

	const onRenderCell = (item?: VexWorkbenchItem): JSX.Element => {
		if (!item) {
			return <></>;
		}

		return (
			<div className={classNames.itemCell} data-is-focusable={true}>
				<div className={classNames.itemContent}>
					<Stack horizontal horizontalAlign={'space-between'}>
						<Stack>
							<Text>
								{item.regulatoryDocument.regulation?.regulationNumber ?? ''}
							</Text>
							<Link
								to={`/regulations/${item?.regulatoryDocument?.regulation?.id}/${
									item?.regulatoryDocument?.id
								}/paragraphs?filter=${inProcess ? 'inProcess' : 'new'}`}
								className={classNames.link}
							>
								{item?.regulatoryDocument?.name}
							</Link>
						</Stack>
						<TooltipHost
							content={`${item?.paragraphCount} Segmente zu bearbeiten`}
						>
							<Text className={classNames.itemCountInline}>
								{item?.paragraphCount}
							</Text>
						</TooltipHost>
					</Stack>
					<div className={classNames.itemSubtext}>
						{t('DueDate')}
						{': '}
						{getDueDate(item)}
					</div>
				</div>
			</div>
		);
	};

	return (
		<div>
			<WorkbenchHeader
				halfHeight
				title={inProcess ? t('InProcessTitle') : t('AssessResponsibilityTitle')}
				items={items.length}
			/>
			<div className={classNames.container} data-is-scrollable>
				<LoadWrapper loading={loading}>
					<List items={items} onRenderCell={onRenderCell} />
				</LoadWrapper>
			</div>
		</div>
	);
};
