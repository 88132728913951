import {
	FontIcon,
	Stack,
	Theme,
	memoizeFunction,
	mergeStyles,
	useTheme,
} from '@fluentui/react';
import React from 'react';
import {Controller} from 'react-hook-form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {v4 as uuidv4} from 'uuid';
import {ErrorMessage} from './ErrorMessage';
import {HookFormProps} from './HookFormProps';
import {LabelWithTooltip} from 'features/localizedTooltips';
import Compressor from 'compressorjs';

type ControlledRichtextEditorProps = HookFormProps & {
	label: string;
	disabled?: boolean;
	actionTags?: {
		iconName: string;
		onClick?: () => void;
	}[];
	enableImages?: boolean;
};

/**
 * It could be something different if you reset the value to something else, or
 * if the initial value is something else.
 */
export type DefaultRichTextEditorValue = undefined | string;

export type ValueCompatibleWithRichTextEditor =
	DefaultRichTextEditorValue | null;

export const ControlledRichtextEditor: React.FC<
	ControlledRichtextEditorProps
> = ({disabled = false, ...props}) => {
	const theme = useTheme();
	const className = getClassName(theme, disabled);

	const quillRef = React.useRef<ReactQuill>(null);

	const imageHandler = React.useCallback(() => {
		const input = document.createElement('input');
		input.setAttribute('type', 'file');
		input.setAttribute('accept', 'image/jpeg, image/png');
		input.click();

		input.onchange = () => {
			const file = input.files?.[0];
			if (!file || !props.enableImages) {
				return;
			}

			const onFileCompressed = (blobResult: File | Blob) => {
				const reader = new FileReader();
				reader.onloadend = () => {
					if (!quillRef.current) {
						return;
					}

					const quill = quillRef.current.getEditor();
					const range = quill.getSelection(true);
					quill.insertEmbed(range.index, 'image', reader.result);
					quill.setSelection(range.index + 1, 0);
				};

				reader.readAsDataURL(blobResult);
			};

			(() =>
				new Compressor(file, {
					quality: 0.6,
					convertSize: 500000,
					maxWidth: 800,
					maxHeight: 800,
					success: onFileCompressed,
				}))();
		};
	}, [props.enableImages]);

	const modules = React.useMemo(
		() => ({
			toolbar: {
				container: [
					[{header: '2'}],
					['bold', 'underline', 'blockquote'],
					[{list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
					['link', ...(props.enableImages ? ['image'] : [])],
					['clean'],
				],
				handlers: {
					image: imageHandler,
				},
			},
		}),
		[imageHandler, props.enableImages],
	);

	return (
		<Controller
			name={props.name}
			control={props.control}
			rules={props.rules}
			defaultValue={props.defaultValue}
			render={({field: {onChange, onBlur, value}, fieldState: {error}}) => (
				<div id={`quillWrapper-${props.name}`}>
					<Stack horizontal tokens={{childrenGap: 5}}>
						<LabelWithTooltip
							required={Boolean(props.rules?.required)}
							translationKey={props.name}
						>
							{props.label}
						</LabelWithTooltip>
						{props.actionTags?.map(actionTag => (
							<FontIcon
								key={uuidv4()}
								iconName={actionTag.iconName}
								style={{paddingTop: 8, cursor: 'pointer'}}
								onClick={() => actionTag.onClick?.()}
							></FontIcon>
						))}
					</Stack>
					<ReactQuill
						ref={quillRef}
						readOnly={disabled}
						theme='snow'
						className={className}
						defaultValue={value || ''}
						onChange={onChange}
						modules={modules}
						onBlur={onBlur}
						bounds={`#quillWrapper-${props.name}`}
					/>
					<ErrorMessage error={error} />
				</div>
			)}
		/>
	);
};

const getClassName = memoizeFunction((theme: Theme, disabled: boolean) => {
	return mergeStyles({
		border: disabled ? 'none' : `1px solid ${theme.palette.neutralSecondary}`,
		background: disabled ? theme.palette.neutralLighter : undefined,
		color: disabled ? theme.palette.neutralTertiary : undefined,
		borderRadius: '2px',
		'.ql-toolbar.ql-snow': {
			border: 'none',
			borderBottom: `1px solid ${theme.palette.neutralTertiaryAlt}`,
		},
		'.ql-container.ql-snow': {
			border: 'none',
		},
	});
});
