import {IToggleProps} from '@fluentui/react';
import {
	PropsWithAriaDescribedBy,
	TooltipWithComponentWithAriaDescribedBy,
} from 'features/localizedTooltips';
import React from 'react';
import {HookFormProps} from '../HookFormProps';

type PropsWithLabel = Pick<IToggleProps, 'label'>;

type ControlledToggleLabelProps = PropsWithLabel & PropsWithAriaDescribedBy;

function ControlledToggleLabel({
	label,
	'aria-describedby': ariaDescribedBy,
}: ControlledToggleLabelProps) {
	return <span aria-describedby={ariaDescribedBy}>{label}</span>;
}

type Props = PropsWithLabel & Pick<HookFormProps, 'name'>;

export const ControlledToggleLabelWithTooltip = (props: Props): JSX.Element => {
	const Host =
		TooltipWithComponentWithAriaDescribedBy<ControlledToggleLabelProps>;

	return (
		<Host
			Component={ControlledToggleLabel}
			tooltipHostProps={{translationKey: props.name}}
			{...props}
		/>
	);
};
