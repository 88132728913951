import {DefaultButton, Panel, PrimaryButton, Stack} from '@fluentui/react';
import {useEntityContext} from 'components/EntityPage/EntityContext';
import {
	ControlledSpinButton,
	ControlledTextField,
	ControlledToggle,
} from 'components/hookForms';
import {useCommand} from 'hooks';
import React from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {GdprRule} from 'types';
import {
	GetGdprRulesDocument,
	useCreateGdprRuleMutation,
	useUpdateGdprRuleMutation,
} from '../hooks/gdprRules.generated';
import {
	GdrpRulesTooltipTranslationProvider,
	ProviderThatEnablesGettingTooltipsFromContext,
} from 'features/localizedTooltips';

export enum GdprRuleFormMode {
	None,
	Create,
	Update,
}

export const RulesForm: React.FC = () => {
	const {t} = useTranslation('features/adminsection', {
		keyPrefix: 'AdminDataProtectionPage',
	});

	const [mode, setMode] = React.useState(GdprRuleFormMode.None);

	const {selectedItems} = useEntityContext<GdprRule>();

	const [createGdprRule] = useCreateGdprRuleMutation();
	const [updateGdprRule] = useUpdateGdprRuleMutation();

	useCommand(
		{
			key: 'create',
			iconProps: {
				iconName: 'Add',
			},
			text: t('CreateRule'),
			onClick() {
				setMode(GdprRuleFormMode.Create);
			},
			title: t('CreateRule'),
			priority: 0,
			disabled: selectedItems.length > 0,
		},
		[mode, selectedItems],
	);

	const {handleSubmit, control, reset} = useForm<GdprRule>({
		reValidateMode: 'onSubmit',
		mode: 'all',
	});

	const handleCancelClick = React.useCallback(() => {
		setMode(GdprRuleFormMode.None);
		reset({});
	}, [mode]);

	useCommand(
		{
			key: 'update',
			iconProps: {
				iconName: 'Remove',
			},
			onClick() {
				setMode(GdprRuleFormMode.Update);
			},
			text: t('UpdateRule'),
			title: t('UpdateRule'),
			priority: 1,
			disabled: selectedItems.length !== 1,
		},
		[mode, selectedItems],
	);

	const handleSaveClick = React.useCallback(() => {
		handleSubmit(input => {
			switch (mode) {
				case GdprRuleFormMode.Create:
					createGdprRule({
						variables: {
							input: {
								anonymize: input.anonymize,
								deletionPeriod: input.deletionPeriod,
								name: input.name,
							},
						},
						refetchQueries: [GetGdprRulesDocument],
					});
					break;
				case GdprRuleFormMode.Update:
					updateGdprRule({
						variables: {
							input: {
								anonymize: input.anonymize,
								deletionPeriod: input.deletionPeriod,
								name: input.name,
								id: selectedItems[0].id,
							},
						},
						refetchQueries: [GetGdprRulesDocument],
					});
					break;
				default:
					break;
			}

			setMode(GdprRuleFormMode.None);
		})();
	}, [mode]);

	React.useEffect(() => {
		reset(selectedItems[0] ?? {});
	}, [selectedItems]);

	const onRenderFooterContent: any = () => (
		<form>
			<PrimaryButton styles={buttonStyles} onClick={handleSaveClick}>
				{mode === GdprRuleFormMode.Create
					? t('CreateButton')
					: t('UpdateButton')}
			</PrimaryButton>
			<DefaultButton onClick={handleCancelClick}>
				{t('CancelButton')}
			</DefaultButton>
		</form>
	);

	const setHeaderText = React.useCallback(() => {
		if (mode === GdprRuleFormMode.Create) {
			return t('CreateGdprRulePanelHeader');
		}

		return t('EditGdprRulePanelHeader');
	}, [mode]);

	return (
		<Panel
			isLightDismiss
			isOpen={mode !== GdprRuleFormMode.None}
			onDismiss={handleCancelClick}
			isFooterAtBottom={true}
			onRenderFooterContent={onRenderFooterContent}
			headerText={setHeaderText()}
		>
			<Stack>
				<GdrpRulesTooltipTranslationProvider>
					<ProviderThatEnablesGettingTooltipsFromContext>
						<ControlledTextField
							required={true}
							control={control}
							name={'name'}
							label={t('GdprRuleName')}
						/>
						<ControlledSpinButton
							control={control}
							min={0}
							max={100}
							name={'deletionPeriod'}
							label={t('DeletionPeriod')}
						/>
						<ControlledToggle
							control={control}
							name={'anonymize'}
							label={t('Anonymize')}
						/>
					</ProviderThatEnablesGettingTooltipsFromContext>
				</GdrpRulesTooltipTranslationProvider>
			</Stack>
		</Panel>
	);
};

const buttonStyles = {
	root: {
		marginRight: 8,
	},
};
