import {
	DefaultButton,
	Panel,
	PanelType,
	PrimaryButton,
	SelectionMode,
	Stack,
} from '@fluentui/react';
import {useUserContext} from 'authentication/UserContext';
import {EntityList, EntityListColumn} from 'components';
import {
	renderArrayField,
	renderRequirementStatus,
} from 'components/EntityList/ColumnRenderers';
import {RegulatoryDetailsPageFormMode} from 'features/RegulatoryDocuments/RegDocDetailsPage/RegDocDetailsPage.types';
import {useRegulatoryDocumentsContext} from 'features/RegulatoryDocuments/context';
import {GetRegulatoryDocumentDetailsDocument} from 'features/RegulatoryDocuments/hooks/useGetRegulatoryDocumentDetails.generated';
import {useRemoveRegulatoryDocumentParagraphsRequirementsMutation} from 'features/RegulatoryDocuments/hooks/useRemoveRegulatoryDocumentParagraphsRequirements.generated';
import {RequirementsTooltipTranslationProvider} from 'features/Requirements/RequirementsTooltipTranslationProvider';
import {mapToRef} from 'helpers';
import {useCommand, useNotificationBar, useSelection} from 'hooks';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {Requirement, RequirementStatus, UserRole} from 'types';
import {useParagraphsContext} from './ParagraphsContext';
import {useCloneRequirement} from 'features/Requirements/hooks/useCloneRequirement';

export const RemoveRequirementsForm = () => {
	const {t} = useTranslation('features/regulatorydocuments', {
		keyPrefix: 'RemoveRequirementsForm',
	});

	const [removeRegulatoryDocumentParagraphsRequirements] =
		useRemoveRegulatoryDocumentParagraphsRequirementsMutation();

	const {selectedRegulatoryDocument} = useRegulatoryDocumentsContext();
	const {selectedParagraphs} = useParagraphsContext();
	const {cloneRequirement} = useCloneRequirement();
	const {isAdmin} = useUserContext();
	const {setMessage} = useNotificationBar();

	const requirementData = React.useMemo(() => {
		const requirements: Requirement[] = [];
		selectedParagraphs.forEach(p =>
			p.requirements.forEach(r => {
				if (!requirements.find(req => r.id === req.id)) {
					requirements.push(r);
				}
			}),
		);
		return requirements;
	}, [selectedParagraphs]);

	const [mode, setMode] = React.useState<RegulatoryDetailsPageFormMode>(
		RegulatoryDetailsPageFormMode.None,
	);

	const [selectedRequirements, setSelectedRequirements] = React.useState<
		Requirement[]
	>([]);

	const [selection] = useSelection<Requirement>({
		onSelectionChanged(selectedItems) {
			setSelectedRequirements(selectedItems);
		},
		getKey: item => item.id,
	});

	const onRenderFooterContent: any = () => (
		<form>
			<PrimaryButton styles={buttonStyles} onClick={handleSaveClick}>
				{t('RemoveButton')}
			</PrimaryButton>
			<DefaultButton onClick={handleCancelClick}>
				{t('CancelButton')}
			</DefaultButton>
		</form>
	);

	const handleRequirementVersioning = React.useCallback(async () => {
		const newRequirements = selectedRequirements.filter(
			req => req.status === RequirementStatus.Final,
		);

		const promises = newRequirements.map(nr =>
			cloneRequirement(nr, [GetRegulatoryDocumentDetailsDocument], undefined),
		);

		await Promise.all(promises);
	}, [
		selectedRequirements,
		selectedParagraphs,
		cloneRequirement,
		GetRegulatoryDocumentDetailsDocument,
	]);

	const handleSaveClick = React.useCallback(async () => {
		const removeRet = await removeRegulatoryDocumentParagraphsRequirements({
			variables: {
				input: {
					regulatoryDocumentId: selectedRegulatoryDocument?.id ?? '',
					requirementRefs: mapToRef(selectedRequirements),
					paragraphIds: selectedParagraphs.map(sp => sp.id) ?? [],
				},
			},
			refetchQueries: [GetRegulatoryDocumentDetailsDocument],
		});

		if (removeRet.data) {
			await handleRequirementVersioning();
		}

		setMode(RegulatoryDetailsPageFormMode.None);
		setMessage(
			t('RemoveMessage', {
				selectedParagraphs: selectedParagraphs.length.toString(),
			}),
		);
		selection.setAllSelected(false);
	}, [
		mode,
		selectedParagraphs,
		selectedRegulatoryDocument,
		selectedRequirements,
	]);

	const handleCancelClick = React.useCallback(() => {
		setMode(RegulatoryDetailsPageFormMode.None);
	}, []);

	const [searchParams] = useSearchParams();

	const filter = searchParams.get('filter') ?? '';

	/* Requirment button, Regulatory Document */
	useCommand(
		{
			key: 'remove',
			text: t('Remove'),
			priority: 2,
			iconProps: {
				iconName: 'Remove',
			},
			disabled: selectedParagraphs.length === 0,
			hidden: filter === 'new' && !isAdmin,
			onClick: () => setMode(RegulatoryDetailsPageFormMode.Remove),
			roles: [UserRole.SystemAdministrator, UserRole.Vex],
		},
		[mode, selectedParagraphs, isAdmin, filter],
	);

	const columns: EntityListColumn[] = React.useMemo(
		() => [
			{
				key: 'column1',
				name: t('Requirement'),
				fieldName: 'name',
				minWidth: 150,
				isResizable: true,
				sortable: true,
			},
			{
				key: 'column2',
				name: t('Status'),
				fieldName: 'status',
				minWidth: 150,
				isResizable: true,
				onRender: renderRequirementStatus(),
				sortable: true,
			},
			{
				key: 'column3',
				name: t('RequirementId'),
				fieldName: 'requirementId',
				minWidth: 150,
				isResizable: true,
				sortable: true,
			},
			{
				key: 'column4',
				name: t('VexCluster'),
				fieldName: 'vexClusters',
				minWidth: 150,
				isResizable: true,
				sortable: true,
				onRender: renderArrayField(),
			},
		],
		[t],
	);

	return (
		<Panel
			isLightDismiss
			type={PanelType.largeFixed}
			isFooterAtBottom={true}
			isOpen={mode === RegulatoryDetailsPageFormMode.Remove}
			onDismiss={handleCancelClick}
			closeButtonAriaLabel='Close'
			headerText={t('RemoveRequirementsPanelHeader')}
			onRenderFooterContent={onRenderFooterContent}
		>
			<Stack>
				<RequirementsTooltipTranslationProvider>
					<EntityList
						items={requirementData}
						columns={columns}
						aria-rowcount
						selectionMode={SelectionMode.multiple}
						selection={selection}
					/>
				</RequirementsTooltipTranslationProvider>
			</Stack>
		</Panel>
	);
};

const buttonStyles = {
	root: {
		marginRight: 8,
	},
};
