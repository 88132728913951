import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import { UserFieldsFragmentDoc } from '../../../fragments/userFields.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDocumentSourcesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetDocumentSourcesQuery = { __typename?: 'Query', documentSources?: Array<{ __typename?: 'DocumentSource', id: string, name: string, createdAt?: any | null, modifiedAt?: any | null, createdBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null }, modifiedBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null }, translations?: Array<{ __typename?: 'KeyValuePairOfStringAndString', key: string, value: string }> | null }> | null };

export type CreateDocumentSourceMutationVariables = Types.Exact<{
  input: Types.CreateDocumentSourceInput;
}>;


export type CreateDocumentSourceMutation = { __typename?: 'Mutation', createDocumentSource: { __typename?: 'CreateDocumentSourcePayload', documentSource?: { __typename?: 'DocumentSource', id: string } | null } };

export type UpdateDocumentSourceMutationVariables = Types.Exact<{
  input: Types.UpdateDocumentSourceInput;
}>;


export type UpdateDocumentSourceMutation = { __typename?: 'Mutation', updateDocumentSource: { __typename?: 'UpdateDocumentSourcePayload', documentSource?: { __typename?: 'DocumentSource', id: string } | null } };

export type DeleteDocumentSourceMutationVariables = Types.Exact<{
  input: Types.DeleteDocumentSourceInput;
}>;


export type DeleteDocumentSourceMutation = { __typename?: 'Mutation', deleteDocumentSource: { __typename?: 'DeleteDocumentSourcePayload', documentSource?: { __typename?: 'DocumentSource', id: string } | null } };


export const GetDocumentSourcesDocument = gql`
    query GetDocumentSources {
  documentSources {
    id
    name
    createdAt
    createdBy {
      ...UserFields
    }
    modifiedAt
    modifiedBy {
      ...UserFields
    }
    translations {
      key
      value
    }
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useGetDocumentSourcesQuery__
 *
 * To run a query within a React component, call `useGetDocumentSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentSourcesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDocumentSourcesQuery(baseOptions?: Apollo.QueryHookOptions<GetDocumentSourcesQuery, GetDocumentSourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentSourcesQuery, GetDocumentSourcesQueryVariables>(GetDocumentSourcesDocument, options);
      }
export function useGetDocumentSourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentSourcesQuery, GetDocumentSourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentSourcesQuery, GetDocumentSourcesQueryVariables>(GetDocumentSourcesDocument, options);
        }
export type GetDocumentSourcesQueryHookResult = ReturnType<typeof useGetDocumentSourcesQuery>;
export type GetDocumentSourcesLazyQueryHookResult = ReturnType<typeof useGetDocumentSourcesLazyQuery>;
export type GetDocumentSourcesQueryResult = Apollo.QueryResult<GetDocumentSourcesQuery, GetDocumentSourcesQueryVariables>;
export const CreateDocumentSourceDocument = gql`
    mutation CreateDocumentSource($input: CreateDocumentSourceInput!) {
  createDocumentSource(input: $input) {
    documentSource {
      id
    }
  }
}
    `;
export type CreateDocumentSourceMutationFn = Apollo.MutationFunction<CreateDocumentSourceMutation, CreateDocumentSourceMutationVariables>;

/**
 * __useCreateDocumentSourceMutation__
 *
 * To run a mutation, you first call `useCreateDocumentSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentSourceMutation, { data, loading, error }] = useCreateDocumentSourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDocumentSourceMutation(baseOptions?: Apollo.MutationHookOptions<CreateDocumentSourceMutation, CreateDocumentSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDocumentSourceMutation, CreateDocumentSourceMutationVariables>(CreateDocumentSourceDocument, options);
      }
export type CreateDocumentSourceMutationHookResult = ReturnType<typeof useCreateDocumentSourceMutation>;
export type CreateDocumentSourceMutationResult = Apollo.MutationResult<CreateDocumentSourceMutation>;
export type CreateDocumentSourceMutationOptions = Apollo.BaseMutationOptions<CreateDocumentSourceMutation, CreateDocumentSourceMutationVariables>;
export const UpdateDocumentSourceDocument = gql`
    mutation UpdateDocumentSource($input: UpdateDocumentSourceInput!) {
  updateDocumentSource(input: $input) {
    documentSource {
      id
    }
  }
}
    `;
export type UpdateDocumentSourceMutationFn = Apollo.MutationFunction<UpdateDocumentSourceMutation, UpdateDocumentSourceMutationVariables>;

/**
 * __useUpdateDocumentSourceMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentSourceMutation, { data, loading, error }] = useUpdateDocumentSourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDocumentSourceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocumentSourceMutation, UpdateDocumentSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocumentSourceMutation, UpdateDocumentSourceMutationVariables>(UpdateDocumentSourceDocument, options);
      }
export type UpdateDocumentSourceMutationHookResult = ReturnType<typeof useUpdateDocumentSourceMutation>;
export type UpdateDocumentSourceMutationResult = Apollo.MutationResult<UpdateDocumentSourceMutation>;
export type UpdateDocumentSourceMutationOptions = Apollo.BaseMutationOptions<UpdateDocumentSourceMutation, UpdateDocumentSourceMutationVariables>;
export const DeleteDocumentSourceDocument = gql`
    mutation DeleteDocumentSource($input: DeleteDocumentSourceInput!) {
  deleteDocumentSource(input: $input) {
    documentSource {
      id
    }
  }
}
    `;
export type DeleteDocumentSourceMutationFn = Apollo.MutationFunction<DeleteDocumentSourceMutation, DeleteDocumentSourceMutationVariables>;

/**
 * __useDeleteDocumentSourceMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentSourceMutation, { data, loading, error }] = useDeleteDocumentSourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDocumentSourceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDocumentSourceMutation, DeleteDocumentSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDocumentSourceMutation, DeleteDocumentSourceMutationVariables>(DeleteDocumentSourceDocument, options);
      }
export type DeleteDocumentSourceMutationHookResult = ReturnType<typeof useDeleteDocumentSourceMutation>;
export type DeleteDocumentSourceMutationResult = Apollo.MutationResult<DeleteDocumentSourceMutation>;
export type DeleteDocumentSourceMutationOptions = Apollo.BaseMutationOptions<DeleteDocumentSourceMutation, DeleteDocumentSourceMutationVariables>;