import {
	AuthenticatedTemplate,
	UnauthenticatedTemplate,
} from '@azure/msal-react';
import {Stack, ThemeProvider, initializeIcons} from '@fluentui/react';
import {DashboardLayout, Layout} from 'appShell/Layout';
import {CommandsProvider} from 'components';
import {LoadWrapper} from 'components/LoadWrapper';
import {
	AdminActiveTypeMarketsPage,
	AdminAttachmentCategoriesPage,
	AdminCategoriesPage,
	AdminDataProtectionPage,
	AdminDriveVariantsPage,
	AdminEngineVariantsPage,
	AdminGearboxVariantsPage,
	AdminKeywordsPage,
	AdminMarketsPage,
	AdminPcmsClustersPage,
	AdminReferencePage,
	AdminRegulationsClustersPage,
	AdminSystemLevelsPage,
	AdminTagsPage,
	AdminVehicleCategoriesPage,
	AdminVexClustersPage,
} from 'features/AdminSection';
import AdminBodyworkVariantsPage from 'features/AdminSection/AdminBodyworkVariantsPage';
import RegulatoryDocumentArchivePage from 'features/Archive/RegulatoryDocumentArchivePage';
import DashboardPage from 'features/Dashboard/DashboardPage';
import {FavoritesPage} from 'features/Favorites';
import {RecentsPage} from 'features/Recents';
import RegulationsPage from 'features/Regulations/RegulationsPage';
import {RegulationDetail} from 'features/Regulations/components';
import {RegulatoryDocumentDetailsPage} from 'features/RegulatoryDocuments';
import RegulatoryDocumentEditorPage from 'features/RegulatoryDocuments/RegulatoryDocumentEditorPage';
import ParagraphDetailsPage from 'features/RegulatoryDocuments/components/DocumentDetails/ParagraphDetails/ParagraphDetailsPage';
import {RequirementDetailsPage} from 'features/Requirements/RequirementDetailsPage';
import RequirementsPage from 'features/Requirements/RequirementsPage';
import {ReportsPage} from 'features/Reports';
import {SearchPage} from 'features/Search';
import VehicleProjectDetailsPage from 'features/VehicleProjects/VehicleProjectDetailsPage';
import VehicleProjectsPage from 'features/VehicleProjects/VehicleProjectsPage';
import PogisDocumentPage from 'features/Archive/PogisDocumentPage';
import {ActiveTypeMarketsPage} from 'features/VehicleProjects/components';
import {PAGE_PATHS} from 'pages';
import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {themes} from 'theme';
import {ActiveTypeMarketDetailsPage} from 'features/VehicleProjects/ActiveTypeMarketDetailsPage/ActiveTypeMarketDetailsPage';
import {
	ProjectDetailsPageParams,
	getMarketPagePath,
} from 'features/VehicleProjects/vehicleProjectPagePaths';
import {createRequirementHref} from 'features/Requirements/requirementLinks.utils';
import {createRegulationHref} from 'features/Regulations/components/regulationLinks.utils';
import AdminDocumentSourcesPage from 'features/AdminSection/AdminDocumentSourcesPage';
import AdminCountriesPage from 'features/AdminSection/AdminCountriesPage';

import {
	FieldsToCreatePathOfBaselineDetailsPage,
	createPathOfBaselineDetailsPage,
} from 'features/VehicleProjects/BaselineDetailsPage/BaselineDetailsPage.utils';
import {BaselineDetailsPage} from 'features/VehicleProjects/BaselineDetailsPage/BaselineDetailsPage';
import {NotFoundPage} from 'components/NotFoundPage';
import {createPathOfBaselinesComparisonPage} from 'features/VehicleProjects/BaselineComparisonPage/BaselineComparisonPage.utils';
import {BaselineComparisonPage} from 'features/VehicleProjects/BaselineComparisonPage/BaselineComparisonPage';
import {SimulationsPage} from 'features/Simulations/SimulationsPage/SimulationsPage';
import {createPathOfSimulationDetailsPage} from 'features/Simulations/SimulationDetailsPage/simulationsPagePaths.utils';
import {SimulationDetailsPage} from 'features/Simulations/SimulationDetailsPage/SimulationDetailsPage';
import SubscriptionsPage from 'features/Subscriptions/SubscriptionsPage';
import VkoVexListPage from 'features/VkoVexList/VkoVexListPage';
import {createBaselineListPagePath} from './features/VehicleProjects/BaselineListPage/BaselinesPage.utils';
import {BaselineListPage} from './features/VehicleProjects/BaselineListPage/BaselineListPage';

function App() {
	const genAndModelSeriesParams: ProjectDetailsPageParams = {
		modelSeries: ':modelSeries',
		generation: ':generation',
	};

	const createRoutePathOfMarketPage = (): string => {
		return getMarketPagePath({
			...genAndModelSeriesParams,
			activeTypeMarketId: ':activeTypeMarketId',
		});
	};

	const createFieldsToCreatePathOfBaselineDetailsPage =
		(): FieldsToCreatePathOfBaselineDetailsPage => {
			return {...genAndModelSeriesParams, baselineId: ':id'};
		};

	const createRoutePathOfBaselineDetailsPage = (): string => {
		const fields: FieldsToCreatePathOfBaselineDetailsPage =
			createFieldsToCreatePathOfBaselineDetailsPage();
		return createPathOfBaselineDetailsPage(fields);
	};

	const createRoutePathOfBaselineComparisonPage = (): string => {
		const baseFields: FieldsToCreatePathOfBaselineDetailsPage =
			createFieldsToCreatePathOfBaselineDetailsPage();
		return createPathOfBaselinesComparisonPage({
			...baseFields,
			idOfBaselineToCompareTo: ':idOfBaselineToCompareTo',
		});
	};

	return (
		<>
			<AuthenticatedTemplate>
				<ThemeProvider theme={themes}>
					<CommandsProvider>
						<BrowserRouter>
							<React.Suspense fallback={<Layout />}>
								<Routes>
									<Route element={<DashboardLayout />}>
										<Route path='/' element={<DashboardPage />} />
									</Route>
									<Route
										path={createRoutePathOfMarketPage()}
										element={<ActiveTypeMarketDetailsPage />}
									/>
									<Route element={<Layout />}>
										<Route path='/favorites' element={<FavoritesPage />} />
										<Route path='/recents' element={<RecentsPage />} />
										<Route
											path='/subscriptions'
											element={<SubscriptionsPage />}
										/>
										<Route
											path={createRegulationHref(':regulationId')}
											element={<RegulationDetail />}
										/>
										<Route
											path='/regulatoryDocuments/:regulatoryDocumentId'
											element={<RegulatoryDocumentDetailsPage />}
										/>
										<Route
											path='/regulations/:regulationId/:regulatoryDocumentId'
											element={<RegulatoryDocumentEditorPage />}
										/>
										<Route
											path='/regulations/:regulationId/:regulatoryDocumentId/paragraphs'
											element={<RegulatoryDocumentDetailsPage />}
										/>
										<Route
											path='/regulations/:regulationId/:regulatoryDocumentId/paragraphs/:paragraphId'
											element={<ParagraphDetailsPage />}
										/>
										<Route
											path={PAGE_PATHS.regulations}
											element={<RegulationsPage />}
										/>
										<Route
											path='/regulatoryDocumentsArchive'
											element={<RegulatoryDocumentArchivePage />}
										/>
										<Route
											path='/pogisArchive'
											element={<PogisDocumentPage />}
										/>
										<Route
											path={PAGE_PATHS.requirements}
											element={<RequirementsPage />}
										/>
										<Route
											path={createRequirementHref(':requirementId')}
											element={<RequirementDetailsPage />}
										/>
										<Route
											path={PAGE_PATHS.vehicleProjects}
											element={<VehicleProjectsPage />}
										/>
										<Route path='/vkoVexList' element={<VkoVexListPage />} />
										<Route
											path='/vehicleProjects/:modelSeries/:generation'
											element={<VehicleProjectDetailsPage />}
										/>
										<Route
											path='/vehicleProjects/:modelSeries/:generation/projects'
											element={<ActiveTypeMarketsPage />}
										/>
										<Route
											path={createBaselineListPagePath(genAndModelSeriesParams)}
											element={<BaselineListPage />}
										/>
										<Route
											path={createRoutePathOfBaselineDetailsPage()}
											element={<BaselineDetailsPage />}
										/>
										<Route
											path={createRoutePathOfBaselineComparisonPage()}
											element={<BaselineComparisonPage />}
										/>
										<Route
											path={PAGE_PATHS.simulations}
											element={<SimulationsPage />}
										/>
										<Route
											path={createPathOfSimulationDetailsPage(':id')}
											element={<SimulationDetailsPage />}
										/>
										<Route
											path='/reports/regulatory-documents'
											element={<ReportsPage />}
										/>
										<Route path='/search' element={<SearchPage />} />
										<Route
											path='/admin/markets'
											element={<AdminMarketsPage />}
										/>
										<Route
											path='/admin/regulationsClusters'
											element={<AdminRegulationsClustersPage />}
										/>
										<Route
											path='/admin/vehicleCategories'
											element={<AdminVehicleCategoriesPage />}
										/>
										<Route
											path='/admin/driveVariants'
											element={<AdminDriveVariantsPage />}
										/>
										<Route
											path='/admin/engineVariants'
											element={<AdminEngineVariantsPage />}
										/>
										<Route
											path='/admin/gearboxVariants'
											element={<AdminGearboxVariantsPage />}
										/>
										<Route
											path='/admin/bodyworkVariants'
											element={<AdminBodyworkVariantsPage />}
										/>
										<Route
											path='/admin/activeTypeMarkets'
											element={<AdminActiveTypeMarketsPage />}
										/>
										<Route
											path='/admin/categories'
											element={<AdminCategoriesPage />}
										/>
										<Route
											path='/admin/keywords'
											element={<AdminKeywordsPage />}
										/>
										<Route
											path='/admin/vexClusters'
											element={<AdminVexClustersPage />}
										/>
										<Route
											path='/admin/systemLevels'
											element={<AdminSystemLevelsPage />}
										/>
										<Route path='/admin/tags' element={<AdminTagsPage />} />
										<Route
											path='/admin/pcmsClusters'
											element={<AdminPcmsClustersPage />}
										/>
										<Route
											path='/admin/attachmentCategories'
											element={<AdminAttachmentCategoriesPage />}
										/>
										<Route
											path='/admin/dataProtection'
											element={<AdminDataProtectionPage />}
										/>
										<Route
											path='/admin/references'
											element={<AdminReferencePage />}
										/>
										<Route
											path='/admin/documentSources'
											element={<AdminDocumentSourcesPage />}
										/>
										<Route
											path='/admin/countries'
											element={<AdminCountriesPage />}
										/>
										<Route path='*' element={<NotFoundPage />} />
									</Route>
								</Routes>
							</React.Suspense>
						</BrowserRouter>
					</CommandsProvider>
				</ThemeProvider>
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<Stack
					styles={{root: {height: '100%', width: '100%', position: 'absolute'}}}
				>
					<LoadWrapper loading={true} text={'Redirecting to sign in...'} />
				</Stack>
			</UnauthenticatedTemplate>
		</>
	);
}

export default App;

initializeIcons(undefined, {disableWarnings: true});
