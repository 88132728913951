import React from 'react';
import {
	IDetailsHeaderProps,
	IRenderFunction,
	Sticky,
	StickyPositionType,
} from '@fluentui/react';
import {
	BasePropsOfComponentForRenderer,
	TooltipRendererCreatorService,
	TooltipRendererFields,
} from 'features/localizedTooltips/TooltipRendererCreator.service';
import {EntityListProps} from '../../../components/EntityList/EntityList';
import {DetailsListHeaderWithTooltips} from 'features/localizedTooltips/componentsWithTooltips/detailsList/DetailsListHeaderWithTooltips';
import {CorrectStickyType} from 'types';

type Props = BasePropsOfComponentForRenderer<IDetailsHeaderProps> &
	Pick<EntityListProps, 'sticky'>;

export const classToAddToHeaderWhenSticky = 'sticky-header';

/**
 * @see DetailsListHeaderWithTooltips for important info about this component.
 */
export function HeaderWithTooltipsAndStickySupport({sticky, ...other}: Props) {
	const header: JSX.Element = <DetailsListHeaderWithTooltips {...other} />;

	const renderStickyHeader = (): JSX.Element => {
		const AnySticky = Sticky as unknown as CorrectStickyType;
		return (
			<AnySticky
				stickyPosition={StickyPositionType.Header}
				isScrollSynced={true}
				stickyClassName={classToAddToHeaderWhenSticky}
			>
				{header}
			</AnySticky>
		);
	};

	return sticky ? renderStickyHeader() : header;
}

type IsSticky = Props['sticky'];

type RendererFields = TooltipRendererFields<IsSticky, IDetailsHeaderProps>;

const renderHeader = ({
	defaultRender,
	props: componentProps,
	extraInfoForRenderer: isSticky,
}: RendererFields): JSX.Element => {
	return (
		<HeaderWithTooltipsAndStickySupport
			defaultRender={defaultRender}
			originalComponentProps={componentProps}
			sticky={isSticky}
		/>
	);
};

type HeaderRenderFunction = IRenderFunction<IDetailsHeaderProps>;

/**
 * To render the header, you usually need to provide a renderer function to the
 * Details List. This helps us create a renderer.
 */
export const getRendererForHeaderWithTooltips = (
	isSticky: IsSticky,
): HeaderRenderFunction => {
	const {getCallRendererIfDefaultOneExists} =
		new TooltipRendererCreatorService();
	return getCallRendererIfDefaultOneExists(isSticky, renderHeader);
};
