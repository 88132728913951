import {QueryParagraph} from 'features/RegulatoryDocuments/RegDocDetailsPage/RegDocDetailsPage.queryTypes';
import {
	MutationKeyOptimisticData as BaseMutationKeyOptimisticData,
	FieldsFromMutationKeyUtilsForMutationKeyService as BaseFieldsFromMutationKeyUtilsForMutationKeyService,
	MutationKeyOptimisticDataUtils,
} from '../../MutationKeyOptimisticData.utils';
import {
	UpdatedOptimisticParagraphService,
	ParagraphOptimisticDataServiceFields,
} from '../UpdatedOptimisticParagraph.service';
import {OptimisticParagraph} from '../optimisticParagraph.types';
import {MutationParagraph} from '../updateRegDocParagraphMutations.types';
import {RegulatoryDocumentParagraph} from 'types';
import {ParagraphSanitizationService} from '../ParagraphSanitization.service';

type FieldsFromParagraphService = Omit<
	ParagraphOptimisticDataServiceFields,
	'paragraphId'
>;

type FieldsFromMutationKeyUtils =
	BaseFieldsFromMutationKeyUtilsForMutationKeyService<MutationParagraph>;

export interface MutationKeyOptimisticDataServiceFields
	extends FieldsFromMutationKeyUtils,
		FieldsFromParagraphService {
	paragraphs: QueryParagraph[];
}

type ParagraphId = RegulatoryDocumentParagraph['id'];

export type MutationKeyOptimisticData =
	BaseMutationKeyOptimisticData<OptimisticParagraph>;

export class MutationKeyOptimisticDataService {
	private paragraphSanitizationService = new ParagraphSanitizationService();

	// eslint-disable-next-line no-useless-constructor
	constructor(private fields: MutationKeyOptimisticDataServiceFields) {}

	private createParagraphService = (
		paragraphId: ParagraphId,
	): UpdatedOptimisticParagraphService => {
		return new UpdatedOptimisticParagraphService({paragraphId, ...this.fields});
	};

	private createUpdatedParagraph = (
		paragraphId: ParagraphId,
	): OptimisticParagraph => {
		const service: UpdatedOptimisticParagraphService =
			this.createParagraphService(paragraphId);
		return service.createOptimisticParagraph();
	};

	private convertQueryParagraphToOptimistic = (
		queryParagraph: QueryParagraph,
	): OptimisticParagraph => {
		return this.paragraphSanitizationService.sanitizeParagraphLikeObject(
			queryParagraph,
		);
	};

	private convertQueryParagraphsToOptimisticOnes =
		(): OptimisticParagraph[] => {
			return this.fields.paragraphs.map(this.convertQueryParagraphToOptimistic);
		};

	private createMutationKeyOptimisticData = (): MutationKeyOptimisticData => {
		return MutationKeyOptimisticDataUtils.createMutationKeyOptimisticData({
			...this.fields,
			createParagraph: this.createUpdatedParagraph,
			paragraphs: this.convertQueryParagraphsToOptimisticOnes(),
		});
	};

	static createMutationKeyOptimisticData = (
		fields: MutationKeyOptimisticDataServiceFields,
	): MutationKeyOptimisticData => {
		const service = new MutationKeyOptimisticDataService(fields);
		return service.createMutationKeyOptimisticData();
	};
}
