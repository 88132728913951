import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateRegulationMutationVariables = Types.Exact<{
  input: Types.CreateRegulationInput;
}>;


export type CreateRegulationMutation = { __typename?: 'Mutation', createRegulation: { __typename?: 'RegulationPayload', regulation: { __typename?: 'Regulation', id: string } } };


export const CreateRegulationDocument = gql`
    mutation CreateRegulation($input: CreateRegulationInput!) {
  createRegulation(input: $input) {
    regulation {
      id
    }
  }
}
    `;
export type CreateRegulationMutationFn = Apollo.MutationFunction<CreateRegulationMutation, CreateRegulationMutationVariables>;

/**
 * __useCreateRegulationMutation__
 *
 * To run a mutation, you first call `useCreateRegulationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRegulationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRegulationMutation, { data, loading, error }] = useCreateRegulationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRegulationMutation(baseOptions?: Apollo.MutationHookOptions<CreateRegulationMutation, CreateRegulationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRegulationMutation, CreateRegulationMutationVariables>(CreateRegulationDocument, options);
      }
export type CreateRegulationMutationHookResult = ReturnType<typeof useCreateRegulationMutation>;
export type CreateRegulationMutationResult = Apollo.MutationResult<CreateRegulationMutation>;
export type CreateRegulationMutationOptions = Apollo.BaseMutationOptions<CreateRegulationMutation, CreateRegulationMutationVariables>;