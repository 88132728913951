import {IRawStyle, IScrollablePaneStyles, IStackStyles} from '@fluentui/react';

/**
 * The styles of the component that directly wraps entity lists when showing two
 * entity lists side-by-side. These styles support scrollable panes and stacks.
 */
export const getParagraphsWrapperStyles = (
	position: IRawStyle['position'],
	rootStyles?: IRawStyle,
): Partial<IScrollablePaneStyles | IStackStyles> => {
	return {
		root: {
			position,
			height: '100%',
			overflowX: 'hidden',
			zIndex: 0,
			...rootStyles,
		},
		contentContainer: {
			position,
			overflowX: 'auto',
		},
	};
};

export const getStackHeight = (fullHeight: boolean): IRawStyle['height'] => {
	return fullHeight ? 'calc(100vh - 150px)' : 'calc(100vh - 410px)';
};
