import React from 'react';
import {HookFormProps} from './HookFormProps';
import {useFieldArray} from 'react-hook-form';
import {ControlledTextField} from './ControlledTextField';
import {
	Stack,
	Label,
	TooltipHost,
	IconButton,
	IStackItemStyles,
} from '@fluentui/react';
import {useTranslation} from 'react-i18next';
import {AppendButton} from './AppendButton';
import {Market} from 'types';
import {LabelWithTooltip} from 'features/localizedTooltips';

export type NamingTemplateFieldProps = {
	label: string;
};

interface FieldNames {
	template: string;
	description: string;
}

/**
 * This component supports tooltips. Read this component's definition to learn
 * what translation keys you must provide.
 */
export const NamingTemplateField: React.FC<
	HookFormProps & NamingTemplateFieldProps
> = ({control, name, label}) => {
	const {t} = useTranslation('components/namingtemplatefield');
	const createFieldNames = (): FieldNames => {
		return {template: 'template', description: 'description'};
	};

	/*
	 * We are not prefixing this with the entity subfield's key by default here
	 * because it's technically not required. If we set that prefix here,
	 * developers would not be able to set a custom prefix, which would impact
	 * their ability to nest the translations in another object.
	 */
	const createTranslationKey = (fieldName: string): string => {
		return `${name}.${fieldName}`;
	};

	const fieldNames: FieldNames = createFieldNames();

	const {fields, remove, append} = useFieldArray<Market>({
		control,
		name,
	});

	const isValidRegex = React.useCallback((value: string) => {
		try {
			const regexp = new RegExp(value);
			return Boolean(regexp);
		} catch (e) {
			return false;
		}
	}, []);

	/**
	 * We disabled getting the tooltips in the text fields because we will show
	 * the tooltips in the labels instead. If we had not disabled getting the
	 * tooltips, this component would throw an error if the tooltip status
	 * provider enabled tooltips by default.
	 */
	return (
		<React.Fragment>
			<Label>{label}</Label>
			<Stack tokens={{childrenGap: 8}}>
				{fields.map((field, index) => (
					<Stack key={field.id} horizontal tokens={{childrenGap: 8}}>
						<Stack.Item grow styles={stackItemStyles}>
							{index === 0 && (
								<LabelWithTooltip
									translationKey={createTranslationKey(fieldNames.template)}
								>
									{t('TemplateLabel')}
								</LabelWithTooltip>
							)}
							<ControlledTextField
								control={control}
								name={`${name}[${index}].${fieldNames.template}`}
								rules={{
									required: t('Required'),
									validate: {
										isValidRegex: value =>
											isValidRegex(value) || t('InvalidRegex'),
									},
								}}
								tooltipHostProps={{shouldGetTextFromContext: false}}
							/>
						</Stack.Item>
						<Stack.Item grow styles={stackItemStyles}>
							{index === 0 && (
								<LabelWithTooltip
									translationKey={createTranslationKey(fieldNames.description)}
								>
									{t('DescriptionLabel')}
								</LabelWithTooltip>
							)}
							<ControlledTextField
								control={control}
								name={`${name}[${index}].${fieldNames.description}`}
								rules={{
									required: t('Required'),
								}}
								tooltipHostProps={{shouldGetTextFromContext: false}}
							/>
						</Stack.Item>
						<Stack.Item>
							<TooltipHost content={t('ToolTipDelete')}>
								<IconButton
									styles={{
										root: {marginTop: index === 0 ? 28 : 0},
									}}
									iconProps={{iconName: 'Delete'}}
									onClick={() => remove(index)}
								/>
							</TooltipHost>
						</Stack.Item>
					</Stack>
				))}
			</Stack>
			<AppendButton
				onClick={() => {
					append({});
				}}
				label={label}
				name={name}
			/>
		</React.Fragment>
	);
};

const stackItemStyles: IStackItemStyles = {
	root: {
		width: 100,
	},
};
