import {CommandButton} from '@fluentui/react';
import * as React from 'react';
import {useTranslation} from 'react-i18next';

type AppendButtonProps = {
	name: string;
	label: string;
	onClick: () => void;
	disabled?: boolean;
};

export const AppendButton: React.FC<AppendButtonProps> = ({
	name,
	label,
	onClick,
	disabled = false,
}) => {
	const {t} = useTranslation('components/appendbutton', {
		keyPrefix: 'AppendButton',
	});

	return (
		<CommandButton
			onClick={onClick}
			text={t('ButtonLabel', {label})}
			iconProps={{iconName: 'Add'}}
			label='add'
			name={name}
			ariaLabel={'appendButton'}
			disabled={disabled}
		/>
	);
};
