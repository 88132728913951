import {GetRegulationQuery} from '../hooks';

type PageRegulation = Exclude<
	GetRegulationQuery['regulation'],
	undefined | null
>;

export type PageRegulatoryDocs = Exclude<
	PageRegulation['regulatoryDocuments'],
	undefined | null
>;

export enum DocumentSourceEnum {
	Pogis = 'POGIS',
}
