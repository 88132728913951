import {IDatePickerStrings} from '@fluentui/react';

export const datePickerStringsDE: IDatePickerStrings = {
	months: [
		'Januar',
		'Februar',
		'März',
		'April',
		'Mai',
		'Juni',
		'Juli',
		'August',
		'September',
		'Oktober',
		'November',
		'Dezember',
	],
	shortMonths: [
		'Jan',
		'Feb',
		'Mär',
		'Apr',
		'Mai',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Okt',
		'Nov',
		'Dez',
	],
	days: [
		'Sonntag',
		'Montag',
		'Dienstag',
		'Mittwoch',
		'Donnerstag',
		'Freitag',
		'Samstag',
	],
	shortDays: ['So.', 'Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sa.'],
	goToToday: 'Zu Heute',
	prevMonthAriaLabel: 'Zum vorigen Monat',
	nextMonthAriaLabel: 'Zum nächsten Monat',
	prevYearAriaLabel: 'Zum vorigen Jahr',
	nextYearAriaLabel: 'Zum nächsten Jahr',
	closeButtonAriaLabel: 'Datumsauswahl schließen',
	isRequiredErrorMessage: 'Startdatum ist erforderlich.',
	invalidInputErrorMessage: 'Datumsformat ist ungültig.',
};
