import React from 'react';
import {
	SidebarInfoProvider,
	SidebarInfoProviderProps,
	createSidebarInfoContext,
} from 'context/SidebarInfoProvider';

export const RegDocSidebarInfoContext = createSidebarInfoContext();

type Props = Pick<SidebarInfoProviderProps, 'children'>;

export const RegDocSidebarInfoProvider = (props: Props): JSX.Element => {
	return <SidebarInfoProvider {...props} Context={RegDocSidebarInfoContext} />;
};
