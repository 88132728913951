/**
 * ! Important
 *
 * Here, "Ka" means keyword assignment.
 */

import {
	ParagraphKeyword,
	QueryParagraph,
} from 'features/RegulatoryDocuments/RegDocDetailsPage/RegDocDetailsPage.queryTypes';
import {SimplifiedFormFields} from '../SimplifiedParagraphFormFields.service';
import type {OptimisticParagraph} from './optimisticParagraph.types';
import {KeywordStatus} from 'types';
import _ from 'lodash';

interface Fields {
	oldParagraph: QueryParagraph;
	formFields: SimplifiedFormFields;
	isBulkEditMode: boolean;
}

/**
 * * Types related to optimistic data
 */

type OptimisticKasArray = NonNullable<
	OptimisticParagraph['keywordAssignments']
>;

export type OptimisticKa = OptimisticKasArray[number];

type OptimisticVexClusterAssignment =
	OptimisticKa['vexClusterAssignments'][number];

/**
 * * Types related to paragraph
 */
type ParagraphKas = NonNullable<QueryParagraph['keywordAssignments']>;
export type ParagraphKa = ParagraphKas[number];

/**
 * * Types related to form
 */
type FormKeywords = SimplifiedFormFields['keywords'];
export type FormKeyword = FormKeywords[number];
type FormVexCluster = FormKeyword['vexClusters'][number];

/*
 * * Other
 */

export class KeywordAssignmentsCreatorService {
	private oldParagraph: Fields['oldParagraph'];
	private formFields: Fields['formFields'];
	private isBulkEditMode: Fields['isBulkEditMode'];
	private oldKasAsOptimisticOnes: OptimisticKa[];

	constructor({oldParagraph, formFields, isBulkEditMode}: Fields) {
		this.oldParagraph = oldParagraph;
		this.formFields = formFields;
		this.isBulkEditMode = isBulkEditMode;
		/**
		 * Leave this at the bottom so we don't accidentally use properties that
		 * haven't been defined yet.
		 */
		this.oldKasAsOptimisticOnes = this.oldParagraph.keywordAssignments ?? [];
	}

	private createVexClusterAssignment = (
		vexCluster: FormVexCluster,
	): OptimisticVexClusterAssignment => {
		return {vexCluster, keywordStatus: KeywordStatus.New};
	};

	private createVexClusterAssignments = (
		keyword: FormKeyword,
	): OptimisticVexClusterAssignment[] => {
		return keyword.vexClusters.map(this.createVexClusterAssignment);
	};

	private createKa = (keyword: FormKeyword): OptimisticKa => {
		return {
			keyword,
			vexClusterAssignments: this.createVexClusterAssignments(keyword),
		};
	};

	private getIfIsNewKeyword = ({id}: FormKeyword): boolean => {
		const {keywords: oldKeywords} = this.oldParagraph;
		type PossibleKeyword = ParagraphKeyword | undefined;
		const possibleKeyword: PossibleKeyword = _.find(oldKeywords, {id});
		return !possibleKeyword;
	};

	private getNewKeywords = (): FormKeywords => {
		return this.formFields.keywords.filter(this.getIfIsNewKeyword);
	};

	private createKasForNewKeywords = (): OptimisticKa[] => {
		const keywords: FormKeywords = this.getNewKeywords();
		return keywords.map(this.createKa);
	};

	private createKasAndCombineWithOldOnes = (
		oldKas: OptimisticKa[],
	): OptimisticKa[] => {
		const keywordAssignments: OptimisticKa[] = this.createKasForNewKeywords();
		return [...oldKas, ...keywordAssignments];
	};

	private getIfKeywordWasDeleted = ({id}: ParagraphKeyword): boolean => {
		type Match = FormKeyword | undefined;
		const possibleKeyword: Match = _.find(this.formFields.keywords, {id});
		return !possibleKeyword;
	};

	private getDeletedKeywords = (): QueryParagraph['keywords'] => {
		return this.oldParagraph.keywords.filter(this.getIfKeywordWasDeleted);
	};

	private getIfShouldDeleteKa = (oldKa: ParagraphKa): boolean => {
		const deletedKeywords: ParagraphKeyword[] = this.getDeletedKeywords();
		type KeywordId = ParagraphKeyword['id'];
		const deletedKeywordIds: KeywordId[] = _.map(deletedKeywords, 'id');
		return deletedKeywordIds.includes(oldKa.keyword.id);
	};

	private getIfShouldKeepKa = (oldKa: ParagraphKa): boolean => {
		const shouldDeleteIt: boolean = this.getIfShouldDeleteKa(oldKa);
		return !shouldDeleteIt;
	};

	private removeKasForMissingKeywords = (): OptimisticKa[] => {
		return this.oldKasAsOptimisticOnes.filter(this.getIfShouldKeepKa);
	};

	/**
	 * We create keyword assignments in this way because that's how the server
	 * does it. It seems the server's behavior is incorrect because the number of
	 * keywords doesn't necessarily tell us whether they were created or deleted,
	 * but the optimistic data should reflect the server's behavior anyways.
	 */
	private createKasForSingleEditMode = (): OptimisticKa[] => {
		const withoutMissingKeywords: OptimisticKa[] =
			this.removeKasForMissingKeywords();
		return this.createKasAndCombineWithOldOnes(withoutMissingKeywords);
	};

	public createKas = (): OptimisticParagraph['keywordAssignments'] => {
		if (this.isBulkEditMode)
			return this.createKasAndCombineWithOldOnes(this.oldKasAsOptimisticOnes);
		return this.createKasForSingleEditMode();
	};

	/**
	 * We don't use the acronym "Ka" here because this is not an internal method,
	 * so a developer would not necessarily know what "Ka" means.
	 */
	static createKeywordAssignments = (
		fields: Fields,
	): OptimisticParagraph['keywordAssignments'] => {
		const service = new KeywordAssignmentsCreatorService(fields);
		return service.createKas();
	};
}
