import {TFunction} from 'react-i18next';
import {formatDateTime} from 'i18n/localeDateFormat';
import i18n from 'i18n/i18n';
import Excel from 'exceljs';
import {renderHeaderXL, createZIPXL} from './ExcelExportCommon';

interface IAdminDetailPage {
	(entityListItems: any[], t: TFunction): Promise<void>;
}

export const handleClickVoProPage: IAdminDetailPage = async (
	entityListItems,
	t,
) => {
	const arrRegDocFilt = entityListItems;

	const strTrNS = 'features/reports';
	const strTrPrefix = 'ReportsList';
	const strPageName = t('RegulatoryDocuments', {
		ns: 'appShell/sidenavigation',
		keyPrefix: 'SideNavigation',
	});
	const arrColWidth = [20, 40, 20, 20, 20, 40, 40, 20, 20, 20, 20];
	const workbook = new Excel.Workbook();
	const sheet = workbook.addWorksheet('TRACE_' + strPageName);
	renderHeaderXL(workbook, sheet, arrColWidth, strPageName, t);

	let intRowCount = 4;
	let row = sheet.getRow(intRowCount);
	row.font = {
		bold: true,
		name: 'Porsche Next TT',
		size: 10,
		color: {argb: 'FFFFFFFF'},
	};
	sheet.addTable({
		name: 'tableVoPro',
		ref: 'B' + String(intRowCount),
		style: {
			showRowStripes: true,
		},
		columns: [
			{
				name: t('Regulation', {ns: strTrNS, keyPrefix: strTrPrefix}),
				filterButton: true,
			},
			{
				name: t('Version', {ns: strTrNS, keyPrefix: strTrPrefix}),
				filterButton: false,
			},
			{
				name: t('Created', {ns: strTrNS, keyPrefix: strTrPrefix}),
				filterButton: true,
			},
			{
				name: t('Creator', {ns: strTrNS, keyPrefix: strTrPrefix}),
				filterButton: true,
			},
			{
				name: t('Status', {ns: strTrNS, keyPrefix: strTrPrefix}),
				filterButton: true,
			},
			{
				name: t('Markets', {ns: strTrNS, keyPrefix: strTrPrefix}),
				filterButton: false,
			},
			{
				name: t('Keywords', {ns: strTrNS, keyPrefix: strTrPrefix}),
				filterButton: false,
			},
			{
				name: t('ModelYear', {ns: strTrNS, keyPrefix: strTrPrefix}),
				filterButton: true,
			},
			{
				name: t('DateEffective', {ns: strTrNS, keyPrefix: strTrPrefix}),
				filterButton: true,
			},
			{
				name: t('DateNewRegistration', {ns: strTrNS, keyPrefix: strTrPrefix}),
				filterButton: true,
			},
			{
				name: t('DateNewTypes', {ns: strTrNS, keyPrefix: strTrPrefix}),
				filterButton: true,
			},
		],
		rows: [],
	});

	intRowCount++;
	const table = sheet.getTable('tableVoPro');

	for (let ii = 0; ii < arrRegDocFilt.length; ii++) {
		row = sheet.getRow(intRowCount);
		row.alignment = {wrapText: true, vertical: 'top'};
		row.getCell('I').alignment = {horizontal: 'left'};
		table.addRow([
			arrRegDocFilt[ii].regulationNumber,
			arrRegDocFilt[ii].name,
			arrRegDocFilt[ii].createdAt,
			arrRegDocFilt[ii].createdBy,
			arrRegDocFilt[ii].status,
			arrRegDocFilt[ii].market.join(';\n'),
			arrRegDocFilt[ii].keywords.join(';\n'),
			arrRegDocFilt[ii].modelYear,
			arrRegDocFilt[ii].dateEffective,
			arrRegDocFilt[ii].dateNewRegistration,
			arrRegDocFilt[ii].dateNewTypes,
		]);

		intRowCount++;
	}

	table.commit();
	const strZipName = strPageName;
	await createZIPXL(workbook, strZipName, t);
};
