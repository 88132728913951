import {Stack, mergeStyleSets, useTheme} from '@fluentui/react';
import {NonSelectable} from 'features/RegulatoryDocuments/components/DocumentEditor/NonSelectable';
import React, {useMemo} from 'react';

export type HierarchyListIndicatorProps = {
	level: number;
	indicatorElementWidth?: number;
	horizontalLineY?: number;
};

export const HierarchyListIndicator: React.FC<HierarchyListIndicatorProps> = ({
	level,
	indicatorElementWidth = 24,
	horizontalLineY,
}) => {
	const theme = useTheme();

	const border = `1px solid ${theme.palette.neutralLighter}`;

	const classNames = useMemo(() => {
		return mergeStyleSets({root: {display: 'flex'}});
	}, []);

	return (
		<NonSelectable className={classNames.root}>
			{Array.from({length: level}, (_, i) => i).map(index => (
				<Stack
					key={index}
					horizontal
					styles={{root: {width: indicatorElementWidth}}}
				>
					<Stack
						verticalFill
						styles={{
							root: {
								width: indicatorElementWidth / 2,
							},
						}}
					>
						<Stack
							verticalFill
							styles={{
								root: {
									width: indicatorElementWidth / 2,
									height: level === index + 1 ? horizontalLineY : undefined,
								},
							}}
						/>
						<Stack
							verticalFill
							styles={{root: {width: indicatorElementWidth / 2}}}
						/>
					</Stack>
					<Stack
						verticalFill
						styles={{
							root: {
								width: indicatorElementWidth / 2,
								borderLeft: border,
							},
						}}
					>
						<Stack
							verticalFill
							styles={{
								root: {
									width: indicatorElementWidth / 2,
									height: level === index + 1 ? horizontalLineY : undefined,
									borderBottom: level === index + 1 ? border : undefined,
								},
							}}
						/>
						<Stack
							verticalFill
							styles={{root: {width: indicatorElementWidth / 2}}}
						/>
					</Stack>
				</Stack>
			))}
		</NonSelectable>
	);
};
