import {DefaultButton, Dialog, IDialogProps, Stack} from '@fluentui/react';
import {ActionButton} from 'components/ActionButton';
import React from 'react';

export type BaseDialogProps = Omit<IDialogProps, 'modalProps'> & {
	onConfirm: (ev?: any) => void;
	confirmText: string;
	cancelText: string;
};

export const BaseDialog: React.FC<BaseDialogProps> = ({
	confirmText,
	cancelText,
	onConfirm,
	onDismiss,
	...props
}) => {
	const onConfirmClick = React.useCallback(
		() => Promise.resolve(onConfirm()),
		[onConfirm],
	);
	return (
		<Dialog
			{...props}
			onDismiss={onDismiss}
			modalProps={{
				onDismiss: onDismiss as any,
				isBlocking: true,
				styles: {main: {maxWidth: 450}},
			}}
		>
			<Stack horizontal horizontalAlign='end' tokens={{childrenGap: 5}}>
				<Stack.Item>
					<ActionButton
						onClick={onConfirmClick}
						ariaLabel={'DialogConfirmButton'}
					>
						{confirmText}
					</ActionButton>
				</Stack.Item>
				<Stack.Item>
					<DefaultButton
						onClick={onDismiss as any}
						ariaLabel={'DialogCancelButton'}
					>
						{cancelText}
					</DefaultButton>
				</Stack.Item>
			</Stack>
		</Dialog>
	);
};
