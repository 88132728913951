import {IContextualMenuProps} from '@fluentui/react';
import {useConst} from '@fluentui/react-hooks';
import {useTranslation} from 'react-i18next';

const HELP_CHANNEL_LINK =
	'https://teams.microsoft.com/l/entity/2a527703-1f6f-4559-a332-d8a7d288cd88/_djb2_msteams_prefix_4052078798?context=%7b%22subEntityId%22:null%2c%22channelId%22:%2219:FAorKuRPa7aFv8u7TaCNSQuM_UJ0Euf0wlUYuSptuGY1%40thread.tacv2%22%7d&groupId=acaa0e4a-8ed6-4aff-95bc-b8c70f8bf164&tenantId=56564e0f-83d3-4b52-92e8-a6bb9ea36564&allowXTenantAccess=false';

export const useHelpDropdown = (showModal: () => void) => {
	const {t} = useTranslation('appShell/navigation', {
		keyPrefix: 'HelpMenu',
	});
	const dropdownMenuProps = useConst<IContextualMenuProps>(() => ({
		items: [
			{
				key: 'helpChannel',
				text: t('Teams'),
				href: HELP_CHANNEL_LINK,
				target: '_blank',
			},
			{
				key: 'infoModal',
				text: t('Info'),
				onClick: showModal,
			},
		],
	}));

	return dropdownMenuProps;
};
