import {useUserContext} from 'authentication/UserContext';
import {UserRole} from 'types';
import {LoadWrapper} from 'components/LoadWrapper';
import React from 'react';
import {useNavigate} from 'react-router-dom';

interface ViewAuthorizerProps {
	roles: UserRole[];
}

export const ViewAuthorizer: React.FC<
	ViewAuthorizerProps & {children: React.ReactNode}
> = ({roles, children}) => {
	const auth = useUserContext();
	const isAuthorized = roles.reduce(
		(prev, role) =>
			prev ||
			auth.roles.map(role => role.toLowerCase()).includes(role.toLowerCase()),
		false,
	);
	const navigate = useNavigate();

	if (!isAuthorized) {
		navigate('/');
		return null;
	}

	return (
		<LoadWrapper loading={!auth}>
			<>{children}</>
		</LoadWrapper>
	);
};
