import {useUserContext} from 'authentication/UserContext';
import {useTheme} from '@fluentui/react';
import React from 'react';
import {VexWorkbenchItems} from './VexWorkbenchItems';
import {MyPendingApprovalsItems} from './MyPendingApprovalsItems';
import {WorkbenchItems} from './WorkbenchItems';
import {getListClassNames} from 'styles';

type WorkbenchListProps = {
	halfHeight?: boolean;
	inProcess?: boolean;
};

export const WorkbenchList: React.FC<WorkbenchListProps> = ({
	halfHeight = false,
	inProcess = false,
}) => {
	const {isVex, isApprover} = useUserContext();
	const theme = useTheme();
	const classNames = getListClassNames(theme, halfHeight);

	return (
		<>
			{isApprover && (
				<div className={classNames.workbenchContainer}>
					<MyPendingApprovalsItems halfHeight />
				</div>
			)}
			<div className={classNames.workbenchContainer}>
				{isVex ? (
					<VexWorkbenchItems halfHeight inProcess={inProcess} />
				) : (
					<WorkbenchItems halfHeight />
				)}
			</div>
		</>
	);
};
