import React from 'react';
import {VexCluster} from 'types';
import {Text} from '@fluentui/react';

export type VexClusterOfVexClustersField = Pick<VexCluster, 'name' | 'id'>;

interface Props {
	cluster: VexClusterOfVexClustersField;
}

export const VexClusterItem: React.FC<Props> = ({cluster}) => {
	return (
		<li>
			<Text>{cluster.name}</Text>
		</li>
	);
};
