import React from 'react';

type DidDocumentChange = boolean;

type SetDidDocumentChange = React.Dispatch<
	React.SetStateAction<DidDocumentChange>
>;

export interface DocumentChangeStateInfo {
	didDocumentChange: DidDocumentChange;
	setDidDocumentChange: SetDidDocumentChange;
}

export const useDidDocumentChange = (): DocumentChangeStateInfo => {
	const [didDocumentChange, setDidDocumentChange] = React.useState(false);
	return {didDocumentChange, setDidDocumentChange};
};
