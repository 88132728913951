import {
	CommandBar,
	ICommandBarProps,
	ICommandBarStyles,
	mergeStyleSets,
	Theme,
	useTheme,
} from '@fluentui/react';
import * as React from 'react';
import {Command} from '../../hooks/useCommand';
import {CommandsContext} from 'context';

export type AppCommandBarComponentProps = ICommandBarProps & {
	hideWhenEmpty?: boolean;
};

/**
 * We omit the children here so we don't accidentally wrap components with the
 * command bar during tests.
 */
type AppCommandBarProps = Omit<
	AppCommandBarComponentProps,
	'items' | 'farItems' | 'children'
>;

const AppCommandBarComponent: React.FunctionComponent<
	AppCommandBarComponentProps
> = ({hideWhenEmpty = false, ...props}) => {
	const theme = useTheme();
	const {commandBarStyles} = getStyles(theme);

	if (
		hideWhenEmpty &&
		[...props.items, ...(props.farItems || [])].length === 0
	) {
		return null;
	}

	return <CommandBar {...props} styles={commandBarStyles} />;
};

/**
 * Wrapper for the Fluent UI command bar. To be used together with CommandsContext and the useCommand hook.
 * It will render all commands passed to the CommandsContext via useCommand.
 */
export const AppCommandBar: React.FunctionComponent<AppCommandBarProps> = ({
	hideWhenEmpty = false,
	...props
}) => {
	const {commands: allCommands} = React.useContext(CommandsContext);
	const commands = (allCommands || []).filter(command => !command.hidden);

	const cmpCommands = (c1: Command, c2: Command) => {
		if (c1.priority === undefined && c2.priority === undefined) {
			return 0;
		}

		if (c1.priority === undefined) {
			return -1;
		}

		if (c2.priority === undefined) {
			return 1;
		}

		return c1.priority - c2.priority;
	};

	const items = React.useMemo(
		() => commands.filter(command => !command.farCommand).sort(cmpCommands),
		[commands],
	);
	const farItems = React.useMemo(
		() => commands.filter(command => command.farCommand).sort(cmpCommands),
		[commands],
	);

	const [togglePosition, setTogglePostion] = React.useState<boolean>(false);

	const toggleMenuPosition = () => {
		if (window.scrollY >= 80) {
			setTogglePostion(true);
		} else {
			setTogglePostion(false);
		}
	};

	window.addEventListener('scroll', toggleMenuPosition);

	return (
		<AppCommandBarComponent
			{...props}
			items={items}
			farItems={farItems}
			hideWhenEmpty={hideWhenEmpty}
			className={togglePosition ? classNames.menuFixed : ''}
			id={'AppCommandBar'}
		/>
	);
};

const getStyles = (theme: Theme) => ({
	commandBarStyles: mergeStyleSets({
		root: {
			borderBottom: `1px solid ${theme.palette.neutralLight}`,
		},
	} as ICommandBarStyles),
});

const classNames = mergeStyleSets({
	menuFixed: {
		position: 'fixed',
		top: 0,
		width: '100%',
		zIndex: 99999,
	},
});
