import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VkoKeywordsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type VkoKeywordsQuery = { __typename?: 'Query', myVKOKeywords: Array<{ __typename?: 'Keyword', id: string, name: string }> };


export const VkoKeywordsDocument = gql`
    query VkoKeywords {
  myVKOKeywords {
    id
    name
  }
}
    `;

/**
 * __useVkoKeywordsQuery__
 *
 * To run a query within a React component, call `useVkoKeywordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVkoKeywordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVkoKeywordsQuery({
 *   variables: {
 *   },
 * });
 */
export function useVkoKeywordsQuery(baseOptions?: Apollo.QueryHookOptions<VkoKeywordsQuery, VkoKeywordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VkoKeywordsQuery, VkoKeywordsQueryVariables>(VkoKeywordsDocument, options);
      }
export function useVkoKeywordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VkoKeywordsQuery, VkoKeywordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VkoKeywordsQuery, VkoKeywordsQueryVariables>(VkoKeywordsDocument, options);
        }
export type VkoKeywordsQueryHookResult = ReturnType<typeof useVkoKeywordsQuery>;
export type VkoKeywordsLazyQueryHookResult = ReturnType<typeof useVkoKeywordsLazyQuery>;
export type VkoKeywordsQueryResult = Apollo.QueryResult<VkoKeywordsQuery, VkoKeywordsQueryVariables>;