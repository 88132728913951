import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetHeadlessRegulatoryDocumentsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetHeadlessRegulatoryDocumentsQuery = { __typename?: 'Query', regulatoryDocuments?: { __typename?: 'RegulatoryDocumentsConnection', edges?: Array<{ __typename?: 'RegulatoryDocumentsEdge', node: { __typename?: 'RegulatoryDocument', id: string, name: string, status: Types.RegulatoryDocumentStatus, summary?: string | null, origin: Types.Origin, createdAt?: any | null, modifiedAt?: any | null, topicManagementSummary?: { __typename?: 'TopicManagementSummary', quarter: Types.TopicManagementSummaryQuarter, year: number } | null, document?: { __typename?: 'BlobRef', uri: any, contentType: string, fileName: string } | null, createdBy: { __typename?: 'User', id: string, givenName?: string | null, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null }, modifiedBy: { __typename?: 'User', id: string, givenName?: string | null, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null } } }> | null } | null };

export type CreateHeadlessRegulatoryDocumentMutationVariables = Types.Exact<{
  input: Types.CreateRegulatoryDocumentInput;
}>;


export type CreateHeadlessRegulatoryDocumentMutation = { __typename?: 'Mutation', createRegulatoryDocument: { __typename?: 'RegulatoryDocumentPayload', regulatoryDocument: { __typename?: 'RegulatoryDocument', id: string } } };

export type UpdateHeadlessRegulatoryDocumentMutationVariables = Types.Exact<{
  input: Types.UpdateRegulatoryDocumentInput;
}>;


export type UpdateHeadlessRegulatoryDocumentMutation = { __typename?: 'Mutation', updateRegulatoryDocument: { __typename?: 'RegulatoryDocumentPayload', regulatoryDocument: { __typename?: 'RegulatoryDocument', id: string } } };

export type DeleteHeadlessRegulatoryDocumentMutationVariables = Types.Exact<{
  input: Types.DeleteRegulatoryDocumentInput;
}>;


export type DeleteHeadlessRegulatoryDocumentMutation = { __typename?: 'Mutation', deleteRegulatoryDocument: { __typename?: 'DeleteRegulatoryDocumentPayload', regulatoryDocument?: { __typename?: 'RegulatoryDocument', id: string } | null } };


export const GetHeadlessRegulatoryDocumentsDocument = gql`
    query GetHeadlessRegulatoryDocuments {
  regulatoryDocuments(
    where: {and: {regulationRef: {eq: null}, workflow: {status: {neq: ARCHIVED}}}}
  ) {
    edges {
      node {
        id
        name
        status
        summary
        topicManagementSummary {
          quarter
          year
        }
        document {
          uri
          contentType
          fileName
        }
        origin
        createdBy {
          id
          givenName
          mail
          name
          surname
          userPrincipalName
        }
        createdAt
        modifiedAt
        modifiedBy {
          id
          givenName
          mail
          name
          surname
          userPrincipalName
        }
      }
    }
  }
}
    `;

/**
 * __useGetHeadlessRegulatoryDocumentsQuery__
 *
 * To run a query within a React component, call `useGetHeadlessRegulatoryDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHeadlessRegulatoryDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHeadlessRegulatoryDocumentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHeadlessRegulatoryDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<GetHeadlessRegulatoryDocumentsQuery, GetHeadlessRegulatoryDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHeadlessRegulatoryDocumentsQuery, GetHeadlessRegulatoryDocumentsQueryVariables>(GetHeadlessRegulatoryDocumentsDocument, options);
      }
export function useGetHeadlessRegulatoryDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHeadlessRegulatoryDocumentsQuery, GetHeadlessRegulatoryDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHeadlessRegulatoryDocumentsQuery, GetHeadlessRegulatoryDocumentsQueryVariables>(GetHeadlessRegulatoryDocumentsDocument, options);
        }
export type GetHeadlessRegulatoryDocumentsQueryHookResult = ReturnType<typeof useGetHeadlessRegulatoryDocumentsQuery>;
export type GetHeadlessRegulatoryDocumentsLazyQueryHookResult = ReturnType<typeof useGetHeadlessRegulatoryDocumentsLazyQuery>;
export type GetHeadlessRegulatoryDocumentsQueryResult = Apollo.QueryResult<GetHeadlessRegulatoryDocumentsQuery, GetHeadlessRegulatoryDocumentsQueryVariables>;
export const CreateHeadlessRegulatoryDocumentDocument = gql`
    mutation CreateHeadlessRegulatoryDocument($input: CreateRegulatoryDocumentInput!) {
  createRegulatoryDocument(input: $input) {
    regulatoryDocument {
      id
    }
  }
}
    `;
export type CreateHeadlessRegulatoryDocumentMutationFn = Apollo.MutationFunction<CreateHeadlessRegulatoryDocumentMutation, CreateHeadlessRegulatoryDocumentMutationVariables>;

/**
 * __useCreateHeadlessRegulatoryDocumentMutation__
 *
 * To run a mutation, you first call `useCreateHeadlessRegulatoryDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHeadlessRegulatoryDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHeadlessRegulatoryDocumentMutation, { data, loading, error }] = useCreateHeadlessRegulatoryDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateHeadlessRegulatoryDocumentMutation(baseOptions?: Apollo.MutationHookOptions<CreateHeadlessRegulatoryDocumentMutation, CreateHeadlessRegulatoryDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateHeadlessRegulatoryDocumentMutation, CreateHeadlessRegulatoryDocumentMutationVariables>(CreateHeadlessRegulatoryDocumentDocument, options);
      }
export type CreateHeadlessRegulatoryDocumentMutationHookResult = ReturnType<typeof useCreateHeadlessRegulatoryDocumentMutation>;
export type CreateHeadlessRegulatoryDocumentMutationResult = Apollo.MutationResult<CreateHeadlessRegulatoryDocumentMutation>;
export type CreateHeadlessRegulatoryDocumentMutationOptions = Apollo.BaseMutationOptions<CreateHeadlessRegulatoryDocumentMutation, CreateHeadlessRegulatoryDocumentMutationVariables>;
export const UpdateHeadlessRegulatoryDocumentDocument = gql`
    mutation UpdateHeadlessRegulatoryDocument($input: UpdateRegulatoryDocumentInput!) {
  updateRegulatoryDocument(input: $input) {
    regulatoryDocument {
      id
    }
  }
}
    `;
export type UpdateHeadlessRegulatoryDocumentMutationFn = Apollo.MutationFunction<UpdateHeadlessRegulatoryDocumentMutation, UpdateHeadlessRegulatoryDocumentMutationVariables>;

/**
 * __useUpdateHeadlessRegulatoryDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateHeadlessRegulatoryDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHeadlessRegulatoryDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHeadlessRegulatoryDocumentMutation, { data, loading, error }] = useUpdateHeadlessRegulatoryDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateHeadlessRegulatoryDocumentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHeadlessRegulatoryDocumentMutation, UpdateHeadlessRegulatoryDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateHeadlessRegulatoryDocumentMutation, UpdateHeadlessRegulatoryDocumentMutationVariables>(UpdateHeadlessRegulatoryDocumentDocument, options);
      }
export type UpdateHeadlessRegulatoryDocumentMutationHookResult = ReturnType<typeof useUpdateHeadlessRegulatoryDocumentMutation>;
export type UpdateHeadlessRegulatoryDocumentMutationResult = Apollo.MutationResult<UpdateHeadlessRegulatoryDocumentMutation>;
export type UpdateHeadlessRegulatoryDocumentMutationOptions = Apollo.BaseMutationOptions<UpdateHeadlessRegulatoryDocumentMutation, UpdateHeadlessRegulatoryDocumentMutationVariables>;
export const DeleteHeadlessRegulatoryDocumentDocument = gql`
    mutation DeleteHeadlessRegulatoryDocument($input: DeleteRegulatoryDocumentInput!) {
  deleteRegulatoryDocument(input: $input) {
    regulatoryDocument {
      id
    }
  }
}
    `;
export type DeleteHeadlessRegulatoryDocumentMutationFn = Apollo.MutationFunction<DeleteHeadlessRegulatoryDocumentMutation, DeleteHeadlessRegulatoryDocumentMutationVariables>;

/**
 * __useDeleteHeadlessRegulatoryDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteHeadlessRegulatoryDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHeadlessRegulatoryDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHeadlessRegulatoryDocumentMutation, { data, loading, error }] = useDeleteHeadlessRegulatoryDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteHeadlessRegulatoryDocumentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteHeadlessRegulatoryDocumentMutation, DeleteHeadlessRegulatoryDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteHeadlessRegulatoryDocumentMutation, DeleteHeadlessRegulatoryDocumentMutationVariables>(DeleteHeadlessRegulatoryDocumentDocument, options);
      }
export type DeleteHeadlessRegulatoryDocumentMutationHookResult = ReturnType<typeof useDeleteHeadlessRegulatoryDocumentMutation>;
export type DeleteHeadlessRegulatoryDocumentMutationResult = Apollo.MutationResult<DeleteHeadlessRegulatoryDocumentMutation>;
export type DeleteHeadlessRegulatoryDocumentMutationOptions = Apollo.BaseMutationOptions<DeleteHeadlessRegulatoryDocumentMutation, DeleteHeadlessRegulatoryDocumentMutationVariables>;