import {
	FontIcon,
	IButtonProps,
	IFontIconProps,
	IRawStyle,
	IconButton,
} from '@fluentui/react';
import React from 'react';

export interface ActionTagProps
	extends IButtonProps,
		Pick<IFontIconProps, 'iconName'> {
	extraRootStyles?: IRawStyle;
}

export const ActionTag: React.FC<ActionTagProps> = ({
	iconName,
	extraRootStyles = {},
	...other
}) => {
	return (
		<IconButton
			styles={{
				root: {
					padding: 0,
					minWidth: 'auto',
					minHeight: 'auto',
					width: 'auto',
					height: 'auto',
					backgroundColor: 'transparent !important',
					color: `inherit !important`,
					...extraRootStyles,
				},
				flexContainer: {
					display: 'flex',
					alignItems: 'flex-start',
				},
			}}
			{...other}
		>
			<FontIcon iconName={iconName} />
		</IconButton>
	);
};
