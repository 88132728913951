import React, {useCallback, useMemo} from 'react';
import {EntityPage, EntityPageProps} from 'components';
import {LoadWrapper} from 'components/LoadWrapper';
import {createTooltipTranslationProviderFromNamespace} from 'features/localizedTooltips';
import {
	SimulationsDocument,
	useCreateSimulationMutation,
	useDeleteSimulationMutation,
	useSimulationsQuery,
	useUpdateSimulationMutation,
} from './simulationsPage.generated';
import {HeaderForPagesWithEntityPage} from 'components/HeaderForPagesWithEntityPage';
import {
	NAMESPACE_FOR_SIMULATIONS,
	useTranslationForSimulationsForm,
	useTranslationForSimulationsPage,
} from './SimulationsPage.utils';
import {Control, UseFormSetValue} from 'react-hook-form';
import {FormFieldsOfSimulationsPage} from './FormFieldsOfSimulationsPage/FormFieldsOfSimulationsPage';
import {
	ControlOfSimulationsPage,
	IFormFieldsOfSimulationsPage,
	PageSimulation,
	ProjectOfSimulationsPage,
} from './SimulationsPage.types';
import {CreateSimulationInput} from 'types';
import {
	CreateHeaderText,
	CreateTxtOfSaveBtn,
	RenderAdditionalFormElements,
} from 'components/EntityPage/EntityFormPanels';
import {PanelType, mergeStyleSets} from '@fluentui/react';
import _ from 'lodash';
import {FormMode} from 'components/EntityPage/EntityPage.types';
import {useEntityPageTranslation} from 'components/EntityPage/hooksForEntityFormPanels';
import {
	ColumnOfSimulationsPage,
	minWidthOfColOfSimulationsPage,
	useColumnsOfSimulationsPage,
} from './SimulationsPage.columns';
import {ExtraFieldsForNameColumn} from 'components/EntityPage/useEntityNameColumn';
import {createPathOfSimulationDetailsPage} from '../SimulationDetailsPage/simulationsPagePaths.utils';
import {NavigateFunction, useNavigate} from 'react-router-dom';

const Provider = createTooltipTranslationProviderFromNamespace(
	NAMESPACE_FOR_SIMULATIONS,
);

const emptyArray: never[] = [];
const refetchQueries = [SimulationsDocument];

export function SimulationsPage() {
	const {t} = useTranslationForSimulationsPage();
	const {t: getEntityPageTranslation} = useEntityPageTranslation();
	const {t: getFormTranslation} = useTranslationForSimulationsForm();

	const {data, loading} = useSimulationsQuery();
	const [deleteSimulation] = useDeleteSimulationMutation();
	const [updateSimulation] = useUpdateSimulationMutation();
	const [createSimulation] = useCreateSimulationMutation();

	const deleteEntity = useCallback(
		async (id: PageSimulation['id']): Promise<void> => {
			/**
			 * We must provide @see refetchQueries because EntityPage doesn't refetch
			 * them for deletions
			 */
			await deleteSimulation({variables: {input: {id}}, refetchQueries});
		},
		[deleteSimulation],
	);

	const updateEntity = useCallback(
		async (newSimulation: PageSimulation): Promise<void> => {
			await updateSimulation({
				/**
				 * Note that passing extra properties will produce an error.
				 */
				variables: {input: _.pick(newSimulation, ['name', 'id'])},
				refetchQueries,
			});
		},
		[updateSimulation],
	);

	const createEntity = useCallback(
		async (formFieldsWithIncorrectType: PageSimulation): Promise<void> => {
			const createInput = (): CreateSimulationInput => {
				const formFields =
					formFieldsWithIncorrectType as unknown as IFormFieldsOfSimulationsPage;

				const {vehicleProjects = [], name} = formFields;

				const vehicleProject: ProjectOfSimulationsPage | undefined =
					vehicleProjects[0];

				/**
				 * Note that providing any extra fields here will produce an error.
				 */
				return {
					name,
					modelSeriesReference: vehicleProject?.modelSeries,
					generationReference: vehicleProject?.generation,
				};
			};

			await createSimulation({
				variables: {input: createInput()},
				refetchQueries,
			});
		},
		[createSimulation],
	);

	const renderExtraFormElements: RenderAdditionalFormElements<PageSimulation> =
		useCallback(
			(
				controlWithIncorrectType,
				mode,
				_selectedItem,
				setValue,
			): JSX.Element => {
				/**
				 * We assert some props here because the EntityPage incorrectly assumes
				 * that the form fields' type is the same as the entity's type.
				 *
				 * We also assert some props here twice. We do this because the 2nd assertion
				 * tells other devs what the type *should* be. If we left it as "any,"
				 * that wouldn't be clear.
				 */
				return (
					<FormFieldsOfSimulationsPage
						control={
							controlWithIncorrectType as Control<any> as ControlOfSimulationsPage
						}
						mode={mode}
						setValue={
							setValue as UseFormSetValue<any> as UseFormSetValue<IFormFieldsOfSimulationsPage>
						}
					/>
				);
			},
			[],
		);

	const navigate: NavigateFunction = useNavigate();

	const handleViewingItem = useCallback(
		(simulation: PageSimulation) => {
			const path: string = createPathOfSimulationDetailsPage(simulation.id);
			navigate(path);
		},
		[navigate],
	);

	const extraFieldsForNameColumn = useMemo((): ExtraFieldsForNameColumn => {
		return {minWidth: minWidthOfColOfSimulationsPage};
	}, []);

	const entityDisplayName = t('EntityName');

	const panelProps: EntityPageProps<PageSimulation>['propsOfEntityFormPanel'] =
		useMemo(() => {
			const createHeaderText: CreateHeaderText = mode => {
				if (mode === FormMode.Create) return getFormTranslation('HeaderText');
				if (mode === FormMode.Update)
					return getEntityPageTranslation('EditEntity', {entityDisplayName});
				return '';
			};

			const createTxtOfSaveBtn: CreateTxtOfSaveBtn = mode => {
				if (mode === FormMode.Create) return getFormTranslation('TxtOfSaveBtn');
				return getEntityPageTranslation('Save');
			};

			return {
				propsOfFormPanel: {
					isFooterAtBottom: true,
					createHeaderText,
					createTxtOfSaveBtn,
				},
			};
		}, [getFormTranslation, getEntityPageTranslation, entityDisplayName]);

	const classNames = useMemo(() => {
		/**
		 * We must consider this in addition to the header's height because the
		 * margin affects the space available after the header.
		 */
		const titleMarginBottom = '1.0625rem';
		const heightOfHeader = '2.625rem';

		return mergeStyleSets({
			entityPage: {
				height: `calc(100% - (${heightOfHeader} + ${titleMarginBottom}))`,
			},
		});
	}, []);

	const columns: ColumnOfSimulationsPage[] = useColumnsOfSimulationsPage();

	/**
	 * We use a fragment because the list won't show up otherwise.
	 */
	return (
		<>
			{/* Make sure to update the EntityPage's height if we change the header */}
			<HeaderForPagesWithEntityPage>
				<h3>{t('PageTitle')}</h3>
			</HeaderForPagesWithEntityPage>
			<LoadWrapper loading={loading}>
				<Provider>
					<EntityPage
						items={data?.simulations ?? emptyArray}
						entityDisplayName={entityDisplayName}
						deleteEntity={deleteEntity}
						updateEntity={updateEntity}
						createEntity={createEntity}
						renderAdditionalFormElements={renderExtraFormElements}
						additionalColumns={columns}
						extraFieldsForNameColumn={extraFieldsForNameColumn}
						panelType={PanelType.medium}
						propsOfEntityFormPanel={panelProps}
						// To prevent a double scrollbar
						shouldWrapListWithScrollablePane={false}
						className={classNames.entityPage}
						onViewItem={handleViewingItem}
					/>
				</Provider>
			</LoadWrapper>
		</>
	);
}
