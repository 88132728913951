import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCompareDocumentDataQueryVariables = Types.Exact<{
  regulatoryDocumentId: Types.Scalars['ID'];
}>;


export type GetCompareDocumentDataQuery = { __typename?: 'Query', regulatoryDocument?: { __typename?: 'RegulatoryDocument', id: string, name: string, paragraphs: Array<{ __typename?: 'RegulatoryDocumentParagraph', id: string, enumeration: string, level: number, page: number, elements: Array<{ __typename?: 'RegulatoryDocumentParagraphElement', type: Types.ParagraphElementType, text?: string | null, page: number, bounds?: Array<number> | null, isHeader: boolean, assetId?: string | null, asset?: { __typename?: 'BlobRef', uri: any } | null }>, vehicleCategories: Array<{ __typename?: 'VehicleCategory', id: string, name: string }>, driveVariants: Array<{ __typename?: 'DriveVariant', id: string, name: string }>, tags: Array<{ __typename?: 'Tag', id: string, name: string }>, requirements: Array<{ __typename?: 'Requirement', id: string, name: string, status: Types.RequirementStatus, version?: string | null }>, categories: Array<{ __typename?: 'Category', id: string, name: string }>, keywords: Array<{ __typename?: 'Keyword', id: string, name: string }> }> } | null };


export const GetCompareDocumentDataDocument = gql`
    query GetCompareDocumentData($regulatoryDocumentId: ID!) {
  regulatoryDocument(id: $regulatoryDocumentId) {
    id
    name
    paragraphs {
      id
      enumeration
      level
      page
      elements {
        type
        text
        page
        bounds
        isHeader
        assetId
        asset {
          uri
        }
      }
      vehicleCategories {
        id
        name
      }
      driveVariants {
        id
        name
      }
      tags {
        id
        name
      }
      requirements {
        id
        name
        status
        version
      }
      categories {
        id
        name
      }
      keywords {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetCompareDocumentDataQuery__
 *
 * To run a query within a React component, call `useGetCompareDocumentDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompareDocumentDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompareDocumentDataQuery({
 *   variables: {
 *      regulatoryDocumentId: // value for 'regulatoryDocumentId'
 *   },
 * });
 */
export function useGetCompareDocumentDataQuery(baseOptions: Apollo.QueryHookOptions<GetCompareDocumentDataQuery, GetCompareDocumentDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompareDocumentDataQuery, GetCompareDocumentDataQueryVariables>(GetCompareDocumentDataDocument, options);
      }
export function useGetCompareDocumentDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompareDocumentDataQuery, GetCompareDocumentDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompareDocumentDataQuery, GetCompareDocumentDataQueryVariables>(GetCompareDocumentDataDocument, options);
        }
export type GetCompareDocumentDataQueryHookResult = ReturnType<typeof useGetCompareDocumentDataQuery>;
export type GetCompareDocumentDataLazyQueryHookResult = ReturnType<typeof useGetCompareDocumentDataLazyQuery>;
export type GetCompareDocumentDataQueryResult = Apollo.QueryResult<GetCompareDocumentDataQuery, GetCompareDocumentDataQueryVariables>;