import * as React from 'react';
import {Regulation} from 'types';

/**
 * I omitted these fields because they are unnecessary and could cause type
 * errors with certain pages.
 */
type SelectedRegulation = Omit<
	Partial<Regulation>,
	| 'keywords'
	| 'mainKeywords'
	| 'markets'
	| 'standardPlusPcmsClusters'
	| 'attachments'
>;

export type RegulationsContextProps = {
	/**
	 * @see SelectedRegulation
	 */
	selectedRegulation: SelectedRegulation | null;
	/**
	 * @see SelectedRegulation
	 */
	setSelectedRegulation: (regulation: SelectedRegulation | null) => void;
};

type RegulationsContextValue =
	| RegulationsContextProps
	| Pick<RegulationsContextProps, 'selectedRegulation'>;

export const RegulationsContext = React.createContext<RegulationsContextValue>({
	selectedRegulation: null,
});
