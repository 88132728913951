import {mergeStyleSets} from '@fluentui/merge-styles';
import React from 'react';

interface Props {
	children: React.ReactNode;
}

export const HeaderForPagesWithEntityPage: React.FC<Props> = ({children}) => {
	const classNames = mergeStyleSets({
		root: {marginLeft: 20, marginTop: 20},
	});

	return <div className={classNames.root}>{children}</div>;
};
