import {DefaultButton, Panel, PanelType, PrimaryButton} from '@fluentui/react';
import {useUserContext} from 'authentication/UserContext';
import {ConfirmUpdateDialog, useDialogState} from 'components/Dialogs';
import {
	ControlledDropdown,
	ControlledTagPicker,
	ControlledTextField,
} from 'components/hookForms';
import {GetWorkbenchItemsVexDocument} from 'features/Dashboard/hooks/workbenchItems.generated';
import {useRegulatoryDocumentsContext} from 'features/RegulatoryDocuments/context';
import {useConcludeRegulatoryDocumentMutation} from 'features/RegulatoryDocuments/hooks/useConcludeRegulatoryDocument.generated';
import {
	GetRegulatoryDocumentDetailsDocument,
	GetVexRegulatoryDocumentParagraphsDocument,
} from 'features/RegulatoryDocuments/hooks/useGetRegulatoryDocumentDetails.generated';
import {useUpdateVexClusterAssignmentBatchMutation} from 'features/RegulatoryDocuments/hooks/useUpdateVexClusterAssignment.generated';
import {useUpdateInterimRequirementStatusMutation} from './hooks/updateInterimRequirement.generated';
import {ProviderThatEnablesGettingTooltipsFromContext} from 'features/localizedTooltips';
import {useCommand, useNotificationBar} from 'hooks';
import React from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {Keyword, KeywordStatus, UserRole, WorkflowStatus} from 'types';
import {KeywordAssignmentFormTooltipTranslationProvider} from './KeywordAssignmentFormTooltipTranslationProvider';
import {useParagraphsContext} from './ParagraphsContext';
import {useKeywordAssignmentsFormData} from './hooks/useKeywordAssignmentsFormData';

enum FormMode {
	None,
	Reject,
	Approve,
	RequirementsDerived,
}

type KeywordAssignmentFormValues = {
	keywordId: string;
	vexClusterId: string;
	rejectReason?: string;
	keywords?: Keyword[];
};

export const KeywordAssignmentForm = () => {
	/** Hooks */
	const {t} = useTranslation('features/regulatorydocuments', {
		keyPrefix: 'KeywordAssignmentForm',
	});

	const [searchParams] = useSearchParams();
	const filter = searchParams.get('filter') ?? '';

	const [formMode, setFormMode] = React.useState<FormMode>(FormMode.None);
	const headerText = React.useMemo(() => {
		if (formMode === FormMode.Reject) {
			return t('RejectKeywordPanelHeader');
		}

		return t('ApproveKeywordPanelHeader');
	}, [formMode]);

	const {selectedRegulatoryDocument} = useRegulatoryDocumentsContext();
	const {selectedParagraphs} = useParagraphsContext();
	const {isVex, isAdmin, myVexClusters} = useUserContext();
	const [updateDialogState, showUpdateDialog, hideUpdateDialog] =
		useDialogState();

	const [updateVexClusterAssignmentBatch] =
		useUpdateVexClusterAssignmentBatchMutation();

	const [updateInterimRequirementStatus] =
		useUpdateInterimRequirementStatusMutation();

	const [concludeRegulatoryDocument] = useConcludeRegulatoryDocumentMutation();

	const {setMessage} = useNotificationBar();

	const {handleSubmit, control, reset, watch} =
		useForm<KeywordAssignmentFormValues>({
			reValidateMode: 'onSubmit',
			mode: 'all',
		});

	React.useEffect(() => {
		reset({});
	}, [formMode]);

	/** Prepare data for pickers */
	const {
		additionalKeywordOptions,
		defaultKeyword,
		defaultVexCluster,
		selectableKeywords,
		selectableVexClusters,
	} = useKeywordAssignmentsFormData(watch('keywordId'));

	const keywordOptions = React.useMemo(() => {
		return selectableKeywords.map(k => ({
			key: k.id,
			text: k.name,
		}));
	}, [selectableKeywords]);

	const vexClusterOptions = React.useMemo(() => {
		return selectableVexClusters.map(vc => ({
			key: vc.id,
			text: vc.name,
		}));
	}, [selectableVexClusters]);

	/** Commands */

	useCommand(
		{
			key: 'keywordAssignment',
			text: t('KeywordAssignmentCommand'),
			priority: -1,
			iconProps: {
				iconName: 'ReminderGroup',
			},
			hidden:
				selectedRegulatoryDocument?.workflow?.status !==
				WorkflowStatus.Finalized,
			roles: [UserRole.SystemAdministrator, UserRole.Vex],
			disabled: selectedParagraphs.length === 0,
			subMenuProps: {
				items: [
					{
						key: 'approveAssignment',
						text: t('ApproveCommand'),
						iconProps: {
							iconName: 'Accept',
						},
						onClick() {
							setFormMode(FormMode.Approve);
						},
					},
					{
						key: 'rejectAssignment',
						text: t('RejectCommand'),
						iconProps: {
							iconName: 'Cancel',
						},
						onClick() {
							setFormMode(FormMode.Reject);
						},
					},
				],
			},
		},
		[formMode, selectedParagraphs],
	);

	const paragraphsToDo = React.useMemo(() => {
		if (!selectedRegulatoryDocument?.paragraphs) {
			return undefined;
		}

		const myVexClusterIds = myVexClusters.map(vc => vc.id);

		return selectedRegulatoryDocument.paragraphs.filter(p =>
			p.keywordAssignments?.some(kwa =>
				kwa.vexClusterAssignments.some(
					vca =>
						myVexClusterIds.includes(vca.vexCluster.id) &&
						vca.keywordStatus === KeywordStatus.New,
				),
			),
		);
	}, [selectedRegulatoryDocument, myVexClusters]);

	const isConcludeDisabled = React.useMemo(() => {
		return paragraphsToDo?.length !== 0;
	}, [paragraphsToDo]);

	useCommand(
		{
			farCommand: true,
			priority: -1,
			title: isConcludeDisabled
				? t('ConcludeCommandDisabled', {count: paragraphsToDo?.length})
				: t('ConcludeCommand'),
			key: 'concludeDocument',
			iconProps: {
				iconName: 'CheckboxComposite',
			},
			roles: [UserRole.SystemAdministrator, UserRole.Vex],
			disabled: isConcludeDisabled,
			hidden: filter !== 'inProcess',
			onClick() {
				showUpdateDialog();
			},
		},
		[
			isVex,
			isAdmin,
			selectedRegulatoryDocument,
			isConcludeDisabled,
			paragraphsToDo,
			filter,
		],
	);

	/** Submit handlers */
	const keywordStatus = React.useMemo(() => {
		if (formMode === FormMode.Reject) {
			return KeywordStatus.Declined;
		}

		if (formMode === FormMode.Approve) {
			return KeywordStatus.Accepted;
		}

		if (formMode === FormMode.RequirementsDerived) {
			return KeywordStatus.RequirementsDerived;
		}

		return KeywordStatus.New;
	}, [formMode]);

	const handleSubmitClick = React.useCallback(() => {
		handleSubmit((item: KeywordAssignmentFormValues) => {
			updateVexClusterAssignmentBatch({
				variables: {
					input: {
						keywordId: item.keywordId,
						keywordStatus,
						paragraphIds: selectedParagraphs?.map(p => p.id),
						regulatoryDocumentId: selectedRegulatoryDocument?.id ?? '',
						rejectReason:
							formMode === FormMode.Reject ? item.rejectReason ?? '' : '',
						vexClusterId: item.vexClusterId,
						suggestedKeywordIds: item.keywords?.map(k => k.id) ?? [],
					},
				},
				refetchQueries: [
					GetWorkbenchItemsVexDocument,
					GetVexRegulatoryDocumentParagraphsDocument,
					GetRegulatoryDocumentDetailsDocument,
				],
			});

			if (item) {
				if (formMode === FormMode.Reject) {
					setMessage(t('RejectMessage'));
				}

				if (formMode === FormMode.Approve) {
					setMessage(t('ApproveMessage'));
				}

				if (formMode === FormMode.RequirementsDerived) {
					setMessage(t('RequirementsDerivedMessage'));
				}
			}

			setFormMode(FormMode.None);
			reset({});
		})();
	}, [selectedParagraphs, selectedRegulatoryDocument, formMode, keywordStatus]);

	const handleCancelClick = React.useCallback(() => {
		setFormMode(FormMode.None);
		reset({});
	}, [formMode]);

	const handleConclusionSubmit = React.useCallback(() => {
		handleSubmit(async () => {
			await concludeRegulatoryDocument({
				variables: {
					input: {
						regulatoryDocumentId: selectedRegulatoryDocument?.id ?? '',
					},
				},
				refetchQueries: [
					GetVexRegulatoryDocumentParagraphsDocument,
					GetRegulatoryDocumentDetailsDocument,
				],
			})
				.then(() =>
					updateInterimRequirementStatus({
						variables: {
							regulatoryDocumentId: selectedRegulatoryDocument?.id ?? '',
						},
					}),
				)
				.catch(error => console.error('Error:', error));
		})().catch(error => console.error('Error:', error));
	}, [selectedRegulatoryDocument]);

	const onConfirmConclusion = React.useCallback(() => {
		handleConclusionSubmit();
		hideUpdateDialog();
	}, [handleConclusionSubmit]);

	/** Component */
	const renderFooterContent: any = () => (
		<form>
			{formMode === FormMode.Reject && (
				<PrimaryButton styles={PrimaryButtonStyles} onClick={handleSubmitClick}>
					{t('RejectButton')}
				</PrimaryButton>
			)}
			{formMode === FormMode.Approve && (
				<PrimaryButton styles={PrimaryButtonStyles} onClick={handleSubmitClick}>
					{t('ApproveButton')}
				</PrimaryButton>
			)}
			{formMode === FormMode.RequirementsDerived && (
				<PrimaryButton styles={PrimaryButtonStyles} onClick={handleSubmitClick}>
					{t('ConfirmButton')}
				</PrimaryButton>
			)}
			<DefaultButton onClick={handleCancelClick}>
				{t('CancelButton')}
			</DefaultButton>
		</form>
	);

	return (
		<React.Fragment>
			<Panel
				isLightDismiss
				isFooterAtBottom={true}
				onDismiss={handleCancelClick}
				isOpen={formMode !== FormMode.None}
				type={PanelType.medium}
				closeButtonAriaLabel='Close'
				headerText={headerText}
				onRenderFooterContent={renderFooterContent}
			>
				<KeywordAssignmentFormTooltipTranslationProvider>
					<ProviderThatEnablesGettingTooltipsFromContext>
						<ControlledDropdown
							control={control}
							name='keywordId'
							label={t('KeywordDropdownLabel')}
							options={keywordOptions}
							defaultValue={defaultKeyword?.id}
							rules={{required: t('KeywordDropdownRequired')}}
							required
						/>
						<ControlledDropdown
							control={control}
							name='vexClusterId'
							label={t('VexClusterDropdownLabel')}
							options={vexClusterOptions}
							defaultValue={defaultVexCluster?.id}
							rules={{required: t('VexClusterDropdownRequired')}}
							required
						/>
						<React.Fragment>
							{formMode === FormMode.Reject && (
								<ControlledTextField
									label={t('RejectReasonTextFieldLabel')}
									name={'rejectReason'}
									control={control}
									multiline
									autoAdjustHeight
								/>
							)}
							{(formMode === FormMode.Approve ||
								formMode === FormMode.Reject) && (
								<ControlledTagPicker
									label={t('KeywordsLabel')}
									name={'keywords'}
									control={control}
									selectableItems={additionalKeywordOptions}
								/>
							)}
						</React.Fragment>
					</ProviderThatEnablesGettingTooltipsFromContext>
				</KeywordAssignmentFormTooltipTranslationProvider>
			</Panel>
			<ConfirmUpdateDialog
				{...updateDialogState}
				onConfirm={onConfirmConclusion}
				dialogContentProps={{
					subText: t('ConfirmDialog'),
					title: t('ConcludeCommand'),
				}}
				confirmButtonLabel={t('ConfirmButtonLabel')}
			/>
		</React.Fragment>
	);
};

const PrimaryButtonStyles = {
	root: {
		marginRight: 8,
	},
};
