import * as Types from '../types/types.generated';

import { gql } from '@apollo/client';
export type AttachmentFieldsFragment = { __typename?: 'AttachmentRef', attachmentId: string, isVkoOnly?: boolean | null, file: { __typename?: 'BlobRef', uri: any, contentType: string, fileName: string }, category?: { __typename?: 'AttachmentCategory', id: string, name: string } | null };

export const AttachmentFieldsFragmentDoc = gql`
    fragment AttachmentFields on AttachmentRef {
  attachmentId
  file {
    uri
    contentType
    fileName
  }
  category {
    id
    name
  }
  isVkoOnly
}
    `;