import React from 'react';
import {getListClassNames} from 'styles';
import {useTheme, Stack, FontIcon, Text} from '@fluentui/react';
import {Link} from 'react-router-dom';

export const ActivityListHeader = ({
	title,
	halfHeight = false,
	showOpenInNewWindow = false,
}: ActivityListHeaderProps) => {
	const theme = useTheme();
	const classNames = getListClassNames(theme, halfHeight);

	return (
		<div>
			<Stack horizontal horizontalAlign='space-between'>
				<Stack.Item>
					<Text className={classNames.activity}>{title}</Text>
				</Stack.Item>
				{showOpenInNewWindow && (
					<Stack.Item>
						<Link to='/recents'>
							<FontIcon
								iconName='OpenInNewWindow'
								className={classNames.iconStyles}
							/>
						</Link>
					</Stack.Item>
				)}
			</Stack>
		</div>
	);
};

export type ActivityListHeaderProps = {
	title: string;
	halfHeight?: boolean;
	showOpenInNewWindow?: boolean;
};
