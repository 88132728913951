import React, {useMemo} from 'react';
import {Text, mergeStyleSets} from '@fluentui/react';

interface Props {
	msg: string;
}

export const MsgForNoItemsFound = ({msg}: Props): JSX.Element => {
	const classNames = useMemo(() => {
		return mergeStyleSets({root: {padding: '1rem'}});
	}, []);

	return (
		<div className={classNames.root}>
			<Text>{msg}</Text>
		</div>
	);
};
