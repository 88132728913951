import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetRegulatoryDocumentQueryVariables = Types.Exact<{
  regulatoryDocumentId: Types.Scalars['ID'];
}>;


export type GetRegulatoryDocumentQuery = { __typename?: 'Query', regulatoryDocument?: { __typename?: 'RegulatoryDocument', id: string, name: string, status: Types.RegulatoryDocumentStatus, summary?: string | null, origin: Types.Origin, documentStatus: Types.DocumentStatus, createdAt?: any | null, modifiedAt?: any | null, regulation?: { __typename?: 'Regulation', id: string, name: string, regulationNumber: string } | null, document?: { __typename?: 'BlobRef', uri: any, contentType: string, fileName: string } | null, workflow: { __typename?: 'Workflow', status: Types.WorkflowStatus, message?: string | null }, paragraphs: Array<{ __typename?: 'RegulatoryDocumentParagraph', id: string, enumeration: string, level: number, page: number, isFootnote: boolean, elements: Array<{ __typename?: 'RegulatoryDocumentParagraphElement', type: Types.ParagraphElementType, text?: string | null, assetId?: string | null, csvContent?: string | null, page: number, bounds?: Array<number> | null, isHeader: boolean, textPosition?: Types.TextPosition | null, asset?: { __typename?: 'BlobRef', uri: any, contentType: string, fileName: string } | null }> }>, predecessor?: { __typename?: 'RegulatoryDocument', id: string, name: string } | null, successor?: { __typename?: 'RegulatoryDocument', id: string, name: string } | null } | null };


export const GetRegulatoryDocumentDocument = gql`
    query GetRegulatoryDocument($regulatoryDocumentId: ID!) {
  regulatoryDocument(id: $regulatoryDocumentId) {
    id
    name
    regulation {
      id
      name
      regulationNumber
    }
    document {
      uri
      contentType
      fileName
    }
    status
    summary
    origin
    workflow {
      status
      message
    }
    documentStatus
    paragraphs {
      id
      enumeration
      level
      page
      isFootnote
      elements {
        type
        text
        assetId
        asset {
          uri
          contentType
          fileName
        }
        csvContent
        page
        bounds
        isHeader
        textPosition
      }
    }
    createdAt
    modifiedAt
    predecessor {
      id
      name
    }
    successor {
      id
      name
    }
  }
}
    `;

/**
 * __useGetRegulatoryDocumentQuery__
 *
 * To run a query within a React component, call `useGetRegulatoryDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegulatoryDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegulatoryDocumentQuery({
 *   variables: {
 *      regulatoryDocumentId: // value for 'regulatoryDocumentId'
 *   },
 * });
 */
export function useGetRegulatoryDocumentQuery(baseOptions: Apollo.QueryHookOptions<GetRegulatoryDocumentQuery, GetRegulatoryDocumentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRegulatoryDocumentQuery, GetRegulatoryDocumentQueryVariables>(GetRegulatoryDocumentDocument, options);
      }
export function useGetRegulatoryDocumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRegulatoryDocumentQuery, GetRegulatoryDocumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRegulatoryDocumentQuery, GetRegulatoryDocumentQueryVariables>(GetRegulatoryDocumentDocument, options);
        }
export type GetRegulatoryDocumentQueryHookResult = ReturnType<typeof useGetRegulatoryDocumentQuery>;
export type GetRegulatoryDocumentLazyQueryHookResult = ReturnType<typeof useGetRegulatoryDocumentLazyQuery>;
export type GetRegulatoryDocumentQueryResult = Apollo.QueryResult<GetRegulatoryDocumentQuery, GetRegulatoryDocumentQueryVariables>;