import {
	ActionButton,
	DefaultButton,
	Panel,
	PanelType,
	PrimaryButton,
	Separator,
	Stack,
	TextField,
} from '@fluentui/react';
import {useUserContext} from 'authentication/UserContext';
import {FAQLevel, FAQList} from 'components/FAQList/FAQList';
import {GetRegulationDocument} from 'features/Regulations/hooks';
import {GetRegulatoryDocumentDetailsDocument} from 'features/RegulatoryDocuments/hooks/useGetRegulatoryDocumentDetails.generated';
import {useCommand} from 'hooks';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {Faq} from 'types';
import {
	useAddRegulationFaqMutation,
	useAddRegulatoryDocumentFaqMutation,
	useAddRegulatoryDocumentParagraphFaqMutation,
} from './hooks/useAddFaq.generated';

type FAQFormPropType = {
	faqs: Faq[];
	level: FAQLevel;
};

/**
 * This component appears to be always hidden.
 */
export const FAQForm: React.FC<FAQFormPropType> = ({faqs, level}) => {
	const [showPanel, setShowPanel] = React.useState(false);
	const [showInput, setShowInput] = React.useState(false);
	const questionInputRef = React.useRef<any>();
	const answerInputRef = React.useRef<any>();
	const {regulationId, regulatoryDocumentId, paragraphId}: any = useParams();
	const [addRegulationFaq] = useAddRegulationFaqMutation();
	const [addRegulatoryDocumentFaq] = useAddRegulatoryDocumentFaqMutation();
	const [addParagraphFaq] = useAddRegulatoryDocumentParagraphFaqMutation();

	const {t} = useTranslation('features/regulatorydocuments', {
		keyPrefix: 'ParagraphFAQForm',
	});

	const {isAdmin} = useUserContext();

	useCommand(
		{
			key: 'showFAQs',
			iconProps: {
				iconName: 'BookAnswers',
			},
			farCommand: true,
			onClick() {
				setShowPanel(true);
			},
			title: t('FAQs'),
			hidden: true,
			priority: 3,
		},
		[showPanel],
	);

	const handleCancelClick = React.useCallback(() => {
		setShowPanel(false);
		setShowInput(false);
	}, [showPanel, showInput]);

	const handleAddRegulationFaq = React.useCallback(
		() =>
			addRegulationFaq({
				variables: {
					input: {
						id: regulationId,
						question: questionInputRef.current.value,
						answer: answerInputRef.current.value,
					},
				},
				refetchQueries: [GetRegulationDocument],
			}),
		[addRegulationFaq, regulationId, questionInputRef, answerInputRef],
	);

	const handleAddRegulatoryDocumentFaq = React.useCallback(
		() =>
			addRegulatoryDocumentFaq({
				variables: {
					input: {
						id: regulatoryDocumentId,
						question: questionInputRef.current.value,
						answer: answerInputRef.current.value,
					},
				},
				refetchQueries: [GetRegulatoryDocumentDetailsDocument],
			}),
		[
			regulatoryDocumentId,
			questionInputRef,
			answerInputRef,
			addRegulatoryDocumentFaq,
		],
	);

	const handleAddParagraphFaq = React.useCallback(
		() =>
			addParagraphFaq({
				variables: {
					input: {
						faqInput: {
							id: regulatoryDocumentId,
							question: questionInputRef.current.value,
							answer: answerInputRef.current.value,
						},
						paragraphId: paragraphId || '',
					},
				},
				refetchQueries: [GetRegulatoryDocumentDetailsDocument],
			}),
		[
			regulatoryDocumentId,
			questionInputRef,
			answerInputRef,
			paragraphId,
			addParagraphFaq,
		],
	);

	return (
		<Panel
			type={PanelType.medium}
			isOpen={showPanel}
			isLightDismiss
			onDismiss={handleCancelClick}
			headerText={t('FaqPanelHeaderText')}
		>
			<FAQList
				items={faqs}
				regulationId={regulationId}
				regulatoryDocumentId={regulatoryDocumentId}
				level={level}
				paragraphId={paragraphId}
			/>
			<Separator />
			{!showInput && isAdmin && (
				<ActionButton
					iconProps={{iconName: 'Add'}}
					text={t('AddFaqButton')}
					onClick={() => setShowInput(true)}
				/>
			)}
			{showInput && isAdmin && (
				<Stack>
					<TextField
						label={t('AddQuestionLabel')}
						componentRef={questionInputRef}
					/>
					<TextField
						label={t('AddAnswerLabel')}
						componentRef={answerInputRef}
					/>
					<Stack horizontal tokens={{childrenGap: 8}} styles={stackStyles}>
						<Stack.Item>
							<PrimaryButton
								text={t('Add')}
								onClick={() => {
									if (level === FAQLevel.Regulation) {
										handleAddRegulationFaq();
									}

									if (level === FAQLevel.RegulatoryDocument) {
										handleAddRegulatoryDocumentFaq();
									}

									if (level === FAQLevel.Paragraph) {
										handleAddParagraphFaq();
									}

									setShowInput(false);
								}}
								action={'enter'}
							/>
						</Stack.Item>
						<Stack.Item>
							<DefaultButton
								text={t('Cancel')}
								onClick={() => setShowInput(false)}
							/>
						</Stack.Item>
					</Stack>
				</Stack>
			)}
		</Panel>
	);
};

const stackStyles = {
	root: {paddingTop: 5},
};
