import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EvaluateRegulationsQueryVariables = Types.Exact<{
  personId: Types.Scalars['ID'];
}>;


export type EvaluateRegulationsQuery = { __typename?: 'Query', evaluateRegulations: Array<{ __typename?: 'Regulation', id: string, name: string, regulationNumber: string }> };

export type EvaluateRegulatoryDocumentsQueryVariables = Types.Exact<{
  personId: Types.Scalars['ID'];
}>;


export type EvaluateRegulatoryDocumentsQuery = { __typename?: 'Query', evaluateRegulatoryDocuments: Array<{ __typename?: 'RegulatoryDocument', id: string, name: string }> };

export type EvaluateRegulatoryDocumentParagraphsQueryVariables = Types.Exact<{
  personId: Types.Scalars['ID'];
}>;


export type EvaluateRegulatoryDocumentParagraphsQuery = { __typename?: 'Query', evaluateRegulatoryDocumentParagraphs: Array<{ __typename?: 'RegulatoryDocumentParagraph', id: string, enumeration: string }> };

export type EvaluateRequirementsQueryVariables = Types.Exact<{
  personId: Types.Scalars['ID'];
}>;


export type EvaluateRequirementsQuery = { __typename?: 'Query', evaluateRequirements: Array<{ __typename?: 'Requirement', id: string, name: string }> };

export type EvaluateVehicleProjectsQueryVariables = Types.Exact<{
  personId: Types.Scalars['ID'];
}>;


export type EvaluateVehicleProjectsQuery = { __typename?: 'Query', evaluateVehicleProjects: Array<{ __typename?: 'VehicleProject', id: string, name: string }> };


export const EvaluateRegulationsDocument = gql`
    query EvaluateRegulations($personId: ID!) {
  evaluateRegulations(personId: $personId) {
    id
    name
    regulationNumber
  }
}
    `;

/**
 * __useEvaluateRegulationsQuery__
 *
 * To run a query within a React component, call `useEvaluateRegulationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEvaluateRegulationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEvaluateRegulationsQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useEvaluateRegulationsQuery(baseOptions: Apollo.QueryHookOptions<EvaluateRegulationsQuery, EvaluateRegulationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EvaluateRegulationsQuery, EvaluateRegulationsQueryVariables>(EvaluateRegulationsDocument, options);
      }
export function useEvaluateRegulationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EvaluateRegulationsQuery, EvaluateRegulationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EvaluateRegulationsQuery, EvaluateRegulationsQueryVariables>(EvaluateRegulationsDocument, options);
        }
export type EvaluateRegulationsQueryHookResult = ReturnType<typeof useEvaluateRegulationsQuery>;
export type EvaluateRegulationsLazyQueryHookResult = ReturnType<typeof useEvaluateRegulationsLazyQuery>;
export type EvaluateRegulationsQueryResult = Apollo.QueryResult<EvaluateRegulationsQuery, EvaluateRegulationsQueryVariables>;
export const EvaluateRegulatoryDocumentsDocument = gql`
    query EvaluateRegulatoryDocuments($personId: ID!) {
  evaluateRegulatoryDocuments(personId: $personId) {
    id
    name
  }
}
    `;

/**
 * __useEvaluateRegulatoryDocumentsQuery__
 *
 * To run a query within a React component, call `useEvaluateRegulatoryDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEvaluateRegulatoryDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEvaluateRegulatoryDocumentsQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useEvaluateRegulatoryDocumentsQuery(baseOptions: Apollo.QueryHookOptions<EvaluateRegulatoryDocumentsQuery, EvaluateRegulatoryDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EvaluateRegulatoryDocumentsQuery, EvaluateRegulatoryDocumentsQueryVariables>(EvaluateRegulatoryDocumentsDocument, options);
      }
export function useEvaluateRegulatoryDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EvaluateRegulatoryDocumentsQuery, EvaluateRegulatoryDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EvaluateRegulatoryDocumentsQuery, EvaluateRegulatoryDocumentsQueryVariables>(EvaluateRegulatoryDocumentsDocument, options);
        }
export type EvaluateRegulatoryDocumentsQueryHookResult = ReturnType<typeof useEvaluateRegulatoryDocumentsQuery>;
export type EvaluateRegulatoryDocumentsLazyQueryHookResult = ReturnType<typeof useEvaluateRegulatoryDocumentsLazyQuery>;
export type EvaluateRegulatoryDocumentsQueryResult = Apollo.QueryResult<EvaluateRegulatoryDocumentsQuery, EvaluateRegulatoryDocumentsQueryVariables>;
export const EvaluateRegulatoryDocumentParagraphsDocument = gql`
    query EvaluateRegulatoryDocumentParagraphs($personId: ID!) {
  evaluateRegulatoryDocumentParagraphs(personId: $personId) {
    id
    enumeration
  }
}
    `;

/**
 * __useEvaluateRegulatoryDocumentParagraphsQuery__
 *
 * To run a query within a React component, call `useEvaluateRegulatoryDocumentParagraphsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEvaluateRegulatoryDocumentParagraphsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEvaluateRegulatoryDocumentParagraphsQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useEvaluateRegulatoryDocumentParagraphsQuery(baseOptions: Apollo.QueryHookOptions<EvaluateRegulatoryDocumentParagraphsQuery, EvaluateRegulatoryDocumentParagraphsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EvaluateRegulatoryDocumentParagraphsQuery, EvaluateRegulatoryDocumentParagraphsQueryVariables>(EvaluateRegulatoryDocumentParagraphsDocument, options);
      }
export function useEvaluateRegulatoryDocumentParagraphsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EvaluateRegulatoryDocumentParagraphsQuery, EvaluateRegulatoryDocumentParagraphsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EvaluateRegulatoryDocumentParagraphsQuery, EvaluateRegulatoryDocumentParagraphsQueryVariables>(EvaluateRegulatoryDocumentParagraphsDocument, options);
        }
export type EvaluateRegulatoryDocumentParagraphsQueryHookResult = ReturnType<typeof useEvaluateRegulatoryDocumentParagraphsQuery>;
export type EvaluateRegulatoryDocumentParagraphsLazyQueryHookResult = ReturnType<typeof useEvaluateRegulatoryDocumentParagraphsLazyQuery>;
export type EvaluateRegulatoryDocumentParagraphsQueryResult = Apollo.QueryResult<EvaluateRegulatoryDocumentParagraphsQuery, EvaluateRegulatoryDocumentParagraphsQueryVariables>;
export const EvaluateRequirementsDocument = gql`
    query EvaluateRequirements($personId: ID!) {
  evaluateRequirements(personId: $personId) {
    id
    name
  }
}
    `;

/**
 * __useEvaluateRequirementsQuery__
 *
 * To run a query within a React component, call `useEvaluateRequirementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEvaluateRequirementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEvaluateRequirementsQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useEvaluateRequirementsQuery(baseOptions: Apollo.QueryHookOptions<EvaluateRequirementsQuery, EvaluateRequirementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EvaluateRequirementsQuery, EvaluateRequirementsQueryVariables>(EvaluateRequirementsDocument, options);
      }
export function useEvaluateRequirementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EvaluateRequirementsQuery, EvaluateRequirementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EvaluateRequirementsQuery, EvaluateRequirementsQueryVariables>(EvaluateRequirementsDocument, options);
        }
export type EvaluateRequirementsQueryHookResult = ReturnType<typeof useEvaluateRequirementsQuery>;
export type EvaluateRequirementsLazyQueryHookResult = ReturnType<typeof useEvaluateRequirementsLazyQuery>;
export type EvaluateRequirementsQueryResult = Apollo.QueryResult<EvaluateRequirementsQuery, EvaluateRequirementsQueryVariables>;
export const EvaluateVehicleProjectsDocument = gql`
    query EvaluateVehicleProjects($personId: ID!) {
  evaluateVehicleProjects(personId: $personId) {
    id
    name
  }
}
    `;

/**
 * __useEvaluateVehicleProjectsQuery__
 *
 * To run a query within a React component, call `useEvaluateVehicleProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEvaluateVehicleProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEvaluateVehicleProjectsQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useEvaluateVehicleProjectsQuery(baseOptions: Apollo.QueryHookOptions<EvaluateVehicleProjectsQuery, EvaluateVehicleProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EvaluateVehicleProjectsQuery, EvaluateVehicleProjectsQueryVariables>(EvaluateVehicleProjectsDocument, options);
      }
export function useEvaluateVehicleProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EvaluateVehicleProjectsQuery, EvaluateVehicleProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EvaluateVehicleProjectsQuery, EvaluateVehicleProjectsQueryVariables>(EvaluateVehicleProjectsDocument, options);
        }
export type EvaluateVehicleProjectsQueryHookResult = ReturnType<typeof useEvaluateVehicleProjectsQuery>;
export type EvaluateVehicleProjectsLazyQueryHookResult = ReturnType<typeof useEvaluateVehicleProjectsLazyQuery>;
export type EvaluateVehicleProjectsQueryResult = Apollo.QueryResult<EvaluateVehicleProjectsQuery, EvaluateVehicleProjectsQueryVariables>;