import {AutoStack} from 'components/AutoStack';
import {
	ControlledDatePicker,
	ControlledTagPicker,
	ControlledTextField,
	ControlledToggle,
	EnumDropdown,
} from 'components/hookForms';
import {LoadWrapper} from 'components/LoadWrapper';
import React from 'react';
import {Control} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {VehicleProject} from 'types';
import {
	GetVehicleProjectFormDataQuery,
	useGetVehicleProjectFormDataQuery,
} from '../hooks/vehicleProjectFormData.generated';
import {VEHICLE_PROJECTS_TRANSLATION_NAMESPACE} from './vehicleProjectConstants';
import {VehicleProjectsTooltipTranslationProvider} from './VehicleProjectsTooltipTranslationProvider';
import {ProviderThatEnablesGettingTooltipsFromContext} from 'features/localizedTooltips';

type VehicleProjectFormElementProps = {
	control: Control<VehicleProject>;
};

const VehicleProjectFormElements: React.FC<VehicleProjectFormElementProps> = ({
	control,
}) => {
	const {t} = useTranslation(VEHICLE_PROJECTS_TRANSLATION_NAMESPACE);

	const {data, loading} = useGetVehicleProjectFormDataQuery();
	const {
		countryData,
		driveVariantData,
		engineVariantData,
		bodyworkVariantData,
		activeTypeMarketData,
		gearboxVariantData,
	} = React.useMemo(() => mapFormEdgeNodes(data), [data]);

	return (
		<>
			<LoadWrapper loading={loading}>
				<VehicleProjectsTooltipTranslationProvider>
					<ProviderThatEnablesGettingTooltipsFromContext>
						<AutoStack horizontal tokens={tokens}>
							<ControlledTextField
								label={t('ModelSeries')}
								control={control}
								name={'modelSeries'}
								required
								rules={{required: t('ModelSeriesFieldRequired')}}
							/>
							<ControlledTextField
								label={t('Generation')}
								control={control}
								name={'generation'}
								required
								rules={{required: t('GenerationFieldRequired')}}
							/>
							<ControlledTextField
								label={t('Derivative')}
								control={control}
								name={'derivative'}
							/>
							<ControlledTextField
								placeholder={`${t('ForExample')} ${new Date()
									.getFullYear()
									.toString()}`}
								control={control}
								name={'modelYear'}
								label={t('ModelYearSpinButton')}
								rules={{
									pattern: {
										value: /^[1-9]\d{3}$/,
										message: t('ErrorModelYear'),
									},
								}}
							/>
						</AutoStack>
						<ControlledToggle
							control={control}
							name={'euLegislation'}
							label={t('EuLegislation')}
						/>
						<ControlledTagPicker
							control={control}
							name={'country'}
							label={t('Country')}
							selectableItems={countryData}
						/>
						<ControlledTagPicker
							control={control}
							name={'activeTypeMarkets'}
							label={t('ActiveTypeMarkets')}
							selectableItems={activeTypeMarketData}
						/>
						<ControlledTagPicker
							control={control}
							name={'driveVariants'}
							label={t('DriveVariants')}
							selectableItems={driveVariantData}
						/>
						<ControlledTagPicker
							control={control}
							name={'engineVariants'}
							label={t('EngineVariants')}
							selectableItems={engineVariantData}
						/>
						<ControlledTagPicker
							control={control}
							name={'bodyworkVariants'}
							label={t('BodyworkVariants')}
							selectableItems={bodyworkVariantData}
						/>
						<ControlledTagPicker
							control={control}
							name={'gearboxVariants'}
							label={t('GearboxVariants')}
							selectableItems={gearboxVariantData}
						/>
						<AutoStack horizontal tokens={tokens}>
							<ControlledToggle
								control={control}
								name={'isElectro'}
								label={t('IsElectro')}
							/>
						</AutoStack>
						<AutoStack horizontal tokens={tokens}>
							<ControlledDatePicker
								control={control}
								name={'eopVsi'}
								label={t('EopVsi')}
							/>
							<ControlledDatePicker
								control={control}
								name={'sopVsi'}
								label={t('SopVsi')}
								isRequired
								rules={{required: t('SopVsiFieldRequired')}}
							/>
							<ControlledDatePicker
								control={control}
								name={'sosVsi'}
								label={t('SosVsi')}
							/>
							<ControlledDatePicker
								control={control}
								name={'detVsi'}
								label={t('DetVsi')}
							/>
							<ControlledDatePicker
								control={control}
								name={'p10Vsi'}
								label={t('P10Vsi')}
							/>
						</AutoStack>
						<AutoStack horizontal tokens={tokens}>
							<ControlledDatePicker
								control={control}
								name={'typStartVsi'}
								label={t('TypStartVsi')}
							/>
							<ControlledDatePicker
								control={control}
								name={'posVsi'}
								label={t('PosVsi')}
							/>

							<ControlledDatePicker
								control={control}
								name={'wvtaVsi'}
								label={t('WvtaVsi')}
							/>
							<ControlledDatePicker
								control={control}
								name={'sysgenVsi'}
								label={t('SysgenVsi')}
							/>
							<ControlledDatePicker
								control={control}
								name={'posEtVsi'}
								label={t('PosEtVsi')}
							/>
						</AutoStack>
					</ProviderThatEnablesGettingTooltipsFromContext>
				</VehicleProjectsTooltipTranslationProvider>
			</LoadWrapper>
		</>
	);
};

const mapFormEdgeNodes = (data?: GetVehicleProjectFormDataQuery) => ({
	countryData: data?.countries ?? [],
	activeTypeMarketData: data?.activeTypeMarkets ?? [],
	driveVariantData: data?.driveVariants ?? [],
	engineVariantData: data?.engineVariants ?? [],
	bodyworkVariantData: data?.bodyworkVariants ?? [],
	gearboxVariantData: data?.gearboxVariants ?? [],
});

const tokens = {
	childrenGap: '5px',
};

export default VehicleProjectFormElements;
