import {LoadWrapper} from 'components/LoadWrapper';
import React from 'react';
import {useParams} from 'react-router-dom';
import {
	ActiveTypeMarketsQuery,
	ActiveTypeMarketsQueryVariables,
	useActiveTypeMarketsQuery,
} from '../hooks';
import {EntityContextProvider} from 'components/EntityPage/EntityContext';
import {BreadcrumbsOfGenerationSubpage} from '../baselines/BreadcrumbsOfGenerationSubpage';
import {QueryResult} from '@apollo/client';
import {ActiveTypeMarketsPageParams} from './activeTypeMarketsPageTypes';
import {ActiveTypeMarketsList} from './ActiveTypeMarketsList/ActiveTypeMarketsList';
import {useActiveTypeMarketsPageTranslations} from './activeTypeMarketsPage.hooks';

export type ActiveTypeMarketsPageQueryResult = QueryResult<
	ActiveTypeMarketsQuery,
	ActiveTypeMarketsQueryVariables
>;

export const ActiveTypeMarketsPage: React.FC = () => {
	const {t} = useActiveTypeMarketsPageTranslations();
	const params = useParams() as ActiveTypeMarketsPageParams;
	const {modelSeries, generation} = params;

	const {loading, data}: ActiveTypeMarketsPageQueryResult =
		useActiveTypeMarketsQuery({
			variables: {modelSeries, generation},
		});

	return (
		<EntityContextProvider>
			<LoadWrapper loading={loading}>
				<section>
					<BreadcrumbsOfGenerationSubpage
						params={params}
						textOfExtraBreadcrumb={t('BreadcrumbTextOfMarkets')}
						keyOfExtraBreadcrumb='markets'
					/>
					<ActiveTypeMarketsList
						markets={data?.activeTypeMarketsForModelSeriesAndGeneration}
						params={params}
					/>
				</section>
			</LoadWrapper>
		</EntityContextProvider>
	);
};
