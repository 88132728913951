import React from 'react';
import {KeywordItem} from './KeywordItem';
import {KeywordFields} from './keywordItem.types';
import {
	ArrayValueProps,
	ArrayValues,
} from 'components/EntityList/ColumnRenderers';
import {EntityListColumn} from 'components/EntityList';

interface Props extends Partial<ArrayValueProps<KeywordFields>> {
	keywords: KeywordFields[];
}

const renderKeyword = (keyword: KeywordFields): JSX.Element => {
	return <KeywordItem keyword={keyword} key={keyword.id} />;
};

export function Keywords({keywords, ...other}: Props): JSX.Element {
	return (
		<ArrayValues
			array={keywords}
			getValue={renderKeyword}
			limit={4}
			{...other}
		/>
	);
}

/**
 * Read this function's definition to know if your Details List is compatible
 * with this renderer.
 */
export const renderKeywordsCell: EntityListColumn['onRender'] = (
	item,
): JSX.Element => {
	return <Keywords keywords={item.keywords} />;
};
