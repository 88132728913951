import React from 'react';

export type TextLineSelection = {
	page: number;
	bounds: number[];
} | null;

export type EditorSelectionContextProps = {
	selectedTextLine: TextLineSelection;
	setSelectedTextLine: (textLine: TextLineSelection) => void;
};

export const EditorSelectionContext =
	React.createContext<EditorSelectionContextProps>({
		selectedTextLine: null,
	} as EditorSelectionContextProps);
