import * as React from 'react';
import {LoadSpinner, LoadSpinnerProps} from './LoadSpinner';

export interface CommonLoadWrapperProps {
	loading: boolean;
	children?: React.ReactNode;
}

type LoadWrapperProps = LoadSpinnerProps & CommonLoadWrapperProps;

export const LoadWrapper: React.FC<LoadWrapperProps> = ({
	loading,
	children,
	...other
}) => {
	if (loading) {
		return <LoadSpinner {...other} />;
	}

	return <>{children}</>;
};
