import {HeaderForPagesWithEntityPage} from 'components/HeaderForPagesWithEntityPage';
import React from 'react';
import {useSubscriptionsTranslation} from './hooks/useSubscriptionsTranslation';
import {LoadWrapper} from 'components/LoadWrapper';
import {
	GetAllMySubscriptionsDocument,
	useCreateSubscriptionMutation,
	useDeleteSubscriptionMutation,
	useGetAllMySubscriptionsQuery,
	useUpdateSubscriptionMutation,
} from './hooks/subscriptions.generated';
import {EntityListColumn, EntityPage} from 'components';
import {SubscriptionsTooltipTranslationProvider} from './SubscriptionsTooltipTranslationProvider';
import {useSubscriptionColumns} from './hooks/useSubscriptionColumns';
import {PanelType} from '@fluentui/react';
import {Subscription} from 'types';
import {mapToRef} from 'helpers';
import SubscriptionFormElements from './components/SubscriptionFormElements';
import {RenderAdditionalFormElements} from 'components/EntityPage/EntityFormPanels';
import {EntityContextProvider} from 'components/EntityPage/EntityContext';

const SubscriptionsPage: React.FC = () => {
	const {t} = useSubscriptionsTranslation();
	const {loading, data} = useGetAllMySubscriptionsQuery();

	const [createSubscriptionMutation] = useCreateSubscriptionMutation();
	const [updateSubscriptionMutation] = useUpdateSubscriptionMutation();
	const [deleteSubscriptionMutation] = useDeleteSubscriptionMutation();

	const subscriptions = React.useMemo(
		() => data?.mySubscriptions ?? [],
		[data],
	);
	const columns: EntityListColumn[] = useSubscriptionColumns();
	const refetchQueries = [GetAllMySubscriptionsDocument];

	const createSubscription = React.useCallback((subscription: Subscription) => {
		const input = mapToInput(subscription);
		createSubscriptionMutation({
			variables: {
				input,
			},
			refetchQueries,
		});
	}, []);

	const updateSubscription = React.useCallback((subscription: Subscription) => {
		const input = mapToInput(subscription);
		updateSubscriptionMutation({
			variables: {
				input: {
					id: subscription.id,
					...input,
				},
			},
			refetchQueries,
		});
	}, []);

	const deleteSubscription = React.useCallback((id: string) => {
		deleteSubscriptionMutation({
			variables: {
				input: {id},
			},
			refetchQueries,
		});
	}, []);

	const renderFormElements: RenderAdditionalFormElements<
		Subscription
	> = control => {
		return <SubscriptionFormElements control={control} />;
	};

	return (
		<EntityContextProvider>
			<HeaderForPagesWithEntityPage>
				<h3>{t('PageTitle')}</h3>
			</HeaderForPagesWithEntityPage>
			<LoadWrapper loading={loading}>
				<SubscriptionsTooltipTranslationProvider>
					<EntityPage
						items={subscriptions as any}
						entityDisplayName={t('EntityName')}
						createEntity={createSubscription}
						updateEntity={updateSubscription}
						deleteEntity={deleteSubscription}
						additionalColumns={columns}
						withNameColumn={false}
						panelType={PanelType.medium}
						shouldWrapListWithScrollablePane={false}
						renderAdditionalFormElements={renderFormElements}
					/>
				</SubscriptionsTooltipTranslationProvider>
			</LoadWrapper>
		</EntityContextProvider>
	);
};

function mapToInput(subscription: Subscription) {
	return {
		name: subscription.name,
		keywordRefs: mapToRef(subscription.keywords),
		marketRefs: mapToRef(subscription.markets),
		notificationFormat: subscription.notificationFormat,
		notificationFrequency: subscription.notificationFrequency,
		standardPlusPcmsClusterRefs: mapToRef(
			subscription.standardPlusPcmsClusters,
		),
		optOnlyForMainKeywords: subscription.optOnlyForMainKeywords,
	};
}

export default SubscriptionsPage;
