import React from 'react';
import {EntityList, EntityListColumn, EntityListProps} from 'components';
import {IColumn} from '@fluentui/react';
import {useNavigate} from 'react-router-dom';
import {useTranslation, TFunction} from 'react-i18next';
import {Report} from './hooks';
import {ItemSummaryTooltip} from './_ItemSummaryTooltip';
import {ReportsTooltipTranslationProvider} from './ReportsTooltipTranslationProvider';
import {
	useTooltipTranslation,
	ColumnWithTooltipOptions,
} from 'features/localizedTooltips';
import {
	renderArrayField,
	renderDateString,
} from 'components/EntityList/ColumnRenderers';
import {DATE_TYPE, REPORTS_TABLE_NAME} from 'components/EntityList/EntityUtils';

interface Column extends Omit<ColumnWithTooltipOptions, 'minWidth'> {
	key: string;
	name: string;
	isIconOnly?: boolean;
	minWidth?: number;
	maxWidth?: number;
	onRender?: IColumn['onRender'];
	filterable?: boolean;
	sortable?: boolean;
	dataType?: string;
}

const getColumn = ({
	isIconOnly = false,
	minWidth = 90,
	maxWidth = 130,
	filterable = true,
	sortable = true,
	dataType = '',
	key,
	...props
}: Column) => ({
	key,
	fieldName: key,
	isIconOnly,
	minWidth,
	maxWidth,
	filterable,
	sortable,
	dataType,
	...props,
});

const useColumns = (t: TFunction): EntityListColumn[] => {
	const {t: getReportsListTranslation} = useTooltipTranslation(
		'features/Reports/reportsList',
	);

	return [
		getColumn({
			key: 'tooltip',
			name: 'tooltip',
			isIconOnly: true,
			minWidth: 16,
			maxWidth: 16,
			sortable: false,
			onRender: (report: Report) => (
				<ItemSummaryTooltip id={report.id} summary={report.summary} />
			),
			tooltipHostProps: {content: getReportsListTranslation('tooltip')},
		}),
		getColumn({
			key: 'regulationNumber',
			name: t('Regulation'),
			sortable: true,
			isResizable: true,
		}),
		getColumn({
			key: 'name',
			name: t('Version'),
			sortable: true,
			isResizable: true,
		}),
		getColumn({
			key: 'createdAt',
			name: t('Created'),
			sortable: true,
			onRender: renderDateString(),
			isResizable: true,
			dataType: DATE_TYPE,
		}),
		getColumn({
			key: 'createdBy',
			name: t('Creator'),
			sortable: true,
			isResizable: true,
		}),
		getColumn({
			key: 'status',
			name: 'Status',
			sortable: true,
			isResizable: true,
		}),
		getColumn({
			key: 'market',
			name: t('Markets'),
			sortable: true,
			minWidth: 200,
			maxWidth: 200,
			onRender: renderArrayField(),
			isResizable: true,
		}),
		getColumn({
			key: 'keywords',
			name: t('Keywords'),
			sortable: true,
			minWidth: 200,
			maxWidth: 200,

			onRender: renderArrayField(),
			isResizable: true,
		}),
		getColumn({
			key: 'modelYear',
			name: t('ModelYear'),
			sortable: true,
			isResizable: true,
		}),
		getColumn({
			key: 'dateEffective',
			name: t('DateEffective'),
			sortable: true,
			isResizable: true,
			onRender: renderDateString(),
			dataType: DATE_TYPE,
		}),
		getColumn({
			key: 'dateNewRegistration',
			name: t('DateNewRegistration'),
			sortable: true,
			isResizable: true,
			onRender: renderDateString(),
			dataType: DATE_TYPE,
		}),
		getColumn({
			key: 'dateNewTypes',
			name: t('DateNewTypes'),
			sortable: true,
			isResizable: true,
			onRender: renderDateString(),
			dataType: DATE_TYPE,
		}),
	];
};

export const ReportsList = ({items}: ReportsListProps) => {
	const navigate = useNavigate();
	const {t} = useTranslation('features/reports', {
		keyPrefix: 'ReportsList',
	});

	const columns = useColumns(t);

	const onItemInvoked = (item: Report) => {
		navigate(`/regulations/${item.regulationId}/${item.id}/paragraphs`);
	};

	return (
		<ReportsTooltipTranslationProvider>
			<EntityList
				sticky={true}
				selectionMode={0}
				{...{items, columns, onItemInvoked}}
				tableId={REPORTS_TABLE_NAME}
			/>
		</ReportsTooltipTranslationProvider>
	);
};

export type ReportsListProps = {
	items: EntityListProps['items'];
};
