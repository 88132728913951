import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetTableConfigurationMutationVariables = Types.Exact<{
  input: Types.AddOrUpdateTableConfigurationInput;
}>;


export type SetTableConfigurationMutation = { __typename?: 'Mutation', addOrUpdateTableConfiguration: { __typename?: 'AddOrUpdateTableConfigurationPayload', user?: { __typename?: 'User', id: string } | null } };


export const SetTableConfigurationDocument = gql`
    mutation SetTableConfiguration($input: AddOrUpdateTableConfigurationInput!) {
  addOrUpdateTableConfiguration(input: $input) {
    user {
      id
    }
  }
}
    `;
export type SetTableConfigurationMutationFn = Apollo.MutationFunction<SetTableConfigurationMutation, SetTableConfigurationMutationVariables>;

/**
 * __useSetTableConfigurationMutation__
 *
 * To run a mutation, you first call `useSetTableConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTableConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTableConfigurationMutation, { data, loading, error }] = useSetTableConfigurationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetTableConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<SetTableConfigurationMutation, SetTableConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTableConfigurationMutation, SetTableConfigurationMutationVariables>(SetTableConfigurationDocument, options);
      }
export type SetTableConfigurationMutationHookResult = ReturnType<typeof useSetTableConfigurationMutation>;
export type SetTableConfigurationMutationResult = Apollo.MutationResult<SetTableConfigurationMutation>;
export type SetTableConfigurationMutationOptions = Apollo.BaseMutationOptions<SetTableConfigurationMutation, SetTableConfigurationMutationVariables>;