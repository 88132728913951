import React from 'react';

export type EntityListContextProps = {
	pageDetails?: any | undefined;
};

export const EntityListContext = React.createContext<EntityListContextProps>(
	{} as any,
);

export const EntityListContextProvider: React.FC<{
	pageDetails?: any | undefined;
	children: React.ReactNode;
}> = ({pageDetails = undefined, children}) => {
	return (
		<EntityListContext.Provider
			value={{
				pageDetails,
			}}
		>
			{children}
		</EntityListContext.Provider>
	);
};

export const useEntityListContext = () =>
	React.useContext(EntityListContext) as unknown as EntityListContextProps;
