import {HookFormProps} from './HookFormProps';
import * as React from 'react';
import {Controller} from 'react-hook-form';
import {
	IPeoplePickerProps,
	IPersonaProps,
	NormalPeoplePicker,
	PeoplePickerItemSuggestion,
} from '@fluentui/react';
import {FC} from 'react';
import {User, UserRole} from 'types';
import {useGetUsersLazyQuery} from './hooks/getUsers.generated';
import {useUserContext} from 'authentication/UserContext';
import {LabelWithTooltip} from 'features/localizedTooltips';

export type ControlledPeoplePickerProps = HookFormProps &
	Omit<
		IPeoplePickerProps,
		| 'onResolveSuggestions'
		| 'selectedItems'
		| 'onChange'
		| 'onBlur'
		| 'defaultSelectedItems'
		| 'onEmptyResolveSuggestions'
	> & {
		label: string;
		roles?: UserRole[];
	};

export type PeoplePickerProps = Omit<
	IPeoplePickerProps,
	'onResolveSuggestions'
> & {vkoOnly?: boolean; excludeCurrentUser?: boolean; roles?: UserRole[]};

export const PeoplePicker: React.FC<PeoplePickerProps> = ({
	excludeCurrentUser = false,
	roles,
	...props
}) => {
	const [getUsers] = useGetUsersLazyQuery();
	const {encodedUserId} = useUserContext();

	const onResolveSuggestions = React.useCallback(
		(filter: string, selectedItems: IPersonaProps[] | undefined) => {
			if (filter?.length > 1) {
				return new Promise<IPersonaProps[]>(resolve => {
					const defaultRoles = roles && roles.length > 0 ? roles : [undefined];
					Promise.all(
						defaultRoles.map(role => {
							return getUsers({
								variables: {
									searchString: filter,
									role,
								},
							});
						}),
					).then(rets => {
						let returnPersonas: any[] = [];
						rets.forEach(res => {
							returnPersonas = returnPersonas.concat(
								res.data?.users?.edges?.map(u => u.node) || [],
							);
						});
						let filteredPersonas = returnPersonas.map(user => ({
							...user,
							text: user.name,
						}));

						if (excludeCurrentUser) {
							filteredPersonas = filteredPersonas.filter(
								user => user.id !== encodedUserId,
							);
						}

						const personas = removeDuplicates(filteredPersonas, selectedItems);
						resolve(personas);
					});
				});
			}

			return [];
		},
		[excludeCurrentUser],
	);

	const onRenderSuggestionsItem = (
		personaProps: any,
		suggestionsProps: any,
	) => (
		<PeoplePickerItemSuggestion
			personaProps={personaProps}
			suggestionsProps={suggestionsProps}
			styles={{personaWrapper: {width: '100%'}}}
		/>
	);

	return (
		<NormalPeoplePicker
			{...props}
			onResolveSuggestions={onResolveSuggestions}
			onRenderSuggestionsItem={onRenderSuggestionsItem}
		/>
	);
};

export const ControlledPeoplePicker: FC<ControlledPeoplePickerProps> = (
	props: ControlledPeoplePickerProps,
) => {
	return (
		<Controller
			name={props.name}
			control={props.control}
			rules={props.rules}
			render={({field: {onChange, onBlur, value}}) => (
				<>
					<LabelWithTooltip
						required={Boolean(props.rules?.required)}
						translationKey={props.name}
					>
						{props.label}
					</LabelWithTooltip>
					<PeoplePicker
						{...props}
						selectedItems={value?.map((v: User) => ({
							...v,
							text: v.name,
						}))}
						defaultSelectedItems={undefined}
						onBlur={onBlur}
						resolveDelay={300}
						onChange={items => {
							onChange(items || []);
						}}
						itemLimit={props.itemLimit}
					/>
				</>
			)}
		/>
	);
};

export function removeDuplicates(
	personas: IPersonaProps[],
	possibleDupes: IPersonaProps[] | undefined,
) {
	return personas.filter(
		persona => !listContainsPersona(persona, possibleDupes ?? []),
	);
}

function listContainsPersona(
	persona: IPersonaProps,
	personas: IPersonaProps[],
) {
	if (!personas || !personas.length || personas.length === 0) {
		return false;
	}

	return personas.filter(item => item.text === persona.text).length > 0;
}
