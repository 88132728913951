import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAttachmentCategoriesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAttachmentCategoriesQuery = { __typename?: 'Query', attachmentCategories?: Array<{ __typename?: 'AttachmentCategory', id: string, name: string }> | null };


export const GetAttachmentCategoriesDocument = gql`
    query GetAttachmentCategories {
  attachmentCategories {
    id
    name
  }
}
    `;

/**
 * __useGetAttachmentCategoriesQuery__
 *
 * To run a query within a React component, call `useGetAttachmentCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttachmentCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttachmentCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAttachmentCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetAttachmentCategoriesQuery, GetAttachmentCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAttachmentCategoriesQuery, GetAttachmentCategoriesQueryVariables>(GetAttachmentCategoriesDocument, options);
      }
export function useGetAttachmentCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAttachmentCategoriesQuery, GetAttachmentCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAttachmentCategoriesQuery, GetAttachmentCategoriesQueryVariables>(GetAttachmentCategoriesDocument, options);
        }
export type GetAttachmentCategoriesQueryHookResult = ReturnType<typeof useGetAttachmentCategoriesQuery>;
export type GetAttachmentCategoriesLazyQueryHookResult = ReturnType<typeof useGetAttachmentCategoriesLazyQuery>;
export type GetAttachmentCategoriesQueryResult = Apollo.QueryResult<GetAttachmentCategoriesQuery, GetAttachmentCategoriesQueryVariables>;