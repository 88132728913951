import {
	DefaultButton,
	PrimaryButton,
	Theme,
	mergeStyles,
	useTheme,
} from '@fluentui/react';
import React from 'react';
import {Stage, TableImageSplitterContext} from './TableImageSplitter';
import {useTranslation} from 'react-i18next';
import {LexicalEditorElement} from './LexicalEditorElement';
import {getHTMLTableEditorClassNames} from './HtmlTableEditor';
import {getHTMLTableClassName} from 'components';

// Draft here: https://codesandbox.io/s/wild-smoke-9xsl92/

const htmlTableRowSelectionClass = 'HTMLTable_row_selection';
export interface TableSplitterProps {
	htmlTableText: string;
}

export const SplittingHtmlTable: React.FC<TableSplitterProps> = ({
	htmlTableText,
}) => {
	const theme = useTheme();
	const classNames = getHTMLTableEditorClassNames(theme);

	const [selectedRows, setSelectedRows] = React.useState<Array<HTMLElement>>(
		[],
	);

	const {splitLocations, setSplitLocations, setStage, onDismiss} =
		React.useContext(TableImageSplitterContext);

	const {t} = useTranslation('features/regulatorydocuments', {
		keyPrefix: 'DocumentEditor.TableImageSplitter',
	});

	const drawHorizontalLine = React.useCallback((row: HTMLElement) => {
		row.classList.add(htmlTableRowSelectionClass);
	}, []);

	const deleteHorizontalLines = React.useCallback(
		(selectedRows: Array<HTMLElement>) => {
			for (const rowElement of selectedRows) {
				deleteHorizontalLine(rowElement);
			}
		},
		[],
	);

	const deleteHorizontalLine = React.useCallback((row: HTMLElement) => {
		row.classList.remove(htmlTableRowSelectionClass);
	}, []);

	const getRowParent = (
		element: HTMLElement,
	): HTMLTableRowElement | undefined => {
		if (element instanceof HTMLTableRowElement) {
			return element;
		}

		if (element.parentElement === null) {
			return undefined;
		}

		return getRowParent(element.parentElement);
	};

	const canBeSplit = (rowIndex: number, rowElement: Element): boolean => {
		const parent = rowElement.parentElement;
		if (parent === null) return false;
		const rowsCount = parent.children.length;

		// Check1: last row is not allowed
		if (rowIndex >= rowsCount - 1) return false;
		let currentRowSpan = 0;

		const rows = parent.children;

		// Check2: row with multiple span not allowed
		for (let i = 0; i < rows.length; i++) {
			currentRowSpan = Math.max(0, currentRowSpan - 1);

			const rowSpan = getMaxRowSpan(i, parent);
			if (rowSpan > 0) currentRowSpan = Math.max(0, rowSpan - 1);

			if (currentRowSpan === 0 && i === rowIndex) return true;
		}

		return false;
	};

	const getMaxRowSpan = (rowIndex: number, parent: Element): number => {
		const row = parent.children[rowIndex];
		let res = 0;

		// Check2: row with multiple span not allowed
		for (const td of row.children) {
			const rowspan = td.getAttribute('rowspan');
			if (rowspan === null) continue;
			const rowspanValue = parseInt(rowspan, 10);
			if (typeof rowspanValue !== 'number') continue;

			res = Math.max(res, rowspanValue);
		}

		return res;
	};

	const onTableClick = (element: HTMLElement) => {
		if (!element.parentNode) return;

		const newSplitLocations = [...splitLocations];
		const newSelectedRows = [...selectedRows];

		const rowElement = getRowParent(element);
		if (rowElement === undefined) return;
		if (rowElement.parentElement === null) return;

		const rowIndex = [...rowElement.parentElement.children].indexOf(rowElement);
		const selectionIndex = splitLocations.indexOf(rowIndex);

		if (!canBeSplit(rowIndex, rowElement)) return;

		// Select table row
		if (selectionIndex < 0) {
			drawHorizontalLine(rowElement);
			newSplitLocations.push(rowIndex);
			newSelectedRows.push(rowElement);
		}
		// Deselect table row
		else {
			deleteHorizontalLine(rowElement);
			newSplitLocations.splice(selectionIndex, 1);
			newSelectedRows.splice(selectionIndex, 1);
		}

		setSplitLocations(newSplitLocations);
		setSelectedRows(newSelectedRows);
	};

	const onTableUpdate = (e: Element) => {
		console.log('update', e);
	};

	const onUndoClick = React.useCallback(() => {
		deleteHorizontalLines(selectedRows);
		setSplitLocations([]);
		setSelectedRows([]);
	}, [splitLocations, selectedRows, setSplitLocations, setSelectedRows]);

	const onSubmitClick = React.useCallback(() => {
		setStage(Stage.AddMetadata);
	}, [setStage]);

	const onClose = React.useCallback(() => {
		setSplitLocations([]);
		setSelectedRows([]);
		onDismiss();
	}, [onDismiss]);

	return (
		<div className={classNames.splitterBackground}>
			<div className={classNames.splittingCanvas}>
				<div className={classNames.canvasWrapper}>
					<LexicalEditorElement
						editable={false}
						clickEvent={onTableClick}
						updateEvent={onTableUpdate}
						themeClasss={{
							table: [
								getTableClassNames(theme),
								getHTMLTableClassName(theme),
							].join(' '),
							// Theme styling goes here
							// Example: tableCell: 'lexical_tableCell',
							// ...
						}}
						htmlTableText={htmlTableText}
						addToolbar={false}
					></LexicalEditorElement>
				</div>
				<div className={classNames.buttons}>
					<div>
						<DefaultButton
							className={classNames.button}
							onClick={onUndoClick}
							disabled={splitLocations.length === 0}
						>
							{t('Undo')}
						</DefaultButton>
						<DefaultButton className={classNames.button} onClick={onClose}>
							{t('Cancel')}
						</DefaultButton>
					</div>

					<PrimaryButton
						className={classNames.button}
						onClick={onSubmitClick}
						disabled={splitLocations.length === 0}
					>
						{t('Continue')}
					</PrimaryButton>
				</div>
			</div>
		</div>
	);
};

const getTableClassNames = (theme: Theme) => {
	return mergeStyles({
		backgroundColor: `${theme.palette.white}`,
		borderCollapse: 'collapse',
		'&  tr.HTMLTable_row_selection': {
			borderBottom: `2px solid ${theme.palette.red}`,
		},
		'    tr:hover': {
			backgroundColor: 'rgb(236,236,236)',
		},
	});
};
