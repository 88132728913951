import {truncateText} from 'helpers';
import {ParagraphElementType} from 'types';
import {QueryParagraph} from './RegDocDetailsPage/RegDocDetailsPage.queryTypes';
import {Node} from 'react-checkbox-tree';
import {mergeStyleSets} from '@fluentui/merge-styles';
import {useCallback, useMemo} from 'react';
import _ from 'lodash';

interface LevelItem {
	level: number;
	[key: string]: any;
}

type NestedItem<T> = T & {children?: T[]};

export function createNestedStructure<T extends LevelItem>(
	items: T[],
	currentLevel = 0,
): NestedItem<T>[] {
	const nestedStructure: NestedItem<T>[] = [];

	while (items.length > 0) {
		const item = items.shift() as T;

		if (item.level === currentLevel) {
			const nestedItem = {...item};
			nestedStructure.push(nestedItem);
		} else if (item.level > currentLevel) {
			const newLevel = currentLevel + 1;
			/**
			 * In case of inconsistent levels (e.g. 0 -> 2) we need to correct
			 * those levels to the one that would logically follow (e.g. 0 -> 1)
			 */
			item.level = Math.min(item.level, newLevel);
			items.unshift(item);
			nestedStructure[nestedStructure.length - 1].children =
				createNestedStructure(items, newLevel);
		} else {
			items.unshift(item);
			break;
		}
	}

	return nestedStructure;
}

export type ParagraphNode = QueryParagraph & Node;

/**
 * We place this in the top scope so we don't have to use useCallback with it.
 */
const getDisabledItemStyles = () => {
	return {
		'& > span > [role="link"]': {
			color: 'rgba(0, 0, 0, 0.6)',
			cursor: 'not-allowed',
		},
	};
};

export function getParagraphLabel(paragraph: QueryParagraph) {
	let label = paragraph.enumeration;
	if (label === '') {
		for (const element of paragraph.elements) {
			if (element.type === ParagraphElementType.Text) {
				label += element.text;
				continue;
			}

			if (element.type === ParagraphElementType.NewLine) {
				break;
			}
		}

		label = truncateText(label, 40);
	}

	return label;
}

export const useNestedParagraphs = (
	allParagraphs: QueryParagraph[],
	selectableParagraphs: QueryParagraph[],
): ParagraphNode[] => {
	/**
	 * We don't place this in the global scope so we don't accidentally create
	 * styles when we import the file. Otherwise, we could affect unrelated tests'
	 * snapshots.
	 */
	const styles = useMemo(() => {
		return mergeStyleSets({
			disabledItem: getDisabledItemStyles(),
		});
	}, []);

	const getIfParagraphIsSelectable = useCallback(
		({id}: QueryParagraph): boolean => {
			type Match = QueryParagraph | undefined;
			const selectableParagraph: Match = _.find(selectableParagraphs, {id});
			return Boolean(selectableParagraph);
		},
		[selectableParagraphs],
	);

	const createNode = useCallback(
		(paragraph: QueryParagraph): ParagraphNode => {
			const isSelectable: boolean = getIfParagraphIsSelectable(paragraph);
			return {
				...paragraph,
				value: paragraph.id,
				label: getParagraphLabel(paragraph),
				className: isSelectable ? undefined : styles.disabledItem,
				disabled: !isSelectable,
			};
		},
		[getIfParagraphIsSelectable, styles],
	);

	const createNestedParagraphs = useCallback((): ParagraphNode[] => {
		const fixedParagraphs: QueryParagraph[] = _.cloneDeep(allParagraphs);

		if (fixedParagraphs.length > 0 && fixedParagraphs[0].level !== 0) {
			fixedParagraphs[0].level = 0;
		}

		return createNestedStructure<ParagraphNode>(
			fixedParagraphs.reduce(
				(nodes: ParagraphNode[], paragraph: QueryParagraph) => {
					if (!paragraph.isFootnote) {
						const node: ParagraphNode = createNode(paragraph);
						return nodes.concat(node);
					}

					return nodes;
				},
				[],
			),
		);
	}, [allParagraphs, createNode]);

	return useMemo(createNestedParagraphs, [createNestedParagraphs]);
};
