import {UserRole} from 'types';
import {ViewAuthorizer} from 'components/ViewAuthorizer';
import {EntityContextProvider} from 'components/EntityPage/EntityContext';
import React from 'react';
import {
	ConvoluteForm,
	ConvoluteList,
	EvaluatePersonForm,
	RulesForm,
	RulesList,
} from './components';

const AdminDataProtectionPage: React.FC = () => {
	return (
		<ViewAuthorizer roles={[UserRole.SystemAdministrator]}>
			<div>
				<EntityContextProvider key={'Convolute'}>
					<ConvoluteList />
					<ConvoluteForm />
				</EntityContextProvider>
				<EntityContextProvider key={'GdprRule'}>
					<EvaluatePersonForm />
					<RulesList />
					<RulesForm />
				</EntityContextProvider>
			</div>
		</ViewAuthorizer>
	);
};

export default AdminDataProtectionPage;
