import {
	BaselineCmdsInfo,
	useBaselineCmds,
} from 'features/VehicleProjects/baselines/baselineCmds';
import {ProjectDetailsPageParams} from 'features/VehicleProjects/vehicleProjectPagePaths';
import {
	TooltipTranslationOptions,
	createPathWithTooltipsTextKey,
	createTooltipTranslationProviderFromNamespace,
} from 'features/localizedTooltips';
import React, {useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {LoadWrapper} from 'components/LoadWrapper';

import {BaselinesList} from './BaselinesList';
import {useGetBaselinesByModelAndSeriesQuery} from '../hooks/baseline.generated';
import {Breadcrumb, IBreadcrumbItem} from '@fluentui/react';
import {useGenerationBreadcrumbs} from '../baselines/breadcrumbs.utils';
import {
	translationNamespace,
	useBaselineListPageTranslations,
} from './BaselinesPage.utils';
import {useCommand} from '../../../hooks';
import {IContextualMenuItem as MenuItem} from '@fluentui/react';
import {useBaselineListCmds} from './baselineListCmds';
import {Baseline} from '../BaselineDetailsPage/BaselineDetailsPage.types';
import {BaselineCreateDialog} from '../components/BaselineCreateDialog';
import {BaselineRenameDialog} from '../components/BaselineRenameDialog';

const TranslationProvider =
	createTooltipTranslationProviderFromNamespace(translationNamespace);

const emptyArray: never[] = [];

export const useBreadcrumbsOfBaselineListPage = (
	params: ProjectDetailsPageParams,
	pageBreadcrumbFields?: Partial<IBreadcrumbItem>,
): IBreadcrumbItem[] => {
	const {t} = useBaselineListPageTranslations();

	const extraItems: IBreadcrumbItem[] = useMemo(() => {
		const createPageBreadcrumb = (): IBreadcrumbItem => {
			return {
				key: 'baselines',
				text: t('BreadcrumbText'),
				...pageBreadcrumbFields,
			};
		};

		return [createPageBreadcrumb()];
	}, [t, pageBreadcrumbFields]);

	return useGenerationBreadcrumbs({params, extraItems});
};

export function BaselineListPage(): JSX.Element {
	const params = useParams() as ProjectDetailsPageParams;
	const breadcrumbs: IBreadcrumbItem[] =
		useBreadcrumbsOfBaselineListPage(params);
	const {data, loading} = useGetBaselinesByModelAndSeriesQuery({
		variables: params,
	});

	const [baselines, setBaselines] = useState<Baseline[]>([]);
	const [renameDialogOpen, setRenameDialogOpen] = useState<boolean>(false);

	useBaselineListCmds({params}, baselines, setRenameDialogOpen);

	const baselineList =
		data?.vehicleProjectBaselinesByModelAndSeries ?? emptyArray;

	const {infoForIsSuccessDialogOpen}: BaselineCmdsInfo = useBaselineCmds({
		params,
		showOverviewCmd: false,
	});

	const translationHookOptions: TooltipTranslationOptions = useMemo(() => {
		return {keyPrefix: createPathWithTooltipsTextKey('Columns')};
	}, []);

	return (
		<>
			<Breadcrumb items={breadcrumbs} />
			<LoadWrapper loading={loading}>
				<TranslationProvider translationHookOptions={translationHookOptions}>
					<BaselinesList
						baselineList={baselineList}
						params={params}
						setBaselines={setBaselines}
					/>
				</TranslationProvider>
			</LoadWrapper>
			<BaselineCreateDialog
				generation={params.generation}
				modelSeries={params.modelSeries}
				infoForIsOpen={infoForIsSuccessDialogOpen}
			/>
			<BaselineRenameDialog
				baselines={baselines}
				renameDialogOpen={renameDialogOpen}
				setRenameDialogOpen={setRenameDialogOpen}
			/>
		</>
	);
}
