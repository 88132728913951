import {mergeStyles} from '@fluentui/react';
import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

type RichtextDisplayProps = {
	value: string;
	limit?: number;
	removeHtml?: boolean;
};

export const RichtextDisplay: React.FC<RichtextDisplayProps> = ({
	value,
	limit = Infinity,
	removeHtml = false,
}) => {
	const quillClassName = mergeStyles({
		'.ql-editor': {
			padding: 0,
		},
	});

	let editedValue = value;

	if (removeHtml) {
		editedValue = value.replace(/<[^>]+>/g, '');
		return <>{editedValue}</>;
	}

	editedValue = editedValue
		.substring(0, limit)
		.concat(value.length > limit ? '...' : '');

	return (
		<ReactQuill
			value={editedValue}
			readOnly={true}
			theme={'bubble'}
			className={quillClassName}
		/>
	);
};
