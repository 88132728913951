import {SelectionMode} from '@fluentui/react';
import {EntityList, EntityListColumn, EntityListProps} from 'components';
import {renderAttachments} from 'components/EntityList/ColumnRenderers';
import {useCommand, useSelection} from 'hooks';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

type SearchResultListProps<T> = Omit<EntityListProps, 'items' | 'columns'> & {
	items: T[];
	columns: EntityListColumn[];
	getItemUrl?: (item?: T) => string;
};

export function SearchResultList<T>(props: SearchResultListProps<T>) {
	const {items, columns, getItemUrl, ...entityListProps} = props;

	const {t} = useTranslation('features/search');
	const [selection, selectedItems] = useSelection<T>();
	const navigate = useNavigate();

	const onItemInvoked = React.useCallback(
		(item: T) => {
			if (getItemUrl) {
				navigate(getItemUrl(item));
			}
		},
		[navigate, getItemUrl],
	);

	useCommand(
		{
			key: 'view',
			text: t('View'),
			priority: 1,
			iconProps: {
				iconName: 'View',
			},
			disabled: selectedItems.length !== 1,
			onClick() {
				if (selectedItems[0] && getItemUrl) {
					navigate(getItemUrl(selectedItems[0]));
				}
			},
		},
		[navigate, selectedItems, getItemUrl],
	);

	const hasAttachments = items[0] && Boolean((items[0] as any).attachments);

	const listColumns = React.useMemo(
		() => [
			...(hasAttachments
				? [
						{
							name: 'attachments',
							key: 'attachments',
							isIconOnly: true,
							iconName: 'Attach',
							fieldName: 'attachments',
							minWidth: 16,
							maxWidth: 16,
							onRender: renderAttachments(),
						},
				  ]
				: []),
			...columns,
		],
		[],
	);

	return (
		<EntityList
			{...entityListProps}
			items={items}
			columns={listColumns}
			selection={selection}
			selectionMode={SelectionMode.single}
			onItemInvoked={onItemInvoked}
			aria-rowcount
		/>
	);
}
