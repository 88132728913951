import {ApolloCache} from '@apollo/client';
import {createProjectCacheEvictor} from 'helpers';

/**
 * We must evict the audit logs after running a mutation that might change them.
 * Otherwise, the app will return the audit logs from the cache when we query
 * them even if we refetch the query.
 */
export const evictAuditLogs = (cache: ApolloCache<any>): void => {
	createProjectCacheEvictor('auditLogs')(cache);
};
