import React from 'react';
import {
	BasePropsOfComponentForRenderer,
	TooltipRendererFields,
	TooltipRendererCreatorService,
} from '../../TooltipRendererCreator.service';
import {
	IDetailsColumnRenderTooltipProps,
	IDetailsHeaderProps,
	IRenderFunction,
} from '@fluentui/react';
import {
	DetailsListHeaderTooltip,
	PropsOfHeaderTooltipRenderer,
} from './DetailsListHeaderTooltip/DetailsListHeaderTooltip';

type PossibleElement = JSX.Element | null;

type Props = BasePropsOfComponentForRenderer<IDetailsHeaderProps>;

/**
 * * Note
 *
 * This component does not throw an error when the field name is missing. See
 * below to understand why.
 */
export function DetailsListHeaderWithTooltips({
	defaultRender,
	originalComponentProps: possibleOriginalProps,
}: Props): PossibleElement {
	type RendererFields = TooltipRendererFields<
		null,
		PropsOfHeaderTooltipRenderer
	>;

	const renderTooltip = ({props}: RendererFields): PossibleElement => {
		return <DetailsListHeaderTooltip {...props} />;
	};

	const {getCallRendererIfDefaultOneExists} =
		new TooltipRendererCreatorService();

	const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> =
		getCallRendererIfDefaultOneExists(null, renderTooltip);

	const renderDefaultHeader = (
		originalProps: IDetailsHeaderProps,
	): PossibleElement => {
		return defaultRender({...originalProps, onRenderColumnHeaderTooltip});
	};

	if (!possibleOriginalProps) return null;
	return renderDefaultHeader(possibleOriginalProps);
}
