import {WorkflowStatus} from 'types';

const workflowOrder = {
	[WorkflowStatus.New]: 1,
	[WorkflowStatus.QualityControlInternal]: 2,
	[WorkflowStatus.QualityControlExternal]: 2,
	[WorkflowStatus.InProgressInternal]: 3,
	[WorkflowStatus.InProgressExternal]: 3,
	[WorkflowStatus.Examination]: 4,
	[WorkflowStatus.Clearing]: 5,
	[WorkflowStatus.ClearingWithReservations]: 5,
	[WorkflowStatus.Finalized]: 6,
	[WorkflowStatus.Archived]: 100,
	[WorkflowStatus.Modified]: 101,
} as const;

export const Workflow = {
	isAfter(status: WorkflowStatus, statusToCompare: WorkflowStatus) {
		return workflowOrder[status] > workflowOrder[statusToCompare];
	},

	isBefore(status: WorkflowStatus, statusToCompare: WorkflowStatus) {
		return workflowOrder[status] < workflowOrder[statusToCompare];
	},
};
