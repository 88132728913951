import React from 'react';
import {useTranslation} from 'react-i18next';
import {
	InputMarketProps,
	MarketNamingTemplateKeys,
} from '../MarketNamingTemplateField';

export const useMarketNamingTemplateValidation = (
	markets: InputMarketProps[],
	namingHint: string,
	templatesToUse: MarketNamingTemplateKeys,
): {
	validateFieldValue: (value: string) => boolean | string;
	isMarketSelected: boolean;
	placeholder: string;
} => {
	const {t} = useTranslation('components/marketnamingtemplatevalidation');

	const isMarketSelected = React.useMemo(
		() => Boolean(markets.length),
		[markets],
	);
	const placeholder = React.useMemo(
		() => (isMarketSelected ? namingHint : t('FieldDisabledPlaceholder')),
		[isMarketSelected],
	);

	const validateFieldValue = React.useCallback(
		(value: string) => {
			const regExps = markets.flatMap(market => {
				return market[templatesToUse]?.map(template => {
					return new RegExp(template.template);
				});
			});
			return (
				regExps.length === 0 ||
				regExps.some(regexp => regexp.test(value)) ||
				namingHint
			);
		},
		[markets],
	);

	return {
		validateFieldValue,
		isMarketSelected,
		placeholder,
	};
};
