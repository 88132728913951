import {EntityPage} from 'components';
import {LoadWrapper} from 'components/LoadWrapper';
import {ViewAuthorizer} from 'components/ViewAuthorizer';
import {ControlledTextField} from 'components/hookForms';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {RegulationCluster, UserRole} from 'types';
import {
	GetAllRegulationClustersDocument,
	useCreateRegulationClusterMutation,
	useDeleteRegulationClusterMutation,
	useGetAllRegulationClustersQuery,
	useUpdateRegulationClusterMutation,
} from '../hooks/regulationClusters.generated';
import {AdminRegulationsClustersPageTooltipTranslationProvider} from './AdminRegulationsClustersPageTooltipTranslationService';

const AdminRegulationsClustersPage: React.FC = () => {
	const {t} = useTranslation('features/adminsection', {
		keyPrefix: 'AdminRegulationsClusterPage',
	});
	const {loading, data} = useGetAllRegulationClustersQuery();
	const [createRegulationClusterMutation] =
		useCreateRegulationClusterMutation();
	const [updateRegulationClusterMutation] =
		useUpdateRegulationClusterMutation();
	const [deleteRegulationClusterMutation] =
		useDeleteRegulationClusterMutation();

	const regulationClusters = React.useMemo(
		() => data?.regulationClusters ?? [],
		[data],
	);

	const refetchQueries = [GetAllRegulationClustersDocument];

	const createRegulationCluster = React.useCallback(
		(regulationCluster: RegulationCluster) => {
			createRegulationClusterMutation({
				variables: {
					input: {
						name: regulationCluster.name,
						// Description gibt es nicht mehr - description: regulationCluster.description,
						// Users gibts auch nicht mehr - users: [],
					},
				},
				refetchQueries,
			});
		},
		[],
	);
	const updateRegulationCluster = React.useCallback(
		(regulationsCluster: RegulationCluster) => {
			updateRegulationClusterMutation({
				variables: {
					input: {
						id: regulationsCluster.id,
						name: regulationsCluster.name,
					},
				},
				refetchQueries,
			});
		},
		[],
	);
	const deleteRegulationCluster = React.useCallback((id: string) => {
		deleteRegulationClusterMutation({
			variables: {
				input: {id},
			},
			refetchQueries,
		});
	}, []);

	return (
		<ViewAuthorizer roles={[UserRole.SystemAdministrator]}>
			<LoadWrapper loading={loading}>
				<AdminRegulationsClustersPageTooltipTranslationProvider>
					<EntityPage
						items={regulationClusters}
						entityDisplayName={t('RegulationCluster')}
						createEntity={createRegulationCluster}
						updateEntity={updateRegulationCluster}
						deleteEntity={deleteRegulationCluster}
						renderAdditionalFormElements={control => (
							<ControlledTextField
								required={true}
								label={t('DescriptionFieldLabel')}
								control={control}
								name={'description'}
								rules={{required: t('DescriptionFieldRequired')}}
								tooltipHostProps={{shouldGetTextFromContext: true}}
							/>
						)}
					/>
				</AdminRegulationsClustersPageTooltipTranslationProvider>
			</LoadWrapper>
		</ViewAuthorizer>
	);
};

export default AdminRegulationsClustersPage;
