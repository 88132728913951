import React from 'react';
import {ITagItemProps, ITagItemStyles, TagItem} from '@fluentui/react';

/**
 * This must not be memoized because the app throws an error if we try using
 * hooks.
 */
const styles: Partial<ITagItemStyles> = {root: {maxWidth: '100%'}};

export const BaselineTag = (tagItemProps: ITagItemProps): JSX.Element => {
	return (
		<TagItem {...tagItemProps} styles={styles}>
			{tagItemProps.item.name}
		</TagItem>
	);
};
