import {
	SanitizedFormFields,
	ParagraphFormFields,
} from './EditParagraphsForm.types';

const parseModelYearFromString = (
	modelYear: string,
): SanitizedFormFields['modelYear'] => {
	/**
	 * If it's an empty string, we don't want to parse it because it would be NaN.
	 */
	return modelYear ? parseInt(modelYear, 10) : undefined;
};

const sanitizeModelYear = (
	modelYear: ParagraphFormFields['modelYear'],
): SanitizedFormFields['modelYear'] => {
	if (typeof modelYear === 'string') return parseModelYearFromString(modelYear);
	return modelYear ?? undefined;
};

/**
 * Implementation notes:
 *
 * I created this function because we there will likely be additional form
 * fields we must sanitize in the future. Ex: We might want to set a default
 * value of an empty array to array fields.
 */
export const sanitizeFormFields = (
	formFields: ParagraphFormFields,
): SanitizedFormFields => {
	return {
		...formFields,
		modelYear: sanitizeModelYear(formFields.modelYear),
	};
};
