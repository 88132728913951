import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllAttachmentCategoriesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllAttachmentCategoriesQuery = { __typename?: 'Query', attachmentCategories?: Array<{ __typename?: 'AttachmentCategory', id: string, name: string, createdAt?: any | null, modifiedAt?: any | null, createdBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null }, modifiedBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null } }> | null };

export type CreateAttachmentCategoryMutationVariables = Types.Exact<{
  input: Types.CreateAttachmentCategoryInput;
}>;


export type CreateAttachmentCategoryMutation = { __typename?: 'Mutation', createAttachmentCategory: { __typename?: 'CreateAttachmentCategoryPayload', attachmentCategory?: { __typename?: 'AttachmentCategory', id: string } | null } };

export type UpdateAttachmentCategoryMutationVariables = Types.Exact<{
  input: Types.UpdateAttachmentCategoryInput;
}>;


export type UpdateAttachmentCategoryMutation = { __typename?: 'Mutation', updateAttachmentCategory: { __typename?: 'UpdateAttachmentCategoryPayload', attachmentCategory?: { __typename?: 'AttachmentCategory', id: string } | null } };

export type DeleteAttachmentCategoryMutationVariables = Types.Exact<{
  input: Types.DeleteAttachmentCategoryInput;
}>;


export type DeleteAttachmentCategoryMutation = { __typename?: 'Mutation', deleteAttachmentCategory: { __typename?: 'DeleteAttachmentCategoryPayload', attachmentCategory?: { __typename?: 'AttachmentCategory', id: string } | null } };


export const GetAllAttachmentCategoriesDocument = gql`
    query GetAllAttachmentCategories {
  attachmentCategories {
    id
    name
    createdAt
    createdBy {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
    modifiedAt
    modifiedBy {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
  }
}
    `;

/**
 * __useGetAllAttachmentCategoriesQuery__
 *
 * To run a query within a React component, call `useGetAllAttachmentCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAttachmentCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAttachmentCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllAttachmentCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllAttachmentCategoriesQuery, GetAllAttachmentCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllAttachmentCategoriesQuery, GetAllAttachmentCategoriesQueryVariables>(GetAllAttachmentCategoriesDocument, options);
      }
export function useGetAllAttachmentCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllAttachmentCategoriesQuery, GetAllAttachmentCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllAttachmentCategoriesQuery, GetAllAttachmentCategoriesQueryVariables>(GetAllAttachmentCategoriesDocument, options);
        }
export type GetAllAttachmentCategoriesQueryHookResult = ReturnType<typeof useGetAllAttachmentCategoriesQuery>;
export type GetAllAttachmentCategoriesLazyQueryHookResult = ReturnType<typeof useGetAllAttachmentCategoriesLazyQuery>;
export type GetAllAttachmentCategoriesQueryResult = Apollo.QueryResult<GetAllAttachmentCategoriesQuery, GetAllAttachmentCategoriesQueryVariables>;
export const CreateAttachmentCategoryDocument = gql`
    mutation CreateAttachmentCategory($input: CreateAttachmentCategoryInput!) {
  createAttachmentCategory(input: $input) {
    attachmentCategory {
      id
    }
  }
}
    `;
export type CreateAttachmentCategoryMutationFn = Apollo.MutationFunction<CreateAttachmentCategoryMutation, CreateAttachmentCategoryMutationVariables>;

/**
 * __useCreateAttachmentCategoryMutation__
 *
 * To run a mutation, you first call `useCreateAttachmentCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAttachmentCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAttachmentCategoryMutation, { data, loading, error }] = useCreateAttachmentCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAttachmentCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateAttachmentCategoryMutation, CreateAttachmentCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAttachmentCategoryMutation, CreateAttachmentCategoryMutationVariables>(CreateAttachmentCategoryDocument, options);
      }
export type CreateAttachmentCategoryMutationHookResult = ReturnType<typeof useCreateAttachmentCategoryMutation>;
export type CreateAttachmentCategoryMutationResult = Apollo.MutationResult<CreateAttachmentCategoryMutation>;
export type CreateAttachmentCategoryMutationOptions = Apollo.BaseMutationOptions<CreateAttachmentCategoryMutation, CreateAttachmentCategoryMutationVariables>;
export const UpdateAttachmentCategoryDocument = gql`
    mutation UpdateAttachmentCategory($input: UpdateAttachmentCategoryInput!) {
  updateAttachmentCategory(input: $input) {
    attachmentCategory {
      id
    }
  }
}
    `;
export type UpdateAttachmentCategoryMutationFn = Apollo.MutationFunction<UpdateAttachmentCategoryMutation, UpdateAttachmentCategoryMutationVariables>;

/**
 * __useUpdateAttachmentCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateAttachmentCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAttachmentCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAttachmentCategoryMutation, { data, loading, error }] = useUpdateAttachmentCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAttachmentCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAttachmentCategoryMutation, UpdateAttachmentCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAttachmentCategoryMutation, UpdateAttachmentCategoryMutationVariables>(UpdateAttachmentCategoryDocument, options);
      }
export type UpdateAttachmentCategoryMutationHookResult = ReturnType<typeof useUpdateAttachmentCategoryMutation>;
export type UpdateAttachmentCategoryMutationResult = Apollo.MutationResult<UpdateAttachmentCategoryMutation>;
export type UpdateAttachmentCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateAttachmentCategoryMutation, UpdateAttachmentCategoryMutationVariables>;
export const DeleteAttachmentCategoryDocument = gql`
    mutation DeleteAttachmentCategory($input: DeleteAttachmentCategoryInput!) {
  deleteAttachmentCategory(input: $input) {
    attachmentCategory {
      id
    }
  }
}
    `;
export type DeleteAttachmentCategoryMutationFn = Apollo.MutationFunction<DeleteAttachmentCategoryMutation, DeleteAttachmentCategoryMutationVariables>;

/**
 * __useDeleteAttachmentCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteAttachmentCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAttachmentCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAttachmentCategoryMutation, { data, loading, error }] = useDeleteAttachmentCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAttachmentCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAttachmentCategoryMutation, DeleteAttachmentCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAttachmentCategoryMutation, DeleteAttachmentCategoryMutationVariables>(DeleteAttachmentCategoryDocument, options);
      }
export type DeleteAttachmentCategoryMutationHookResult = ReturnType<typeof useDeleteAttachmentCategoryMutation>;
export type DeleteAttachmentCategoryMutationResult = Apollo.MutationResult<DeleteAttachmentCategoryMutation>;
export type DeleteAttachmentCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteAttachmentCategoryMutation, DeleteAttachmentCategoryMutationVariables>;