import React from 'react';
import {IDetailsColumnRenderTooltipProps} from '@fluentui/react';
import {PropsWithAriaDescribedBy} from '../../../TooltipWithComponentWithAriaDescribedBy';

export type DetailsListHeadingTooltipChildrenProps = Pick<
	IDetailsColumnRenderTooltipProps,
	'children'
> &
	PropsWithAriaDescribedBy;

export function DetailsListHeadingTooltipChildren({
	'aria-describedby': ariaDescribedBy,
	children,
}: DetailsListHeadingTooltipChildrenProps): JSX.Element {
	return <div aria-describedby={ariaDescribedBy}>{children}</div>;
}
