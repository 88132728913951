import {IDetailsRowProps, IRenderFunction} from '@fluentui/react';
import {getStylesForRowBgColor} from '../entityListStyles';

type Renderer = IRenderFunction<IDetailsRowProps>;
type RendererParams = Parameters<Renderer>;
export type DefaultRowProps = RendererParams[0];
export type DefaultRowRender = RendererParams[1];
export type PossibleRenderedRow = JSX.Element | null;

interface Props {
	defaultProps: RendererParams[0];
	defaultRender: RendererParams[1];
}

export function BaselineRow({
	defaultProps,
	defaultRender,
}: Props): PossibleRenderedRow {
	if (!defaultProps || !defaultRender) return null;

	const renderRowWithGreen = (): any => {
		return defaultRender({
			...defaultProps,
			styles: getStylesForRowBgColor('#d9f2d0'),
		});
	};

	if (defaultProps.item.isCurrent) return renderRowWithGreen();
	return defaultRender(defaultProps);
}
