import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AssignRegulatoryDocumentParagraphsRequirementsMutationVariables = Types.Exact<{
  input: Types.AssignRegulatoryDocumentParagraphsRequirementsInput;
}>;


export type AssignRegulatoryDocumentParagraphsRequirementsMutation = { __typename?: 'Mutation', assignRegulatoryDocumentParagraphsRequirements: { __typename?: 'AssignRegulatoryDocumentParagraphsRequirementsPayload', regulatoryDocument?: { __typename?: 'RegulatoryDocument', id: string } | null } };


export const AssignRegulatoryDocumentParagraphsRequirementsDocument = gql`
    mutation AssignRegulatoryDocumentParagraphsRequirements($input: AssignRegulatoryDocumentParagraphsRequirementsInput!) {
  assignRegulatoryDocumentParagraphsRequirements(input: $input) {
    regulatoryDocument {
      id
    }
  }
}
    `;
export type AssignRegulatoryDocumentParagraphsRequirementsMutationFn = Apollo.MutationFunction<AssignRegulatoryDocumentParagraphsRequirementsMutation, AssignRegulatoryDocumentParagraphsRequirementsMutationVariables>;

/**
 * __useAssignRegulatoryDocumentParagraphsRequirementsMutation__
 *
 * To run a mutation, you first call `useAssignRegulatoryDocumentParagraphsRequirementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignRegulatoryDocumentParagraphsRequirementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignRegulatoryDocumentParagraphsRequirementsMutation, { data, loading, error }] = useAssignRegulatoryDocumentParagraphsRequirementsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignRegulatoryDocumentParagraphsRequirementsMutation(baseOptions?: Apollo.MutationHookOptions<AssignRegulatoryDocumentParagraphsRequirementsMutation, AssignRegulatoryDocumentParagraphsRequirementsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignRegulatoryDocumentParagraphsRequirementsMutation, AssignRegulatoryDocumentParagraphsRequirementsMutationVariables>(AssignRegulatoryDocumentParagraphsRequirementsDocument, options);
      }
export type AssignRegulatoryDocumentParagraphsRequirementsMutationHookResult = ReturnType<typeof useAssignRegulatoryDocumentParagraphsRequirementsMutation>;
export type AssignRegulatoryDocumentParagraphsRequirementsMutationResult = Apollo.MutationResult<AssignRegulatoryDocumentParagraphsRequirementsMutation>;
export type AssignRegulatoryDocumentParagraphsRequirementsMutationOptions = Apollo.BaseMutationOptions<AssignRegulatoryDocumentParagraphsRequirementsMutation, AssignRegulatoryDocumentParagraphsRequirementsMutationVariables>;