import _ from 'lodash';
import {SelectedParagraphs} from 'features/RegulatoryDocuments/RegDocDetailsPage/RegDocDetailsPage.types';
import {QueryParagraph} from 'features/RegulatoryDocuments/RegDocDetailsPage/RegDocDetailsPage.queryTypes';
import {RegulatoryDocumentParagraph} from 'types';

type ParagraphId = RegulatoryDocumentParagraph['id'];

type IdAndTypeName = Pick<RegulatoryDocumentParagraph, 'id' | '__typename'>;

export type CommonOptimisticParagraphFields = Required<IdAndTypeName>;

export class ParagraphOptimisticDataUtils {
	private handleMissingSelectedParagraph = (
		paragraphId: ParagraphId,
	): never => {
		throw new Error(
			`Failed to find the paragraph with id ${paragraphId} in selected paragraphs.`,
		);
	};

	public getSelectedParagraphById = (
		selectedParagraphs: SelectedParagraphs,
		paragraphId: ParagraphId,
	): QueryParagraph => {
		type ParagraphMatch = QueryParagraph | undefined;
		const id: ParagraphId = paragraphId;
		const match: ParagraphMatch = _.find(selectedParagraphs, {id});
		return match ?? this.handleMissingSelectedParagraph(paragraphId);
	};

	public createCommonParagraphFields = (
		paragraph: QueryParagraph,
	): CommonOptimisticParagraphFields => {
		return {id: paragraph.id, __typename: 'RegulatoryDocumentParagraph'};
	};
}
