import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UploadRegulatoryDocumentMutationVariables = Types.Exact<{
  input: Types.UploadRegulatoryDocumentFileInput;
}>;


export type UploadRegulatoryDocumentMutation = { __typename?: 'Mutation', uploadRegulatoryDocumentFile: { __typename?: 'RegulatoryDocumentFilePayload', regulatoryDocumentFile?: { __typename?: 'BlobRef', contentType: string, fileName: string, uri: any } | null, errors?: Array<{ __typename?: 'DoubletError', message: string }> | null } };


export const UploadRegulatoryDocumentDocument = gql`
    mutation UploadRegulatoryDocument($input: UploadRegulatoryDocumentFileInput!) {
  uploadRegulatoryDocumentFile(input: $input) {
    regulatoryDocumentFile {
      contentType
      fileName
      uri
    }
    errors {
      ... on Error {
        message
      }
    }
  }
}
    `;
export type UploadRegulatoryDocumentMutationFn = Apollo.MutationFunction<UploadRegulatoryDocumentMutation, UploadRegulatoryDocumentMutationVariables>;

/**
 * __useUploadRegulatoryDocumentMutation__
 *
 * To run a mutation, you first call `useUploadRegulatoryDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadRegulatoryDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadRegulatoryDocumentMutation, { data, loading, error }] = useUploadRegulatoryDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadRegulatoryDocumentMutation(baseOptions?: Apollo.MutationHookOptions<UploadRegulatoryDocumentMutation, UploadRegulatoryDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadRegulatoryDocumentMutation, UploadRegulatoryDocumentMutationVariables>(UploadRegulatoryDocumentDocument, options);
      }
export type UploadRegulatoryDocumentMutationHookResult = ReturnType<typeof useUploadRegulatoryDocumentMutation>;
export type UploadRegulatoryDocumentMutationResult = Apollo.MutationResult<UploadRegulatoryDocumentMutation>;
export type UploadRegulatoryDocumentMutationOptions = Apollo.BaseMutationOptions<UploadRegulatoryDocumentMutation, UploadRegulatoryDocumentMutationVariables>;