import {
	DefaultButton,
	Dialog,
	DialogFooter,
	DialogType,
	IDialogContentProps,
	IDialogProps,
	PrimaryButton,
} from '@fluentui/react';
import {useTranslation} from 'react-i18next';
import React from 'react';
import {User} from 'types';

export type ApprovalDialogProps = Omit<IDialogProps, 'modalProps'> & {
	onConfirm: () => void;
	requirementName: string;
	users: User[];
};

export const ApprovalDialog: React.FC<ApprovalDialogProps> = ({
	requirementName,
	users,
	...props
}) => {
	const {t} = useTranslation('features/requirements', {
		keyPrefix: 'WorkflowDialog',
	});
	const dialogContentProps: IDialogContentProps = React.useMemo(
		() => ({
			type: DialogType.normal,
			title: t('ApprovalOKHeader'),
			subText: t('ApprovalOKText', {name: requirementName}),
		}),
		[],
	);
	const modalProps = {
		isBlocking: true,
		styles: {main: {maxWidth: 450}},
	};
	return (
		<Dialog
			dialogContentProps={dialogContentProps}
			{...props}
			modalProps={modalProps}
		>
			<DialogFooter>
				<PrimaryButton onClick={props.onConfirm}>
					{t('ApprovalConfirmButton')}
				</PrimaryButton>
				<DefaultButton
					onClick={props.onDismiss as any}
					text={t('CancelButton')}
				/>
			</DialogFooter>
		</Dialog>
	);
};
