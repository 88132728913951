import {
	IMessageBarProps,
	IMessageBarStyles,
	mergeStyles,
	MessageBar,
	MessageBarButton,
	MessageBarType,
} from '@fluentui/react';
import {useNotificationBar} from 'hooks';
import React from 'react';
import {useTranslation} from 'react-i18next';

export type NotificationBarProps = Omit<
	IMessageBarProps,
	'styles' | 'onDismiss' | 'messageBarType'
> & {
	timeout?: number;
};

const NotificationBarStyles: IMessageBarStyles = {
	root: {
		position: 'absolute',
		zIndex: 10000,
	},
};

const getWrapperStyle = (visible: boolean) =>
	mergeStyles({
		position: 'relative',
		transition: 'opacity 0.1s linear',
		opacity: visible ? '1' : '0',
	});

/**
 * Use this along with the notification context to display a notification with
 * a specific message. Note that Layout already has a notification bar.
 */
export const NotificationBar: React.FC<NotificationBarProps> = ({
	timeout: defaultTimeout,
	...messageBarProps
}) => {
	const {t} = useTranslation('components/notificationbar');

	const {message, messageId, setMessage, messageBarType, onShowMoreClick} =
		useNotificationBar();
	const [hideNotification, setHideNotification] = React.useState(true);

	const reset = (): void => {
		setHideNotification(true);
		setMessage(undefined);
	};

	type PossibleTimeout = number | undefined;

	const getPossibleMsgTimeout = (): PossibleTimeout => {
		if (
			message &&
			typeof message !== 'string' &&
			message.timeout !== undefined
		) {
			if (message.timeout === 0) {
				return undefined;
			}

			return message.timeout;
		}

		return defaultTimeout;
	};

	const setTimeoutToResetIfNecessary = (): void => {
		const timeout: PossibleTimeout = getPossibleMsgTimeout();
		if (message && timeout) {
			setTimeout(reset, timeout);
		}
	};

	React.useEffect(() => {
		setHideNotification(!message);
		setTimeoutToResetIfNecessary();
	}, [messageId]);

	const wrapperClassName = React.useMemo(
		() => getWrapperStyle(!hideNotification),
		[hideNotification],
	);

	const actions = React.useMemo(
		() => (
			<div>
				<MessageBarButton onClick={onShowMoreClick}>
					{t('ShowMore')}
				</MessageBarButton>
			</div>
		),
		[onShowMoreClick],
	);

	const possibleMsgText: string | undefined =
		typeof message === 'string' ? message : message?.message;

	return (
		<div className={wrapperClassName}>
			<MessageBar
				{...messageBarProps}
				messageBarType={messageBarType ?? MessageBarType.success}
				styles={NotificationBarStyles}
				onDismiss={() => setHideNotification(true)}
				actions={onShowMoreClick ? actions : undefined}
			>
				{possibleMsgText}
			</MessageBar>
		</div>
	);
};
