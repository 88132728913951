import React from 'react';
import {RegulatoryDocument} from 'types';

interface FilterableRegulatoryDoc
	extends Pick<RegulatoryDocument, 'dateEffective'> {
	workflow: Pick<RegulatoryDocument['workflow'], 'status'>;
}

interface Fields<Doc extends FilterableRegulatoryDoc> {
	docs?: Doc[];
	extraDependencies: React.DependencyList;
}

/**
 * We are using a generic instead of assuming it is a reg doc from either the
 * details page or list of docs. By doing so, we can get a more exact return
 * type.
 */
export const useSortRegulatoryDocs = <Doc extends FilterableRegulatoryDoc>({
	docs,
	extraDependencies,
}: Fields<Doc>): Doc[] => {
	return React.useMemo(
		() =>
			/**
			 * Sorts by date ascending.
			 */
			docs?.slice().sort((a, b) => {
				if (
					a.dateEffective === '' ||
					a.dateEffective === undefined ||
					a.dateEffective === null
				) {
					return -1;
				}

				if (
					b.dateEffective === '' ||
					b.dateEffective === undefined ||
					b.dateEffective === null
				) {
					return 1;
				}

				return a.dateEffective?.localeCompare(b.dateEffective || '');
			}) ?? [],
		[...extraDependencies, docs],
	);
};
