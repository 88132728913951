import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllDriveVariantsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllDriveVariantsQuery = { __typename?: 'Query', driveVariants?: Array<{ __typename?: 'DriveVariant', id: string, name: string, createdAt?: any | null, modifiedAt?: any | null, createdBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null }, modifiedBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null } }> | null };

export type CreateDriveVariantMutationVariables = Types.Exact<{
  input: Types.CreateDriveVariantInput;
}>;


export type CreateDriveVariantMutation = { __typename?: 'Mutation', createDriveVariant: { __typename?: 'CreateDriveVariantPayload', driveVariant?: { __typename?: 'DriveVariant', id: string } | null } };

export type UpdateDriveVariantMutationVariables = Types.Exact<{
  input: Types.UpdateDriveVariantInput;
}>;


export type UpdateDriveVariantMutation = { __typename?: 'Mutation', updateDriveVariant: { __typename?: 'UpdateDriveVariantPayload', driveVariant?: { __typename?: 'DriveVariant', id: string } | null } };

export type DeleteDriveVariantMutationVariables = Types.Exact<{
  input: Types.DeleteDriveVariantInput;
}>;


export type DeleteDriveVariantMutation = { __typename?: 'Mutation', deleteDriveVariant: { __typename?: 'DeleteDriveVariantPayload', driveVariant?: { __typename?: 'DriveVariant', id: string } | null } };


export const GetAllDriveVariantsDocument = gql`
    query GetAllDriveVariants {
  driveVariants {
    id
    name
    createdAt
    createdBy {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
    modifiedAt
    modifiedBy {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
  }
}
    `;

/**
 * __useGetAllDriveVariantsQuery__
 *
 * To run a query within a React component, call `useGetAllDriveVariantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDriveVariantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDriveVariantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllDriveVariantsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllDriveVariantsQuery, GetAllDriveVariantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllDriveVariantsQuery, GetAllDriveVariantsQueryVariables>(GetAllDriveVariantsDocument, options);
      }
export function useGetAllDriveVariantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllDriveVariantsQuery, GetAllDriveVariantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllDriveVariantsQuery, GetAllDriveVariantsQueryVariables>(GetAllDriveVariantsDocument, options);
        }
export type GetAllDriveVariantsQueryHookResult = ReturnType<typeof useGetAllDriveVariantsQuery>;
export type GetAllDriveVariantsLazyQueryHookResult = ReturnType<typeof useGetAllDriveVariantsLazyQuery>;
export type GetAllDriveVariantsQueryResult = Apollo.QueryResult<GetAllDriveVariantsQuery, GetAllDriveVariantsQueryVariables>;
export const CreateDriveVariantDocument = gql`
    mutation CreateDriveVariant($input: CreateDriveVariantInput!) {
  createDriveVariant(input: $input) {
    driveVariant {
      id
    }
  }
}
    `;
export type CreateDriveVariantMutationFn = Apollo.MutationFunction<CreateDriveVariantMutation, CreateDriveVariantMutationVariables>;

/**
 * __useCreateDriveVariantMutation__
 *
 * To run a mutation, you first call `useCreateDriveVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDriveVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDriveVariantMutation, { data, loading, error }] = useCreateDriveVariantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDriveVariantMutation(baseOptions?: Apollo.MutationHookOptions<CreateDriveVariantMutation, CreateDriveVariantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDriveVariantMutation, CreateDriveVariantMutationVariables>(CreateDriveVariantDocument, options);
      }
export type CreateDriveVariantMutationHookResult = ReturnType<typeof useCreateDriveVariantMutation>;
export type CreateDriveVariantMutationResult = Apollo.MutationResult<CreateDriveVariantMutation>;
export type CreateDriveVariantMutationOptions = Apollo.BaseMutationOptions<CreateDriveVariantMutation, CreateDriveVariantMutationVariables>;
export const UpdateDriveVariantDocument = gql`
    mutation UpdateDriveVariant($input: UpdateDriveVariantInput!) {
  updateDriveVariant(input: $input) {
    driveVariant {
      id
    }
  }
}
    `;
export type UpdateDriveVariantMutationFn = Apollo.MutationFunction<UpdateDriveVariantMutation, UpdateDriveVariantMutationVariables>;

/**
 * __useUpdateDriveVariantMutation__
 *
 * To run a mutation, you first call `useUpdateDriveVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDriveVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDriveVariantMutation, { data, loading, error }] = useUpdateDriveVariantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDriveVariantMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDriveVariantMutation, UpdateDriveVariantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDriveVariantMutation, UpdateDriveVariantMutationVariables>(UpdateDriveVariantDocument, options);
      }
export type UpdateDriveVariantMutationHookResult = ReturnType<typeof useUpdateDriveVariantMutation>;
export type UpdateDriveVariantMutationResult = Apollo.MutationResult<UpdateDriveVariantMutation>;
export type UpdateDriveVariantMutationOptions = Apollo.BaseMutationOptions<UpdateDriveVariantMutation, UpdateDriveVariantMutationVariables>;
export const DeleteDriveVariantDocument = gql`
    mutation DeleteDriveVariant($input: DeleteDriveVariantInput!) {
  deleteDriveVariant(input: $input) {
    driveVariant {
      id
    }
  }
}
    `;
export type DeleteDriveVariantMutationFn = Apollo.MutationFunction<DeleteDriveVariantMutation, DeleteDriveVariantMutationVariables>;

/**
 * __useDeleteDriveVariantMutation__
 *
 * To run a mutation, you first call `useDeleteDriveVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDriveVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDriveVariantMutation, { data, loading, error }] = useDeleteDriveVariantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDriveVariantMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDriveVariantMutation, DeleteDriveVariantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDriveVariantMutation, DeleteDriveVariantMutationVariables>(DeleteDriveVariantDocument, options);
      }
export type DeleteDriveVariantMutationHookResult = ReturnType<typeof useDeleteDriveVariantMutation>;
export type DeleteDriveVariantMutationResult = Apollo.MutationResult<DeleteDriveVariantMutation>;
export type DeleteDriveVariantMutationOptions = Apollo.BaseMutationOptions<DeleteDriveVariantMutation, DeleteDriveVariantMutationVariables>;