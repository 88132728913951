import React from 'react';
import {mergeStyles} from '@fluentui/react';
import {ParagraphsListProps} from './components/DocumentDetails';
import {RegDocDetailsPageParagraphsList} from './RegDocDetailsPage/RegDocDetailsPage.ParagraphsList';
import {SidePaneForParagraphs} from './SidePaneForParagraphs';

export default function ParagraphsComparisonPanel(props: ParagraphsListProps) {
	const getClassName = (): string => {
		return mergeStyles({
			width: '50%',
		});
	};

	return (
		<SidePaneForParagraphs extraStyle={getClassName()}>
			<RegDocDetailsPageParagraphsList
				filterable={false}
				onShouldVirtualize={() => false}
				sticky
				{...props}
			/>
		</SidePaneForParagraphs>
	);
}
