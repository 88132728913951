import React from 'react';
import {IconButton, Theme, useTheme} from '@fluentui/react';
import {useBoolean} from '@fluentui/react-hooks';
import {useTranslation} from 'react-i18next';
import {useHelpDropdown} from './_useHelpDropdown';
import {InfoModal} from './_InfoModal';

export const HelpButton = () => {
	const [isModalOpen, {setTrue: showModal, setFalse: hideModal}] =
		useBoolean(false);
	const dropDownMenuProps = useHelpDropdown(showModal);

	const theme: Theme = useTheme();
	const {t: getText} = useTranslation('appShell/navigation', {
		keyPrefix: 'Navigation',
	});
	const label: string = getText('HelpButtonLabel');

	return (
		<>
			<IconButton
				ariaLabel={label}
				menuProps={dropDownMenuProps}
				title={label}
				styles={{
					root: {
						color: theme.palette.white,
						backgroundColor: 'transparent !important',
					},
				}}
				iconProps={{
					iconName: 'Help',
					styles: {
						root: {
							color: theme.palette.white,
						},
					},
				}}
			/>
			<InfoModal {...{isModalOpen, hideModal}} />
		</>
	);
};
