import * as Types from '../types/types.generated';

import { gql } from '@apollo/client';
import { AttachmentFieldsFragmentDoc } from './attachmentFields.generated';
export type RegDocFieldsForDocFormFragment = { __typename?: 'RegulatoryDocument', topicManagementSummary?: { __typename?: 'TopicManagementSummary', quarter: Types.TopicManagementSummaryQuarter, year: number } | null, predecessor?: { __typename?: 'RegulatoryDocument', id: string, name: string } | null, successor?: { __typename?: 'RegulatoryDocument', id: string, name: string } | null, documentSource?: { __typename?: 'DocumentSource', id: string, name: string } | null, document?: { __typename?: 'BlobRef', uri: any, contentType: string, fileName: string } | null, documentReferences?: Array<{ __typename?: 'DocumentReference', referenceDocumentType?: string | null, reference?: { __typename?: 'Reference', id: string, name: string } | null, regulationReferences: Array<{ __typename?: 'Regulation', id: string, name: string, regulationNumber: string }>, regulatoryDocumentReferences: Array<{ __typename?: 'RegulatoryDocument', id: string, name: string }> }> | null, attachments?: Array<{ __typename?: 'AttachmentRef', attachmentId: string, isVkoOnly?: boolean | null, file: { __typename?: 'BlobRef', uri: any, contentType: string, fileName: string }, category?: { __typename?: 'AttachmentCategory', id: string, name: string } | null }> | null };

export const RegDocFieldsForDocFormFragmentDoc = gql`
    fragment RegDocFieldsForDocForm on RegulatoryDocument {
  topicManagementSummary {
    quarter
    year
  }
  predecessor {
    id
    name
  }
  successor {
    id
    name
  }
  documentSource {
    id
    name
  }
  document {
    uri
    contentType
    fileName
  }
  documentReferences {
    referenceDocumentType
    reference {
      id
      name
    }
    regulationReferences {
      id
      name
      regulationNumber
    }
    regulatoryDocumentReferences {
      id
      name
    }
  }
  attachments {
    ...AttachmentFields
  }
}
    ${AttachmentFieldsFragmentDoc}`;