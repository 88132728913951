import * as React from 'react';
import {
	Stack,
	IStackStyles,
	IStackTokens,
	IStackItemStyles,
	Image,
	Link,
	useTheme,
	Theme,
	IImageStyles,
} from '@fluentui/react';
import PorscheLogo from '../../../assets/porsche.svg';
import TraceLogo from '../../../assets/trace.svg';
import {TextSearchBar} from 'components';
import {NavigationRight} from './NavigationRight';

export const Navigation = () => {
	const theme = useTheme();
	const {
		horizontalGapStackTokens,
		logoLinkStyles,
		porscheLogoStyles,
		traceLogoStyles,
		stackStyles,
	} = getStyles(theme);

	return (
		<Stack
			horizontal
			disableShrink
			horizontalAlign='space-between'
			styles={stackStyles}
			tokens={horizontalGapStackTokens}
		>
			<Stack.Item align='start'>
				<Link href='/' styles={logoLinkStyles}>
					<Image src={PorscheLogo} alt='Porsche' styles={porscheLogoStyles} />
					<Image src={TraceLogo} alt='Trace' styles={traceLogoStyles} />
				</Link>
			</Stack.Item>
			<Stack.Item>
				<TextSearchBar />
			</Stack.Item>
			<NavigationRight />
		</Stack>
	);
};

const getStyles = (theme: Theme) => ({
	stackStyles: {
		root: {
			background: theme.palette.neutralPrimary,
			padding: 10,
			height: 70,
		},
	} as IStackStyles,

	horizontalGapStackTokens: {
		padding: 10,
	} as IStackTokens,

	logoLinkStyles: {
		root: {
			color: theme.palette.white,
			padding: 5,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			selectors: {
				':hover': {
					textDecoration: 'none',
				},
			},
		},
	} as IStackItemStyles,

	porscheLogoStyles: {
		root: {
			marginRight: 10,
		},
		image: {
			height: 40,
		},
	} as IImageStyles,

	traceLogoStyles: {
		root: {
			marginRight: 10,
			marginLeft: 10,
		},
		image: {
			height: 20,
		},
	} as IImageStyles,
});
