import React from 'react';
import {FontWeights, Text} from '@fluentui/react';

interface Props {
	children: React.ReactNode;
}

export const RequirementsFieldName: React.FC<Props> = ({children}) => {
	return (
		<Text
			styles={{
				root: {
					fontWeight: FontWeights.semibold,
					minWidth: 90,
				},
			}}
		>
			{children}
		</Text>
	);
};
