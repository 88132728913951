/**
 * If renaming this component, update EntityList's comment accordingly.
 */

import React, {useMemo, createContext} from 'react';
import {
	TooltipTranslationOptions,
	TOOLTIPS_TEXT_KEY,
	useTooltipTranslation,
} from '../translationHooks';
import {throwErrorInDevAndLogInProd} from '../tooltipErrorUtils';

type Translation = string | null;

export interface ValidTooltipTranslationContextValue {
	getTooltipText: (translationKey: string) => Translation;
}

export type TooltipTranslationContextValue =
	null | ValidTooltipTranslationContextValue;

/**
 * Provides a translation function to get the tooltips' text.
 */
export const TooltipTranslationContext =
	createContext<TooltipTranslationContextValue>(null);

interface Props {
	children: React.ReactNode;
	/**
	 * When this is enabled, the provider will assume that the field name uses dot
	 * notation and will use the last word as the translation key.
	 */
	treatAsDottedFormFieldName?: boolean;
	translationHookOptions?: TooltipTranslationOptions;
}

/**
 * This creates a translation provider with all of the necessary values.
 *
 * * Notes:
 *
 * Note that this component's translation function may throw an error when a
 * translation is missing. Note that the generated component will only throw
 * certain errors in development. It will still log all errors to the console,
 * though.
 */
export const createTooltipTranslationProviderFromNamespace = (
	namespace: string,
): React.FC<Props> => {
	const TooltipsProviderForNamespace = ({
		treatAsDottedFormFieldName,
		children,
		translationHookOptions,
	}: Props): JSX.Element => {
		const {t} = useTooltipTranslation(namespace, translationHookOptions);

		const getLastWordFromFieldName = (fieldName: string): string => {
			const splitByDots: string[] = fieldName.split('.');
			const lastIndex: number = splitByDots.length - 1;
			return splitByDots[lastIndex];
		};

		const getTranslationKey = (originalTranslationKey: string): string => {
			if (treatAsDottedFormFieldName)
				return getLastWordFromFieldName(originalTranslationKey);
			return originalTranslationKey;
		};

		const getMissingTranslationError = (
			text: string,
			translationFieldPath: string,
		): Error => {
			return new Error(
				`A translation ('${text}') matches its translation field's path ('${translationFieldPath}'). This indicates that this translation is missing in the namespace "${namespace}".`,
			);
		};

		const handleMissingTranslation = (
			text: string,
			translationFieldPath: string,
		): void => {
			const error: Error = getMissingTranslationError(
				text,
				translationFieldPath,
			);
			throwErrorInDevAndLogInProd(error);
		};

		const getKeyPrefix = (): string => {
			return translationHookOptions?.keyPrefix ?? TOOLTIPS_TEXT_KEY;
		};

		const getTranslationFieldPath = (translationKey: string): string => {
			const keyPrefix: string = getKeyPrefix();
			return `${keyPrefix}.${translationKey}`;
		};

		const validateTranslation = (
			translation: Translation,
			translationKey: string,
		): void => {
			const fieldPath: string = getTranslationFieldPath(translationKey);
			if (translation === fieldPath)
				handleMissingTranslation(translation, fieldPath);
		};

		const getAndValidateTranslation = (
			originalTranslationKey: string,
		): Translation => {
			const translationKey: string = getTranslationKey(originalTranslationKey);
			const translation: Translation = t(translationKey);
			validateTranslation(translation, translationKey);
			return translation;
		};

		const getValue = (): ValidTooltipTranslationContextValue => {
			return {getTooltipText: getAndValidateTranslation};
		};

		const value: ValidTooltipTranslationContextValue = useMemo(getValue, [
			treatAsDottedFormFieldName,
			translationHookOptions,
		]);

		return (
			<TooltipTranslationContext.Provider value={value}>
				{children}
			</TooltipTranslationContext.Provider>
		);
	};

	return TooltipsProviderForNamespace;
};
