import {Stack} from '@fluentui/react';
import React from 'react';
import {UserSettings} from 'components/PersonaButton';
import {HelpButton} from './HelpButton';

export const NavigationRight: React.FC = () => {
	return (
		<Stack.Item>
			<Stack horizontal verticalAlign='center'>
				<Stack.Item styles={{root: {paddingRight: 10}}}>
					<HelpButton />
				</Stack.Item>
				<Stack.Item>
					<UserSettings />
				</Stack.Item>
			</Stack>
		</Stack.Item>
	);
};
