import {FC} from 'react';
import {Controller} from 'react-hook-form';
import {IRenderFunction, ITextFieldProps, TextField} from '@fluentui/react';
import * as React from 'react';
import {HookFormProps} from '../HookFormProps';
import {ActionTagsProps} from 'features/RegulatoryDocuments/components/DocumentDetails/ActionTags';
import {
	TooltipRendererFields,
	TooltipRendererCreatorService,
} from 'features/localizedTooltips/TooltipRendererCreator.service';
import ControlledTextFieldTooltipAndLabel, {
	DataWithHostProps,
} from './ControlledTextFieldLabel';

type PropsFromTextField = Omit<ITextFieldProps, 'defaultValue' | 'name'>;

export interface ControlledTextFieldProps
	extends PropsFromTextField,
		HookFormProps,
		DataWithHostProps {
	actionTags?: ActionTagsProps['tags'];
}

type TextRenderer = IRenderFunction<ITextFieldProps>;

export type TextFieldValue = ITextFieldProps['value'];

export const ControlledTextField: FC<ControlledTextFieldProps> = ({
	tooltipHostProps,
	...other
}) => {
	const {getCallRendererIfDefaultOneExists} =
		new TooltipRendererCreatorService();

	const renderLabelUsingDefaultRenderer = ({
		defaultRender,
		props,
	}: TooltipRendererFields<null, ITextFieldProps>): JSX.Element => {
		return (
			<ControlledTextFieldTooltipAndLabel
				defaultRender={defaultRender}
				originalComponentProps={props}
				tooltipHostProps={tooltipHostProps}
			/>
		);
	};

	const renderLabel: TextRenderer = getCallRendererIfDefaultOneExists(
		null,
		renderLabelUsingDefaultRenderer,
	);

	return (
		<Controller
			name={other.name}
			control={other.control}
			rules={other.rules}
			defaultValue={other.defaultValue || ''}
			render={({
				field: {onChange, onBlur, name: fieldName, value},
				fieldState: {error},
			}) => (
				<TextField
					{...other}
					onChange={onChange}
					value={value}
					onBlur={onBlur}
					name={fieldName}
					errorMessage={error?.message}
					onRenderLabel={renderLabel}
				/>
			)}
		/>
	);
};
