import {Simulation} from 'types';
import i18n from 'i18n/i18n';
import {TFunction} from 'react-i18next';
import {formatDateTime} from 'i18n/localeDateFormat';
import {renderHeaderXL, createZIPXL} from './ExcelExportCommon';
import Excel from 'exceljs';

interface IRegulationVersionDetailPage {
	(arrSimulationFilt: Simulation[], t: TFunction): Promise<void>;
}

export const handleClickSimulations: IRegulationVersionDetailPage = async (
	arrSimulationFilt,
	t,
) => {
	const workbook = new Excel.Workbook();

	const arrColWidth: number[] = [30, 20, 40, 30, 20, 20];
	const strPageName = t('PageTitle', {
		ns: 'features/Simulations/simulationsPage',
	});
	const sheet = workbook.addWorksheet('TRACE_' + strPageName);

	renderHeaderXL(workbook, sheet, arrColWidth, strPageName, t);

	let intRowCount = 4;
	let row = sheet.getRow(intRowCount);
	row.alignment = {wrapText: true, vertical: 'top'};
	row.font = {
		bold: true,
		name: 'Porsche Next TT',
		size: 10,
		color: {argb: 'FFFFFFFF'},
	};

	sheet.addTable({
		name: 'tableSimulation',
		ref: 'B' + String(intRowCount),
		style: {
			showRowStripes: true,
		},
		columns: [
			{
				name: t('EntityName', {ns: 'features/Simulations/simulationsPage'}),
				filterButton: false,
			},
			{
				name: t('createdBy', {ns: 'features/Simulations/simulations'}),
				filterButton: true,
			},
			{
				name: t('modelSeriesReference', {
					ns: 'features/Simulations/simulations',
				}),
				filterButton: true,
			},
			{
				name: t('generationReference', {
					ns: 'features/Simulations/simulations',
				}),
				filterButton: true,
			},
			{
				name: t('modifiedBy', {ns: 'features/Simulations/simulations'}),
				filterButton: true,
			},
			{
				name: t('modifiedAt', {ns: 'features/Simulations/simulations'}),
				filterButton: true,
			},
		],
		rows: [],
	});

	const table = sheet.getTable('tableSimulation');

	intRowCount++;
	for (let ii = 0; ii < arrSimulationFilt.length; ii++) {
		row = sheet.getRow(intRowCount);
		row.alignment = {wrapText: true, vertical: 'top'};
		table.addRow([
			arrSimulationFilt[ii].name,
			arrSimulationFilt[ii].createdBy!.name ?? '',
			arrSimulationFilt[ii].modelSeriesReference ?? '',
			arrSimulationFilt[ii].generationReference ?? '',
			arrSimulationFilt[ii].modifiedBy!.name ?? '',
			arrSimulationFilt[ii].modifiedAt
				? formatDateTime(new Date(arrSimulationFilt[ii].modifiedAt), i18n)
				: '',
		]);
		intRowCount++;
	}

	table.commit();
	const strZipName = strPageName;
	await createZIPXL(workbook, strZipName, t);
};
