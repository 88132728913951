import {
	DefaultButton,
	PrimaryButton,
	Theme,
	mergeStyleSets,
	useTheme,
} from '@fluentui/react';
import React from 'react';
import {Stage, TableImageSplitterContext} from './TableImageSplitter';
import {drawImageOnCanvas} from './Utils';
import {useViewport} from 'hooks';
import {useTranslation} from 'react-i18next';

// Draft here: https://codesandbox.io/s/wild-smoke-9xsl92/

export interface SplittingCanvasProps {
	imgSrc: string;
}

export const SplittingCanvas: React.FC<SplittingCanvasProps> = ({imgSrc}) => {
	const theme = useTheme();
	const classNames = getClassNames(theme);

	const {t} = useTranslation('features/regulatorydocuments', {
		keyPrefix: 'DocumentEditor.TableImageSplitter',
	});

	const {splitLocations, setSplitLocations, setStage, onDismiss, setImgHeight} =
		React.useContext(TableImageSplitterContext);

	const canvasRef = React.useRef<HTMLCanvasElement>();
	const hlineRef = React.useRef<HTMLDivElement>();

	const {width, height} = useViewport();
	const maxCanvasWidth = Math.min(800, width - 100);
	const maxCanvasHeight = Math.min(800, height - 50);

	const drawImage = React.useCallback(
		(onImageDrawn?: () => void) => {
			if (!canvasRef.current) {
				return;
			}

			const canvas = canvasRef.current;

			const setLineWidth = () => {
				if (hlineRef.current) {
					hlineRef.current.style.width = `${canvas.width}px`;
				}
			};

			drawImageOnCanvas(
				imgSrc,
				maxCanvasWidth,
				maxCanvasHeight,
				canvas,
				img => {
					setLineWidth();
					setImgHeight(img.height);
					onImageDrawn?.();
				},
			);
		},
		[imgSrc],
	);

	React.useEffect(() => {
		drawImage();
	}, [drawImage]);

	const drawHorizontalLine = React.useCallback(
		(yCoord: number) => {
			const canvas = canvasRef.current;
			const ctx = canvas?.getContext('2d');

			if (!ctx || !canvas) {
				return;
			}

			const {width, height} = canvas;

			ctx.strokeStyle = 'red';
			ctx.lineWidth = 1.5;

			const y = yCoord * height;

			ctx.beginPath();
			ctx.moveTo(0, y);
			ctx.lineTo(width, y);
			ctx.stroke();
		},
		[canvasRef],
	);

	const onCanvasClick: React.MouseEventHandler<HTMLCanvasElement> =
		React.useCallback(
			e => {
				const canvas = e.target as HTMLCanvasElement;
				const bounds = canvas.getBoundingClientRect();
				const y = e.clientY - bounds.top;
				const {height} = canvas;
				const yCoord = y / height;

				drawHorizontalLine(yCoord);

				setSplitLocations([...splitLocations, yCoord]);
			},
			[splitLocations, drawHorizontalLine],
		);

	const onUndoClick = React.useCallback(() => {
		if (splitLocations.length === 0) {
			return;
		}

		const linesToDraw = splitLocations.slice(0, -1);

		drawImage(() => {
			for (const line of linesToDraw) {
				drawHorizontalLine(line);
			}
		});

		setSplitLocations(linesToDraw);
	}, [splitLocations, drawHorizontalLine, drawImage]);

	const onMouseMove: React.MouseEventHandler<HTMLCanvasElement> =
		React.useCallback(e => {
			if (!hlineRef.current) {
				return;
			}

			const bounds = (e.target as Element).getBoundingClientRect();
			// For vertical splitting: var x = e.clientX - bounds.left;
			const y = e.clientY - bounds.top + 1;

			hlineRef.current.style.top = `${y}px`;
		}, []);

	const onSubmitClick = React.useCallback(() => {
		setStage(Stage.AddMetadata);
	}, [setStage]);

	const onClose = React.useCallback(() => {
		setSplitLocations([]);
		onDismiss();
	}, [onDismiss]);

	return (
		<div className={classNames.splittingCanvas}>
			<div className={classNames.canvasWrapper}>
				<div
					className={classNames.hline}
					id='hlineSplitter'
					ref={hlineRef as any}
				/>
				<canvas
					onMouseMove={onMouseMove}
					ref={canvasRef as any}
					onClick={onCanvasClick}
					className={classNames.canvas}
				/>
			</div>
			<div className={classNames.buttons}>
				<DefaultButton
					className={classNames.button}
					onClick={onUndoClick}
					disabled={splitLocations.length === 0}
				>
					{t('Undo')}
				</DefaultButton>
				<div>
					<PrimaryButton
						className={classNames.button}
						onClick={onSubmitClick}
						disabled={splitLocations.length === 0}
					>
						{t('Continue')}
					</PrimaryButton>
					<DefaultButton className={classNames.button} onClick={onClose}>
						{t('Cancel')}
					</DefaultButton>
				</div>
			</div>
		</div>
	);
};

const getClassNames = (theme: Theme) =>
	mergeStyleSets({
		splittingCanvas: {
			position: 'fixed',
			top: '50%',
			left: '50%',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			minWidth: 400,
		},
		canvas: {
			cursor: 'crosshair',
			position: 'relative',
		},
		canvasWrapper: {
			display: 'flex',
			justifyContent: 'center',
			':hover #hlineSplitter': {
				display: 'block',
			},
			border: `1px dotted ${theme.palette.neutralLight}`,
		},
		hline: {
			position: 'absolute',
			display: 'none',
			height: '2px',
			background: theme.palette.neutralPrimary,
			opacity: 0.7,
			zIndex: 2000,
			pointerEvents: 'none',
			left: '50%',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
		},
		buttons: {
			display: 'flex',
			justifyContent: 'space-between',
		},
		button: {
			margin: '4px',
		},
	});
