import {format as formatDate, Locale} from 'date-fns';
import {de, enUS} from 'date-fns/locale';
import {i18n} from 'i18next';

export enum DateTimeFormat {
	DateShort = 'DateShort',
	DateMonth = 'DateMonth',
	DateMonthWeekday = 'DateMonthWeekday',
	DateTimeShort = 'DateTimeShort',
	DateTimeMonth = 'DateTimeMonth',
	DateTimeMonthWeekday = 'DateTimeMonthWeekday',
}

export const getLocaleForDateFns = (language: string): Locale => {
	return language.startsWith('de') ? de : enUS;
};

export const formatDateTimeI18n = (
	value: any,
	lng: string,
	format: string,
): string => {
	const locale: Locale = getLocaleForDateFns(lng);

	if (isDefaultDate(value)) return '';

	switch (format) {
		case DateTimeFormat.DateShort:
			return formatDate(value, 'P', {locale});
		case DateTimeFormat.DateMonth:
			return formatDate(value, 'PP', {locale});
		case DateTimeFormat.DateMonthWeekday:
			return formatDate(value, 'PPPP', {locale});
		case DateTimeFormat.DateTimeShort:
			return formatDate(value, 'Pp', {locale});
		case DateTimeFormat.DateTimeMonth:
			return formatDate(value, 'PPp', {locale});
		case DateTimeFormat.DateTimeMonthWeekday:
			return formatDate(value, 'PPPPp', {locale});

		default:
			return formatDate(value, 'Pp', {locale});
	}
};

const isDefaultDate = (date: Date): boolean => {
	const defaultDate = new Date(+0);
	return date.getFullYear() === defaultDate.getFullYear();
};

export const formatDateTime = (
	value: Date,
	translation: i18n,
	format?: DateTimeFormat,
) => {
	return formatDateTimeI18n(
		value,
		translation.language,
		format || DateTimeFormat.DateShort,
	);
};
