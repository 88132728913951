import {Separator, Stack, Text} from '@fluentui/react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {
	InputMarketProps,
	MarketNamingTemplateKeys,
} from './MarketNamingTemplateField';

export const MarketNamingTemplateDescriptions: React.FC<{
	markets: InputMarketProps[];
	templatesToUse: MarketNamingTemplateKeys;
}> = ({markets, templatesToUse}) => {
	const {t} = useTranslation('components/marketnamingtemplatevalidation');

	return (
		<Stack tokens={{childrenGap: 5}} styles={{root: {marginTop: 5}}}>
			{markets.map(market => (
				<Stack key={market.id}>
					<Text styles={{root: {textDecoration: 'underline'}}}>
						{market.name}:
					</Text>
					<Stack>
						{market[templatesToUse]?.map(template => (
							<Stack
								horizontal
								key={template.template}
								tokens={{childrenGap: 4}}
							>
								<Text>{'\u2022'}</Text>
								<Text key={template.template}>{template.description}</Text>
							</Stack>
						))}
						{market[templatesToUse]?.length === 0 && (
							<Text>
								<i>{t('NoNamingTemplates')}</i>
							</Text>
						)}
					</Stack>
				</Stack>
			))}
			{markets.length > 0 && <Separator />}
		</Stack>
	);
};
