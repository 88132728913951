import React from 'react';
import {useTranslation} from 'react-i18next';
import {ActivityList} from '../../components/ActivityList';

export const RecentsPage = () => {
	const {t} = useTranslation('features/recents', {
		keyPrefix: 'RecentsPage',
	});
	return <ActivityList title={t('RecentActivity')} />;
};
