import React from 'react';
import {KeywordFields} from '../keywordItem.types';
import {Vkos} from './Vkos';
import {VexClusters} from './VexClusters';
import {SetOptional} from 'type-fest';

/**
 * Although the keywords should have these fields, we set them to optional
 * because many pages do not have proper type safety. This is because they cast
 * their query's types to a type that is less accurate. So, many pages wouldn't
 * show type-errors if these fields were missing. Therefore, this component must
 * handle these fields being missing. We can use @see KeywordFields directly
 * once pages have better type safety.
 */
type ExpectedKeywordFields = SetOptional<KeywordFields, 'vexClusters' | 'vkos'>;

interface Props {
	keyword: ExpectedKeywordFields;
}

export function KeywordTooltip({keyword}: Props): JSX.Element {
	const {vexClusters = [], vkos = []} = keyword;

	return (
		<div>
			<VexClusters vexClusters={vexClusters} />
			<Vkos vkos={vkos} />
		</div>
	);
}
