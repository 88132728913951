import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllMySubscriptionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllMySubscriptionsQuery = { __typename?: 'Query', mySubscriptions: Array<{ __typename?: 'Subscription', id: string, name: string, notificationFormat: Types.NotificationFormat, notificationFrequency: Types.NotificationFrequency, optOnlyForMainKeywords?: boolean | null, createdAt?: any | null, modifiedAt?: any | null, markets: Array<{ __typename?: 'Market', id: string, name: string }>, keywords: Array<{ __typename?: 'Keyword', id: string, name: string, vexClusters: Array<{ __typename?: 'VexCluster', id: string, name: string }>, vkos: Array<{ __typename?: 'User', id: string, name: string }> }>, standardPlusPcmsClusters: Array<{ __typename?: 'StandardPlusPcmsCluster', id: string, name: string }>, createdBy: { __typename?: 'User', id: string, name: string, mail?: string | null, userPrincipalName?: string | null }, modifiedBy: { __typename?: 'User', id: string, name: string, mail?: string | null, userPrincipalName?: string | null } }> };

export type KeywordTagFieldsFragment = { __typename?: 'Keyword', id: string, name: string, vexClusters: Array<{ __typename?: 'VexCluster', id: string, name: string }>, vkos: Array<{ __typename?: 'User', id: string, name: string }> };

export type CreateSubscriptionMutationVariables = Types.Exact<{
  input: Types.CreateSubscriptionInput;
}>;


export type CreateSubscriptionMutation = { __typename?: 'Mutation', createSubscription: { __typename?: 'SubscriptionPayload', subscription: { __typename?: 'Subscription', id: string } } };

export type UpdateSubscriptionMutationVariables = Types.Exact<{
  input: Types.UpdateSubscriptionInput;
}>;


export type UpdateSubscriptionMutation = { __typename?: 'Mutation', updateSubscription: { __typename?: 'SubscriptionPayload', subscription: { __typename?: 'Subscription', id: string } } };

export type DeleteSubscriptionMutationVariables = Types.Exact<{
  input: Types.DeleteSubscriptionInput;
}>;


export type DeleteSubscriptionMutation = { __typename?: 'Mutation', deleteSubscription: { __typename?: 'DeleteSubscriptionPayload', subscription?: { __typename?: 'Subscription', id: string } | null } };

export const KeywordTagFieldsFragmentDoc = gql`
    fragment KeywordTagFields on Keyword {
  id
  name
  vexClusters {
    id
    name
  }
  vkos {
    id
    name
  }
}
    `;
export const GetAllMySubscriptionsDocument = gql`
    query GetAllMySubscriptions {
  mySubscriptions {
    id
    name
    notificationFormat
    notificationFrequency
    optOnlyForMainKeywords
    markets {
      id
      name
    }
    keywords {
      ...KeywordTagFields
    }
    standardPlusPcmsClusters {
      id
      name
    }
    createdAt
    modifiedAt
    createdBy {
      id
      name
      mail
      userPrincipalName
    }
    modifiedBy {
      id
      name
      mail
      userPrincipalName
    }
  }
}
    ${KeywordTagFieldsFragmentDoc}`;

/**
 * __useGetAllMySubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetAllMySubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllMySubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllMySubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllMySubscriptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllMySubscriptionsQuery, GetAllMySubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllMySubscriptionsQuery, GetAllMySubscriptionsQueryVariables>(GetAllMySubscriptionsDocument, options);
      }
export function useGetAllMySubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllMySubscriptionsQuery, GetAllMySubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllMySubscriptionsQuery, GetAllMySubscriptionsQueryVariables>(GetAllMySubscriptionsDocument, options);
        }
export type GetAllMySubscriptionsQueryHookResult = ReturnType<typeof useGetAllMySubscriptionsQuery>;
export type GetAllMySubscriptionsLazyQueryHookResult = ReturnType<typeof useGetAllMySubscriptionsLazyQuery>;
export type GetAllMySubscriptionsQueryResult = Apollo.QueryResult<GetAllMySubscriptionsQuery, GetAllMySubscriptionsQueryVariables>;
export const CreateSubscriptionDocument = gql`
    mutation CreateSubscription($input: CreateSubscriptionInput!) {
  createSubscription(input: $input) {
    subscription {
      id
    }
  }
}
    `;
export type CreateSubscriptionMutationFn = Apollo.MutationFunction<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;

/**
 * __useCreateSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionMutation, { data, loading, error }] = useCreateSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>(CreateSubscriptionDocument, options);
      }
export type CreateSubscriptionMutationHookResult = ReturnType<typeof useCreateSubscriptionMutation>;
export type CreateSubscriptionMutationResult = Apollo.MutationResult<CreateSubscriptionMutation>;
export type CreateSubscriptionMutationOptions = Apollo.BaseMutationOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;
export const UpdateSubscriptionDocument = gql`
    mutation UpdateSubscription($input: UpdateSubscriptionInput!) {
  updateSubscription(input: $input) {
    subscription {
      id
    }
  }
}
    `;
export type UpdateSubscriptionMutationFn = Apollo.MutationFunction<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>;

/**
 * __useUpdateSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpdateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubscriptionMutation, { data, loading, error }] = useUpdateSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>(UpdateSubscriptionDocument, options);
      }
export type UpdateSubscriptionMutationHookResult = ReturnType<typeof useUpdateSubscriptionMutation>;
export type UpdateSubscriptionMutationResult = Apollo.MutationResult<UpdateSubscriptionMutation>;
export type UpdateSubscriptionMutationOptions = Apollo.BaseMutationOptions<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>;
export const DeleteSubscriptionDocument = gql`
    mutation DeleteSubscription($input: DeleteSubscriptionInput!) {
  deleteSubscription(input: $input) {
    subscription {
      id
    }
  }
}
    `;
export type DeleteSubscriptionMutationFn = Apollo.MutationFunction<DeleteSubscriptionMutation, DeleteSubscriptionMutationVariables>;

/**
 * __useDeleteSubscriptionMutation__
 *
 * To run a mutation, you first call `useDeleteSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubscriptionMutation, { data, loading, error }] = useDeleteSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSubscriptionMutation, DeleteSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSubscriptionMutation, DeleteSubscriptionMutationVariables>(DeleteSubscriptionDocument, options);
      }
export type DeleteSubscriptionMutationHookResult = ReturnType<typeof useDeleteSubscriptionMutation>;
export type DeleteSubscriptionMutationResult = Apollo.MutationResult<DeleteSubscriptionMutation>;
export type DeleteSubscriptionMutationOptions = Apollo.BaseMutationOptions<DeleteSubscriptionMutation, DeleteSubscriptionMutationVariables>;