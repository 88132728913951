import _ from 'lodash';
import React, {useCallback, useMemo} from 'react';
import {ICalloutProps, IInputProps, mergeStyleSets} from '@fluentui/react';
import {v4 as uuidv4} from 'uuid';
import {
	ControlOfSimulationsPage,
	IFormFieldsOfSimulationsPage,
	ProjectOfSimulationsPage as Project,
} from '../../SimulationsPage.types';
import {useVehicleProjectsOfSimulationsPageQuery} from '../../simulationsPage.generated';
import {
	ControlledTagPicker as BaseControlledTagPicker,
	ResolveSuggestionsFromBaseOnes,
} from 'components/hookForms';
import {CssLoadWrapper} from 'components/CssLoadWrapper';
import {
	namespaceOfSimulationsPage,
	useTranslationForSimulationsForm,
} from '../../SimulationsPage.utils';
import {UseControllerProps, UseFormSetValue, useWatch} from 'react-hook-form';
import {
	KEY_FOR_OPTION_FOR_WITH_REFERENCE,
	NAME_OF_REFERENCE_CHOICE_FIELD,
} from '../ReferenceChoice';
import {useTranslation} from 'react-i18next';
import {
	SuggestionWithGroupInfo,
	IVehicleProjectSuggestion,
} from './VehicleProjectsReferencePicker.types';
import {} from './VehicleProjectSuggestion/VehicleProjectSuggestion';
import {CustomTagPicker} from './CustomPicker';
import {
	LocalizedTooltipHostProps,
	TooltipTranslationOptions,
	createPathWithTooltipsTextKey,
	createTooltipTranslationProviderFromNamespace,
} from 'features/localizedTooltips';

const Provider = createTooltipTranslationProviderFromNamespace(
	namespaceOfSimulationsPage,
);

export interface PropsOfReferencePicker {
	control: ControlOfSimulationsPage;
	setValue: UseFormSetValue<IFormFieldsOfSimulationsPage>;
}

const ControlledTagPicker = BaseControlledTagPicker<Project>;

export function VehicleProjectsReferencePicker({
	control,
	setValue,
}: PropsOfReferencePicker): JSX.Element {
	/**
	 * Although I could put this at the page level, I put it here to prevent the
	 * user from unnecessarily loading the projects when they are not using the
	 * form to create a Vehicle Project.
	 */
	const {data, loading} = useVehicleProjectsOfSimulationsPageQuery({
		/**
		 * We use this network policy because if we use cache-and-network, the
		 * query's data will change from the cached version of the data to the data
		 * we fetched from the network. If this happens, the the IDs we add to the
		 * projects below will get changed, which means the user's selection might
		 * not get correctly submitted. To prevent this, we use this network policy
		 * instead.
		 */
		fetchPolicy: 'network-only',
	});

	const {t} = useTranslationForSimulationsForm();
	const {t: getCommonFormTranslation} = useTranslation('common/forms');

	const referenceChoice: IFormFieldsOfSimulationsPage['referenceChoice'] =
		useWatch({control, name: NAME_OF_REFERENCE_CHOICE_FIELD});

	const isSimulationWithReference: boolean =
		referenceChoice === KEY_FOR_OPTION_FOR_WITH_REFERENCE;

	type Rules = Exclude<UseControllerProps['rules'], undefined>;

	const rules = useMemo((): Rules => {
		return {
			required: isSimulationWithReference
				? getCommonFormTranslation('Required')
				: false,
		};
	}, [isSimulationWithReference, getCommonFormTranslation]);

	interface ProjectWithId extends Project {
		id: string;
	}

	/**
	 * We sort these because we want to group them by model series. This requires
	 * ensuring that all vehicle projects that are part of the same group are next
	 * to each other.
	 */
	const vehicleProjects = useMemo((): ProjectWithId[] => {
		const addIdToProject = (project: Project): ProjectWithId => {
			/**
			 * We use uuid for the ID instead of the index so that we generate new IDs
			 * each time the list of vehicle projects is recreated. This way, if the
			 * projects' query is refetched for some reason in the future and the
			 * order of vehicle projects changes, the user will not accidentally
			 * submit the wrong vehicle project.
			 */
			return {...project, id: uuidv4()};
		};

		const projects: Project[] = data?.vehicleProjectOverviewPageData ?? [];
		const sorted: Project[] = _.sortBy(projects, ['modelSeries']);
		return sorted.map(addIdToProject);
	}, [data]);

	const getTagName = useCallback((project: Project): Project['generation'] => {
		return project.generation;
	}, []);

	const selectOptionForWithReference = useCallback((): void => {
		setValue(NAME_OF_REFERENCE_CHOICE_FIELD, KEY_FOR_OPTION_FOR_WITH_REFERENCE);
	}, [setValue]);

	/**
	 * We must add the property to each tag here because we need the list of
	 * filtered Vehicle Projects, and this is the only place where we have access
	 * to that.
	 */
	const resolveSuggestionsFromBaseOnes: ResolveSuggestionsFromBaseOnes<Project> =
		useCallback((suggestions): SuggestionWithGroupInfo[] => {
			const getIfSuggestionIs1stItemInItsGroup = (
				suggestion: IVehicleProjectSuggestion,
				index: number,
				suggestions: IVehicleProjectSuggestion[],
			): boolean => {
				if (index === 0) return true;
				const previousSuggestion: IVehicleProjectSuggestion =
					suggestions[index - 1];
				/**
				 * All suggestions that have the same model series are next to each
				 * other. So, if the model series is different between two suggestions,
				 * it means a new group started.
				 */
				return suggestion.modelSeries !== previousSuggestion.modelSeries;
			};

			const createSuggestion = (
				suggestion: IVehicleProjectSuggestion,
				index: number,
				suggestions: IVehicleProjectSuggestion[],
			): SuggestionWithGroupInfo => {
				const isNewGroup: boolean = getIfSuggestionIs1stItemInItsGroup(
					suggestion,
					index,
					suggestions,
				);
				return {...suggestion, isNewGroup};
			};

			return suggestions.map(createSuggestion);
		}, []);

	const classNames = useMemo(() => {
		return mergeStyleSets({
			root: {marginTop: '0.1rem', paddingLeft: '1.625rem'},
		});
	}, []);

	const inputProps = useMemo((): IInputProps => {
		return {placeholder: t('PlaceholderOfVehicleProjectPicker')};
	}, [t]);

	const pickerCalloutProps = useMemo((): ICalloutProps => {
		/**
		 * The max height is important so that the callout doesn't get positioned
		 * over the reference picker's input. Otherwise, the callout might close
		 * immediately after the user clicks on the input.
		 */
		return {
			calloutMaxWidth: 600,
			calloutMaxHeight: 300,
			calloutMinWidth: 260,
		};
	}, []);

	const tooltipsHostProps = useMemo((): LocalizedTooltipHostProps => {
		return {shouldGetTextFromContext: true};
	}, []);

	const optionsForProvider = useMemo((): TooltipTranslationOptions => {
		return {keyPrefix: createPathWithTooltipsTextKey('Form')};
	}, []);

	const name: keyof IFormFieldsOfSimulationsPage = 'vehicleProjects';

	return (
		<div className={classNames.root}>
			<CssLoadWrapper loading={loading}>
				<Provider translationHookOptions={optionsForProvider}>
					<ControlledTagPicker
						name={name}
						control={control}
						getName={getTagName}
						selectableItems={vehicleProjects}
						label={t(`VehicleProject`)}
						rules={rules}
						performExtraActionOnChange={selectOptionForWithReference}
						itemLimit={1}
						inputProps={inputProps}
						hideTagIcon
						resolveSuggestionsFromBaseOnes={resolveSuggestionsFromBaseOnes}
						TagPickerComponent={CustomTagPicker}
						pickerCalloutProps={pickerCalloutProps}
						tooltipHostProps={tooltipsHostProps}
					/>
				</Provider>
			</CssLoadWrapper>
		</div>
	);
}
