import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetRegulatoryDocumentsWithParagraphsQueryVariables = Types.Exact<{
  regulationId: Types.Scalars['ID'];
}>;


export type GetRegulatoryDocumentsWithParagraphsQuery = { __typename?: 'Query', regulation?: { __typename?: 'Regulation', id: string, regulatoryDocuments: Array<{ __typename?: 'RegulatoryDocument', id: string, paragraphs: Array<{ __typename?: 'RegulatoryDocumentParagraph', id: string, requirements: Array<{ __typename?: 'Requirement', id: string }> }> }> } | null };


export const GetRegulatoryDocumentsWithParagraphsDocument = gql`
    query GetRegulatoryDocumentsWithParagraphs($regulationId: ID!) {
  regulation(id: $regulationId) {
    id
    regulatoryDocuments {
      id
      paragraphs {
        id
        requirements {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useGetRegulatoryDocumentsWithParagraphsQuery__
 *
 * To run a query within a React component, call `useGetRegulatoryDocumentsWithParagraphsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegulatoryDocumentsWithParagraphsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegulatoryDocumentsWithParagraphsQuery({
 *   variables: {
 *      regulationId: // value for 'regulationId'
 *   },
 * });
 */
export function useGetRegulatoryDocumentsWithParagraphsQuery(baseOptions: Apollo.QueryHookOptions<GetRegulatoryDocumentsWithParagraphsQuery, GetRegulatoryDocumentsWithParagraphsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRegulatoryDocumentsWithParagraphsQuery, GetRegulatoryDocumentsWithParagraphsQueryVariables>(GetRegulatoryDocumentsWithParagraphsDocument, options);
      }
export function useGetRegulatoryDocumentsWithParagraphsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRegulatoryDocumentsWithParagraphsQuery, GetRegulatoryDocumentsWithParagraphsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRegulatoryDocumentsWithParagraphsQuery, GetRegulatoryDocumentsWithParagraphsQueryVariables>(GetRegulatoryDocumentsWithParagraphsDocument, options);
        }
export type GetRegulatoryDocumentsWithParagraphsQueryHookResult = ReturnType<typeof useGetRegulatoryDocumentsWithParagraphsQuery>;
export type GetRegulatoryDocumentsWithParagraphsLazyQueryHookResult = ReturnType<typeof useGetRegulatoryDocumentsWithParagraphsLazyQuery>;
export type GetRegulatoryDocumentsWithParagraphsQueryResult = Apollo.QueryResult<GetRegulatoryDocumentsWithParagraphsQuery, GetRegulatoryDocumentsWithParagraphsQueryVariables>;