import React, {useContext} from 'react';
import {
	ParagraphsList,
	ParagraphsListProps,
} from '../components/DocumentDetails';
import {
	RegDocDetailsPageInfo,
	RegDocDetailsPageInfoContext,
} from './RegDocDetailsPage.context';

export function RegDocDetailsPageParagraphsList({
	sticky,
	...other
}: ParagraphsListProps) {
	const {shouldShowRefetchIndicator} = useContext(
		RegDocDetailsPageInfoContext,
	) as RegDocDetailsPageInfo;

	/**
	 * We avoid setting it to sticky when the resources are loading so it doesn't
	 * show on top of the Refetch Load Wrapper
	 */
	return (
		<ParagraphsList
			sticky={shouldShowRefetchIndicator ? false : sticky}
			{...other}
		/>
	);
}
