import {getConfig} from 'config';
import {themes} from 'theme';

const config = getConfig();

class ViewSDKClient {
	adobeDCView: any;
	readyPromise: Promise<void>;
	adobeViewer: any;
	results = [];

	constructor() {
		this.readyPromise = new Promise<void>(resolve => {
			if (window.AdobeDC) {
				resolve();
			} else {
				document.addEventListener('adobe_dc_view_sdk.ready', () => {
					resolve();
				});
			}
		});

		this.adobeDCView = undefined;
	}

	ready(): Promise<void> {
		return this.readyPromise;
	}

	async previewFile(_divId: any, url: string) {
		const adobeConfig = {
			clientId: config.REACT_APP_ADOBE_CLIENT_ID,
			downloadWithCredentials: true,
		};

		this.adobeDCView = new window.AdobeDC.View(adobeConfig);

		const adobeViewer = await this.adobeDCView.previewFile(
			{
				content: {
					location: {
						url,
					},
				},
				metaData: {
					fileName: 'PDF Document Preview',
					id: '6d07d124-ac85-43b3-a867-36930f502ac6',
				},
			},
			{
				defaultViewMode: 'FIT_PAGE',
				enableFormFilling: false,
				showBookmarks: false,
				showPrintPDF: false,
				enableAnnotationAPIs: true,
			},
		);

		this.adobeViewer = adobeViewer;
	}

	goToPage(page: number) {
		if (this.adobeViewer) {
			this.adobeViewer
				.getAPIs()
				.then((apis: {gotoLocation: (page: number) => Promise<any>}) => {
					apis.gotoLocation(page);
				});
		}
	}

	initAnnotationsManager() {
		this.adobeViewer
			.getAnnotationManager()
			.then(
				(annotationManager: {
					setConfig: (arg0: {showCommentsPanel: boolean}) => void;
					registerEventListener: (
						arg0: (event: {type: any; data: any}) => void,
						arg1: any,
					) => void;
				}) => {
					annotationManager.setConfig({showCommentsPanel: false});
				},
			);
	}

	markDocument(bounds: number[], annotationId: string, page: number) {
		const annotations = [
			/* Annotation data for text annotation */
			{
				'@context': [
					'https://www.w3.org/ns/anno.jsonld',
					'https://comments.acrobat.com/ns/anno.jsonld',
				],
				type: 'Annotation',
				id: annotationId,
				bodyValue: '',
				motivation: 'commenting',
				target: {
					source: '6d07d124-ac85-43b3-a867-36930f502ac6',
					selector: {
						node: {
							index: page - 1,
						},
						opacity: 0.3,
						subtype: 'highlight',
						boundingBox: bounds,
						quadPoints: [
							bounds[0],
							bounds[3],
							bounds[2],
							bounds[3],
							bounds[0],
							bounds[1],
							bounds[2],
							bounds[1],
						],
						strokeColor: themes.palette?.themeDark,
						strokeWidth: 3,
						type: 'AdobeAnnoSelector',
					},
				},
				creator: {
					type: 'Person',
					name: 'PAG Trace',
				},
				created: new Date().toISOString(),
				modified: new Date().toISOString(),
			},
		];

		const addAnnotation = (annotationManager: any) =>
			annotationManager.addAnnotations(annotations);

		if (this.adobeViewer) {
			this.adobeViewer
				.getAnnotationManager()
				.then(
					async (annotationManager: {
						addAnnotations: (arg0: any) => Promise<any>;
						deleteAnnotations: (arg0?: any) => Promise<any>;
					}) => {
						annotationManager
							.deleteAnnotations()
							.catch((err: any) => {
								if (!err.message.startsWith('Annotation')) {
									console.log(err);
								}
							})
							.finally(() => addAnnotation(annotationManager));
					},
				);
		}
	}

	registerEventsHandler(callBack: (event: any) => void) {
		this.adobeDCView.registerCallback(
			window.AdobeDC.View.Enum.CallbackType.EVENT_LISTENER,
			(event: any) => {
				callBack(event);
			},
			{
				listenOn: [
					window.AdobeDC.View.Enum.FilePreviewEvents.PREVIEW_PAGE_CLICK,
				],
				enableFilePreviewEvents: true,
			},
		);
	}
}

export default ViewSDKClient;
