import React from 'react';
import {
	RenderChildrenFromTooltipId,
	LocalizedTooltipHostWithId,
} from './LocalizedTooltipHostWithId';
import {
	LocalizedTooltipHostProps,
	TooltipOptionsForComponentOrColumn,
} from './LocalizedTooltipHost';

export interface PropsWithAriaDescribedBy {
	'aria-describedby'?: string;
}

type HostProps = Omit<LocalizedTooltipHostProps, 'children'>;

export type PropsForTooltipHoc<OriginalComponentProps> =
	OriginalComponentProps &
		TooltipOptionsForComponentOrColumn<HostProps> & {
			Component: React.FC<OriginalComponentProps>;
		};

export type PropsForTooltipWithComponentWithAriaDescribedBy<
	OriginalComponentProps extends PropsWithAriaDescribedBy,
> = PropsForTooltipHoc<OriginalComponentProps>;

/**
 * This component helps us make tooltips more accessible
 */
export const TooltipWithComponentWithAriaDescribedBy = <
	OriginalComponentProps extends PropsWithAriaDescribedBy,
>({
	Component,
	tooltipHostProps,
	...childProps
}: PropsForTooltipWithComponentWithAriaDescribedBy<OriginalComponentProps>): JSX.Element => {
	const renderChildren: RenderChildrenFromTooltipId = id => {
		return (
			<Component
				aria-describedby={id}
				{...(childProps as unknown as OriginalComponentProps)}
			/>
		);
	};

	return (
		<LocalizedTooltipHostWithId {...tooltipHostProps}>
			{renderChildren}
		</LocalizedTooltipHostWithId>
	);
};
