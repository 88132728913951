/* eslint-disable complexity */
import {
	CommandButton,
	IButtonStyles,
	IIconProps,
	INavLink,
	INavLinkGroup,
	INavStyles,
	ISeparatorStyles,
	IStackItemStyles,
	IStackStyles,
	mergeStyles,
	mergeStyleSets,
	Nav,
	Separator,
	Stack,
	Theme,
	useTheme,
} from '@fluentui/react';
import {useUserContext} from 'authentication/UserContext';
import {
	SidebarInfo,
	useSidebarInfoAndValidateIt,
} from 'context/SidebarInfoProvider';
import {getSideNavigationNode} from 'helpers';
import * as React from 'react';
import {TFunction, useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {SideNavigationContext} from './SideNavigationProvider';
import {PAGE_PATHS} from 'pages';

const createLink = (name: string, url: string, key: string, icon: string) => ({
	name,
	url,
	key,
	icon,
});

const getNavLinkGroups = (
	t: TFunction,
	isAdmin: boolean,
	isVko: boolean,
	isVex: boolean,
	isVkoExternal: boolean,
	isApprover: boolean,
	isReader: boolean,
	isRegulationReader: boolean,
) =>
	[
		{
			links: [
				{
					name: t('Dashboard'),
					url: '/',
					key: 'home',
					icon: 'Home',
				},
				...(isAdmin ||
				isVko ||
				isVex ||
				isApprover ||
				isReader ||
				isRegulationReader
					? [
							{
								name: t('Favorites'),
								url: '/favorites',
								key: 'favorites',
								icon: 'FavoriteStar',
								style: {paddingLeft: 10},
							},
					  ]
					: []),
				...[
					{
						name: t('Recents'),
						url: '/recents',
						key: 'recents',
						icon: 'Clock',
						style: {paddingLeft: 10},
					},
				],
				...(isAdmin ||
				isVko ||
				isVex ||
				isVkoExternal ||
				isApprover ||
				isReader ||
				isRegulationReader
					? [
							{
								name: t('Subscriptions'),
								url: '/subscriptions',
								key: 'subscriptions',
								icon: 'Subscribe',
								style: {paddingLeft: 10},
							},
					  ]
					: []),
				...(isAdmin ||
				isVko ||
				isVex ||
				isVkoExternal ||
				isApprover ||
				isReader ||
				isRegulationReader
					? [
							{
								name: t('Regulations'),
								url: '/regulations',
								key: 'regulations',
								icon: 'EntitlementRedemption',
							},
					  ]
					: []),
				...(isAdmin ||
				isVko ||
				isVex ||
				isApprover ||
				isReader ||
				isRegulationReader
					? [
							{
								name: 'POGIS Archive',
								url: '/pogisArchive',
								key: 'pogisArchive',
								icon: 'Archive',
							},
					  ]
					: []),
				...(isAdmin || isVko || isVex || isApprover || isReader
					? [
							{
								name: t('Requirements'),
								url: '/requirements',
								key: 'requirements',
								icon: 'Quantity',
							},
					  ]
					: []),
				...(isAdmin || isVko || isVex || isApprover || isReader
					? [
							{
								name: t('VehicleProjects'),
								url: '/vehicleProjects',
								key: 'vehicleProjects',
								icon: 'ProjectCollection',
							},
					  ]
					: []),
				...(isAdmin || isVko || isVex || isReader
					? [
							{
								name: t('VkoVexList'),
								url: '/VkoVexList',
								key: 'VkoVexList',
								icon: 'EntitlementRedemption',
							},
					  ]
					: []),
				...(isAdmin || isVko || isVex
					? [
							{
								name: t('Simulations'),
								url: PAGE_PATHS.simulations,
								key: 'simulations',
								icon: 'TableComputed',
							},
					  ]
					: []),
				// Reports need some extra logic, since "reports" array could be empty or undefined
				...(isAdmin ||
				isVko ||
				isVex ||
				isApprover ||
				isReader ||
				isRegulationReader
					? [
							{
								name: t('Reports'),
								url: '/reports/regulatory-documents',
								icon: 'BookAnswers',
								key: `reports-regulatory-documents`,
								expandAriaLabel: t('ExpandReports'),
								links: [
									{
										key: `reports-regulatory-documents`,
										name: t('RegulatoryDocuments'),
										url: '/reports/regulatory-documents',
										icon: 'Table',
										style: {paddingLeft: 20},
									},
								],
							},
					  ]
					: []),
			],
		},
	] as INavLinkGroup[];

const getAdminNavLinkGroups = (t: TFunction) =>
	[
		{
			links: [
				createLink(
					// Despite the underlying entity is markets, we call it Legal Area
					t('Markets'),
					'/admin/markets',
					'markets',
					'WebAppBuilderFragment',
				),
				createLink(
					t('RegulationsClusters'),
					'/admin/regulationsClusters',
					'regulationsClusters',
					'EntitlementPolicy',
				),
				createLink(
					t('VehicleCategories'),
					'/admin/vehicleCategories',
					'vehicleCategories',
					'Car',
				),
				createLink(
					t('DriveVariants'),
					'/admin/driveVariants',
					'driveVariants',
					'EngineeringGroup',
				),
				createLink(
					t('EngineVariants'),
					'/admin/engineVariants',
					'engineVariants',
					'Robot',
				),
				createLink(
					t('GearboxVariants'),
					'/admin/gearboxVariants',
					'gearboxVariants',
					'Settings',
				),
				getSideNavigationNode(
					t('BodyworkVariants'),
					'/admin/bodyworkVariants',
					'bodyworkVariants',
					'WebAppBuilderSlot',
				),
				createLink(
					t('ActiveTypeMarkets'),
					'/admin/activeTypeMarkets',
					'activeTypeMarkets',
					'Market',
				),
				createLink(
					t('Categories'),
					'/admin/categories',
					'categories',
					'StackIndicator',
				),
				createLink(
					t('Keywords'),
					'/admin/keywords',
					'keywords',
					'AzureKeyVault',
				),
				createLink(
					t('VexClusters'),
					'/admin/vexClusters',
					'vexClusters',
					'Group',
				),
				createLink(t('Tags'), '/admin/tags', 'tags', 'Tag'),
				createLink(
					t('PcmsClusters'),
					'/admin/pcmsClusters',
					'pcmsClusters',
					'ProductionFloorManagement',
				),
				createLink(
					t('SystemLevels'),
					'/admin/systemLevels',
					'systemLevels',
					'System',
				),
				createLink(
					t('AttachmentCategories'),
					'/admin/attachmentCategories',
					'attachmentCategories',
					'Attach',
				),
				createLink(
					t('DataProtection'),
					'/admin/dataProtection',
					'dataProtection',
					'Shield',
				),
				createLink(
					t('References'),
					'/admin/references',
					'references',
					'DataflowsLink',
				),
				createLink(
					t('DocumentSources'),
					'/admin/documentSources',
					'documentSources',
					'DocumentSet',
				),
				createLink(t('Countries'), '/admin/countries', 'countries', 'Flag'),
			],
		},
	] as INavLinkGroup[];

const sidebarHeight = 'calc(100vh - 70px)';

export const SideNavigation: React.FunctionComponent = () => {
	const {t} = useTranslation('appShell/sidenavigation', {
		keyPrefix: 'SideNavigation',
	});

	const {
		isAdmin,
		isVko,
		isVex,
		isVkoExternal,
		isApprover,
		isReader,
		isRegulationReader,
	} = useUserContext();

	const adminNav = React.useMemo(() => getAdminNavLinkGroups(t), []);
	const userNav = React.useMemo(
		() =>
			getNavLinkGroups(
				t,
				isAdmin,
				isVko,
				isVex,
				isVkoExternal,
				isApprover,
				isReader,
				isRegulationReader,
			),
		[
			isAdmin,
			isVex,
			isVko,
			isVkoExternal,
			isApprover,
			isReader,
			isRegulationReader,
		],
	);

	const theme = useTheme();
	const {
		stackStyles,
		stackItemStyles,
		activeButtonStyles,
		activeStyles,
		activeStylesCollapsed,
		stackStylesCollapsed,
		collapseNavStyles,
		enhanceNavStyles,
		collapseButtonStyles,
		separatorStyles,
		buttonStyles,
	} = getStyles(theme);

	const [selected, setSelected] = React.useState<string>();

	const {isOpen, setIsOpen}: SidebarInfo = useSidebarInfoAndValidateIt(
		SideNavigationContext,
	);

	const collapsed = !isOpen;

	const setCollapsed = React.useCallback(
		(isCollapsed: boolean): void => {
			setIsOpen(!isCollapsed);
		},
		[setIsOpen],
	);

	const [active, setActive] = React.useState(
		localStorage.getItem('sidenavigation') ?? 'User',
	);
	const [sideNav, setSideNav] = React.useState(userNav);

	React.useEffect(() => {
		setSideNav(active === 'Admin' ? adminNav : userNav);
	}, [
		isAdmin,
		isVex,
		isVko,
		isVkoExternal,
		isApprover,
		isReader,
		isRegulationReader,
	]);

	const adminClick = React.useCallback(() => {
		setSideNav(adminNav);
		setActive('Admin');
		localStorage.setItem('sidenavigation', 'Admin');
	}, [adminNav, active]);

	const userClick = React.useCallback(() => {
		setSideNav(userNav);
		setActive('User');
		localStorage.setItem('sidenavigation', 'User');
	}, [userNav, active]);

	const toggleCollapsed = React.useMemo(
		() => () => {
			setCollapsed(!collapsed);
			localStorage.setItem('navigation-drawer', `${!collapsed}`);
		},
		[collapsed, setCollapsed],
	);

	const navigate = useNavigate();

	const _onLinkClick = React.useCallback(
		(ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
			ev?.preventDefault();

			navigate(item?.url ?? '/');
			setSelected(item?.key ?? '');
		},
		[setSelected, navigate],
	);

	const commandBtnHeight = '2.5rem';

	const calculateContainerHeight = React.useCallback(
		(buttonsHeight: string, extraHeight: string): string => {
			const expandBtnHeight = '2.5rem';
			/**
			 * We must calculate this leave enough room for the admin's buttons at the bottom of the
			 * sidebar.
			 */
			return `calc(${sidebarHeight} - ${buttonsHeight} - ${expandBtnHeight} + ${extraHeight})`;
		},
		[],
	);

	const getCollapsedContainerHeight = React.useCallback((): string => {
		const buttonsHeight = `calc(${commandBtnHeight} * 2)`;
		/**
		 * We add a certain amount to eliminate the extra margin at the bottom,
		 * which is caused by navigation.
		 */
		return calculateContainerHeight(buttonsHeight, '1.5rem');
	}, [calculateContainerHeight]);

	const getContainerHeight = React.useCallback((): string => {
		if (collapsed) return getCollapsedContainerHeight();
		return calculateContainerHeight(commandBtnHeight, '1rem');
	}, [collapsed, calculateContainerHeight, getCollapsedContainerHeight]);

	const classNames = React.useMemo(() => {
		return mergeStyleSets({
			container: {
				overflowY: 'scroll',
				height: getContainerHeight(),
			},
		});
	}, [getContainerHeight]);

	const adminIcon: IIconProps = {iconName: 'AdminALogoInverse32'};
	const userIcon: IIconProps = {iconName: 'AccountBrowser'};
	const enhanceIcon: IIconProps = {iconName: 'DoubleChevronRight'};
	const collapseIcon: IIconProps = {iconName: 'DoubleChevronLeft'};

	return (
		<Stack styles={collapsed ? stackStylesCollapsed : stackStyles}>
			<CommandButton
				iconProps={collapsed ? enhanceIcon : collapseIcon}
				onClick={toggleCollapsed}
				styles={collapseButtonStyles}
				ariaLabel={'collapse'}
			/>
			<Separator styles={separatorStyles} />
			<div className={classNames.container}>
				<Nav
					onLinkClick={_onLinkClick}
					selectedKey={selected}
					ariaLabel='Porsche Project Nav'
					groups={sideNav}
					styles={collapsed ? collapseNavStyles : enhanceNavStyles}
				/>
			</div>
			{isAdmin && (
				<Stack styles={stackItemStyles}>
					<CommandButton
						aria-label='User'
						onClick={userClick}
						iconProps={userIcon}
						styles={active === 'User' ? buttonStyles : activeButtonStyles}
						className={collapsed ? activeStylesCollapsed : activeStyles}
					/>

					<CommandButton
						aria-label='Admin'
						onClick={adminClick}
						iconProps={adminIcon}
						styles={active === 'Admin' ? buttonStyles : activeButtonStyles}
						className={collapsed ? activeStylesCollapsed : activeStyles}
					/>
				</Stack>
			)}
		</Stack>
	);
};

const getStyles = (theme: Theme) => ({
	stackStyles: {
		root: {
			width: '240px',
			height: sidebarHeight,
			position: 'relative',
			background: theme.palette.white,
			boxSizing: 'border-box',
			borderRight: `1px solid ${theme.palette.neutralLight}`,
		},
	} as IStackStyles,
	stackStylesCollapsed: {
		root: {
			height: sidebarHeight,
			position: 'relative',
			background: theme.palette.white,
			boxSizing: 'border-box',
			borderRight: `1px solid ${theme.palette.neutralLight}`,
		},
	} as IStackStyles,
	stackItemStyles: {
		root: {
			position: 'absolute',
			bottom: 0,
			display: 'flex',
			alignItems: 'center',
			flexFlow: 'wrap',
			width: '100%',
		},
	} as IStackItemStyles,
	buttonStyles: {
		root: {background: theme.palette.white},
	} as IButtonStyles,
	activeButtonStyles: {
		root: {
			background: theme.palette.neutralLight,
		},
		icon: {
			color: theme.palette.neutralDark,
		},
		iconHovered: {
			color: theme.palette.neutralDark,
		},
	} as IButtonStyles,
	collapseButtonStyles: {
		root: {
			paddingLeft: '10px',
			selectors: {
				':hover': {
					backgroundColor: theme.palette.neutralLight,
				},
			},
		},
	} as IButtonStyles,
	collapseNavStyles: {
		linkText: {
			display: 'none',
		},
		link: {
			paddingLeft: '10px',
			paddingRight: '10px',
		},
	} as INavStyles,
	enhanceNavStyles: {
		linkText: {
			display: 'flex',
		},
		link: {
			paddingLeft: '10px',
			paddingRight: '10px',
		},
		chevronButton: {
			left: 'auto',
			right: 4,
		},
	} as INavStyles,
	separatorStyles: {
		root: {
			height: '8px',
			padding: '0px',
		},
	} as ISeparatorStyles,
	activeStyles: mergeStyles({
		width: '50%',
		display: 'flex',
		justifyContent: 'center',
	}),
	activeStylesCollapsed: mergeStyles({
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
	}),
});
