import {PersonaSize, SelectionMode} from '@fluentui/react';
import {EntityList, EntityListColumn, EntityListProps} from 'components';
import {
	renderAttachments,
	renderArrayField,
	renderRequirementStatus,
	renderRichtext,
	renderPersona,
	renderReferenceField,
	ValueForAttachmentColumn,
	ArrayItemWithName,
	StatusFieldProps,
	RichTextValue,
	ValueForPersonaColumn,
	ValueForDateColumn,
	ValueForReferenceColumn,
} from 'components/EntityList/ColumnRenderers';
import {getDateColumn, getTextColumn} from 'helpers';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {ColumnValue, Requirement} from 'types';
import {RequirementsTooltipTranslationProvider} from 'features/Requirements/RequirementsTooltipTranslationProvider';
import {renderKeywordsCell} from 'components/Keywords/Keywords';
import {useVehicleProjectsTranslation} from '../../../hooks/vehicleProjectsTranslationHooks';
import {KeywordFields} from 'components/Keywords/keywordItem.types';

type ItemsWithName = ArrayItemWithName[];

/**
 * We use this type to ensure that the items match the columns.
 */
interface RequirementForList {
	name: ColumnValue;
	requirementId?: ColumnValue;
	attachments?: ValueForAttachmentColumn;
	vexClusters: ItemsWithName;
	status: StatusFieldProps['value'];
	definition?: RichTextValue;
	systemLevels: ItemsWithName;
	keywords: KeywordFields[];
	markets: ItemsWithName;
	tags: ItemsWithName;
	documentReferences?: ValueForReferenceColumn;
	createdBy: ValueForPersonaColumn;
	modifiedAt?: ValueForDateColumn;
}

export type RequirementsListProps = Partial<
	Pick<EntityListProps, 'columns' | 'onRenderRow' | 'selection'>
> & {
	items: RequirementForList[];
};

export const useReqColumnsOfDetailsPage = (): EntityListColumn[] => {
	const {t: tEnum} = useTranslation('common/enums');
	const {t} = useVehicleProjectsTranslation();

	/**
	 * If you want to change these columns, please update the requirement's type
	 * (above) if necessary.
	 */
	const columns = React.useMemo((): EntityListColumn[] => {
		const filterable = true;

		return [
			getTextColumn('name', t('RequirementName'), 'name', true, false),
			{
				name: 'attachments',
				key: 'attachments',
				isIconOnly: true,
				iconName: 'Attach',
				fieldName: 'attachments',
				minWidth: 16,
				maxWidth: 16,
				onRender: renderAttachments(),
			},
			{
				key: 'vexClusters',
				name: t('VexCluster'),
				fieldName: 'vexClusters',
				filterable,
				minWidth: 150,
				maxWidth: 200,
				isResizable: true,
				onRender: renderArrayField(),
			},
			{
				key: 'status',
				name: t('Status'),
				fieldName: 'status',
				filterable,
				getFilterLabel: item => tEnum(`RequirementStatus.${item}`),
				minWidth: 150,
				maxWidth: 200,
				isResizable: true,
				onRender: renderRequirementStatus(),
			},
			{
				key: 'definition',
				name: t('Definition'),
				fieldName: 'definition',
				minWidth: 150,
				maxWidth: 200,
				isResizable: true,
				onRender: renderRichtext(),
			},
			{
				key: 'systemLevels',
				name: t('SystemLevels'),
				fieldName: 'systemLevels',
				filterable,
				minWidth: 150,
				maxWidth: 200,
				isResizable: true,
				onRender: renderArrayField(),
			},
			{
				key: 'keywords',
				name: t('Keywords'),
				fieldName: 'keywords',
				filterable,
				minWidth: 150,
				maxWidth: 200,
				isResizable: true,
				onRender: renderKeywordsCell,
			},
			{
				key: 'markets',
				name: t('Markets'),
				fieldName: 'markets',
				filterable,
				minWidth: 150,
				maxWidth: 200,
				isResizable: true,
				onRender: renderArrayField(),
			},
			{
				key: 'tags',
				name: t('Tags'),
				fieldName: 'tags',
				filterable: true,
				minWidth: 150,
				maxWidth: 200,
				isResizable: true,
				onRender: renderArrayField(),
			},
			getTextColumn(
				'requirementId',
				t('RequirementId'),
				'requirementId',
				false,
				false,
			),
			{
				key: 'references',
				name: t('References'),
				iconName: 'Link',
				isIconOnly: true,
				fieldName: 'documentReferences',
				minWidth: 300,
				onRender: renderReferenceField(),
			},
			{
				key: 'createdBy',
				name: t('CreatedBy'),
				fieldName: 'createdBy',
				filterable,
				minWidth: 150,
				maxWidth: 200,
				isResizable: true,
				onRender: renderPersona({size: PersonaSize.size8}),
			},
			getDateColumn('modifiedAt', t('ModifiedAt'), 'modifiedAt', true),
		];
	}, [t, tEnum]);

	return columns;
};

export const RequirementsList: React.FC<RequirementsListProps> = ({
	items,
	...props
}) => {
	const columns: EntityListColumn[] = useReqColumnsOfDetailsPage();

	const navigate = useNavigate();

	const onItemInvoked = React.useCallback(
		(req: Requirement) => navigate(`/requirements/${req.id}`),
		[navigate],
	);

	return (
		<RequirementsTooltipTranslationProvider>
			<EntityList
				sticky
				listId='RequirementsList'
				columns={columns}
				items={items}
				selectionMode={SelectionMode.multiple}
				onItemInvoked={onItemInvoked}
				{...props}
			/>
		</RequirementsTooltipTranslationProvider>
	);
};
