import * as Types from '../types/types.generated';

import { gql } from '@apollo/client';
export type UserFieldsFragment = { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null };

export const UserFieldsFragmentDoc = gql`
    fragment UserFields on User {
  givenName
  id
  mail
  name
  surname
  userPrincipalName
}
    `;