import {IRenderFunction} from '@fluentui/react';

type PropsOfRenderFunction<RendererProps> = Parameters<
	IRenderFunction<RendererProps>
>[0];

export interface TooltipRendererFields<InfoForRenderer, RendererProps> {
	props: PropsOfRenderFunction<RendererProps>;
	defaultRender: IRenderFunction<RendererProps>;
	extraInfoForRenderer: InfoForRenderer;
}

type RenderUsingDefaultRenderer<InfoForRenderer, Props> = (
	fields: TooltipRendererFields<InfoForRenderer, Props>,
) => ReturnType<IRenderFunction<Props>>;

export class TooltipRendererCreatorService {
	public getCallRendererIfDefaultOneExists = <
		ExtraInfoForRenderer,
		RendererProps,
	>(
		extraInfoForRenderer: ExtraInfoForRenderer,
		render: RenderUsingDefaultRenderer<ExtraInfoForRenderer, RendererProps>,
	): IRenderFunction<RendererProps> => {
		return (props, defaultRender) => {
			if (!defaultRender) return null;
			return render({props, defaultRender, extraInfoForRenderer});
		};
	};
}

export interface BasePropsOfComponentForRenderer<Props> {
	defaultRender: IRenderFunction<Props>;
	originalComponentProps: PropsOfRenderFunction<Props>;
}
