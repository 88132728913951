import {mergeStyleSets, Text} from '@fluentui/react';
import emptyResultsImage from 'assets/emptyResults.svg';
import * as React from 'react';
import {useTranslation} from 'react-i18next';

export interface EmptyResultProps {
	text?: string;
	isSearchResult?: boolean;
}

const classNames = mergeStyleSets({
	image: {
		backgroundImage: `url("${emptyResultsImage}")`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center center',
		backgroundSize: 'contain',
		maxHeight: '480px',
		minHeight: '200px',
		flex: 1,
		marginBottom: 2,
	},
	content: {
		padding: '5%',
		flex: 1,
		flexDirection: 'column',
		textAlign: 'center',
	},
});

export const EmptyResults: React.FunctionComponent<EmptyResultProps> = ({
	text,
	isSearchResult,
}) => {
	const {t} = useTranslation('components/entitylist');

	return (
		<div className={classNames.content}>
			<div className={classNames.image} />
			<Text>
				{text || isSearchResult ? t('EmptySearchText') : t('EmptyItemsText')}
			</Text>
		</div>
	);
};
