import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateRegulatoryDocumentMutationVariables = Types.Exact<{
  input: Types.CreateRegulatoryDocumentInput;
  reapprovingVKO?: Types.InputMaybe<Types.EntityRefOfUserInput>;
}>;


export type CreateRegulatoryDocumentMutation = { __typename?: 'Mutation', createRegulatoryDocument: { __typename?: 'RegulatoryDocumentPayload', regulatoryDocument: { __typename?: 'RegulatoryDocument', id: string } } };

export type CheckVersionNameMutationVariables = Types.Exact<{
  input: Types.CheckVersionNameRegulatoryDocumentInput;
}>;


export type CheckVersionNameMutation = { __typename?: 'Mutation', checkVersionNameRegulatoryDocument: { __typename?: 'CheckVersionNameRegulatoryDocumentPayload', boolean?: boolean | null } };


export const CreateRegulatoryDocumentDocument = gql`
    mutation CreateRegulatoryDocument($input: CreateRegulatoryDocumentInput!, $reapprovingVKO: EntityRefOfUserInput) {
  createRegulatoryDocument(input: $input, reapprovingVKO: $reapprovingVKO) {
    regulatoryDocument {
      id
    }
  }
}
    `;
export type CreateRegulatoryDocumentMutationFn = Apollo.MutationFunction<CreateRegulatoryDocumentMutation, CreateRegulatoryDocumentMutationVariables>;

/**
 * __useCreateRegulatoryDocumentMutation__
 *
 * To run a mutation, you first call `useCreateRegulatoryDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRegulatoryDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRegulatoryDocumentMutation, { data, loading, error }] = useCreateRegulatoryDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      reapprovingVKO: // value for 'reapprovingVKO'
 *   },
 * });
 */
export function useCreateRegulatoryDocumentMutation(baseOptions?: Apollo.MutationHookOptions<CreateRegulatoryDocumentMutation, CreateRegulatoryDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRegulatoryDocumentMutation, CreateRegulatoryDocumentMutationVariables>(CreateRegulatoryDocumentDocument, options);
      }
export type CreateRegulatoryDocumentMutationHookResult = ReturnType<typeof useCreateRegulatoryDocumentMutation>;
export type CreateRegulatoryDocumentMutationResult = Apollo.MutationResult<CreateRegulatoryDocumentMutation>;
export type CreateRegulatoryDocumentMutationOptions = Apollo.BaseMutationOptions<CreateRegulatoryDocumentMutation, CreateRegulatoryDocumentMutationVariables>;
export const CheckVersionNameDocument = gql`
    mutation CheckVersionName($input: CheckVersionNameRegulatoryDocumentInput!) {
  checkVersionNameRegulatoryDocument(input: $input) {
    boolean
  }
}
    `;
export type CheckVersionNameMutationFn = Apollo.MutationFunction<CheckVersionNameMutation, CheckVersionNameMutationVariables>;

/**
 * __useCheckVersionNameMutation__
 *
 * To run a mutation, you first call `useCheckVersionNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckVersionNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkVersionNameMutation, { data, loading, error }] = useCheckVersionNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckVersionNameMutation(baseOptions?: Apollo.MutationHookOptions<CheckVersionNameMutation, CheckVersionNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckVersionNameMutation, CheckVersionNameMutationVariables>(CheckVersionNameDocument, options);
      }
export type CheckVersionNameMutationHookResult = ReturnType<typeof useCheckVersionNameMutation>;
export type CheckVersionNameMutationResult = Apollo.MutationResult<CheckVersionNameMutation>;
export type CheckVersionNameMutationOptions = Apollo.BaseMutationOptions<CheckVersionNameMutation, CheckVersionNameMutationVariables>;