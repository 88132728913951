import React, {
	Context,
	Dispatch,
	SetStateAction,
	createContext,
	useContext,
	useMemo,
	useState,
} from 'react';

export interface SidebarInfo {
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export type SidebarInfoContextValue = null | SidebarInfo;

export type SidebarInfoContextType = Context<SidebarInfoContextValue>;

export const createSidebarInfoContext = (): SidebarInfoContextType => {
	return createContext<SidebarInfoContextValue>(null);
};

export const useSidebarInfoAndValidateIt = (
	Context: SidebarInfoContextType,
): SidebarInfo => {
	const value: SidebarInfoContextValue = useContext(Context);
	if (!value) throw new Error('Sidebar context not found.');
	return value;
};

export interface SidebarInfoProviderProps {
	children: React.ReactNode;
	defaultValue?: boolean;
	Context: SidebarInfoContextType;
}

export const SidebarInfoProvider = ({
	children,
	defaultValue = false,
	Context,
}: SidebarInfoProviderProps): JSX.Element => {
	const [isOpen, setIsOpen] = useState<boolean>(defaultValue);

	const info: SidebarInfo = useMemo(() => {
		return {isOpen, setIsOpen};
	}, [isOpen, setIsOpen]);

	return <Context.Provider value={info}>{children}</Context.Provider>;
};
