import {Locale, format} from 'date-fns';
import {getLocaleForDateFns} from 'i18n/localeDateFormat';
import i18next from 'i18next';
import React from 'react';
import {Baseline} from 'types';

type Props = Pick<Baseline, 'createdAt'>;

export function TimeCell({createdAt}: Props): JSX.Element | null {
	if (!createdAt) return null;

	const formatTime = (): string => {
		const locale: Locale = getLocaleForDateFns(i18next.language);
		const date = new Date(createdAt);
		return format(date, 'HH:mm', {locale});
	};

	const time: string = formatTime();
	return <span>{time}</span>;
}
