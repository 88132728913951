import {
	GetRequirementsFormDataQuery,
	GetRequirementsFormDataQueryVariables,
	useGetAggregatedFieldsLazyQuery,
	useGetRequirementsFormDataQuery,
	useUpdateRequirementMutation,
} from './requirements.generated';
import {
	CommonRequirementInputFields as Input,
	RequirementUtilsService,
} from '../RequirementUtils.service';
import {ListOrDetailsRequirement} from '../requirements.types';
import {InternalRefetchQueriesInclude, QueryResult} from '@apollo/client';
import React, {useCallback} from 'react';
import {AttachmentInput, UpdateRequirementInput} from 'types';
import {RenderAdditionalFormElements} from 'components/EntityPage/EntityFormPanels';
import {Path} from 'react-hook-form';
import {useUpdateRequirementAttachmentsMutation} from './requirementAttachments.generated';
import {RequirementFormElements} from '../components/RequirementFormElements';

export type UpdateRequirement = (
	requirement: ListOrDetailsRequirement,
) => Promise<void>;

export const useRequirementUpdater = (
	refetchQueries: InternalRefetchQueriesInclude,
): UpdateRequirement => {
	const [updateRequirementMutation] = useUpdateRequirementMutation();
	const {getCommonInputFields: mapToInput} = new RequirementUtilsService();

	const getInput = (info: ListOrDetailsRequirement): UpdateRequirementInput => {
		const input: Input = mapToInput(info);
		return {id: info.id, ...input};
	};

	const updateRequirement = async (requirement: ListOrDetailsRequirement) => {
		await updateRequirementMutation({
			variables: {input: getInput(requirement)},
			refetchQueries,
		});
	};

	return useCallback(updateRequirement, []);
};

export type UpdateRequirementAttachments = (
	id: string,
	attachments: AttachmentInput[],
) => Promise<void>;

export const useRequirementAttachmentsUpdater =
	(): UpdateRequirementAttachments => {
		const [updateRequirementAttachments] =
			useUpdateRequirementAttachmentsMutation();

		const updateAttachments = async (
			id: string,
			attachments: AttachmentInput[],
		) => {
			await updateRequirementAttachments({
				variables: {input: {requirementId: id, attachments}},
			});
		};

		return React.useCallback(updateAttachments, []);
	};

type FormDataResult = QueryResult<
	GetRequirementsFormDataQuery,
	GetRequirementsFormDataQueryVariables
>;

export interface RequirementsFormElementsInfo<
	Requirement extends ListOrDetailsRequirement,
> {
	formDataResult: FormDataResult;
	renderFormElements: RenderAdditionalFormElements<Requirement>;
}

export const useRequirementsFormElementsRenderer = <
	Requirement extends ListOrDetailsRequirement,
>(): RequirementsFormElementsInfo<Requirement> => {
	const [getAggregatedFields] = useGetAggregatedFieldsLazyQuery();
	const formDataResult: FormDataResult = useGetRequirementsFormDataQuery();

	const onAggregateClick = React.useCallback(
		async (
			id: string,
			setValue: (key: Path<Requirement>, val: any) => void,
		) => {
			await getAggregatedFields({
				variables: {input: id},
			}).then(result => {
				setValue(
					'vehicleCategories' as Path<Requirement>,
					result.data?.requirement?.aggregatedFields.vehicleCategories ?? [],
				);
			});
		},
		[],
	);

	const renderFormElements: RenderAdditionalFormElements<Requirement> = (
		control,
		mode,
		selectedItem,
		setValue,
	) => {
		const id = selectedItem?.id ?? '';

		return (
			<div>
				<RequirementFormElements
					control={control}
					formData={formDataResult.data}
					mode={mode}
					id={id}
					onAggregateClick={() => onAggregateClick(id, setValue)}
				/>
			</div>
		);
	};

	return {formDataResult, renderFormElements};
};
