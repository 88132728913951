import * as Types from '../types/types.generated';

import { gql } from '@apollo/client';
export type MarketNamingTemplateFieldsFragment = { __typename?: 'Market', regulationNamingTemplates: Array<{ __typename?: 'NamingTemplate', template: string, description: string }>, regulatoryDocumentNamingTemplates: Array<{ __typename?: 'NamingTemplate', template: string, description: string }> };

export const MarketNamingTemplateFieldsFragmentDoc = gql`
    fragment MarketNamingTemplateFields on Market {
  regulationNamingTemplates {
    template
    description
  }
  regulatoryDocumentNamingTemplates {
    template
    description
  }
}
    `;