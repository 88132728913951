import * as React from 'react';
import {Regulation} from 'types';
import {RegulationsContext} from '.';

export const RegulationsContextProvider: React.FC<{
	regulation?: Partial<Regulation> | null;
	children: React.ReactNode;
}> = ({regulation = null, children}) => {
	const [selectedRegulation, setSelectedRegulation] =
		React.useState<Partial<Regulation> | null>(regulation);

	return (
		<RegulationsContext.Provider
			value={{
				selectedRegulation,
				setSelectedRegulation,
			}}
		>
			{children}
		</RegulationsContext.Provider>
	);
};
