import {useUserContext} from 'authentication/UserContext';
import {useCommand} from './useCommand';
import {
	useAddFavoriteMutation,
	useRemoveFavoriteMutation,
} from './useFavoriteCommand.generated';
import {DocumentNode} from 'graphql';
import {MyFavoritesIdsOnlyDocument} from 'authentication/hooks/myFavorites.generated';
import {useTranslation} from 'react-i18next';
import {UserRole} from 'types';

export type FavoriteCommandOptions = {
	refetchQueries?: DocumentNode[];
	farCommand?: boolean;
	priority?: number;
	roles?: UserRole[];
};

export const useFavoriteCommand = (
	entityId: string,
	deps: any[],
	options?: FavoriteCommandOptions,
) => {
	const {farCommand = true, priority = 11, refetchQueries = []} = options || {};

	const {t} = useTranslation('hooks/useFavoriteCommand');

	const [addFavorite] = useAddFavoriteMutation();
	const [removeFavorite] = useRemoveFavoriteMutation();

	const {favoriteIds} = useUserContext();
	const isFavorite = favoriteIds && favoriteIds[entityId];

	useCommand(
		{
			key: 'favorite',
			title: isFavorite ? t('RemoveFavorite') : t('AddFavorite'),
			iconOnly: true,
			iconProps: isFavorite
				? {iconName: 'FavoriteStarFill'}
				: {iconName: 'FavoriteStar'},
			farCommand,
			priority,
			hidden: favoriteIds === undefined,
			onClick() {
				if (isFavorite) {
					removeFavorite({
						variables: {
							id: entityId,
						},
						refetchQueries: [...refetchQueries, MyFavoritesIdsOnlyDocument],
					});
				} else {
					addFavorite({
						variables: {
							id: entityId,
						},
						refetchQueries: [...refetchQueries, MyFavoritesIdsOnlyDocument],
					});
				}
			},
			roles: options?.roles,
		},
		[favoriteIds, ...deps],
	);
};
