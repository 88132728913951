import {Stack, mergeStyleSets} from '@fluentui/react';
import React from 'react';
import {VexClusterItem, VexClusterOfVexClustersField} from './VexClusterItem';
import {RequirementsFieldName} from '../RequirementsFieldName';
import {useRequirementsTranslation} from '../useRequirementsTranslation';

interface Props {
	clusters: VexClusterOfVexClustersField[];
}

export const VexClustersField: React.FC<Props> = ({clusters}) => {
	const {t: getText} = useRequirementsTranslation();

	const renderCluster = (
		cluster: VexClusterOfVexClustersField,
	): JSX.Element => {
		return <VexClusterItem cluster={cluster} key={cluster.id} />;
	};

	const classNames = mergeStyleSets({
		list: {
			paddingLeft: '1rem',
		},
	});

	const renderedClusters: JSX.Element[] = clusters.map(renderCluster);

	return (
		<Stack horizontal>
			<RequirementsFieldName>{getText('VEX Cluster')}:</RequirementsFieldName>
			<ul className={classNames.list}>{renderedClusters}</ul>
		</Stack>
	);
};
