import {RegulatoryDocumentParagraph} from 'types';
import React, {Dispatch, SetStateAction} from 'react';

type PossibleIndexToScrollTo = number | undefined;

/**
 * ! Important
 *
 * This context has inaccurate types; the paragraph will almost never have all
 * of the RegulatoryDocumentParagraph's fields.
 *
 * * Notes
 *
 * We cannot use the ParagraphList's paragraph type here because the paragraph's
 * type depends on the page that uses this context and which paragraphs it
 * provides to the provider. Since pages' might query different paragraphs, we
 * cannot assume that the paragraph is of any specific type.
 */
export type ParagraphsContextProps<Paragraph = RegulatoryDocumentParagraph> = {
	selectedParagraphs: Paragraph[];
	setSelectedParagraphs: (selectedParagraphs: Paragraph[]) => void;
	secondSelectedParagraphs: Paragraph[];
	setSecondSelectedParagraphs: (secondSelectedParagraphs: Paragraph[]) => void;
	filteredParagraphs: Paragraph[];
	setFilteredParagraphs: (filteredParagraphs: Paragraph[]) => void;
	scrollToParagraphId: string | undefined;
	setScrollToParagraphId: (paragraphId: string) => void;
	indexToScrollTo: PossibleIndexToScrollTo;
	/**
	 * Use this instead of setting the initial focused index on the paragraph.
	 * Otherwise, the user will be scrolled to that index each time they select a
	 * paragraph.
	 */
	setIndexToScrollTo: Dispatch<SetStateAction<PossibleIndexToScrollTo>>;
};

export const ParagraphsContext = React.createContext<ParagraphsContextProps>({
	selectedParagraphs: [],
	secondSelectedParagraphs: [],
	regulatoryDocumentId: '',
} as any);

export const ParagraphsContextProvider: React.FC<{
	selectedParagraphs?: RegulatoryDocumentParagraph[];
	secondSelectedParagraphs?: RegulatoryDocumentParagraph[];
	children: React.ReactNode;
}> = ({selectedParagraphs, secondSelectedParagraphs, children}) => {
	const [paragraphs, setParagraphs] = React.useState<
		RegulatoryDocumentParagraph[]
	>(selectedParagraphs || []);

	const [secondParagraphs, setSecondParagraphs] = React.useState<
		RegulatoryDocumentParagraph[]
	>(secondSelectedParagraphs || []);

	const [filteredParagraphs, setFilteredParagraphs] = React.useState<
		RegulatoryDocumentParagraph[]
	>([]);

	const [scrollToParagraphId, setScrollToParagraphId] = React.useState<
		string | undefined
	>(undefined);

	const [indexToScrollTo, setIndexToScrollTo] =
		React.useState<PossibleIndexToScrollTo>(undefined);

	return (
		<ParagraphsContext.Provider
			value={{
				selectedParagraphs: paragraphs,
				setSelectedParagraphs: setParagraphs,
				secondSelectedParagraphs: secondParagraphs,
				setSecondSelectedParagraphs: setSecondParagraphs,
				filteredParagraphs,
				setFilteredParagraphs,
				scrollToParagraphId,
				setScrollToParagraphId,
				indexToScrollTo,
				setIndexToScrollTo,
			}}
		>
			{children}
		</ParagraphsContext.Provider>
	);
};

export const useParagraphsContext = <
	Paragraph = RegulatoryDocumentParagraph,
>() =>
	React.useContext(
		ParagraphsContext,
	) as unknown as ParagraphsContextProps<Paragraph>;
