import {
	Checkbox,
	DirectionalHint,
	ICheckboxProps,
	IRenderFunction,
} from '@fluentui/react';
import React from 'react';
import {
	TooltipRendererFields,
	TooltipRendererCreatorService,
} from '../../TooltipRendererCreator.service';
import {TooltipWithCheckboxLabel} from './TooltipWithCheckboxLabel';
import {TooltipOptionsForComponentOrColumn} from 'features/localizedTooltips/LocalizedTooltipHost';

export type PropsOfCheckboxWithTooltip = ICheckboxProps &
	TooltipOptionsForComponentOrColumn;

/**
 * We created this component because the checkbox needs a special renderer to
 * pass the correct accessibility property.
 */
export function CheckboxWithTooltip({
	tooltipHostProps,
	...checkboxProps
}: PropsOfCheckboxWithTooltip): JSX.Element {
	const {getCallRendererIfDefaultOneExists} =
		new TooltipRendererCreatorService();

	const renderLabelWithDefaultRenderer = ({
		props,
		defaultRender,
	}: TooltipRendererFields<null, ICheckboxProps>): JSX.Element => {
		return (
			<TooltipWithCheckboxLabel
				originalComponentProps={props}
				defaultRender={defaultRender}
				directionalHint={DirectionalHint.leftCenter}
				{...tooltipHostProps}
			/>
		);
	};

	const renderLabel: IRenderFunction<ICheckboxProps> =
		getCallRendererIfDefaultOneExists(null, renderLabelWithDefaultRenderer);

	return <Checkbox onRenderLabel={renderLabel} {...checkboxProps} />;
}
