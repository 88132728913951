import * as Types from '../../../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type KeywordAssignmentsFormDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type KeywordAssignmentsFormDataQuery = { __typename?: 'Query', keywords?: Array<{ __typename?: 'Keyword', id: string, name: string }> | null };


export const KeywordAssignmentsFormDataDocument = gql`
    query KeywordAssignmentsFormData {
  keywords {
    id
    name
  }
}
    `;

/**
 * __useKeywordAssignmentsFormDataQuery__
 *
 * To run a query within a React component, call `useKeywordAssignmentsFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeywordAssignmentsFormDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeywordAssignmentsFormDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useKeywordAssignmentsFormDataQuery(baseOptions?: Apollo.QueryHookOptions<KeywordAssignmentsFormDataQuery, KeywordAssignmentsFormDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KeywordAssignmentsFormDataQuery, KeywordAssignmentsFormDataQueryVariables>(KeywordAssignmentsFormDataDocument, options);
      }
export function useKeywordAssignmentsFormDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KeywordAssignmentsFormDataQuery, KeywordAssignmentsFormDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KeywordAssignmentsFormDataQuery, KeywordAssignmentsFormDataQueryVariables>(KeywordAssignmentsFormDataDocument, options);
        }
export type KeywordAssignmentsFormDataQueryHookResult = ReturnType<typeof useKeywordAssignmentsFormDataQuery>;
export type KeywordAssignmentsFormDataLazyQueryHookResult = ReturnType<typeof useKeywordAssignmentsFormDataLazyQuery>;
export type KeywordAssignmentsFormDataQueryResult = Apollo.QueryResult<KeywordAssignmentsFormDataQuery, KeywordAssignmentsFormDataQueryVariables>;