import React from 'react';
import {useTranslation} from 'react-i18next';
import {useUserContext} from 'authentication/UserContext';
import {ArrayValues} from 'components/EntityList/ColumnRenderers';
import {Separator, Stack} from '@fluentui/react';
import {RegulatoryDocumentParagraph} from 'types';

export const useMetadata = () => {
	const {t} = useTranslation('features/regulatorydocuments', {
		keyPrefix: 'CompareVersionPanel',
	});
	const {isVex} = useUserContext();
	const renderArraySection = (arr: any[], tKey: string) =>
		arr.length > 0 ? (
			<Stack.Item>
				<Stack horizontal tokens={{childrenGap: 4}}>
					<Stack.Item>{`${t(tKey)}:`}</Stack.Item>
					<Stack.Item>
						<ArrayValues array={arr} />
					</Stack.Item>
				</Stack>
			</Stack.Item>
		) : (
			<></>
		);

	const renderMetadata = (paragraph: RegulatoryDocumentParagraph) => (
		<>
			{(paragraph.vehicleCategories.length > 0 ||
				paragraph.keywords.length > 0 ||
				paragraph.categories.length > 0 ||
				paragraph.driveVariants.length > 0) && <Separator />}
			<Stack>
				{/* Non-Vex Roles */}
				{!isVex &&
					renderArraySection(paragraph.vehicleCategories, 'VehicleCategories')}
				{!isVex && renderArraySection(paragraph.keywords, 'Keywords')}
				{!isVex && renderArraySection(paragraph.categories, 'Categories')}
				{!isVex && renderArraySection(paragraph.driveVariants, 'DriveVariants')}
				{/* Vex Roles */}
				{isVex && renderArraySection(paragraph.tags, 'Tags')}
				{isVex && renderArraySection(paragraph.requirements, 'Requirements')}
			</Stack>
		</>
	);

	return {renderMetadata};
};
