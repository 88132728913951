import React, {useState} from 'react';
import {PrimaryButton, Spinner, IButtonProps} from '@fluentui/react';

export interface ActionButtonProps extends IButtonProps {
	onClick: () => Promise<void>;
	children: React.ReactNode;
}

export const ActionButton = ({
	onClick,
	children,
	...props
}: ActionButtonProps) => {
	const [isLoading, setIsLoading] = useState(false);
	const handleClick = async () => {
		setIsLoading(true);

		await onClick();

		setIsLoading(false);
	};

	return (
		<PrimaryButton disabled={isLoading} onClick={handleClick} {...props}>
			{isLoading ? <Spinner /> : children}
		</PrimaryButton>
	);
};
