import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllActiveTypeMarketsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllActiveTypeMarketsQuery = { __typename?: 'Query', activeTypeMarkets?: Array<{ __typename?: 'ActiveTypeMarket', id: string, name: string, createdAt?: any | null, modifiedAt?: any | null, createdBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null }, modifiedBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null } }> | null };

export type CreateActiveTypeMarketMutationVariables = Types.Exact<{
  input: Types.CreateActiveTypeMarketInput;
}>;


export type CreateActiveTypeMarketMutation = { __typename?: 'Mutation', createActiveTypeMarket: { __typename?: 'CreateActiveTypeMarketPayload', activeTypeMarket?: { __typename?: 'ActiveTypeMarket', id: string } | null } };

export type UpdateActiveTypeMarketMutationVariables = Types.Exact<{
  input: Types.UpdateActiveTypeMarketInput;
}>;


export type UpdateActiveTypeMarketMutation = { __typename?: 'Mutation', updateActiveTypeMarket: { __typename?: 'UpdateActiveTypeMarketPayload', activeTypeMarket?: { __typename?: 'ActiveTypeMarket', id: string } | null } };

export type DeleteActiveTypeMarketMutationVariables = Types.Exact<{
  input: Types.DeleteActiveTypeMarketInput;
}>;


export type DeleteActiveTypeMarketMutation = { __typename?: 'Mutation', deleteActiveTypeMarket: { __typename?: 'DeleteActiveTypeMarketPayload', activeTypeMarket?: { __typename?: 'ActiveTypeMarket', id: string } | null } };


export const GetAllActiveTypeMarketsDocument = gql`
    query GetAllActiveTypeMarkets {
  activeTypeMarkets {
    id
    name
    createdAt
    createdBy {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
    modifiedAt
    modifiedBy {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
  }
}
    `;

/**
 * __useGetAllActiveTypeMarketsQuery__
 *
 * To run a query within a React component, call `useGetAllActiveTypeMarketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllActiveTypeMarketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllActiveTypeMarketsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllActiveTypeMarketsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllActiveTypeMarketsQuery, GetAllActiveTypeMarketsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllActiveTypeMarketsQuery, GetAllActiveTypeMarketsQueryVariables>(GetAllActiveTypeMarketsDocument, options);
      }
export function useGetAllActiveTypeMarketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllActiveTypeMarketsQuery, GetAllActiveTypeMarketsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllActiveTypeMarketsQuery, GetAllActiveTypeMarketsQueryVariables>(GetAllActiveTypeMarketsDocument, options);
        }
export type GetAllActiveTypeMarketsQueryHookResult = ReturnType<typeof useGetAllActiveTypeMarketsQuery>;
export type GetAllActiveTypeMarketsLazyQueryHookResult = ReturnType<typeof useGetAllActiveTypeMarketsLazyQuery>;
export type GetAllActiveTypeMarketsQueryResult = Apollo.QueryResult<GetAllActiveTypeMarketsQuery, GetAllActiveTypeMarketsQueryVariables>;
export const CreateActiveTypeMarketDocument = gql`
    mutation CreateActiveTypeMarket($input: CreateActiveTypeMarketInput!) {
  createActiveTypeMarket(input: $input) {
    activeTypeMarket {
      id
    }
  }
}
    `;
export type CreateActiveTypeMarketMutationFn = Apollo.MutationFunction<CreateActiveTypeMarketMutation, CreateActiveTypeMarketMutationVariables>;

/**
 * __useCreateActiveTypeMarketMutation__
 *
 * To run a mutation, you first call `useCreateActiveTypeMarketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateActiveTypeMarketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createActiveTypeMarketMutation, { data, loading, error }] = useCreateActiveTypeMarketMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateActiveTypeMarketMutation(baseOptions?: Apollo.MutationHookOptions<CreateActiveTypeMarketMutation, CreateActiveTypeMarketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateActiveTypeMarketMutation, CreateActiveTypeMarketMutationVariables>(CreateActiveTypeMarketDocument, options);
      }
export type CreateActiveTypeMarketMutationHookResult = ReturnType<typeof useCreateActiveTypeMarketMutation>;
export type CreateActiveTypeMarketMutationResult = Apollo.MutationResult<CreateActiveTypeMarketMutation>;
export type CreateActiveTypeMarketMutationOptions = Apollo.BaseMutationOptions<CreateActiveTypeMarketMutation, CreateActiveTypeMarketMutationVariables>;
export const UpdateActiveTypeMarketDocument = gql`
    mutation UpdateActiveTypeMarket($input: UpdateActiveTypeMarketInput!) {
  updateActiveTypeMarket(input: $input) {
    activeTypeMarket {
      id
    }
  }
}
    `;
export type UpdateActiveTypeMarketMutationFn = Apollo.MutationFunction<UpdateActiveTypeMarketMutation, UpdateActiveTypeMarketMutationVariables>;

/**
 * __useUpdateActiveTypeMarketMutation__
 *
 * To run a mutation, you first call `useUpdateActiveTypeMarketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActiveTypeMarketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActiveTypeMarketMutation, { data, loading, error }] = useUpdateActiveTypeMarketMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateActiveTypeMarketMutation(baseOptions?: Apollo.MutationHookOptions<UpdateActiveTypeMarketMutation, UpdateActiveTypeMarketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateActiveTypeMarketMutation, UpdateActiveTypeMarketMutationVariables>(UpdateActiveTypeMarketDocument, options);
      }
export type UpdateActiveTypeMarketMutationHookResult = ReturnType<typeof useUpdateActiveTypeMarketMutation>;
export type UpdateActiveTypeMarketMutationResult = Apollo.MutationResult<UpdateActiveTypeMarketMutation>;
export type UpdateActiveTypeMarketMutationOptions = Apollo.BaseMutationOptions<UpdateActiveTypeMarketMutation, UpdateActiveTypeMarketMutationVariables>;
export const DeleteActiveTypeMarketDocument = gql`
    mutation DeleteActiveTypeMarket($input: DeleteActiveTypeMarketInput!) {
  deleteActiveTypeMarket(input: $input) {
    activeTypeMarket {
      id
    }
  }
}
    `;
export type DeleteActiveTypeMarketMutationFn = Apollo.MutationFunction<DeleteActiveTypeMarketMutation, DeleteActiveTypeMarketMutationVariables>;

/**
 * __useDeleteActiveTypeMarketMutation__
 *
 * To run a mutation, you first call `useDeleteActiveTypeMarketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActiveTypeMarketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteActiveTypeMarketMutation, { data, loading, error }] = useDeleteActiveTypeMarketMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteActiveTypeMarketMutation(baseOptions?: Apollo.MutationHookOptions<DeleteActiveTypeMarketMutation, DeleteActiveTypeMarketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteActiveTypeMarketMutation, DeleteActiveTypeMarketMutationVariables>(DeleteActiveTypeMarketDocument, options);
      }
export type DeleteActiveTypeMarketMutationHookResult = ReturnType<typeof useDeleteActiveTypeMarketMutation>;
export type DeleteActiveTypeMarketMutationResult = Apollo.MutationResult<DeleteActiveTypeMarketMutation>;
export type DeleteActiveTypeMarketMutationOptions = Apollo.BaseMutationOptions<DeleteActiveTypeMarketMutation, DeleteActiveTypeMarketMutationVariables>;