import {EntityPage} from 'components';
import {ViewAuthorizer} from 'components/ViewAuthorizer';
import {LoadWrapper} from 'components/LoadWrapper';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {DocumentSource, UserRole} from 'types';
import {
	useCreateDocumentSourceMutation,
	useUpdateDocumentSourceMutation,
	useDeleteDocumentSourceMutation,
	GetDocumentSourcesDocument,
} from './hooks/documentSources.generated';
import {DocumentSourcesTooltipTranslationProvider} from 'features/localizedTooltips';
import {useGetDocumentSourcesQuery} from './hooks/documentSources.generated';

const AdminDocumentSourcesPage: React.FC = () => {
	const {t} = useTranslation('features/adminsection', {
		keyPrefix: 'AdminDocumentSourcesPage',
	});
	const {loading, data} = useGetDocumentSourcesQuery();
	const [createDocumentSourceMutation] = useCreateDocumentSourceMutation();
	const [updateDocumentSourceMutation] = useUpdateDocumentSourceMutation();
	const [deleteDocumentSourceMutation] = useDeleteDocumentSourceMutation();

	const documentSources = React.useMemo(
		() => data?.documentSources ?? [],
		[data],
	);

	const refetchQueries = [GetDocumentSourcesDocument];

	const createDocumentSource = React.useCallback(
		(documentSource: DocumentSource) => {
			createDocumentSourceMutation({
				variables: {
					input: {
						name: documentSource.name,
					},
				},
				refetchQueries,
			});
		},
		[],
	);
	const updateDocumentSource = React.useCallback(
		(documentSource: DocumentSource) => {
			updateDocumentSourceMutation({
				variables: {
					input: {
						id: documentSource.id,
						name: documentSource.name,
					},
				},
				refetchQueries,
			});
		},
		[],
	);
	const deleteDocumentSource = React.useCallback((id: string) => {
		deleteDocumentSourceMutation({
			variables: {
				input: {id},
			},
			refetchQueries,
		});
	}, []);

	return (
		<ViewAuthorizer roles={[UserRole.SystemAdministrator]}>
			<LoadWrapper loading={loading}>
				<DocumentSourcesTooltipTranslationProvider>
					<EntityPage
						items={documentSources}
						entityDisplayName={t('DocumentSource')}
						createEntity={createDocumentSource}
						updateEntity={updateDocumentSource}
						deleteEntity={deleteDocumentSource}
					/>
				</DocumentSourcesTooltipTranslationProvider>
			</LoadWrapper>
		</ViewAuthorizer>
	);
};

export default AdminDocumentSourcesPage;
