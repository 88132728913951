import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
// A import LanguageDetector from 'i18next-browser-languagedetector';
import {isDate} from 'date-fns';
import {formatDateTimeI18n} from './localeDateFormat';

function loadResources(requireContext: __WebpackModuleApi.RequireContext) {
	requireContext.keys().forEach(relativePath => {
		const rgx = /\/(.+\/.+)\.(.+)\.json$/;
		const match = relativePath.match(rgx);
		if (match) {
			const [, ns, lng] = match;
			const content = requireContext(relativePath);
			i18n.addResourceBundle(lng, ns, content);
		}
	});
}

export const initI18n = (requireContext: __WebpackModuleApi.RequireContext) =>
	i18n
		// Detect user language
		// A .use(LanguageDetector)
		// Pass the i18n instance to react-i18next.
		.use(initReactI18next)
		// Init i18next
		.init(
			{
				lng: navigator.language,
				fallbackLng: 'de',
				debug:
					process.env.NODE_ENV !== 'production' &&
					process.env.NODE_ENV !== 'test',
				resources: {de: {}, en: {}},
				interpolation: {
					// Not needed for react as it escapes by default
					escapeValue: false,
					format(value, format, lng) {
						if (isDate(value) && lng && format) {
							return formatDateTimeI18n(value, lng, format);
						}

						return value;
					},
				},
			},
			() => {
				loadResources(requireContext);
			},
		);

export default i18n;
