import * as Types from '../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MyTableConfigurationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MyTableConfigurationsQuery = { __typename?: 'Query', myTableConfigurations: Array<{ __typename?: 'TableConfiguration', tableId: string, columns: Array<{ __typename?: 'Column', key: string, width: number, hidden: boolean }> }> };


export const MyTableConfigurationsDocument = gql`
    query MyTableConfigurations {
  myTableConfigurations {
    tableId
    columns {
      key
      width
      hidden
    }
  }
}
    `;

/**
 * __useMyTableConfigurationsQuery__
 *
 * To run a query within a React component, call `useMyTableConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyTableConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyTableConfigurationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyTableConfigurationsQuery(baseOptions?: Apollo.QueryHookOptions<MyTableConfigurationsQuery, MyTableConfigurationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyTableConfigurationsQuery, MyTableConfigurationsQueryVariables>(MyTableConfigurationsDocument, options);
      }
export function useMyTableConfigurationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyTableConfigurationsQuery, MyTableConfigurationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyTableConfigurationsQuery, MyTableConfigurationsQueryVariables>(MyTableConfigurationsDocument, options);
        }
export type MyTableConfigurationsQueryHookResult = ReturnType<typeof useMyTableConfigurationsQuery>;
export type MyTableConfigurationsLazyQueryHookResult = ReturnType<typeof useMyTableConfigurationsLazyQuery>;
export type MyTableConfigurationsQueryResult = Apollo.QueryResult<MyTableConfigurationsQuery, MyTableConfigurationsQueryVariables>;