import React, {useState} from 'react';
import {
	DefaultButton,
	PrimaryButton,
	Theme,
	mergeStyleSets,
	mergeStyles,
	useTheme,
} from '@fluentui/react';
import {DocumentTextLine} from '../slate';
import {LexicalEditorElement} from './LexicalEditorElement';
import {useTranslation} from 'react-i18next';
import {Editor, Transforms} from 'slate';
import {useSlate} from 'slate-react';
import _ from 'lodash';
import {v4 as uuidv4} from 'uuid';
import {getHTMLTableClassName} from 'components';

export type HtmlTableEditorContextType = {
	tableNodePath: Array<number>;
	leaf: DocumentTextLine;
	onDismiss: () => void;
};

export const TableEditorContext =
	React.createContext<HtmlTableEditorContextType>(
		{} as HtmlTableEditorContextType,
	);

export interface HtmlTableEditorProps {
	editable: boolean;
	isOpen: boolean;
	tableNodePath: Array<number>;
	htmlTableText: string;
	leaf?: DocumentTextLine;
	onDismiss: () => void;
}

export const HtmlTableEditor: React.FC<HtmlTableEditorProps> = ({
	editable = true,
	isOpen = false,
	htmlTableText,
	tableNodePath,
	leaf,
	onDismiss,
}) => {
	const theme = useTheme();
	const classNames = getHTMLTableEditorClassNames(theme);
	const editor = useSlate();

	const [htmlString, setHtmlString] = useState(htmlTableText);

	const {t} = useTranslation('features/regulatorydocuments', {
		keyPrefix: 'DocumentEditor.HtmlTableEditor',
	});

	const onClose = React.useCallback(() => {
		onDismiss();
	}, [onDismiss]);

	const onSave = React.useCallback(() => {
		const oldNode = Editor.node(editor, tableNodePath)[0] as any;

		const newNode = _.cloneDeep(oldNode) as DocumentTextLine;
		if (!newNode) return;

		newNode._guid = uuidv4();

		newNode.text = htmlString;

		Transforms.insertNodes(editor, newNode, {
			at: tableNodePath,
		});

		// Remove the old note
		Transforms.removeNodes(editor, {
			at: [tableNodePath[0], tableNodePath[1] + 1],
		});

		onDismiss();
	}, [onDismiss, editor, tableNodePath, htmlString]);

	if (!isOpen || !leaf) {
		return <></>;
	}

	const onTableClick = (element: HTMLElement) => {
		console.log('onTableClick', element);
	};

	const onTableUpdate = (e: Element) => {
		if (e) setHtmlString(e.outerHTML);
	};

	return (
		<div className={classNames.splitterBackground}>
			<div className={classNames.splittingCanvas}>
				<div className={classNames.canvasWrapper}>
					<LexicalEditorElement
						editable={editable}
						clickEvent={onTableClick}
						updateEvent={onTableUpdate}
						themeClasss={{
							table: [
								getTableClassNames(theme),
								getHTMLTableClassName(theme),
							].join(' '),
							// Theme styling goes here
							// Example: tableCell: 'lexical_tableCell',
							// ...
						}}
						htmlTableText={htmlTableText}
						addToolbar={true}
					></LexicalEditorElement>
				</div>
				<div className={classNames.buttons}>
					<DefaultButton className={classNames.button} onClick={onClose}>
						{t('Cancel')}
					</DefaultButton>
					<PrimaryButton className={classNames.button} onClick={onSave}>
						{t('Ok')}
					</PrimaryButton>
				</div>
			</div>
		</div>
	);
};

const getTableClassNames = (theme: Theme) => {
	return mergeStyles({
		backgroundColor: `${theme.palette.white}`,
		borderCollapse: 'collapse',
		'&  tr.HTMLTable_row_selection': {
			borderBottom: `2px solid ${theme.palette.red}`,
		},
		'    tr:hover': {
			backgroundColor: 'rgb(236,236,236)',
		},
	});
};

export const getHTMLTableEditorClassNames = (theme: Theme) =>
	mergeStyleSets({
		splitterBackground: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: '100vh',
			backgroundColor: 'rgba(100, 100, 100, 0.7)',

			position: 'fixed',
			width: '100%',

			background: 'rgba(100, 100, 100, .7)',
			zIndex: 1000,
			top: 0,
			left: 0,
		},
		splittingCanvas: {
			position: 'fixed',
			height: 'calc( 100% - 40px)',
			margin: '20pt',
			padding: '10pt;',
			minWidth: 400,
			background: 'rgb(197 197 197)',
		},

		canvasWrapper: {
			display: 'flex',
			maxHeight: '100%',
			overflow: 'auto',
			height: 'calc( 100% - 40px)',
			backgroundColor: `${theme.palette.neutralLight}`,
			justifyContent: 'center',
			':hover #hlineSplitter': {
				display: 'block',
			},
			border: `1px dotted ${theme.palette.neutralLight}`,
		},
		buttons: {
			display: 'flex',
			justifyContent: 'space-between',
		},
		button: {
			margin: '4px',
		},
	});
