import React from 'react';
import {SplittingCanvas} from './SplittingCanvas';
import {mergeStyleSets} from '@fluentui/react';
import {SplittingImageComponent} from './SplittingImageComponent';
import {DocumentTextLine} from '../slate';
import {SplittingHtmlTable} from './SplittingHtmlTable';
import {SplittingHtmlTableComponent} from './SplittingHtmlTableComponent';

export enum Stage {
	DefineSplitLocations,
	AddMetadata,
}

export type TableImageSplitterContextType = {
	splitLocations: Array<number>;
	setSplitLocations: (splitLocations: Array<number>) => void;

	imgHeight: number;
	setImgHeight: (imgHeight: number) => void;

	stage: Stage;
	setStage: (stage: Stage) => void;

	data: string[];
	setData: React.Dispatch<React.SetStateAction<string[]>>;

	tableNodePath: Array<number>;
	leaf: DocumentTextLine;

	onDismiss: () => void;
};

export const TableImageSplitterContext =
	React.createContext<TableImageSplitterContextType>(
		{} as TableImageSplitterContextType,
	);

export interface TableImageSplitterProps {
	isOpen: boolean;
	tableNodePath: Array<number>;
	imgSrc: string;

	leaf?: DocumentTextLine;
	onDismiss: () => void;
}

export const TableImageSplitter: React.FC<TableImageSplitterProps> = ({
	isOpen = false,
	tableNodePath,
	imgSrc,
	leaf,
	onDismiss,
}) => {
	const [splitLocations, setSplitLocations] = React.useState<Array<number>>([]);
	const [stage, setStage] = React.useState<Stage>(Stage.DefineSplitLocations);
	const [imgHeight, setImgHeight] = React.useState<number>(0);
	const [data, setData] = React.useState<Array<string>>([]);

	// Note: for table parts count is equal to the split Location
	const splitLocationsLength = imgSrc.length
		? splitLocations.length + 1
		: splitLocations.length;

	React.useEffect(
		() => setData(Array(splitLocationsLength).fill('')),
		[splitLocations],
	);

	const classNames = getClassNames();

	if (!isOpen || !leaf) {
		return <></>;
	}

	return (
		<TableImageSplitterContext.Provider
			value={{
				splitLocations,
				setSplitLocations,
				stage,
				setStage,
				imgHeight,
				setImgHeight,
				data,
				setData,
				leaf,
				tableNodePath,
				onDismiss,
			}}
		>
			{imgSrc.length ? (
				<div className={classNames.splitterBackground}>
					{stage === Stage.DefineSplitLocations && (
						<SplittingCanvas imgSrc={imgSrc} />
					)}
					{stage === Stage.AddMetadata && (
						<SplittingImageComponent imgSrc={imgSrc} />
					)}
				</div>
			) : (
				<div className={classNames.splitterBackground}>
					{stage === Stage.DefineSplitLocations && (
						<SplittingHtmlTable htmlTableText={leaf.text} />
					)}
					{stage === Stage.AddMetadata && (
						<SplittingHtmlTableComponent text={leaf.text} />
					)}
				</div>
			)}
		</TableImageSplitterContext.Provider>
	);
};

const getClassNames = () =>
	mergeStyleSets({
		splitterBackground: {
			position: 'fixed',
			width: '100%',
			height: '100%',
			background: 'rgba(100, 100, 100, .7)',
			zIndex: 1000,
			top: 0,
			left: 0,
		},
	});
