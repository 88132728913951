import {useTranslation} from 'react-i18next';
import React from 'react';
import {
	GetAllCategoriesDocument,
	useCreateCategoryMutation,
	useDeleteCategoryMutation,
	useGetAllCategoriesQuery,
	useUpdateCategoryMutation,
} from './hooks/categories.generated';
import {Category, UserRole} from 'types';
import {LoadWrapper} from 'components/LoadWrapper';
import {EntityPage} from 'components';
import {ViewAuthorizer} from 'components/ViewAuthorizer';
import {CategoriesTooltipTranslationProvider} from 'features/localizedTooltips';

const AdminCategoriesPage: React.FC = () => {
	const {t} = useTranslation('features/adminsection', {
		keyPrefix: 'AdminCategoriesPage',
	});

	const {loading, data} = useGetAllCategoriesQuery();
	const [createCategoryMutation] = useCreateCategoryMutation();
	const [updateCategoryMutation] = useUpdateCategoryMutation();
	const [deleteCategoryMutation] = useDeleteCategoryMutation();

	const categories = React.useMemo(() => data?.categories ?? [], [data]);

	const refetchQueries = [GetAllCategoriesDocument];

	const createCategory = React.useCallback((category: Category) => {
		createCategoryMutation({
			variables: {
				input: {
					name: category.name,
				},
			},
			refetchQueries,
		});
	}, []);

	const updateCategory = React.useCallback((category: Category) => {
		updateCategoryMutation({
			variables: {
				input: {id: category.id, name: category.name},
			},
			refetchQueries,
		});
	}, []);

	const deleteCategory = React.useCallback((id: string) => {
		deleteCategoryMutation({
			variables: {
				input: {id},
			},
			refetchQueries,
		});
	}, []);

	return (
		<ViewAuthorizer roles={[UserRole.SystemAdministrator]}>
			<LoadWrapper loading={loading}>
				<CategoriesTooltipTranslationProvider>
					<EntityPage
						items={categories}
						entityDisplayName={t('Categories')}
						createEntity={createCategory}
						updateEntity={updateCategory}
						deleteEntity={deleteCategory}
					/>
				</CategoriesTooltipTranslationProvider>
			</LoadWrapper>
		</ViewAuthorizer>
	);
};

export default AdminCategoriesPage;
