import React from 'react';
import {PrimaryButton, Stack} from '@fluentui/react';
import {useUserContext} from 'authentication/UserContext';
import {Control} from 'react-hook-form';
import {GetRequirementsFormDataQuery} from '../hooks/requirements.generated';
import {FormMode} from 'components/EntityPage/EntityPage.types';
import {ListOrDetailsRequirement} from '../requirements.types';
import {
	CommonFormElementsOfRequirements,
	DataForSelectableFields,
	createDataForSelectableFields,
} from './CommonFormElementsOfRequirements';
import {useRequirementsTranslation} from '../hooks/useRequirementsTranslation';

type RequirementFormElementProps<Requirement extends ListOrDetailsRequirement> =
	{
		control: Control<Requirement>;
		formData: NonNullable<GetRequirementsFormDataQuery> | undefined;
		mode: FormMode;
		id: string;
		onAggregateClick?: () => void;
	};

export const RequirementFormElements = <
	Requirement extends ListOrDetailsRequirement,
>({
	control,
	formData,
	mode,
	onAggregateClick,
	id,
}: RequirementFormElementProps<Requirement>): JSX.Element => {
	const {t} = useRequirementsTranslation();
	const {myVexClusters, isAdmin} = useUserContext();

	const vexClusters = React.useMemo(() => {
		const {vexClusterData}: DataForSelectableFields =
			createDataForSelectableFields(formData);
		return isAdmin ? vexClusterData : myVexClusters;
	}, [isAdmin, myVexClusters, formData]);

	const renderPossibleAggregationBtn = (): JSX.Element | null => {
		if (mode !== FormMode.Update) return null;
		return (
			<PrimaryButton
				styles={{root: {marginTop: 10}}}
				onClick={onAggregateClick}
			>
				{t('ManualAggregation')}
			</PrimaryButton>
		);
	};

	const disableRule = React.useCallback(
		(fieldName: string): boolean =>
			fieldName === 'vexClusters' &&
			control._formValues.status === 'FINAL' &&
			control._formValues.vexClusters?.length > 0,
		[control._formValues.status, control._formValues.vexClusters?.length],
	);

	return (
		<Stack>
			<CommonFormElementsOfRequirements
				control={control}
				formData={formData}
				selectableVexClusters={vexClusters}
				aggregationBtn={renderPossibleAggregationBtn()}
				id={id}
				disableRule={disableRule}
			/>
		</Stack>
	);
};
