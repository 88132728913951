import {useMemo} from 'react';
import {IBreadcrumbItem} from '@fluentui/react';
import {
	ProjectDetailsPageParams,
	encodeGenAndModelSeries,
} from '../vehicleProjectPagePaths';
import {useNavigate} from 'react-router-dom';
import {useVehicleProjectsTranslation} from '../hooks/vehicleProjectsTranslationHooks';

export interface GenerationBreadcrumbFields {
	params: ProjectDetailsPageParams;
	extraItems?: IBreadcrumbItem[];
}

export const useGenerationBreadcrumbs = ({
	params,
	extraItems = [],
}: GenerationBreadcrumbFields): IBreadcrumbItem[] => {
	const {t} = useVehicleProjectsTranslation();
	const navigate = useNavigate();

	const getItems = (): IBreadcrumbItem[] => {
		const {modelSeries: encodedModelSeries, generation: encodedGeneration} =
			encodeGenAndModelSeries(params);
		return [
			{
				text: t('VehicleProjects'),
				key: 'vehicleProjects',
				onClick: () => navigate('/vehicleProjects'),
			},
			{
				text: `${params.modelSeries}`,
				key: 'modelSeries',
				onClick: () =>
					navigate(
						`/vehicleProjects/${encodedModelSeries}/${encodedGeneration}`,
					),
			},
			{
				text: `${params.generation}`,
				key: 'generation',
				onClick: () =>
					navigate(
						`/vehicleProjects/${encodedModelSeries}/${encodedGeneration}`,
					),
			},
			...extraItems,
		];
	};

	return useMemo(getItems, [params, extraItems, navigate, t]);
};
