import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllPcmsClustersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllPcmsClustersQuery = { __typename?: 'Query', standardPlusPcmsClusters?: Array<{ __typename?: 'StandardPlusPcmsCluster', id: string, name: string, createdAt?: any | null, modifiedAt?: any | null, createdBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null }, modifiedBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null } }> | null };

export type CreatePcmsClusterMutationVariables = Types.Exact<{
  input: Types.CreateStandardPlusPcmsClusterInput;
}>;


export type CreatePcmsClusterMutation = { __typename?: 'Mutation', createStandardPlusPcmsCluster: { __typename?: 'CreateStandardPlusPcmsClusterPayload', standardPlusPcmsCluster?: { __typename?: 'StandardPlusPcmsCluster', id: string } | null } };

export type UpdatePcmsClusterMutationVariables = Types.Exact<{
  input: Types.UpdateStandardPlusPcmsClusterInput;
}>;


export type UpdatePcmsClusterMutation = { __typename?: 'Mutation', updateStandardPlusPcmsCluster: { __typename?: 'UpdateStandardPlusPcmsClusterPayload', standardPlusPcmsCluster?: { __typename?: 'StandardPlusPcmsCluster', id: string } | null } };

export type DeletePcmsClusterMutationVariables = Types.Exact<{
  input: Types.DeleteStandardPlusPcmsClusterInput;
}>;


export type DeletePcmsClusterMutation = { __typename?: 'Mutation', deleteStandardPlusPcmsCluster: { __typename?: 'DeleteStandardPlusPcmsClusterPayload', standardPlusPcmsCluster?: { __typename?: 'StandardPlusPcmsCluster', id: string } | null } };


export const GetAllPcmsClustersDocument = gql`
    query GetAllPcmsClusters {
  standardPlusPcmsClusters {
    id
    name
    createdAt
    createdBy {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
    modifiedAt
    modifiedBy {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
  }
}
    `;

/**
 * __useGetAllPcmsClustersQuery__
 *
 * To run a query within a React component, call `useGetAllPcmsClustersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPcmsClustersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPcmsClustersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllPcmsClustersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllPcmsClustersQuery, GetAllPcmsClustersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllPcmsClustersQuery, GetAllPcmsClustersQueryVariables>(GetAllPcmsClustersDocument, options);
      }
export function useGetAllPcmsClustersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllPcmsClustersQuery, GetAllPcmsClustersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllPcmsClustersQuery, GetAllPcmsClustersQueryVariables>(GetAllPcmsClustersDocument, options);
        }
export type GetAllPcmsClustersQueryHookResult = ReturnType<typeof useGetAllPcmsClustersQuery>;
export type GetAllPcmsClustersLazyQueryHookResult = ReturnType<typeof useGetAllPcmsClustersLazyQuery>;
export type GetAllPcmsClustersQueryResult = Apollo.QueryResult<GetAllPcmsClustersQuery, GetAllPcmsClustersQueryVariables>;
export const CreatePcmsClusterDocument = gql`
    mutation CreatePcmsCluster($input: CreateStandardPlusPcmsClusterInput!) {
  createStandardPlusPcmsCluster(input: $input) {
    standardPlusPcmsCluster {
      id
    }
  }
}
    `;
export type CreatePcmsClusterMutationFn = Apollo.MutationFunction<CreatePcmsClusterMutation, CreatePcmsClusterMutationVariables>;

/**
 * __useCreatePcmsClusterMutation__
 *
 * To run a mutation, you first call `useCreatePcmsClusterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePcmsClusterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPcmsClusterMutation, { data, loading, error }] = useCreatePcmsClusterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePcmsClusterMutation(baseOptions?: Apollo.MutationHookOptions<CreatePcmsClusterMutation, CreatePcmsClusterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePcmsClusterMutation, CreatePcmsClusterMutationVariables>(CreatePcmsClusterDocument, options);
      }
export type CreatePcmsClusterMutationHookResult = ReturnType<typeof useCreatePcmsClusterMutation>;
export type CreatePcmsClusterMutationResult = Apollo.MutationResult<CreatePcmsClusterMutation>;
export type CreatePcmsClusterMutationOptions = Apollo.BaseMutationOptions<CreatePcmsClusterMutation, CreatePcmsClusterMutationVariables>;
export const UpdatePcmsClusterDocument = gql`
    mutation UpdatePcmsCluster($input: UpdateStandardPlusPcmsClusterInput!) {
  updateStandardPlusPcmsCluster(input: $input) {
    standardPlusPcmsCluster {
      id
    }
  }
}
    `;
export type UpdatePcmsClusterMutationFn = Apollo.MutationFunction<UpdatePcmsClusterMutation, UpdatePcmsClusterMutationVariables>;

/**
 * __useUpdatePcmsClusterMutation__
 *
 * To run a mutation, you first call `useUpdatePcmsClusterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePcmsClusterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePcmsClusterMutation, { data, loading, error }] = useUpdatePcmsClusterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePcmsClusterMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePcmsClusterMutation, UpdatePcmsClusterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePcmsClusterMutation, UpdatePcmsClusterMutationVariables>(UpdatePcmsClusterDocument, options);
      }
export type UpdatePcmsClusterMutationHookResult = ReturnType<typeof useUpdatePcmsClusterMutation>;
export type UpdatePcmsClusterMutationResult = Apollo.MutationResult<UpdatePcmsClusterMutation>;
export type UpdatePcmsClusterMutationOptions = Apollo.BaseMutationOptions<UpdatePcmsClusterMutation, UpdatePcmsClusterMutationVariables>;
export const DeletePcmsClusterDocument = gql`
    mutation DeletePcmsCluster($input: DeleteStandardPlusPcmsClusterInput!) {
  deleteStandardPlusPcmsCluster(input: $input) {
    standardPlusPcmsCluster {
      id
    }
  }
}
    `;
export type DeletePcmsClusterMutationFn = Apollo.MutationFunction<DeletePcmsClusterMutation, DeletePcmsClusterMutationVariables>;

/**
 * __useDeletePcmsClusterMutation__
 *
 * To run a mutation, you first call `useDeletePcmsClusterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePcmsClusterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePcmsClusterMutation, { data, loading, error }] = useDeletePcmsClusterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePcmsClusterMutation(baseOptions?: Apollo.MutationHookOptions<DeletePcmsClusterMutation, DeletePcmsClusterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePcmsClusterMutation, DeletePcmsClusterMutationVariables>(DeletePcmsClusterDocument, options);
      }
export type DeletePcmsClusterMutationHookResult = ReturnType<typeof useDeletePcmsClusterMutation>;
export type DeletePcmsClusterMutationResult = Apollo.MutationResult<DeletePcmsClusterMutation>;
export type DeletePcmsClusterMutationOptions = Apollo.BaseMutationOptions<DeletePcmsClusterMutation, DeletePcmsClusterMutationVariables>;