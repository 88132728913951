/**
 * Put providers here when the corresponding entity does not have its own folder
 * in "features" yet and when we might need to reuse it. This is better than
 * defining the provider only where it gets used because it allows us to easily
 * find and use the providers if we need them in the future.
 */

import {createTooltipTranslationProviderFromNamespace} from './contexts/TooltipTranslationContext';

export const createLocalizedTooltipsNamespace = (
	pluralEntityName: string,
): string => {
	return `features/localizedTooltips/${pluralEntityName}`;
};

const createProviderFromEntityName = (pluralEntityName: string) => {
	const namespace: string = createLocalizedTooltipsNamespace(pluralEntityName);
	return createTooltipTranslationProviderFromNamespace(namespace);
};

/*
 * You can add namespaces here if other files need to import them.
 */
export const commonEntityNamespacesWithTooltipText = {
	workflows: createLocalizedTooltipsNamespace('workflows'),
};

export const BodyworkVariantsTooltipTranslationProvider =
	createProviderFromEntityName('bodyworkVariants');

export const CategoriesTooltipTranslationProvider =
	createProviderFromEntityName('categories');

export const DriveVariantsTooltipTranslationProvider =
	createProviderFromEntityName('driveVariants');

export const EngineVariantsTooltipTranslationProvider =
	createProviderFromEntityName('engineVariants');

export const GearboxVariantsTooltipTranslationProvider =
	createProviderFromEntityName('gearboxVariants');

export const KeywordsTooltipTranslationProvider =
	createProviderFromEntityName('keywords');

export const MarketsTooltipTranslationProvider =
	createProviderFromEntityName('markets');

export const PcmsClustersTooltipTranslationProvider =
	createProviderFromEntityName('pcmsClusters');

export const RegulationClustersTooltipTranslationProvider =
	createProviderFromEntityName('regulationClusters');

export const SystemLevelsTooltipTranslationProvider =
	createProviderFromEntityName('systemLevels');

export const TagsTooltipTranslationProvider =
	createProviderFromEntityName('tags');

export const VehicleCategoriesTooltipTranslationProvider =
	createProviderFromEntityName('vehicleCategories');

export const VexClustersTooltipTranslationProvider =
	createProviderFromEntityName('vexClusters');

export const RequirementCategoryTooltipTranslationProvider =
	createProviderFromEntityName('requirementCategories');

export const WorkflowsTooltipTranslationProvider =
	createTooltipTranslationProviderFromNamespace(
		commonEntityNamespacesWithTooltipText.workflows,
	);

export const ActiveTypeMarketsTooltipTranslationProvider =
	createProviderFromEntityName('activeTypeMarkets');

export const AttachmentCategoriesTooltipTranslationProvider =
	createProviderFromEntityName('attachmentCategories');

export const AttachmentsTooltipTranslationProvider =
	createProviderFromEntityName('attachments');

export const ConvolutesTooltipTranslationProvider =
	createProviderFromEntityName('convolutes');

export const DocumentReferencesTooltipTranslationProvider =
	createProviderFromEntityName('documentReferences');

export const GdrpRulesTooltipTranslationProvider =
	createProviderFromEntityName('gdrpRules');

export const ReferencesTooltipTranslationProvider =
	createProviderFromEntityName('references');

export const DocumentSourcesTooltipTranslationProvider =
	createProviderFromEntityName('documentSources');

export const RegDocParagraphTooltipTranslationProvider =
	createTooltipTranslationProviderFromNamespace(
		'features/RegDocs/regDocParagraphs',
	);

export const CountriesTooltipTranslationProvider =
	createProviderFromEntityName('countries');
