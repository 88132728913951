import React, {useCallback} from 'react';
import {CellLink} from './CellLink';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import {ILinkStyles, Theme, useTheme} from '@fluentui/react';

interface Props {
	href: string;
	children: React.ReactNode;
}

const getStyles = (theme: Theme) =>
	({
		root: {
			'&, *': {cursor: 'pointer'},
			color: theme.palette.neutralPrimary,
		},
	} as ILinkStyles);

export function CellWithEntityLink({href, children}: Props): JSX.Element {
	const navigate: NavigateFunction = useNavigate();
	const theme = useTheme();
	const styles = React.useMemo(() => getStyles(theme), [theme]);

	const openDetailsPage = useCallback((): void => {
		navigate(href);
	}, [href, navigate]);

	return (
		<CellLink onClick={openDetailsPage} styles={styles}>
			{children}
		</CellLink>
	);
}
