import {useEditorSelectionContext} from 'features/RegulatoryDocuments/context';
import {usePdfPageContext} from 'features/RegulatoryDocuments/context/usePdfPageContext';
import React from 'react';
import ViewSDKClient from './ViewSDKClient';

declare global {
	interface Window {
		AdobeDC: any;
	}
}

const PAGE_CLICK = 'PREVIEW_PAGE_CLICK';
export const PDF_VIEWER_ID = 'adobe-dc-view';

export type PdfViewerProps = {
	url: string;
};

export const PdfViewer: React.FC<PdfViewerProps> = ({url}) => {
	const {selectedTextLine} = useEditorSelectionContext();
	const {setCurrentPage} = usePdfPageContext();

	React.useEffect(() => {
		const script = document.createElement('script');
		script.src = 'https://documentservices.adobe.com/view-sdk/viewer.js';
		script.async = true;
		document.body.appendChild(script);
		return () => {
			document.body.removeChild(script);
		};
	}, []);

	const [viewSDKClient, setViewSDKClient] =
		React.useState<ViewSDKClient | null>(null);

	React.useEffect(() => {
		const viewSDK = new ViewSDKClient();
		viewSDK.ready().then(async () => {
			/* Invoke file preview */
			await viewSDK.previewFile('adobe-dc-view', url);

			viewSDK.initAnnotationsManager();
			viewSDK.registerEventsHandler(event => {
				if (event.type === PAGE_CLICK && setCurrentPage) {
					setCurrentPage(event.data.pageNumber);
				}
			});
			setViewSDKClient(viewSDK);
		});
	}, []);

	React.useEffect(() => {
		if (viewSDKClient && selectedTextLine) {
			viewSDKClient.goToPage(selectedTextLine.page);
		}
	}, [viewSDKClient, selectedTextLine?.page]);

	React.useEffect(() => {
		const annotationId = 'a53f7e60-9694-470c-a621-8e0b492eb5b7';
		if (viewSDKClient && selectedTextLine) {
			viewSDKClient.markDocument(
				selectedTextLine.bounds,
				annotationId,
				selectedTextLine.page,
			);
		}
	}, [viewSDKClient, selectedTextLine?.bounds]);

	return <div id={PDF_VIEWER_ID}></div>;
};
