import {Theme, Image, mergeStyles, IImageStyles} from '@fluentui/react';
import {v4 as uuidv4} from 'uuid';
import React from 'react';
import {
	ComparableAsset,
	CompareResult,
	IComparable,
	IComparedParagraph,
} from '../../compareDocuments/compareDocuments';
import {useMetadata} from '../_useMetadata';
import {getClassNames, getComparisonColor} from '../CompareVersionPanel.utils';

const getBorderMarkClassName = (result: CompareResult, theme: Theme) => {
	const color = getComparisonColor(result, theme);

	return mergeStyles({
		borderRight: `5px solid ${color}`,
		height: '100%',
	});
};

const getParagraphBackgroundColor = (result: CompareResult, theme: Theme) => {
	let background;

	switch (result) {
		case CompareResult.Deleted:
			background = `${theme.palette.redDark}33`;
			break;
		case CompareResult.New:
			background = `${theme.palette.greenLight}33`;
			break;
		default:
			background = undefined;
	}

	return mergeStyles({
		background,
	});
};

const imageStyles: IImageStyles = {
	root: {zIndex: 900},
	image: {maxWidth: '100%'},
};

export const ParagraphItem: React.FC<{
	comparedParagraph: IComparedParagraph | undefined;
	resultType: CompareResult;
	showComparisonMark: boolean;
	showMetadata: boolean;
	theme: Theme;
}> = ({
	comparedParagraph,
	resultType,
	showComparisonMark,
	showMetadata,
	theme,
}) => {
	const classNames = getClassNames(theme);

	const {renderMetadata} = useMetadata();

	const renderAsset = (
		asset: ComparableAsset,
		key: string | number,
	): JSX.Element => {
		return (
			<div key={key}>
				<Image src={asset.uri} alt={asset.assetId} styles={imageStyles} />
			</div>
		);
	};

	const renderAssets = (comparableItem: IComparable): JSX.Element[] => {
		return comparableItem.assets.map(renderAsset);
	};

	const renderParagraph = (
		cmpParagraph: IComparedParagraph,
		resultType: CompareResult,
	): JSX.Element[] => {
		const assets = cmpParagraph.paragraph.assets.slice();

		let textIndex = 0;

		if (!cmpParagraph.textElements.length) {
			return renderAssets(cmpParagraph.paragraph);
		}

		return cmpParagraph.textElements.map((textElem, textElemIndex) => {
			const newTextIndex = textIndex + textElem.text.length;

			const currentAssets = assets.filter(
				a =>
					/**
					 * If the asset's index indicates that it must be placed in this paragraph.
					 */
					(textIndex < a.index && a.index <= newTextIndex) ||
					/**
					 * If the asset's index indicates that it must be placed after the
					 * first paragraph. This allows assets to be rendered when there is
					 * only one text element and that element is empty.
					 */
					(a.index === 0 && textElemIndex === 0),
			);

			const {text, isChange} = textElem;

			let content = <> {text}</>;

			if (currentAssets.length > 0) {
				const components: JSX.Element[] = [];
				let lastIndex = 0;

				for (const asset of currentAssets) {
					const splitLocation = asset.index;
					/**
					 * This allows assets to be inserted in between a paragraph's text
					 */
					const textPart = text.slice(lastIndex, splitLocation);
					components.push(
						<span
							key={`${resultType}-text-${splitLocation}`}
							className={isChange ? classNames.changeMark : ''}
						>
							{textPart}
						</span>,
					);

					const assetKey = `${resultType}-image-${asset.assetId}`;
					const renderedAsset: JSX.Element = renderAsset(asset, assetKey);
					components.push(renderedAsset);

					lastIndex = splitLocation;
				}

				components.push(<span key='text-last'>{text.slice(lastIndex)}</span>);

				content = <>{components}</>;
			}

			textIndex = newTextIndex;

			return (
				<span className={isChange ? classNames.changeMark : ''} key={uuidv4()}>
					{content}
				</span>
			);
		});
	};

	return (
		<div
			className={
				showComparisonMark
					? getBorderMarkClassName(resultType, theme)
					: undefined
			}
		>
			<div className={classNames.paragraphItem}>
				{comparedParagraph && (
					<div>
						<div className={classNames.enumeration}>
							{comparedParagraph.paragraph.enumeration || ''}
						</div>
						<div
							className={`${classNames.elemBox} ${getParagraphBackgroundColor(
								resultType,
								theme,
							)}`}
						>
							{renderParagraph(comparedParagraph, resultType)}
						</div>

						{showMetadata && renderMetadata(comparedParagraph.paragraph as any)}
					</div>
				)}
			</div>
		</div>
	);
};
