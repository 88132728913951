import {FontIcon} from '@fluentui/react';
import {EntityListColumn} from 'components';
import {getRegulatoryDocumentsListBaseColumns} from 'features/RegulatoryDocuments/components/DocumentDetails/RegulatoryDocumentsListBaseColumns';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {RegulatoryDocument} from 'types';
import {FullTextSearchDocumentsQuery} from '../hooks';
import {SearchResultList} from './SearchResultList';
import {RegDocsTooltipTranslationProvider} from 'features/RegulatoryDocuments/components/RegDocTooltipTranslationProvider';

export const RegulatoryDocumentResultsList: React.FC<{
	regulatoryDocuments: FullTextSearchDocumentsQuery['searchRegulatoryDocumentsAndParagraphs']['regulatoryDocuments'];
}> = ({regulatoryDocuments}) => {
	const {t} = useTranslation('features/regulatorydocuments', {
		keyPrefix: 'RegulatoryDocumentsList',
	});
	const {t: tEnum} = useTranslation('common/enums');

	const columns = React.useMemo(
		() =>
			[
				{
					key: 'c-document',
					iconName: 'Page',
					isIconOnly: true,
					fieldName: 'document',
					minWidth: 16,
					maxWidth: 16,
					onRender: (item: RegulatoryDocument) =>
						item.document ? (
							<FontIcon aria-label='Page' iconName='PDF' />
						) : (
							<></>
						),
				},
				...getRegulatoryDocumentsListBaseColumns(t, tEnum),
			] as EntityListColumn[],
		[t, tEnum],
	);

	return (
		<RegDocsTooltipTranslationProvider>
			<SearchResultList
				items={regulatoryDocuments}
				columns={columns}
				getItemUrl={regDoc =>
					regDoc?.regulation
						? `/regulations/${regDoc?.regulation.id}/${regDoc?.id}/paragraphs`
						: `/regulatoryDocuments/${regDoc?.id}`
				}
			/>
		</RegDocsTooltipTranslationProvider>
	);
};
