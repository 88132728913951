import {Panel, PrimaryButton, Stack} from '@fluentui/react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Column} from 'types';
import {EntityListColumn, EntityListContext} from './EntityList';
import {CheckboxWithTooltip} from 'features/localizedTooltips';

type HideColumnsPanelProps = {
	isOpen: boolean;
	columns: EntityListColumn[];
	onSubmit: (columnConfigs: Column[]) => void | Promise<void>;
	onDismiss: () => void | Promise<void>;
};

export const HideColumnsPanel: React.FC<HideColumnsPanelProps> = ({
	isOpen,
	columns,
	onSubmit,
	onDismiss,
}) => {
	const {t} = useTranslation('components/entitylist', {
		keyPrefix: 'HideColumnsPanel',
	});

	const {currentColumnConfig, setCurrentColumnConfig} =
		React.useContext(EntityListContext);

	const onSaveClick = React.useCallback(
		() => onSubmit(currentColumnConfig),
		[currentColumnConfig],
	);

	const onCheckboxChange = React.useCallback(
		(col: EntityListColumn, checked: boolean) => {
			setCurrentColumnConfig(cfg => {
				const newConfig = cfg.slice();
				const i = newConfig.findIndex(c => c.key === col?.key);
				const changedCol = newConfig[i];
				if (i !== -1) {
					newConfig[i] = {
						...changedCol,
						hidden: !checked,
					};
				}

				return newConfig;
			});
		},
		[],
	);

	const onRenderFooterContent: any = () => (
		<form>
			<PrimaryButton onClick={onSaveClick}>
				{t('SaveHiddenColumns')}
			</PrimaryButton>
		</form>
	);

	return (
		<Panel
			isOpen={isOpen}
			onDismiss={onDismiss}
			onRenderFooterContent={onRenderFooterContent}
			headerText={t('ConfigureVisibleColumns')}
			isFooterAtBottom={true}
		>
			<Stack
				tokens={{childrenGap: 4}}
				styles={{
					root: {
						marginTop: 16,
					},
				}}
			>
				{columns.map(c => (
					<ColumnCheckbox key={c.key} column={c} onChange={onCheckboxChange} />
				))}
			</Stack>
		</Panel>
	);
};

const ColumnCheckbox: React.FC<{
	column: EntityListColumn;
	onChange: (col: EntityListColumn, checked: boolean) => void;
}> = ({column, onChange}) => {
	const onCheckboxChange = React.useCallback(
		(_ev: any, checked?: boolean) => onChange(column, Boolean(checked)),
		[],
	);

	const {currentColumnConfig} = React.useContext(EntityListContext);

	const hidden = React.useMemo(
		() => currentColumnConfig.find(c => c.key === column.key)?.hidden,
		[currentColumnConfig],
	);

	return (
		/**
		 * Note that we only want it to get the text from the context when it has a
		 * field name. Otherwise, it will throw an error.
		 */
		<CheckboxWithTooltip
			defaultChecked={!hidden}
			label={column.name}
			onChange={onCheckboxChange}
			tooltipHostProps={{
				translationKey: column.fieldName,
				shouldGetTextFromContext: Boolean(column.fieldName),
			}}
		/>
	);
};
