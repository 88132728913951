import {CommandsContext} from 'context';
import * as React from 'react';
import {Command} from 'hooks';

export enum ActionType {
	ADD_COMMAND = 'ADD_COMMAND',
	REMOVE_COMMAND = 'REMOVE_COMMAND',
}

type State = Command[];

const initialState: State = [];

export function reducer(
	state: State,
	{type, command}: {type: ActionType; command: Command},
): State {
	switch (type) {
		case ActionType.ADD_COMMAND:
			return [...state, command];
		case ActionType.REMOVE_COMMAND:
			return state.filter(x => x.key !== command.key);
		default:
			return initialState;
	}
}

export const CommandsProvider: React.FC<{children: React.ReactNode}> = ({
	children,
}) => {
	const [commands, dispatch] = React.useReducer(reducer, initialState);
	const removeCommand = React.useMemo(
		() => (command: Command) => {
			dispatch({type: ActionType.REMOVE_COMMAND, command});
		},
		[dispatch],
	);
	const addCommand = React.useMemo(
		() => (command: Command) => {
			dispatch({type: ActionType.ADD_COMMAND, command});

			return () => removeCommand(command);
		},
		[dispatch, removeCommand],
	);

	return (
		<CommandsContext.Provider value={{commands, addCommand, removeCommand}}>
			{children}
		</CommandsContext.Provider>
	);
};
