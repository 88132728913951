import {ArrayValues} from 'components/EntityList/ColumnRenderers';
import React from 'react';
import {BasicArrayFieldProps} from './DetailsSection.types';
import {ArrayFieldWrapper} from './ArrayFieldWrapper';

export const ArrayField: React.FC<BasicArrayFieldProps> = ({
	data,
	limit,
	...other
}) => {
	return (
		<ArrayFieldWrapper {...other}>
			<ArrayValues array={data} limit={limit ?? 10} />
		</ArrayFieldWrapper>
	);
};
