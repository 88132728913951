import React from 'react';

export type PdfPageContextProps = {
	currentPage: number;
	setCurrentPage: (page: number) => void;
};

export const PdfPageContext = React.createContext<PdfPageContextProps>({
	currentPage: 0,
} as PdfPageContextProps);
