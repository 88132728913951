import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllReferencesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllReferencesQuery = { __typename?: 'Query', references?: Array<{ __typename?: 'Reference', id: string, name: string, createdAt?: any | null, modifiedAt?: any | null, referenceTypes: Array<Types.ReferenceType>, createdBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null }, modifiedBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null } }> | null };

export type CreateReferenceMutationVariables = Types.Exact<{
  input: Types.CreateReferenceInput;
}>;


export type CreateReferenceMutation = { __typename?: 'Mutation', createReference: { __typename?: 'CreateReferencePayload', reference?: { __typename?: 'Reference', id: string } | null } };

export type UpdateReferenceMutationVariables = Types.Exact<{
  input: Types.UpdateReferenceInput;
}>;


export type UpdateReferenceMutation = { __typename?: 'Mutation', updateReference: { __typename?: 'UpdateReferencePayload', reference?: { __typename?: 'Reference', id: string } | null } };

export type DeleteReferenceMutationVariables = Types.Exact<{
  input: Types.DeleteReferenceInput;
}>;


export type DeleteReferenceMutation = { __typename?: 'Mutation', deleteReference: { __typename?: 'DeleteReferencePayload', reference?: { __typename?: 'Reference', id: string } | null } };


export const GetAllReferencesDocument = gql`
    query GetAllReferences {
  references {
    id
    name
    createdAt
    createdBy {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
    modifiedAt
    modifiedBy {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
    referenceTypes
  }
}
    `;

/**
 * __useGetAllReferencesQuery__
 *
 * To run a query within a React component, call `useGetAllReferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllReferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllReferencesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllReferencesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllReferencesQuery, GetAllReferencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllReferencesQuery, GetAllReferencesQueryVariables>(GetAllReferencesDocument, options);
      }
export function useGetAllReferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllReferencesQuery, GetAllReferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllReferencesQuery, GetAllReferencesQueryVariables>(GetAllReferencesDocument, options);
        }
export type GetAllReferencesQueryHookResult = ReturnType<typeof useGetAllReferencesQuery>;
export type GetAllReferencesLazyQueryHookResult = ReturnType<typeof useGetAllReferencesLazyQuery>;
export type GetAllReferencesQueryResult = Apollo.QueryResult<GetAllReferencesQuery, GetAllReferencesQueryVariables>;
export const CreateReferenceDocument = gql`
    mutation CreateReference($input: CreateReferenceInput!) {
  createReference(input: $input) {
    reference {
      id
    }
  }
}
    `;
export type CreateReferenceMutationFn = Apollo.MutationFunction<CreateReferenceMutation, CreateReferenceMutationVariables>;

/**
 * __useCreateReferenceMutation__
 *
 * To run a mutation, you first call `useCreateReferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReferenceMutation, { data, loading, error }] = useCreateReferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReferenceMutation(baseOptions?: Apollo.MutationHookOptions<CreateReferenceMutation, CreateReferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReferenceMutation, CreateReferenceMutationVariables>(CreateReferenceDocument, options);
      }
export type CreateReferenceMutationHookResult = ReturnType<typeof useCreateReferenceMutation>;
export type CreateReferenceMutationResult = Apollo.MutationResult<CreateReferenceMutation>;
export type CreateReferenceMutationOptions = Apollo.BaseMutationOptions<CreateReferenceMutation, CreateReferenceMutationVariables>;
export const UpdateReferenceDocument = gql`
    mutation UpdateReference($input: UpdateReferenceInput!) {
  updateReference(input: $input) {
    reference {
      id
    }
  }
}
    `;
export type UpdateReferenceMutationFn = Apollo.MutationFunction<UpdateReferenceMutation, UpdateReferenceMutationVariables>;

/**
 * __useUpdateReferenceMutation__
 *
 * To run a mutation, you first call `useUpdateReferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReferenceMutation, { data, loading, error }] = useUpdateReferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReferenceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReferenceMutation, UpdateReferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReferenceMutation, UpdateReferenceMutationVariables>(UpdateReferenceDocument, options);
      }
export type UpdateReferenceMutationHookResult = ReturnType<typeof useUpdateReferenceMutation>;
export type UpdateReferenceMutationResult = Apollo.MutationResult<UpdateReferenceMutation>;
export type UpdateReferenceMutationOptions = Apollo.BaseMutationOptions<UpdateReferenceMutation, UpdateReferenceMutationVariables>;
export const DeleteReferenceDocument = gql`
    mutation DeleteReference($input: DeleteReferenceInput!) {
  deleteReference(input: $input) {
    reference {
      id
    }
  }
}
    `;
export type DeleteReferenceMutationFn = Apollo.MutationFunction<DeleteReferenceMutation, DeleteReferenceMutationVariables>;

/**
 * __useDeleteReferenceMutation__
 *
 * To run a mutation, you first call `useDeleteReferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReferenceMutation, { data, loading, error }] = useDeleteReferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteReferenceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReferenceMutation, DeleteReferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReferenceMutation, DeleteReferenceMutationVariables>(DeleteReferenceDocument, options);
      }
export type DeleteReferenceMutationHookResult = ReturnType<typeof useDeleteReferenceMutation>;
export type DeleteReferenceMutationResult = Apollo.MutationResult<DeleteReferenceMutation>;
export type DeleteReferenceMutationOptions = Apollo.BaseMutationOptions<DeleteReferenceMutation, DeleteReferenceMutationVariables>;