import {DialogState} from 'components/Dialogs';
import {useTranslation} from 'react-i18next';
import React from 'react';
import {RequirementFromDetailsPage} from '../requirementDetailsPage.types';
import {RequirementStatus, User} from 'types';

import {
	GetAllRequirementsDocument,
	useUpdateRequirementMutation,
} from '../../hooks/requirements.generated';
import {mapToRef} from 'helpers';
import {useNotificationBar} from 'hooks/useNotificationBar';
import {useNavigate} from 'react-router-dom';
import {useGetAllVexClustersQuery} from 'features/AdminSection/hooks/vexClusters.generated';
import {useGetAllKeywordsQuery} from 'features/AdminSection/hooks/keywords.generated';
import {RequirementStatusType} from '../RequirementDetailsPage';
import {
	SendApprovalDialog,
	SendApprovalDialogChangeTypeMode,
} from './SendApprovalDialog';
import {ApprovalDialog} from './ApprovalDialog';
import {DeclineDialog} from './DeclineDialog';
import {ListOrDetailsRequirement} from 'features/Requirements/requirements.types';
import {
	RequirementChangeType,
	UpdateRequirementInput,
} from 'types/types.generated';
import {
	RequirementUtilsService,
	CommonRequirementInputFields as Input,
} from 'features/Requirements/RequirementUtils.service';
import {useRequirementsVersions} from 'features/Requirements/hooks/useRequirementsVersions';

export interface RegulationFormProps {
	dialogType: RequirementStatusType;
	dialogState: DialogState;
	hideDialog: () => void;
	requirementDetails: RequirementFromDetailsPage;
}

export const WorkFlowDialogHandler = ({
	dialogType,
	dialogState,
	hideDialog,
	requirementDetails,
}: RegulationFormProps) => {
	const {t} = useTranslation('features/requirements', {
		keyPrefix: 'WorkflowDialog',
	});

	const navigate = useNavigate();
	const [users, setUsers] = React.useState<User[]>([]);
	const [dialogChangeType, setDialogChangeType] =
		React.useState<RequirementChangeType>(requirementDetails.changeType);
	const {setMessage} = useNotificationBar();
	const [reason, setReason] = React.useState('');
	const {data: vexData} = useGetAllVexClustersQuery();
	const {data: keywordsData} = useGetAllKeywordsQuery();
	const {getCommonInputFields: mapToInput} = new RequirementUtilsService();

	const [updateRequirementDocument] = useUpdateRequirementMutation();

	const {getInitialVersion, incrementMinorVersion, incrementMajorVersion} =
		useRequirementsVersions();

	const getDeclinedRequirementStatus = (
		requirementDetails: RequirementFromDetailsPage,
	): RequirementStatus => {
		const isNewRequirement =
			!requirementDetails?.changeType ||
			requirementDetails?.changeType === RequirementChangeType.None;

		return isNewRequirement
			? RequirementStatus.Draft
			: RequirementStatus.Modified;
	};

	const vexClusters = React.useMemo(
		() => vexData?.vexClusters ?? [],
		[vexData],
	);
	const keywords = React.useMemo(
		() => keywordsData?.keywords ?? [],
		[keywordsData],
	);

	const prePopulatedUsers = React.useMemo(() => {
		const vexClusterRefs = requirementDetails.vexClusters;
		const clusterApprovers = vexClusterRefs?.map(e => {
			const matchedVexCluster = vexClusters?.find(f => [e.id].includes(f.id));
			return matchedVexCluster?.approvers ?? [];
		});
		if (clusterApprovers[0]?.length >= 1) return clusterApprovers;

		const keywordsRef = requirementDetails.keywords;
		const keywordsApprovers = keywordsRef?.map(e => {
			const matchedKeywords = keywords?.find(f => [e.id].includes(f.id));
			return matchedKeywords?.approvers ?? [];
		});

		return keywordsApprovers;
	}, [
		vexClusters,
		requirementDetails.vexClusters,
		keywords,
		requirementDetails.keywords,
	]);

	const updateRequirementDocuments = (
		requirementStatus: RequirementStatus,
		changeType: RequirementChangeType,
		extraWorkflowProps = {},
		approvers?: any,
		version?: string,
	) => {
		const getInput = (
			info: ListOrDetailsRequirement | any,
		): UpdateRequirementInput => {
			const input: Input = mapToInput(info);
			return {
				...input,
				name: requirementDetails.name,
				id: requirementDetails.id,
				requirementId: requirementDetails.requirementId,
				status: requirementStatus,
				version,
				vexClusterRefs: mapToRef(requirementDetails.vexClusters),
				approvalWorkFlow: {
					approverRefs: mapToRef(approvers),
					...extraWorkflowProps,
				},
				changeType,
			};
		};

		return updateRequirementDocument({
			variables: {
				input: getInput(requirementDetails),
			},
			refetchQueries: [GetAllRequirementsDocument],
		});
	};

	const getApprovedVersion = (): string | undefined => {
		if (requirementDetails.version) {
			switch (requirementDetails.changeType) {
				case RequirementChangeType.Content:
					return incrementMajorVersion(requirementDetails.version);
				case RequirementChangeType.Editorial:
					return incrementMinorVersion(requirementDetails.version);
				default:
					return requirementDetails.version || getInitialVersion();
			}
		}

		return getInitialVersion();
	};

	const onSendForApprovalConfirmClick = React.useCallback(async () => {
		await updateRequirementDocuments(
			RequirementStatus.Approval,
			dialogChangeType,
			{},
			mapToRef(prePopulatedUsers[0] ?? users),
			requirementDetails.version || undefined,
		)
			.then(() => {
				setMessage(t('ApprovalSaveMessage'));
				navigate('/');
			})
			.catch(error => {
				console.error('Error:', error);
			});
	}, [requirementDetails, dialogChangeType, users, prePopulatedUsers]);

	const onConfirmApprovalclick = React.useCallback(async () => {
		await updateRequirementDocuments(
			RequirementStatus.Final,
			RequirementChangeType.None,
			{},
			null,
			getApprovedVersion(),
		)
			.then(() => {
				setMessage(t('ApprovalOKSaveMessage'));
				navigate('/');
			})
			.catch(error => {
				console.error('Error:', error);
			});
	}, [requirementDetails]);

	const onDeclineclick = React.useCallback(async () => {
		await updateRequirementDocuments(
			getDeclinedRequirementStatus(requirementDetails),
			RequirementChangeType.None,
			{
				message: reason,
			},
			null,
			requirementDetails.version || undefined,
		)
			.then(() => {
				setMessage(t('ApprovalNOKSaveMessage'));
				navigate('/');
			})
			.catch(error => {
				console.error('Error:', error);
			});
	}, [requirementDetails, reason]);

	const getChangeTypeMode = (): SendApprovalDialogChangeTypeMode => {
		if (requirementDetails?.status === RequirementStatus.Modified) {
			return SendApprovalDialogChangeTypeMode.Show;
		}
		// eslint-disable-next-line no-warning-comments
		// TODO: Disable Combobox if next major version exists

		return SendApprovalDialogChangeTypeMode.Hide;
	};

	return (
		<>
			{dialogType === RequirementStatusType.Clearing && (
				<SendApprovalDialog
					requirementName={requirementDetails.name}
					defaultApprovers={prePopulatedUsers[0]}
					{...dialogState}
					onConfirm={onSendForApprovalConfirmClick}
					users={users}
					setUsers={setUsers}
					changeType={dialogChangeType}
					setChangeType={setDialogChangeType}
					changeTypeMode={getChangeTypeMode()}
				/>
			)}
			{dialogType === RequirementStatusType.ClearingOK && (
				<ApprovalDialog
					requirementName={requirementDetails.name}
					{...dialogState}
					onConfirm={onConfirmApprovalclick}
					users={users}
				/>
			)}
			{dialogType === RequirementStatusType.ClearingNOK && (
				<DeclineDialog
					requirementName={requirementDetails.name}
					{...dialogState}
					onConfirm={onDeclineclick}
					reason={reason}
					setReason={setReason}
				/>
			)}
		</>
	);
};
