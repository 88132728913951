import React, {useMemo} from 'react';
import {
	CommandBarButton,
	IButtonStyles,
	IIconProps,
	TooltipHost,
} from '@fluentui/react';
import {VexCluster} from 'types';
import {useLocation, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {
	IArrayRoles,
	excelButtonLocation,
	excelButtonDisable,
	tooltipXL,
} from './ExcelExportCommon';
import {handleClickRegulationVersionDetailPage} from './XLRegulationVersionDetailPage';
import {handleClickRegulationDetailPage} from './XLRegulationDetailPage';
import {handleClickRegulations} from './XLRegulationsPage';
import {handleClickRequirements} from './XLRequirementsPage';
import {handleClickRequirementDetailPage} from './XLRequirementDetailPage';
import {handleClickVehProjects} from './XLVehProjectsPage';
import {handleClickVehProjectDetail} from './XLVehProjectDetailsPage';
import {handleClickSimulations} from './XLSimulationsPage';
import {handleClickAdminPage} from './XLAdminPage';
import {handleClickVoProPage} from './XLVoPro';
import {handleClickSimulationDetail} from './XLSimulationDetailsPage';
import {handleClickPOGISPage} from './XLPOGISArchivPage';
import {handleClickVkoVexPage} from './XLVkoVexPage';

interface ExcelButtonProps {
	entityListItems: any[];
	arrRoles: IArrayRoles;
	myVexClusters: VexCluster[];
	pageDetails: any | undefined;
}

export const ExcelExportButton: React.FC<ExcelButtonProps> = props => {
	const {t} = useTranslation();
	const location = useLocation();
	const {
		regulationId,
		regulatoryDocumentId,
		paragraphId,
		requirementId,
		modelSeries,
		generation,
		id,
	} = useParams();

	const styles: IButtonStyles = useMemo(() => {
		return {root: {height: '100%'}};
	}, []);

	const iconProps: IIconProps = useMemo(() => {
		return {iconName: 'ExcelLogo'};
	}, []);

	const strLocationType: string = useMemo(
		() =>
			excelButtonLocation(location.pathname, {
				regulationId,
				regulatoryDocumentId,
				paragraphId,
				requirementId,
				modelSeries,
				generation,
				id,
			}),
		[location],
	);

	const boolExcelButtonDisable = useMemo(() => {
		return excelButtonDisable(
			props.entityListItems.length,
			props.arrRoles,
			props.myVexClusters,
			strLocationType,
			props.pageDetails,
		);
	}, [location, props]);

	const getTooltipContent = (): string | undefined => {
		return tooltipXL(strLocationType, t);
	};

	let handleClick;

	if (props.entityListItems) {
		switch (strLocationType) {
			case 'regulations_page':
				handleClick = async () =>
					handleClickRegulations(props.entityListItems, t);
				break;
			case 'regulation_detail_page':
				handleClick = async () =>
					handleClickRegulationDetailPage(
						props.entityListItems,
						props.pageDetails,
						t,
					);
				break;
			case 'regulation_version_detail_page':
				handleClick = async () =>
					handleClickRegulationVersionDetailPage(
						props.entityListItems,
						props.arrRoles,
						props.pageDetails,

						t,
					);
				break;
			case 'requirements_page':
				handleClick = async () =>
					handleClickRequirements(
						props.entityListItems,
						props.arrRoles,
						props.myVexClusters,
						t,
					);
				break;
			case 'requirements_detail_page':
				handleClick = async () =>
					handleClickRequirementDetailPage(
						props.entityListItems,
						props.pageDetails,
						t,
					);
				break;
			case 'vehicle_projects_page':
				handleClick = async () =>
					handleClickVehProjects(props.entityListItems, t);
				break;
			case 'vehicle_project_detail_page':
				handleClick = async () =>
					handleClickVehProjectDetail(
						props.entityListItems,
						props.pageDetails,
						t,
					);
				break;
			case 'simulations_page':
				handleClick = async () =>
					handleClickSimulations(props.entityListItems, t);
				break;
			case 'simulation_detail_page':
				handleClick = async () =>
					handleClickSimulationDetail(
						props.entityListItems,
						props.pageDetails,
						t,
					);
				break;
			case 'report_reg_doc_page':
				handleClick = async () =>
					handleClickVoProPage(props.entityListItems, t);
				break;
			case 'pogis_archiv_page':
				handleClick = async () =>
					handleClickPOGISPage(props.entityListItems, t);
				break;
			case 'vko_vex_page':
				handleClick = async () =>
					handleClickVkoVexPage(props.entityListItems, t);
				break;
			case 'admin_page':
				handleClick = async () =>
					handleClickAdminPage(
						props.entityListItems as any[],
						location.pathname.split('/')[2],
						t,
					);
				break;
			default:
				handleClick = () => {
					console.log('page not found');
				};
		}
	}

	return (
		<TooltipHost content={getTooltipContent()}>
			<CommandBarButton
				text={t('ExcelExport', {ns: 'components/EntityList/ExcelExport'})}
				styles={styles}
				iconProps={iconProps}
				onClick={handleClick}
				disabled={boolExcelButtonDisable}
			/>
		</TooltipHost>
	);
};
