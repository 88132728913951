import React from 'react';
import {DetailsSection} from 'components';
import {useBaselinesTranslations} from './baselines.utils';
import {Baseline} from 'types';

interface Props {
	baselineName: Baseline['name'];
}

const emptyArray: never[] = [];

export function BaselineDetailsSection({baselineName}: Props): JSX.Element {
	const {t} = useBaselinesTranslations();

	/**
	 * We show a default value for the baseline's name in case the name is an
	 * empty string.
	 */
	return (
		<DetailsSection
			title={baselineName || t('DefaultBaselineName')}
			initialFieldRows={emptyArray}
		/>
	);
}
