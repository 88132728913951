import {Dispatch, SetStateAction, useState} from 'react';
import {UseFormReturn, useForm} from 'react-hook-form';
import {FormMode} from './EntityPage.types';
import {DialogInfo, useDialogState} from 'components/Dialogs';
import {useTranslation} from 'react-i18next';
import {EntityOfFormPanels} from './EntityFormPanels.types';

export type SetEntityFormMode = Dispatch<SetStateAction<FormMode>>;

export interface StateForEntityFormPanels<Entity extends EntityOfFormPanels> {
	formInfo: UseFormReturn<Entity>;
	/**
	 * The mode commonly controls whether the form displays. Other components
	 * might use the mode to control their behavior, too.
	 */
	modeInfo: [FormMode, SetEntityFormMode];
	infoOfUpdateDialog: DialogInfo;
}

export const useStateForEntityFormPanels = <
	Entity extends EntityOfFormPanels,
>(): StateForEntityFormPanels<Entity> => {
	return {
		formInfo: useForm<Entity>({reValidateMode: 'onSubmit', mode: 'all'}),
		modeInfo: useState<FormMode>(FormMode.None),
		infoOfUpdateDialog: useDialogState(),
	};
};

export const useEntityPageTranslation = () => {
	return useTranslation('components/entitypage', {
		keyPrefix: 'EntityPage',
	});
};
