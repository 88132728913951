import {Panel, PrimaryButton, Stack, useTheme} from '@fluentui/react';
import {PeoplePicker} from 'components/hookForms/ControlledPeoplePicker';
import {LoadWrapper} from 'components/LoadWrapper';
import {useCommand} from 'hooks';
import React from 'react';
import {CSVLink} from 'react-csv';
import {useTranslation} from 'react-i18next';
import {
	useEvaluateRegulationsLazyQuery,
	useEvaluateRegulatoryDocumentParagraphsLazyQuery,
	useEvaluateRegulatoryDocumentsLazyQuery,
	useEvaluateRequirementsLazyQuery,
	useEvaluateVehicleProjectsLazyQuery,
} from '../hooks/evaluateConvolutes.generated';

export const EvaluatePersonForm: React.FC = () => {
	const {t} = useTranslation('features/adminsection', {
		keyPrefix: 'AdminDataProtectionPage',
	});
	const theme = useTheme();
	const [isOpen, setIsOpen] = React.useState(false);
	const [evaluated, setEvaluated] = React.useState(false);
	const [content, setContent] = React.useState();

	const [getEvaluatedRegulations, {loading: loadRegs}] =
		useEvaluateRegulationsLazyQuery();
	const [getEvaluatedRegulatoryDocuments, {loading: loadRegDocs}] =
		useEvaluateRegulatoryDocumentsLazyQuery();
	const [
		getEvaluatedRegulatoryDocumentParagraphs,
		{loading: loadRegDocParagraphs},
	] = useEvaluateRegulatoryDocumentParagraphsLazyQuery();
	const [getEvaluatedRequirements, {loading: loadReqs}] =
		useEvaluateRequirementsLazyQuery();
	const [getEvaluatedVehicleProjects, {loading: loadVPs}] =
		useEvaluateVehicleProjectsLazyQuery();

	useCommand(
		{
			key: 'evaluate',
			text: t('Evaluate'),
			iconProps: {iconName: 'ProfileSearch'},
			priority: 3,
			onClick() {
				setIsOpen(true);
			},
		},
		[isOpen],
	);

	const handleCancelClick = React.useCallback(() => {
		setIsOpen(false);
	}, [isOpen]);

	const [user, setUser] = React.useState();

	const onUserInputChange = React.useCallback(
		(items: any) => setUser(items[0] || ''),
		[user],
	);

	const evaluate = React.useCallback(async () => {
		setEvaluated(false);

		const regulations = await getEvaluatedRegulations({
			variables: {
				personId: (user as any).id || '',
			},
		});

		const regDocs = await getEvaluatedRegulatoryDocuments({
			variables: {
				personId: (user as any).id || '',
			},
		});

		const regDocParagraphs = await getEvaluatedRegulatoryDocumentParagraphs({
			variables: {
				personId: (user as any).id || '',
			},
		});

		const requirements = await getEvaluatedRequirements({
			variables: {
				personId: (user as any).id || '',
			},
		});

		const vehicleProjects = await getEvaluatedVehicleProjects({
			variables: {
				personId: (user as any).id || '',
			},
		});

		const csvContent = [['title', 'typeName']];

		regulations.data?.evaluateRegulations?.forEach(reg =>
			csvContent.push([reg.name, reg.__typename || '']),
		);

		regDocs.data?.evaluateRegulatoryDocuments?.forEach(reg =>
			csvContent.push([reg.name, reg.__typename || '']),
		);

		regDocParagraphs.data?.evaluateRegulatoryDocumentParagraphs?.forEach(reg =>
			csvContent.push([reg.enumeration, reg.__typename || '']),
		);

		requirements.data?.evaluateRequirements?.forEach(reg =>
			csvContent.push([reg.name, reg.__typename || '']),
		);

		vehicleProjects.data?.evaluateVehicleProjects?.forEach(reg =>
			csvContent.push([reg.name, reg.__typename || '']),
		);

		setContent(csvContent as any);

		if (csvContent.length > 0) {
			setEvaluated(true);
		}
	}, [user, evaluated, content]);

	return (
		<Panel
			isLightDismiss
			isOpen={isOpen}
			onDismiss={handleCancelClick}
			headerText={t('EvaluatePerson')}
		>
			<Stack tokens={{childrenGap: 8}}>
				<PeoplePicker
					onChange={items => onUserInputChange(items || [])}
					itemLimit={1}
				/>
				<PrimaryButton text={t('Evaluate')} onClick={evaluate} />
				{evaluated && (
					<LoadWrapper
						loading={
							loadRegDocParagraphs ||
							loadRegDocs ||
							loadRegs ||
							loadReqs ||
							loadVPs
						}
					>
						<CSVLink
							data={content as any}
							filename={`Auswertung_${(user as any).name}`}
							target='_blank'
							style={{color: theme.palette.themePrimary}}
						>
							{t('DownloadEvaluation')}
						</CSVLink>
					</LoadWrapper>
				)}
			</Stack>
		</Panel>
	);
};
