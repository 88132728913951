import {PersonaSize} from '@fluentui/react';
import {EntityListColumn} from 'components';
import {
	renderDate,
	renderPersona as getRenderPersona,
} from 'components/EntityList/ColumnRenderers';
import {useMemo} from 'react';
import {SetRequired} from 'type-fest';
import {PageSimulation} from './SimulationsPage.types';
import {useTranslation} from 'react-i18next';
import {NAMESPACE_FOR_SIMULATIONS} from './SimulationsPage.utils';
import {DATE_TYPE} from 'components/EntityList/EntityUtils';

export interface ColumnOfSimulationsPage extends EntityListColumn {
	key: keyof PageSimulation;
}

export const minWidthOfColOfSimulationsPage = 200;

export const useColumnsOfSimulationsPage = (): ColumnOfSimulationsPage[] => {
	const {t: getSimulationTranslation} = useTranslation(
		NAMESPACE_FOR_SIMULATIONS,
	);

	const columns = useMemo((): ColumnOfSimulationsPage[] => {
		type FieldsToCreateColumn = SetRequired<
			Partial<ColumnOfSimulationsPage>,
			'key'
		>;

		const createColumnFromKey = ({
			key,
			...other
		}: FieldsToCreateColumn): ColumnOfSimulationsPage => {
			return {
				key,
				name: getSimulationTranslation(key),
				minWidth: minWidthOfColOfSimulationsPage,
				fieldName: key,
				filterable: true,
				...other,
			};
		};

		const createCreationFields = (): FieldsToCreateColumn[] => {
			const renderPersona = getRenderPersona({size: PersonaSize.size8});
			return [
				{key: 'createdBy', onRender: renderPersona},
				{key: 'modelSeriesReference'},
				{key: 'generationReference'},
				{key: 'modifiedBy', onRender: renderPersona},
				{key: 'modifiedAt', onRender: renderDate(), dataType: DATE_TYPE},
			];
		};

		const creationFields: FieldsToCreateColumn[] = createCreationFields();
		return creationFields.map(createColumnFromKey);
	}, [getSimulationTranslation]);

	return columns;
};
