import {
	ActivityItem,
	Icon,
	IconButton,
	Link,
	Stack,
	Theme,
	useTheme,
} from '@fluentui/react';
import {useUserContext} from 'authentication/UserContext';
import {GetRegulationDocument} from 'features/Regulations/hooks';
import {GetRegulatoryDocumentDetailsDocument} from 'features/RegulatoryDocuments/hooks/useGetRegulatoryDocumentDetails.generated';
import i18n from 'i18next';
import {formatDateTime, DateTimeFormat} from 'i18n/localeDateFormat';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Faq} from 'types';
import {
	useDeleteRegulationFaqMutation,
	useDeleteRegulatoryDocumentFaqMutation,
	useDeleteRegulatoryDocumentParagraphFaqMutation,
} from './hooks';

export enum FAQLevel {
	Regulation,
	RegulatoryDocument,
	Paragraph,
}

type FAQListProps = {
	items: Faq[];
	regulationId: string;
	regulatoryDocumentId: string;
	level: FAQLevel;
	paragraphId?: string;
};

export const FAQList: React.FC<FAQListProps> = ({
	items,
	regulationId,
	regulatoryDocumentId,
	level,
	paragraphId,
}) => {
	const {t} = useTranslation('components/faqlist');
	const theme = useTheme();
	const isAdmin = useUserContext();
	const {iconStyles, activityItemStyles, deleteStyles} = getStyles(theme);
	const [deleteRegulationFaq] = useDeleteRegulationFaqMutation();
	const [deleteRegulatoryDocumentFaq] =
		useDeleteRegulatoryDocumentFaqMutation();
	const [deleteRegulatoryDocumentParagraphFaq] =
		useDeleteRegulatoryDocumentParagraphFaqMutation();

	const handleRegulationFaqDelete = React.useCallback(
		(item: Faq) =>
			deleteRegulationFaq({
				variables: {
					input: {
						id: regulationId,
						faqId: item.id,
					},
				},
				refetchQueries: [GetRegulationDocument],
			}),
		[deleteRegulationFaq, regulationId],
	);

	const handleRegulatoryDocumentFaqDelete = React.useCallback(
		(item: Faq) =>
			deleteRegulatoryDocumentFaq({
				variables: {
					input: {
						id: regulatoryDocumentId,
						faqId: item.id,
					},
				},
				refetchQueries: [GetRegulatoryDocumentDetailsDocument],
			}),
		[deleteRegulatoryDocumentFaq, regulatoryDocumentId],
	);

	const handleParagraphFaqDelete = React.useCallback(
		(item: Faq) =>
			deleteRegulatoryDocumentParagraphFaq({
				variables: {
					input: {
						paragraphId: paragraphId || '',
						regulatoryDocumentId,
						faqId: item.id,
					},
				},
				refetchQueries: [GetRegulatoryDocumentDetailsDocument],
			}),
		[deleteRegulatoryDocumentParagraphFaq, paragraphId, regulatoryDocumentId],
	);

	const faqs = items.map((item: Faq, index: number) => (
		<ActivityItem
			key={index}
			activityIcon={<Icon iconName={'BookAnswers'} styles={iconStyles} />}
			activityDescription={
				<Stack horizontal horizontalAlign='space-between'>
					<Stack.Item>
						<>
							{t('CreatedBy')} <Link>{item.createdBy.name}</Link>
							{t('CreatedAt')}
							{formatDateTime(
								new Date(item.createdAt),
								i18n,
								DateTimeFormat.DateMonthWeekday,
							)}
						</>
					</Stack.Item>
					{isAdmin && (
						<Stack.Item>
							<IconButton
								iconProps={{iconName: 'StatusCircleErrorX'}}
								styles={deleteStyles}
								onClick={() => {
									if (level === FAQLevel.Regulation) {
										handleRegulationFaqDelete(item);
									}

									if (level === FAQLevel.RegulatoryDocument) {
										handleRegulatoryDocumentFaqDelete(item);
									}

									if (level === FAQLevel.Paragraph) {
										handleParagraphFaqDelete(item);
									}
								}}
							/>
						</Stack.Item>
					)}
				</Stack>
			}
			comments={
				<Stack>
					<Stack.Item>
						<strong>
							{t('Question')}
							{item.question}
						</strong>
					</Stack.Item>
					<Stack.Item>
						{t('Answer')}
						{item.answer}
					</Stack.Item>
				</Stack>
			}
			styles={activityItemStyles}
		/>
	));

	return <div>{faqs}</div>;
};

const getStyles = (theme: Theme) => ({
	iconStyles: {
		root: {
			color: theme.palette.themePrimary,
		},
	},
	activityItemStyles: {
		root: {marginTop: 20},
	},
	deleteStyles: {
		root: {
			height: 20,
			width: 20,
		},
		rootHovered: {
			background: theme.palette.whiteTranslucent40,
		},
		rootPressed: {
			background: theme.palette.whiteTranslucent40,
		},
	},
});
