import {
	ControlledEntityDropdown,
	GetSelectedKey,
	PropsOfControlledEntityDropdown,
} from 'components/hookForms';
import React, {useCallback, useMemo} from 'react';
import {Control, UseFormWatch} from 'react-hook-form';
import {RegulatoryDocument} from 'types';
import {
	BasicDocFields,
	FormRegulatoryDoc,
} from './RegulatoryDocumentsForm.types';
import {useRegDocsFormTranslations} from 'features/RegulatoryDocuments/hooks/RegulatoryDocuments.commonHooks';
import {IDropdownOption} from '@fluentui/react';

type DropdownProps = Omit<
	PropsOfControlledEntityDropdown<BasicDocFields>,
	'selectableOptions' | 'getKey' | 'getText' | 'name'
>;

type Name = 'predecessor' | 'successor';

export interface PredecessorOrSuccessorProps extends DropdownProps {
	control: Control<RegulatoryDocument>;
	regulatoryDocuments: BasicDocFields[];
	regulatoryDocument: FormRegulatoryDoc;
	watch: UseFormWatch<RegulatoryDocument>;
	name: Name;
}

export const KEY_FOR_NO_SELECTION = 'no-selection';

export function PredecessorOrSuccessor({
	regulatoryDocuments,
	regulatoryDocument,
	watch,
	name,
	...other
}: PredecessorOrSuccessorProps) {
	const {t} = useRegDocsFormTranslations();

	const value: RegulatoryDocument[Name] = watch(name);

	type Option = BasicDocFields & Pick<IDropdownOption, 'hidden'>;

	const selectableOptions = useMemo((): Option[] => {
		const getIfRegDocIsNotSelected = (): boolean => {
			return !value || value.id === KEY_FOR_NO_SELECTION;
		};

		const regDocOptions: BasicDocFields[] = regulatoryDocuments.filter(
			regDoc => regDoc.id !== regulatoryDocument?.id,
		);

		return [
			{
				id: KEY_FOR_NO_SELECTION,
				name: t('LabelForOptionToSelectNothing'),
				hidden: getIfRegDocIsNotSelected(),
			},
			...regDocOptions,
		];
	}, [regulatoryDocuments, regulatoryDocument, value, t]);

	const getKey = useCallback((item: BasicDocFields): string => item.id, []);

	const getText = useCallback((item: BasicDocFields): string => item.name, []);

	const getSelectedKey: GetSelectedKey<BasicDocFields> = useCallback(
		possibleInfoOfSelectedRegDoc => {
			if (possibleInfoOfSelectedRegDoc)
				return getKey(possibleInfoOfSelectedRegDoc);
			/**
			 * So that we consistently show the "No Selection" option's text when
			 * there is no selection.
			 */
			return KEY_FOR_NO_SELECTION;
		},
		[getKey],
	);

	return (
		<ControlledEntityDropdown
			selectableOptions={selectableOptions}
			getKey={getKey}
			getText={getText}
			name={name}
			getSelectedKey={getSelectedKey}
			{...other}
		/>
	);
}
