import {
	DatePicker,
	DayOfWeek,
	FirstWeekOfYear,
	IDatePickerProps,
	Stack,
} from '@fluentui/react';
import {formatDateTime} from 'i18n/localeDateFormat';
import * as React from 'react';
import {FC} from 'react';
import {Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {datePickerStringsDE} from './datePickerStrings';
import {HookFormProps} from './HookFormProps';
import {
	ActionTags,
	ActionTagsProps,
} from 'features/RegulatoryDocuments/components/DocumentDetails/ActionTags';
import {LabelWithTooltip} from 'features/localizedTooltips';

export type LocalizedDatePickerProps = Omit<
	IDatePickerProps,
	| 'firstDayOfWeek'
	| 'firstWeekOfYear'
	| 'strings'
	| 'showWeekNumbers'
	| 'allowTextInput'
	| 'formatDate'
> & {
	actionTags?: ActionTagsProps['tags'];
	/**
	 * This is used only for the tooltip's translation key.
	 */
	name?: HookFormProps['name'];
};

export const LocalizedDatePicker: React.FC<
	LocalizedDatePickerProps
> = props => {
	const {t, i18n} = useTranslation('components/controlleddatepicker');
	const currLang = i18n.language;

	const onFormatDate = React.useCallback(
		(_valueToFormat?: Date | undefined) => {
			if (_valueToFormat) {
				return formatDateTime(_valueToFormat, i18n);
			}

			return '';
		},
		[t],
	);

	const firstDayOfWeek = currLang.startsWith('de')
		? DayOfWeek.Monday
		: undefined;
	const firstWeekOfYear = currLang.startsWith('de')
		? FirstWeekOfYear.FirstFullWeek
		: undefined;
	const datePickerStrings = currLang.startsWith('de')
		? datePickerStringsDE
		: undefined;

	const parseDate = (dateStr: string): Date | null => {
		const parts = dateStr.split('.');
		if (parts.length !== 3) {
			return null;
		}

		const day = parseInt(parts[0], 10);
		const month = parseInt(parts[1], 10) - 1;
		const year = parseInt(parts[2], 10);
		if (isNaN(day) || isNaN(month) || isNaN(year)) {
			return null;
		}

		return new Date(year, month, day);
	};

	return (
		<>
			<Stack horizontal tokens={{childrenGap: 5}}>
				{props.label && (
					<LabelWithTooltip translationKey={props.name}>
						{props.label}
					</LabelWithTooltip>
				)}
				<ActionTags tags={props.actionTags} itemStyle={{paddingTop: 8}} />
			</Stack>
			<DatePicker
				{...props}
				label={undefined}
				firstDayOfWeek={firstDayOfWeek}
				firstWeekOfYear={firstWeekOfYear}
				showWeekNumbers={true}
				allowTextInput
				formatDate={onFormatDate}
				parseDateFromString={parseDate}
				strings={datePickerStrings}
			/>
		</>
	);
};

export const ControlledDatePicker: FC<
	Omit<IDatePickerProps, 'defaultValue'> &
		HookFormProps & {
			actionTags?: ActionTagsProps['tags'];
		}
> = props => {
	return (
		<Controller
			name={props.name}
			control={props.control}
			rules={props.rules}
			defaultValue={props.defaultValue || undefined}
			render={({
				field: {onChange, onBlur, name: fieldName, value},
				fieldState: {error},
			}) => {
				const newValue = value ? new Date(value) : undefined;

				return (
					<LocalizedDatePicker
						{...props}
						textField={{
							name: fieldName,
							errorMessage: error && error.message,
						}}
						onSelectDate={date => {
							let tzDate = date;
							if (date) {
								tzDate = toUTCDate(date);
							}

							onChange(tzDate);
						}}
						value={value instanceof Date ? value : newValue}
						onBlur={onBlur}
					/>
				);
			}}
		/>
	);
};

const toUTCDate = (date: Date) =>
	new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
