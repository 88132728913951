import {
	PhaseFieldNames,
	QueryParagraphWithCorrectTypes,
} from 'features/RegulatoryDocuments/RegDocDetailsPage/RegDocDetailsPage.queryTypes';
import {
	OptimisticParagraph,
	OptimisticPhaseFieldArray,
	OptimisticPhaseFieldItem,
} from './optimisticParagraph.types';
import _ from 'lodash';

/*
 * Phase field types
 */
type PhaseField = QueryParagraphWithCorrectTypes[PhaseFieldNames];
type PhaseFieldArray = NonNullable<PhaseField>;
type PhaseFieldItem = PhaseFieldArray[number];

/*
 * Sanitized paragraph
 */
type FieldsToSanitize =
	| PhaseFieldNames
	| 'summary'
	| 'modelYear'
	| 'keywordAssignments';

type FieldsFromQueryParagraph = Pick<
	QueryParagraphWithCorrectTypes,
	FieldsToSanitize
>;

/*
 * Sanitized fields
 */
type SanitizedFields = Pick<OptimisticParagraph, FieldsToSanitize>;

export type CommonSanitizedParagraphFields<
	Paragraph extends FieldsFromQueryParagraph,
> = Omit<Paragraph, FieldsToSanitize> & SanitizedFields;

/**
 * This service's goal is to sanitize the paragraph's fields so that they're
 * more like the optimistic paragraph.
 */
export class ParagraphSanitizationService {
	private sanitizePhaseFieldItem = (
		item: PhaseFieldItem,
	): OptimisticPhaseFieldItem => {
		/**
		 * We make a copy to avoid changing the original data.
		 */
		const itemCopy: PhaseFieldItem = {...item};
		return _.defaults(itemCopy, {date: null, status: null});
	};

	private sanitizePhaseFieldItems = (
		items: PhaseFieldArray,
	): OptimisticPhaseFieldArray => {
		return items.map(this.sanitizePhaseFieldItem);
	};

	private sanitizePhaseField = (
		phaseField: PhaseField,
	): OptimisticPhaseFieldArray => {
		if (phaseField) return this.sanitizePhaseFieldItems(phaseField);
		return [];
	};

	public sanitizeNonDateParagraphFields = <
		Paragraph extends FieldsFromQueryParagraph,
	>({
		phaseIn,
		phaseOut,
		summary,
		modelYear,
		keywordAssignments,
		...other
	}: Paragraph): CommonSanitizedParagraphFields<Paragraph> => {
		return {
			phaseIn: this.sanitizePhaseField(phaseIn),
			phaseOut: this.sanitizePhaseField(phaseOut),
			summary: summary ?? null,
			modelYear: modelYear ?? null,
			keywordAssignments: keywordAssignments ?? [],
			...other,
		};
	};

	public sanitizeParagraphLikeObject = ({
		comprehensive,
		dateNewRegistration,
		dateNewTypes,
		...other
	}: QueryParagraphWithCorrectTypes): OptimisticParagraph => {
		const baseFields: CommonSanitizedParagraphFields<QueryParagraphWithCorrectTypes> =
			this.sanitizeNonDateParagraphFields(other);
		return {
			...baseFields,
			comprehensive: comprehensive ?? null,
			dateNewRegistration: dateNewRegistration ?? null,
			dateNewTypes: dateNewTypes ?? null,
		};
	};
}
