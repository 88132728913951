import React from 'react';
import {PdfPageContext} from './PdfPageContext';

export interface PdfPageContextProviderProps {
	currentPage?: number;
	children: React.ReactNode;
}

export const PdfPageContextProvider: React.FC<PdfPageContextProviderProps> = ({
	currentPage = 0,
	children,
}) => {
	const [page, setPage] = React.useState(currentPage);

	return (
		<PdfPageContext.Provider
			value={{
				currentPage: page,
				setCurrentPage: setPage,
			}}
		>
			{children}
		</PdfPageContext.Provider>
	);
};
