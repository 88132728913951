import {IStackProps, Stack} from '@fluentui/react';
import React from 'react';

export const AutoStack: React.FC<IStackProps> = ({children, ...props}) => (
	<Stack {...props}>
		{React.Children.map(children, (child: any) => {
			const type =
				child && child.type ? child.type.displayName || child.type : null;
			return /^(StackItem|FieldStack)/.test(type) ? (
				child
			) : (
				<Stack.Item grow={1}>{child}</Stack.Item>
			);
		})}
	</Stack>
);
