import {EntityListColumn} from 'components/EntityList';
import {useTranslation} from 'react-i18next';

export type ExtraFieldsForNameColumn = Partial<EntityListColumn>;

export const useEntityNameColumn = (
	entityDisplayName: string,
	extraFields?: ExtraFieldsForNameColumn,
): EntityListColumn => {
	const {t} = useTranslation('components/entitypage', {
		keyPrefix: 'EntityPage',
	});

	return {
		key: 'name',
		name: t('EntityName', {entityDisplayName}),
		fieldName: 'name',
		minWidth: 100,
		maxWidth: 200,
		isResizable: true,
		sortable: true,
		isMultiline: true,
		...extraFields,
	};
};
