import {Command} from 'hooks';
import {RegulatoryDocument, WorkflowStatus, UserRole} from 'types';
import {
	RegulatoryDocumentFormMode,
	RegulatoryDocumentFormModeInfo,
} from './components/RegulatoryDocumentsForm/useRegulatoryDocumentFormMode';
import {UserContextType} from 'authentication/UserContext';
import {DocumentChangeStateInfo} from './components/useDidDocumentChange';
import {QueryResult} from '@apollo/client';
import {useEditEntityCmd} from 'hooks/useEditEntityCmd';

type DocWithWorkflow = Pick<RegulatoryDocument, 'workflow'>;

export const getIfDocHasUneditableStatus = (
	doc: Partial<DocWithWorkflow>,
	isAdmin: UserContextType['isAdmin'],
	_username: UserContextType['username'],
): boolean => {
	if (!doc.workflow) return true;
	return doc.workflow?.status === WorkflowStatus.Clearing && !isAdmin;
};

export interface EditRegulatoryDocCmdOptions
	extends Pick<DocumentChangeStateInfo, 'setDidDocumentChange'>,
		Pick<RegulatoryDocumentFormModeInfo, 'setMode'>,
		Command {
	extraDependencies?: unknown[];
	stopPolling: QueryResult['stopPolling'] | null;
}

export const useEditRegulatoryDocCmd = ({
	setDidDocumentChange,
	setMode,
	stopPolling,
	extraDependencies = [],
	...commandFields
}: EditRegulatoryDocCmdOptions) => {
	return useEditEntityCmd(
		{
			onClick() {
				setDidDocumentChange(false);
				setMode(RegulatoryDocumentFormMode.Update);
				/**
				 * This prevents the doc form from resetting each time the
				 * doc changes. The doc might change if its attachment
				 * URLs change.
				 */
				stopPolling?.();
			},
			roles: [UserRole.SystemAdministrator, UserRole.Vko],
			...commandFields,
		},
		[stopPolling, ...extraDependencies],
	);
};
