import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveRegulatoryDocumentParagraphsRequirementsMutationVariables = Types.Exact<{
  input: Types.RemoveRegulatoryDocumentParagraphsRequirementsInput;
}>;


export type RemoveRegulatoryDocumentParagraphsRequirementsMutation = { __typename?: 'Mutation', removeRegulatoryDocumentParagraphsRequirements: { __typename?: 'RemoveRegulatoryDocumentParagraphsRequirementsPayload', regulatoryDocument?: { __typename?: 'RegulatoryDocument', id: string } | null } };


export const RemoveRegulatoryDocumentParagraphsRequirementsDocument = gql`
    mutation RemoveRegulatoryDocumentParagraphsRequirements($input: RemoveRegulatoryDocumentParagraphsRequirementsInput!) {
  removeRegulatoryDocumentParagraphsRequirements(input: $input) {
    regulatoryDocument {
      id
    }
  }
}
    `;
export type RemoveRegulatoryDocumentParagraphsRequirementsMutationFn = Apollo.MutationFunction<RemoveRegulatoryDocumentParagraphsRequirementsMutation, RemoveRegulatoryDocumentParagraphsRequirementsMutationVariables>;

/**
 * __useRemoveRegulatoryDocumentParagraphsRequirementsMutation__
 *
 * To run a mutation, you first call `useRemoveRegulatoryDocumentParagraphsRequirementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRegulatoryDocumentParagraphsRequirementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRegulatoryDocumentParagraphsRequirementsMutation, { data, loading, error }] = useRemoveRegulatoryDocumentParagraphsRequirementsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveRegulatoryDocumentParagraphsRequirementsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveRegulatoryDocumentParagraphsRequirementsMutation, RemoveRegulatoryDocumentParagraphsRequirementsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveRegulatoryDocumentParagraphsRequirementsMutation, RemoveRegulatoryDocumentParagraphsRequirementsMutationVariables>(RemoveRegulatoryDocumentParagraphsRequirementsDocument, options);
      }
export type RemoveRegulatoryDocumentParagraphsRequirementsMutationHookResult = ReturnType<typeof useRemoveRegulatoryDocumentParagraphsRequirementsMutation>;
export type RemoveRegulatoryDocumentParagraphsRequirementsMutationResult = Apollo.MutationResult<RemoveRegulatoryDocumentParagraphsRequirementsMutation>;
export type RemoveRegulatoryDocumentParagraphsRequirementsMutationOptions = Apollo.BaseMutationOptions<RemoveRegulatoryDocumentParagraphsRequirementsMutation, RemoveRegulatoryDocumentParagraphsRequirementsMutationVariables>;