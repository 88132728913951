import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetActivityItemsQueryVariables = Types.Exact<{
  userId: Types.Scalars['String'];
}>;


export type GetActivityItemsQuery = { __typename?: 'Query', regulatoryDocuments?: { __typename?: 'RegulatoryDocumentsConnection', nodes?: Array<{ __typename?: 'RegulatoryDocument', id: string, name: string, modifiedAt?: any | null, type: 'RegulatoryDocument', regulation?: { __typename?: 'Regulation', id: string, regulationNumber: string } | null }> | null } | null, userCreatedRegulations: Array<{ __typename?: 'Regulation', id: string, name: string, modifiedAt?: any | null, regulationNumber: string, type: 'Regulation' }> };


export const GetActivityItemsDocument = gql`
    query GetActivityItems($userId: String!) {
  regulatoryDocuments(first: 30, where: {createdById: {eq: $userId}}) {
    nodes {
      type: __typename
      id
      name
      modifiedAt
      regulation {
        id
        regulationNumber
      }
    }
  }
  userCreatedRegulations(userId: $userId) {
    type: __typename
    id
    name
    modifiedAt
    regulationNumber
  }
}
    `;

/**
 * __useGetActivityItemsQuery__
 *
 * To run a query within a React component, call `useGetActivityItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityItemsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetActivityItemsQuery(baseOptions: Apollo.QueryHookOptions<GetActivityItemsQuery, GetActivityItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActivityItemsQuery, GetActivityItemsQueryVariables>(GetActivityItemsDocument, options);
      }
export function useGetActivityItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivityItemsQuery, GetActivityItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActivityItemsQuery, GetActivityItemsQueryVariables>(GetActivityItemsDocument, options);
        }
export type GetActivityItemsQueryHookResult = ReturnType<typeof useGetActivityItemsQuery>;
export type GetActivityItemsLazyQueryHookResult = ReturnType<typeof useGetActivityItemsLazyQuery>;
export type GetActivityItemsQueryResult = Apollo.QueryResult<GetActivityItemsQuery, GetActivityItemsQueryVariables>;