import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllRegulationClustersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllRegulationClustersQuery = { __typename?: 'Query', regulationClusters?: Array<{ __typename?: 'RegulationCluster', id: string, name: string, createdAt?: any | null, modifiedAt?: any | null, createdBy: { __typename?: 'User', id: string, mail?: string | null, userPrincipalName?: string | null, name: string, surname?: string | null, givenName?: string | null }, modifiedBy: { __typename?: 'User', id: string, mail?: string | null, userPrincipalName?: string | null, name: string, surname?: string | null, givenName?: string | null } }> | null };

export type CreateRegulationClusterMutationVariables = Types.Exact<{
  input: Types.CreateRegulationClusterInput;
}>;


export type CreateRegulationClusterMutation = { __typename?: 'Mutation', createRegulationCluster: { __typename?: 'CreateRegulationClusterPayload', regulationCluster?: { __typename?: 'RegulationCluster', id: string } | null } };

export type UpdateRegulationClusterMutationVariables = Types.Exact<{
  input: Types.UpdateRegulationClusterInput;
}>;


export type UpdateRegulationClusterMutation = { __typename?: 'Mutation', updateRegulationCluster: { __typename?: 'UpdateRegulationClusterPayload', regulationCluster?: { __typename?: 'RegulationCluster', id: string } | null } };

export type DeleteRegulationClusterMutationVariables = Types.Exact<{
  input: Types.DeleteRegulationClusterInput;
}>;


export type DeleteRegulationClusterMutation = { __typename?: 'Mutation', deleteRegulationCluster: { __typename?: 'DeleteRegulationClusterPayload', regulationCluster?: { __typename?: 'RegulationCluster', id: string } | null } };


export const GetAllRegulationClustersDocument = gql`
    query GetAllRegulationClusters {
  regulationClusters {
    id
    name
    createdBy {
      id
      mail
      userPrincipalName
      name
      surname
      givenName
    }
    createdAt
    modifiedBy {
      id
      mail
      userPrincipalName
      name
      surname
      givenName
    }
    modifiedAt
  }
}
    `;

/**
 * __useGetAllRegulationClustersQuery__
 *
 * To run a query within a React component, call `useGetAllRegulationClustersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRegulationClustersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRegulationClustersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllRegulationClustersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllRegulationClustersQuery, GetAllRegulationClustersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllRegulationClustersQuery, GetAllRegulationClustersQueryVariables>(GetAllRegulationClustersDocument, options);
      }
export function useGetAllRegulationClustersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllRegulationClustersQuery, GetAllRegulationClustersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllRegulationClustersQuery, GetAllRegulationClustersQueryVariables>(GetAllRegulationClustersDocument, options);
        }
export type GetAllRegulationClustersQueryHookResult = ReturnType<typeof useGetAllRegulationClustersQuery>;
export type GetAllRegulationClustersLazyQueryHookResult = ReturnType<typeof useGetAllRegulationClustersLazyQuery>;
export type GetAllRegulationClustersQueryResult = Apollo.QueryResult<GetAllRegulationClustersQuery, GetAllRegulationClustersQueryVariables>;
export const CreateRegulationClusterDocument = gql`
    mutation CreateRegulationCluster($input: CreateRegulationClusterInput!) {
  createRegulationCluster(input: $input) {
    regulationCluster {
      id
    }
  }
}
    `;
export type CreateRegulationClusterMutationFn = Apollo.MutationFunction<CreateRegulationClusterMutation, CreateRegulationClusterMutationVariables>;

/**
 * __useCreateRegulationClusterMutation__
 *
 * To run a mutation, you first call `useCreateRegulationClusterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRegulationClusterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRegulationClusterMutation, { data, loading, error }] = useCreateRegulationClusterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRegulationClusterMutation(baseOptions?: Apollo.MutationHookOptions<CreateRegulationClusterMutation, CreateRegulationClusterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRegulationClusterMutation, CreateRegulationClusterMutationVariables>(CreateRegulationClusterDocument, options);
      }
export type CreateRegulationClusterMutationHookResult = ReturnType<typeof useCreateRegulationClusterMutation>;
export type CreateRegulationClusterMutationResult = Apollo.MutationResult<CreateRegulationClusterMutation>;
export type CreateRegulationClusterMutationOptions = Apollo.BaseMutationOptions<CreateRegulationClusterMutation, CreateRegulationClusterMutationVariables>;
export const UpdateRegulationClusterDocument = gql`
    mutation UpdateRegulationCluster($input: UpdateRegulationClusterInput!) {
  updateRegulationCluster(input: $input) {
    regulationCluster {
      id
    }
  }
}
    `;
export type UpdateRegulationClusterMutationFn = Apollo.MutationFunction<UpdateRegulationClusterMutation, UpdateRegulationClusterMutationVariables>;

/**
 * __useUpdateRegulationClusterMutation__
 *
 * To run a mutation, you first call `useUpdateRegulationClusterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRegulationClusterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRegulationClusterMutation, { data, loading, error }] = useUpdateRegulationClusterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRegulationClusterMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRegulationClusterMutation, UpdateRegulationClusterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRegulationClusterMutation, UpdateRegulationClusterMutationVariables>(UpdateRegulationClusterDocument, options);
      }
export type UpdateRegulationClusterMutationHookResult = ReturnType<typeof useUpdateRegulationClusterMutation>;
export type UpdateRegulationClusterMutationResult = Apollo.MutationResult<UpdateRegulationClusterMutation>;
export type UpdateRegulationClusterMutationOptions = Apollo.BaseMutationOptions<UpdateRegulationClusterMutation, UpdateRegulationClusterMutationVariables>;
export const DeleteRegulationClusterDocument = gql`
    mutation DeleteRegulationCluster($input: DeleteRegulationClusterInput!) {
  deleteRegulationCluster(input: $input) {
    regulationCluster {
      id
    }
  }
}
    `;
export type DeleteRegulationClusterMutationFn = Apollo.MutationFunction<DeleteRegulationClusterMutation, DeleteRegulationClusterMutationVariables>;

/**
 * __useDeleteRegulationClusterMutation__
 *
 * To run a mutation, you first call `useDeleteRegulationClusterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRegulationClusterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRegulationClusterMutation, { data, loading, error }] = useDeleteRegulationClusterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRegulationClusterMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRegulationClusterMutation, DeleteRegulationClusterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRegulationClusterMutation, DeleteRegulationClusterMutationVariables>(DeleteRegulationClusterDocument, options);
      }
export type DeleteRegulationClusterMutationHookResult = ReturnType<typeof useDeleteRegulationClusterMutation>;
export type DeleteRegulationClusterMutationResult = Apollo.MutationResult<DeleteRegulationClusterMutation>;
export type DeleteRegulationClusterMutationOptions = Apollo.BaseMutationOptions<DeleteRegulationClusterMutation, DeleteRegulationClusterMutationVariables>;