import {EntityListColumn} from './EntityList';

type NameColumnFields = Partial<EntityListColumn> &
	Pick<EntityListColumn, 'name'>;

/**
 * Implementation notes:
 *
 * We do not set the name here because we do not consistently set the name
 * throughout the app.
 */
export const getNameColumnFields = (
	fields: NameColumnFields,
): EntityListColumn => {
	return {fieldName: 'name', key: 'name', minWidth: 100, ...fields};
};
