/**
 * Note:
 *
 * Dist = Distance
 */

import {idOfScrollablePane} from 'appShell/Layout';
import {HEIGHT_OF_LIST_HEADER, ROW_SELECTOR} from 'components';
import {classToAddToHeaderWhenSticky} from 'features/localizedTooltips';
import {
	CLASS_TO_ADD_TO_PARAGRAPHS_SECTION_HEADER_WHEN_STICKY,
	HEIGHT_OF_PARAGRAPHS_SECTION_HEADER,
} from './RegDocDetailsPage.constants';
import {RefToContainerOfMainParagraphs} from './RegDocDetailsPage.types';

interface Boxes {
	scrollablePane: DOMRect;
	paragraph: DOMRect;
}

class ParagraphVisibilityService {
	private getRequiredDistanceForPossiblyStickyElement = (
		classNameWhenElementIsSticky: string,
		distanceToAddIfSticky: number,
	): number => {
		const element: HTMLElement | null = document.querySelector(
			`.${classNameWhenElementIsSticky}`,
		);
		return element ? distanceToAddIfSticky : 0;
	};

	private getRequiredDistanceForSectionHeader = (): number => {
		return this.getRequiredDistanceForPossiblyStickyElement(
			CLASS_TO_ADD_TO_PARAGRAPHS_SECTION_HEADER_WHEN_STICKY,
			HEIGHT_OF_PARAGRAPHS_SECTION_HEADER,
		);
	};

	private getRequiredDistanceForListHeader = (): number => {
		return this.getRequiredDistanceForPossiblyStickyElement(
			classToAddToHeaderWhenSticky,
			HEIGHT_OF_LIST_HEADER,
		);
	};

	private getNumberWithinRange = (
		offset: number,
		min: number,
		max: number,
	): number => {
		if (offset > max) return max;
		if (offset < min) return min;
		return offset;
	};

	private PARAGRAPH_VERTICAL_PADDING = 11;

	private calculateDistanceOffsetBasedOnHeight = (
		paragraphBox: DOMRect,
	): number => {
		const {height} = paragraphBox;
		const max = 30;
		const offset = height * 0.1;
		return this.getNumberWithinRange(
			offset,
			this.PARAGRAPH_VERTICAL_PADDING,
			max,
		);
	};

	private calculateRequiredDistanceFromTopWithoutOffset = (
		scrollablePaneBox: DOMRect,
	): number => {
		const sectionHeaderDist: number =
			this.getRequiredDistanceForSectionHeader();
		const listHeaderDist: number = this.getRequiredDistanceForListHeader();
		return sectionHeaderDist + listHeaderDist + scrollablePaneBox.top;
	};

	private getIfParagraphTopIsBeforeMaxDistFromTop = ({
		scrollablePane,
		paragraph,
	}: Boxes): boolean => {
		/**
		 * So that a paragraph isn't considered visible if only its padding is
		 * showing.
		 */
		const dist: number =
			scrollablePane.bottom - this.PARAGRAPH_VERTICAL_PADDING;
		return paragraph.top < dist;
	};

	private calculateRequiredDistanceFromTop = ({
		paragraph,
		scrollablePane,
	}: Boxes): number => {
		const distance: number =
			this.calculateRequiredDistanceFromTopWithoutOffset(scrollablePane);
		/**
		 * We add an offset so a paragraph is only considered visible if a certain
		 * portion of it is showing.
		 */
		const offset: number = this.calculateDistanceOffsetBasedOnHeight(paragraph);
		return distance + offset;
	};

	private getIfParagraphBottomIsAfterDistanceFromTop = (
		boxes: Boxes,
	): boolean => {
		const requiredDistanceFromTop: number =
			this.calculateRequiredDistanceFromTop(boxes);
		return boxes.paragraph.bottom > requiredDistanceFromTop;
	};

	private getIfParagraphIsVisibleBasedOnBoundingBoxes = (
		boxes: Boxes,
	): boolean => {
		const isBeforeDistanceFromBottom: boolean =
			this.getIfParagraphTopIsBeforeMaxDistFromTop(boxes);

		const isAfterDistanceFromTop: boolean =
			this.getIfParagraphBottomIsAfterDistanceFromTop(boxes);

		return isAfterDistanceFromTop && isBeforeDistanceFromBottom;
	};

	private getCheckIfParagraphIsVisible = (scrollablePane: HTMLElement) => {
		return (paragraph: HTMLElement): boolean => {
			/**
			 * Note that we don't use offsetTop because it could cause the
			 * functionality for checking visibility to break if someone adds a
			 * relative parent element. The bounding box also provides more info.
			 */
			return this.getIfParagraphIsVisibleBasedOnBoundingBoxes({
				scrollablePane: scrollablePane.getBoundingClientRect(),
				paragraph: paragraph.getBoundingClientRect(),
			});
		};
	};

	private queryParagraphs = (
		refToContainerOfMainParagraphs: RefToContainerOfMainParagraphs,
	): HTMLElement[] => {
		const {current} = refToContainerOfMainParagraphs;
		/**
		 * We don't throw an error when "current" is missing because this is
		 * expected; the main paragraphs might not have been rendered yet. This
		 * can happen when we click on the "View PDF" button repeatedly really
		 * quickly.
		 */
		if (!current) return [];
		const elements: NodeListOf<HTMLElement> =
			current.querySelectorAll(ROW_SELECTOR);
		return Array.from(elements);
	};

	public findIndexOfVisibleParagraph = (
		refToContainerOfMainParagraphs: RefToContainerOfMainParagraphs,
	): number => {
		const paragraphs: HTMLElement[] = this.queryParagraphs(
			refToContainerOfMainParagraphs,
		);

		const scrollablePane: HTMLElement | null =
			document.getElementById(idOfScrollablePane);
		if (!scrollablePane) return -1;

		const getIfIsVisible = this.getCheckIfParagraphIsVisible(scrollablePane);
		return paragraphs.findIndex(getIfIsVisible);
	};
}

export const paragraphVisibilityService = new ParagraphVisibilityService();
