import {TFunction} from 'react-i18next';
import {formatDateTime} from 'i18n/localeDateFormat';
import i18n from 'i18n/i18n';
import Excel from 'exceljs';
import {renderHeaderXL, createZIPXL} from './ExcelExportCommon';
import {PogisDocument} from 'types';

interface IPOGISPage {
	(entityListItems: any[], t: TFunction): Promise<void>;
}

export const handleClickPOGISPage: IPOGISPage = async (entityListItems, t) => {
	const arrPOGISFilt = entityListItems as PogisDocument[];

	const strTrNS = 'features/pogisdocuments';
	const strTrPrefix = 'PogisDocumentsList';
	const strPageName = 'POGIS';
	const arrColWidth = [20, 40, 40, 30, 80, 30, 30, 30, 30, 30, 30, 30, 20];
	const workbook = new Excel.Workbook();
	const sheet = workbook.addWorksheet('TRACE_' + strPageName);
	renderHeaderXL(workbook, sheet, arrColWidth, strPageName, t);

	let intRowCount = 4;
	let row = sheet.getRow(intRowCount);
	row.font = {
		bold: true,
		name: 'Porsche Next TT',
		size: 10,
		color: {argb: 'FFFFFFFF'},
	};
	sheet.addTable({
		name: 'tablePOGIS',
		ref: 'B' + String(intRowCount),
		style: {
			showRowStripes: true,
		},
		columns: [
			{
				name: t('pogisId', {ns: strTrNS, keyPrefix: strTrPrefix}),
				filterButton: true,
			},
			{
				name: t('keywords', {ns: strTrNS, keyPrefix: strTrPrefix}),
				filterButton: false,
			},
			{
				name: t('markets', {ns: strTrNS, keyPrefix: strTrPrefix}),
				filterButton: false,
			},
			{
				name: t('regulationStatus', {ns: strTrNS, keyPrefix: strTrPrefix}),
				filterButton: true,
			},
			{
				name: t('shortInfo', {ns: strTrNS, keyPrefix: strTrPrefix}),
				filterButton: false,
			},
			{
				name: t('dates', {ns: strTrNS, keyPrefix: strTrPrefix}),
				filterButton: false,
			},
			{
				name: t('standards', {ns: strTrNS, keyPrefix: strTrPrefix}),
				filterButton: false,
			},
			{
				name: t('associations', {ns: strTrNS, keyPrefix: strTrPrefix}),
				filterButton: false,
			},
			{
				name: t('editor', {ns: strTrNS, keyPrefix: strTrPrefix}),
				filterButton: true,
			},
			{
				name: t('relatedDocuments', {ns: strTrNS, keyPrefix: strTrPrefix}),
				filterButton: false,
			},
			{
				name: t('summaryAttachments', {ns: strTrNS, keyPrefix: strTrPrefix}),
				filterButton: false,
			},
			{
				name: t('otherAttachments', {ns: strTrNS, keyPrefix: strTrPrefix}),
				filterButton: false,
			},
			{
				name: t('docStatus', {ns: strTrNS, keyPrefix: strTrPrefix}),
				filterButton: true,
			},
		],
		rows: [],
	});

	intRowCount++;
	const regex = /POGIS\sSummary\s\d+\.pdf/g;
	const table = sheet.getTable('tablePOGIS');
	for (let ii = 0; ii < arrPOGISFilt.length; ii++) {
		row = sheet.getRow(intRowCount);
		row.alignment = {wrapText: true, vertical: 'top'};
		table.addRow([
			arrPOGISFilt[ii].pogisId,
			arrPOGISFilt[ii].keywords.join(';\n'),
			arrPOGISFilt[ii].markets.join(';\n'),
			arrPOGISFilt[ii].regulationStatus,
			arrPOGISFilt[ii].shortInfo,
			arrPOGISFilt[ii].dates
				.map(
					x =>
						(x.date ? formatDateTime(new Date(x.date), i18n) : '') +
						' (' +
						x.dateType +
						')',
				)
				.join(';\n'),
			arrPOGISFilt[ii].standards.join(';\n'),
			arrPOGISFilt[ii].associations.join(';\n'),
			arrPOGISFilt[ii].editor,
			arrPOGISFilt[ii].relatedDocuments.join(';\n'),
			'',
			'',
			arrPOGISFilt[ii].docStatus,
		]);
		intRowCount++;

		const arrAttach = (arrPOGISFilt[ii].pogisAttachments ?? []).filter(x =>
			x?.file.fileName.match(regex),
		);

		const arrAttachOther = (arrPOGISFilt[ii].pogisAttachments ?? []).filter(
			x => !x?.file.fileName.match(regex),
		);

		arrAttach.forEach((iAttach, nn) => {
			row = sheet.getRow(intRowCount);
			row.outlineLevel = 1;
			row.getCell('B').fill = {
				type: 'pattern',
				pattern: 'solid',
				fgColor: {argb: '8DB4E2'},
			};
			row.getCell('L').style.font = {
				underline: true,
				bold: false,
				color: {argb: '00B0F0'},
			};
			row.getCell('M').style.font = {
				underline: true,
				bold: false,
				color: {argb: '00B0F0'},
			};
			table.addRow([
				'',
				'',
				'',
				'',
				'',
				'',
				'',
				'',
				'',
				'',
				{
					text: iAttach.attachmentId,
					hyperlink: iAttach.file.uri,
					tooltip: t('Link', {
						ns: 'components/EntityList/ExcelExport',
						keyPrefix: 'TooltipsText',
					}),
				},
				nn < arrAttachOther.length
					? {
							text: arrAttachOther[nn].attachmentId,
							hyperlink: arrAttachOther[nn].file.uri,
							tooltip: t('Link', {
								ns: 'components/EntityList/ExcelExport',
								keyPrefix: 'TooltipsText',
							}),
					  }
					: '',
				'',
			]);
			intRowCount++;
		});

		arrAttachOther.forEach((iAttach, nn) => {
			if (nn >= arrAttach.length) {
				row = sheet.getRow(intRowCount);
				row.outlineLevel = 1;
				row.getCell('B').fill = {
					type: 'pattern',
					pattern: 'solid',
					fgColor: {argb: '8DB4E2'},
				};
				row.getCell('M').style.font = {
					underline: true,
					bold: false,
					color: {argb: '00B0F0'},
				};
				table.addRow([
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					{
						text: iAttach.attachmentId,
						hyperlink: iAttach.file.uri,
						tooltip: t('Link', {
							ns: 'components/EntityList/ExcelExport',
							keyPrefix: 'TooltipsText',
						}),
					},
					'',
				]);
				intRowCount++;
			}
		});
	}

	table.commit();
	const strZipName = strPageName;
	await createZIPXL(workbook, strZipName, t);
};
