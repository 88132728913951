import React from 'react';
import {Label, mergeStyleSets} from '@fluentui/react';
import {CellWithEntityLink} from 'components/EntityList/CellWithEntityLink';
import {Regulation} from 'types';
import {createHrefFromRegulation} from './regulationsListLinks.utils';

export function RegulationNumberCell(item: Regulation): JSX.Element {
	const classNames = getClassNames();

	const detailsPageHref: string = createHrefFromRegulation(item);
	return (
		<CellWithEntityLink href={detailsPageHref}>
			<Label className={classNames.labels}>{item.regulationNumber}</Label>
		</CellWithEntityLink>
	);
}

const getClassNames = () =>
	mergeStyleSets({
		labels: {
			padding: 0,
			fontSize: 16,
			color: 'inherit',
		},
	});
