import React, {
	Dispatch,
	SetStateAction,
	createContext,
	useMemo,
	useState,
} from 'react';

export interface EditParagraphsFormSubmissionStatusInfo {
	/**
	 * Unlike the useForm hook, this keeps track of all submission processes, and
	 * not just whether the mutation has finished. For example, this keeps track
	 * of whether the form's mutations, refetching, etc, have finished.
	 *
	 * We need this to disable certain cmds while the optimistic data is still
	 * showing. In some cases, we must do this to prevent the server from using
	 * outdated data if the EditParagraphsForm's requests are still in progress.
	 * If the server used outdated data, it could cause unforeseen issues because
	 * there would be a mismatch between the data the user sees and the actual
	 * data in the database.
	 *
	 * In other cases, we must disable commands to prevent the
	 * EditParagraphsForm's refetch from overwriting another's form refetch, and
	 * vice versa. This can happen if the EditParagraphsForm takes a while to
	 * submit its requests, the user opens another form, and then submits it. The
	 * other form could start refetching its data as the EditParagraphsForm is
	 * submitting its attachments. The EditParagraphsForm would show optimistic
	 * data for the attachments, but the other form would overwrite this data with
	 * stale data. Other issues can also occur. To prevent this, we disable those commands.
	 */
	isSubmitting: boolean;
	setSubmissionStarted: () => void;
	setSubmissionFinished: () => void;
}

export type EditParagraphsFormSubmissionStatusContextValue =
	EditParagraphsFormSubmissionStatusInfo | null;

export const EditParagraphsFormSubmissionStatusContext =
	createContext<EditParagraphsFormSubmissionStatusContextValue>(null);

interface Props {
	children: React.ReactNode;
}

export const EditParagraphsFormSubmissionStatusProvider = ({
	children,
}: Props): JSX.Element => {
	type SetIsSubmitting = Dispatch<SetStateAction<boolean>>;

	type StateValue = [boolean, SetIsSubmitting];

	const [isSubmitting, setIsSubmitting]: StateValue = useState<boolean>(false);

	const setSubmissionStarted = (): void => {
		setIsSubmitting(true);
	};

	const setSubmissionFinished = (): void => {
		setIsSubmitting(false);
	};

	const getContextValue = (): EditParagraphsFormSubmissionStatusInfo => {
		return {isSubmitting, setSubmissionStarted, setSubmissionFinished};
	};

	const value: EditParagraphsFormSubmissionStatusInfo = useMemo(
		getContextValue,
		[isSubmitting],
	);

	return (
		<EditParagraphsFormSubmissionStatusContext.Provider value={value}>
			{children}
		</EditParagraphsFormSubmissionStatusContext.Provider>
	);
};
