import {mergeStyleSets} from '@fluentui/merge-styles';
import React, {useMemo} from 'react';

interface Props {
	children: React.ReactNode;
	className?: string;
}

/**
 * This is beneficial so that we don't forget to set the CSS styles and the
 * contentEditable attribute on elements that should not be selectable.
 * Otherwise, text selection will not work correctly. See
 * https://github.com/ianstormtaylor/slate/issues/3421 for more info.
 *
 * * Use Cases:
 *
 * - We use it to prevent the context menu from showing if a user uses their
 *   cursor to select text backwards until they reach a checkbox. Otherwise, the
 *   user would not be able to delete text.
 */
export function NonSelectable({children, className}: Props): JSX.Element {
	const styles = useMemo(() => {
		return mergeStyleSets({
			root: {userSelect: 'none'},
		});
	}, []);

	const getClassName = (): string => {
		if (className) {
			return `${styles.root} ${className}`;
		}

		return styles.root;
	};

	return (
		<div contentEditable={false} className={getClassName()}>
			{children}
		</div>
	);
}
