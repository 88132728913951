import {PartialTheme} from '@fluentui/react';

export const themes: PartialTheme = {
	palette: {
		themePrimary: '#c42929',
		themeLighterAlt: '#fdf5f5',
		themeLighter: '#f6d7d7',
		themeLight: '#edb5b5',
		themeTertiary: '#dc7474',
		themeSecondary: '#cb3e3e',
		themeDarkAlt: '#b12525',
		themeDark: '#951f1f',
		themeDarker: '#6e1717',
		neutralLighterAlt: '#faf9f8',
		neutralLighter: '#f3f2f1',
		neutralLight: '#edebe9',
		neutralQuaternaryAlt: '#e1dfdd',
		neutralQuaternary: '#d0d0d0',
		neutralTertiaryAlt: '#c8c6c4',
		neutralTertiary: '#a19f9d',
		neutralSecondary: '#605e5c',
		neutralPrimaryAlt: '#3b3a39',
		neutralPrimary: '#323130',
		neutralDark: '#201f1e',
		black: '#000000',
		white: '#ffffff',
		greenLight: '#5FD255',
		blueLight: '#EFF6FC',
		blue: '#0078D4',
		yellow: '#FFC80A',
	},
};
