import _ from 'lodash';
import {
	SimplifiedFieldValue,
	SimplifiedFormFields,
	SimplifiedParagraphFormFieldsService,
} from '../SimplifiedParagraphFormFields.service';
import {QueryParagraph} from 'features/RegulatoryDocuments/RegDocDetailsPage/RegDocDetailsPage.queryTypes';
import {SanitizedFormFields} from '../../EditParagraphsForm.types';
import {
	NameOfMergeableArrayField,
	namesOfMergeableArrayFields,
} from './mergeableArrayFields';
import {
	CommonOptimisticParagraphFields,
	ParagraphOptimisticDataUtils,
} from '../ParagraphOptimisticData.utils';
import {KeywordAssignmentsCreatorService} from './KeywordAssignmentsCreator.service';
import {
	ParagraphSanitizationService,
	CommonSanitizedParagraphFields,
} from './ParagraphSanitization.service';
import {
	EditParagraphsFormDefaultValues,
	initialRegulatoryDocumentParagraph,
} from '../../editParagraphsForm.constants';
import {OptimisticParagraph} from './optimisticParagraph.types';

/*
 * * Service's fields
 */
type SimplifiedFieldKey = keyof SimplifiedFormFields;

type ClearedFields = SimplifiedFieldKey[];

export interface ParagraphOptimisticDataServiceFields {
	sanitizedFormFields: SanitizedFormFields;
	paragraphId: QueryParagraph['id'];
	selectedParagraphs: QueryParagraph[];
	clearedFields: ClearedFields;
}

/*
 * * Picked form fields
 */
export const fieldNamesToPickFromFormFields = [
	'comprehensive',
	'dateNewRegistration',
	'dateNewTypes',
	'modelYear',
	'summary',
	'phaseIn',
	'phaseOut',
] as const;

export type NameOfPickedFormField =
	(typeof fieldNamesToPickFromFormFields)[number];

type PickedFormFields = Pick<SimplifiedFormFields, NameOfPickedFormField>;

type PickedValue = PickedFormFields[NameOfPickedFormField];

type PickedFieldPair = [NameOfPickedFormField, PickedValue];

/*
 * * Other
 */
export type MergeableArrayPair<Key extends NameOfMergeableArrayField> = [
	Key,
	OptimisticParagraph[NameOfMergeableArrayField],
];

export type OptimisticFieldsForMergedArrays = Pick<
	OptimisticParagraph,
	NameOfMergeableArrayField
>;

type FormFieldsOptimisticData = Omit<
	OptimisticParagraph,
	'id' | '__typename' | 'keywordAssignments'
>;

type PartialFormFields = Partial<SimplifiedFormFields>;

interface FieldsToGetSelectedParagraph {
	selectedParagraphs: QueryParagraph[];
	paragraphId: QueryParagraph['id'];
}

interface FieldsToGetOldParagraph extends FieldsToGetSelectedParagraph {
	clearedFields: ClearedFields;
}

interface FieldsToUpdateParagraphToReflectClearedFields {
	paragraph: QueryParagraph;
	clearedFields: ClearedFields;
}

type DefaultClearedFields =
	CommonSanitizedParagraphFields<EditParagraphsFormDefaultValues>;

interface ArrayToMerge {
	id: string;
}

export class UpdatedOptimisticParagraphService {
	private utils = new ParagraphOptimisticDataUtils();
	private simplifiedService = new SimplifiedParagraphFormFieldsService();
	private paragraphSanitizationService = new ParagraphSanitizationService();

	private simplifiedFormFields: SimplifiedFormFields;
	private isEditingInBulk: boolean;
	private oldParagraph: QueryParagraph;

	private simplifyFormFields = (
		formFields: SanitizedFormFields,
	): SimplifiedFormFields => {
		return this.simplifiedService.simplifyFormFields(formFields);
	};

	private getSelectedParagraph = ({
		selectedParagraphs,
		paragraphId,
	}: FieldsToGetSelectedParagraph): QueryParagraph => {
		return this.utils.getSelectedParagraphById(selectedParagraphs, paragraphId);
	};

	private getDefaultFields = (): DefaultClearedFields => {
		return this.paragraphSanitizationService.sanitizeNonDateParagraphFields(
			initialRegulatoryDocumentParagraph,
		);
	};

	/**
	 * Implementation notes:
	 *
	 * The app does not yet create optimistic data for cleared fields'
	 * mutations. So, the paragraphs' values will be outdated for cleared fields
	 * whenever the user clears a field and then submits the form. If the
	 * paragraphs do not have the correct values for the cleared fields, then we
	 * cannot merge the form fields with those outdated paragraphs' fields.
	 * Therefore, we must instead use the cleared fields' form values. This is
	 * why we pick the cleared fields. We might be able to remove this part of
	 * the code if we generate optimistic data for cleared fields.
	 */
	private getDefaultsForClearedFields = (
		clearedFields: ClearedFields,
	): Partial<DefaultClearedFields> => {
		const defaultFields: DefaultClearedFields = this.getDefaultFields();
		return _.pick(defaultFields, clearedFields);
	};

	private updateParagraphWithDefaultsForClearedFields = ({
		clearedFields,
		paragraph,
	}: FieldsToUpdateParagraphToReflectClearedFields): QueryParagraph => {
		const clearedFieldValues: Partial<DefaultClearedFields> =
			this.getDefaultsForClearedFields(clearedFields);
		return {...paragraph, ...clearedFieldValues};
	};

	private updateParagraphToReflectClearedKeywordAssignmentsIfNecessary = (
		fields: FieldsToUpdateParagraphToReflectClearedFields,
	): QueryParagraph => {
		/**
		 * We do this because the server clears the keyword assignments when the
		 * keywords are cleared.
		 */
		if (fields.clearedFields.includes('keywords')) {
			return {...fields.paragraph, keywordAssignments: []};
		}

		return fields.paragraph;
	};

	private updateParagraphToReflectClearedFields = (
		fields: FieldsToUpdateParagraphToReflectClearedFields,
	): QueryParagraph => {
		const paragraphWithDefaultsForClearedFields: QueryParagraph =
			this.updateParagraphWithDefaultsForClearedFields(fields);
		return this.updateParagraphToReflectClearedKeywordAssignmentsIfNecessary({
			...fields,
			paragraph: paragraphWithDefaultsForClearedFields,
		});
	};

	private getOldParagraph = ({
		clearedFields,
		...other
	}: FieldsToGetOldParagraph): QueryParagraph => {
		const paragraph: QueryParagraph = this.getSelectedParagraph(other);
		return this.updateParagraphToReflectClearedFields({
			clearedFields,
			paragraph,
		});
	};

	constructor({
		paragraphId,
		selectedParagraphs,
		sanitizedFormFields,
		clearedFields,
	}: ParagraphOptimisticDataServiceFields) {
		this.simplifiedFormFields = this.simplifyFormFields(sanitizedFormFields);
		this.isEditingInBulk = selectedParagraphs.length > 1;
		this.oldParagraph = this.getOldParagraph({
			paragraphId,
			selectedParagraphs,
			clearedFields,
		});
	}

	private pickIdFromObject = ({id}: ArrayToMerge): ArrayToMerge['id'] => {
		return id;
	};

	public mergeArraysWithoutDuplicates = <Item extends ArrayToMerge>(
		array1: Item[],
		array2: Item[],
	): Item[] => {
		const mergedValues: Item[] = [...array1, ...array2];
		/**
		 * We use uniqBy instead of uniq because the ID is the only reliable way
		 * of comparing them. This is because an existing paragraph's array can
		 * have different properties from an array created by the tag picker. If
		 * we used uniq to remove duplicates, we could fail to remove certain
		 * duplicates because of this.
		 */
		return _.uniqBy<Item>(mergedValues, this.pickIdFromObject);
	};

	private createFieldsForMergeableArrays =
		(): OptimisticFieldsForMergedArrays => {
			const merge = this.mergeArraysWithoutDuplicates;

			const old = this.oldParagraph;
			const fields = this.simplifiedFormFields;

			/**
			 * Note that this is probably the only way to create these fields while
			 * maintaining type safety. Type safety is especially important here
			 * because if either the old or new array did not match the optimistic
			 * paragraph field's type, then the application would throw an error.
			 *
			 * Suppose we tried refactoring this code by creating a function that
			 * retrieved the old and new field value and merged them. Then, suppose we
			 * used that function to create each of the mergeable array fields'
			 * values. In this case, TypeScript would not know that old and new
			 * values' types are equal. So, it would throw a type error saying that we
			 * cannot merge the old and new arrays. To fix it, we would need to assert
			 * both values' types, which means we wouldn't have type-safety.
			 *
			 * We would not be able to fix the type error with a generic, either. Note
			 * that using a generic for the key would produce a type error because
			 * TypeScript would still not know that the old paragraph field's type
			 * equals the new paragraph field's type. For example it wouldn't know
			 * that the type of this.oldParagraph.keywords is the type of
			 * this.simplifiedFormFields.keywords.
			 */
			return {
				keywords: merge(old.keywords, fields.keywords),
				categories: merge(old.categories, fields.categories),
				driveVariants: merge(old.driveVariants, fields.driveVariants),
				tags: merge(old.tags, fields.tags),
				vehicleCategories: merge(
					old.vehicleCategories,
					fields.vehicleCategories,
				),
			};
		};

	/**
	 * Note that in bulk edit mode, certain array fields will get merged with each
	 * paragraph's existing corresponding value. However, this does not happen
	 * when editing single paragraphs. So, we must mimic this behavior when
	 * creating the optimistic data.
	 */
	private createFieldsForPossiblyMergeableArrays =
		(): OptimisticFieldsForMergedArrays => {
			if (this.isEditingInBulk) return this.createFieldsForMergeableArrays();
			return _.pick(this.simplifiedFormFields, namesOfMergeableArrayFields);
		};

	/**
	 * Note that we must pick these fields instead of using the simplified form
	 * fields directly because they could include additional fields that we don't
	 * want to overwrite.
	 */
	private pickOptimisticDataFieldsFromFormFields = (): PartialFormFields => {
		return _.pick(this.simplifiedFormFields, fieldNamesToPickFromFormFields);
	};

	/**
	 * Note that we must pick these fields instead of using the simplified form
	 * fields directly because they could include additional fields that we don't
	 * want to overwrite.
	 */
	private pickOptimisticDataFieldsFromFormFieldsAndSetCorrectType =
		(): PickedFormFields => {
			const fields: PartialFormFields =
				this.pickOptimisticDataFieldsFromFormFields();
			return fields as PickedFormFields;
		};

	private sanitizeOldParagraph = (): OptimisticParagraph => {
		return this.paragraphSanitizationService.sanitizeParagraphLikeObject(
			this.oldParagraph,
		);
	};

	private getSanitizedOldParagraphValue = (
		key: NameOfPickedFormField,
	): PickedValue => {
		const sanitizedParagraph: OptimisticParagraph = this.sanitizeOldParagraph();
		return sanitizedParagraph[key];
	};

	private getIfShouldUseOldParagraphValue = (
		key: SimplifiedFieldKey,
		newValue: SimplifiedFieldValue,
	): boolean => {
		/**
		 * We must use this condition to avoid updating phase fields because the
		 * form does not update them in these conditions.
		 */
		const isEmptyArray: boolean = Array.isArray(newValue) && !newValue.length;
		return isEmptyArray || !newValue;
	};

	private getOldOrNewParagraphValue = (
		key: NameOfPickedFormField,
	): PickedValue => {
		const newValue: PickedValue = this.simplifiedFormFields[key];
		const shouldUseOld: boolean = this.getIfShouldUseOldParagraphValue(
			key,
			newValue,
		);
		if (shouldUseOld) return this.getSanitizedOldParagraphValue(key);
		return newValue;
	};

	private getKeyAndParagraphValuePair = (
		key: NameOfPickedFormField,
	): PickedFieldPair => {
		const value: PickedValue = this.getOldOrNewParagraphValue(key);
		return [key, value];
	};

	private createPairsOfPickedFields = (): PickedFieldPair[] => {
		return fieldNamesToPickFromFormFields.map(this.getKeyAndParagraphValuePair);
	};

	private mergeFormFieldsWithParagraphFields = () => {
		const pairs: PickedFieldPair[] = this.createPairsOfPickedFields();
		const pickedFormFields: PartialFormFields = _.fromPairs(pairs);
		return pickedFormFields as PickedFormFields;
	};

	private getOptimisticDataFieldsFromFormFields = (): PickedFormFields => {
		if (this.isEditingInBulk) return this.mergeFormFieldsWithParagraphFields();
		return this.pickOptimisticDataFieldsFromFormFieldsAndSetCorrectType();
	};

	/**
	 * @see {namesOfMergeableArrayFields} to learn more.
	 */
	private createFormFieldsOptimisticData = (): FormFieldsOptimisticData => {
		const pickedFields: PickedFormFields =
			this.getOptimisticDataFieldsFromFormFields();
		const arrayFields: OptimisticFieldsForMergedArrays =
			this.createFieldsForPossiblyMergeableArrays();
		return {...arrayFields, ...pickedFields};
	};

	private createKeywordAssignments =
		(): OptimisticParagraph['keywordAssignments'] => {
			return KeywordAssignmentsCreatorService.createKeywordAssignments({
				oldParagraph: this.oldParagraph,
				formFields: this.simplifiedFormFields,
				isBulkEditMode: this.isEditingInBulk,
			});
		};

	/**
	 * Implementation notes:
	 *
	 * This shouldn't return all fields because Apollo will merge it with the
	 * paragraph's existing fields. The form fields might also include more fields
	 * than those that we edited, which could have unforeseen consequences if we
	 * try to write them to the cache. Even if it didn't cause a problem yet, it
	 * could cause an issue if a developer starts modifying the query. For
	 * example, if a developer removed a tag's ID from the page, query, this would
	 * throw an error if we returned all of the form fields here.
	 */
	public createOptimisticParagraph = (): OptimisticParagraph => {
		const commonFields: CommonOptimisticParagraphFields =
			this.utils.createCommonParagraphFields(this.oldParagraph);
		const formFieldsOptimisticData: FormFieldsOptimisticData =
			this.createFormFieldsOptimisticData();
		return {
			...commonFields,
			...formFieldsOptimisticData,
			keywordAssignments: this.createKeywordAssignments(),
		};
	};
}
