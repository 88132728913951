import {Command, useCommand} from 'hooks';
import {useTranslation} from 'react-i18next';
import {StateForShouldShowAudit} from './useShouldShowAudit';

export const useToggleAuditCmd = (
	[showAudit, setShowAudit]: StateForShouldShowAudit,
	extraCmdFields: Partial<Command> = {},
): void => {
	const {t} = useTranslation('hooks/useToggleAuditCmd');
	useCommand(
		{
			key: 'toggleAudit',
			iconProps: {
				iconName: 'History',
			},
			farCommand: true,
			priority: 4,
			onClick() {
				setShowAudit(!showAudit);
			},
			title: t('AuditBtnLabel'),
			...extraCmdFields,
		},
		[showAudit],
	);
};
