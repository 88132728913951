import {RefetchQueriesInclude, useApolloClient} from '@apollo/client';
import {
	DefaultButton,
	IPanelProps,
	IRenderFunction,
	Panel,
	PanelType,
	PrimaryButton,
	Separator,
	Stack,
} from '@fluentui/react';
import {ConfirmUpdateDialog} from 'components/Dialogs';
import {useFileUpload} from 'hooks';
import React from 'react';
import {Control, UseFormSetValue} from 'react-hook-form';
import {AttachmentInput} from 'types';
import {FormMode} from './EntityPage.types';
import {
	StateForEntityFormPanels,
	useEntityPageTranslation,
} from './hooksForEntityFormPanels';
import {AttachmentsField} from 'components/AttachmentsField';
import {EntityNameField} from 'components/EntityNameField';
import {EntityOfFormPanels} from './EntityFormPanels.types';

export interface IIdentifiable {
	id: string;
}

/**
 * ! Important
 *
 * The type of the parameter here is inaccurate. It can actually be anything
 * because the component supports rendering custom form fields. So, the form fields
 * don't even have to be related at all to the entity.
 */
type CreateEntity<T extends EntityOfFormPanels> = (
	item: T,
) =>
	| IIdentifiable
	| Promise<IIdentifiable | null | undefined | void>
	| null
	| undefined
	| void;

export type RenderAdditionalFormElements<Entity extends EntityOfFormPanels> = (
	control: Control<Entity, any>,
	mode: FormMode,
	selectedItem: Entity | undefined,
	setValue: UseFormSetValue<Entity>,
) => JSX.Element;

/**
 * Note that we might create other types based on this type. Those types might
 * seem redundant if they are identical to this one. However, we should not
 * delete those other types because they might change in the future.
 */
type GetTxtFromFormMode = (mode: FormMode) => string;

export type CreateHeaderText = GetTxtFromFormMode;

export type CreateTxtOfSaveBtn = GetTxtFromFormMode;

interface PropsOfFormPanel extends Pick<IPanelProps, 'isFooterAtBottom'> {
	createHeaderText?: CreateHeaderText;
	createTxtOfSaveBtn?: CreateTxtOfSaveBtn;
}

export interface EntityFormPanelsProps<Entity extends EntityOfFormPanels>
	extends StateForEntityFormPanels<Entity> {
	entity: Entity | undefined;
	entityDisplayName: string;
	createEntity: CreateEntity<Entity> | null;
	updateEntity: (item: Entity, oldItem?: Entity) => any;
	/**
	 * ! Important
	 *
	 * You will probably need to provide tooltip text for the attachments field to
	 * use this. See this component's definition to learn which key you must add
	 * to your translation files.
	 *
	 * Providing this callback will also render a File input
	 */
	updateEntityAttachments?: (
		entityId: string,
		attachments: AttachmentInput[],
	) => Promise<any>;
	renderAdditionalFormElements?: RenderAdditionalFormElements<Entity>;
	panelType?: PanelType;
	confirmUpdate?: boolean;
	/**
	 * ! Important
	 *
	 * If you are using mutations, do not rely on this. Instead, the docs
	 * (https://www.apollographql.com/docs/react/data/refetching/#corresponding-clientmutate-options)
	 * say that we must use the mutation options to refetch the queries.
	 */
	refetchQueries?: RefetchQueriesInclude;
	withNameColumn?: boolean;
	/**
	 * The partial entity header text. This will be used in the template for the
	 * translation.
	 */
	entityHeaderText?: string;
	performExtraTaskOnCancellation?: () => void;
	/**
	 * We accept this function because the form state's "isSubmitting" does not
	 * update when there is an error.
	 */
	setIsSubmittingOrRefetching?: (isLoading: boolean) => void;
	propsOfFormPanel?: PropsOfFormPanel;
}

export function EntityFormPanels<Entity extends EntityOfFormPanels>(
	props: EntityFormPanelsProps<Entity>,
) {
	const {t} = useEntityPageTranslation();
	const {
		entityDisplayName,
		createEntity,
		updateEntity,
		updateEntityAttachments,
		renderAdditionalFormElements = () => null,
		confirmUpdate = false,
		refetchQueries = [],
		withNameColumn = true,
		entityHeaderText,
		entity,
		performExtraTaskOnCancellation,
		formInfo: {handleSubmit, control, setValue},
		infoOfUpdateDialog: [updateDialogState, showUpdateDialog, hideUpdateDialog],
		modeInfo: [mode, setMode],
		setIsSubmittingOrRefetching,
		propsOfFormPanel = {},
	} = props;
	const apolloClient = useApolloClient();

	const {attachments, FileUploadComponent} = useFileUpload(entity);

	const submit = async (): Promise<void> => {
		await handleSubmit(async data => {
			switch (mode) {
				case FormMode.Create:
					if (!createEntity) break;
					await Promise.resolve(createEntity(data)).then(async entity => {
						if (entity && attachments) {
							await updateEntityAttachments?.(
								entity.id,
								attachments.map(f => ({
									attachmentId: f.file.name,
									file: f.file,
									categoryId: f.attachmentCategoryId,
									isVkoOnly: f.isVkoOnly,
								})),
							);
						}

						await apolloClient.refetchQueries({include: refetchQueries});
					});
					break;
				case FormMode.Update:
					await Promise.resolve(updateEntity(data, entity)).then(async () => {
						if (entity && attachments) {
							await updateEntityAttachments?.(
								entity.id,
								attachments.map(f => ({
									attachmentId: f.file.name,
									file: f.file,
									categoryId: f.attachmentCategoryId,
									isVkoOnly: f.isVkoOnly,
								})),
							);
						}

						await apolloClient.refetchQueries({include: refetchQueries});
					});
					break;
				default:
			}

			setMode(FormMode.None);
		})();
	};

	const submitAndSetLoadingToFalse = async (): Promise<void> => {
		try {
			await submit();
		} finally {
			setIsSubmittingOrRefetching?.(false);
		}
	};

	const setLoadingToTrueAndSubmit = async (): Promise<void> => {
		setIsSubmittingOrRefetching?.(true);
		await submitAndSetLoadingToFalse();
	};

	const handleSaveClick = React.useCallback(
		(_ev: any, confirmed: boolean | undefined = false) => {
			if (confirmUpdate && !confirmed && mode === FormMode.Update) {
				showUpdateDialog();

				return;
			}

			setLoadingToTrueAndSubmit();
		},
		[mode, attachments, entity, apolloClient],
	);

	const onUpdateConfirmed = React.useCallback(() => {
		handleSaveClick(undefined, true);
		hideUpdateDialog();
	}, [handleSaveClick, mode]);

	const handleCancelClick = React.useCallback(() => {
		setMode(FormMode.None);

		performExtraTaskOnCancellation?.();
	}, [performExtraTaskOnCancellation]);

	const {createHeaderText, createTxtOfSaveBtn, ...other} = propsOfFormPanel;

	const onRenderFooterContent: IRenderFunction<IPanelProps> | undefined =
		React.useCallback(() => {
			const getSaveBtnTxt = (): string => {
				return createTxtOfSaveBtn ? createTxtOfSaveBtn(mode) : t('Save');
			};

			return (
				<form>
					<Stack horizontal tokens={{childrenGap: 8}}>
						<PrimaryButton onClick={handleSaveClick}>
							{getSaveBtnTxt()}
						</PrimaryButton>
						<DefaultButton onClick={handleCancelClick}>
							{t('Cancel')}
						</DefaultButton>
					</Stack>
				</form>
			);
		}, [mode, handleSaveClick, handleCancelClick, createTxtOfSaveBtn, t]);

	const getHeaderText = React.useCallback(() => {
		if (createHeaderText) return createHeaderText(mode);
		if (
			(entity as any)?.__typename === 'Requirement' &&
			(entity as any)?.status === 'FINAL'
		) {
			return entityHeaderText
				? t('EditEntityHeader', {entityHeaderText})
				: t('EditEntity', {entityDisplayName});
		}

		switch (mode) {
			case FormMode.Create:
				return entityHeaderText
					? t('CreateEntityHeader', {entityHeaderText})
					: t('CreateEntity', {entityDisplayName});
			case FormMode.Update:
				return entityHeaderText
					? t('EditEntityHeader', {entityHeaderText})
					: t('EditEntity', {entityDisplayName});

			default:
				return '';
		}
	}, [mode, createHeaderText]);

	/**
	 * We don't enable tooltips here using a provider because we don't want to
	 * accidentally enable tooltips for the additional form elements, which could
	 * content that does not need tooltips.
	 */
	return (
		<>
			<Panel
				isLightDismiss
				isOpen={mode !== FormMode.None}
				onDismiss={handleCancelClick}
				headerText={getHeaderText()}
				onRenderFooterContent={onRenderFooterContent}
				type={props.panelType ?? PanelType.smallFixedFar}
				onOuterClick={() => undefined}
				{...other}
			>
				{withNameColumn && (
					<EntityNameField
						labelTranslationKey='NameFieldLabel'
						control={control}
						translationKeyOfRequiredMsg='NameFieldRequired'
						t={t}
					/>
				)}
				{renderAdditionalFormElements(control, mode, entity, setValue)}
				{updateEntityAttachments && (
					<>
						<Separator />
						<AttachmentsField t={t} FileUploadComponent={FileUploadComponent} />
					</>
				)}
			</Panel>

			<ConfirmUpdateDialog
				{...updateDialogState}
				onConfirm={onUpdateConfirmed}
			/>
		</>
	);
}
