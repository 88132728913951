import React from 'react';
import {FileUploadInfo} from 'hooks';
import {TFunction} from 'react-i18next';
import {LabelWithTooltip} from 'features/localizedTooltips';

interface Props extends Pick<FileUploadInfo, 'FileUploadComponent'> {
	t: TFunction<string>;
}

/**
 * ! Important
 *
 * This requires using a tooltip translation provider. See below for more info.
 * Also, see below to learn which translation keys are required.
 */
export function AttachmentsField({t, FileUploadComponent}: Props) {
	return (
		<>
			<LabelWithTooltip
				translationKey='attachments'
				tooltipHostProps={{shouldGetTextFromContext: true}}
			>
				{t('AttachmentsFieldLabel')}
			</LabelWithTooltip>
			<FileUploadComponent />
		</>
	);
}
