import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllVehicleCategoriesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllVehicleCategoriesQuery = { __typename?: 'Query', vehicleCategories?: Array<{ __typename?: 'VehicleCategory', id: string, name: string, createdAt?: any | null, modifiedAt?: any | null, createdBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null }, modifiedBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null } }> | null };

export type CreateVehicleCategoryMutationVariables = Types.Exact<{
  input: Types.CreateVehicleCategoryInput;
}>;


export type CreateVehicleCategoryMutation = { __typename?: 'Mutation', createVehicleCategory: { __typename?: 'CreateVehicleCategoryPayload', vehicleCategory?: { __typename?: 'VehicleCategory', id: string } | null } };

export type UpdateVehicleCategoryMutationVariables = Types.Exact<{
  input: Types.UpdateVehicleCategoryInput;
}>;


export type UpdateVehicleCategoryMutation = { __typename?: 'Mutation', updateVehicleCategory: { __typename?: 'UpdateVehicleCategoryPayload', vehicleCategory?: { __typename?: 'VehicleCategory', id: string } | null } };

export type DeleteVehicleCategoryMutationVariables = Types.Exact<{
  input: Types.DeleteVehicleCategoryInput;
}>;


export type DeleteVehicleCategoryMutation = { __typename?: 'Mutation', deleteVehicleCategory: { __typename?: 'DeleteVehicleCategoryPayload', vehicleCategory?: { __typename?: 'VehicleCategory', id: string } | null } };


export const GetAllVehicleCategoriesDocument = gql`
    query GetAllVehicleCategories {
  vehicleCategories {
    id
    name
    createdAt
    createdBy {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
    modifiedAt
    modifiedBy {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
  }
}
    `;

/**
 * __useGetAllVehicleCategoriesQuery__
 *
 * To run a query within a React component, call `useGetAllVehicleCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllVehicleCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllVehicleCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllVehicleCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllVehicleCategoriesQuery, GetAllVehicleCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllVehicleCategoriesQuery, GetAllVehicleCategoriesQueryVariables>(GetAllVehicleCategoriesDocument, options);
      }
export function useGetAllVehicleCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllVehicleCategoriesQuery, GetAllVehicleCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllVehicleCategoriesQuery, GetAllVehicleCategoriesQueryVariables>(GetAllVehicleCategoriesDocument, options);
        }
export type GetAllVehicleCategoriesQueryHookResult = ReturnType<typeof useGetAllVehicleCategoriesQuery>;
export type GetAllVehicleCategoriesLazyQueryHookResult = ReturnType<typeof useGetAllVehicleCategoriesLazyQuery>;
export type GetAllVehicleCategoriesQueryResult = Apollo.QueryResult<GetAllVehicleCategoriesQuery, GetAllVehicleCategoriesQueryVariables>;
export const CreateVehicleCategoryDocument = gql`
    mutation CreateVehicleCategory($input: CreateVehicleCategoryInput!) {
  createVehicleCategory(input: $input) {
    vehicleCategory {
      id
    }
  }
}
    `;
export type CreateVehicleCategoryMutationFn = Apollo.MutationFunction<CreateVehicleCategoryMutation, CreateVehicleCategoryMutationVariables>;

/**
 * __useCreateVehicleCategoryMutation__
 *
 * To run a mutation, you first call `useCreateVehicleCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVehicleCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVehicleCategoryMutation, { data, loading, error }] = useCreateVehicleCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVehicleCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateVehicleCategoryMutation, CreateVehicleCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVehicleCategoryMutation, CreateVehicleCategoryMutationVariables>(CreateVehicleCategoryDocument, options);
      }
export type CreateVehicleCategoryMutationHookResult = ReturnType<typeof useCreateVehicleCategoryMutation>;
export type CreateVehicleCategoryMutationResult = Apollo.MutationResult<CreateVehicleCategoryMutation>;
export type CreateVehicleCategoryMutationOptions = Apollo.BaseMutationOptions<CreateVehicleCategoryMutation, CreateVehicleCategoryMutationVariables>;
export const UpdateVehicleCategoryDocument = gql`
    mutation UpdateVehicleCategory($input: UpdateVehicleCategoryInput!) {
  updateVehicleCategory(input: $input) {
    vehicleCategory {
      id
    }
  }
}
    `;
export type UpdateVehicleCategoryMutationFn = Apollo.MutationFunction<UpdateVehicleCategoryMutation, UpdateVehicleCategoryMutationVariables>;

/**
 * __useUpdateVehicleCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateVehicleCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVehicleCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVehicleCategoryMutation, { data, loading, error }] = useUpdateVehicleCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVehicleCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVehicleCategoryMutation, UpdateVehicleCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVehicleCategoryMutation, UpdateVehicleCategoryMutationVariables>(UpdateVehicleCategoryDocument, options);
      }
export type UpdateVehicleCategoryMutationHookResult = ReturnType<typeof useUpdateVehicleCategoryMutation>;
export type UpdateVehicleCategoryMutationResult = Apollo.MutationResult<UpdateVehicleCategoryMutation>;
export type UpdateVehicleCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateVehicleCategoryMutation, UpdateVehicleCategoryMutationVariables>;
export const DeleteVehicleCategoryDocument = gql`
    mutation DeleteVehicleCategory($input: DeleteVehicleCategoryInput!) {
  deleteVehicleCategory(input: $input) {
    vehicleCategory {
      id
    }
  }
}
    `;
export type DeleteVehicleCategoryMutationFn = Apollo.MutationFunction<DeleteVehicleCategoryMutation, DeleteVehicleCategoryMutationVariables>;

/**
 * __useDeleteVehicleCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteVehicleCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVehicleCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVehicleCategoryMutation, { data, loading, error }] = useDeleteVehicleCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteVehicleCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVehicleCategoryMutation, DeleteVehicleCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVehicleCategoryMutation, DeleteVehicleCategoryMutationVariables>(DeleteVehicleCategoryDocument, options);
      }
export type DeleteVehicleCategoryMutationHookResult = ReturnType<typeof useDeleteVehicleCategoryMutation>;
export type DeleteVehicleCategoryMutationResult = Apollo.MutationResult<DeleteVehicleCategoryMutation>;
export type DeleteVehicleCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteVehicleCategoryMutation, DeleteVehicleCategoryMutationVariables>;