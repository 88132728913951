import React from 'react';
import {useTranslation} from 'react-i18next';
import {
	GetAllPcmsClustersDocument,
	useCreatePcmsClusterMutation,
	useDeletePcmsClusterMutation,
	useGetAllPcmsClustersQuery,
	useUpdatePcmsClusterMutation,
} from './hooks/pcmsClusters.generated';
import {StandardPlusPcmsCluster, UserRole} from 'types';
import {ViewAuthorizer} from 'components/ViewAuthorizer';
import {LoadWrapper} from 'components/LoadWrapper';
import {EntityPage} from 'components';
import {PcmsClustersTooltipTranslationProvider} from 'features/localizedTooltips';

const AdminPcmsClustersPage: React.FC = () => {
	const {t} = useTranslation('features/adminsection', {
		keyPrefix: 'AdminPcmsClustersPage',
	});

	const {loading, data} = useGetAllPcmsClustersQuery();
	const [createPcmsClusterMutation] = useCreatePcmsClusterMutation();
	const [updatePcmsClusterMutation] = useUpdatePcmsClusterMutation();
	const [deletePcmsClusterMutation] = useDeletePcmsClusterMutation();

	const pcmsClusters = React.useMemo(
		() => data?.standardPlusPcmsClusters ?? [],
		[data],
	);

	const refetchQueries = [GetAllPcmsClustersDocument];

	const createPcmsCluster = React.useCallback(
		async (pcmsCluster: StandardPlusPcmsCluster) => {
			try {
				await createPcmsClusterMutation({
					variables: {
						input: {
							name: pcmsCluster.name,
						},
					},
					refetchQueries,
				});
			} catch (error) {
				console.error('Error while creating cluster:', error);
			}
		},
		[],
	);

	const updatePcmsCluster = React.useCallback(
		async (pcmsCluster: StandardPlusPcmsCluster) => {
			try {
				await updatePcmsClusterMutation({
					variables: {
						input: {
							id: pcmsCluster.id,
							name: pcmsCluster.name,
						},
					},
					refetchQueries,
				});
			} catch (error) {
				console.error('Error while updating cluster:', error);
			}
		},
		[],
	);

	const deletePcmsCluster = React.useCallback(async (id: string) => {
		try {
			await deletePcmsClusterMutation({
				variables: {input: {id}},
				refetchQueries,
			});
		} catch (error) {
			console.error('Error while deleting cluster:', error);
		}
	}, []);

	return (
		<ViewAuthorizer roles={[UserRole.SystemAdministrator]}>
			<LoadWrapper loading={loading}>
				<PcmsClustersTooltipTranslationProvider>
					<EntityPage
						items={pcmsClusters}
						entityDisplayName={t('PcmsClusters')}
						createEntity={createPcmsCluster}
						updateEntity={updatePcmsCluster}
						deleteEntity={deletePcmsCluster}
					/>
				</PcmsClustersTooltipTranslationProvider>
			</LoadWrapper>
		</ViewAuthorizer>
	);
};

export default AdminPcmsClustersPage;
