import * as Types from '../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMyKeywordsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMyKeywordsQuery = { __typename?: 'Query', myKeywords: Array<{ __typename?: 'Keyword', id: string, name: string, vexClusters: Array<{ __typename?: 'VexCluster', id: string, name: string }> }> };


export const GetMyKeywordsDocument = gql`
    query GetMyKeywords {
  myKeywords {
    id
    name
    vexClusters {
      id
      name
    }
  }
}
    `;

/**
 * __useGetMyKeywordsQuery__
 *
 * To run a query within a React component, call `useGetMyKeywordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyKeywordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyKeywordsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyKeywordsQuery(baseOptions?: Apollo.QueryHookOptions<GetMyKeywordsQuery, GetMyKeywordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyKeywordsQuery, GetMyKeywordsQueryVariables>(GetMyKeywordsDocument, options);
      }
export function useGetMyKeywordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyKeywordsQuery, GetMyKeywordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyKeywordsQuery, GetMyKeywordsQueryVariables>(GetMyKeywordsDocument, options);
        }
export type GetMyKeywordsQueryHookResult = ReturnType<typeof useGetMyKeywordsQuery>;
export type GetMyKeywordsLazyQueryHookResult = ReturnType<typeof useGetMyKeywordsLazyQuery>;
export type GetMyKeywordsQueryResult = Apollo.QueryResult<GetMyKeywordsQuery, GetMyKeywordsQueryVariables>;