import {createContext, useContext} from 'react';

export interface ValidTooltipsStatusContextValue {
	shouldGetTooltipTextFromContext: boolean;
}

export type TooltipStatusContextValue = ValidTooltipsStatusContextValue | null;

export const TooltipStatusContext =
	createContext<TooltipStatusContextValue>(null);

export const useTooltipStatusContext = (): TooltipStatusContextValue => {
	return useContext(TooltipStatusContext);
};
