import React, {useMemo} from 'react';
import {
	TooltipStatusContext,
	ValidTooltipsStatusContextValue,
} from './TooltipStatusContext';

interface Props {
	children: React.ReactNode;
}

export const ProviderThatEnablesGettingTooltipsFromContext = (
	props: Props,
): JSX.Element => {
	const getValue = (): ValidTooltipsStatusContextValue => {
		return {shouldGetTooltipTextFromContext: true};
	};

	const value: ValidTooltipsStatusContextValue = useMemo(getValue, []);

	return <TooltipStatusContext.Provider value={value} {...props} />;
};
