import {
	IDialogContentProps,
	DialogType,
	IModalProps,
	Dialog,
	DialogFooter,
	PrimaryButton,
	DefaultButton,
} from '@fluentui/react';
import {LoadWrapper} from 'components/LoadWrapper';
import {
	FileWithMetadata,
	FileUpload,
} from 'components/hookForms/ControlledFileUpload';
import {useEditorCommands} from 'features/RegulatoryDocuments/hooks';
import {useUploadRegulatoryDocumentAssetMutation} from 'features/RegulatoryDocuments/hooks/useUploadDocumentAsset.generated';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {useSlate} from 'slate-react';
import {BlobRef} from 'types';

export const InsertImageDialog: React.FC<{
	hidden: boolean;
	onDismiss: () => void;
}> = ({onDismiss, hidden}) => {
	const {t} = useTranslation('features/regulatorydocuments', {
		keyPrefix: 'DocumentEditor.EditorContent',
	});

	const [imageFiles, setImageFiles] = React.useState<File[]>([]);
	const [loading, setLoading] = React.useState(false);
	const [uploadAsset] = useUploadRegulatoryDocumentAssetMutation();
	const {regulatoryDocumentId} = useParams();
	const editor = useSlate();
	const {addImages} = useEditorCommands(editor);

	const onFileUploadChange = React.useCallback((files: FileWithMetadata[]) => {
		setImageFiles(files.map(f => f.file));
	}, []);

	const onCancelClick = React.useCallback(() => {
		setImageFiles([]);
		onDismiss();
	}, []);

	const onSubmitClick = React.useCallback(async () => {
		if (!regulatoryDocumentId) {
			return;
		}

		setLoading(true);
		await Promise.all(
			imageFiles.map(async imageFile => {
				const {data} = await uploadAsset({
					variables: {
						input: {
							assetId: imageFile.name,
							file: imageFile,
							regulatoryDocumentId,
						},
					},
				});

				const blobRef =
					data?.uploadRegulatoryDocumentAsset.regulatoryDocumentAsset;

				return blobRef;
			}),
		)
			.then(uploadedFiles => {
				addImages(uploadedFiles.filter(f => f !== null) as BlobRef[]);
				setImageFiles([]);
			})
			.finally(() => {
				onDismiss();
				setLoading(false);
			});
	}, [imageFiles]);

	const insertImageDialogContentProps: IDialogContentProps = {
		type: DialogType.largeHeader,
		title: t('InsertImageHeader'),
		subText: t('InsertImageSubText'),
	};
	const insertImageModelProps: IModalProps = {
		isBlocking: false,
		styles: {main: {maxWidth: 450}},
	};

	return (
		<Dialog
			hidden={hidden}
			onDismiss={onDismiss}
			dialogContentProps={insertImageDialogContentProps}
			modalProps={insertImageModelProps}
		>
			<LoadWrapper loading={loading} text=''>
				<FileUpload
					renderCategory={false}
					onChange={onFileUploadChange}
					accept={{
						'image/*': ['.jpg', '.jpeg', '.png', '.gif', '.bmp'],
					}}
				/>
			</LoadWrapper>
			<DialogFooter>
				<PrimaryButton
					onClick={onSubmitClick}
					text={t('InsertImage')}
					disabled={loading}
				/>
				<DefaultButton
					onClick={onCancelClick}
					text={t('Cancel')}
					disabled={loading}
				/>
			</DialogFooter>
		</Dialog>
	);
};
