import {UserRole, SystemLevel} from 'types';
import {EntityPage} from 'components';
import {ViewAuthorizer} from 'components/ViewAuthorizer';
import {LoadWrapper} from 'components/LoadWrapper';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {
	GetAllSystemLevelsDocument,
	useCreateSystemLevelMutation,
	useDeleteSystemLevelMutation,
	useGetAllSystemLevelsQuery,
	useUpdateSystemLevelMutation,
} from './hooks/systemLevels.generated';
import {SystemLevelsTooltipTranslationProvider} from 'features/localizedTooltips';

const AdminSystemLevelsPage: React.FC = () => {
	const {t} = useTranslation('features/adminsection', {
		keyPrefix: 'AdminSystemLevelsPage',
	});
	const {loading, data} = useGetAllSystemLevelsQuery();
	const [createSystemLevelMutation] = useCreateSystemLevelMutation();
	const [updateSystemLevelMutation] = useUpdateSystemLevelMutation();
	const [deleteSystemLevelMutation] = useDeleteSystemLevelMutation();

	const systemLevels = React.useMemo(() => data?.systemLevels ?? [], [data]);

	const refetchQueries = [GetAllSystemLevelsDocument];

	const createSystemLevel = React.useCallback((systemLevel: SystemLevel) => {
		createSystemLevelMutation({
			variables: {
				input: {
					name: systemLevel.name,
				},
			},
			refetchQueries,
		});
	}, []);
	const updateSystemLevel = React.useCallback((systemLevel: SystemLevel) => {
		updateSystemLevelMutation({
			variables: {
				input: {
					id: systemLevel.id,
					name: systemLevel.name,
				},
			},
			refetchQueries,
		});
	}, []);
	const deleteSystemLevel = React.useCallback((id: string) => {
		deleteSystemLevelMutation({
			variables: {
				input: {id},
			},
			refetchQueries,
		});
	}, []);

	return (
		<ViewAuthorizer roles={[UserRole.SystemAdministrator]}>
			<LoadWrapper loading={loading}>
				<SystemLevelsTooltipTranslationProvider>
					<EntityPage
						items={systemLevels}
						entityDisplayName={t('SystemLevel')}
						createEntity={createSystemLevel}
						updateEntity={updateSystemLevel}
						deleteEntity={deleteSystemLevel}
					/>
				</SystemLevelsTooltipTranslationProvider>
			</LoadWrapper>
		</ViewAuthorizer>
	);
};

export default AdminSystemLevelsPage;
