import React from 'react';

import {
	AuthenticationResult,
	EventMessage,
	EventType,
	InteractionRequiredAuthError,
	PublicClientApplication,
	Configuration,
	IPublicClientApplication,
	AccountInfo,
} from '@azure/msal-browser';
import {MsalProvider} from '@azure/msal-react';
import {getConfig} from 'config';
import {UserContextProvider} from './UserContext';
import {UserRole} from 'types';

const config = getConfig();

const authority = config.REACT_APP_AUTHORITY;
const clientId = config.REACT_APP_CLIENT_ID;
const redirectUri = config.REACT_APP_REDIRECT_URI;
const scope = config.REACT_APP_MSAL_SCOPE;

export const msalConfig = {
	auth: {
		authority,
		clientId,
		redirectUri,
	},
	cache: {
		cacheLocation: 'localStorage',
		storeAuthStateInCookie: true,
	},
} as Configuration;

export type UserContextType = AccountInfo & {
	roles: UserRole[];
	isAdmin: boolean;
	isVko: boolean;
	isVkoExternal: boolean;
	isVex: boolean;
	isServiceProvider: boolean;
	isApprover: boolean;
	isReader: boolean;
	isRegulationReader: boolean;
};

export const logout = (msalInstance: IPublicClientApplication) => {
	msalInstance.logoutRedirect({
		account: msalInstance.getActiveAccount(),
	});
};

export let AccountContext = React.createContext({
	homeAccount: '',
});

const scopes = [scope];

export const getToken = async (msalInstance: IPublicClientApplication) => {
	const account = msalInstance.getActiveAccount();
	if (account) {
		try {
			const authResult = await msalInstance.acquireTokenSilent({
				scopes,
			});

			return authResult.accessToken;
		} catch (err) {
			if (err instanceof InteractionRequiredAuthError) {
				msalInstance.acquireTokenRedirect({
					scopes,
				});
				return Promise.reject(Error('InteractionRequired'));
			}

			return Promise.reject(Error('TokenNotAcquired'));
		}
	}

	return Promise.reject(Error('NoAccountFound'));
};

export type MsalAuthComponentProps = {
	msalInstance: PublicClientApplication;
	children?: JSX.Element;
};

export const MsalAuthComponent: React.FC<MsalAuthComponentProps> = ({
	msalInstance,
	children,
}) => {
	const [authResult, setAuthResult] =
		React.useState<AuthenticationResult | null>(null);
	React.useEffect(() => {
		msalInstance.addEventCallback((event: EventMessage) => {
			if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
				const payload = event.payload as AuthenticationResult;
				msalInstance.setActiveAccount(payload.account);
			}

			if (
				event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS &&
				event.payload
			) {
				const authenticationResult = event.payload as AuthenticationResult;
				document.cookie = `trace-msalAuthToken=${
					authenticationResult.accessToken
				}; expires=${authenticationResult.expiresOn?.toUTCString()}; path=/; domain=${
					window.location.hostname
				};`;
				setAuthResult(authenticationResult);
			}
		});

		const accounts = msalInstance.getAllAccounts();
		if (accounts.length > 0) {
			msalInstance.setActiveAccount(accounts[0]);
			AccountContext = React.createContext({
				homeAccount: accounts[0].homeAccountId,
			});
		}

		const account = msalInstance.getActiveAccount();
		if (!account) {
			msalInstance.handleRedirectPromise().then(_resp => {
				msalInstance.loginRedirect();
			});
		}
	});

	return (
		<UserContextProvider authResult={authResult}>
			<MsalProvider instance={msalInstance}>{children}</MsalProvider>
		</UserContextProvider>
	);
};
