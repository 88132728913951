import React from 'react';
import {BaseEditor, Editor, Node, NodeEntry, Path, Transforms} from 'slate';
import {HistoryEditor} from 'slate-history';
import {ReactEditor} from 'slate-react';
import {
	BlobRef,
	ImportRegulatoryDocumentParagraphInput,
	ParagraphElementType,
	TextPosition,
} from 'types';
import {v4 as uuidv4} from 'uuid';
import {
	DocumentEditorType,
	DocumentParagraph,
	DocumentTextLine,
} from '../components/DocumentEditor/slate';

export const getSelectedItems: (
	editor: DocumentEditorType,
) => NodeEntry<Node>[] = editor => {
	const selectedNodes = Editor.nodes(editor, {
		at: [],
		match: (node, _path) => Boolean((node as any).selected),
		mode: 'lowest',
	});

	const selectedItems = generatorToArray(selectedNodes);

	return selectedItems;
};

const resetSelectionCommand = (editor: DocumentEditorType) => () => {
	Transforms.setNodes(
		editor,
		{
			selected: false,
		} as any,
		{
			at: [],
			match: (_node, path) => path.length === 2,
		},
	);
};

const removeHeaderCommand = (editor: DocumentEditorType) => () => {
	const selectedItems = getSelectedItems(editor);
	selectedItems.reverse();

	for (const selectedNode of selectedItems) {
		const nodePath = selectedNode[1];

		if (nodePath[0] === 0 && nodePath[1] === 0) {
			Transforms.setNodes(
				editor,
				{isHeader: false, selected: false} as Partial<DocumentTextLine>,
				{
					at: nodePath,
				},
			);
			Transforms.setNodes(
				editor,
				{enumeration: '0'} as Partial<DocumentParagraph>,
				{
					at: [nodePath[0]],
				},
			);

			return;
		}

		Transforms.setNodes(
			editor,
			{
				isHeader: false,
				selected: false,
			} as any,
			{
				at: nodePath,
			},
		);
		const nodesAbove = Editor.nodes(editor, {
			at: [nodePath[0] - 1],
			match: (_node, path) => path.length === 2,
		});

		const nodeCount = getGeneratorCount(nodesAbove);
		Transforms.moveNodes(editor, {
			at: [nodePath[0]],
			match(node, path) {
				return path.length === 2 && (node as any).text !== 'Footnote';
			},
			to: [nodePath[0] - 1, nodeCount],
		});
		Transforms.removeNodes(editor, {
			at: [nodePath[0]],
		});
	}
};

const declareHeader = (
	editor: DocumentEditorType,
	selectedItems: NodeEntry<Node>[],
) => {
	selectedItems.reverse();
	for (const selectedNode of selectedItems) {
		const nodePath = selectedNode[1];
		const nodeValue = selectedNode[0] as DocumentTextLine;

		if (nodePath[1] === 0) {
			Transforms.setNodes(
				editor,
				{isHeader: true, selected: false} as Partial<DocumentTextLine>,
				{
					at: nodePath,
				},
			);
			Transforms.setNodes(
				editor,
				{enumeration: nodeValue.text.trim()} as Partial<DocumentParagraph>,
				{
					at: [nodePath[0]],
				},
			);

			return;
		}

		Transforms.insertNodes(
			editor,
			[
				{
					type: 'paragraph',
					enumeration: nodeValue.text.trim(),
					page: nodeValue.page,
					level: 0,
					isFootnote: false,
					_guid: nodeValue._guid,
					children: [],
				} as any,
			],
			{
				at: [nodePath[0] + 1],
			},
		);

		Transforms.moveNodes(editor, {
			at: [nodePath[0]],
			match: (_node, path) => path.length === 2 && path[1] >= nodePath[1],
			to: [nodePath[0] + 1, 0],
		});

		Transforms.setNodes(editor, {isHeader: true, selected: false} as any, {
			at: [nodePath[0] + 1, 0],
		});
	}
};

const declareHeaderCommand = (editor: DocumentEditorType) => () => {
	const selectedItems = getSelectedItems(editor);
	declareHeader(editor, selectedItems);
};

const declareFootnoteCommand = (editor: DocumentEditorType) => () => {
	const selectedItems = getSelectedItems(editor);
	const selectedNode = selectedItems[0];
	Transforms.insertNodes(
		editor,
		[
			{
				type: 'paragraph',
				enumeration: 'Footnote',
				page: (selectedNode[0] as any).page,
				level: 0,
				isFootnote: true,
				children: [],
			} as any,
		],
		{
			at: [selectedNode[1][0] + 1],
		},
	);

	Transforms.moveNodes(editor, {
		at: [selectedNode[1][0]],
		match: (_node, path) => path.length === 2 && path[1] >= selectedNode[1][1],
		to: [selectedNode[1][0] + 1, 0],
	});

	Transforms.insertNodes(
		editor,
		{
			type: ParagraphElementType.Text,
			page: (selectedNode[0] as any).page,
			bounds: (selectedNode[0] as any).bounds,
			isHeader: true,
			text: 'Footnote',
			selected: false,
			_guid: uuidv4(),
		} as any,
		{
			at: [selectedNode[1][0] + 1, 0],
		},
	);
};

const splitTextCommand = (editor: DocumentEditorType) => () => {
	const {selection} = editor;
	if (selection && selection.anchor.offset === selection.focus.offset) {
		const {path} = selection.anchor;
		const textNode = Editor.node(editor, path);
		const {text} = textNode[0] as any;
		const cursorPosition = selection.anchor.offset;
		const textAfter = text.slice(cursorPosition, text.length);
		Transforms.insertText(editor, text.slice(0, cursorPosition).trim(), {
			at: textNode[1],
		});
		Transforms.insertNodes(
			editor,
			{
				type: ParagraphElementType.Text,
				page: (textNode[0] as any).page,
				bounds: (textNode[0] as any).bounds,
				isHeader: false,
				text: textAfter.trim(),
				selected: false,
				_guid: uuidv4(),
			} as any,
			{
				at: [path[0], path[1] + 1],
			},
		);
	}
};

const mergeLines = (
	editor: DocumentEditorType,
	selectedItems: NodeEntry<Node>[],
) => {
	const firstItem = selectedItems[0];
	const otherItems = selectedItems.slice(1, undefined);
	const text = selectedItems
		.map(item => (item[0] as any).text)
		.join('')
		.trim();
	Transforms.insertText(editor, text, {at: firstItem[1]});
	Transforms.removeNodes(editor, {
		at: [firstItem[1][0]],
		match: (_node, path) =>
			path.length === 2 && otherItems.some(item => item[1][1] === path[1]),
	});

	Transforms.setNodes(editor, {selected: false} as any, {
		at: firstItem[1],
	});

	if ((firstItem[0] as DocumentTextLine).isHeader) {
		Transforms.setNodes(editor, {enumeration: text}, {at: [firstItem[1][0]]});
	}
};

const mergeLinesCommand = (editor: DocumentEditorType) => () => {
	const selectedItems = getSelectedItems(editor);
	mergeLines(editor, selectedItems);
};

const addParagraphCommand =
	(editor: DocumentEditorType) =>
	(
		enumeration: string,
		insertAbove: boolean,
		customLocation?: {
			location: number;
			paragraph: DocumentTextLine;
		},
		customChildren?: DocumentTextLine[],
	) => {
		const selectedItems = getSelectedItems(editor);

		if (selectedItems.length === 0 && !customLocation) {
			return;
		}

		const selectedNode = selectedItems[0];
		const selectedParagraph =
			customLocation?.paragraph ?? (selectedNode[0] as DocumentTextLine);

		let children = [
			{
				type: ParagraphElementType.Text,
				_guid: uuidv4(),
				isHeader: true,
				page: selectedParagraph.page,
				selected: false,
				text: enumeration,
				bounds: [0, 0, 0, 0],
			} as DocumentTextLine,
		];

		if (customChildren) {
			children = [...children, ...customChildren];
		} else {
			children.push({
				type: ParagraphElementType.Text,
				_guid: uuidv4(),
				isHeader: false,
				page: selectedParagraph.page,
				selected: false,
				text: ' ',
				bounds: [0, 0, 0, 0],
			} as DocumentTextLine);
		}

		Transforms.insertNodes(
			editor,
			{
				type: 'paragraph',
				enumeration,
				level: 0,
				page: selectedParagraph.page,
				isFootnote: false,
				children,
			} as DocumentParagraph,
			{
				at: [
					customLocation?.location ??
						selectedNode[1][0] + (insertAbove ? 0 : 1),
				],
			},
		);
	};

const addTextlineCommand =
	(editor: DocumentEditorType) => (insertAbove: boolean) => {
		const selectedItems = getSelectedItems(editor);
		let selectedNode = selectedItems[0];

		if (!selectedNode) {
			const {selection} = editor;
			if (selection && selection.anchor.offset === selection.focus.offset) {
				const {path} = selection.anchor;
				const node = Editor.node(editor, path);
				selectedNode = node;
			}
		}

		const selectedParagraph = selectedNode[0] as DocumentTextLine;
		const offset = selectedParagraph.isHeader || !insertAbove ? 1 : 0;
		const path = [selectedNode[1][0], selectedNode[1][1] + offset];
		Transforms.insertNodes(
			editor,
			{
				type: ParagraphElementType.Text,
				_guid: uuidv4(),
				isHeader: false,
				page: selectedParagraph.page,
				selected: false,
				text: ' ',
				bounds: [0, 0, 0, 0],
			} as DocumentTextLine,
			{
				at: path,
			},
		);

		Transforms.select(editor, {path, offset: 0});
	};

export const addImages = (
	editor: DocumentEditorType,
	selectedItems: NodeEntry<Node>[],
	imageFiles: BlobRef[],
	insertAbove?: boolean,
) => {
	const selectedNode = selectedItems[0];
	const selectedParagraph = selectedNode[0] as DocumentTextLine;
	const offset = selectedParagraph.isHeader || !insertAbove ? 1 : 0;

	imageFiles.reverse();
	for (const imageFile of imageFiles) {
		const {uri, fileName} = imageFile;

		Transforms.insertNodes(
			editor,
			{
				type: ParagraphElementType.Image,
				assetId: fileName,
				asset: {
					uri,
				},
				_guid: uuidv4(),
				isHeader: false,
				page: selectedParagraph.page,
				selected: false,
				text: fileName,
				bounds: [0, 0, 0, 0],
			} as DocumentTextLine,
			{
				at: [selectedNode[1][0], selectedNode[1][1] + offset],
			},
		);
	}
};

const addImagesCommand =
	(editor: DocumentEditorType) =>
	(imageFiles: BlobRef[], insertAbove?: boolean) => {
		const selectedItems = getSelectedItems(editor);
		addImages(editor, selectedItems, imageFiles, insertAbove);
	};

enum LevelChange {
	Increment,
	Decrement,
}

const changeLevelCommand =
	(editor: DocumentEditorType, change: LevelChange) => () => {
		const selectedItems = getSelectedItems(editor);

		for (const selectedNode of selectedItems) {
			const parentNode = Editor.nodes(editor, {
				at: [selectedNode[1][0]],
				match: (_node, path) => path.length === 1,
			}).next().value;

			if (parentNode) {
				const parentParagraph = parentNode[0] as DocumentParagraph;
				let level =
					parentParagraph.level + (change === LevelChange.Increment ? 1 : -1);
				level = Math.max(0, level);

				Transforms.setNodes(editor, {level} as any, {
					at: [selectedNode[1][0]],
				});
			}
		}
	};

const changeTextPositionCommand =
	(editor: DocumentEditorType, textPosition?: TextPosition) => () => {
		const selectedItems = getSelectedItems(editor);

		for (const selectedNode of selectedItems) {
			Transforms.setNodes(editor, {textPosition: textPosition ?? null} as any, {
				at: selectedNode[1],
			});
		}
	};

const moveElementCommand =
	(editor: DocumentEditorType, moveUp?: boolean) => () => {
		const selectedItems = getSelectedItems(editor);
		const selectedNode = selectedItems[0];
		const nodePath = selectedNode[1];
		const selectedElement = selectedNode[0] as DocumentTextLine;

		const isParagraph = selectedElement.isHeader;

		if (isParagraph) {
			const to = [nodePath[0] + (moveUp ? -1 : 1)];
			Transforms.moveNodes(editor, {
				at: [nodePath[0]],
				to,
			});
		} else if (moveUp) {
			if (Path.hasPrevious(nodePath)) {
				const previousPath = Path.previous(nodePath);
				const nodeAbove = Editor.node(editor, previousPath);
				const previousElement = nodeAbove[0] as DocumentTextLine;
				if (previousElement.isHeader) {
					const nodesAbove = Editor.nodes(editor, {
						at: [Math.max(selectedNode[1][0] - 1, 0)],
						match: (_node, path) => path.length === 2,
					});

					const nodeCount = getGeneratorCount(nodesAbove);
					const to = [Math.max(nodePath[0] - 1, 0), nodeCount];
					Transforms.moveNodes(editor, {
						at: nodePath,
						to,
					});
				} else {
					const to = [nodePath[0], nodePath[1] - 1];
					Transforms.moveNodes(editor, {
						at: nodePath,
						to,
					});
				}
			}
		} else {
			const nodesInParagraph = Editor.nodes(editor, {
				at: [nodePath[0]],
				match: (_node, path) => path.length === 2,
			});
			const nodeCount = getGeneratorCount(nodesInParagraph);

			if (nodePath[1] === nodeCount - 1) {
				const to = [Math.min(nodePath[0] + 1, editor.children.length - 1), 1];
				Transforms.moveNodes(editor, {
					at: nodePath,
					to,
				});
			} else {
				const to = [nodePath[0], nodePath[1] + 1];
				Transforms.moveNodes(editor, {
					at: nodePath,
					to,
				});
			}
		}
	};

const addNewLineCommand = (editor: DocumentEditorType) => () => {
	const selectedItems = getSelectedItems(editor);
	let selectedNode = selectedItems[0];

	if (!selectedNode) {
		const {selection} = editor;
		if (selection && selection.anchor.offset === selection.focus.offset) {
			const {path} = selection.anchor;
			const node = Editor.node(editor, path);
			selectedNode = node;
		}
	}

	const selectedParagraph = selectedNode[0] as DocumentTextLine;
	const offset = 1;
	const path = [selectedNode[1][0], selectedNode[1][1] + offset];
	Transforms.insertNodes(
		editor,
		{
			type: ParagraphElementType.NewLine,
			_guid: uuidv4(),
			isHeader: false,
			page: selectedParagraph.page,
			selected: false,
			text: '¶',
			bounds: [0, 0, 0, 0],
		} as DocumentTextLine,
		{
			at: [selectedNode[1][0], selectedNode[1][1] + offset],
		},
	);

	Transforms.select(editor, {path, offset: 1});
};

const mergeAllParagraphLinesCommand = (editor: DocumentEditorType) => () => {
	const nodes = Editor.nodes(editor, {
		at: [],
		match: (n, _) => (n as any).type === 'paragraph',
		mode: 'highest',
	});

	for (const node of nodes) {
		if ((node[0] as DocumentParagraph).children.length <= 1) {
			continue;
		}

		const children = Editor.nodes(editor, {
			at: [node[1][0]],
			match: (n, path) =>
				!(n as DocumentTextLine).isHeader && path.length === 2,
			mode: 'lowest',
		});

		mergeLines(editor, generatorToArray(children));
	}
};

const saveParagraphsCommand =
	(editor: DocumentEditorType) =>
	async (
		onSave: (
			paragraphs: ImportRegulatoryDocumentParagraphInput[],
		) => void | Promise<void>,
	) => {
		const paragraphs: ImportRegulatoryDocumentParagraphInput[] = (
			editor.children as any
		).map(
			(pElem: DocumentParagraph) =>
				({
					id: pElem.id,
					enumeration: pElem.enumeration,
					level: pElem.level,
					page: pElem.page,
					isFootnote: pElem.isFootnote,
					elements: pElem.children.map((tElem: DocumentTextLine) => ({
						type: tElem.type,
						assetId: tElem.assetId,
						csvContent: tElem.csvContent,
						text: tElem.text,
						isHeader: tElem.isHeader,
						bounds: tElem.bounds || [],
						page: tElem.page,
						textPosition: tElem.textPosition,
					})),
					categoryRefs: [],
					systemLevelRefs: [],
					driveVariantRefs: [],
					vehicleCategoryRefs: [],
					tagRefs: [],
					keywordAssignments: [],
				} as ImportRegulatoryDocumentParagraphInput),
		);
		await onSave(paragraphs);
	};

export const useEditorCommands = (
	editor: BaseEditor & ReactEditor & HistoryEditor,
) => {
	const selectedItems = React.useMemo(
		() => getSelectedItems(editor),
		[editor.children, editor.selection],
	);
	const selectedItemsCount = selectedItems.length;

	const logItems = React.useCallback(() => {
		console.log(selectedItems);
	}, [selectedItems]);

	const resetSelection = React.useCallback(resetSelectionCommand(editor), [
		editor,
	]);

	const disableResetSelection = React.useMemo(
		() => selectedItems.length < 1,
		[selectedItems],
	);

	const cursorIsAtEndOfLine = React.useMemo(() => {
		const {selection} = editor;
		if (selection) {
			const {path} = selection.anchor;
			const textNode = Editor.node(editor, path);
			return selection.anchor.offset === (textNode[0] as any).text.length;
		}

		return false;
	}, [editor.selection]);

	const removeHeader = React.useCallback(removeHeaderCommand(editor), [editor]);

	const disableRemoveHeader = React.useMemo(
		() =>
			selectedItems.length === 0 ||
			selectedItems.some(
				node =>
					!(node[0] as DocumentTextLine).isHeader ||
					(node[0] as DocumentTextLine).type !== ParagraphElementType.Text,
			),
		[selectedItems],
	);

	const declareHeader = React.useCallback(declareHeaderCommand(editor), [
		editor,
	]);

	const disableDeclareHeader = React.useMemo(
		() =>
			selectedItems.length === 0 ||
			selectedItems.some(
				node =>
					(node[0] as any).isHeader ||
					(node[0] as DocumentTextLine).type !== ParagraphElementType.Text,
			),
		[selectedItems],
	);

	const declareFootnote = React.useCallback(declareFootnoteCommand(editor), [
		editor,
	]);

	const disableDeclateFootnote = React.useMemo(
		() =>
			selectedItems.length !== 1 ||
			selectedItems.some(
				node =>
					(node[0] as any).isHeader ||
					(node[0] as DocumentTextLine).type !== ParagraphElementType.Text,
			),
		[selectedItems],
	);

	const splitText = React.useCallback(splitTextCommand(editor), [editor]);

	const disableSplitText = React.useMemo(() => {
		const {selection} = editor;
		if (selection) {
			const {path} = selection.anchor;
			const textNode = Editor.node(editor, path);
			return (
				selection.anchor.offset === 0 ||
				selection.anchor.offset === (textNode[0] as any).text.length
			);
		}

		return true;
	}, [selectedItems, editor]);

	const incrementLevel = React.useCallback(
		changeLevelCommand(editor, LevelChange.Increment),
		[editor],
	);
	const decrementLevel = React.useCallback(
		changeLevelCommand(editor, LevelChange.Decrement),
		[editor],
	);

	const disableLevelChange = React.useMemo(
		() =>
			selectedItems.length === 0 ||
			selectedItems.some(
				node =>
					!(node[0] as DocumentTextLine).isHeader ||
					(node[0] as DocumentTextLine).type !== ParagraphElementType.Text,
			),
		[selectedItems],
	);

	const toSubscript = React.useCallback(
		changeTextPositionCommand(editor, TextPosition.Subscript),
		[editor],
	);
	const toSuperscript = React.useCallback(
		changeTextPositionCommand(editor, TextPosition.Superscript),
		[editor],
	);
	const resetTextPosition = React.useCallback(
		changeTextPositionCommand(editor, undefined),
		[editor],
	);

	const disableTextPositionChange = React.useMemo(
		() =>
			selectedItems.length === 0 ||
			selectedItems.some(
				node =>
					(node[0] as any).isHeader ||
					(node[0] as DocumentTextLine).type !== ParagraphElementType.Text,
			),
		[selectedItems],
	);

	const moveElementUp = React.useCallback(moveElementCommand(editor, true), [
		editor,
	]);
	const moveElementDown = React.useCallback(moveElementCommand(editor, false), [
		editor,
	]);

	const disableMoveElement = React.useMemo(
		() => selectedItems.length !== 1,
		[selectedItems],
	);

	const addNewLine = React.useCallback(addNewLineCommand(editor), []);

	const disableAddNewLine = React.useMemo(() => {
		return !cursorIsAtEndOfLine && selectedItems.length === 0;
	}, [selectedItems, cursorIsAtEndOfLine]);

	const mergeLines = React.useCallback(mergeLinesCommand(editor), [editor]);

	const disableMergeLines = React.useMemo(
		() =>
			selectedItems.length < 2 ||
			!selectedItems.every(
				node =>
					selectedItems[0][1][0] === node[1][0] &&
					!(
						(node[0] as DocumentTextLine).isHeader &&
						(node[0] as DocumentTextLine).text === 'Footnote'
					) &&
					(node[0] as DocumentTextLine).type === ParagraphElementType.Text,
			),
		[selectedItems],
	);

	const addParagraph = React.useCallback(addParagraphCommand(editor), [editor]);

	const disableAddParagraph = React.useMemo(
		() => selectedItems.length !== 1 || !(selectedItems[0][0] as any).isHeader,
		[selectedItems],
	);

	const addTextline = React.useCallback(addTextlineCommand(editor), [editor]);

	const disableAddTextline = React.useMemo(
		() => disableAddNewLine,
		[disableAddNewLine],
	);

	const addImages = React.useCallback(addImagesCommand(editor), [editor]);

	const disableAddImages = React.useMemo(
		() => selectedItems.length !== 1,
		[selectedItems],
	);

	const cursorIsInNewLine = React.useMemo(() => {
		const {selection} = editor;
		if (selection) {
			const {path} = selection.anchor;
			const textNode = Editor.node(editor, path);
			const {type} = textNode[0] as DocumentTextLine;

			return type === ParagraphElementType.NewLine;
		}

		return false;
	}, [editor.selection]);

	const mergeAllParagraphsLines = React.useCallback(
		mergeAllParagraphLinesCommand(editor),
		[editor],
	);

	const saveParagraphs = React.useCallback(saveParagraphsCommand(editor), [
		editor,
	]);

	return {
		resetSelection,
		disableResetSelection,
		selectedItemsCount,
		logItems,
		removeHeader,
		disableRemoveHeader,
		declareHeader,
		disableDeclareHeader,
		declareFootnote,
		disableDeclateFootnote,
		splitText,
		disableSplitText,
		mergeLines,
		disableMergeLines,
		addParagraph,
		disableAddParagraph,
		addTextline,
		disableAddTextline,
		addImages,
		disableAddImages,
		incrementLevel,
		decrementLevel,
		disableLevelChange,
		toSubscript,
		toSuperscript,
		resetTextPosition,
		disableTextPositionChange,
		moveElementUp,
		moveElementDown,
		disableMoveElement,
		addNewLine,
		disableAddNewLine,
		mergeAllParagraphsLines,
		saveParagraphs,
		selectedItems,
		cursorIsInNewLine,
	};
};

function generatorToArray<T>(generator: Generator<T, void, undefined>): T[] {
	const res = [];

	for (const item of generator) {
		res.push(item);
	}

	return res;
}

function getGeneratorCount<T>(
	generator: Generator<T, void, undefined>,
): number {
	let count = 0;

	for (const _ of generator) {
		count++;
	}

	return count;
}
