import React from 'react';
import {Link, ILinkProps} from '@fluentui/react';

export const CellLink = (props: ILinkProps) => {
	/**
	 * We disable tabbing to this item because each Details List's row is
	 * focusable. So, users would have to press "Tab" twice to advance to the next
	 * item.
	 */
	return <Link tabIndex={-1} {...props} />;
};
