import React from 'react';
import DOMPurify from 'dompurify';
import {Theme, mergeStyles, useTheme} from '@fluentui/react';

type TableComponentProps = {
	htmlTable: string;
};

export const TableComponent: React.FC<TableComponentProps> = ({htmlTable}) => {
	const theme = useTheme();
	const className = getHTMLTableClassName(theme);

	const cleanHtml = DOMPurify.sanitize(htmlTable);

	return (
		<div
			className={className}
			dangerouslySetInnerHTML={{__html: cleanHtml}}
		></div>
	);
};

export const getHTMLTableClassName = (theme: Theme) => {
	return mergeStyles({
		'& table, th, td': {
			border: `1px solid ${theme.palette.neutralSecondaryAlt}`,
			borderCollapse: 'collapse',
		},
		'& th, td': {
			padding: 5,
			minWidth: 50,
		},
		overflowX: 'auto',
	});
};
