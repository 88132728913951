import {QueryParagraph} from 'features/RegulatoryDocuments/RegDocDetailsPage/RegDocDetailsPage.queryTypes';
import {AttachmentInput} from 'types';
import {
	CommonOptimisticParagraphFields,
	ParagraphOptimisticDataUtils,
} from '../ParagraphOptimisticData.utils';
import {UpdateParagraphAttachmentsMutation} from 'features/RegulatoryDocuments/hooks/useUpdateParagraphAttachments.generated';
import _ from 'lodash';
import {GetAttachmentCategoriesQuery} from 'components/hookForms/hooks/getAttachmentCategories.generated';

type FormQueryAttachmentCategories =
	GetAttachmentCategoriesQuery['attachmentCategories'];

interface Fields {
	attachments: AttachmentInput[];
	attachmentCategories: FormQueryAttachmentCategories;
	selectedParagraphs: QueryParagraph[];
	paragraphId: QueryParagraph['id'];
}

export type {Fields as FieldsOfParagraphWithAttachmentsService};

/**
 * * Mutations
 */
type MutationData =
	UpdateParagraphAttachmentsMutation['updateParagraphAttachments'];
type RegDoc = NonNullable<MutationData['regulatoryDocument']>;
type Paragraphs = RegDoc['paragraphs'];
type Paragraph = Paragraphs[number];
type MutationAttachments = Paragraph['attachments'];
/**
 * * Note that this doesn't have the correct type for the file. @see
 *   OptimisticAttachmentFile for the correct type.
 */
type Attachment = NonNullable<MutationAttachments>[number];

/**
 * * Recursively required types
 */
type ValueOfRecursivelyRequiredObject<Value> = Value extends object
	? RecursivelyRequired<Value>
	: Value;

type ObjectWithRequiredValues<CustomObject> = {
	[Key in keyof CustomObject]: ValueOfRecursivelyRequiredObject<
		CustomObject[Key]
	>;
};

type RecursivelyRequired<CustomObject> = Required<
	ObjectWithRequiredValues<CustomObject>
>;

/**
 * * Optimistic data
 */
type FieldsFromAttachmentFile = Omit<Attachment['file'], 'uri' | '__typename'>;

/**
 * We are rewriting this type because the uri's type is wrong.
 */
interface OptimisticAttachmentFile extends FieldsFromAttachmentFile {
	uri: string;
}

type OptimisticAttachmentFieldsWithoutFile = Omit<
	RecursivelyRequired<Attachment>,
	'file'
>;

export interface OptimisticAttachment
	extends OptimisticAttachmentFieldsWithoutFile {
	file: OptimisticAttachmentFile;
}

type ParagraphFieldsWithoutAttachments = Omit<
	RecursivelyRequired<Paragraph>,
	'attachments'
>;

export interface OptimisticParagraph extends ParagraphFieldsWithoutAttachments {
	attachments: OptimisticAttachment[];
}

/**
 * * Other
 */
type PossibleAttachment = Attachment | undefined;

type FormQueryAttachmentCategory =
	NonNullable<FormQueryAttachmentCategories>[number];
type PossibleQueryAttachmentCategory = FormQueryAttachmentCategory | undefined;

export class ParagraphWithAttachmentsService {
	private utils = new ParagraphOptimisticDataUtils();

	private attachments: Fields['attachments'];
	private attachmentCategories: Fields['attachmentCategories'];
	private selectedParagraph: QueryParagraph;

	constructor({
		attachments,
		attachmentCategories,
		selectedParagraphs,
		paragraphId,
	}: Fields) {
		this.attachments = attachments;
		this.attachmentCategories = attachmentCategories;
		this.selectedParagraph = this.utils.getSelectedParagraphById(
			selectedParagraphs,
			paragraphId,
		);
	}

	private createAttachmentFile = (file: File): OptimisticAttachmentFile => {
		return {
			uri: URL.createObjectURL(file),
			contentType: file.type,
			fileName: file.name,
		};
	};

	private getOldAttachmentsWithDefaultValue = (): Attachment[] => {
		return this.selectedParagraph.attachments ?? [];
	};

	private findOldAttachmentById = (file: File): PossibleAttachment => {
		const oldAttachments: MutationAttachments =
			this.getOldAttachmentsWithDefaultValue();
		/**
		 * We use the file's name to find it because the form sets the attachment's IDs to their files' names.
		 */
		return _.find(oldAttachments, {attachmentId: file.name});
	};

	private findOrCreateAttachmentFile = (
		file: File,
	): OptimisticAttachmentFile => {
		const oldAttachment: PossibleAttachment = this.findOldAttachmentById(file);
		/**
		 * The file will be empty if the user edited an existing attachment. So, we
		 * need to get its file data from the existing attachment in that case.
		 */
		if (oldAttachment && !file.size) return oldAttachment.file;
		return this.createAttachmentFile(file);
	};

	private findAttachmentCategoryIfPossible = (
		attachmentCategoryId: AttachmentInput['categoryId'],
	): PossibleQueryAttachmentCategory => {
		if (!attachmentCategoryId) return undefined;
		return _.find(this.attachmentCategories, {
			id: attachmentCategoryId,
		});
	};

	private createOptimisticCategoryFromQueryCategory = (
		category: FormQueryAttachmentCategory,
	): OptimisticAttachment['category'] => {
		return {...category, __typename: 'AttachmentCategory'};
	};

	private getAttachmentCategory = (
		attachmentCategoryId: AttachmentInput['categoryId'],
	): OptimisticAttachment['category'] => {
		const match: PossibleQueryAttachmentCategory =
			this.findAttachmentCategoryIfPossible(attachmentCategoryId);
		if (match) return this.createOptimisticCategoryFromQueryCategory(match);
		return null;
	};

	private createAttachmentOptimisticData = ({
		file,
		categoryId,
		isVkoOnly,
	}: AttachmentInput): OptimisticAttachment => {
		return {
			__typename: 'AttachmentRef',
			file: this.findOrCreateAttachmentFile(file),
			attachmentId: file.name,
			category: this.getAttachmentCategory(categoryId),
			isVkoOnly: isVkoOnly ?? null,
		};
	};

	private createAttachments = (): OptimisticAttachment[] => {
		return this.attachments.map(this.createAttachmentOptimisticData);
	};

	public createParagraph = (): OptimisticParagraph => {
		const commonFields: CommonOptimisticParagraphFields =
			this.utils.createCommonParagraphFields(this.selectedParagraph);
		return {
			...commonFields,
			attachments: this.createAttachments(),
		};
	};

	static createParagraph = (fields: Fields): OptimisticParagraph => {
		const service = new ParagraphWithAttachmentsService(fields);
		return service.createParagraph();
	};
}
