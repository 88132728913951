import {
	DefaultButton,
	PrimaryButton,
	Separator,
	Stack,
	mergeStyleSets,
} from '@fluentui/react';

import {ControlledCheckbox, ControlledTextField} from 'components/hookForms';
import {useEditorCommands} from 'features/RegulatoryDocuments/hooks';
import React from 'react';
import {useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';

import {useSlate} from 'slate-react';
import {ParagraphElementType} from 'types';
import {v4 as uuidv4} from 'uuid';
import {DocumentTextLine} from '../slate';
import {Stage, TableImageSplitterContext} from './TableImageSplitter';
import {useTranslation} from 'react-i18next';
import {useEditorContext} from '../EditorContext';
import {TableComponent} from 'components';

type HtmlTablePart = {
	height: number;
	yIndex: number;
	index: number;
};

interface SplitTableForm {
	splitTables: Array<{
		data: string;
		enumeration: string;
		isTableHeader: boolean;
	}>;
	keepSourceTable: boolean;
}

export interface SplittingTableComponentProps {
	text: string;
}

export const SplittingHtmlTableComponent: React.FC<
	SplittingTableComponentProps
> = ({text}) => {
	const {t} = useTranslation('features/regulatorydocuments', {
		keyPrefix: 'DocumentEditor.TableImageSplitter',
	});
	const {
		splitLocations,
		setSplitLocations,

		setStage,
		onDismiss,
		data,
		leaf,
		tableNodePath,
	} = React.useContext(TableImageSplitterContext);

	const {deleteParagraphElement} = useEditorContext();

	const classNames = getClassNames();
	const editor = useSlate();
	const {addParagraph} = useEditorCommands(editor);
	const {regulatoryDocumentId} = useParams();
	const {
		handleSubmit,
		control,
		setValue,
		getValues,
		watch,
		resetField,
		formState: {isValid, isSubmitting},
	} = useForm<SplitTableForm>({
		reValidateMode: 'onChange',
		mode: 'all',
	});

	React.useEffect(
		() =>
			setValue(
				'splitTables',
				data.map(imgData => ({
					enumeration: '',
					isTableHeader: false,
					data: imgData,
				})),
			),
		[data],
	);

	const tableParts: HtmlTablePart[] = React.useMemo(() => {
		const res = new Array<HtmlTablePart>();

		let newSplitLocations = [...new Set(splitLocations)];
		newSplitLocations = newSplitLocations.sort((a, b) => a - b);

		const t = document.createElement('div');
		t.innerHTML = text;

		const trList = t.querySelectorAll('tr');

		let startIndex = 0;
		let index = 0;
		for (const splitLocation of newSplitLocations) {
			const endIndex = Math.min(splitLocation, trList.length);
			const height = endIndex - startIndex + 1;
			res.push({
				yIndex: startIndex,
				height,
				index,
			});
			startIndex += height;
			index++;
		}

		// Calculate the height of the last part
		const lastPartHeight = trList.length - startIndex;
		const lastPartIndex = startIndex;

		if (lastPartHeight > 0 && lastPartIndex < trList.length) {
			res.push({
				yIndex: lastPartIndex,
				height: lastPartHeight,
				index,
			});
		}

		return res;
	}, [splitLocations]);

	const onBackButtonClick = React.useCallback(() => {
		setSplitLocations([]);
		setStage(Stage.DefineSplitLocations);
	}, [setStage]);

	const onCancelButtonClick = React.useCallback(() => {
		setSplitLocations([]);
		setStage(Stage.DefineSplitLocations);
		onDismiss();
	}, [setStage]);

	const onSubmitClick = React.useCallback(async () => {
		if (!regulatoryDocumentId) {
			return;
		}

		handleSubmit(async data => {
			const {splitTables} = data;

			const headerTables = splitTables.find(r => r.isTableHeader);
			const tables = splitTables.filter(r => !r.isTableHeader);

			if (!data.keepSourceTable) {
				deleteParagraphElement(editor, tableNodePath);
			}

			tables.reverse();

			for (const img of tables) {
				addParagraph(
					img.enumeration,
					false,
					{
						location: tableNodePath[0] + 1,
						paragraph: leaf,
					},
					[
						getTableEditorElement(
							leaf.page,
							mergeHtmlTables(img, headerTables),
						),
					],
				);
			}

			setSplitLocations([]);

			setStage(Stage.DefineSplitLocations);
			onDismiss();
		})();
	}, [data, editor, regulatoryDocumentId, onDismiss]);

	const mergeHtmlTables = (
		tabel1: {data: string} | undefined,
		tabel2: {data: string} | undefined,
	): string => {
		const res = '';

		if (tabel1 === undefined && tabel2 === undefined) return res;
		if (tabel1 === undefined && tabel2 !== undefined) return tabel2?.data;
		if (tabel1 !== undefined && tabel2 === undefined) return tabel1?.data;

		const t1 = document.createElement('div');
		t1.innerHTML = tabel1!.data;

		const t2 = document.createElement('div');
		t2.innerHTML = tabel2!.data;

		const tbodys1 = t1.getElementsByTagName('tbody');
		const tbodys2 = t2.getElementsByTagName('tbody');

		if (tbodys1.length !== 1) return t2.innerHTML;
		if (tbodys2.length !== 1) return t1.innerHTML;

		const tbody1 = tbodys1[0];
		const tbody2 = tbodys2[0];

		tbody2.innerHTML += tbody1.innerHTML;

		return t2.innerHTML;
	};

	const onCheckboxChange = React.useCallback(
		(checked: boolean, index: number) => {
			if (checked) {
				for (let i = 0; i < data.length; i++) {
					if (i !== index) {
						setValue(`splitTables.${i}.isTableHeader`, false);
					}
				}

				resetField(`splitTables.${index}.enumeration`);
			}
		},
		[data.length, setValue],
	);

	return (
		<div className={classNames.SplittingTableComponent}>
			<div className={classNames.tablePartsContainer}>
				{tableParts.map((htmlTablePart, i) => {
					const isTableHeader = watch(`splitTables.${i}.isTableHeader`);
					return (
						<div key={htmlTablePart.yIndex}>
							<Stack
								horizontal
								horizontalAlign='space-between'
								tokens={{childrenGap: 16}}
							>
								<ControlledTextField
									placeholder={
										isTableHeader ? undefined : t('HeaderFieldPlaceholder')
									}
									styles={{root: {width: 400}}}
									name={`splitTables.${i}.enumeration`}
									control={control}
									required={!isTableHeader}
									disabled={isTableHeader}
									rules={{
										validate: {
											required: val =>
												!val && !getValues(`splitTables.${i}.isTableHeader`)
													? t('HeaderError')
													: true,
										},
									}}
								/>
								<ControlledCheckbox
									label={t('IsTableHeader')}
									control={control}
									name={`splitTables.${i}.isTableHeader`}
									onChange={(_, val) => onCheckboxChange(Boolean(val), i)}
								/>
							</Stack>

							<SplitTable text={text} htmlTablePart={htmlTablePart} />
							<br></br>
							<br></br>
						</div>
					);
				})}
			</div>
			<Separator />
			<div className={classNames.buttons}>
				<DefaultButton
					className={classNames.button}
					onClick={onBackButtonClick}
				>
					{t('Back')}
				</DefaultButton>
				<Stack horizontal tokens={{childrenGap: 8}}>
					<ControlledCheckbox
						label={t('KeepSourceTable')}
						control={control}
						name={`keepSourceTable`}
						styles={{root: {marginTop: 10}}}
					/>
					<PrimaryButton
						className={classNames.button}
						onClick={onSubmitClick}
						disabled={!isValid || isSubmitting}
					>
						{t('InsertIntoDocument')}
					</PrimaryButton>
					<DefaultButton
						className={classNames.button}
						onClick={onCancelButtonClick}
					>
						{t('Cancel')}
					</DefaultButton>
				</Stack>
			</div>
		</div>
	);
};

interface SplitTableProps {
	text: string;
	htmlTablePart: HtmlTablePart;
}

const SplitTable: React.FC<SplitTableProps> = ({text, htmlTablePart}) => {
	const {setData} = React.useContext(TableImageSplitterContext);
	const t = document.createElement('div');
	t.innerHTML = text;

	const trList = t.querySelectorAll('tr');

	const beforeIndex = htmlTablePart.yIndex;
	const afterIndex = htmlTablePart.yIndex + htmlTablePart.height;

	const trToRemoveList = new Array<HTMLElement>();

	// Get all tr to be removed
	for (let i = 0; i < trList.length; i++) {
		if (i >= beforeIndex && i < afterIndex) continue;
		trToRemoveList.push(trList[i]);
	}

	// Delete all after HtmlTablePart
	for (const trToRemove of trToRemoveList) {
		const parent = trToRemove.parentElement;
		if (parent === null) continue;
		parent.removeChild(trToRemove);
	}

	React.useEffect(() => {
		setData(imgs => {
			const newImgData = imgs.slice();
			newImgData[htmlTablePart.index] = t.innerHTML;
			return newImgData;
		});
	}, []);

	return <TableComponent htmlTable={t.innerHTML} />;
};

const getTableEditorElement = (page: number, data: string) => {
	return {
		type: ParagraphElementType.HtmlTable,
		_guid: uuidv4(),
		isHeader: false,
		page,
		selected: false,
		text: data,
		bounds: [0, 0, 0, 0],
	} as DocumentTextLine;
};

const getClassNames = () =>
	mergeStyleSets({
		SplittingTableComponent: {
			background: 'white',
			position: 'fixed',
			top: '50%',
			left: '50%',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			minWidth: 400,
		},
		tablePartsContainer: {
			background: 'white',
			padding: '20px',
			height: 'calc(100vh - 150px)',
			overflowY: 'auto',
		},
		canvas: {
			border: '1px dashed',
			margin: '10px 0',
		},
		buttons: {
			display: 'flex',
			justifyContent: 'space-between',
			padding: '0px 4px 4px 4px',
		},
		button: {
			margin: '4px',
		},
	});
