import React from 'react';
import {
	PropsForTooltipWithComponentWithAriaDescribedBy,
	TooltipWithComponentWithAriaDescribedBy,
} from '../TooltipWithComponentWithAriaDescribedBy';
import {DirectionalHint, ILabelProps, Label} from '@fluentui/react';
import {LocalizedTooltipHostProps} from '../LocalizedTooltipHost';

type HocProps = PropsForTooltipWithComponentWithAriaDescribedBy<ILabelProps>;

type PropsFromHoc = Omit<HocProps, 'Component'>;

/**
 * Note:
 *
 * translationKey is an alias for the tooltip host props translation key. This
 * facilitates adding a translation key when a form has many fields.
 */
export type LabelWithTooltipProps = PropsFromHoc &
	Pick<LocalizedTooltipHostProps, 'translationKey'>;

export function LabelWithTooltip({
	translationKey,
	tooltipHostProps,
	...other
}: LabelWithTooltipProps): JSX.Element {
	return (
		<TooltipWithComponentWithAriaDescribedBy
			Component={Label}
			tooltipHostProps={{
				directionalHint: DirectionalHint.leftCenter,
				translationKey,
				...tooltipHostProps,
			}}
			{...other}
		/>
	);
}
