import {UseTranslationOptions, useTranslation} from 'react-i18next';

export const useRegDocTranslations = (
	options?: UseTranslationOptions<string>,
) => {
	return useTranslation('features/regulatorydocuments', options);
};

export const useRegDocsFormTranslations = () => {
	return useRegDocTranslations({keyPrefix: 'RegulatoryDocumentsForm'});
};
