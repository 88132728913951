import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteRegulationFaqMutationVariables = Types.Exact<{
  input: Types.DeleteRegulationFaqInput;
}>;


export type DeleteRegulationFaqMutation = { __typename?: 'Mutation', deleteRegulationFaq: { __typename?: 'DeleteRegulationFaqPayload', regulation?: { __typename?: 'Regulation', id: string } | null } };

export type DeleteRegulatoryDocumentFaqMutationVariables = Types.Exact<{
  input: Types.DeleteRegulatoryDocumentFaqInput;
}>;


export type DeleteRegulatoryDocumentFaqMutation = { __typename?: 'Mutation', deleteRegulatoryDocumentFaq: { __typename?: 'DeleteRegulatoryDocumentFaqPayload', regulatoryDocument?: { __typename?: 'RegulatoryDocument', id: string } | null } };

export type DeleteRegulatoryDocumentParagraphFaqMutationVariables = Types.Exact<{
  input: Types.DeleteRegulatoryDocumentParagraphFaqInput;
}>;


export type DeleteRegulatoryDocumentParagraphFaqMutation = { __typename?: 'Mutation', deleteRegulatoryDocumentParagraphFaq: { __typename?: 'DeleteRegulatoryDocumentParagraphFaqPayload', regulatoryDocument?: { __typename?: 'RegulatoryDocument', id: string } | null } };


export const DeleteRegulationFaqDocument = gql`
    mutation DeleteRegulationFaq($input: DeleteRegulationFaqInput!) {
  deleteRegulationFaq(input: $input) {
    regulation {
      id
    }
  }
}
    `;
export type DeleteRegulationFaqMutationFn = Apollo.MutationFunction<DeleteRegulationFaqMutation, DeleteRegulationFaqMutationVariables>;

/**
 * __useDeleteRegulationFaqMutation__
 *
 * To run a mutation, you first call `useDeleteRegulationFaqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRegulationFaqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRegulationFaqMutation, { data, loading, error }] = useDeleteRegulationFaqMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRegulationFaqMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRegulationFaqMutation, DeleteRegulationFaqMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRegulationFaqMutation, DeleteRegulationFaqMutationVariables>(DeleteRegulationFaqDocument, options);
      }
export type DeleteRegulationFaqMutationHookResult = ReturnType<typeof useDeleteRegulationFaqMutation>;
export type DeleteRegulationFaqMutationResult = Apollo.MutationResult<DeleteRegulationFaqMutation>;
export type DeleteRegulationFaqMutationOptions = Apollo.BaseMutationOptions<DeleteRegulationFaqMutation, DeleteRegulationFaqMutationVariables>;
export const DeleteRegulatoryDocumentFaqDocument = gql`
    mutation DeleteRegulatoryDocumentFaq($input: DeleteRegulatoryDocumentFaqInput!) {
  deleteRegulatoryDocumentFaq(input: $input) {
    regulatoryDocument {
      id
    }
  }
}
    `;
export type DeleteRegulatoryDocumentFaqMutationFn = Apollo.MutationFunction<DeleteRegulatoryDocumentFaqMutation, DeleteRegulatoryDocumentFaqMutationVariables>;

/**
 * __useDeleteRegulatoryDocumentFaqMutation__
 *
 * To run a mutation, you first call `useDeleteRegulatoryDocumentFaqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRegulatoryDocumentFaqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRegulatoryDocumentFaqMutation, { data, loading, error }] = useDeleteRegulatoryDocumentFaqMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRegulatoryDocumentFaqMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRegulatoryDocumentFaqMutation, DeleteRegulatoryDocumentFaqMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRegulatoryDocumentFaqMutation, DeleteRegulatoryDocumentFaqMutationVariables>(DeleteRegulatoryDocumentFaqDocument, options);
      }
export type DeleteRegulatoryDocumentFaqMutationHookResult = ReturnType<typeof useDeleteRegulatoryDocumentFaqMutation>;
export type DeleteRegulatoryDocumentFaqMutationResult = Apollo.MutationResult<DeleteRegulatoryDocumentFaqMutation>;
export type DeleteRegulatoryDocumentFaqMutationOptions = Apollo.BaseMutationOptions<DeleteRegulatoryDocumentFaqMutation, DeleteRegulatoryDocumentFaqMutationVariables>;
export const DeleteRegulatoryDocumentParagraphFaqDocument = gql`
    mutation DeleteRegulatoryDocumentParagraphFaq($input: DeleteRegulatoryDocumentParagraphFaqInput!) {
  deleteRegulatoryDocumentParagraphFaq(input: $input) {
    regulatoryDocument {
      id
    }
  }
}
    `;
export type DeleteRegulatoryDocumentParagraphFaqMutationFn = Apollo.MutationFunction<DeleteRegulatoryDocumentParagraphFaqMutation, DeleteRegulatoryDocumentParagraphFaqMutationVariables>;

/**
 * __useDeleteRegulatoryDocumentParagraphFaqMutation__
 *
 * To run a mutation, you first call `useDeleteRegulatoryDocumentParagraphFaqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRegulatoryDocumentParagraphFaqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRegulatoryDocumentParagraphFaqMutation, { data, loading, error }] = useDeleteRegulatoryDocumentParagraphFaqMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRegulatoryDocumentParagraphFaqMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRegulatoryDocumentParagraphFaqMutation, DeleteRegulatoryDocumentParagraphFaqMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRegulatoryDocumentParagraphFaqMutation, DeleteRegulatoryDocumentParagraphFaqMutationVariables>(DeleteRegulatoryDocumentParagraphFaqDocument, options);
      }
export type DeleteRegulatoryDocumentParagraphFaqMutationHookResult = ReturnType<typeof useDeleteRegulatoryDocumentParagraphFaqMutation>;
export type DeleteRegulatoryDocumentParagraphFaqMutationResult = Apollo.MutationResult<DeleteRegulatoryDocumentParagraphFaqMutation>;
export type DeleteRegulatoryDocumentParagraphFaqMutationOptions = Apollo.BaseMutationOptions<DeleteRegulatoryDocumentParagraphFaqMutation, DeleteRegulatoryDocumentParagraphFaqMutationVariables>;