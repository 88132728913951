import {EntityPage} from 'components';
import {ViewAuthorizer} from 'components/ViewAuthorizer';
import {LoadWrapper} from 'components/LoadWrapper';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Tag, UserRole} from 'types';
import {
	useGetAllTagsQuery,
	useCreateTagMutation,
	useUpdateTagMutation,
	useDeleteTagMutation,
	GetAllTagsDocument,
} from './hooks/tags.generated';
import {TagsTooltipTranslationProvider} from 'features/localizedTooltips';

const AdminTagsPage: React.FC = () => {
	const {t} = useTranslation('features/adminsection', {
		keyPrefix: 'AdminTagsPage',
	});
	const {loading, data} = useGetAllTagsQuery();
	const [createTagMutation] = useCreateTagMutation();
	const [updateTagMutation] = useUpdateTagMutation();
	const [deleteTagMutation] = useDeleteTagMutation();

	const tags = React.useMemo(() => data?.tags ?? [], [data]);

	const refetchQueries = [GetAllTagsDocument];

	const createTag = React.useCallback((tag: Tag) => {
		createTagMutation({
			variables: {
				input: {
					name: tag.name,
				},
			},
			refetchQueries,
		});
	}, []);
	const updateTag = React.useCallback((tag: Tag) => {
		updateTagMutation({
			variables: {
				input: {
					id: tag.id,
					name: tag.name,
				},
			},
			refetchQueries,
		});
	}, []);
	const deleteTag = React.useCallback((id: string) => {
		deleteTagMutation({
			variables: {
				input: {id},
			},
			refetchQueries,
		});
	}, []);

	return (
		<ViewAuthorizer roles={[UserRole.SystemAdministrator]}>
			<LoadWrapper loading={loading}>
				<TagsTooltipTranslationProvider>
					<EntityPage
						items={tags}
						entityDisplayName={t('Tag')}
						createEntity={createTag}
						updateEntity={updateTag}
						deleteEntity={deleteTag}
					/>
				</TagsTooltipTranslationProvider>
			</LoadWrapper>
		</ViewAuthorizer>
	);
};

export default AdminTagsPage;
