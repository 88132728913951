import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AssignVehicleProjectPermissionsMutationVariables = Types.Exact<{
  input: Types.AssignVehicleProjectPermissionsInput;
}>;


export type AssignVehicleProjectPermissionsMutation = { __typename?: 'Mutation', assignVehicleProjectPermissions: { __typename?: 'AssignVehicleProjectPermissionsPayload', user?: Array<{ __typename?: 'User', id: string }> | null } };


export const AssignVehicleProjectPermissionsDocument = gql`
    mutation AssignVehicleProjectPermissions($input: AssignVehicleProjectPermissionsInput!) {
  assignVehicleProjectPermissions(input: $input) {
    user {
      id
    }
  }
}
    `;
export type AssignVehicleProjectPermissionsMutationFn = Apollo.MutationFunction<AssignVehicleProjectPermissionsMutation, AssignVehicleProjectPermissionsMutationVariables>;

/**
 * __useAssignVehicleProjectPermissionsMutation__
 *
 * To run a mutation, you first call `useAssignVehicleProjectPermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignVehicleProjectPermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignVehicleProjectPermissionsMutation, { data, loading, error }] = useAssignVehicleProjectPermissionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignVehicleProjectPermissionsMutation(baseOptions?: Apollo.MutationHookOptions<AssignVehicleProjectPermissionsMutation, AssignVehicleProjectPermissionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignVehicleProjectPermissionsMutation, AssignVehicleProjectPermissionsMutationVariables>(AssignVehicleProjectPermissionsDocument, options);
      }
export type AssignVehicleProjectPermissionsMutationHookResult = ReturnType<typeof useAssignVehicleProjectPermissionsMutation>;
export type AssignVehicleProjectPermissionsMutationResult = Apollo.MutationResult<AssignVehicleProjectPermissionsMutation>;
export type AssignVehicleProjectPermissionsMutationOptions = Apollo.BaseMutationOptions<AssignVehicleProjectPermissionsMutation, AssignVehicleProjectPermissionsMutationVariables>;