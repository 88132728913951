import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CloneRegulatoryDocumentMutationVariables = Types.Exact<{
  input: Types.CloneRegulatoryDocumentInput;
}>;


export type CloneRegulatoryDocumentMutation = { __typename?: 'Mutation', cloneRegulatoryDocument: { __typename?: 'RegulatoryDocumentPayload', regulatoryDocument: { __typename?: 'RegulatoryDocument', id: string, paragraphs: Array<{ __typename?: 'RegulatoryDocumentParagraph', id: string }> } } };


export const CloneRegulatoryDocumentDocument = gql`
    mutation CloneRegulatoryDocument($input: CloneRegulatoryDocumentInput!) {
  cloneRegulatoryDocument(input: $input) {
    regulatoryDocument {
      id
      paragraphs {
        id
      }
    }
  }
}
    `;
export type CloneRegulatoryDocumentMutationFn = Apollo.MutationFunction<CloneRegulatoryDocumentMutation, CloneRegulatoryDocumentMutationVariables>;

/**
 * __useCloneRegulatoryDocumentMutation__
 *
 * To run a mutation, you first call `useCloneRegulatoryDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneRegulatoryDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneRegulatoryDocumentMutation, { data, loading, error }] = useCloneRegulatoryDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloneRegulatoryDocumentMutation(baseOptions?: Apollo.MutationHookOptions<CloneRegulatoryDocumentMutation, CloneRegulatoryDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloneRegulatoryDocumentMutation, CloneRegulatoryDocumentMutationVariables>(CloneRegulatoryDocumentDocument, options);
      }
export type CloneRegulatoryDocumentMutationHookResult = ReturnType<typeof useCloneRegulatoryDocumentMutation>;
export type CloneRegulatoryDocumentMutationResult = Apollo.MutationResult<CloneRegulatoryDocumentMutation>;
export type CloneRegulatoryDocumentMutationOptions = Apollo.BaseMutationOptions<CloneRegulatoryDocumentMutation, CloneRegulatoryDocumentMutationVariables>;