import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteRegulationCommentaryMutationVariables = Types.Exact<{
  input: Types.DeleteRegulationCommentaryInput;
}>;


export type DeleteRegulationCommentaryMutation = { __typename?: 'Mutation', deleteRegulationCommentary: { __typename?: 'DeleteRegulationCommentaryPayload', regulation?: { __typename?: 'Regulation', id: string } | null } };

export type DeleteRegulatoryDocumentCommentaryMutationVariables = Types.Exact<{
  input: Types.DeleteRegulatoryDocumentCommentaryInput;
}>;


export type DeleteRegulatoryDocumentCommentaryMutation = { __typename?: 'Mutation', deleteRegulatoryDocumentCommentary: { __typename?: 'DeleteRegulatoryDocumentCommentaryPayload', regulatoryDocument?: { __typename?: 'RegulatoryDocument', id: string } | null } };

export type DeleteRegulatoryDocumentParagraphCommentaryMutationVariables = Types.Exact<{
  input: Types.DeleteRegulatoryDocumentParagraphCommentaryInput;
}>;


export type DeleteRegulatoryDocumentParagraphCommentaryMutation = { __typename?: 'Mutation', deleteRegulatoryDocumentParagraphCommentary: { __typename?: 'DeleteRegulatoryDocumentParagraphCommentaryPayload', regulatoryDocument?: { __typename?: 'RegulatoryDocument', id: string } | null } };


export const DeleteRegulationCommentaryDocument = gql`
    mutation DeleteRegulationCommentary($input: DeleteRegulationCommentaryInput!) {
  deleteRegulationCommentary(input: $input) {
    regulation {
      id
    }
  }
}
    `;
export type DeleteRegulationCommentaryMutationFn = Apollo.MutationFunction<DeleteRegulationCommentaryMutation, DeleteRegulationCommentaryMutationVariables>;

/**
 * __useDeleteRegulationCommentaryMutation__
 *
 * To run a mutation, you first call `useDeleteRegulationCommentaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRegulationCommentaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRegulationCommentaryMutation, { data, loading, error }] = useDeleteRegulationCommentaryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRegulationCommentaryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRegulationCommentaryMutation, DeleteRegulationCommentaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRegulationCommentaryMutation, DeleteRegulationCommentaryMutationVariables>(DeleteRegulationCommentaryDocument, options);
      }
export type DeleteRegulationCommentaryMutationHookResult = ReturnType<typeof useDeleteRegulationCommentaryMutation>;
export type DeleteRegulationCommentaryMutationResult = Apollo.MutationResult<DeleteRegulationCommentaryMutation>;
export type DeleteRegulationCommentaryMutationOptions = Apollo.BaseMutationOptions<DeleteRegulationCommentaryMutation, DeleteRegulationCommentaryMutationVariables>;
export const DeleteRegulatoryDocumentCommentaryDocument = gql`
    mutation DeleteRegulatoryDocumentCommentary($input: DeleteRegulatoryDocumentCommentaryInput!) {
  deleteRegulatoryDocumentCommentary(input: $input) {
    regulatoryDocument {
      id
    }
  }
}
    `;
export type DeleteRegulatoryDocumentCommentaryMutationFn = Apollo.MutationFunction<DeleteRegulatoryDocumentCommentaryMutation, DeleteRegulatoryDocumentCommentaryMutationVariables>;

/**
 * __useDeleteRegulatoryDocumentCommentaryMutation__
 *
 * To run a mutation, you first call `useDeleteRegulatoryDocumentCommentaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRegulatoryDocumentCommentaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRegulatoryDocumentCommentaryMutation, { data, loading, error }] = useDeleteRegulatoryDocumentCommentaryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRegulatoryDocumentCommentaryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRegulatoryDocumentCommentaryMutation, DeleteRegulatoryDocumentCommentaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRegulatoryDocumentCommentaryMutation, DeleteRegulatoryDocumentCommentaryMutationVariables>(DeleteRegulatoryDocumentCommentaryDocument, options);
      }
export type DeleteRegulatoryDocumentCommentaryMutationHookResult = ReturnType<typeof useDeleteRegulatoryDocumentCommentaryMutation>;
export type DeleteRegulatoryDocumentCommentaryMutationResult = Apollo.MutationResult<DeleteRegulatoryDocumentCommentaryMutation>;
export type DeleteRegulatoryDocumentCommentaryMutationOptions = Apollo.BaseMutationOptions<DeleteRegulatoryDocumentCommentaryMutation, DeleteRegulatoryDocumentCommentaryMutationVariables>;
export const DeleteRegulatoryDocumentParagraphCommentaryDocument = gql`
    mutation DeleteRegulatoryDocumentParagraphCommentary($input: DeleteRegulatoryDocumentParagraphCommentaryInput!) {
  deleteRegulatoryDocumentParagraphCommentary(input: $input) {
    regulatoryDocument {
      id
    }
  }
}
    `;
export type DeleteRegulatoryDocumentParagraphCommentaryMutationFn = Apollo.MutationFunction<DeleteRegulatoryDocumentParagraphCommentaryMutation, DeleteRegulatoryDocumentParagraphCommentaryMutationVariables>;

/**
 * __useDeleteRegulatoryDocumentParagraphCommentaryMutation__
 *
 * To run a mutation, you first call `useDeleteRegulatoryDocumentParagraphCommentaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRegulatoryDocumentParagraphCommentaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRegulatoryDocumentParagraphCommentaryMutation, { data, loading, error }] = useDeleteRegulatoryDocumentParagraphCommentaryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRegulatoryDocumentParagraphCommentaryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRegulatoryDocumentParagraphCommentaryMutation, DeleteRegulatoryDocumentParagraphCommentaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRegulatoryDocumentParagraphCommentaryMutation, DeleteRegulatoryDocumentParagraphCommentaryMutationVariables>(DeleteRegulatoryDocumentParagraphCommentaryDocument, options);
      }
export type DeleteRegulatoryDocumentParagraphCommentaryMutationHookResult = ReturnType<typeof useDeleteRegulatoryDocumentParagraphCommentaryMutation>;
export type DeleteRegulatoryDocumentParagraphCommentaryMutationResult = Apollo.MutationResult<DeleteRegulatoryDocumentParagraphCommentaryMutation>;
export type DeleteRegulatoryDocumentParagraphCommentaryMutationOptions = Apollo.BaseMutationOptions<DeleteRegulatoryDocumentParagraphCommentaryMutation, DeleteRegulatoryDocumentParagraphCommentaryMutationVariables>;