import {
	DocumentReference,
	GdprRule,
	PhaseDate,
	RegulatoryDocumentParagraph,
	Requirement,
	RequirementStatus,
} from 'types';
import {RequirementUtilsService} from '../RequirementUtils.service';
import {
	CreateRequirementMutation,
	useCreateRequirementMutation,
} from './requirements.generated';
import React from 'react';
import {useReassignAllRegulatoryDocumentsForClonedRequirementMutation} from 'features/RegulatoryDocuments/hooks/useReassignAllRegulatoryDocumentsForClonedRequirement.generated';
import {FetchResult} from '@apollo/client';
import {RequirementFromListPage} from '../requirementPage.types';
import {useRequirementsVersions} from './useRequirementsVersions';
import {useUpdateRequirementsVersionsMutation} from 'features/RegulatoryDocuments/hooks/useUpdateRequirementsVersions.generated';
import {mapToRef} from 'helpers';
import {GetRegulatoryDocumentDetailsDocument} from 'features/RegulatoryDocuments/hooks/useGetRegulatoryDocumentDetails.generated';

const {createCloneCreationInputFields} = new RequirementUtilsService();
export const useCloneRequirement = () => {
	const [createRequirementMutation] = useCreateRequirementMutation();
	const [updateParagraphsRequirements] =
		useReassignAllRegulatoryDocumentsForClonedRequirementMutation();
	const [updateRequirementsVersions] = useUpdateRequirementsVersionsMutation();
	const {getInitialVersion, incrementMinorVersion, getVersionMatches} =
		useRequirementsVersions();

	const getMatchingVersions = (versionsArray: string[], version: string) => {
		const {major: majorVersion} = getVersionMatches(version);

		return versionsArray
			.filter(v => {
				const {major} = getVersionMatches(v);
				return major === majorVersion;
			})
			.map(v => v.substring(1).split('.').map(Number));
	};
	const getHighestVersion = (matchingVersions: number[][]) => {
		const highestMinorVersion = matchingVersions.reduce((max, current) => {
			return current[1] > max[1] ? current : max;
		});
		return `V${highestMinorVersion[0]}.${highestMinorVersion[1]}`;
	};

	const findHighestMinorVersion = (
		version: string,
		versionsArray: string[],
	) => {
		if (versionsArray?.length === 0) {
			return version;
		}

		const matchingVersions = getMatchingVersions(versionsArray, version);

		if (matchingVersions.length === 0) {
			return version;
		}

		return getHighestVersion(matchingVersions);
	};

	const cloneRequirement = React.useCallback(
		async (
			requirement: Requirement,
			refetchQueries: any,
			rule: GdprRule | undefined | null,
			origComparableRequiremnt?: RequirementFromListPage,
		): Promise<FetchResult<CreateRequirementMutation> | undefined> => {
			const relevantMetadataChanged = hasRequirementRelevantMetaDataChanged(
				requirement,
				origComparableRequiremnt,
			);

			const nonRelevantMetadataChanged =
				hasRequirementNonRelevantMetaDataChanged(
					requirement,
					origComparableRequiremnt,
				);

			if (!relevantMetadataChanged && !nonRelevantMetadataChanged) {
				return undefined;
			}

			const requirementVersion = requirement.version || getInitialVersion();
			const version = relevantMetadataChanged
				? requirementVersion
				: incrementMinorVersion(requirementVersion);

			const status = relevantMetadataChanged
				? RequirementStatus.Modified
				: RequirementStatus.Final;

			const newCloneRes = await createRequirementMutation({
				variables: {
					input: createCloneCreationInputFields(
						requirement,
						rule,
						version,
						status,
					),
				},
				refetchQueries,
			});

			const newClone = newCloneRes.data?.createRequirement?.requirement;

			if (newClone?.id) {
				const {id: originalReqId} = requirement as Requirement;
				await reassignAllRegulatoryDocumentsForClonedRequirement(
					originalReqId,
					newClone.id,
					refetchQueries,
				);
			}

			return newCloneRes;
		},
		[createRequirementMutation, createCloneCreationInputFields],
	);

	const createCloneForMerging = React.useCallback(
		async (
			requirement: Requirement,

			rule: GdprRule | undefined | null,
			versions: string[],
			isLastElement: boolean,
		): Promise<string | undefined> => {
			const highestVersion = findHighestMinorVersion(
				requirement.version || getInitialVersion(),
				versions,
			);

			const version = incrementMinorVersion(highestVersion);

			const status = RequirementStatus.Final;

			const newCloneRes = await createRequirementMutation({
				variables: {
					input: createCloneCreationInputFields(
						requirement,
						rule,
						version,
						status,
					),
				},
			});

			const newClone = newCloneRes.data?.createRequirement?.requirement;

			if (newClone?.id) {
				const {id: originalReqId} = requirement as Requirement;

				await reassignAllRegulatoryDocumentsForClonedRequirement(
					originalReqId,
					newClone.id,
					isLastElement ? [GetRegulatoryDocumentDetailsDocument] : [],
				);
			}

			return newClone?.id;
		},
		[createRequirementMutation, createCloneCreationInputFields],
	);

	const hasRequirementRelevantMetaDataChanged = (
		edited: Requirement,
		original?: RequirementFromListPage,
	): boolean => {
		if (!original) {
			return true;
		}

		const {
			name,
			definition,
			category,
			systemLevels,
			vexClusters,
			gearboxVariants,
			bodyworkVariants,
			activeTypeMarkets,
			engineVariants,
			driveVariants,
			vehicleCategories,
			documentReferences,
		} = original;
		if (
			edited.name !== name ||
			edited.definition !== definition ||
			edited.category !== category
		)
			return true;

		if (
			hasCollectionChanged(edited.systemLevels, systemLevels) ||
			hasCollectionChanged(edited.vexClusters, vexClusters) ||
			hasCollectionChanged(edited.gearboxVariants, gearboxVariants) ||
			hasCollectionChanged(edited.bodyworkVariants, bodyworkVariants) ||
			hasCollectionChanged(edited.activeTypeMarkets, activeTypeMarkets) ||
			hasCollectionChanged(edited.engineVariants, engineVariants) ||
			hasCollectionChanged(edited.driveVariants, driveVariants) ||
			hasCollectionChanged(edited.vehicleCategories, vehicleCategories) ||
			haveDocumentReferencesChanged(
				edited.documentReferences || undefined,
				documentReferences || undefined,
			)
		) {
			return true;
		}

		return false;
	};

	const hasRequirementNonRelevantMetaDataChanged = (
		edited: Requirement,
		original?: RequirementFromListPage,
	): boolean => {
		if (!original) {
			return true;
		}

		const mappedEditedAttachments =
			edited.attachments?.map(a => {
				return {
					id: a.attachmentId,
				};
			}) || [];

		const mappedOriginalAttachments =
			original.attachments?.map(a => {
				return {
					id: a.attachmentId,
				};
			}) || [];

		if (
			hasCollectionChanged(
				mappedEditedAttachments,
				mappedOriginalAttachments,
			) ||
			hasCollectionChanged(edited.tags, original.tags)
		) {
			return true;
		}

		return false;
	};

	const haveDocumentReferencesChanged = (
		edited?: DocumentReference[],
		original?: {
			reference?:
				| {
						id: string;
				  }
				| null
				| undefined;
			referenceDocumentType?: string | null | undefined;
			regulationReferences?: {id: string}[];
			regulatoryDocumentParagraphReferences?: {id: string}[];
			regulatoryDocumentReferences?: {id: string}[];
			requirementReferences?: {id: string}[];
		}[],
	) => {
		if (!edited && !original) return false;
		if (!original) {
			return edited?.length !== 0;
		}

		if (!edited) {
			return original?.length !== 0;
		}

		if (edited.length !== original.length) {
			return true;
		}

		for (let i = 0; i < edited.length; i++) {
			const e = edited[i];
			const o = original[i];
			if (e.reference?.id !== o?.reference?.id) {
				return true;
			}

			if (e.referenceDocumentType !== o.referenceDocumentType) {
				return true;
			}

			const regDocParRefs = e.regulatoryDocumentParagraphReferences
				? e?.regulatoryDocumentParagraphReferences.map(rdpr => {
						return rdpr === null ? {id: ''} : rdpr;
				  })
				: ([] as {id: string}[]);

			if (
				hasCollectionChanged(e.regulationReferences, o.regulationReferences) ||
				hasCollectionChanged(
					regDocParRefs,
					o.regulatoryDocumentParagraphReferences,
				) ||
				hasCollectionChanged(
					e.regulatoryDocumentReferences,
					o.regulatoryDocumentReferences,
				) ||
				hasCollectionChanged(e.requirementReferences, o.requirementReferences)
			) {
				return true;
			}
		}

		return false;
	};

	const hasCollectionChanged = (
		edited: {id: string}[],
		original?: {id: string}[],
	): boolean => {
		if (!original) {
			return edited.length !== 0;
		}

		return (
			edited.filter(
				editedEntry =>
					original.findIndex(
						originalEntry => originalEntry.id === editedEntry.id,
					) !== -1,
			).length !== edited.length
		);
	};

	const reassignAllRegulatoryDocumentsForClonedRequirement = async (
		sourceRequirementId: string,
		distRequirementId: string,
		refetchQueries?: any,
	) => {
		updateParagraphsRequirements({
			variables: {
				input: {
					sourceRequirementId,
					distRequirementId,
				},
			},
			refetchQueries,
		});
	};

	const hasPhaseDateChange = (
		edited?: PhaseDate[],
		original?: PhaseDate[],
	): boolean => {
		if (!edited && original && original.length > 0) return true;
		if (!original && edited && edited?.length > 0) return true;

		return (
			edited?.filter(
				editedDate =>
					original?.findIndex(
						originalDate =>
							originalDate.date === editedDate.date &&
							originalDate.status === editedDate.status,
					) !== -1,
			).length !== edited?.length
		);
	};

	const hasParagraphRelevantMetaDataChanged = (
		edited: any,
		original: RegulatoryDocumentParagraph,
	) => {
		// Cconst {modelYear, comprehensive, dateNewTypes, dateNewRegistration} = originalComparableParagraph;
		const {
			keywords,
			driveVariants,
			vehicleCategories,
			categories,
			tags,
			phaseIn,
			phaseOut,
			summary,
		} = original;

		if (
			hasCollectionChanged(mapToRef(edited.keywordRefs), keywords) ||
			hasCollectionChanged(edited.driveVariantRefs, driveVariants) ||
			hasCollectionChanged(edited.vehicleCategoryRefs, vehicleCategories) ||
			hasCollectionChanged(edited.categoryRefs, categories) ||
			hasCollectionChanged(edited.tagRefs, tags) ||
			hasPhaseDateChange(edited.phaseIn as PhaseDate[], phaseIn || undefined) ||
			hasPhaseDateChange(
				edited.phaseOut as PhaseDate[],
				phaseOut || undefined,
			) ||
			edited.summary !== summary
		) {
			return true;
		}

		return false;
	};

	const updateFinalRequirementsVersions = async (
		paragraph: any,
		originalComparableParagraphs: RegulatoryDocumentParagraph[],
	): Promise<boolean> => {
		let updatablaRequirements: {id: string; version: string}[] = [];

		originalComparableParagraphs.forEach(originalParagraph => {
			const relevantMetadataChanged = hasParagraphRelevantMetaDataChanged(
				paragraph,
				originalParagraph,
			);

			if (!relevantMetadataChanged) {
				const filteredReqs = originalParagraph.requirements.reduce(
					(acc, req) => {
						if (req.status === RequirementStatus.Final) {
							acc.push({
								id: req.id,
								version: req.version || getInitialVersion(),
							});
						}

						return acc;
					},
					[] as {id: string; version: string}[],
				);
				updatablaRequirements = updatablaRequirements.concat(filteredReqs);
			}
		});

		if (updatablaRequirements.length === 0) {
			return false;
		}

		const newVersions = updatablaRequirements.map(req => {
			return incrementMinorVersion(req.version || getInitialVersion());
		});

		await updateRequirementsVersions({
			variables: {
				input: {
					requirementRefs: updatablaRequirements.map(ur => {
						return {
							id: ur.id,
						};
					}),
					newVersions,
				},
			},
		});
		return true;
	};

	return {
		cloneRequirement,
		getInitialVersion,
		createCloneForMerging,
		updateFinalRequirementsVersions,
	};
};
