import React from 'react';
import {TFunction, useTranslation} from 'react-i18next';
import {
	GetAllReferencesDocument,
	useCreateReferenceMutation,
	useDeleteReferenceMutation,
	useGetAllReferencesQuery,
	useUpdateReferenceMutation,
} from './hooks/references.generated';
import {ViewAuthorizer} from 'components/ViewAuthorizer';
import {LoadWrapper} from 'components/LoadWrapper';
import {EntityListColumn, EntityPage} from 'components';
import {Reference, ReferenceType, UserRole} from 'types';
import {Control} from 'react-hook-form';
import {ControlledCheckbox} from 'components/hookForms/ControlledCheckbox';
import {
	ProviderThatEnablesGettingTooltipsFromContext,
	ReferencesTooltipTranslationProvider,
	createTooltipTranslationProviderFromNamespace,
} from 'features/localizedTooltips';

interface FormElementsProps {
	control: Control<Reference>;
	t: TFunction;
}

const TranslationProvider = createTooltipTranslationProviderFromNamespace(
	'features/AdminSection/adminReferencePage',
);

const FormElements: React.FC<FormElementsProps> = ({control, t}) => {
	const enumCheckboxes = Object.keys(ReferenceType).map(key => (
		<TranslationProvider key={key} treatAsDottedFormFieldName>
			<ProviderThatEnablesGettingTooltipsFromContext>
				<ControlledCheckbox
					name={`referenceTypes.${key}`}
					label={t(
						`References.${ReferenceType[key as keyof typeof ReferenceType]}`,
					)}
					control={control}
				/>
			</ProviderThatEnablesGettingTooltipsFromContext>
		</TranslationProvider>
	));

	return <div>{enumCheckboxes}</div>;
};

const ReferenceTypesRenderer: React.FC<{item: Reference; t: TFunction}> = ({
	item,
	t,
}) => (
	<div>
		{Object.keys(item.referenceTypes).map((key: any) => {
			if (item.referenceTypes[key]) {
				return <div key={key}>{t(`References.${key}`)}</div>;
			}

			return null;
		})}
	</div>
);

const AdminReferencePage: React.FC = () => {
	const {t} = useTranslation('features/adminsection', {
		keyPrefix: 'AdminReferencePage',
	});

	const {loading, data} = useGetAllReferencesQuery();
	const [createReferenceMutation] = useCreateReferenceMutation();
	const [updateReferenceMutation] = useUpdateReferenceMutation();
	const [deleteReferenceMutation] = useDeleteReferenceMutation();

	const transformReferenceTypes = (
		referenceTypes: ReferenceType[],
	): Record<string, boolean> => {
		const transformedReferenceTypes: Record<string, boolean> = {};

		Object.keys(ReferenceType).forEach(key => {
			transformedReferenceTypes[key] = referenceTypes.includes(
				ReferenceType[key as keyof typeof ReferenceType],
			);
		});

		return transformedReferenceTypes;
	};

	const references = React.useMemo(() => {
		return (
			data?.references?.map(reference => ({
				...reference,
				referenceTypes: transformReferenceTypes(reference.referenceTypes),
			})) ?? []
		);
	}, [data]);

	const refetchQueries = [GetAllReferencesDocument];

	const createReference = React.useCallback((reference: Reference) => {
		const selectedReferenceTypes = Object.keys(reference.referenceTypes)
			.filter((key: any) => reference.referenceTypes[key])
			.map(key => ReferenceType[key as keyof typeof ReferenceType]);

		createReferenceMutation({
			variables: {
				input: {
					name: reference.name,
					referenceTypes: selectedReferenceTypes,
				},
			},
			refetchQueries,
		}).catch(error => {
			console.error('Error creating reference:', error);
		});
	}, []);

	const updateReference = React.useCallback((reference: Reference) => {
		const selectedReferenceTypes = Object.keys(reference.referenceTypes)
			.filter((key: any) => {
				return isNaN(Number(key)) && reference.referenceTypes[key];
			})
			.map(key => ReferenceType[key as keyof typeof ReferenceType]);

		updateReferenceMutation({
			variables: {
				input: {
					id: reference.id,
					name: reference.name,
					referenceTypes: selectedReferenceTypes,
				},
			},
			refetchQueries,
		}).catch(error => {
			console.error('Error creating reference:', error);
		});
	}, []);

	const deleteReference = React.useCallback((id: string) => {
		deleteReferenceMutation({
			variables: {
				input: {id},
			},
			refetchQueries,
		}).catch(error => {
			console.error('Error creating reference:', error);
		});
	}, []);

	const columns: EntityListColumn[] = React.useMemo(
		() => [
			{
				key: 'referenceTypes',
				name: t('ReferenceTypes'),
				fieldName: 'referenceTypes',
				minWidth: 200,
				maxWidth: 400,
				onRender: (item: Reference) => (
					<ReferenceTypesRenderer item={item} t={t} />
				),
			},
		],
		[t],
	);

	return (
		<ViewAuthorizer roles={[UserRole.SystemAdministrator]}>
			<LoadWrapper loading={loading}>
				<ReferencesTooltipTranslationProvider>
					<EntityPage
						items={references as any}
						entityDisplayName={t('Reference')}
						createEntity={createReference}
						updateEntity={updateReference}
						deleteEntity={deleteReference}
						additionalColumns={columns}
						renderAdditionalFormElements={control => (
							<FormElements control={control} t={t} />
						)}
					/>
				</ReferencesTooltipTranslationProvider>
			</LoadWrapper>
		</ViewAuthorizer>
	);
};

export default AdminReferencePage;
