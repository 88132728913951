import {Label, Stack} from '@fluentui/react';
import {ChangeHistory} from 'components/ChangeHistory';
import React from 'react';
import {AuditLog} from 'types';

export interface PropsOfArrayFieldWrapper {
	label: string;
	audit?: boolean;
	auditLogData?: AuditLog[];
	auditFieldName?: string;
	children: JSX.Element;
}

export const ArrayFieldWrapper: React.FC<PropsOfArrayFieldWrapper> = ({
	label,
	audit,
	auditLogData,
	auditFieldName,
	children,
}) => {
	return (
		<div>
			<Stack horizontal>
				<Label>{label}</Label>
				{audit && (
					<ChangeHistory
						data={auditLogData ?? []}
						auditFieldName={auditFieldName ?? ''}
					/>
				)}
			</Stack>
			{children}
		</div>
	);
};
