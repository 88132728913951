import {useCommand} from 'hooks';
import {useVehicleProjectsTranslation} from '../hooks/vehicleProjectsTranslationHooks';
import {IContextualMenuItem as MenuItem} from '@fluentui/react';
import {useUserContext, UserContextType} from 'authentication/UserContext';
import {useMemo} from 'react';
import {useBoolean} from '@fluentui/react-hooks';
import {BooleanState} from 'types/fluentUi';

import {ProjectDetailsPageParams} from 'features/VehicleProjects/vehicleProjectPagePaths';
import {
	NavigateToBaselineListPage,
	useNavigateToBaselineListPage,
} from './BaselinesPage.utils';
import {Baseline} from '../BaselineDetailsPage/BaselineDetailsPage.types';

export interface BaselineCmdsInfo {
	infoForIsSuccessDialogOpen: BooleanState;
}

interface Options {
	params: ProjectDetailsPageParams;
}

export const useBaselineListCmds = (
	{params}: Options,
	baselines: Baseline[],
	setRenameDialogOpen: (isOpen: boolean) => void,
): BaselineCmdsInfo => {
	const {isAdmin}: UserContextType = useUserContext();
	const {t} = useVehicleProjectsTranslation({keyPrefix: 'BaselineCmds'});
	const navigateToBaselinesPage: NavigateToBaselineListPage =
		useNavigateToBaselineListPage(params);

	const infoForIsSuccessDialogOpen: BooleanState = useBoolean(false);
	const [, {setTrue: setVisible}] = infoForIsSuccessDialogOpen;

	const subcommands = useMemo((): MenuItem[] => {
		/**
		 * We define another function here so that the return type is void. This is
		 * required to avoid type errors.
		 */
		const handleCreation = (): void => {
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			setVisible();
		};

		const createOverviewMenuItem = (): MenuItem => {
			return {
				key: 'Overview',
				text: t('ViewOverview'),
				onClick: navigateToBaselinesPage,
			};
		};

		const createCreateMenuItem = (): MenuItem => {
			return {
				key: 'Create',
				text: t('Create'),
				onClick() {
					handleCreation();
				},
			};
		};

		const getCmdsForAllRoles = (): MenuItem[] => {
			const overviewMenuItem: MenuItem = createOverviewMenuItem();
			const createMenuItem: MenuItem = createCreateMenuItem();

			return [overviewMenuItem, createMenuItem];
		};

		return getCmdsForAllRoles();
	}, [setVisible, t, navigateToBaselinesPage]);

	useCommand(
		{
			key: 'BaselineRename',
			text: t('BaselineRename'),
			onClick() {
				setRenameDialogOpen(true);
			},
			iconProps: {iconName: 'Edit'},

			hidden: baselines.length === 0,
			priority: 10,
		},
		[baselines],
	);

	return {
		infoForIsSuccessDialogOpen,
	};
};
