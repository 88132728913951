import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllEngineVariantsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllEngineVariantsQuery = { __typename?: 'Query', engineVariants?: Array<{ __typename?: 'EngineVariant', id: string, name: string, createdAt?: any | null, modifiedAt?: any | null, createdBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null }, modifiedBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null } }> | null };

export type CreateEngineVariantMutationVariables = Types.Exact<{
  input: Types.CreateEngineVariantInput;
}>;


export type CreateEngineVariantMutation = { __typename?: 'Mutation', createEngineVariant: { __typename?: 'CreateEngineVariantPayload', engineVariant?: { __typename?: 'EngineVariant', id: string } | null } };

export type UpdateEngineVariantMutationVariables = Types.Exact<{
  input: Types.UpdateEngineVariantInput;
}>;


export type UpdateEngineVariantMutation = { __typename?: 'Mutation', updateEngineVariant: { __typename?: 'UpdateEngineVariantPayload', engineVariant?: { __typename?: 'EngineVariant', id: string } | null } };

export type DeleteEngineVariantMutationVariables = Types.Exact<{
  input: Types.DeleteEngineVariantInput;
}>;


export type DeleteEngineVariantMutation = { __typename?: 'Mutation', deleteEngineVariant: { __typename?: 'DeleteEngineVariantPayload', engineVariant?: { __typename?: 'EngineVariant', id: string } | null } };


export const GetAllEngineVariantsDocument = gql`
    query GetAllEngineVariants {
  engineVariants {
    id
    name
    createdAt
    createdBy {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
    modifiedAt
    modifiedBy {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
  }
}
    `;

/**
 * __useGetAllEngineVariantsQuery__
 *
 * To run a query within a React component, call `useGetAllEngineVariantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEngineVariantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllEngineVariantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllEngineVariantsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllEngineVariantsQuery, GetAllEngineVariantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllEngineVariantsQuery, GetAllEngineVariantsQueryVariables>(GetAllEngineVariantsDocument, options);
      }
export function useGetAllEngineVariantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllEngineVariantsQuery, GetAllEngineVariantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllEngineVariantsQuery, GetAllEngineVariantsQueryVariables>(GetAllEngineVariantsDocument, options);
        }
export type GetAllEngineVariantsQueryHookResult = ReturnType<typeof useGetAllEngineVariantsQuery>;
export type GetAllEngineVariantsLazyQueryHookResult = ReturnType<typeof useGetAllEngineVariantsLazyQuery>;
export type GetAllEngineVariantsQueryResult = Apollo.QueryResult<GetAllEngineVariantsQuery, GetAllEngineVariantsQueryVariables>;
export const CreateEngineVariantDocument = gql`
    mutation CreateEngineVariant($input: CreateEngineVariantInput!) {
  createEngineVariant(input: $input) {
    engineVariant {
      id
    }
  }
}
    `;
export type CreateEngineVariantMutationFn = Apollo.MutationFunction<CreateEngineVariantMutation, CreateEngineVariantMutationVariables>;

/**
 * __useCreateEngineVariantMutation__
 *
 * To run a mutation, you first call `useCreateEngineVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEngineVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEngineVariantMutation, { data, loading, error }] = useCreateEngineVariantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEngineVariantMutation(baseOptions?: Apollo.MutationHookOptions<CreateEngineVariantMutation, CreateEngineVariantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEngineVariantMutation, CreateEngineVariantMutationVariables>(CreateEngineVariantDocument, options);
      }
export type CreateEngineVariantMutationHookResult = ReturnType<typeof useCreateEngineVariantMutation>;
export type CreateEngineVariantMutationResult = Apollo.MutationResult<CreateEngineVariantMutation>;
export type CreateEngineVariantMutationOptions = Apollo.BaseMutationOptions<CreateEngineVariantMutation, CreateEngineVariantMutationVariables>;
export const UpdateEngineVariantDocument = gql`
    mutation UpdateEngineVariant($input: UpdateEngineVariantInput!) {
  updateEngineVariant(input: $input) {
    engineVariant {
      id
    }
  }
}
    `;
export type UpdateEngineVariantMutationFn = Apollo.MutationFunction<UpdateEngineVariantMutation, UpdateEngineVariantMutationVariables>;

/**
 * __useUpdateEngineVariantMutation__
 *
 * To run a mutation, you first call `useUpdateEngineVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEngineVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEngineVariantMutation, { data, loading, error }] = useUpdateEngineVariantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEngineVariantMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEngineVariantMutation, UpdateEngineVariantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEngineVariantMutation, UpdateEngineVariantMutationVariables>(UpdateEngineVariantDocument, options);
      }
export type UpdateEngineVariantMutationHookResult = ReturnType<typeof useUpdateEngineVariantMutation>;
export type UpdateEngineVariantMutationResult = Apollo.MutationResult<UpdateEngineVariantMutation>;
export type UpdateEngineVariantMutationOptions = Apollo.BaseMutationOptions<UpdateEngineVariantMutation, UpdateEngineVariantMutationVariables>;
export const DeleteEngineVariantDocument = gql`
    mutation DeleteEngineVariant($input: DeleteEngineVariantInput!) {
  deleteEngineVariant(input: $input) {
    engineVariant {
      id
    }
  }
}
    `;
export type DeleteEngineVariantMutationFn = Apollo.MutationFunction<DeleteEngineVariantMutation, DeleteEngineVariantMutationVariables>;

/**
 * __useDeleteEngineVariantMutation__
 *
 * To run a mutation, you first call `useDeleteEngineVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEngineVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEngineVariantMutation, { data, loading, error }] = useDeleteEngineVariantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteEngineVariantMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEngineVariantMutation, DeleteEngineVariantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEngineVariantMutation, DeleteEngineVariantMutationVariables>(DeleteEngineVariantDocument, options);
      }
export type DeleteEngineVariantMutationHookResult = ReturnType<typeof useDeleteEngineVariantMutation>;
export type DeleteEngineVariantMutationResult = Apollo.MutationResult<DeleteEngineVariantMutation>;
export type DeleteEngineVariantMutationOptions = Apollo.BaseMutationOptions<DeleteEngineVariantMutation, DeleteEngineVariantMutationVariables>;