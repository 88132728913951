import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetGdprRulesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetGdprRulesQuery = { __typename?: 'Query', gdprRules?: Array<{ __typename?: 'GdprRule', id: string, name: string, createdAt?: any | null, modifiedAt?: any | null, deletionPeriod: number, anonymize: boolean, createdBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null }, modifiedBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null } }> | null };

export type GetGdprRuleQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetGdprRuleQuery = { __typename?: 'Query', gdprRule?: { __typename?: 'GdprRule', id: string, name: string, createdAt?: any | null, modifiedAt?: any | null, deletionPeriod: number, anonymize: boolean, createdBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null }, modifiedBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null } } | null };

export type CreateGdprRuleMutationVariables = Types.Exact<{
  input: Types.CreateGdprRuleInput;
}>;


export type CreateGdprRuleMutation = { __typename?: 'Mutation', createGdprRule: { __typename?: 'CreateGdprRulePayload', gdprRule?: { __typename?: 'GdprRule', id: string } | null } };

export type UpdateGdprRuleMutationVariables = Types.Exact<{
  input: Types.UpdateGdprRuleInput;
}>;


export type UpdateGdprRuleMutation = { __typename?: 'Mutation', updateGdprRule: { __typename?: 'UpdateGdprRulePayload', gdprRule?: { __typename?: 'GdprRule', id: string } | null } };


export const GetGdprRulesDocument = gql`
    query GetGdprRules {
  gdprRules {
    id
    name
    createdAt
    createdBy {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
    modifiedAt
    modifiedBy {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
    deletionPeriod
    anonymize
  }
}
    `;

/**
 * __useGetGdprRulesQuery__
 *
 * To run a query within a React component, call `useGetGdprRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGdprRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGdprRulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGdprRulesQuery(baseOptions?: Apollo.QueryHookOptions<GetGdprRulesQuery, GetGdprRulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGdprRulesQuery, GetGdprRulesQueryVariables>(GetGdprRulesDocument, options);
      }
export function useGetGdprRulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGdprRulesQuery, GetGdprRulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGdprRulesQuery, GetGdprRulesQueryVariables>(GetGdprRulesDocument, options);
        }
export type GetGdprRulesQueryHookResult = ReturnType<typeof useGetGdprRulesQuery>;
export type GetGdprRulesLazyQueryHookResult = ReturnType<typeof useGetGdprRulesLazyQuery>;
export type GetGdprRulesQueryResult = Apollo.QueryResult<GetGdprRulesQuery, GetGdprRulesQueryVariables>;
export const GetGdprRuleDocument = gql`
    query GetGdprRule($id: ID!) {
  gdprRule(id: $id) {
    id
    name
    createdAt
    createdBy {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
    modifiedAt
    modifiedBy {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
    deletionPeriod
    anonymize
  }
}
    `;

/**
 * __useGetGdprRuleQuery__
 *
 * To run a query within a React component, call `useGetGdprRuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGdprRuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGdprRuleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGdprRuleQuery(baseOptions: Apollo.QueryHookOptions<GetGdprRuleQuery, GetGdprRuleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGdprRuleQuery, GetGdprRuleQueryVariables>(GetGdprRuleDocument, options);
      }
export function useGetGdprRuleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGdprRuleQuery, GetGdprRuleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGdprRuleQuery, GetGdprRuleQueryVariables>(GetGdprRuleDocument, options);
        }
export type GetGdprRuleQueryHookResult = ReturnType<typeof useGetGdprRuleQuery>;
export type GetGdprRuleLazyQueryHookResult = ReturnType<typeof useGetGdprRuleLazyQuery>;
export type GetGdprRuleQueryResult = Apollo.QueryResult<GetGdprRuleQuery, GetGdprRuleQueryVariables>;
export const CreateGdprRuleDocument = gql`
    mutation CreateGdprRule($input: CreateGdprRuleInput!) {
  createGdprRule(input: $input) {
    gdprRule {
      id
    }
  }
}
    `;
export type CreateGdprRuleMutationFn = Apollo.MutationFunction<CreateGdprRuleMutation, CreateGdprRuleMutationVariables>;

/**
 * __useCreateGdprRuleMutation__
 *
 * To run a mutation, you first call `useCreateGdprRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGdprRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGdprRuleMutation, { data, loading, error }] = useCreateGdprRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGdprRuleMutation(baseOptions?: Apollo.MutationHookOptions<CreateGdprRuleMutation, CreateGdprRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGdprRuleMutation, CreateGdprRuleMutationVariables>(CreateGdprRuleDocument, options);
      }
export type CreateGdprRuleMutationHookResult = ReturnType<typeof useCreateGdprRuleMutation>;
export type CreateGdprRuleMutationResult = Apollo.MutationResult<CreateGdprRuleMutation>;
export type CreateGdprRuleMutationOptions = Apollo.BaseMutationOptions<CreateGdprRuleMutation, CreateGdprRuleMutationVariables>;
export const UpdateGdprRuleDocument = gql`
    mutation UpdateGdprRule($input: UpdateGdprRuleInput!) {
  updateGdprRule(input: $input) {
    gdprRule {
      id
    }
  }
}
    `;
export type UpdateGdprRuleMutationFn = Apollo.MutationFunction<UpdateGdprRuleMutation, UpdateGdprRuleMutationVariables>;

/**
 * __useUpdateGdprRuleMutation__
 *
 * To run a mutation, you first call `useUpdateGdprRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGdprRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGdprRuleMutation, { data, loading, error }] = useUpdateGdprRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGdprRuleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGdprRuleMutation, UpdateGdprRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGdprRuleMutation, UpdateGdprRuleMutationVariables>(UpdateGdprRuleDocument, options);
      }
export type UpdateGdprRuleMutationHookResult = ReturnType<typeof useUpdateGdprRuleMutation>;
export type UpdateGdprRuleMutationResult = Apollo.MutationResult<UpdateGdprRuleMutation>;
export type UpdateGdprRuleMutationOptions = Apollo.BaseMutationOptions<UpdateGdprRuleMutation, UpdateGdprRuleMutationVariables>;