import {useTranslation} from 'react-i18next';
import {getListClassNames} from 'styles';
import {useGetMyPendingApprovalsQuery} from '../hooks/myPendingApprovals.generated';
import React from 'react';
import {Link} from 'react-router-dom';
import {Stack, useTheme, List, Text} from '@fluentui/react';
import {LoadWrapper} from 'components/LoadWrapper';
import {WorkbenchHeader} from './Headers';

type MyPendingApprovalsItemsProps = {
	halfHeight?: boolean;
	inProcess?: false;
};

export const MyPendingApprovalsItems: React.FC<
	MyPendingApprovalsItemsProps
> = ({halfHeight = false, inProcess = false}) => {
	const {i18n, t: tEnum} = useTranslation('common/enums', {
		keyPrefix: 'WorkflowStatus',
	});
	const {t} = useTranslation('features/dashboard', {
		keyPrefix: 'WorkbenchList',
	});

	const {data, loading} = useGetMyPendingApprovalsQuery();
	const theme = useTheme();
	const classNames = getListClassNames(theme, halfHeight);

	const items = React.useMemo(() => data?.myPendingApprovals, [data]);

	const onRenderCell = (item: any): JSX.Element => {
		return (
			<div className={classNames.itemCell} data-is-focusable={true}>
				<div className={classNames.itemContent}>
					<Stack horizontal horizontalAlign={'space-between'}>
						<Stack>
							<Text>{item.name}</Text>
							<Link to={`/requirements/${item.id}`} className={classNames.link}>
								<div className={classNames.itemName}>{item.name}</div>
							</Link>
						</Stack>
					</Stack>
				</div>
			</div>
		);
	};

	return (
		<LoadWrapper loading={loading}>
			<WorkbenchHeader
				halfHeight
				title={t('InApprovalTitle')}
				items={items?.length}
			/>
			<div className={classNames.container} data-is-scrollable>
				<List items={items} onRenderCell={onRenderCell} />
			</div>
		</LoadWrapper>
	);
};
