export const drawImageOnCanvas = (
	imgSrc: string,
	maxWidth: number,
	maxHeight: number,
	canvas: HTMLCanvasElement,
	onImageDrawn?: (img: HTMLImageElement) => void,
	cropOptions?: {
		sy: number;
		sHeight: number;
	},
	// eslint-disable-next-line max-params
) => {
	const ctx = canvas.getContext('2d');

	if (!ctx) {
		return;
	}

	const img = new Image();
	img.crossOrigin = 'anonymous';
	img.onload = () => {
		const {canvas} = ctx;

		const {width} = img;
		const height = cropOptions?.sHeight || img.height;

		const hRatio = Math.min(width, maxWidth) / img.width;
		const vRatio = Math.min(height, maxHeight) / height;
		const ratio = Math.min(hRatio, vRatio);

		const cWidth = width * ratio;
		const cHeight = height * ratio;

		canvas.width = cWidth;
		canvas.height = cHeight;

		const sy = cropOptions?.sy || 0;

		ctx.drawImage(img, 0, sy, width, height, 0, 0, cWidth, cHeight);

		onImageDrawn?.(img);
	};

	const cacheBustedUrl = `${imgSrc}?cache_bust=${Date.now()}`;
	img.src = cacheBustedUrl;
};

export const dataURLtoFile = async (dataURL: string, filename: string) => {
	const response = await fetch(dataURL);
	const blob = await response.blob();
	const file = new File([blob], filename, {type: blob.type});
	return file;
};
