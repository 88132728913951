import {addImages} from 'features/RegulatoryDocuments/hooks';
import {useUploadRegulatoryDocumentAssetMutation} from 'features/RegulatoryDocuments/hooks/useUploadDocumentAsset.generated';
import React from 'react';
import {useParams} from 'react-router-dom';
import {createEditor, Descendant, Editor, Transforms} from 'slate';
import {withHistory} from 'slate-history';
import {Slate, withReact} from 'slate-react';
import {
	BlobRef,
	ImportRegulatoryDocumentParagraphInput,
	RegulatoryDocumentParagraph,
	WorkflowStatus,
} from 'types';
import {v4 as uuidv4} from 'uuid';
import {EditorContent} from './EditorContent';
import {EditorCommands} from './EditorCommands';
import {EditorContextProvider} from './EditorContext';
import {DocumentEditorType} from './slate';

export type DocumentEditorProps = {
	paragraphs: Pick<
		RegulatoryDocumentParagraph,
		'elements' | 'enumeration' | 'page' | 'level' | 'id' | 'isFootnote'
	>[];
	workflowStatus: WorkflowStatus;
	onSave: (paragraphs: ImportRegulatoryDocumentParagraphInput[]) => void;
};

export const DocumentEditor: React.FC<DocumentEditorProps> = ({
	paragraphs,
	workflowStatus,
	onSave,
}) => {
	const {regulatoryDocumentId} = useParams();
	const [uploadAsset] = useUploadRegulatoryDocumentAssetMutation();
	const uploadImage = React.useCallback(
		async (file: File) => {
			if (!regulatoryDocumentId) {
				return;
			}

			const {data} = await uploadAsset({
				variables: {
					input: {
						assetId: file.name,
						file,
						regulatoryDocumentId,
					},
				},
			});

			const blobRef =
				data?.uploadRegulatoryDocumentAsset.regulatoryDocumentAsset ?? null;

			return blobRef;
		},
		[regulatoryDocumentId],
	);

	const editor = React.useMemo(
		() => withImagePaste(withHistory(withReact(createEditor())), uploadImage),
		[uploadImage],
	);
	const slateData: Descendant[] = paragraphs.map((p, i) => {
		const prevParagraph = paragraphs[i - 1];
		const isNewPage = prevParagraph && prevParagraph.page !== p.page;
		return {
			id: p.id,
			enumeration: p.enumeration,
			isFootnote: p.isFootnote,
			type: 'paragraph',
			level: p.level,
			page: p.page,
			isNewPage,
			children: (p.elements ?? []).map(elem => ({
				...elem,
				text: elem.text ?? '',
				bounds: elem.bounds ?? [],
				selected: false,
				_guid: uuidv4(),
			})),
		};
	});

	return (
		<EditorContextProvider mergedView={false}>
			<Slate editor={editor} value={slateData}>
				<EditorCommands onSave={onSave} workflowStatus={workflowStatus} />
				<EditorContent />
			</Slate>
		</EditorContextProvider>
	);
};

const withImagePaste = (
	editor: DocumentEditorType,
	uploadImage: (file: File) => Promise<BlobRef | undefined | null>,
) => {
	editor.insertData = data => {
		const plainText = data.getData('text/plain');
		const {files} = data;

		const path = editor.selection?.anchor.path.slice();

		if (files && files.length === 0) {
			Transforms.insertText(editor, plainText);
		}

		if (!path) {
			return;
		}

		const onFilesUploaded = (refs: (BlobRef | undefined | null)[]) => {
			const node = Editor.node(editor, path);
			const selectedItems = [node];
			addImages(
				editor,
				selectedItems,
				refs.filter(ref => Boolean(ref)) as BlobRef[],
			);
		};

		Promise.all(
			Array.from(files).map(file => {
				const [mime] = file.type.split('/');
				if (mime !== 'image') {
					return;
				}

				return uploadImage(file);
			}),
		).then(onFilesUploaded);
	};

	return editor;
};
