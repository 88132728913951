import React from 'react';
import {getListClassNames} from 'styles';
import {useTheme, Text, FontIcon, List, Stack} from '@fluentui/react';
import {NewsItemsHeader} from './Headers';

type NewsItemsListProps = {halfHeight?: boolean; title?: string};

export const NewsItemsList: React.FC<NewsItemsListProps> = ({
	halfHeight = false,
	title,
}) => {
	const theme = useTheme();
	const classNames = getListClassNames(theme, halfHeight);

	const items = ['Release Notes - Neue Funktionen verfügbar!'];

	const onRenderCell = (item: any): JSX.Element => {
		return (
			<div className={classNames.itemCell} data-is-focusable={true}>
				<div className={classNames.itemContent}>
					<Stack horizontal>
						<FontIcon
							iconName='FavoriteStarFill'
							className={classNames.iconStyles}
						/>
						<Text variant='large'>{item}</Text>
					</Stack>
				</div>
			</div>
		);
	};

	return (
		<div className={classNames.activityContainer}>
			<NewsItemsHeader halfHeight title={title} />
			<div className={classNames.container} data-is-scrollable>
				<List items={items} onRenderCell={onRenderCell} />
			</div>
		</div>
	);
};
