import {IColumn} from '@fluentui/react';
import {EntityList} from 'components';
import {useEntityContext} from 'components/EntityPage/EntityContext';
import {LoadWrapper} from 'components/LoadWrapper';
import {useSelection} from 'hooks';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Convolute} from 'types';
import {useGetConvolutesQuery} from '../hooks/convolutes.generated';
import {ConvolutesTooltipTranslationProvider} from 'features/localizedTooltips';

export const ConvoluteList: React.FC = () => {
	const {t} = useTranslation('features/adminsection', {
		keyPrefix: 'AdminDataProtectionPage',
	});

	const {setSelectedItems} = useEntityContext<Convolute>();

	const [selection] = useSelection<Convolute>({
		onSelectionChanged(selectedItems) {
			setSelectedItems?.(selectedItems);
		},
	});

	const {data, loading} = useGetConvolutesQuery();

	const convolutes = React.useMemo(() => data?.convolutes || [], [data]);

	const columns: IColumn[] = React.useMemo(
		() => [
			{
				key: 'name',
				name: t('ConvoluteName'),
				fieldName: 'name',
				minWidth: 100,
				maxWidth: 200,
			},
			{
				key: 'gdprRule',
				name: t('GdprRuleName'),
				fieldName: 'gdprRule',
				minWidth: 100,
				maxWidth: 200,
				onRender: (item: Convolute) => item.gdprRule?.name,
			},
		],
		[],
	);

	return (
		<LoadWrapper loading={loading}>
			<ConvolutesTooltipTranslationProvider>
				<EntityList
					items={convolutes}
					columns={columns}
					selection={selection}
				/>
			</ConvolutesTooltipTranslationProvider>
		</LoadWrapper>
	);
};
