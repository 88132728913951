import React, {useCallback, useMemo, useState} from 'react';
import {BaselineDetailsPageParams} from '../BaselineDetailsPage.types';
import {BooleanState} from 'types';
import {
	Dialog,
	DialogFooter,
	DialogType,
	IDialogContentProps,
} from '@fluentui/react';
import {useTranslationsOfBaselinesSelectionDialog} from './BaselinesSelectionDialog.utils';
import {BtnToCompareBaselines} from './BtnToCompareBaselines';

import {BaselinesPicker} from './BaselinesPicker/BaselinesPicker';
import {PossibleSelectedBaseline} from './BaselinesSelectionDialog.types';

interface Props {
	infoForIsOpen: BooleanState;
	params: BaselineDetailsPageParams;
}

export function BaselinesSelectionDialog({
	infoForIsOpen: [isOpen, {setFalse}],
	params,
}: Props): JSX.Element {
	const {t} = useTranslationsOfBaselinesSelectionDialog();

	const [baseline, setBaseline] = useState<PossibleSelectedBaseline | null>(
		null,
	);

	const closeDialog = useCallback((): void => {
		setFalse();
		setBaseline(null);
	}, [setFalse]);

	const dialogContentProps = useMemo((): IDialogContentProps => {
		return {
			type: DialogType.close,
			title: t('Title'),
			closeButtonAriaLabel: t('LabelOfCloseBtn'),
		};
	}, [t]);

	return (
		<Dialog
			hidden={!isOpen}
			onDismiss={closeDialog}
			dialogContentProps={dialogContentProps}
			minWidth={700}
		>
			<BaselinesPicker
				setBaseline={setBaseline}
				possibleSelectedBaseline={baseline}
				params={params}
			/>
			<DialogFooter>
				<BtnToCompareBaselines
					params={params}
					possibleSelectedBaseline={baseline}
				/>
			</DialogFooter>
		</Dialog>
	);
}
