import {createTooltipTranslationProviderFromNamespace} from 'features/localizedTooltips';
import {POGIS_DOCUMENT_TRANSLATION_NAMESPACE} from './pogisDocumentConstants';
import {useTooltipTranslation} from 'features/localizedTooltips/translationHooks';

export const PogisDocumentsTooltipTranslationProvider =
	createTooltipTranslationProviderFromNamespace(
		POGIS_DOCUMENT_TRANSLATION_NAMESPACE,
	);

export const usePogisDocumentsTooltipText = () => {
	return useTooltipTranslation(POGIS_DOCUMENT_TRANSLATION_NAMESPACE);
};
