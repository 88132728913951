import {IColumn, IDetailsColumnRenderTooltipProps} from '@fluentui/react';
import {
	LocalizedTooltipHostProps,
	TooltipOptionsForComponentOrColumn,
} from 'features/localizedTooltips/LocalizedTooltipHost';
import React from 'react';
import {
	DetailsListHeadingTooltipChildrenProps,
	DetailsListHeadingTooltipChildren,
} from './DetailsListHeadingTooltipChildren';
import {TooltipWithComponentWithAriaDescribedBy as HostWithoutType} from '../../../TooltipWithComponentWithAriaDescribedBy';
import {
	TooltipStatusContextValue,
	useTooltipStatusContext,
} from 'features/localizedTooltips/contexts';
import {BasePropsOfComponentForRenderer} from 'features/localizedTooltips/TooltipRendererCreator.service';

export type ColumnWithTooltipOptions = IColumn &
	TooltipOptionsForComponentOrColumn;

export interface PropsOfHeaderTooltipRenderer
	extends IDetailsColumnRenderTooltipProps {
	column?: ColumnWithTooltipOptions;
}

type Props =
	BasePropsOfComponentForRenderer<PropsOfHeaderTooltipRenderer>['originalComponentProps'];

export function DetailsListHeaderTooltip(props: Props) {
	const statusContext: TooltipStatusContextValue = useTooltipStatusContext();

	const Host = HostWithoutType<DetailsListHeadingTooltipChildrenProps>;

	/**
	 * We are overwriting shouldGetTextFromContext because the column can
	 * sometimes be initially undefined, which means the field name would be
	 * missing. If this option were enabled when that happened, it would cause
	 * the component to throw an error. So, we only enable this once the
	 * column is present.
	 */
	const getIfShouldGetTextFromContext = (
		column: IColumn | undefined,
	): boolean => {
		return Boolean(statusContext?.shouldGetTooltipTextFromContext && column);
	};

	const getTooltipHostProps = (): LocalizedTooltipHostProps => {
		return {
			/**
			 * We included this because the props can have content when
			 * ariaLabelForSelectAllCheckbox is set on the details list.
			 */
			...props,
			translationKey: props?.column?.fieldName,
			shouldGetTextFromContext: getIfShouldGetTextFromContext(props?.column),
			/**
			 * The tooltipHostProps must be last in case we want to overwrite anything.
			 */
			...props?.column?.tooltipHostProps,
		};
	};

	return (
		<Host
			Component={DetailsListHeadingTooltipChildren}
			tooltipHostProps={getTooltipHostProps()}
			{...props}
		/>
	);
}
