import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetNamedEntityValuesQueryVariables = Types.Exact<{
  ids: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;


export type GetNamedEntityValuesQuery = { __typename?: 'Query', nodes: Array<{ __typename?: 'ActiveTypeMarket', id: string, name: string } | { __typename?: 'AttachmentCategory' } | { __typename?: 'AuditLog' } | { __typename?: 'BaselineRequirement' } | { __typename?: 'BodyworkVariant', id: string, name: string } | { __typename?: 'Category', id: string, name: string } | { __typename?: 'Convolute' } | { __typename?: 'Country' } | { __typename?: 'DocumentSource' } | { __typename?: 'DriveVariant', id: string, name: string } | { __typename?: 'EngineVariant', id: string, name: string } | { __typename?: 'GdprRule' } | { __typename?: 'GearboxVariant', id: string, name: string } | { __typename?: 'Keyword', id: string, name: string } | { __typename?: 'Market', id: string, name: string } | { __typename?: 'PogisDocument' } | { __typename?: 'Reference' } | { __typename?: 'Regulation' } | { __typename?: 'RegulationCluster', id: string, name: string } | { __typename?: 'RegulatoryDocument' } | { __typename?: 'Requirement', id: string, name: string } | { __typename?: 'Simulation' } | { __typename?: 'SimulationVehicleProject' } | { __typename?: 'StandardPlusPcmsCluster', id: string, name: string } | { __typename?: 'Subscription' } | { __typename?: 'SystemLevel', id: string, name: string } | { __typename?: 'Tag', id: string, name: string } | { __typename?: 'User' } | { __typename?: 'VehicleCategory', id: string, name: string } | { __typename?: 'VehicleProject', id: string, name: string } | { __typename?: 'VexCluster', id: string, name: string } | null> };


export const GetNamedEntityValuesDocument = gql`
    query GetNamedEntityValues($ids: [ID!]!) {
  nodes(ids: $ids) {
    ... on Market {
      id
      name
    }
    ... on BodyworkVariant {
      id
      name
    }
    ... on ActiveTypeMarket {
      id
      name
    }
    ... on BodyworkVariant {
      id
      name
    }
    ... on Category {
      id
      name
    }
    ... on DriveVariant {
      id
      name
    }
    ... on EngineVariant {
      id
      name
    }
    ... on GearboxVariant {
      id
      name
    }
    ... on Keyword {
      id
      name
    }
    ... on RegulationCluster {
      id
      name
    }
    ... on StandardPlusPcmsCluster {
      id
      name
    }
    ... on SystemLevel {
      id
      name
    }
    ... on VehicleCategory {
      id
      name
    }
    ... on VexCluster {
      id
      name
    }
    ... on Tag {
      id
      name
    }
    ... on Requirement {
      id
      name
    }
    ... on VehicleProject {
      id
      name
    }
  }
}
    `;

/**
 * __useGetNamedEntityValuesQuery__
 *
 * To run a query within a React component, call `useGetNamedEntityValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNamedEntityValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNamedEntityValuesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetNamedEntityValuesQuery(baseOptions: Apollo.QueryHookOptions<GetNamedEntityValuesQuery, GetNamedEntityValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNamedEntityValuesQuery, GetNamedEntityValuesQueryVariables>(GetNamedEntityValuesDocument, options);
      }
export function useGetNamedEntityValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNamedEntityValuesQuery, GetNamedEntityValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNamedEntityValuesQuery, GetNamedEntityValuesQueryVariables>(GetNamedEntityValuesDocument, options);
        }
export type GetNamedEntityValuesQueryHookResult = ReturnType<typeof useGetNamedEntityValuesQuery>;
export type GetNamedEntityValuesLazyQueryHookResult = ReturnType<typeof useGetNamedEntityValuesLazyQuery>;
export type GetNamedEntityValuesQueryResult = Apollo.QueryResult<GetNamedEntityValuesQuery, GetNamedEntityValuesQueryVariables>;