import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddRegulatoryDocumentFaqMutationVariables = Types.Exact<{
  input: Types.AddFaqInput;
}>;


export type AddRegulatoryDocumentFaqMutation = { __typename?: 'Mutation', addRegulatoryDocumentFaq: { __typename?: 'RegulatoryDocument', id: string } };

export type AddRegulatoryDocumentParagraphFaqMutationVariables = Types.Exact<{
  input: Types.AddRegulatoryDocumentParagraphFaqInput;
}>;


export type AddRegulatoryDocumentParagraphFaqMutation = { __typename?: 'Mutation', addRegulatoryDocumentParagraphFaq: { __typename?: 'AddRegulatoryDocumentParagraphFaqPayload', regulatoryDocument?: { __typename?: 'RegulatoryDocument', id: string } | null } };

export type AddRegulationFaqMutationVariables = Types.Exact<{
  input: Types.AddFaqInput;
}>;


export type AddRegulationFaqMutation = { __typename?: 'Mutation', addRegulationFaq: { __typename?: 'Regulation', id: string } };


export const AddRegulatoryDocumentFaqDocument = gql`
    mutation AddRegulatoryDocumentFaq($input: AddFaqInput!) {
  addRegulatoryDocumentFaq(input: $input) {
    id
  }
}
    `;
export type AddRegulatoryDocumentFaqMutationFn = Apollo.MutationFunction<AddRegulatoryDocumentFaqMutation, AddRegulatoryDocumentFaqMutationVariables>;

/**
 * __useAddRegulatoryDocumentFaqMutation__
 *
 * To run a mutation, you first call `useAddRegulatoryDocumentFaqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRegulatoryDocumentFaqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRegulatoryDocumentFaqMutation, { data, loading, error }] = useAddRegulatoryDocumentFaqMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRegulatoryDocumentFaqMutation(baseOptions?: Apollo.MutationHookOptions<AddRegulatoryDocumentFaqMutation, AddRegulatoryDocumentFaqMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddRegulatoryDocumentFaqMutation, AddRegulatoryDocumentFaqMutationVariables>(AddRegulatoryDocumentFaqDocument, options);
      }
export type AddRegulatoryDocumentFaqMutationHookResult = ReturnType<typeof useAddRegulatoryDocumentFaqMutation>;
export type AddRegulatoryDocumentFaqMutationResult = Apollo.MutationResult<AddRegulatoryDocumentFaqMutation>;
export type AddRegulatoryDocumentFaqMutationOptions = Apollo.BaseMutationOptions<AddRegulatoryDocumentFaqMutation, AddRegulatoryDocumentFaqMutationVariables>;
export const AddRegulatoryDocumentParagraphFaqDocument = gql`
    mutation AddRegulatoryDocumentParagraphFaq($input: AddRegulatoryDocumentParagraphFaqInput!) {
  addRegulatoryDocumentParagraphFaq(input: $input) {
    regulatoryDocument {
      id
    }
  }
}
    `;
export type AddRegulatoryDocumentParagraphFaqMutationFn = Apollo.MutationFunction<AddRegulatoryDocumentParagraphFaqMutation, AddRegulatoryDocumentParagraphFaqMutationVariables>;

/**
 * __useAddRegulatoryDocumentParagraphFaqMutation__
 *
 * To run a mutation, you first call `useAddRegulatoryDocumentParagraphFaqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRegulatoryDocumentParagraphFaqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRegulatoryDocumentParagraphFaqMutation, { data, loading, error }] = useAddRegulatoryDocumentParagraphFaqMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRegulatoryDocumentParagraphFaqMutation(baseOptions?: Apollo.MutationHookOptions<AddRegulatoryDocumentParagraphFaqMutation, AddRegulatoryDocumentParagraphFaqMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddRegulatoryDocumentParagraphFaqMutation, AddRegulatoryDocumentParagraphFaqMutationVariables>(AddRegulatoryDocumentParagraphFaqDocument, options);
      }
export type AddRegulatoryDocumentParagraphFaqMutationHookResult = ReturnType<typeof useAddRegulatoryDocumentParagraphFaqMutation>;
export type AddRegulatoryDocumentParagraphFaqMutationResult = Apollo.MutationResult<AddRegulatoryDocumentParagraphFaqMutation>;
export type AddRegulatoryDocumentParagraphFaqMutationOptions = Apollo.BaseMutationOptions<AddRegulatoryDocumentParagraphFaqMutation, AddRegulatoryDocumentParagraphFaqMutationVariables>;
export const AddRegulationFaqDocument = gql`
    mutation AddRegulationFaq($input: AddFaqInput!) {
  addRegulationFaq(input: $input) {
    id
  }
}
    `;
export type AddRegulationFaqMutationFn = Apollo.MutationFunction<AddRegulationFaqMutation, AddRegulationFaqMutationVariables>;

/**
 * __useAddRegulationFaqMutation__
 *
 * To run a mutation, you first call `useAddRegulationFaqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRegulationFaqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRegulationFaqMutation, { data, loading, error }] = useAddRegulationFaqMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRegulationFaqMutation(baseOptions?: Apollo.MutationHookOptions<AddRegulationFaqMutation, AddRegulationFaqMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddRegulationFaqMutation, AddRegulationFaqMutationVariables>(AddRegulationFaqDocument, options);
      }
export type AddRegulationFaqMutationHookResult = ReturnType<typeof useAddRegulationFaqMutation>;
export type AddRegulationFaqMutationResult = Apollo.MutationResult<AddRegulationFaqMutation>;
export type AddRegulationFaqMutationOptions = Apollo.BaseMutationOptions<AddRegulationFaqMutation, AddRegulationFaqMutationVariables>;