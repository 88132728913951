import React, {useContext} from 'react';
import {LoadWrapper} from 'components/LoadWrapper';
import {
	RefetchLoadWrapper,
	RefetchLoadWrapperProps,
} from './RefetchLoadWrapper';
import {
	RegDocDetailsPageInfo,
	RegDocDetailsPageInfoContext,
} from '../RegDocDetailsPage.context';

type Props = Pick<RefetchLoadWrapperProps, 'children'>;

export function RegDocDetailsPageLoadWrapper(props: Props): JSX.Element {
	const {isLoadingFor1stTime} = useContext(
		RegDocDetailsPageInfoContext,
	) as RegDocDetailsPageInfo;

	/**
	 * Implementation notes:
	 *
	 * We use two load wrappers because while some components likely cannot be
	 * rendered unless the queries' data have loaded, we must still preserve the
	 * user's scroll position. The first load wrapper prevents us from rendering the
	 * components while data is unavailable, while the Refetch Load Wrapper
	 * preserves the scroll position.
	 */
	return (
		<LoadWrapper loading={isLoadingFor1stTime}>
			<RefetchLoadWrapper {...props} />
		</LoadWrapper>
	);
}
