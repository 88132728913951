import React, {useMemo, useState} from 'react';
import {BaselineCmdsInfo} from '../baselines/baselineCmds';
import {
	Dialog,
	DialogFooter,
	DialogType,
	IDialogContentProps,
	PrimaryButton,
	TextField,
} from '@fluentui/react';
import {useVehicleProjectsTranslation} from 'features/VehicleProjects/hooks/vehicleProjectsTranslationHooks';
import {useRenameBaselineMutation} from '../hooks/baseline.generated';
import {Baseline} from '../BaselineDetailsPage/BaselineDetailsPage.types';

interface Props {
	baselines: Baseline[];
	renameDialogOpen: boolean;
	setRenameDialogOpen: (isOpen: boolean) => void;
}

export function BaselineRenameDialog({
	baselines,
	renameDialogOpen,
	setRenameDialogOpen,
}: Props): JSX.Element {
	const {t} = useVehicleProjectsTranslation({
		keyPrefix: 'BaselineRenameDialog',
	});

	const [renameBaselineMutation] = useRenameBaselineMutation();

	const [name, setName] = useState('');

	const onTitleChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string,
	) => {
		setName(newValue ?? '');
	};

	const handleClose = async () => {
		try {
			for (let i = 0; i < baselines.length; i++) {
				// eslint-disable-next-line no-await-in-loop
				await renameBaselineMutation({
					variables: {
						id: baselines[i].id,
						name,
					},
					refetchQueries: [],
				});
			}
			window.location.reload();
		} catch (error) {
			console.error('Failed to rename baseline:', error);
		}

		setRenameDialogOpen(false);
	};

	const handleCancel = async () => {
		setRenameDialogOpen(false);
	};

	const handleOpen = async () => {
		setName(baselines.length === 1 ? baselines[0].name : '');
	};

	const dialogContentProps: IDialogContentProps = useMemo(() => {
		return {
			type: DialogType.normal,
			title: t('Title'),
			styles: {title: {wordBreak: 'break-word'}},
		};
	}, [t]);

	const isNameEmpty = !name;

	return (
		<Dialog
			hidden={!renameDialogOpen}
			onDismiss={handleCancel}
			dialogContentProps={dialogContentProps}
			onLayerDidMount={handleOpen}
		>
			<TextField
				value={name}
				onChange={onTitleChange}
				multiline
				required
				styles={{fieldGroup: {minHeight: '200px'}}}
				errorMessage={isNameEmpty ? t('EmptyNameError') : undefined}
			/>
			<DialogFooter>
				<PrimaryButton onClick={handleCancel} disabled={false}>
					{t('CancelBtn')}
				</PrimaryButton>

				<PrimaryButton onClick={handleClose} disabled={isNameEmpty}>
					{t('CloseBtn')}
				</PrimaryButton>
			</DialogFooter>
		</Dialog>
	);
}
