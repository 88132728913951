import * as Types from '../../../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateInterimRequirementStatusMutationVariables = Types.Exact<{
  regulatoryDocumentId: Types.Scalars['ID'];
}>;


export type UpdateInterimRequirementStatusMutation = { __typename?: 'Mutation', updateInterimRequirementStatus: { __typename?: 'RequirementPayload', requirement: { __typename?: 'Requirement', id: string, status: Types.RequirementStatus } } };


export const UpdateInterimRequirementStatusDocument = gql`
    mutation UpdateInterimRequirementStatus($regulatoryDocumentId: ID!) {
  updateInterimRequirementStatus(regulatoryDocumentId: $regulatoryDocumentId) {
    requirement {
      id
      status
    }
  }
}
    `;
export type UpdateInterimRequirementStatusMutationFn = Apollo.MutationFunction<UpdateInterimRequirementStatusMutation, UpdateInterimRequirementStatusMutationVariables>;

/**
 * __useUpdateInterimRequirementStatusMutation__
 *
 * To run a mutation, you first call `useUpdateInterimRequirementStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInterimRequirementStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInterimRequirementStatusMutation, { data, loading, error }] = useUpdateInterimRequirementStatusMutation({
 *   variables: {
 *      regulatoryDocumentId: // value for 'regulatoryDocumentId'
 *   },
 * });
 */
export function useUpdateInterimRequirementStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInterimRequirementStatusMutation, UpdateInterimRequirementStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInterimRequirementStatusMutation, UpdateInterimRequirementStatusMutationVariables>(UpdateInterimRequirementStatusDocument, options);
      }
export type UpdateInterimRequirementStatusMutationHookResult = ReturnType<typeof useUpdateInterimRequirementStatusMutation>;
export type UpdateInterimRequirementStatusMutationResult = Apollo.MutationResult<UpdateInterimRequirementStatusMutation>;
export type UpdateInterimRequirementStatusMutationOptions = Apollo.BaseMutationOptions<UpdateInterimRequirementStatusMutation, UpdateInterimRequirementStatusMutationVariables>;