import {UseTranslationOptions, useTranslation} from 'react-i18next';
import type pageTranslations from 'locales/features/Simulations/simulationsPage.de.json';
import {createNamespaceForSimulationsFeature} from '../simulations.utils';

export const namespaceOfSimulationsPage: string =
	createNamespaceForSimulationsFeature('simulationsPage');

type PageTranslations = typeof pageTranslations;

type PageTranslationKey = keyof PageTranslations;

export const useTranslationForSimulationsPageWithIncorrectKeyPrefixType = (
	options?: UseTranslationOptions<string>,
) => {
	return useTranslation(namespaceOfSimulationsPage, options);
};

export const useTranslationForSimulationsPage = (
	options?: UseTranslationOptions<PageTranslationKey>,
) => {
	return useTranslationForSimulationsPageWithIncorrectKeyPrefixType(options);
};

export const FORM_KEY_PREFIX: PageTranslationKey = 'Form';

export const useTranslationForSimulationsForm = () => {
	return useTranslationForSimulationsPage({keyPrefix: FORM_KEY_PREFIX});
};

export const NAMESPACE_FOR_SIMULATIONS: string =
	createNamespaceForSimulationsFeature('simulations');
