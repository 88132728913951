import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSubscriptionFormDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSubscriptionFormDataQuery = { __typename?: 'Query', markets?: Array<{ __typename?: 'Market', id: string, name: string }> | null, keywords?: Array<{ __typename?: 'Keyword', id: string, name: string }> | null, standardPlusPcmsClusters?: Array<{ __typename?: 'StandardPlusPcmsCluster', id: string, name: string }> | null };


export const GetSubscriptionFormDataDocument = gql`
    query GetSubscriptionFormData {
  markets {
    id
    name
  }
  keywords {
    id
    name
  }
  standardPlusPcmsClusters {
    id
    name
  }
}
    `;

/**
 * __useGetSubscriptionFormDataQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionFormDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionFormDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubscriptionFormDataQuery(baseOptions?: Apollo.QueryHookOptions<GetSubscriptionFormDataQuery, GetSubscriptionFormDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubscriptionFormDataQuery, GetSubscriptionFormDataQueryVariables>(GetSubscriptionFormDataDocument, options);
      }
export function useGetSubscriptionFormDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubscriptionFormDataQuery, GetSubscriptionFormDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubscriptionFormDataQuery, GetSubscriptionFormDataQueryVariables>(GetSubscriptionFormDataDocument, options);
        }
export type GetSubscriptionFormDataQueryHookResult = ReturnType<typeof useGetSubscriptionFormDataQuery>;
export type GetSubscriptionFormDataLazyQueryHookResult = ReturnType<typeof useGetSubscriptionFormDataLazyQuery>;
export type GetSubscriptionFormDataQueryResult = Apollo.QueryResult<GetSubscriptionFormDataQuery, GetSubscriptionFormDataQueryVariables>;