import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateRegulationMutationVariables = Types.Exact<{
  input: Types.UpdateRegulationInput;
}>;


export type UpdateRegulationMutation = { __typename?: 'Mutation', updateRegulation: { __typename?: 'RegulationPayload', regulation: { __typename?: 'Regulation', id: string } } };


export const UpdateRegulationDocument = gql`
    mutation UpdateRegulation($input: UpdateRegulationInput!) {
  updateRegulation(input: $input) {
    regulation {
      id
    }
  }
}
    `;
export type UpdateRegulationMutationFn = Apollo.MutationFunction<UpdateRegulationMutation, UpdateRegulationMutationVariables>;

/**
 * __useUpdateRegulationMutation__
 *
 * To run a mutation, you first call `useUpdateRegulationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRegulationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRegulationMutation, { data, loading, error }] = useUpdateRegulationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRegulationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRegulationMutation, UpdateRegulationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRegulationMutation, UpdateRegulationMutationVariables>(UpdateRegulationDocument, options);
      }
export type UpdateRegulationMutationHookResult = ReturnType<typeof useUpdateRegulationMutation>;
export type UpdateRegulationMutationResult = Apollo.MutationResult<UpdateRegulationMutation>;
export type UpdateRegulationMutationOptions = Apollo.BaseMutationOptions<UpdateRegulationMutation, UpdateRegulationMutationVariables>;