import {useCommand, useFavoriteCommand} from 'hooks';
import {Dispatch, SetStateAction} from 'react';
import {Requirement} from 'types';

interface FieldsOfToggleAuditCmd {
	setShowAudit: Dispatch<SetStateAction<boolean>>;
	showAudit: boolean;
}

const useToggleAuditCmd = ({
	setShowAudit,
	showAudit,
}: FieldsOfToggleAuditCmd): void => {
	useCommand(
		{
			key: 'toggleAudit',
			iconProps: {
				iconName: 'History',
			},
			farCommand: true,
			priority: 3,
			onClick() {
				setShowAudit(!showAudit);
			},
		},
		[showAudit],
	);
};

interface Fields {
	requirementId: Requirement['id'];
	toggleAuditCmdFields: FieldsOfToggleAuditCmd;
}

export const useRequirementDetailsPageNonEditCmds = ({
	requirementId,
	toggleAuditCmdFields,
}: Fields): void => {
	useToggleAuditCmd(toggleAuditCmdFields);
	useFavoriteCommand(requirementId, []);
};
