import {ISpinnerStyles, Spinner, SpinnerSize} from '@fluentui/react';
import _ from 'lodash';
import * as React from 'react';
import {useTranslation} from 'react-i18next';

export interface LoadSpinnerProps {
	text?: string;
	size?: SpinnerSize;
	extraStyles?: ISpinnerStyles;
	/**
	 * Controls whether we apply the vertical transformation to vertically center
	 * the loading spinner. If there is not enough space, this could cause the
	 * spinner to shift under another element. So, set this to false if you do not
	 * need to center the spinner vertically. Note that even if this is disabled,
	 * the element will still use relative positioning, so it does not prevent the
	 * spinner from being centered vertically.
	 */
	applyVerticalTransformation?: boolean;
}

export const LoadSpinner: React.FC<LoadSpinnerProps> = ({
	text,
	size,
	extraStyles,
	applyVerticalTransformation = true,
}) => {
	const {t} = useTranslation('components/loadwrapper', {
		keyPrefix: 'LoadWrapper',
	});

	const getVerticalTransformation = (): string => {
		return applyVerticalTransformation ? '-50%' : '0';
	};

	const getSpinnerTransformation = (): string => {
		const verticalTransformation: string = getVerticalTransformation();
		return `translate(-50%, ${verticalTransformation})`;
	};

	const getBaseSpinnerStyles = (): ISpinnerStyles => {
		return {
			root: {
				position: 'relative',
				left: '50%',
				top: '50%',
				transform: getSpinnerTransformation(),
			},
		};
	};

	const getSpinnerStyles = (): ISpinnerStyles => {
		const baseStyles: ISpinnerStyles = getBaseSpinnerStyles();
		return _.merge(baseStyles, extraStyles ?? {});
	};

	const spinnerStyles: ISpinnerStyles = React.useMemo(getSpinnerStyles, [
		extraStyles,
		applyVerticalTransformation,
	]);

	return (
		<Spinner
			styles={spinnerStyles}
			size={size ?? SpinnerSize.large}
			label={text ?? t('LoadingSpinner')}
		/>
	);
};
