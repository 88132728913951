import React from 'react';
import {KeywordFields} from 'components/Keywords/keywordItem.types';
import {Keywords} from 'components/Keywords/Keywords';
import {ArrayFieldWrapper, PropsOfArrayFieldWrapper} from './ArrayFieldWrapper';

interface Props extends Omit<PropsOfArrayFieldWrapper, 'children'> {
	data: KeywordFields[];
}

export function KeywordsField({data, ...other}: Props): JSX.Element {
	return (
		<ArrayFieldWrapper {...other}>
			<Keywords keywords={data} />
		</ArrayFieldWrapper>
	);
}
