import React from 'react';
import {
	PropsWithAriaDescribedBy,
	TooltipWithComponentWithAriaDescribedBy as TooltipWithComponentWithAriaDescribedByWithoutType,
} from './TooltipWithComponentWithAriaDescribedBy';
import {throwErrorInDevAndLogInProd} from './tooltipErrorUtils';
import {
	LocalizedTooltipHostProps,
	TooltipOptionsForComponentOrColumn,
} from './LocalizedTooltipHost';
import {DirectionalHint} from '@fluentui/react';

type HostProps = Omit<LocalizedTooltipHostProps, 'children'>;

export interface DefaultTooltipHostWithComponentProps<
	ComponentProps extends PropsWithAriaDescribedBy,
> extends TooltipOptionsForComponentOrColumn<HostProps> {
	keyWithFieldName: keyof ComponentProps;
	Component: React.FC<ComponentProps>;
	/**
	 * We try to extract the field's name from these props.
	 */
	componentProps: ComponentProps;
}

/**
 * This component is a tooltip host that handles automatically extracting the
 * translation key from the component's props, which allows us to automatically
 * get the translation from the translation context. It also adds
 * aria-describedby to the component.
 *
 * Note that this component will only throw certain errors in development. It
 * will still log all errors to the console, though.
 */
export default function DefaultTooltipHostWithComponent<
	ComponentProps extends PropsWithAriaDescribedBy,
>({
	componentProps,
	tooltipHostProps,
	keyWithFieldName,
	Component,
}: DefaultTooltipHostWithComponentProps<ComponentProps>) {
	const TooltipWithComponentWithAriaDescribedBy =
		TooltipWithComponentWithAriaDescribedByWithoutType<ComponentProps>;

	const handleInvalidKey = (): void => {
		const error = new Error(
			`The key that has the field's name is not a string.`,
		);
		throwErrorInDevAndLogInProd(error);
	};

	const validateFieldNameIsString = (): void => {
		if (typeof keyWithFieldName !== 'string') return handleInvalidKey();
	};

	const getTranslationKey = (): string => {
		validateFieldNameIsString();
		return componentProps[keyWithFieldName] as string;
	};

	const defaultTooltipPosition = DirectionalHint.leftCenter;

	const getTooltipHostProps = (): HostProps => {
		return {
			translationKey: getTranslationKey(),
			directionalHint: defaultTooltipPosition,
			...tooltipHostProps,
		};
	};

	return (
		<TooltipWithComponentWithAriaDescribedBy
			Component={Component}
			tooltipHostProps={getTooltipHostProps()}
			{...componentProps}
		/>
	);
}
