import React from 'react';
import {KeywordFields} from '../keywordItem.types';
import {PersonaSize} from '@fluentui/react';
import {Personas} from 'components/Personas';
import {KeywordTooltipHeading} from './KeywordTooltipHeading';
import {useKeywordsTranslations} from '../keywords.commonHooks';
import {FieldWrapper} from './FieldWrapper';

interface Props {
	vkos: KeywordFields['vkos'];
}

export function Vkos({vkos}: Props): JSX.Element {
	const {t} = useKeywordsTranslations();

	return (
		<div>
			<KeywordTooltipHeading>{t('VKOs')}</KeywordTooltipHeading>
			<FieldWrapper list={vkos}>
				<Personas persons={vkos} size={PersonaSize.size8} />
			</FieldWrapper>
		</div>
	);
}
