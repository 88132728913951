import React from 'react';
import {BaseEditor, Editor, Transforms} from 'slate';
import {HistoryEditor} from 'slate-history';
import {ReactEditor} from 'slate-react';

export type EditorContextProps = {
	isMergedView: boolean;
	setIsMergedView: (val: boolean) => void;
	deleteParagraphElement: (
		editor: BaseEditor & ReactEditor & HistoryEditor,
		guild: string | number[] | undefined,
	) => void;
};

export const EditorContext = React.createContext<EditorContextProps>({
	isMergedView: false,
} as EditorContextProps);

const deleteParagraphElement = (
	editor: BaseEditor & ReactEditor & HistoryEditor,
	guidOrAt: string | number[] | undefined,
): void => {
	if (!guidOrAt) return;

	const [node] =
		typeof guidOrAt === 'string'
			? Editor.nodes(editor, {
					at: [],
					match: n => (n as any)._guid === guidOrAt,
					mode: 'lowest',
			  })
			: [Editor.node(editor, guidOrAt as number[])];

	const parent = Editor.parent(editor, node[1]);

	const siblingCount = parent[0].children.length;

	// The node has no sibling, we need to remove the parent, otherwise error will be thrown for the parent has no children with keys
	if (siblingCount === 1) {
		Transforms.removeNodes(editor, {
			at: parent[1],
		});
		return;
	}

	// The node has sibling, we ned the parent
	if (typeof guidOrAt === 'string') {
		Transforms.removeNodes(editor, {
			at: [],
			match: node => (node as any)._guid === guidOrAt,
			mode: 'lowest',
		});
	} else {
		Transforms.removeNodes(editor, {
			at: guidOrAt,
		});
	}
};

export const EditorContextProvider: React.FC<{
	mergedView: boolean;
	children: React.ReactNode;
}> = ({mergedView, children}) => {
	const [isMergedView, setIsMergedView] = React.useState<boolean>(mergedView);

	return (
		<EditorContext.Provider
			value={{
				isMergedView,
				setIsMergedView,
				deleteParagraphElement,
			}}
		>
			{children}
		</EditorContext.Provider>
	);
};

export const useEditorContext = () => React.useContext(EditorContext);
