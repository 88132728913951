import {IBreadcrumbItem} from '@fluentui/react';
import _ from 'lodash';
import React, {useMemo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {
	GetVehicleProjectDetailsPageDataQuery as Query,
	useGetVehicleProjectDetailsPageDataQuery,
} from './hooks';
import {
	ProjectDetailsPageParams,
	createGenPagePath,
	encodeGenAndModelSeries,
} from './vehicleProjectPagePaths';
import {
	DetailsPageForEntityLikeAProject,
	PropsForDetailsPageForEntityLikeAProject,
	RenderRequirementsList,
} from './components/DetailsPageForEntityLikeAProject/DetailsPageForEntityLikeAProject';
import {useCommand} from 'hooks';
import {VehicleProject} from 'types';
import {AssignedRequirementsList} from './components/DetailsPageForEntityLikeAProject/AssignedRequirementsList';
import {EntityListContextProvider} from '../../components/EntityList/EntityListContext';

const defaultArray: never[] = [];

const VehicleProjectDetailsPage: React.FC = () => {
	const {t} = useTranslation('features/vehicleprojects');

	const navigate = useNavigate();
	const {modelSeries, generation} = useParams() as ProjectDetailsPageParams;

	const generationOrDefaultValue: VehicleProject['generation'] =
		generation ?? '';

	const {loading, data, refetch} = useGetVehicleProjectDetailsPageDataQuery({
		variables: {
			generation: generationOrDefaultValue,
			modelSeries: modelSeries ?? '',
		},
	});

	const createPathOfActiveTypeMarketsPage = useCallback((): string => {
		const encodeGenAndModelSeriesOrDefaults = (): ProjectDetailsPageParams => {
			return encodeGenAndModelSeries({
				generation: generationOrDefaultValue,
				modelSeries: modelSeries ?? '',
			});
		};

		const createGenPathWithEncodedParams = (): string => {
			const encodedFields: ProjectDetailsPageParams =
				encodeGenAndModelSeriesOrDefaults();
			return createGenPagePath(encodedFields);
		};

		const genPath: string = createGenPathWithEncodedParams();
		return `${genPath}/projects`;
	}, [generationOrDefaultValue, modelSeries]);

	useCommand(
		{
			key: 'regions',
			text: t('ActiveTypeMarketsButton'),
			iconProps: {iconName: 'Market'},
			priority: 3,
			onClick() {
				const path: string = createPathOfActiveTypeMarketsPage();
				navigate(path);
			},
		},
		[navigate, t, createPathOfActiveTypeMarketsPage],
	);

	const itemsWithHref: IBreadcrumbItem[] = [
		{
			text: t('VehicleProjects'),
			key: 'vehicleProjects',
			onClick: () => navigate('/vehicleProjects'),
		},
		{
			text: `${modelSeries}`,
			key: 'modelSeries',
		},
		{
			text: `${generation}`,
			key: 'generation',
		},
	];

	const pageData =
		useMemo((): PropsForDetailsPageForEntityLikeAProject['pageData'] => {
			type PossibleQueryData = Query['vehicleProjectDetailsPageData'];
			type QueryData = NonNullable<PossibleQueryData>;

			const calculateEarliestSop = (queryData: QueryData): Date | undefined => {
				const sopVsiValues: string[] = queryData.sopVsiValues ?? [];

				return sopVsiValues.length > 0
					? new Date(
							Math.min(...sopVsiValues.map(dateStr => Date.parse(dateStr))),
					  )
					: undefined;
			};

			const queryData: PossibleQueryData = data?.vehicleProjectDetailsPageData;
			if (!queryData) return queryData;
			return {...queryData, earliestSOP: calculateEarliestSop(queryData)};
		}, [data]);

	const renderAssignedRequirements: RenderRequirementsList = useCallback(() => {
		/**
		 * We use a default array here in the items in case there's an error.
		 */
		return (
			<AssignedRequirementsList
				items={
					data?.vehicleProjectDetailsPageData?.assignedRequirements ??
					defaultArray
				}
				vehicleProjectId={pageData?.vehicleProjectId ?? ''}
			/>
		);
	}, [data, pageData?.vehicleProjectId]);

	return (
		<EntityListContextProvider
			pageDetails={data?.vehicleProjectDetailsPageData}
		>
			<DetailsPageForEntityLikeAProject
				isLoading={loading}
				refetchPageData={refetch}
				pageData={pageData}
				breadcrumbs={itemsWithHref}
				generation={generation}
				renderRequirementsList={renderAssignedRequirements}
			/>
		</EntityListContextProvider>
	);
};

export default VehicleProjectDetailsPage;
