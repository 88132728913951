import {QueryResult} from '@apollo/client';
import {RegulatoryDocument, StringOperationFilterInput} from 'types';
import _ from 'lodash';
import {SetStateAction, useCallback, useEffect, useState} from 'react';
import {
	GetAuditLogEntityInfoQuery,
	GetAuditLogEntityInfoQueryVariables as EntityInfoVariables,
	GetAuditLogsQuery,
	GetAuditLogsQueryVariables as AuditLogsVariables,
	useGetAuditLogsLazyQuery,
} from '../hooks/useGetRegulatoryDocumentDetails.generated';

export type RegDocQueryAuditLogs = NonNullable<GetAuditLogsQuery['auditLogs']>;
type AuditLogs = NonNullable<RegDocQueryAuditLogs['nodes']>;
export type QueryAuditLog = AuditLogs[number];

/**
 * Entity info
 */
type AuditLogsEntityInfo = NonNullable<GetAuditLogEntityInfoQuery['auditLogs']>;

type EntityInfoNodes = NonNullable<AuditLogsEntityInfo['nodes']>;

export type EntityInfo = EntityInfoNodes[number];

type IsLoading = boolean;

/**
 * Other
 */
export interface RegDocAuditLogsInfo {
	auditLogs: QueryAuditLog[];
	refetchAuditLogsAndUpdateIsLoading: () => Promise<void>;
	isLoading: IsLoading;
	setAreAuditLogsLoading: React.Dispatch<SetStateAction<IsLoading>>;
}

type AuditFieldNames =
	| 'summary'
	| 'workflowStatus'
	| 'status'
	| 'modelYear'
	| 'dateEffective'
	| 'dateExpiration'
	| 'name';

export const regDocAuditFieldNames: Record<AuditFieldNames, string> = {
	summary: '$.summary',
	workflowStatus: 'workflow.status',
	status: '$.status',
	modelYear: '$.modelYear',
	dateEffective: 'dateEffective',
	dateExpiration: 'dateExpiration',
	name: '$.name',
};

const getFieldFilter = (field: string): StringOperationFilterInput => {
	return {startsWith: field};
};

export const getFieldsFilter = (): AuditLogsVariables['fieldsFilter'] => {
	const fields: string[] = Object.values(regDocAuditFieldNames).concat(
		'$.workflow.reapprovingMessage',
	);
	return fields.map(getFieldFilter);
};

export const useGetRegDocAuditLogs = (
	docId: RegulatoryDocument['id'],
): RegDocAuditLogsInfo => {
	const [auditLogs, setAuditLogs] = useState<QueryAuditLog[]>([]);
	const [isLoading, setIsLoading] = useState<IsLoading>(false);
	const [getAuditLogs] = useGetAuditLogsLazyQuery();

	/**
	 * Entity infos
	 */
	type EntityInfoResult = QueryResult<
		GetAuditLogEntityInfoQuery,
		EntityInfoVariables
	>;

	type PossibleEntityInfo = EntityInfo | null;

	interface EntityInfoState {
		queryResult: EntityInfoResult;
		possibleRegDocEntityInfo: PossibleEntityInfo;
		shouldContinueSearching: boolean;
	}

	type AuditLogsResult = QueryResult<GetAuditLogsQuery, AuditLogsVariables>;

	const getIfQueryResultHasNextPage = (
		result: EntityInfoResult | AuditLogsResult,
	): boolean => {
		return Boolean(result.data?.auditLogs?.pageInfo?.hasNextPage);
	};

	type PossibleEndCursor =
		| EntityInfoVariables['endCursor']
		| AuditLogsVariables['endCursor'];

	interface AuditLogsState {
		auditLogs: QueryAuditLog[];
		hasNextPage: boolean;
		queryResult: AuditLogsResult;
	}

	const getEndCursorFromQueryResult = (
		state: EntityInfoState | AuditLogsState,
	): PossibleEndCursor => {
		return state.queryResult.data?.auditLogs?.pageInfo.endCursor;
	};

	type PossibleAuditLogsState = AuditLogsState | null;

	const getAuditLogsFromPrevState = (
		entityId: string,
		prevState: PossibleAuditLogsState,
	): Promise<AuditLogsResult> => {
		return getAuditLogs({
			variables: {
				endCursor: prevState
					? getEndCursorFromQueryResult(prevState)
					: undefined,
				entityId,
				fieldsFilter: getFieldsFilter(),
			},
		});
	};

	const combineAuditLogs = (
		result: AuditLogsResult,
		auditLogs: QueryAuditLog[] = [],
	): QueryAuditLog[] => {
		const newAuditLogs: QueryAuditLog[] = result.data?.auditLogs?.nodes ?? [];
		return [...auditLogs, ...newAuditLogs];
	};

	const createAuditLogsStateFromResult = (
		queryResult: AuditLogsResult,
		prevAuditLogs?: QueryAuditLog[],
	): AuditLogsState => {
		return {
			queryResult,
			auditLogs: combineAuditLogs(queryResult, prevAuditLogs),
			hasNextPage: getIfQueryResultHasNextPage(queryResult),
		};
	};

	const getAuditLogsState = async (
		entityId: string,
		state: PossibleAuditLogsState,
	): Promise<AuditLogsState> => {
		const result: AuditLogsResult = await getAuditLogsFromPrevState(
			entityId,
			state,
		);
		return createAuditLogsStateFromResult(result, state?.auditLogs);
	};

	const addRemainingAuditLogs = async (
		entityId: string,
		state: AuditLogsState,
	): Promise<void> => {
		while (state.hasNextPage) {
			// eslint-disable-next-line no-await-in-loop
			const newState: AuditLogsState = await getAuditLogsState(entityId, state);
			_.assign(state, newState);
		}
	};

	const getAuditLogsFromAllPages = async (
		entityId: string,
	): Promise<QueryAuditLog[]> => {
		const state: AuditLogsState = await getAuditLogsState(entityId, null);
		await addRemainingAuditLogs(entityId, state);
		return state.auditLogs;
	};

	const getAuditLogsIfPossible = async (
		docId: string,
	): Promise<QueryAuditLog[]> => {
		const entityId = atob(docId).substring('RegulatoryDocument\nd'.length);
		return getAuditLogsFromAllPages(entityId);
	};

	const refetchAuditLogsAndUpdateIsLoading: RegDocAuditLogsInfo['refetchAuditLogsAndUpdateIsLoading'] =
		useCallback(async () => {
			setIsLoading(true);
			const auditLogs: QueryAuditLog[] = await getAuditLogsIfPossible(docId);
			setAuditLogs(auditLogs);
			setIsLoading(false);
		}, [docId]);

	useEffect(() => {
		refetchAuditLogsAndUpdateIsLoading();
	}, [docId]);

	return {
		auditLogs,
		refetchAuditLogsAndUpdateIsLoading,
		isLoading,
		setAreAuditLogsLoading: setIsLoading,
	};
};
