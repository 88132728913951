import {
	ActionButton,
	IButtonStyles,
	IIconProps,
	IModalStyles,
	IStackStyles,
	ITextStyles,
	IconButton,
	Modal,
	Stack,
	Text,
	Theme,
	mergeStyles,
	useTheme,
} from '@fluentui/react';
import React from 'react';
import {useTranslation} from 'react-i18next';

type ErrorModalProps = {
	isOpen: boolean;
	onDismiss: () => void;
	error?: string;
};

export const ErrorModal: React.FC<ErrorModalProps> = ({
	isOpen,
	onDismiss,
	error,
}) => {
	const {t} = useTranslation('components/errormodal');
	const theme = useTheme();

	const onCopyClick = React.useCallback(async () => {
		if (error) {
			await navigator.clipboard.writeText(error);
		}
	}, [error]);

	const closeButtonStyles = React.useMemo(
		() => getCloseButtonStyles(theme),
		[theme],
	);

	return (
		<Modal
			isOpen={isOpen}
			onDismiss={onDismiss}
			closeButtonAriaLabel='close'
			styles={modalStyles}
		>
			<Stack styles={stackStyles} tokens={stackTokens}>
				<Stack horizontal horizontalAlign='space-between'>
					<Text variant='xLarge'>{t('ErrorMessage')}</Text>
					<IconButton
						styles={closeButtonStyles}
						iconProps={cancelIcon}
						ariaLabel='Close popup modal'
						onClick={onDismiss}
					/>
				</Stack>
				<div className={scrollableContentClassName}>
					<Text styles={textStyles}>{error}</Text>
				</div>
				<div>
					<ActionButton
						iconProps={copyIcon}
						styles={copyButtonStyles}
						onClick={onCopyClick}
					>
						{t('Copy')}
					</ActionButton>
				</div>
			</Stack>
		</Modal>
	);
};

const scrollableContentClassName = mergeStyles({
	maxHeight: '400px',
	overflowY: 'auto',
	padding: '16px',
});
const modalStyles: Partial<IModalStyles> = {root: {whiteSpace: 'pre-wrap'}};
const stackStyles: IStackStyles = {root: {maxWidth: 800, padding: 20}};
const stackTokens = {childrenGap: 8};
const textStyles: ITextStyles = {root: {fontFamily: 'ui-monospace'}};
const copyIcon: IIconProps = {iconName: 'Copy'};
const cancelIcon: IIconProps = {iconName: 'Cancel'};
const copyButtonStyles: IButtonStyles = {root: {float: 'right'}};
const getCloseButtonStyles: (
	theme: Theme,
) => Partial<IButtonStyles> = theme => ({
	root: {
		color: theme.palette.neutralPrimary,
		marginLeft: 'auto',
		marginTop: '4px',
		marginRight: '2px',
		float: 'right',
	},
	rootHovered: {
		color: theme.palette.neutralDark,
	},
});
