import * as Types from '../../types/types.generated';

import { gql } from '@apollo/client';
import { UserFieldsFragmentDoc } from '../../fragments/userFields.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMyVexClustersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMyVexClustersQuery = { __typename?: 'Query', myVexClusters: Array<{ __typename?: 'VexCluster', id: string, name: string, createdAt?: any | null, modifiedAt?: any | null, createdBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null }, modifiedBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null } }> };


export const GetMyVexClustersDocument = gql`
    query GetMyVexClusters {
  myVexClusters {
    id
    name
    createdBy {
      ...UserFields
    }
    modifiedBy {
      ...UserFields
    }
    createdAt
    modifiedAt
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useGetMyVexClustersQuery__
 *
 * To run a query within a React component, call `useGetMyVexClustersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyVexClustersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyVexClustersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyVexClustersQuery(baseOptions?: Apollo.QueryHookOptions<GetMyVexClustersQuery, GetMyVexClustersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyVexClustersQuery, GetMyVexClustersQueryVariables>(GetMyVexClustersDocument, options);
      }
export function useGetMyVexClustersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyVexClustersQuery, GetMyVexClustersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyVexClustersQuery, GetMyVexClustersQueryVariables>(GetMyVexClustersDocument, options);
        }
export type GetMyVexClustersQueryHookResult = ReturnType<typeof useGetMyVexClustersQuery>;
export type GetMyVexClustersLazyQueryHookResult = ReturnType<typeof useGetMyVexClustersLazyQuery>;
export type GetMyVexClustersQueryResult = Apollo.QueryResult<GetMyVexClustersQuery, GetMyVexClustersQueryVariables>;