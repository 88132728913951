import _ from 'lodash';
import {
	NameOfMergeableArrayField,
	namesOfMergeableArrayFields,
} from '../components/DocumentDetails/EditParagraphsForm/optimisticData/updateRegDocParagraphMutations/mergeableArrayFields';
import {
	DocOfRegDocDetailsPageQuery,
	QueryParagraph as Paragraph,
} from './RegDocDetailsPage.queryTypes';
import {RegDocOfRegDocsFormData} from '../components/DocumentDetails/CompareVersionForm.types';

/**
 * We omit attachments so it's more compatible with certain queries' paragraphs.
 */
type RegDocWithParagraphs =
	| DocOfRegDocDetailsPageQuery
	| RegDocOfRegDocsFormData;

type ArrayToSort = NonNullable<Paragraph[NameOfMergeableArrayField]>;
type ArrayItem = ArrayToSort[number];

type ParagraphKey = keyof Paragraph;
type ParagraphValue = Paragraph[ParagraphKey];

/**
 * This class helps us sort the paragraph's arrays so that the optimistic
 * paragraph's array fields (Ex: keyword) display in the same order as the real
 * paragraph's array fields.
 */
export class ParagraphFieldsSortingService {
	private getName = (item: ArrayItem): string => {
		return item.name;
	};

	private getIfShouldSortItem = (
		key: string,
		value: ParagraphValue,
	): boolean => {
		const keyWithCorrectType = key as NameOfMergeableArrayField;
		/**
		 * Note that the the mergeable arrays are the sortable arrays. Only
		 * mergeable arrays must be sorted because the server will not reorder any
		 * other fields in a special way.
		 */
		const isSortableField: boolean =
			namesOfMergeableArrayFields.includes(keyWithCorrectType);
		return isSortableField && _.isArray(value);
	};

	private sortArray = (items: ArrayToSort): ArrayToSort => {
		const sortedItems: ArrayItem[] = _.sortBy<ArrayItem>(items, this.getName);
		return sortedItems as ArrayToSort;
	};

	private sortFieldIfNecessary = (
		value: ParagraphValue,
		key: string,
	): ParagraphValue => {
		const isSortable: boolean = this.getIfShouldSortItem(key, value);
		if (isSortable) return this.sortArray(value);
		return value;
	};

	private sortParagraph = (paragraph: Paragraph): Paragraph => {
		return _.mapValues<Paragraph, ParagraphValue>(
			paragraph,
			this.sortFieldIfNecessary,
		);
	};

	public sortParagraphs = (paragraphs: Paragraph[]): Paragraph[] => {
		return paragraphs.map(this.sortParagraph);
	};

	public sortParagraphsInRegDoc = <RegDoc extends RegDocWithParagraphs>(
		regDoc: RegDoc,
	): RegDoc => {
		return {
			...regDoc,
			paragraphs: this.sortParagraphs(regDoc.paragraphs),
		};
	};
}
