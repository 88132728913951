import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateRegulationAttachmentsMutationVariables = Types.Exact<{
  input: Types.UpdateRegulationAttachmentsInput;
}>;


export type UpdateRegulationAttachmentsMutation = { __typename?: 'Mutation', updateRegulationAttachments: { __typename?: 'RegulationAttachmentsPayload', regulationAttachments?: { __typename?: 'UpdateEntityAttachmentsResult', added: number, deleted: number } | null } };


export const UpdateRegulationAttachmentsDocument = gql`
    mutation UpdateRegulationAttachments($input: UpdateRegulationAttachmentsInput!) {
  updateRegulationAttachments(input: $input) {
    regulationAttachments {
      added
      deleted
    }
  }
}
    `;
export type UpdateRegulationAttachmentsMutationFn = Apollo.MutationFunction<UpdateRegulationAttachmentsMutation, UpdateRegulationAttachmentsMutationVariables>;

/**
 * __useUpdateRegulationAttachmentsMutation__
 *
 * To run a mutation, you first call `useUpdateRegulationAttachmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRegulationAttachmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRegulationAttachmentsMutation, { data, loading, error }] = useUpdateRegulationAttachmentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRegulationAttachmentsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRegulationAttachmentsMutation, UpdateRegulationAttachmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRegulationAttachmentsMutation, UpdateRegulationAttachmentsMutationVariables>(UpdateRegulationAttachmentsDocument, options);
      }
export type UpdateRegulationAttachmentsMutationHookResult = ReturnType<typeof useUpdateRegulationAttachmentsMutation>;
export type UpdateRegulationAttachmentsMutationResult = Apollo.MutationResult<UpdateRegulationAttachmentsMutation>;
export type UpdateRegulationAttachmentsMutationOptions = Apollo.BaseMutationOptions<UpdateRegulationAttachmentsMutation, UpdateRegulationAttachmentsMutationVariables>;