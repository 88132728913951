import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetConvolutesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetConvolutesQuery = { __typename?: 'Query', convolutes?: Array<{ __typename?: 'Convolute', id: string, name: string, createdAt?: any | null, modifiedAt?: any | null, convoluteType: Types.ConvoluteType, createdBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null }, modifiedBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null }, gdprRule?: { __typename?: 'GdprRule', id: string, name: string } | null }> | null };

export type UpdateConvoluteMutationVariables = Types.Exact<{
  input: Types.UpdateConvoluteInput;
}>;


export type UpdateConvoluteMutation = { __typename?: 'Mutation', updateConvolute: { __typename?: 'UpdateConvolutePayload', convolute?: { __typename?: 'Convolute', id: string } | null } };


export const GetConvolutesDocument = gql`
    query GetConvolutes {
  convolutes {
    id
    name
    createdAt
    createdBy {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
    modifiedAt
    modifiedBy {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
    gdprRule {
      id
      name
    }
    convoluteType
  }
}
    `;

/**
 * __useGetConvolutesQuery__
 *
 * To run a query within a React component, call `useGetConvolutesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConvolutesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConvolutesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetConvolutesQuery(baseOptions?: Apollo.QueryHookOptions<GetConvolutesQuery, GetConvolutesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConvolutesQuery, GetConvolutesQueryVariables>(GetConvolutesDocument, options);
      }
export function useGetConvolutesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConvolutesQuery, GetConvolutesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConvolutesQuery, GetConvolutesQueryVariables>(GetConvolutesDocument, options);
        }
export type GetConvolutesQueryHookResult = ReturnType<typeof useGetConvolutesQuery>;
export type GetConvolutesLazyQueryHookResult = ReturnType<typeof useGetConvolutesLazyQuery>;
export type GetConvolutesQueryResult = Apollo.QueryResult<GetConvolutesQuery, GetConvolutesQueryVariables>;
export const UpdateConvoluteDocument = gql`
    mutation UpdateConvolute($input: UpdateConvoluteInput!) {
  updateConvolute(input: $input) {
    convolute {
      id
    }
  }
}
    `;
export type UpdateConvoluteMutationFn = Apollo.MutationFunction<UpdateConvoluteMutation, UpdateConvoluteMutationVariables>;

/**
 * __useUpdateConvoluteMutation__
 *
 * To run a mutation, you first call `useUpdateConvoluteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConvoluteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConvoluteMutation, { data, loading, error }] = useUpdateConvoluteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConvoluteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateConvoluteMutation, UpdateConvoluteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateConvoluteMutation, UpdateConvoluteMutationVariables>(UpdateConvoluteDocument, options);
      }
export type UpdateConvoluteMutationHookResult = ReturnType<typeof useUpdateConvoluteMutation>;
export type UpdateConvoluteMutationResult = Apollo.MutationResult<UpdateConvoluteMutation>;
export type UpdateConvoluteMutationOptions = Apollo.BaseMutationOptions<UpdateConvoluteMutation, UpdateConvoluteMutationVariables>;