import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import { KeywordTagFieldsFragmentDoc } from '../../../fragments/keywordTagFields.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetParagraphsFormDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetParagraphsFormDataQuery = { __typename?: 'Query', keywords?: Array<{ __typename?: 'Keyword', id: string, name: string, vexClusters: Array<{ __typename?: 'VexCluster', id: string, name: string }>, vkos: Array<{ __typename?: 'User', id: string, name: string }> }> | null, vehicleCategories?: Array<{ __typename?: 'VehicleCategory', id: string, name: string }> | null, categories?: Array<{ __typename?: 'Category', id: string, name: string }> | null, driveVariants?: Array<{ __typename?: 'DriveVariant', id: string, name: string }> | null, tags?: Array<{ __typename?: 'Tag', id: string, name: string }> | null };


export const GetParagraphsFormDataDocument = gql`
    query GetParagraphsFormData {
  keywords {
    ...KeywordTagFields
  }
  vehicleCategories {
    id
    name
  }
  categories {
    id
    name
  }
  driveVariants {
    id
    name
  }
  tags {
    id
    name
  }
}
    ${KeywordTagFieldsFragmentDoc}`;

/**
 * __useGetParagraphsFormDataQuery__
 *
 * To run a query within a React component, call `useGetParagraphsFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParagraphsFormDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParagraphsFormDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetParagraphsFormDataQuery(baseOptions?: Apollo.QueryHookOptions<GetParagraphsFormDataQuery, GetParagraphsFormDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetParagraphsFormDataQuery, GetParagraphsFormDataQueryVariables>(GetParagraphsFormDataDocument, options);
      }
export function useGetParagraphsFormDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetParagraphsFormDataQuery, GetParagraphsFormDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetParagraphsFormDataQuery, GetParagraphsFormDataQueryVariables>(GetParagraphsFormDataDocument, options);
        }
export type GetParagraphsFormDataQueryHookResult = ReturnType<typeof useGetParagraphsFormDataQuery>;
export type GetParagraphsFormDataLazyQueryHookResult = ReturnType<typeof useGetParagraphsFormDataLazyQuery>;
export type GetParagraphsFormDataQueryResult = Apollo.QueryResult<GetParagraphsFormDataQuery, GetParagraphsFormDataQueryVariables>;