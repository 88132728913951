import * as React from 'react';
import {SelectionMode} from '@fluentui/react';
import {RegulatoryDocument} from 'types';

export type SelectedRegDoc = Partial<RegulatoryDocument>;

export type RegulatoryDocumentsContextProps = {
	selectedRegulatoryDocument: SelectedRegDoc | null;
	setSelectedRegulatoryDocument: (
		regulatoryDocument: Partial<RegulatoryDocument> | null,
	) => void;
	allSelectedRegulatoryDocuments: Partial<RegulatoryDocument>[] | null;
	setAllSelectedRegulatoryDocuments: (
		regulatoryDocuments: Partial<RegulatoryDocument>[] | null,
	) => void;
	selectionMode: SelectionMode;
	setSelectionMode: (selectionMode: SelectionMode) => void;
};

export const RegulatoryDocumentsContext =
	React.createContext<RegulatoryDocumentsContextProps>({
		selectedRegulatoryDocument: null,
		allSelectedRegulatoryDocuments: null,
		selectionMode: SelectionMode.none,
	} as RegulatoryDocumentsContextProps);
