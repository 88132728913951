import {PanelType, Stack} from '@fluentui/react';
import {EntityListColumn, EntityPage} from 'components';
import {LoadWrapper} from 'components/LoadWrapper';
import {ViewAuthorizer} from 'components/ViewAuthorizer';
import React from 'react';
import {TFunction, useTranslation} from 'react-i18next';
import {Market, UserRole} from 'types';
import {MarketFormElements} from './components';
import {
	GetAllMarketsDocument,
	useCreateMarketMutation,
	useDeleteMarketMutation,
	useGetAllMarketsQuery,
	useUpdateMarketMutation,
} from './hooks/markets.generated';
import {
	ENTITY_SUBFIELDS_KEY,
	MarketsTooltipTranslationProvider,
	ProviderThatEnablesGettingTooltipsFromContext,
	TOOLTIPS_TEXT_KEY,
} from 'features/localizedTooltips';
import {mapToRef, omitTypename} from 'helpers';
import {Control} from 'react-hook-form';
import {renderArrayField} from 'components/EntityList/ColumnRenderers';

const AdminMarketsPage: React.FC = () => {
	const {t} = useTranslation('features/adminsection', {
		keyPrefix: 'AdminMarketsPage',
	});
	const {t: tEnum} = useTranslation('common/enums', {
		keyPrefix: 'Unit',
	});
	const {t: tEnumMilestone} = useTranslation('common/enums', {
		keyPrefix: 'Milestone',
	});
	// Const {loading, data} = useGetAllMarketsQuery();
	// const {loadingCountries, dataCountries} = useGetCountriesQuery();
	const [createMarketMutation] = useCreateMarketMutation();
	const [updateMarketMutation] = useUpdateMarketMutation();
	const [deleteMarketMutation] = useDeleteMarketMutation();

	const {loading, data} = useGetAllMarketsQuery();
	const markets = React.useMemo(() => data?.markets ?? [], [data]);

	const refetchQueries = [GetAllMarketsDocument];

	const createMarket = React.useCallback((market: Market) => {
		createMarketMutation({
			variables: {
				input: {
					name: market.name,
					regulationNamingTemplates: market.regulationNamingTemplates,
					regulatoryDocumentNamingTemplates:
						market.regulatoryDocumentNamingTemplates,
					countriesRef: mapToRef((market as any).countries),
				},
			},
			refetchQueries,
		});
	}, []);
	const updateMarket = React.useCallback((market: Market) => {
		updateMarketMutation({
			variables: {
				input: {
					id: market.id,
					name: market.name,
					regulationNamingTemplates: omitTypename(
						market.regulationNamingTemplates,
					),
					regulatoryDocumentNamingTemplates: omitTypename(
						market.regulatoryDocumentNamingTemplates,
					),
					countriesRef: mapToRef((market as any).countries),
				},
			},
			refetchQueries,
		});
	}, []);
	const deleteMarket = React.useCallback((id: string) => {
		deleteMarketMutation({
			variables: {
				input: {id},
			},
			refetchQueries,
		});
	}, []);

	const columns: EntityListColumn[] = React.useMemo(
		() => [
			{
				key: 'namingTemplates',
				name: t('namingTemplates'),
				fieldName: 'namingTemplates',
				minWidth: 50,
				maxWidth: 250,
				isResizable: true,
				onRender: renderNamingTemplates(t),
			},
			{
				key: 'countries',
				name: t('Countries'),
				fieldName: 'countries',
				minWidth: 200,
				isResizable: true,
				isMultiline: true,
				filterable: true,
				onRender: renderArrayField(),
			},
		],
		[t],
	);

	const getFormTranslationKeyPrefix = (): string => {
		return `${TOOLTIPS_TEXT_KEY}.${ENTITY_SUBFIELDS_KEY}`;
	};

	const formElements = React.useCallback((control: Control<Market>) => {
		return (
			<MarketsTooltipTranslationProvider
				translationHookOptions={{keyPrefix: getFormTranslationKeyPrefix()}}
			>
				<ProviderThatEnablesGettingTooltipsFromContext>
					<MarketFormElements control={control} />
				</ProviderThatEnablesGettingTooltipsFromContext>
			</MarketsTooltipTranslationProvider>
		);
	}, []);

	return (
		<ViewAuthorizer roles={[UserRole.SystemAdministrator]}>
			<LoadWrapper loading={loading}>
				<MarketsTooltipTranslationProvider>
					<EntityPage
						items={markets}
						entityDisplayName={t('Market')}
						createEntity={createMarket}
						updateEntity={updateMarket}
						deleteEntity={deleteMarket}
						additionalColumns={columns}
						panelType={PanelType.medium}
						renderAdditionalFormElements={formElements}
					/>
				</MarketsTooltipTranslationProvider>
			</LoadWrapper>
		</ViewAuthorizer>
	);
};

// eslint-disable-next-line react/display-name
const renderNamingTemplates = (t: TFunction) => (item: Market) => {
	const hasRegulationNamingTemplates =
		item.regulationNamingTemplates.length > 0;
	const hasRegulatoryDocumentNamingTemplates =
		item.regulatoryDocumentNamingTemplates.length > 0;

	return (
		<Stack horizontal tokens={{childrenGap: 8}}>
			<Stack>
				{hasRegulationNamingTemplates && (
					<Stack.Item>{t('RegulationNumber')}:</Stack.Item>
				)}
				{hasRegulatoryDocumentNamingTemplates && (
					<Stack.Item>{t('RegulatoryDocumentName')}:</Stack.Item>
				)}
			</Stack>
			<Stack>
				{hasRegulationNamingTemplates && (
					<Stack.Item>{item.regulationNamingTemplates.length}</Stack.Item>
				)}
				{hasRegulatoryDocumentNamingTemplates && (
					<Stack.Item>
						{item.regulatoryDocumentNamingTemplates.length}
					</Stack.Item>
				)}
			</Stack>
		</Stack>
	);
};

export default AdminMarketsPage;
