import React, {useCallback} from 'react';
import {IRenderFunction} from '@fluentui/utilities';
import {
	PropsForTooltipHoc,
	PropsWithAriaDescribedBy,
} from './TooltipWithComponentWithAriaDescribedBy';
import {
	TooltipRendererCreatorService,
	TooltipRendererFields,
} from 'features/localizedTooltips/TooltipRendererCreator.service';
import DefaultTooltipHostWithComponent from './DefaultTooltipHostWithComponent';

interface BasePropsOfOriginalComponent<
	PropsToRenderLabel extends PropsWithAriaDescribedBy,
> extends PropsWithAriaDescribedBy {
	onRenderLabel?: IRenderFunction<PropsToRenderLabel>;
}

type Props<
	PropsToRenderLabel extends PropsWithAriaDescribedBy,
	OriginalComponentProps extends BasePropsOfOriginalComponent<PropsToRenderLabel>,
> = PropsForTooltipHoc<OriginalComponentProps> & {
	keyWithFieldName: keyof OriginalComponentProps;
};

export const WithDefaultTooltipLabelRenderer = <
	PropsToRenderLabel extends PropsWithAriaDescribedBy,
	PropsOfOriginalComponent extends BasePropsOfOriginalComponent<PropsToRenderLabel>,
>({
	Component,
	tooltipHostProps = {},
	keyWithFieldName,
	...originalComponentPropsWithoutType
}: Props<PropsToRenderLabel, PropsOfOriginalComponent>): JSX.Element => {
	const {getCallRendererIfDefaultOneExists} =
		new TooltipRendererCreatorService();

	const originalComponentProps =
		originalComponentPropsWithoutType as unknown as PropsOfOriginalComponent;

	type RenderLabel = IRenderFunction<PropsOfOriginalComponent>;

	type ExtraRendererInfo = null;

	type FieldsToRenderDefaultTooltipHost = TooltipRendererFields<
		ExtraRendererInfo,
		PropsOfOriginalComponent
	>;

	const renderDefaultTooltipHost = ({
		props,
		defaultRender,
	}: FieldsToRenderDefaultTooltipHost): JSX.Element => {
		return (
			<DefaultTooltipHostWithComponent
				Component={defaultRender}
				componentProps={props as PropsOfOriginalComponent}
				keyWithFieldName={keyWithFieldName}
				tooltipHostProps={tooltipHostProps}
			/>
		);
	};

	const createRenderer = (): RenderLabel => {
		return getCallRendererIfDefaultOneExists<
			ExtraRendererInfo,
			PropsOfOriginalComponent
		>(null, renderDefaultTooltipHost);
	};

	const renderLabel: RenderLabel = createRenderer();

	const memoizedRenderLabel: RenderLabel = useCallback(renderLabel, [
		keyWithFieldName,
		tooltipHostProps,
	]);

	return (
		<Component
			onRenderLabel={memoizedRenderLabel}
			{...originalComponentProps}
		/>
	);
};

type KeysWeProvideInHoc = 'Component' | 'keyWithFieldName';

export type PropsForComponentWithDefaultTooltipLabelRenderer<
	PropsToRenderLabel extends PropsWithAriaDescribedBy,
	PropsOfOriginalComponent extends BasePropsOfOriginalComponent<PropsToRenderLabel>,
> = Omit<
	Props<PropsToRenderLabel, PropsOfOriginalComponent>,
	KeysWeProvideInHoc
>;

export const withDefaultTooltipLabelRenderer = <
	PropsToRenderLabel extends PropsWithAriaDescribedBy,
	PropsOfOriginalComponent extends BasePropsOfOriginalComponent<PropsToRenderLabel>,
>(
	Component: Props<PropsToRenderLabel, PropsOfOriginalComponent>['Component'],
	keyWithFieldName: keyof PropsOfOriginalComponent,
) => {
	type PropsWithGenerics = Props<PropsToRenderLabel, PropsOfOriginalComponent>;

	/**
	 * The HOC still accepts the tooltip props in case we need to overwrite them.
	 */
	type PropsForComponentWithLabelRenderer =
		PropsForComponentWithDefaultTooltipLabelRenderer<
			PropsToRenderLabel,
			PropsOfOriginalComponent
		>;

	const ComponentWithLabelRenderer = (
		props: PropsForComponentWithLabelRenderer,
	): JSX.Element => {
		type BaseProps = Pick<PropsWithGenerics, KeysWeProvideInHoc>;

		const getBaseProps = (): BaseProps => {
			return {
				Component,
				keyWithFieldName: keyWithFieldName as BaseProps['keyWithFieldName'],
			};
		};

		/**
		 * The compiler seems to be getting confused here, so we have to assert
		 * this type.
		 */
		const combineBasePropsWithProps = (baseProps: BaseProps) => {
			return {...baseProps, ...props} as PropsWithGenerics;
		};

		const getOriginalProps = (): PropsWithGenerics => {
			const baseProps: BaseProps = getBaseProps();
			return combineBasePropsWithProps(baseProps);
		};

		const originalComponentProps: PropsWithGenerics = getOriginalProps();

		const ComponentWithGenericTypes = WithDefaultTooltipLabelRenderer<
			PropsToRenderLabel,
			PropsOfOriginalComponent
		>;

		return <ComponentWithGenericTypes {...originalComponentProps} />;
	};

	return ComponentWithLabelRenderer;
};
