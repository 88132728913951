import {UseTranslationOptions, useTranslation} from 'react-i18next';
import vehicleProjectsTranslations from '../../../locales/features/vehicleprojects.de.json';

type KeyOfVehicleProjectsTranslations =
	keyof typeof vehicleProjectsTranslations;

export const useVehicleProjectsTranslation = (
	options?: UseTranslationOptions<KeyOfVehicleProjectsTranslations>,
) => {
	return useTranslation('features/vehicleprojects', options);
};

export const createNamespaceForVehicleProjects = (
	vehicleProjectsNamespace: string,
): string => {
	return `features/VehicleProjects/${vehicleProjectsNamespace}`;
};

export const useTranslationForPageInVehicleProjects = (
	vehicleProjectsNamespace: string,
) => {
	const namespace: string = createNamespaceForVehicleProjects(
		vehicleProjectsNamespace,
	);
	return useTranslation(namespace);
};
