import {FC} from 'react';
import {Controller} from 'react-hook-form';
import {
	DirectionalHint,
	ISpinButtonProps,
	Position,
	SpinButton,
} from '@fluentui/react';
import * as React from 'react';
import {HookFormProps} from './HookFormProps';
import {
	TooltipWithComponentWithAriaDescribedBy,
	TooltipOptionsForComponentOrColumn,
} from 'features/localizedTooltips';

type Props = HookFormProps &
	ISpinButtonProps &
	TooltipOptionsForComponentOrColumn;

export const ControlledSpinButton: FC<Props> = ({
	tooltipHostProps,
	...other
}) => {
	return (
		<Controller
			name={other.name}
			control={other.control}
			rules={other.rules}
			defaultValue={other.defaultValue}
			render={({field: {onChange, onBlur, value}}) => (
				<TooltipWithComponentWithAriaDescribedBy
					{...other}
					Component={SpinButton}
					min={other.min}
					onChange={(_, val) => {
						if (val) {
							onChange(parseInt(val, 10));
						}
					}}
					value={value ? `${value}` : undefined}
					onBlur={onBlur}
					labelPosition={other.labelPosition ?? Position.top}
					decrementButtonAriaLabel={'decrement'}
					incrementButtonAriaLabel={'increment'}
					tooltipHostProps={{
						translationKey: other.name,
						directionalHint: DirectionalHint.leftCenter,
						...tooltipHostProps,
					}}
				/>
			)}
		/>
	);
};
