import {Controller} from 'react-hook-form';
import {Dropdown, IDropdownProps} from '@fluentui/react';
import * as React from 'react';
import {HookFormProps} from './HookFormProps';
import {
	PropsForComponentWithDefaultTooltipLabelRenderer,
	WithDefaultTooltipLabelRenderer,
	withDefaultTooltipLabelRenderer,
} from 'features/localizedTooltips/WithDefaultTooltipLabelRenderer';

type Key = string | number;

type PropsOfDropdownWithoutTooltip<Item> = Omit<IDropdownProps, 'options'> & {
	/**
	 * This can be an array of any object. However, you must define getKey and
	 * getText.
	 */
	selectableOptions: Item[];
	getKey: (item: Item) => Key;
	getText: (item: Item) => string;
};

export type GetSelectedKey<Item> = (possibleValue: Item | undefined) => string;

interface PropsOfControlledEntityDropdownWithoutTooltip<T>
	extends HookFormProps,
		Omit<
			PropsOfDropdownWithoutTooltip<T>,
			'required' | 'selectedKey' | 'onBlur' | 'errorMessage' | 'defaultValue'
		> {
	/**
	 * The value can be undefined when it's undefined in the form fields by
	 * default.
	 */
	getSelectedKey?: GetSelectedKey<T>;
}

export function EntityDropdown<T>({
	selectableOptions,
	getKey,
	getText,
	...dropdownProps
}: PropsOfDropdownWithoutTooltip<T>) {
	const options = React.useMemo(
		() =>
			selectableOptions.map(o => ({
				...o,
				key: getKey(o),
				text: getText(o),
			})),
		[selectableOptions],
	);

	return <Dropdown {...dropdownProps} options={options} />;
}

function ControlledEntityDropdownWithoutTooltip<Item>(
	props: PropsOfControlledEntityDropdownWithoutTooltip<Item>,
) {
	return (
		<Controller
			name={props.name}
			control={props.control}
			rules={props.rules}
			render={({field: {onChange, onBlur, value}, fieldState: {error}}) => {
				const {getKey, getSelectedKey: possibleSelectedKeyGetter} = props;

				const getSelectedKey = (): Key | undefined => {
					if (possibleSelectedKeyGetter)
						return possibleSelectedKeyGetter(value);
					return value ? getKey(value) : undefined;
				};

				return (
					<EntityDropdown
						{...props}
						required={Boolean(props.rules?.required)}
						selectedKey={getSelectedKey()}
						onChange={(_, option) => {
							onChange(option);
						}}
						onBlur={onBlur}
						defaultSelectedKey={undefined}
						errorMessage={error && error.message}
					/>
				);
			}}
		/>
	);
}

export type PropsOfControlledEntityDropdown<Item> =
	PropsForComponentWithDefaultTooltipLabelRenderer<
		IDropdownProps,
		PropsOfControlledEntityDropdownWithoutTooltip<Item>
	>;

/**
 * We are not using the corresponding HOC here. Otherwise, the component might
 * get recreated each time the state changes, which could cause unforeseen
 * issues.
 */
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const ControlledEntityDropdown = <Item extends unknown>(
	props: PropsOfControlledEntityDropdown<Item>,
) => {
	const ComponentWithGenerics = WithDefaultTooltipLabelRenderer<
		IDropdownProps,
		PropsOfControlledEntityDropdownWithoutTooltip<Item>
	>;

	return (
		<ComponentWithGenerics
			Component={ControlledEntityDropdownWithoutTooltip}
			keyWithFieldName='name'
			{...props}
		/>
	);
};

type PropsOfControlledDropdownWithoutTooltip = HookFormProps & IDropdownProps;

const ControlledDropdownWithoutTooltip: React.FC<
	PropsOfControlledDropdownWithoutTooltip
> = props => {
	return (
		<Controller
			name={props.name}
			control={props.control}
			rules={props.rules}
			defaultValue={props.defaultValue || ''}
			render={({field: {onChange, onBlur, value}, fieldState: {error}}) => (
				<Dropdown
					{...props}
					required={Boolean(props.rules?.required)}
					selectedKey={value}
					onChange={(_e, option) => {
						onChange(option?.key);
						props.onChange?.(_e, option);
					}}
					onBlur={onBlur}
					errorMessage={error && error.message}
					defaultValue={undefined}
				/>
			)}
		/>
	);
};

export type ControlledDropdownProps =
	PropsForComponentWithDefaultTooltipLabelRenderer<
		IDropdownProps,
		PropsOfControlledDropdownWithoutTooltip
	>;

export const ControlledDropdown = withDefaultTooltipLabelRenderer<
	IDropdownProps,
	PropsOfControlledDropdownWithoutTooltip
>(ControlledDropdownWithoutTooltip, 'name');
