import {PanelType} from '@fluentui/react';
import {EntityPage} from 'components';
import {LoadWrapper} from 'components/LoadWrapper';
import {ViewAuthorizer} from 'components/ViewAuthorizer';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Country, UserRole} from 'types';
import {
	GetCountriesDocument,
	useCreateCountryMutation,
	useDeleteCountryMutation,
	useGetCountriesQuery,
	useUpdateCountryMutation,
} from './hooks/countries.generated';
import {CountriesTooltipTranslationProvider} from 'features/localizedTooltips';

const AdminCountriesPage: React.FC = () => {
	const {t} = useTranslation('features/adminsection', {
		keyPrefix: 'AdminCountriesPage',
	});
	const {loading, data} = useGetCountriesQuery();
	const [createCountryMutation] = useCreateCountryMutation();
	const [updateCountryMutation] = useUpdateCountryMutation();
	const [deleteCountryMutation] = useDeleteCountryMutation();

	const countries = React.useMemo(() => data?.countries ?? [], [data]);

	const refetchQueries = [GetCountriesDocument];

	const createCountry = React.useCallback((country: Country) => {
		createCountryMutation({
			variables: {
				input: {
					name: country.name,
				},
			},
			refetchQueries,
		});
	}, []);
	const updateCountry = React.useCallback((country: Country) => {
		updateCountryMutation({
			variables: {
				input: {
					id: country.id,
					name: country.name,
				},
			},
			refetchQueries,
		});
	}, []);
	const deleteCountry = React.useCallback((id: string) => {
		deleteCountryMutation({
			variables: {
				input: {id},
			},
			refetchQueries,
		});
	}, []);

	return (
		<ViewAuthorizer roles={[UserRole.SystemAdministrator]}>
			<LoadWrapper loading={loading}>
				<CountriesTooltipTranslationProvider>
					<EntityPage
						items={countries}
						entityDisplayName={t('Country')}
						createEntity={createCountry}
						updateEntity={updateCountry}
						deleteEntity={deleteCountry}
						panelType={PanelType.medium}
					/>
				</CountriesTooltipTranslationProvider>
			</LoadWrapper>
		</ViewAuthorizer>
	);
};

export default AdminCountriesPage;
