import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateRegulatoryDocumentMutationVariables = Types.Exact<{
  input: Types.UpdateRegulatoryDocumentInput;
  clearingVKOs?: Types.InputMaybe<Array<Types.EntityRefOfUserInput> | Types.EntityRefOfUserInput>;
  reapprovingVKO?: Types.InputMaybe<Types.EntityRefOfUserInput>;
}>;


export type UpdateRegulatoryDocumentMutation = { __typename?: 'Mutation', updateRegulatoryDocument: { __typename?: 'RegulatoryDocumentPayload', regulatoryDocument: { __typename?: 'RegulatoryDocument', id: string } } };


export const UpdateRegulatoryDocumentDocument = gql`
    mutation UpdateRegulatoryDocument($input: UpdateRegulatoryDocumentInput!, $clearingVKOs: [EntityRefOfUserInput!], $reapprovingVKO: EntityRefOfUserInput) {
  updateRegulatoryDocument(
    input: $input
    clearingVKOs: $clearingVKOs
    reapprovingVKO: $reapprovingVKO
  ) {
    regulatoryDocument {
      id
    }
  }
}
    `;
export type UpdateRegulatoryDocumentMutationFn = Apollo.MutationFunction<UpdateRegulatoryDocumentMutation, UpdateRegulatoryDocumentMutationVariables>;

/**
 * __useUpdateRegulatoryDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateRegulatoryDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRegulatoryDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRegulatoryDocumentMutation, { data, loading, error }] = useUpdateRegulatoryDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      clearingVKOs: // value for 'clearingVKOs'
 *      reapprovingVKO: // value for 'reapprovingVKO'
 *   },
 * });
 */
export function useUpdateRegulatoryDocumentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRegulatoryDocumentMutation, UpdateRegulatoryDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRegulatoryDocumentMutation, UpdateRegulatoryDocumentMutationVariables>(UpdateRegulatoryDocumentDocument, options);
      }
export type UpdateRegulatoryDocumentMutationHookResult = ReturnType<typeof useUpdateRegulatoryDocumentMutation>;
export type UpdateRegulatoryDocumentMutationResult = Apollo.MutationResult<UpdateRegulatoryDocumentMutation>;
export type UpdateRegulatoryDocumentMutationOptions = Apollo.BaseMutationOptions<UpdateRegulatoryDocumentMutation, UpdateRegulatoryDocumentMutationVariables>;