/**
 * This file contains common hooks that are not in other files.
 */

import {useTranslation} from 'react-i18next';
import {SUBSCRIPTIONS_TRANSLATION_NAMESPACE} from '../subscriptionsConstants';

export const useSubscriptionsTranslation = () => {
	return useTranslation(SUBSCRIPTIONS_TRANSLATION_NAMESPACE);
};
