import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateInterimRequirementMutationVariables = Types.Exact<{
  regulatoryDocumentId: Types.Scalars['ID'];
}>;


export type CreateInterimRequirementMutation = { __typename?: 'Mutation', createInterimRequirement: { __typename?: 'RegulatoryDocument', id: string, name: string, status: Types.RegulatoryDocumentStatus } };


export const CreateInterimRequirementDocument = gql`
    mutation createInterimRequirement($regulatoryDocumentId: ID!) {
  createInterimRequirement(regulatoryDocumentId: $regulatoryDocumentId) {
    id
    name
    status
  }
}
    `;
export type CreateInterimRequirementMutationFn = Apollo.MutationFunction<CreateInterimRequirementMutation, CreateInterimRequirementMutationVariables>;

/**
 * __useCreateInterimRequirementMutation__
 *
 * To run a mutation, you first call `useCreateInterimRequirementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInterimRequirementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInterimRequirementMutation, { data, loading, error }] = useCreateInterimRequirementMutation({
 *   variables: {
 *      regulatoryDocumentId: // value for 'regulatoryDocumentId'
 *   },
 * });
 */
export function useCreateInterimRequirementMutation(baseOptions?: Apollo.MutationHookOptions<CreateInterimRequirementMutation, CreateInterimRequirementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInterimRequirementMutation, CreateInterimRequirementMutationVariables>(CreateInterimRequirementDocument, options);
      }
export type CreateInterimRequirementMutationHookResult = ReturnType<typeof useCreateInterimRequirementMutation>;
export type CreateInterimRequirementMutationResult = Apollo.MutationResult<CreateInterimRequirementMutation>;
export type CreateInterimRequirementMutationOptions = Apollo.BaseMutationOptions<CreateInterimRequirementMutation, CreateInterimRequirementMutationVariables>;