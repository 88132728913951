import React from 'react';
import {
	SidebarInfoContextType,
	SidebarInfoProvider,
	SidebarInfoProviderProps,
	createSidebarInfoContext,
} from 'context/SidebarInfoProvider';

type Props = Pick<SidebarInfoProviderProps, 'children'>;

export const SideNavigationContext: SidebarInfoContextType =
	createSidebarInfoContext();

export function SideNavigationProvider(props: Props): JSX.Element {
	const defaultValueForIsCollapsed: boolean =
		localStorage.getItem('navigation-drawer') === 'true';

	return (
		<SidebarInfoProvider
			Context={SideNavigationContext}
			defaultValue={!defaultValueForIsCollapsed}
			{...props}
		/>
	);
}
