import {
	MarketPageParams,
	ProjectDetailsPageParams,
	encodeGenAndModelSeries,
	getMarketPagePath,
} from 'features/VehicleProjects/vehicleProjectPagePaths';
import {useNavigate} from 'react-router-dom';
import {
	ActiveTypeMarketsPageActiveTypeMarket as ActiveTypeMarket,
	ActiveTypeMarketsPageParams,
} from '../activeTypeMarketsPageTypes';
import {useCallback} from 'react';

export type NavigateToActiveTypeMarketDetails = (
	market: ActiveTypeMarket,
) => void;

export const useNavigateToActiveTypeMarketDetailsPage = (
	params: ActiveTypeMarketsPageParams,
): NavigateToActiveTypeMarketDetails => {
	const navigate = useNavigate();

	const getEncodedMarketPagePath = ({
		activeTypeMarketId,
		...other
	}: MarketPageParams): string => {
		/**
		 * We must encode these in case they have special characters.
		 */
		const genAndModelSeries: ProjectDetailsPageParams =
			encodeGenAndModelSeries(other);
		return getMarketPagePath({
			activeTypeMarketId,
			...genAndModelSeries,
		});
	};

	const getMarketPagePathFromMarket = useCallback(
		(activeTypeMarket: ActiveTypeMarket): string => {
			return getEncodedMarketPagePath({
				...params,
				activeTypeMarketId: activeTypeMarket.id,
			});
		},
		[params],
	);

	const navigateToDetailsPage = (activeTypeMarket: ActiveTypeMarket): void => {
		const path: string = getMarketPagePathFromMarket(activeTypeMarket);
		navigate(path);
	};

	return useCallback(navigateToDetailsPage, [
		getMarketPagePathFromMarket,
		navigate,
	]);
};
