import React, {useMemo} from 'react';
import {VehicleProjectsOfActiveTypeMarketQuery} from '../hooks';
import {EntityList, EntityListColumn} from 'components';
import {
	getArrayFieldColumn,
	getBooleanColumn,
	getDateColumn,
	getTextColumn,
} from 'helpers';
import {useVehicleProjectsTranslation} from '../hooks/vehicleProjectsTranslationHooks';
import {QueryResult} from '@apollo/client';
import {SelectionMode} from '@fluentui/react';
import {VehicleProjectsTooltipTranslationProvider} from '../components/VehicleProjectsTooltipTranslationProvider';
import {layoutTopHeight} from 'appShell/Layout';
import {heightOfBreadcrumbsOfGenerationSubpage} from '../baselines/BreadcrumbsOfGenerationSubpage';

interface Props {
	queryData: QueryResult<VehicleProjectsOfActiveTypeMarketQuery>['data'];
}

export function MarketVehicleProjects({queryData}: Props) {
	const {t} = useVehicleProjectsTranslation();

	const getRemainingPageHeight = (): string => {
		const extraSpace = '1rem';
		const topHeight = `${layoutTopHeight} + ${heightOfBreadcrumbsOfGenerationSubpage} + ${extraSpace}`;
		return `calc(100vh - (${topHeight}))`;
	};

	const getColumns = (): EntityListColumn[] => {
		return [
			getArrayFieldColumn('markets', t('Market'), 'markets', true),
			getTextColumn(
				'modelSeries',
				t('ModelSeries'),
				'modelSeries',
				false,
				true,
			),
			getTextColumn('generation', t('Generation'), 'generation', false, true),
			getTextColumn('derivative', t('Derivative'), 'derivative', false, true),
			getTextColumn('modelYear', t('ModelYear'), 'modelYear', false, true),
			getBooleanColumn('euLegislation', t('EuLegislation'), 'euLegislation'),
			getArrayFieldColumn(
				'engineVariants',
				t('EngineVariants'),
				'engineVariants',
				true,
			),
			getArrayFieldColumn(
				'driveVariants',
				t('DriveVariants'),
				'driveVariants',
				true,
			),
			getArrayFieldColumn(
				'bodyworkVariants',
				t('BodyworkVariants'),
				'bodyworkVariants',
				true,
			),
			getArrayFieldColumn(
				'gearboxVariants',
				t('GearboxVariants'),
				'gearboxVariants',
				true,
			),
			getBooleanColumn('isElectro', t('IsElectro'), 'isElectro'),
			getDateColumn('detVsi', t('DetVsi'), 'detVsi', true),
			getDateColumn('p10Vsi', t('P10Vsi'), 'p10Vsi', true),
			getDateColumn('typStartVsi', t('TypStartVsi'), 'typStartVsi', true),
			getDateColumn('sysgenVsi', t('SysgenVsi'), 'sysgenVsi', true),
			getDateColumn('sopVsi', t('SopVsi'), 'sopVsi', true),
			getDateColumn('wvtaVsi', t('WvtaVsi'), 'wvtaVsi', true),
			getDateColumn('sosVsi', t('SosVsi'), 'sosVsi', true),
			getDateColumn('posEtVsi', t('PosEtVsi'), 'posEtVsi', true),
			getDateColumn('posVsi', t('PosVsi'), 'posVsi', true),
			getDateColumn('eopVsi', t('EopVsi'), 'eopVsi', true),
		];
	};

	const columns: EntityListColumn[] = useMemo(getColumns, []);

	return (
		<VehicleProjectsTooltipTranslationProvider>
			<EntityList
				items={
					queryData?.vehicleProjectsByModelSeriesGenerationActiveTypeMarket ??
					[]
				}
				columns={columns}
				selectionMode={SelectionMode.none}
				styles={{
					root: {
						overflowY: 'scroll',
						// This allows the user to always see about 3 rows.
						minHeight: '12.5rem',
						height: getRemainingPageHeight(),
					},
				}}
			/>
		</VehicleProjectsTooltipTranslationProvider>
	);
}
