import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import { MarketNamingTemplateFieldsFragmentDoc } from '../../../fragments/marketNamingTemplateFields.generated';
import { KeywordTagFieldsFragmentDoc } from '../../../fragments/keywordTagFields.generated';
import { AttachmentFieldsFragmentDoc } from '../../../fragments/attachmentFields.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllRegulationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllRegulationsQuery = { __typename?: 'Query', regulations: Array<{ __typename?: 'Regulation', id: string, regulationNumber: string, name: string, summary: string, createdAt?: any | null, modifiedAt?: any | null, markets: Array<{ __typename?: 'Market', id: string, name: string, regulationNamingTemplates: Array<{ __typename?: 'NamingTemplate', template: string, description: string }>, regulatoryDocumentNamingTemplates: Array<{ __typename?: 'NamingTemplate', template: string, description: string }> }>, mainKeywords: Array<{ __typename?: 'Keyword', id: string, name: string }>, keywords: Array<{ __typename?: 'Keyword', id: string, name: string, vexClusters: Array<{ __typename?: 'VexCluster', id: string, name: string }>, vkos: Array<{ __typename?: 'User', id: string, name: string }> }>, standardPlusPcmsClusters: Array<{ __typename?: 'StandardPlusPcmsCluster', id: string, name: string }>, createdBy: { __typename?: 'User', id: string, name: string, mail?: string | null, userPrincipalName?: string | null }, modifiedBy: { __typename?: 'User', id: string, name: string, mail?: string | null, userPrincipalName?: string | null }, attachments?: Array<{ __typename?: 'AttachmentRef', attachmentId: string, isVkoOnly?: boolean | null, file: { __typename?: 'BlobRef', uri: any, contentType: string, fileName: string }, category?: { __typename?: 'AttachmentCategory', id: string, name: string } | null }> | null }> };


export const GetAllRegulationsDocument = gql`
    query GetAllRegulations {
  regulations {
    id
    regulationNumber
    name
    summary
    markets {
      id
      name
      ...MarketNamingTemplateFields
    }
    mainKeywords {
      id
      name
    }
    keywords {
      ...KeywordTagFields
    }
    standardPlusPcmsClusters {
      id
      name
    }
    createdAt
    modifiedAt
    createdBy {
      id
      name
      mail
      userPrincipalName
    }
    modifiedBy {
      id
      name
      mail
      userPrincipalName
    }
    attachments {
      ...AttachmentFields
    }
  }
}
    ${MarketNamingTemplateFieldsFragmentDoc}
${KeywordTagFieldsFragmentDoc}
${AttachmentFieldsFragmentDoc}`;

/**
 * __useGetAllRegulationsQuery__
 *
 * To run a query within a React component, call `useGetAllRegulationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRegulationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRegulationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllRegulationsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllRegulationsQuery, GetAllRegulationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllRegulationsQuery, GetAllRegulationsQueryVariables>(GetAllRegulationsDocument, options);
      }
export function useGetAllRegulationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllRegulationsQuery, GetAllRegulationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllRegulationsQuery, GetAllRegulationsQueryVariables>(GetAllRegulationsDocument, options);
        }
export type GetAllRegulationsQueryHookResult = ReturnType<typeof useGetAllRegulationsQuery>;
export type GetAllRegulationsLazyQueryHookResult = ReturnType<typeof useGetAllRegulationsLazyQuery>;
export type GetAllRegulationsQueryResult = Apollo.QueryResult<GetAllRegulationsQuery, GetAllRegulationsQueryVariables>;