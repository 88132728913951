/**
 * We aren't throwing an error here in the test environment because that would
 * require adding a translation provider and setting up the locales for almost
 * all tests. Instead, I documented how to test missing translations. If
 * necessary, we can mock this in unit tests to throw an error.
 */
export const throwErrorInDevAndLogInProd = (error: Error): void => {
	if (process.env.NODE_ENV === 'development') throw error;
	console.error(error);
};
