import {
	DefaultButton,
	Dialog,
	DialogFooter,
	DialogType,
	PrimaryButton,
} from '@fluentui/react';
import React from 'react';
import {useTranslation} from 'react-i18next';

export const DeleteImageDialog: React.FC<{
	hidden: boolean;
	onDismiss: () => void;
	onDeleteImageClick: () => void;
}> = ({onDismiss, hidden, onDeleteImageClick}) => {
	const {t} = useTranslation('features/regulatorydocuments', {
		keyPrefix: 'DocumentEditor.EditorContent',
	});

	const deleteImageDialogContentProps = {
		type: DialogType.largeHeader,
		title: t('DeleteImageHeader'),
		subText: t('DeleteImageSubText'),
	};
	const deleteImageModelProps = {
		isBlocking: false,
		styles: {main: {maxWidth: 450}},
	};

	return (
		<Dialog
			hidden={hidden}
			onDismiss={onDismiss}
			dialogContentProps={deleteImageDialogContentProps}
			modalProps={deleteImageModelProps}
		>
			<DialogFooter>
				<PrimaryButton onClick={onDeleteImageClick} text={t('Delete')} />
				<DefaultButton onClick={onDismiss} text={t('Cancel')} />
			</DialogFooter>
		</Dialog>
	);
};
