import React from 'react';
import {Market} from 'types';
import {MarketNamingTemplateDescriptions} from './MarketNamingTemplateDescriptions';
import {useMarketNamingTemplateValidation} from './hooks';

type MarketProps = keyof Market;

export type MarketNamingTemplateKeys = Extract<
	MarketProps,
	'regulationNamingTemplates' | 'regulatoryDocumentNamingTemplates'
>;

export type InputMarketProps = Pick<
	Market,
	| 'id'
	| 'name'
	| 'regulationNamingTemplates'
	| 'regulatoryDocumentNamingTemplates'
>;

type MarketNamingTemplateFieldChildProps = {
	validateFieldValue: (value: string) => boolean | string;
	isMarketSelected: boolean;
	placeholder: string;
};

type MarketNamingTemplateFieldProps = {
	markets: InputMarketProps[];
	namingHint: string;
	templatesToUse: MarketNamingTemplateKeys;
	children: (props: MarketNamingTemplateFieldChildProps) => React.ReactNode;
};

export const MarketNamingTemplateField: React.FC<
	MarketNamingTemplateFieldProps
> = ({markets, namingHint, templatesToUse, children}) => {
	const {validateFieldValue, isMarketSelected, placeholder} =
		useMarketNamingTemplateValidation(markets, namingHint, templatesToUse);

	return (
		<React.Fragment>
			{children({
				validateFieldValue,
				isMarketSelected,
				placeholder,
			})}
			<MarketNamingTemplateDescriptions
				markets={markets}
				templatesToUse={templatesToUse}
			/>
		</React.Fragment>
	);
};
