import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddRegulatoryDocumentParagraphCommentaryMutationVariables = Types.Exact<{
  input: Types.AddRegulatoryDocumentParagraphCommentaryInput;
}>;


export type AddRegulatoryDocumentParagraphCommentaryMutation = { __typename?: 'Mutation', addRegulatoryDocumentParagraphCommentary: { __typename?: 'AddRegulatoryDocumentParagraphCommentaryPayload', regulatoryDocument?: { __typename?: 'RegulatoryDocument', id: string } | null } };

export type AddRegulatoryDocumentCommentaryMutationVariables = Types.Exact<{
  input: Types.AddCommentaryInput;
}>;


export type AddRegulatoryDocumentCommentaryMutation = { __typename?: 'Mutation', addRegulatoryDocumentCommentary: { __typename?: 'RegulatoryDocument', id: string } };

export type AddRegulationCommentaryMutationVariables = Types.Exact<{
  input: Types.AddCommentaryInput;
}>;


export type AddRegulationCommentaryMutation = { __typename?: 'Mutation', addRegulationCommentary: { __typename?: 'Regulation', id: string } };


export const AddRegulatoryDocumentParagraphCommentaryDocument = gql`
    mutation AddRegulatoryDocumentParagraphCommentary($input: AddRegulatoryDocumentParagraphCommentaryInput!) {
  addRegulatoryDocumentParagraphCommentary(input: $input) {
    regulatoryDocument {
      id
    }
  }
}
    `;
export type AddRegulatoryDocumentParagraphCommentaryMutationFn = Apollo.MutationFunction<AddRegulatoryDocumentParagraphCommentaryMutation, AddRegulatoryDocumentParagraphCommentaryMutationVariables>;

/**
 * __useAddRegulatoryDocumentParagraphCommentaryMutation__
 *
 * To run a mutation, you first call `useAddRegulatoryDocumentParagraphCommentaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRegulatoryDocumentParagraphCommentaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRegulatoryDocumentParagraphCommentaryMutation, { data, loading, error }] = useAddRegulatoryDocumentParagraphCommentaryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRegulatoryDocumentParagraphCommentaryMutation(baseOptions?: Apollo.MutationHookOptions<AddRegulatoryDocumentParagraphCommentaryMutation, AddRegulatoryDocumentParagraphCommentaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddRegulatoryDocumentParagraphCommentaryMutation, AddRegulatoryDocumentParagraphCommentaryMutationVariables>(AddRegulatoryDocumentParagraphCommentaryDocument, options);
      }
export type AddRegulatoryDocumentParagraphCommentaryMutationHookResult = ReturnType<typeof useAddRegulatoryDocumentParagraphCommentaryMutation>;
export type AddRegulatoryDocumentParagraphCommentaryMutationResult = Apollo.MutationResult<AddRegulatoryDocumentParagraphCommentaryMutation>;
export type AddRegulatoryDocumentParagraphCommentaryMutationOptions = Apollo.BaseMutationOptions<AddRegulatoryDocumentParagraphCommentaryMutation, AddRegulatoryDocumentParagraphCommentaryMutationVariables>;
export const AddRegulatoryDocumentCommentaryDocument = gql`
    mutation AddRegulatoryDocumentCommentary($input: AddCommentaryInput!) {
  addRegulatoryDocumentCommentary(input: $input) {
    id
  }
}
    `;
export type AddRegulatoryDocumentCommentaryMutationFn = Apollo.MutationFunction<AddRegulatoryDocumentCommentaryMutation, AddRegulatoryDocumentCommentaryMutationVariables>;

/**
 * __useAddRegulatoryDocumentCommentaryMutation__
 *
 * To run a mutation, you first call `useAddRegulatoryDocumentCommentaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRegulatoryDocumentCommentaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRegulatoryDocumentCommentaryMutation, { data, loading, error }] = useAddRegulatoryDocumentCommentaryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRegulatoryDocumentCommentaryMutation(baseOptions?: Apollo.MutationHookOptions<AddRegulatoryDocumentCommentaryMutation, AddRegulatoryDocumentCommentaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddRegulatoryDocumentCommentaryMutation, AddRegulatoryDocumentCommentaryMutationVariables>(AddRegulatoryDocumentCommentaryDocument, options);
      }
export type AddRegulatoryDocumentCommentaryMutationHookResult = ReturnType<typeof useAddRegulatoryDocumentCommentaryMutation>;
export type AddRegulatoryDocumentCommentaryMutationResult = Apollo.MutationResult<AddRegulatoryDocumentCommentaryMutation>;
export type AddRegulatoryDocumentCommentaryMutationOptions = Apollo.BaseMutationOptions<AddRegulatoryDocumentCommentaryMutation, AddRegulatoryDocumentCommentaryMutationVariables>;
export const AddRegulationCommentaryDocument = gql`
    mutation AddRegulationCommentary($input: AddCommentaryInput!) {
  addRegulationCommentary(input: $input) {
    id
  }
}
    `;
export type AddRegulationCommentaryMutationFn = Apollo.MutationFunction<AddRegulationCommentaryMutation, AddRegulationCommentaryMutationVariables>;

/**
 * __useAddRegulationCommentaryMutation__
 *
 * To run a mutation, you first call `useAddRegulationCommentaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRegulationCommentaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRegulationCommentaryMutation, { data, loading, error }] = useAddRegulationCommentaryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRegulationCommentaryMutation(baseOptions?: Apollo.MutationHookOptions<AddRegulationCommentaryMutation, AddRegulationCommentaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddRegulationCommentaryMutation, AddRegulationCommentaryMutationVariables>(AddRegulationCommentaryDocument, options);
      }
export type AddRegulationCommentaryMutationHookResult = ReturnType<typeof useAddRegulationCommentaryMutation>;
export type AddRegulationCommentaryMutationResult = Apollo.MutationResult<AddRegulationCommentaryMutation>;
export type AddRegulationCommentaryMutationOptions = Apollo.BaseMutationOptions<AddRegulationCommentaryMutation, AddRegulationCommentaryMutationVariables>;