export const REPORTS_TABLE_NAME = 'reports';
export const isReportsTable = (id: string | undefined) =>
	id === REPORTS_TABLE_NAME;

export const DEFAULT_DATE = '1970-01-01T00:00:00.000Z';
export const DATE_TYPE = 'date';

export const isValueDateField = (dataType: string) => {
	return dataType === DATE_TYPE;
};
