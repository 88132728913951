import {IPersonaProps, Persona} from '@fluentui/react';
import React from 'react';

interface Persona {
	name: string;
}

interface Props extends IPersonaProps {
	persons: Persona[];
}

export function Personas({persons, ...other}: Props): JSX.Element {
	const renderPersona = (persona: Persona, index: number): JSX.Element => (
		<Persona key={index} text={persona.name} {...other} />
	);

	const renderedPersonas: JSX.Element[] = persons.map(renderPersona);

	return <>{renderedPersonas}</>;
}
