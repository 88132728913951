import {
	DefaultButton,
	Dialog,
	DialogType,
	IDialogProps,
	PrimaryButton,
	Stack,
} from '@fluentui/react';
import {useTranslation} from 'react-i18next';
import React from 'react';

export type ConfirmUpdateDialogProps = Omit<IDialogProps, 'modalProps'> & {
	onConfirm: (ev?: any) => void;
	itemCount?: number;
	confirmButtonLabel?: string;
};

export const ConfirmUpdateDialog: React.FC<ConfirmUpdateDialogProps> = ({
	dialogContentProps,
	itemCount,
	confirmButtonLabel,
	...props
}) => {
	const {t} = useTranslation('components/dialogs', {
		keyPrefix: 'ConfirmUpdateDialog',
	});

	return (
		<Dialog
			dialogContentProps={{
				type: DialogType.normal,
				title: t('Title'),
				subText: t('Subtext', {count: itemCount}),
				...dialogContentProps,
			}}
			{...props}
			modalProps={{
				isBlocking: true,
				styles: {main: {maxWidth: 450}},
			}}
		>
			<Stack horizontal horizontalAlign='end' tokens={{childrenGap: 5}}>
				<Stack.Item>
					<PrimaryButton
						onClick={props.onConfirm}
						ariaLabel={'UpdateDialogButton'}
					>
						{confirmButtonLabel ?? t('Update')}
					</PrimaryButton>
				</Stack.Item>
				<Stack.Item>
					<DefaultButton
						onClick={props.onDismiss as any}
						ariaLabel={'CancelDialogButton'}
					>
						{t('Cancel')}
					</DefaultButton>
				</Stack.Item>
			</Stack>
		</Dialog>
	);
};
