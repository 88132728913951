/**
 * Implementation Notes:
 *
 * We don't need to use a type with this variable because TypeScript will still
 * validate it when we try to use these fields as the default values.
 *
 * Note that we use this in ParagraphSanitizationService, so we should be
 * careful when changing it.
 */

export const initialRegulatoryDocumentParagraph = {
	keywords: [],
	driveVariants: [],
	vehicleCategories: [],
	tags: [],
	categories: [],
	modelYear: null,
	comprehensive: null,
	dateNewRegistration: null,
	dateNewTypes: null,
	summary: null,
	phaseIn: [],
	phaseOut: [],
};

export type EditParagraphsFormDefaultValues =
	typeof initialRegulatoryDocumentParagraph;
