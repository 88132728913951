import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllBodyworkVariantsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllBodyworkVariantsQuery = { __typename?: 'Query', bodyworkVariants?: Array<{ __typename?: 'BodyworkVariant', id: string, name: string, createdAt?: any | null, modifiedAt?: any | null, createdBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null }, modifiedBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null } }> | null };

export type CreateBodyworkVariantMutationVariables = Types.Exact<{
  input: Types.CreateBodyworkVariantInput;
}>;


export type CreateBodyworkVariantMutation = { __typename?: 'Mutation', createBodyworkVariant: { __typename?: 'CreateBodyworkVariantPayload', bodyworkVariant?: { __typename?: 'BodyworkVariant', id: string } | null } };

export type UpdateBodyworkVariantMutationVariables = Types.Exact<{
  input: Types.UpdateBodyworkVariantInput;
}>;


export type UpdateBodyworkVariantMutation = { __typename?: 'Mutation', updateBodyworkVariant: { __typename?: 'UpdateBodyworkVariantPayload', bodyworkVariant?: { __typename?: 'BodyworkVariant', id: string } | null } };

export type DeleteBodyworkVariantMutationVariables = Types.Exact<{
  input: Types.DeleteBodyworkVariantInput;
}>;


export type DeleteBodyworkVariantMutation = { __typename?: 'Mutation', deleteBodyworkVariant: { __typename?: 'DeleteBodyworkVariantPayload', bodyworkVariant?: { __typename?: 'BodyworkVariant', id: string } | null } };


export const GetAllBodyworkVariantsDocument = gql`
    query GetAllBodyworkVariants {
  bodyworkVariants {
    id
    name
    createdAt
    createdBy {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
    modifiedAt
    modifiedBy {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
  }
}
    `;

/**
 * __useGetAllBodyworkVariantsQuery__
 *
 * To run a query within a React component, call `useGetAllBodyworkVariantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllBodyworkVariantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllBodyworkVariantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllBodyworkVariantsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllBodyworkVariantsQuery, GetAllBodyworkVariantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllBodyworkVariantsQuery, GetAllBodyworkVariantsQueryVariables>(GetAllBodyworkVariantsDocument, options);
      }
export function useGetAllBodyworkVariantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllBodyworkVariantsQuery, GetAllBodyworkVariantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllBodyworkVariantsQuery, GetAllBodyworkVariantsQueryVariables>(GetAllBodyworkVariantsDocument, options);
        }
export type GetAllBodyworkVariantsQueryHookResult = ReturnType<typeof useGetAllBodyworkVariantsQuery>;
export type GetAllBodyworkVariantsLazyQueryHookResult = ReturnType<typeof useGetAllBodyworkVariantsLazyQuery>;
export type GetAllBodyworkVariantsQueryResult = Apollo.QueryResult<GetAllBodyworkVariantsQuery, GetAllBodyworkVariantsQueryVariables>;
export const CreateBodyworkVariantDocument = gql`
    mutation CreateBodyworkVariant($input: CreateBodyworkVariantInput!) {
  createBodyworkVariant(input: $input) {
    bodyworkVariant {
      id
    }
  }
}
    `;
export type CreateBodyworkVariantMutationFn = Apollo.MutationFunction<CreateBodyworkVariantMutation, CreateBodyworkVariantMutationVariables>;

/**
 * __useCreateBodyworkVariantMutation__
 *
 * To run a mutation, you first call `useCreateBodyworkVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBodyworkVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBodyworkVariantMutation, { data, loading, error }] = useCreateBodyworkVariantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBodyworkVariantMutation(baseOptions?: Apollo.MutationHookOptions<CreateBodyworkVariantMutation, CreateBodyworkVariantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBodyworkVariantMutation, CreateBodyworkVariantMutationVariables>(CreateBodyworkVariantDocument, options);
      }
export type CreateBodyworkVariantMutationHookResult = ReturnType<typeof useCreateBodyworkVariantMutation>;
export type CreateBodyworkVariantMutationResult = Apollo.MutationResult<CreateBodyworkVariantMutation>;
export type CreateBodyworkVariantMutationOptions = Apollo.BaseMutationOptions<CreateBodyworkVariantMutation, CreateBodyworkVariantMutationVariables>;
export const UpdateBodyworkVariantDocument = gql`
    mutation UpdateBodyworkVariant($input: UpdateBodyworkVariantInput!) {
  updateBodyworkVariant(input: $input) {
    bodyworkVariant {
      id
    }
  }
}
    `;
export type UpdateBodyworkVariantMutationFn = Apollo.MutationFunction<UpdateBodyworkVariantMutation, UpdateBodyworkVariantMutationVariables>;

/**
 * __useUpdateBodyworkVariantMutation__
 *
 * To run a mutation, you first call `useUpdateBodyworkVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBodyworkVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBodyworkVariantMutation, { data, loading, error }] = useUpdateBodyworkVariantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBodyworkVariantMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBodyworkVariantMutation, UpdateBodyworkVariantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBodyworkVariantMutation, UpdateBodyworkVariantMutationVariables>(UpdateBodyworkVariantDocument, options);
      }
export type UpdateBodyworkVariantMutationHookResult = ReturnType<typeof useUpdateBodyworkVariantMutation>;
export type UpdateBodyworkVariantMutationResult = Apollo.MutationResult<UpdateBodyworkVariantMutation>;
export type UpdateBodyworkVariantMutationOptions = Apollo.BaseMutationOptions<UpdateBodyworkVariantMutation, UpdateBodyworkVariantMutationVariables>;
export const DeleteBodyworkVariantDocument = gql`
    mutation DeleteBodyworkVariant($input: DeleteBodyworkVariantInput!) {
  deleteBodyworkVariant(input: $input) {
    bodyworkVariant {
      id
    }
  }
}
    `;
export type DeleteBodyworkVariantMutationFn = Apollo.MutationFunction<DeleteBodyworkVariantMutation, DeleteBodyworkVariantMutationVariables>;

/**
 * __useDeleteBodyworkVariantMutation__
 *
 * To run a mutation, you first call `useDeleteBodyworkVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBodyworkVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBodyworkVariantMutation, { data, loading, error }] = useDeleteBodyworkVariantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteBodyworkVariantMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBodyworkVariantMutation, DeleteBodyworkVariantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBodyworkVariantMutation, DeleteBodyworkVariantMutationVariables>(DeleteBodyworkVariantDocument, options);
      }
export type DeleteBodyworkVariantMutationHookResult = ReturnType<typeof useDeleteBodyworkVariantMutation>;
export type DeleteBodyworkVariantMutationResult = Apollo.MutationResult<DeleteBodyworkVariantMutation>;
export type DeleteBodyworkVariantMutationOptions = Apollo.BaseMutationOptions<DeleteBodyworkVariantMutation, DeleteBodyworkVariantMutationVariables>;