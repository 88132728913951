import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateRegulatoryDocumentWorkflowStatusMutationVariables = Types.Exact<{
  regulatoryDocumentId: Types.Scalars['ID'];
  workflowStatus: Types.WorkflowStatus;
}>;


export type UpdateRegulatoryDocumentWorkflowStatusMutation = { __typename?: 'Mutation', updateRegulatoryDocumentWorkflowStatus: { __typename?: 'RegulatoryDocument', id: string } };


export const UpdateRegulatoryDocumentWorkflowStatusDocument = gql`
    mutation UpdateRegulatoryDocumentWorkflowStatus($regulatoryDocumentId: ID!, $workflowStatus: WorkflowStatus!) {
  updateRegulatoryDocumentWorkflowStatus(
    regulatoryDocumentId: $regulatoryDocumentId
    workflowStatus: $workflowStatus
  ) {
    id
  }
}
    `;
export type UpdateRegulatoryDocumentWorkflowStatusMutationFn = Apollo.MutationFunction<UpdateRegulatoryDocumentWorkflowStatusMutation, UpdateRegulatoryDocumentWorkflowStatusMutationVariables>;

/**
 * __useUpdateRegulatoryDocumentWorkflowStatusMutation__
 *
 * To run a mutation, you first call `useUpdateRegulatoryDocumentWorkflowStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRegulatoryDocumentWorkflowStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRegulatoryDocumentWorkflowStatusMutation, { data, loading, error }] = useUpdateRegulatoryDocumentWorkflowStatusMutation({
 *   variables: {
 *      regulatoryDocumentId: // value for 'regulatoryDocumentId'
 *      workflowStatus: // value for 'workflowStatus'
 *   },
 * });
 */
export function useUpdateRegulatoryDocumentWorkflowStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRegulatoryDocumentWorkflowStatusMutation, UpdateRegulatoryDocumentWorkflowStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRegulatoryDocumentWorkflowStatusMutation, UpdateRegulatoryDocumentWorkflowStatusMutationVariables>(UpdateRegulatoryDocumentWorkflowStatusDocument, options);
      }
export type UpdateRegulatoryDocumentWorkflowStatusMutationHookResult = ReturnType<typeof useUpdateRegulatoryDocumentWorkflowStatusMutation>;
export type UpdateRegulatoryDocumentWorkflowStatusMutationResult = Apollo.MutationResult<UpdateRegulatoryDocumentWorkflowStatusMutation>;
export type UpdateRegulatoryDocumentWorkflowStatusMutationOptions = Apollo.BaseMutationOptions<UpdateRegulatoryDocumentWorkflowStatusMutation, UpdateRegulatoryDocumentWorkflowStatusMutationVariables>;