import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCountriesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCountriesQuery = { __typename?: 'Query', countries?: Array<{ __typename?: 'Country', id: string, name: string, createdAt?: any | null, modifiedAt?: any | null, createdBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null }, modifiedBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null } }> | null };

export type CreateCountryMutationVariables = Types.Exact<{
  input: Types.CreateCountryInput;
}>;


export type CreateCountryMutation = { __typename?: 'Mutation', createCountry: { __typename?: 'CreateCountryPayload', country?: { __typename?: 'Country', id: string } | null } };

export type UpdateCountryMutationVariables = Types.Exact<{
  input: Types.UpdateCountryInput;
}>;


export type UpdateCountryMutation = { __typename?: 'Mutation', updateCountry: { __typename?: 'UpdateCountryPayload', country?: { __typename?: 'Country', id: string } | null } };

export type DeleteCountryMutationVariables = Types.Exact<{
  input: Types.DeleteCountryInput;
}>;


export type DeleteCountryMutation = { __typename?: 'Mutation', deleteCountry: { __typename?: 'DeleteCountryPayload', country?: { __typename?: 'Country', id: string } | null } };


export const GetCountriesDocument = gql`
    query GetCountries {
  countries {
    id
    name
    createdAt
    createdBy {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
    modifiedAt
    modifiedBy {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
  }
}
    `;

/**
 * __useGetCountriesQuery__
 *
 * To run a query within a React component, call `useGetCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountriesQuery(baseOptions?: Apollo.QueryHookOptions<GetCountriesQuery, GetCountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCountriesQuery, GetCountriesQueryVariables>(GetCountriesDocument, options);
      }
export function useGetCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCountriesQuery, GetCountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCountriesQuery, GetCountriesQueryVariables>(GetCountriesDocument, options);
        }
export type GetCountriesQueryHookResult = ReturnType<typeof useGetCountriesQuery>;
export type GetCountriesLazyQueryHookResult = ReturnType<typeof useGetCountriesLazyQuery>;
export type GetCountriesQueryResult = Apollo.QueryResult<GetCountriesQuery, GetCountriesQueryVariables>;
export const CreateCountryDocument = gql`
    mutation CreateCountry($input: CreateCountryInput!) {
  createCountry(input: $input) {
    country {
      id
    }
  }
}
    `;
export type CreateCountryMutationFn = Apollo.MutationFunction<CreateCountryMutation, CreateCountryMutationVariables>;

/**
 * __useCreateCountryMutation__
 *
 * To run a mutation, you first call `useCreateCountryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCountryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCountryMutation, { data, loading, error }] = useCreateCountryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCountryMutation(baseOptions?: Apollo.MutationHookOptions<CreateCountryMutation, CreateCountryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCountryMutation, CreateCountryMutationVariables>(CreateCountryDocument, options);
      }
export type CreateCountryMutationHookResult = ReturnType<typeof useCreateCountryMutation>;
export type CreateCountryMutationResult = Apollo.MutationResult<CreateCountryMutation>;
export type CreateCountryMutationOptions = Apollo.BaseMutationOptions<CreateCountryMutation, CreateCountryMutationVariables>;
export const UpdateCountryDocument = gql`
    mutation UpdateCountry($input: UpdateCountryInput!) {
  updateCountry(input: $input) {
    country {
      id
    }
  }
}
    `;
export type UpdateCountryMutationFn = Apollo.MutationFunction<UpdateCountryMutation, UpdateCountryMutationVariables>;

/**
 * __useUpdateCountryMutation__
 *
 * To run a mutation, you first call `useUpdateCountryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCountryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCountryMutation, { data, loading, error }] = useUpdateCountryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCountryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCountryMutation, UpdateCountryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCountryMutation, UpdateCountryMutationVariables>(UpdateCountryDocument, options);
      }
export type UpdateCountryMutationHookResult = ReturnType<typeof useUpdateCountryMutation>;
export type UpdateCountryMutationResult = Apollo.MutationResult<UpdateCountryMutation>;
export type UpdateCountryMutationOptions = Apollo.BaseMutationOptions<UpdateCountryMutation, UpdateCountryMutationVariables>;
export const DeleteCountryDocument = gql`
    mutation DeleteCountry($input: DeleteCountryInput!) {
  deleteCountry(input: $input) {
    country {
      id
    }
  }
}
    `;
export type DeleteCountryMutationFn = Apollo.MutationFunction<DeleteCountryMutation, DeleteCountryMutationVariables>;

/**
 * __useDeleteCountryMutation__
 *
 * To run a mutation, you first call `useDeleteCountryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCountryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCountryMutation, { data, loading, error }] = useDeleteCountryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCountryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCountryMutation, DeleteCountryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCountryMutation, DeleteCountryMutationVariables>(DeleteCountryDocument, options);
      }
export type DeleteCountryMutationHookResult = ReturnType<typeof useDeleteCountryMutation>;
export type DeleteCountryMutationResult = Apollo.MutationResult<DeleteCountryMutation>;
export type DeleteCountryMutationOptions = Apollo.BaseMutationOptions<DeleteCountryMutation, DeleteCountryMutationVariables>;