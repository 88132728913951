import React from 'react';
import {v4 as uuidv4} from 'uuid';
import {ActionTag, ActionTagProps} from './ActionTag';

export interface ActionTagsProps {
	tags?: ActionTagProps[];
	itemStyle?: ActionTagProps['extraRootStyles'];
}

export const ActionTags: React.FC<ActionTagsProps> = ({
	tags = [],
	itemStyle,
}) => {
	const renderTag = (actionTagProps: ActionTagProps): JSX.Element => (
		<ActionTag {...actionTagProps} extraRootStyles={itemStyle} key={uuidv4()} />
	);

	const renderedTags: JSX.Element[] = tags.map(renderTag);

	return <>{renderedTags}</>;
};
