import React from 'react';
import {ControlledTextField} from './hookForms';
import {TFunction} from 'react-i18next';
import {ControlledTextFieldProps} from './hookForms/ControlledTextField/ControlledTextField';

interface Props extends Omit<ControlledTextFieldProps, 'name'> {
	t: TFunction;
	labelTranslationKey: string;
	translationKeyOfRequiredMsg: string;
}

/**
 * ! Important
 *
 * This requires a tooltip translation provider.
 */
export function EntityNameField({
	t,
	labelTranslationKey,
	translationKeyOfRequiredMsg,
	...other
}: Props) {
	return (
		<ControlledTextField
			required
			label={t(labelTranslationKey)}
			name={'name'}
			rules={{required: t(translationKeyOfRequiredMsg)}}
			tooltipHostProps={{shouldGetTextFromContext: true}}
			{...other}
		/>
	);
}
