import * as Types from '../types/types.generated';

import { gql } from '@apollo/client';
export type FieldsToEditRequirementFragment = { __typename?: 'Requirement', id: string, status: Types.RequirementStatus, definition?: string | null, systemLevels: Array<{ __typename?: 'SystemLevel', id: string, name: string, createdAt?: any | null, modifiedAt?: any | null, createdBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null }, modifiedBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null } }>, vexClusters: Array<{ __typename?: 'VexCluster', id: string, name: string }>, gearboxVariants: Array<{ __typename?: 'GearboxVariant', id: string, name: string }>, bodyworkVariants: Array<{ __typename?: 'BodyworkVariant', id: string, name: string }>, activeTypeMarkets: Array<{ __typename?: 'ActiveTypeMarket', id: string, name: string }>, engineVariants: Array<{ __typename?: 'EngineVariant', id: string, name: string }>, tags: Array<{ __typename?: 'Tag', id: string, name: string }>, driveVariants: Array<{ __typename?: 'DriveVariant', id: string, name: string }>, vehicleCategories: Array<{ __typename?: 'VehicleCategory', id: string, name: string }>, documentReferences?: Array<{ __typename?: 'DocumentReference', referenceDocumentType?: string | null, reference?: { __typename?: 'Reference', id: string } | null }> | null };

export const FieldsToEditRequirementFragmentDoc = gql`
    fragment FieldsToEditRequirement on Requirement {
  id
  status
  definition
  systemLevels {
    id
    name
    createdAt
    createdBy {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
    modifiedAt
    modifiedBy {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
  }
  vexClusters {
    id
    name
  }
  gearboxVariants {
    id
    name
  }
  bodyworkVariants {
    id
    name
  }
  activeTypeMarkets {
    id
    name
  }
  engineVariants {
    id
    name
  }
  tags {
    id
    name
  }
  driveVariants {
    id
    name
  }
  vehicleCategories {
    id
    name
  }
  documentReferences {
    reference {
      id
    }
    referenceDocumentType
  }
}
    `;