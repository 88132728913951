import {
	DefaultButton,
	PrimaryButton,
	Separator,
	Stack,
	mergeStyleSets,
} from '@fluentui/react';
import {ControlledCheckbox, ControlledTextField} from 'components/hookForms';
import {useEditorCommands} from 'features/RegulatoryDocuments/hooks';
import {useUploadRegulatoryDocumentAssetMutation} from 'features/RegulatoryDocuments/hooks/useUploadDocumentAsset.generated';
import React from 'react';
import {useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import {useSlate} from 'slate-react';
import {ParagraphElementType} from 'types';
import {v4 as uuidv4} from 'uuid';
import {DocumentTextLine} from '../slate';
import {Stage, TableImageSplitterContext} from './TableImageSplitter';
import {dataURLtoFile, drawImageOnCanvas} from './Utils';
import {useTranslation} from 'react-i18next';
import {useEditorContext} from '../EditorContext';

type ImagePart = {
	y: number;
	height: number;
	index: number;
};

interface SplitImageForm {
	splitImages: Array<{
		data: string;
		enumeration: string;
		isTableHeader: boolean;
	}>;
	keepSourceTable: boolean;
}

export interface SplittingImageComponentProps {
	imgSrc: string;
}

export const SplittingImageComponent: React.FC<
	SplittingImageComponentProps
> = ({imgSrc}) => {
	const {t} = useTranslation('features/regulatorydocuments', {
		keyPrefix: 'DocumentEditor.TableImageSplitter',
	});
	const {
		splitLocations,
		setSplitLocations,
		imgHeight,
		setStage,
		onDismiss,
		data,
		leaf,
		tableNodePath,
	} = React.useContext(TableImageSplitterContext);
	const classNames = getClassNames();
	const editor = useSlate();
	const {addParagraph} = useEditorCommands(editor);
	const [uploadRegulatoryDocumentAsset] =
		useUploadRegulatoryDocumentAssetMutation();
	const {regulatoryDocumentId} = useParams();
	const {deleteParagraphElement} = useEditorContext();

	const {
		handleSubmit,
		control,
		setValue,
		getValues,
		watch,
		resetField,
		formState: {isValid, isSubmitting},
	} = useForm<SplitImageForm>({
		reValidateMode: 'onChange',
		mode: 'all',
	});

	React.useEffect(
		() =>
			setValue(
				'splitImages',
				data.map(imgData => ({
					enumeration: '',
					isTableHeader: false,
					data: imgData,
				})),
			),
		[data],
	);

	const imageParts: ImagePart[] = React.useMemo(() => {
		splitLocations.sort((a, b) => a - b);
		splitLocations.push(1);

		const mapToImagePart = (splitLocation: number, i: number) => {
			const prevSplitLocation = i > 0 ? splitLocations[i - 1] : 0;

			return {
				y: prevSplitLocation * imgHeight,
				height: (splitLocation - prevSplitLocation) * imgHeight,
				index: i,
			};
		};

		return splitLocations.map(mapToImagePart);
	}, [splitLocations]);

	const onBackButtonClick = React.useCallback(() => {
		setSplitLocations([]);
		setStage(Stage.DefineSplitLocations);
	}, [setStage]);

	const onCancelButtonClick = React.useCallback(() => {
		setSplitLocations([]);
		setStage(Stage.DefineSplitLocations);
		onDismiss();
	}, [setStage]);

	const onSubmitClick = React.useCallback(async () => {
		if (!regulatoryDocumentId) {
			return;
		}

		handleSubmit(async data => {
			const {splitImages} = data;

			const mutationResults = await Promise.all(
				splitImages.map(async (img, i) => {
					const imgPartAssetId = `${leaf.assetId}-${i}`;
					const file = await dataURLtoFile(img.data, imgPartAssetId);
					const res = await uploadRegulatoryDocumentAsset({
						variables: {
							input: {
								file,
								assetId: file.name,
								regulatoryDocumentId,
								contentType: 'image/jpeg',
							},
						},
					});
					const blob =
						res.data?.uploadRegulatoryDocumentAsset.regulatoryDocumentAsset;

					if (!blob) {
						throw new Error('Failed to upload image');
					}

					return {
						...img,
						assetId: blob.fileName,
						uri: blob.uri,
					};
				}),
			);

			const headerImage = mutationResults.find(r => r.isTableHeader);
			const images = mutationResults.filter(r => !r.isTableHeader);

			if (!data.keepSourceTable) {
				deleteParagraphElement(editor, tableNodePath);
			}

			images.reverse();

			for (const img of images) {
				addParagraph(
					img.enumeration,
					false,
					{
						location: tableNodePath[0] + 1,
						paragraph: leaf,
					},
					[
						...(headerImage
							? [
									getTableEditorElement(leaf.page, {
										assetId: headerImage.assetId,
										uri: headerImage.uri,
									}),
							  ]
							: []),
						getTableEditorElement(leaf.page, {
							assetId: img.assetId,
							uri: img.uri,
						}),
					],
				);
			}

			setSplitLocations([]);
			setStage(Stage.DefineSplitLocations);
			onDismiss();
		})();
	}, [data, editor, regulatoryDocumentId, onDismiss]);

	const onCheckboxChange = React.useCallback(
		(checked: boolean, index: number) => {
			if (checked) {
				for (let i = 0; i < data.length; i++) {
					if (i !== index) {
						setValue(`splitImages.${i}.isTableHeader`, false);
					}
				}

				resetField(`splitImages.${index}.enumeration`);
			}
		},
		[data.length, setValue],
	);

	return (
		<div className={classNames.SplittingImageComponent}>
			<div className={classNames.imagePartsContainer}>
				{imageParts.map((imagePart, i) => {
					const isTableHeader = watch(`splitImages.${i}.isTableHeader`);
					return (
						<div key={imagePart.y}>
							<Stack
								horizontal
								horizontalAlign='space-between'
								tokens={{childrenGap: 16}}
							>
								<ControlledTextField
									placeholder={
										isTableHeader ? undefined : t('HeaderFieldPlaceholder')
									}
									styles={{root: {width: 400}}}
									name={`splitImages.${i}.enumeration`}
									control={control}
									required={!isTableHeader}
									disabled={isTableHeader}
									rules={{
										validate: {
											required: val =>
												!val && !getValues(`splitImages.${i}.isTableHeader`)
													? t('HeaderError')
													: true,
										},
									}}
								/>
								<ControlledCheckbox
									label={t('IsTableHeader')}
									control={control}
									name={`splitImages.${i}.isTableHeader`}
									onChange={(_, val) => onCheckboxChange(Boolean(val), i)}
								/>
							</Stack>
							<SplitImage imgSrc={imgSrc} imgPart={imagePart} />
						</div>
					);
				})}
			</div>
			<Separator />
			<div className={classNames.buttons}>
				<DefaultButton
					className={classNames.button}
					onClick={onBackButtonClick}
				>
					{t('Back')}
				</DefaultButton>
				<Stack horizontal tokens={{childrenGap: 8}}>
					<ControlledCheckbox
						label={t('KeepSourceTable')}
						control={control}
						name={`keepSourceTable`}
						styles={{root: {marginTop: 10}}}
					/>
					<PrimaryButton
						className={classNames.button}
						onClick={onSubmitClick}
						disabled={!isValid || isSubmitting}
					>
						{t('InsertIntoDocument')}
					</PrimaryButton>
					<DefaultButton
						className={classNames.button}
						onClick={onCancelButtonClick}
					>
						{t('Cancel')}
					</DefaultButton>
				</Stack>
			</div>
		</div>
	);
};

interface SplitImageProps {
	imgSrc: string;
	imgPart: ImagePart;
}

const SplitImage: React.FC<SplitImageProps> = ({imgSrc, imgPart}) => {
	const classNames = getClassNames();
	const {setData} = React.useContext(TableImageSplitterContext);
	const canvasRef = React.useRef<HTMLCanvasElement>(null);

	React.useEffect(() => {
		const canvas = canvasRef.current;
		if (canvas) {
			drawImageOnCanvas(
				imgSrc,
				800,
				Infinity,
				canvas,
				() => {
					setData(imgs => {
						const newImgData = imgs.slice();
						newImgData[imgPart.index] = canvas.toDataURL('image/jpeg');
						return newImgData;
					});
				},
				{
					sy: imgPart.y,
					sHeight: imgPart.height,
				},
			);
		}
	}, []);

	return <canvas ref={canvasRef} className={classNames.canvas} />;
};

const getTableEditorElement = (
	page: number,
	asset: {
		assetId: string;
		uri: string;
		csvContent?: string;
	},
) => {
	const {assetId, uri, csvContent} = asset;
	return {
		type: ParagraphElementType.Table,
		_guid: uuidv4(),
		isHeader: false,
		page,
		selected: false,
		text: ' ',
		bounds: [0, 0, 0, 0],
		assetId,
		asset: {
			uri,
		},
		csvContent,
	} as DocumentTextLine;
};

const getClassNames = () =>
	mergeStyleSets({
		SplittingImageComponent: {
			background: 'white',
			position: 'fixed',
			top: '50%',
			left: '50%',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			minWidth: 400,
		},
		imagePartsContainer: {
			background: 'white',
			padding: '20px',
			height: 'calc(100vh - 150px)',
			overflowY: 'auto',
		},
		canvas: {
			border: '1px dashed',
			margin: '10px 0',
		},
		buttons: {
			display: 'flex',
			justifyContent: 'space-between',
			padding: '0px 4px 4px 4px',
		},
		button: {
			margin: '4px',
		},
	});
