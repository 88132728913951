import _ from 'lodash';
import {
	ParagraphsFormDate,
	SanitizedFormFields,
} from '../EditParagraphsForm.types';
import {initialRegulatoryDocumentParagraph as defaultFormFields} from '../editParagraphsForm.constants';
import {
	ParagraphSanitizationService,
	CommonSanitizedParagraphFields,
} from './updateRegDocParagraphMutations/ParagraphSanitization.service';
import {
	OptimisticParagraph,
	OptimisticPhaseFieldArray,
	OptimisticPhaseFieldItem,
} from './updateRegDocParagraphMutations/optimisticParagraph.types';
import {NameOfMergeableArrayField} from './updateRegDocParagraphMutations/mergeableArrayFields';
import {PhaseFieldNames} from 'features/RegulatoryDocuments/RegDocDetailsPage/RegDocDetailsPage.queryTypes';

/*
 * * Simplified form fields
 */
type SimplifiedDate = Exclude<ParagraphsFormDate, Date | undefined>;

export interface SimplifiedFormFields
	extends Pick<OptimisticParagraph, keyof SanitizedFormFields> {
	comprehensive: SimplifiedDate;
	dateNewRegistration: SimplifiedDate;
	dateNewTypes: SimplifiedDate;
	keywords: NonNullable<SanitizedFormFields['keywords']>;
}

export type SimplifiedFieldValue =
	SimplifiedFormFields[keyof SimplifiedFormFields];

/**
 * * Other
 */

export type FormFieldsToSimplify = Partial<SanitizedFormFields>;

type SanitizedParagraphFromFormFields =
	CommonSanitizedParagraphFields<FormFieldsToSimplify>;

type FormFieldsOfSanitizedParagraphFields = Pick<
	SanitizedParagraphFromFormFields,
	keyof SimplifiedFormFields
>;

type RequiredFormFields = Required<FormFieldsOfSanitizedParagraphFields>;

/**
 * We create types for this instead of using the optimistic versions of these
 * because the fields from the paragraph sanitization service are different.
 * This is because the date's type could be a JS date.
 */
type SanitizedPhaseFieldArray = NonNullable<
	RequiredFormFields[PhaseFieldNames]
>;
type SanitizedPhaseFieldItem = SanitizedPhaseFieldArray[number];

type DateToSanitize = Exclude<ParagraphsFormDate, undefined>;

/**
 * Reasoning:
 *
 * We use this service to simplify the form fields to make it easier to create
 * the optimistic data. In the paragraphs' optimistic data service, we might
 * process a field in different ways, but we might need to set the field's
 * default value in the same way. Instead of setting the default value for each
 * way we process the field, we could just set it once before we send it to that
 * service. It also makes sense for us to add the sanitization logic here so
 * that everything is more organized.
 */
export class SimplifiedParagraphFormFieldsService {
	private paragraphSanitizationService = new ParagraphSanitizationService();

	/**
	 * We pick the fields to avoid including extras
	 */
	private pickFieldsOfSanitizedParagraphFields = (
		sanitizedParagraph: SanitizedParagraphFromFormFields,
	): FormFieldsOfSanitizedParagraphFields => {
		return _.pick(sanitizedParagraph, [
			'phaseIn',
			'phaseOut',
			'summary',
			'modelYear',
		]);
	};

	private sanitizeParagraphFieldsOfFormFields = (
		formFields: FormFieldsToSimplify,
	): FormFieldsOfSanitizedParagraphFields => {
		const sanitized: SanitizedParagraphFromFormFields =
			this.paragraphSanitizationService.sanitizeNonDateParagraphFields(
				formFields,
			);
		return this.pickFieldsOfSanitizedParagraphFields(sanitized);
	};

	private addMissingFields = (
		formFields: FormFieldsToSimplify,
	): RequiredFormFields => {
		const withSanitizedParagraphFields: FormFieldsOfSanitizedParagraphFields =
			this.sanitizeParagraphFieldsOfFormFields(formFields);
		/**
		 * The sanitized form fields must be last to avoid overwriting them with the
		 * default form fields and to ensure we overwrite the form fields with the
		 * sanitized ones.
		 */
		return {
			...defaultFormFields,
			...formFields,
			...withSanitizedParagraphFields,
		};
	};

	private formatDate = (date: Date): string => {
		const isoFormat: string = date.toISOString();
		const [dateWithoutTime]: string[] = isoFormat.split('T');
		return dateWithoutTime;
	};

	/**
	 * We must convert JS dates into strings to more accurately represent what the
	 * server responds with.
	 */
	private transformDateIfNecessary = (
		value: DateToSanitize,
	): SimplifiedDate => {
		// This is already in UTC
		if (value instanceof Date) return this.formatDate(value);
		return value ?? null;
	};

	private sanitizeMergeableArray = <
		Value extends SimplifiedFormFields[NameOfMergeableArrayField],
	>(
		value: Value,
	): Value => {
		return value ?? [];
	};

	private sanitizePhaseFieldItem = (
		item: SanitizedPhaseFieldItem,
	): OptimisticPhaseFieldItem => {
		return {...item, date: this.transformDateIfNecessary(item.date)};
	};

	private sanitizePhaseFieldItems = (
		items: SanitizedPhaseFieldArray,
	): OptimisticPhaseFieldArray => {
		return items.map(this.sanitizePhaseFieldItem);
	};

	private sanitizePhaseField = (
		phaseField: RequiredFormFields[PhaseFieldNames],
	): OptimisticParagraph[PhaseFieldNames] => {
		return phaseField ? this.sanitizePhaseFieldItems(phaseField) : phaseField;
	};

	/**
	 * We return these as an object instead of iterating over the form fields and
	 * sanitizing them. This is because we must ensure we maintain type safety. In
	 * other words, we must make sure the value's type corresponds to the key.
	 */
	private sanitizeAndTransformFields = ({
		comprehensive,
		dateNewRegistration,
		dateNewTypes,
		keywords,
		driveVariants,
		vehicleCategories,
		categories,
		tags,
		phaseIn,
		phaseOut,
		...other
	}: RequiredFormFields): SimplifiedFormFields => {
		return {
			comprehensive: this.transformDateIfNecessary(comprehensive),
			dateNewRegistration: this.transformDateIfNecessary(dateNewRegistration),
			dateNewTypes: this.transformDateIfNecessary(dateNewTypes),
			keywords: this.sanitizeMergeableArray(keywords),
			driveVariants: this.sanitizeMergeableArray(driveVariants),
			vehicleCategories: this.sanitizeMergeableArray(vehicleCategories),
			categories: this.sanitizeMergeableArray(categories),
			tags: this.sanitizeMergeableArray(tags),
			phaseIn: this.sanitizePhaseField(phaseIn),
			phaseOut: this.sanitizePhaseField(phaseOut),
			...other,
		};
	};

	/**
	 * We must iterate over each value and replace it instead of setting default
	 * values and then assigning the form fields to them. This is because some
	 * form fields won't be missing, but will have values of undefined. Since we
	 * don't want any undefined fields here, we must check if they are undefined
	 * and set a default value if so.
	 */
	public simplifyFormFields = (
		formFields: FormFieldsToSimplify,
	): SimplifiedFormFields => {
		const withMissingFields: RequiredFormFields =
			this.addMissingFields(formFields);
		return this.sanitizeAndTransformFields(withMissingFields);
	};
}
