import {mergeStyleSets, Theme} from '@fluentui/react';
import {
	CompareResult,
	IComparedItem,
} from '../compareDocuments/compareDocuments';
import {useTranslation} from 'react-i18next';
import {Requirement, RequirementStatus} from 'types';
import {GetAddRequirementsFormDataQuery} from 'features/RegulatoryDocuments/hooks/useGetAddRequirementsFormData.generated';
import _ from 'lodash';
import {mapToRef} from 'helpers';

export const getClassNames = (theme: Theme) =>
	mergeStyleSets({
		statusBox: {
			display: 'flex',
			alignItems: 'center',
			marginRight: 25,
		},
		itemHeader: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
		paragraphItem: {
			color: theme.palette.neutralSecondary,
			fontSize: 14,
		},
		enumeration: {
			whiteSpace: 'no-wrap',
			fontWeight: 'bold',
		},
		elemBox: {
			whiteSpace: 'normal',
			paddingRight: 10,
		},
		iconStyles: {
			marginRight: 10,
			borderRadius: '50%',
		},
		changeMark: {
			background: theme.palette.yellow,
		},
	});

export const getComparisonColor = (result: CompareResult, theme: Theme) => {
	let dotColor;

	switch (result) {
		case CompareResult.Deleted:
			dotColor = theme.palette.redDark;
			break;
		case CompareResult.New:
			dotColor = theme.palette.green;
			break;
		case CompareResult.Changed:
			dotColor = theme.palette.yellow;
			break;
		case CompareResult.Equal:
			dotColor = theme.palette.neutralLight;
			break;
		case CompareResult.InReview:
			dotColor = theme.palette.blue;
			break;
		default:
			dotColor = theme.palette.neutralLight;
	}

	return dotColor;
};

export const useTranslationsOfCompareVersionPanel = () => {
	return useTranslation('features/regulatorydocuments', {
		keyPrefix: 'CompareVersionPanel',
	});
};

export const createCloneRequirements = async (
	data: GetAddRequirementsFormDataQuery | undefined,
	selectedItems: IComparedItem[],
	createCloneForMerging: any,
	getInitialVersion: any,
): Promise<Map<string, string>> => {
	// We should create cloned requirement with increased minor version for all unchangedd segments
	const idMapper = new Map<string, string>();
	const requirementsIdsInFinalState: string[] = [];
	selectedItems.forEach(item => {
		const {oldParagraph, resultType, newParagraph} = item;

		if (resultType === CompareResult.Equal) {
			oldParagraph?.paragraph.requirements?.forEach((e: any) => {
				//find unique requirements in final state, don't include requiremenits which are existing in child paragraph
				if (
					e.status === RequirementStatus.Final &&
					newParagraph?.paragraph.requirements?.findIndex(
						(elem: any) => elem.id === e.id,
					) === -1
				) {
					requirementsIdsInFinalState.push(e.id);
				}
			});
		}
	});
	/* eslint-disable no-await-in-loop */
	for (const [index, id] of _.uniq(requirementsIdsInFinalState).entries()) {
		const req = data?.requirements?.find(e => e.id === id);
		if (req) {
			const versions = data?.requirements
				.filter(
					e =>
						e.status === RequirementStatus.Final &&
						e.requirementId === req.requirementId,
				)
				.map(e => e.version || getInitialVersion());

			const isLastElement = index === requirementsIdsInFinalState.length - 1;

			const returnedValue = await createCloneForMerging(
				req as Requirement,
				undefined,
				versions,
				isLastElement,
			);

			idMapper.set(id, returnedValue);
		}
	}
	return idMapper;
};

export const prepareRequirementsIdsForUpdate = (
	item: IComparedItem,
	map: Map<string, string>,
) => {
	const {oldParagraph, resultType} = item;

	// we should not move directly final requirements for not changed segments, we should used created clones
	if (resultType === CompareResult.Equal) {
		const idsForUpdate: {id: string}[] = [];
		oldParagraph?.paragraph.requirements?.forEach((e: any) => {
			if (e.status !== RequirementStatus.Final) {
				idsForUpdate.push(e);
			}
			const id = map.get(e.id);

			if (id) {
				idsForUpdate.push({id});
			}
		});
		return mapToRef(idsForUpdate);
	}

	return mapToRef(oldParagraph?.paragraph.requirements);
};
