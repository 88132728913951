import React, {useCallback, useMemo} from 'react';
import {
	DirectionalHint,
	ITooltipProps,
	ITooltipStyles,
	TooltipHost,
} from '@fluentui/react';
import {KeywordFields} from './keywordItem.types';
import {KeywordTooltip} from './KeywordTooltip/KeywordTooltip';

interface Props {
	keyword: KeywordFields;
}

export function KeywordItem({keyword}: Props): JSX.Element {
	const renderTooltip = useCallback((): JSX.Element => {
		return <KeywordTooltip keyword={keyword} key={keyword.id} />;
	}, [keyword]);

	const tooltipStyles = useMemo((): Partial<ITooltipStyles> => {
		return {content: {overflowX: 'auto'}};
	}, []);

	const tooltipProps = useMemo((): ITooltipProps => {
		return {onRenderContent: renderTooltip, styles: tooltipStyles};
	}, [renderTooltip, tooltipStyles]);

	type PossibleError = Error | null;

	const createValidationErrorIfNecessary = (): PossibleError => {
		const {vexClusters, vkos} = keyword;
		if (vexClusters && vkos) return null;
		return new Error('Keyword is missing VEX clusters or VKOs.');
	};

	const validateKeyword = (): void => {
		const isDev: boolean = process.env.NODE_ENV === 'development';
		const error: PossibleError = createValidationErrorIfNecessary();
		if (isDev && error) throw error;
		if (error) console.error(error);
	};

	validateKeyword();

	return (
		<TooltipHost
			tooltipProps={tooltipProps}
			directionalHint={DirectionalHint.leftCenter}
		>
			{keyword.name}
		</TooltipHost>
	);
}
