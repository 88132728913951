import {useTranslation} from 'react-i18next';
import {Command, useCommand} from './useCommand';

export const useEditEntityCmd = (fields: Command, deps?: unknown[]): void => {
	const {t} = useTranslation('hooks/useEditEntityCmd');

	useCommand(
		{
			text: t('Edit'),
			iconProps: {iconName: 'Edit'},
			...fields,
		},
		deps,
	);
};
