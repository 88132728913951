import {NavigateFunction, useNavigate} from 'react-router-dom';

type Navigate = () => void;

export type CreateNavigator = (href: string) => Navigate;

export const useNavigatorCreator = () => {
	const navigate: NavigateFunction = useNavigate();

	const createNavigator: CreateNavigator = href => {
		return (): void => navigate(href);
	};

	return createNavigator;
};
