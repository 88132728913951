import {
	useTheme,
	Theme,
	mergeStyleSets,
	FontIcon,
	Label,
	Stack,
	IconButton,
	Shimmer,
} from '@fluentui/react';

import * as React from 'react';
import {Controller} from 'react-hook-form';
import {HookFormProps} from './HookFormProps';
import {useDropzone, DropzoneOptions} from 'react-dropzone';
import {useTranslation} from 'react-i18next';
import {ErrorMessage} from './ErrorMessage';
import {BlobRef} from 'types';
import {useGetAttachmentCategoriesQuery} from './hooks/getAttachmentCategories.generated';
import {EntityDropdown} from './ControlledDropdown';
import {useUserContext} from 'authentication/UserContext';
import {
	CheckboxWithTooltip,
	AttachmentsTooltipTranslationProvider,
} from 'features/localizedTooltips';

export type ControlledFileUploadProps = HookFormProps &
	DropzoneOptions & {pdfOnly?: boolean};

/**
 * Note that the file's data might be empty for existing attachments.
 */
export interface DefaultFile {
	name: string;
	blob: BlobRef;
	attachmentCategoryId?: string;
	isVkoOnly?: boolean;
}

export type FileWithMetadata = {
	file: File;
	attachmentCategoryId?: string;
	isVkoOnly?: boolean;
};

export type FileUploadProps = DropzoneOptions & {
	onChange: (files: FileWithMetadata[]) => void;
	defaultFiles?: DefaultFile[];
	pdfOnly?: boolean;
	renderCategory?: boolean;
	enableVkoOnlyOption?: boolean;
};

/**
 * @see DefaultFile for important info
 */
export const FileUpload: React.FC<FileUploadProps> = ({
	onChange,
	defaultFiles,
	pdfOnly = false,
	renderCategory = true,
	enableVkoOnlyOption = false,
	...dropzoneOptions
}) => {
	const {data, loading} = useGetAttachmentCategoriesQuery();
	const attachmentCategories = React.useMemo(
		() => data?.attachmentCategories ?? [],
		[data],
	);

	const {t} = useTranslation('components/controlledfileupload');
	const theme = useTheme();
	const classnames = getClassnames(theme);
	const {isVko} = useUserContext();

	const defaultFileMap = (f: DefaultFile): FileWithMetadata => ({
		file: new File([], f.name),
		attachmentCategoryId: f.attachmentCategoryId || attachmentCategories[0]?.id,
		isVkoOnly: Boolean(f.isVkoOnly),
	});
	const [files, setFiles] = React.useState<FileWithMetadata[]>(
		(defaultFiles || []).map(defaultFileMap),
	);
	React.useEffect(
		() => setFiles((defaultFiles || []).map(defaultFileMap)),
		[defaultFiles, attachmentCategories],
	);

	const onDropAccepted = React.useCallback(
		(acceptedFiles: File[]) => {
			const newFiles = files.concat(
				acceptedFiles.map((f: File) => ({
					file: f,
					attachmentCategoryId: attachmentCategories[0]?.id,
					isVkoOnly: false,
				})),
			);
			setFiles(newFiles);
			onChange(newFiles);
		},
		[files],
	);

	const onFileDeleted = React.useCallback(
		(deletedFile: FileWithMetadata) => {
			const newFiles = files.filter(f => f.file.name !== deletedFile.file.name);
			setFiles(newFiles);
			onChange(newFiles);
		},
		[files],
	);

	const onCategoryChanged = React.useCallback(
		(i: number, categoryId: string) => {
			const newFiles = files.slice();
			newFiles[i].attachmentCategoryId = categoryId;
			setFiles(newFiles);
			onChange(newFiles);
		},
		[files],
	);

	const onVkoOnlyChange = React.useCallback(
		(i: number, checked: boolean | undefined) => {
			const newFiles = files.slice();
			newFiles[i].isVkoOnly = Boolean(checked);
			setFiles(newFiles);
			onChange(newFiles);
		},
		[files],
	);

	const {
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject,
	} = useDropzone({
		...dropzoneOptions,
		accept: pdfOnly
			? {'application/pdf': ['.pdf'], 'application/octet-stream': ['.reqifz']}
			: dropzoneOptions.accept,
		disabled: files.length >= (dropzoneOptions.maxFiles || Infinity),
		noDragEventsBubbling: dropzoneOptions.noDragEventsBubbling ?? true,
		onDropAccepted,
	});

	/**
	 * We must provide the attachments' translation provider here when rendering the
	 * checkbox for "isVkoOnly" because it is an attachment property. The option
	 * does not appear in any other unrelated types, so we can safely assume that
	 * we need the attachments' translations.
	 */
	const acceptedFileItems = files.map((f, i) => (
		<Stack
			horizontal
			key={`${f.file.name}.${i}`}
			horizontalAlign={'space-between'}
		>
			<Stack.Item styles={{root: {width: renderCategory ? '55%' : '100%'}}}>
				<Stack horizontal>
					<IconButton
						iconProps={{iconName: 'Delete'}}
						aria-label='Delete'
						onClick={() => onFileDeleted(f)}
					/>
					<Label>{f.file.name}</Label>
				</Stack>
			</Stack.Item>
			<Stack.Item styles={{root: {width: '40%'}}}>
				<Stack tokens={{childrenGap: 4}}>
					{renderCategory && (
						<>
							{loading ? (
								<Shimmer />
							) : (
								<EntityDropdown
									onChange={(_, o) =>
										o && onCategoryChanged(i, o.key as string)
									}
									defaultSelectedKey={f.attachmentCategoryId}
									styles={{root: {width: '100%'}}}
									selectableOptions={attachmentCategories}
									getKey={a => a.id}
									getText={a => a.name}
								/>
							)}
						</>
					)}
					{enableVkoOnlyOption && isVko && (
						<AttachmentsTooltipTranslationProvider>
							<CheckboxWithTooltip
								label={t('VkoInternal')}
								defaultChecked={f.isVkoOnly}
								onChange={(_, checked) => onVkoOnlyChange(i, checked)}
								tooltipHostProps={{
									shouldGetTextFromContext: true,
									translationKey: 'isVkoOnly',
								}}
							/>
						</AttachmentsTooltipTranslationProvider>
					)}
				</Stack>
			</Stack.Item>
		</Stack>
	));

	return (
		<>
			<div className={classnames.DropzoneBox} {...getRootProps()}>
				<div
					className={
						isDragAccept && isDragActive
							? classnames.DropzoneBoxOverlayAccept
							: isDragReject && isDragActive
							? classnames.DropzoneBoxOverlayReject
							: classnames.DropzoneBoxOverlayBase
					}
				>
					<input {...getInputProps()} />
					<>
						<FontIcon
							aria-label='PageAdd'
							iconName='PageAdd'
							className={classnames.iconClass}
						/>
						<Label className={classnames.selectText}>
							{pdfOnly
								? t('SelectPDFUploadLabel')
								: t('SelectFilesUploadLabel')}
						</Label>
						<div className={classnames.dragText}>
							{isDragActive && isDragAccept
								? t('DropTheFilesHereParagraph')
								: isDragActive && isDragReject
								? t('WrongFileType')
								: t('OrDragAndDropItHereDiv')}
						</div>
					</>
				</div>
			</div>
			<Stack tokens={{childrenGap: 8}} styles={{root: {marginTop: 4}}}>
				{acceptedFileItems}
			</Stack>
		</>
	);
};

export const ControlledFileUpload: React.FC<ControlledFileUploadProps> = ({
	name,
	control,
	rules,
	defaultValue,
	...fileUploadProps
}) => {
	return (
		<Controller
			name={name}
			control={control}
			rules={rules}
			defaultValue={defaultValue || []}
			render={({field: {onChange, value}, fieldState: {error}}) => (
				<>
					<FileUpload
						defaultFiles={value}
						onChange={onChange}
						{...fileUploadProps}
					/>
					<ErrorMessage error={error} />
				</>
			)}
		/>
	);
};

const getClassnames = (theme: Theme) =>
	mergeStyleSets({
		DropzoneBox: {
			width: '100%',
		},
		DropzoneBoxOverlayBase: {
			height: '100%',
			padding: 20,
			background: '#fafafa',
			color: '#bdbdbd',
			border: `2px dashed`,
			borderRadius: '2px',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			cursor: 'pointer',
		},
		DropzoneBoxOverlayAccept: {
			height: '100%',
			padding: 20,
			background: '#fafafa',
			color: theme.palette.greenLight,
			border: `2px dashed`,
			borderRadius: '2px',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			cursor: 'pointer',
		},
		DropzoneBoxOverlayReject: {
			height: '100%',
			padding: 20,
			background: '#fafafa',
			color: theme.palette.red,
			border: `2px dashed`,
			borderRadius: '2px',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			cursor: 'pointer',
		},
		iconClass: {
			fontSize: 50,
			height: 50,
			width: 50,
			margin: '0 25px',
			color: theme.palette.themePrimary,
		},
		selectText: {
			color: theme.palette.themePrimary,
		},
		dragText: {
			color: theme.palette.neutralSecondary,
		},
		container: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			margin: '10px 0',
		},
		fileIconClass: {
			fontSize: 20,
			padding: 5,
		},
	});
