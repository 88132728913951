import React from 'react';
import {useParams} from 'react-router-dom';
import {BaselineComparisonPageParams} from './BaselineComparisonPage.types';
import {LoadWrapper} from 'components/LoadWrapper';
import {BaselineDetailsSection} from '../baselines/BaselineDetailsSection';
import {PageBreadcrumbs} from './PageBreadcrumbs';

import {RequirementsTooltipTranslationProvider} from 'features/Requirements/RequirementsTooltipTranslationProvider';

import {QueryResult} from '@apollo/client';
import _ from 'lodash';
import {
	GetCompareBaselineQuery,
	GetCompareBaselineQueryVariables,
	useGetCompareBaselineQuery,
} from '../hooks/baseline.generated';
import {RequirementsList} from './RequirementsList';

export function BaselineComparisonPage(): JSX.Element {
	const params = useParams() as unknown as BaselineComparisonPageParams;

	type PageQuery = QueryResult<
		GetCompareBaselineQuery,
		GetCompareBaselineQueryVariables
	>;

	const {data, loading}: PageQuery = useGetCompareBaselineQuery({
		variables: {
			baselineId1: params.id,
			baselineId2: params.idOfBaselineToCompareTo,
		},
	});

	const baselineId1 = data?.compareBaseline.id1;
	const baselineId2 = data?.compareBaseline.id2;
	const baselineName1 = data?.compareBaseline.name1 ?? '';

	if (baselineId2 === undefined) return <></>;
	if (baselineId1 === undefined) return <></>;

	if (loading) return <LoadWrapper loading />;
	const changes = data?.compareBaseline.changes ?? [];
	return (
		<>
			<PageBreadcrumbs baselineName={baselineName1} params={params} />
			<BaselineDetailsSection baselineName={baselineName1} />
			<RequirementsTooltipTranslationProvider>
				<RequirementsList baselineId={baselineId1} changes={changes} />
			</RequirementsTooltipTranslationProvider>
		</>
	);
}
