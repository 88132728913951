import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConcludeRegulatoryDocumentMutationVariables = Types.Exact<{
  input: Types.ConcludeRegulatoryDocumentInput;
}>;


export type ConcludeRegulatoryDocumentMutation = { __typename?: 'Mutation', concludeRegulatoryDocument: { __typename?: 'ConcludeRegulatoryDocumentPayload', regulatoryDocument?: { __typename?: 'RegulatoryDocument', id: string } | null } };


export const ConcludeRegulatoryDocumentDocument = gql`
    mutation ConcludeRegulatoryDocument($input: ConcludeRegulatoryDocumentInput!) {
  concludeRegulatoryDocument(input: $input) {
    regulatoryDocument {
      id
    }
  }
}
    `;
export type ConcludeRegulatoryDocumentMutationFn = Apollo.MutationFunction<ConcludeRegulatoryDocumentMutation, ConcludeRegulatoryDocumentMutationVariables>;

/**
 * __useConcludeRegulatoryDocumentMutation__
 *
 * To run a mutation, you first call `useConcludeRegulatoryDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConcludeRegulatoryDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [concludeRegulatoryDocumentMutation, { data, loading, error }] = useConcludeRegulatoryDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConcludeRegulatoryDocumentMutation(baseOptions?: Apollo.MutationHookOptions<ConcludeRegulatoryDocumentMutation, ConcludeRegulatoryDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConcludeRegulatoryDocumentMutation, ConcludeRegulatoryDocumentMutationVariables>(ConcludeRegulatoryDocumentDocument, options);
      }
export type ConcludeRegulatoryDocumentMutationHookResult = ReturnType<typeof useConcludeRegulatoryDocumentMutation>;
export type ConcludeRegulatoryDocumentMutationResult = Apollo.MutationResult<ConcludeRegulatoryDocumentMutation>;
export type ConcludeRegulatoryDocumentMutationOptions = Apollo.BaseMutationOptions<ConcludeRegulatoryDocumentMutation, ConcludeRegulatoryDocumentMutationVariables>;