import React, {useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {useVehicleProjectsOfActiveTypeMarketQuery} from '../hooks';
import {MarketPageParams} from '../vehicleProjectPagePaths';
import {LoadWrapper} from 'components/LoadWrapper';
import {BreadcrumbsOfGenerationSubpage} from '../baselines/BreadcrumbsOfGenerationSubpage';
import {MarketVehicleProjects} from './MarketVehicleProjects';
import {useTranslationForPageInVehicleProjects} from '../hooks/vehicleProjectsTranslationHooks';
import {Layout} from 'appShell/Layout';
import {IScrollablePaneProps} from '@fluentui/react';

export function ActiveTypeMarketDetailsPage() {
	const params = useParams() as MarketPageParams;

	const {loading, data} = useVehicleProjectsOfActiveTypeMarketQuery({
		variables: params,
	});

	const {t} = useTranslationForPageInVehicleProjects(
		'activeTypeMarketDetailsPage',
	);

	const getScrollablePaneProps = (): IScrollablePaneProps => {
		return {
			styles: {
				contentContainer: {
					// Firefox
					'scrollbar-width': 'none',
					'&::-webkit-scrollbar': {
						display: 'none',
					},
				},
			},
		};
	};

	const scrollablePaneProps: IScrollablePaneProps = useMemo(
		getScrollablePaneProps,
		[],
	);

	return (
		<Layout scrollablePaneProps={scrollablePaneProps}>
			<LoadWrapper loading={loading}>
				<BreadcrumbsOfGenerationSubpage
					params={params}
					textOfExtraBreadcrumb={
						data?.activeTypeMarket?.name ?? t('FallbackTextOfMarketBreadcrumb')
					}
					keyOfExtraBreadcrumb='markets'
				/>
				<MarketVehicleProjects queryData={data} />
			</LoadWrapper>
		</Layout>
	);
}
