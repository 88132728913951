import {EntityPage} from 'components';
import {ViewAuthorizer} from 'components/ViewAuthorizer';
import {LoadWrapper} from 'components/LoadWrapper';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ActiveTypeMarket, UserRole} from 'types';
import {
	useGetAllActiveTypeMarketsQuery,
	useCreateActiveTypeMarketMutation,
	useUpdateActiveTypeMarketMutation,
	useDeleteActiveTypeMarketMutation,
	GetAllActiveTypeMarketsDocument,
} from './hooks/activeTypeMarkets.generated';
import {ActiveTypeMarketsTooltipTranslationProvider} from 'features/localizedTooltips';

const AdminActiveTypeMarketsPage: React.FC = () => {
	const {t} = useTranslation('features/adminsection', {
		keyPrefix: 'AdminActiveTypeMarketsPage',
	});

	const {loading, data} = useGetAllActiveTypeMarketsQuery();
	const [createActiveTypeMarketMutation] = useCreateActiveTypeMarketMutation();
	const [updateActiveTypeMarketMutation] = useUpdateActiveTypeMarketMutation();
	const [deleteActiveTypeMarketMutation] = useDeleteActiveTypeMarketMutation();

	const activeTypeMarkets = React.useMemo(
		() => data?.activeTypeMarkets ?? [],
		[data],
	);

	const refetchQueries = [GetAllActiveTypeMarketsDocument];

	const createActiveTypeMarket = React.useCallback(
		(activeTypeMarket: ActiveTypeMarket) => {
			createActiveTypeMarketMutation({
				variables: {
					input: {
						name: activeTypeMarket.name,
					},
				},
				refetchQueries,
			});
		},
		[],
	);

	const updateActiveTypeMarket = React.useCallback(
		(activeTypeMarket: ActiveTypeMarket) => {
			updateActiveTypeMarketMutation({
				variables: {
					input: {id: activeTypeMarket.id, name: activeTypeMarket.name},
				},
				refetchQueries,
			});
		},
		[],
	);

	const deleteActiveTypeMarket = React.useCallback((id: string) => {
		deleteActiveTypeMarketMutation({
			variables: {
				input: {id},
			},
			refetchQueries,
		});
	}, []);

	return (
		<ViewAuthorizer roles={[UserRole.SystemAdministrator]}>
			<LoadWrapper loading={loading}>
				<ActiveTypeMarketsTooltipTranslationProvider>
					<EntityPage
						items={activeTypeMarkets}
						entityDisplayName={t('ActiveTypeMarkets')}
						createEntity={createActiveTypeMarket}
						updateEntity={updateActiveTypeMarket}
						deleteEntity={deleteActiveTypeMarket}
					/>
				</ActiveTypeMarketsTooltipTranslationProvider>
			</LoadWrapper>
		</ViewAuthorizer>
	);
};

export default AdminActiveTypeMarketsPage;
