import React, {useState, Dispatch, SetStateAction} from 'react';
import {useCommand, useSelection} from 'hooks';
import {useTranslation} from 'react-i18next';
import {
	DefaultButton,
	Panel,
	PanelType,
	PrimaryButton,
	SelectionMode,
} from '@fluentui/react';
import {useForm} from 'react-hook-form';
import {Requirement, VehicleProject, UserRole} from 'types';
import {LoadWrapper} from 'components/LoadWrapper';
import {useAssignRequirementsVehicleProjectBatchMutation} from '../../../hooks';
import {EntityList, EntityListColumn} from 'components';
import {useGetAddRequirementsFormDataQuery} from 'features/RegulatoryDocuments/hooks/useGetAddRequirementsFormData.generated';
import {
	getArrayFieldColumn,
	getRequirementStatusColumn,
	getTextColumn,
} from 'helpers';
import FieldForReasonToAddRequirements from './FieldForReasonToAddRequirements';
import {RequirementsTooltipTranslationProvider} from 'features/Requirements/RequirementsTooltipTranslationProvider';

export type SetAddReason = Dispatch<SetStateAction<string>>;

interface Props {
	// Other props...
	refreshData: () => void; // Add this line
	vehicleProjectId: string;
}

export const AssignVehicleProjectRequirementsForm = ({
	refreshData,
	vehicleProjectId,
}: Props) => {
	const {t} = useTranslation('features/vehicleprojects');
	const [isOpen, setIsOpen] = React.useState<boolean>(false);

	type StateForAddReason = [string, SetAddReason];

	/**
	 * This shouldn't be undefined because it means the text field will switch from
	 * being an uncontrolled to a controlled component.
	 */
	const [reasonToAddRequirements, setAddReason]: StateForAddReason =
		useState<string>('');

	const {data, loading} = useGetAddRequirementsFormDataQuery();

	const requirementData = React.useMemo(() => data?.requirements ?? [], [data]);

	const [items, setItems] = React.useState(requirementData);

	const [selectedRequirements, setSelectedRequirements] = React.useState<
		Requirement[]
	>([]);

	const [selection] = useSelection<Requirement>({
		onSelectionChanged(selectedItems) {
			setSelectedRequirements(selectedItems);
		},
		getKey: item => item.id,
	});

	const [assignRequirementsBatch] =
		useAssignRequirementsVehicleProjectBatchMutation();

	useCommand(
		{
			text: t('AssignRequirements'),
			key: 'assignRequirements',
			iconProps: {
				iconName: 'Add',
			},
			title: t('AssignRequirements'),
			priority: 1,
			onClick() {
				setIsOpen(true);
			},
			roles: [UserRole.SystemAdministrator, UserRole.Vex],
		},
		[isOpen],
	);

	const closeAndReset = (): void => {
		setIsOpen(false);
		selection.setAllSelected(false);
		setAddReason('');
	};

	const handleCancelClick = React.useCallback(() => {
		closeAndReset();
		setItems(requirementData);
	}, [isOpen, requirementData, selection]);

	const {handleSubmit} = useForm<VehicleProject>({
		reValidateMode: 'onSubmit',
		mode: 'all',
	});

	const handleSaveClick = React.useCallback(() => {
		handleSubmit(() => {
			assignRequirementsBatch({
				variables: {
					input: {
						requirementIds: selectedRequirements.map(r => r.id),
						vehicleProjectIds: [vehicleProjectId],
						addReason: reasonToAddRequirements,
					},
				},
				refetchQueries: [],
			}).then(() => {
				refreshData();
			});
		})();

		closeAndReset();
	}, [
		isOpen,
		vehicleProjectId,
		selectedRequirements,
		selection,
		reasonToAddRequirements,
	]);

	const onRenderFooterContent: any = React.useCallback(
		() => (
			<form>
				<PrimaryButton
					styles={{root: {marginRight: 8}}}
					onClick={handleSaveClick}
					disabled={!reasonToAddRequirements}
				>
					{t('AssignButton')}
				</PrimaryButton>
				<DefaultButton onClick={handleCancelClick}>
					{t('CancelButton')}
				</DefaultButton>
			</form>
		),
		[handleSaveClick, handleCancelClick, reasonToAddRequirements],
	);

	const columns = React.useMemo(
		() =>
			[
				getTextColumn('requirementName', t('Requirement'), 'name', true, false),
				getRequirementStatusColumn(
					'requirementStatus',
					t('Status'),
					'status',
					false,
				),
				getTextColumn(
					'requirementId',
					t('RequirementId'),
					'requirementId',
					false,
					false,
				),
				getArrayFieldColumn(
					'vexClusters',
					t('VexCluster'),
					'vexClusters',
					false,
				),
			] as EntityListColumn[],
		[t],
	);

	return (
		<>
			<Panel
				isLightDismiss
				isOpen={isOpen}
				onDismiss={handleCancelClick}
				headerText={t('AssignRequirements')}
				onRenderFooterContent={onRenderFooterContent}
				isFooterAtBottom={true}
				type={PanelType.largeFixed}
			>
				<LoadWrapper loading={loading}>
					<FieldForReasonToAddRequirements
						value={reasonToAddRequirements}
						setValue={setAddReason}
						selectedRequirements={selectedRequirements}
					/>
					<RequirementsTooltipTranslationProvider>
						<EntityList
							items={items.length > 0 ? items : requirementData}
							columns={columns}
							aria-rowcount
							selectionMode={SelectionMode.multiple}
							selection={selection}
						/>
					</RequirementsTooltipTranslationProvider>
				</LoadWrapper>
			</Panel>
		</>
	);
};
