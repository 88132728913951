import {EntityListColumn} from 'components';
import {renderArrayField} from 'components/EntityList/ColumnRenderers';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {FullTextSearchRegulationsQuery} from '../hooks';
import {SearchResultList} from './SearchResultList';
import {RegulationsTooltipTranslationProvider} from 'features/Regulations/components/RegulationsTooltipTranslationProvider';
import {renderKeywordsCell} from 'components/Keywords/Keywords';

export const RegulationResultsList: React.FC<{
	regulations: FullTextSearchRegulationsQuery['searchRegulations']['regulations'];
}> = ({regulations}) => {
	const {t} = useTranslation('features/regulations', {
		keyPrefix: 'RegulationsList',
	});

	const columns = React.useMemo(
		() =>
			[
				{
					key: 'c-regulationNumber',
					name: t('RegulationNumber'),
					fieldName: 'regulationNumber',
					isMultiline: true,
					minWidth: 150,
					maxWidth: 150,
					sortable: true,
					isResizable: true,
				},
				{
					key: 'c-name',
					name: t('Name'),
					fieldName: 'name',
					isMultiline: true,
					minWidth: 500,
					sortable: true,
					isResizable: true,
				},
				{
					key: 'c-markets',
					name: t('Markets'),
					fieldName: 'markets',
					minWidth: 200,
					isResizable: true,
					filterable: true,
					isMultiline: true,
					onRender: renderArrayField(),
				},
				{
					key: 'c-mainkeywords',
					name: t('MainKeywords'),
					fieldName: 'mainKeywords',
					isMultiline: true,
					minWidth: 500,
					filterable: true,
					isResizable: true,
					onRender: renderArrayField(),
				},
				{
					key: 'c-keywords',
					name: t('KeyWords'),
					fieldName: 'keywords',
					isMultiline: true,
					minWidth: 500,
					filterable: true,
					isResizable: true,
					onRender: renderKeywordsCell,
				},
			] as EntityListColumn[],
		[t],
	);

	return (
		<RegulationsTooltipTranslationProvider>
			<SearchResultList
				items={regulations}
				columns={columns}
				getItemUrl={reg => `/regulations/${reg?.id}`}
			/>
		</RegulationsTooltipTranslationProvider>
	);
};
