import React from 'react';
import {useParagraphsContext} from '../ParagraphsContext';
import {useUserContext} from 'authentication/UserContext';
import {KeywordAssignment, KeywordStatus} from 'types';
import _ from 'lodash';
import {useKeywordAssignmentsFormDataQuery} from './keywordAssignmentFromData.generated';

export const useKeywordAssignmentsFormData = (
	selectedKeywordId: string | undefined,
) => {
	const {selectedParagraphs} = useParagraphsContext();
	const {myVexClusters} = useUserContext();

	const {data} = useKeywordAssignmentsFormDataQuery();
	const keywords = React.useMemo(() => data?.keywords ?? [], [data]);

	// Exclude a keyword to suggest additionally, iff every paragraph contains it
	const additionalKeywordOptions = React.useMemo(
		() =>
			keywords.filter(k => {
				return !selectedParagraphs.every(p => {
					return p.keywords.some(pk => pk.id === k.id);
				});
			}),
		[keywords, selectedParagraphs],
	);

	const myVexClusterIds = React.useMemo(
		() => myVexClusters.map(vc => vc.id),
		[myVexClusters],
	);

	const myKeywordAssignmentsWithMyVexAssignments = React.useMemo(() => {
		const allKeywordAssignments =
			selectedParagraphs
				?.flatMap(p => p.keywordAssignments ?? [])
				.filter(kwa =>
					kwa.vexClusterAssignments.some(vca =>
						myVexClusterIds.includes(vca.vexCluster.id),
					),
				)
				.map(kwa => ({
					...kwa,
					vexClusterAssignments: kwa.vexClusterAssignments.filter(vca =>
						myVexClusterIds.includes(vca.vexCluster.id),
					),
				})) ?? [];

		const uniqueKeywordAssignments = _.uniqBy(
			allKeywordAssignments,
			kwa =>
				kwa.keyword.id +
				kwa.vexClusterAssignments
					.map(vca => `${vca.vexCluster.id}${vca.keywordStatus}`)
					.join(''),
		);

		return uniqueKeywordAssignments;
	}, [selectedParagraphs, myVexClusterIds]);

	const defaultKeywordAssignment: KeywordAssignment | undefined = React.useMemo(
		() =>
			myKeywordAssignmentsWithMyVexAssignments.find(kwa =>
				kwa.vexClusterAssignments.some(
					vca => vca.keywordStatus === KeywordStatus.New,
				),
			),
		[myKeywordAssignmentsWithMyVexAssignments],
	);

	const defaultKeyword = React.useMemo(() => {
		if (defaultKeywordAssignment) {
			return defaultKeywordAssignment.keyword;
		}
	}, [defaultKeywordAssignment]);

	const defaultVexCluster = React.useMemo(() => {
		let keywordAssignment: KeywordAssignment | undefined;

		if (defaultKeywordAssignment) {
			keywordAssignment = defaultKeywordAssignment;
		}

		if (selectedKeywordId) {
			keywordAssignment = myKeywordAssignmentsWithMyVexAssignments.find(
				kwa => kwa.keyword.id === selectedKeywordId,
			);
		}

		if (keywordAssignment) {
			return keywordAssignment.vexClusterAssignments.find(
				vca => vca.keywordStatus === KeywordStatus.New,
			)?.vexCluster;
		}
	}, [
		selectedKeywordId,
		defaultKeywordAssignment,
		myKeywordAssignmentsWithMyVexAssignments,
	]);

	const selectableKeywords = React.useMemo(() => {
		const keywords = _.uniqBy(
			myKeywordAssignmentsWithMyVexAssignments.map(kwa => kwa.keyword),
			k => k.id,
		);

		return keywords;
	}, [myKeywordAssignmentsWithMyVexAssignments]);

	const selectableVexClusters = React.useMemo(() => {
		const keywordAssignment = myKeywordAssignmentsWithMyVexAssignments.find(
			kwa => kwa.keyword.id === selectedKeywordId,
		);
		const vexClusters =
			keywordAssignment?.vexClusterAssignments.map(vca => vca.vexCluster) ?? [];

		const uniqueVexClusters = _.uniqBy(vexClusters, vc => vc.id);

		return uniqueVexClusters;
	}, [selectedKeywordId, myKeywordAssignmentsWithMyVexAssignments]);

	return {
		additionalKeywordOptions,
		defaultKeyword,
		defaultVexCluster,
		selectableKeywords,
		selectableVexClusters,
	};
};
