import {MessageBarType} from '@fluentui/react';
import React from 'react';

export type MessageOptions = {
	messageBarType?: MessageBarType;
	onShowMoreClick?: () => void;
};

export interface MessageInfo {
	message: string;
	/**
	 * Timeout in ms.
	 * Set it to 0 if the message bar should stay.
	 * Set it to undefined to use the default timeout.
	 */
	timeout?: number;
	options?: MessageOptions;
}

type PossibleMessage = string | MessageInfo | undefined;

export type SetMessage = (message: PossibleMessage) => void;

export type NotificationContextType = {
	message: PossibleMessage;
	messageId: string | undefined;
	setMessage: (message: PossibleMessage) => void;
	messageBarType?: MessageBarType;
	onShowMoreClick?: () => void;
};

export const NotificationContext = React.createContext(
	{} as NotificationContextType,
);
