import React, {useMemo, useState} from 'react';
import {EntityList, EntityListColumn, EntityListProps} from 'components';
import {useRequirementColumns} from 'features/Requirements/hooks/useRequirementColumns';
import {
	IconButton,
	SelectionMode,
	mergeStyles,
	useTheme,
} from '@fluentui/react';

import {
	CompareBaselineChange,
	CompareBaselineChanges,
} from './BaselineComparisonPage.types';
import {SetRequired} from 'type-fest';
import {useTranslation} from 'react-i18next';
import {NAMESPACE_OF_BASELINE_CHANGES} from './BaselineComparisonPage.utils';
import {BaselineComparisonRow} from './BaselineComparisonRow';
import {useSelection} from '../../../hooks';
import {DownloadButton} from './BaselineComparisonDownloadButton';
import classnames from 'classnames';

interface Props {
	baselineId: string;
	changes: CompareBaselineChanges;
}

function getKey(ar: Array<boolean>): string {
	const res = [];
	for (let i = 0; i < ar.length; i++) {
		res.push(ar[i] ? '1' : '0');
	}

	return res.join('');
}

export function RequirementsList({changes, baselineId}: Props): JSX.Element {
	const {t} = useTranslation(NAMESPACE_OF_BASELINE_CHANGES, {});

	const {t: t1} = useTranslation(NAMESPACE_OF_BASELINE_CHANGES, {
		keyPrefix: 'Statuses',
	});

	let [collapsibleList, setCollapsibleList] = useState(Array<boolean>);

	const [selectedRequirements, setSelectedRequirements] = React.useState<any[]>(
		[],
	);

	const requirements = useMemo((): any[] => {
		const requirements = [];

		const collapsibles = [];
		for (let i = 0; i < changes.length; i++) {
			const r: any = {...changes[i].requirement};
			r.compareStatus = changes[i].compareStatus;
			r.changes = changes[i].changes;
			collapsibles.push(true);
			requirements.push(r);
		}

		setCollapsibleList(collapsibles);

		return requirements;
	}, [changes, setCollapsibleList]);

	const requirementColumns: EntityListColumn[] = useRequirementColumns();
	const theme = useTheme();

	const columnToggleChanges = useMemo((): EntityListColumn[] => {
		type ColumnCreationFields = SetRequired<
			Partial<EntityListColumn>,
			'fieldName'
		>;

		type ColumnRenderer = (item: CompareBaselineChange) => JSX.Element | null;

		const renderToggleChanges: ColumnRenderer = item => {
			return item.changes.length > 0 ? (
				<IconButton
					size={10}
					iconProps={{iconName: 'MoreVertical'}}
					title='More options'
					ariaLabel='More options'
					onClick={() => {
						const sfdf = requirements.indexOf(item);

						collapsibleList[sfdf] = !collapsibleList[sfdf];

						collapsibleList = [...collapsibleList, false];
						setCollapsibleList(collapsibleList);
					}}
					styles={{
						root: {
							backgroundColor: theme.palette.yellow,
							height: '20px',
							width: '20px',
						},
						icon: {
							fontSize: '10px',
						},
					}}
				/>
			) : (
				<></>
			);
		};

		const createCreationFields = (): ColumnCreationFields[] => {
			return [{fieldName: 'toggleChanges', onRender: renderToggleChanges}];
		};

		const createColumnFromFieldName = ({
			fieldName,
			...other
		}: ColumnCreationFields): EntityListColumn => {
			return {
				key: fieldName,
				minWidth: 70,
				maxWidth: 140,
				fieldName,
				name: t(fieldName),
				isResizable: true,
				...other,
			};
		};

		const creationFields: ColumnCreationFields[] = createCreationFields();
		return creationFields.map(createColumnFromFieldName);
	}, [collapsibleList, requirements, t1, theme.palette.yellow]);

	const columnCompareStatus = useMemo((): EntityListColumn[] => {
		type ColumnCreationFields = SetRequired<
			Partial<EntityListColumn>,
			'fieldName'
		>;

		type ColumnRenderer = (item: CompareBaselineChange) => JSX.Element | null;

		const renderStatus: ColumnRenderer = item => {
			let color = 'grey';

			switch (item.compareStatus) {
				case 'new':
					color = theme.palette.green;
					break;
				case 'deleted':
					color = theme.palette.red;
					break;
				case 'changed':
					color = theme.palette.yellow;
					break;

				case 'unchanged':
					// No Color changed
					break;

				default:
			}

			return (
				<span
					style={{
						background: color,
						color: 'white',
						padding: '5px',
						height: '100%',
						width: '100px',
					}}
				>
					{t1(`${item.compareStatus}`)}
				</span>
			);
		};

		const createCreationFields = (): ColumnCreationFields[] => {
			return [{fieldName: 'compareStatus', onRender: renderStatus}];
		};

		const createColumnFromFieldName = ({
			fieldName,
			...other
		}: ColumnCreationFields): EntityListColumn => {
			return {
				key: fieldName,
				minWidth: 140,
				maxWidth: 220,
				fieldName,
				name: t(fieldName),
				isResizable: true,
				...other,
			};
		};

		const creationFields: ColumnCreationFields[] = createCreationFields();
		return creationFields.map(createColumnFromFieldName);
	}, [t, theme.palette.green, theme.palette.red, theme.palette.yellow]);

	const columns = [
		...columnCompareStatus,
		...requirementColumns,
		...columnToggleChanges,
	];

	const renderRow: EntityListProps['onRenderRow'] = (props, defaultRender) => {
		if (props === undefined) return <></>;
		const index = props!.itemIndex;
		if (index === undefined) return <></>;
		const collapsible = collapsibleList[index];

		return (
			<BaselineComparisonRow
				defaultProps={props}
				defaultRender={defaultRender}
				collapsible={collapsible}
			/>
		);
	};

	const [selection] = useSelection<any>({
		onSelectionChanged(selectedItems) {
			setSelectedRequirements(selectedItems);
		},
		getKey: item => item.id,
	});

	return (
		<>
			<DownloadButton
				baselineId={baselineId}
				selectedRequirements={selectedRequirements}
			>
				{' '}
			</DownloadButton>
			<EntityList
				key={getKey(collapsibleList)}
				className={createStyle()}
				columns={columns}
				items={requirements}
				selectionMode={SelectionMode.multiple}
				selection={selection}
				sticky
				onRenderRow={renderRow}
			/>
		</>
	);
}

// This will give the first 2 and last column a sticky position

function createStyle(): string {
	const selectionWidth = 48;

	return mergeStyles({
		// Add more styles as needed

		// Header, First Column
		'.ms-DetailsHeader-cell:first-child': {
			position: 'sticky',
			left: '0px',
			backgroundColor: 'white', // Add a solid background color
			opacity: 1, // Ensure the element is not transparent
			zIndex: 10,
		},

		// Header, Second Column
		'.ms-DetailsHeader-cell:nth-child(2)': {
			position: 'sticky',
			left: `${selectionWidth}px`,
			backgroundColor: 'inherit', // Add a solid background color
			opacity: 1, // Ensure the element is not transparent
			zIndex: 9,
		},

		// Header, last Column
		'div[data-item-key="toggleChanges"]': {
			position: 'sticky',
			right: '0px',
			backgroundColor: 'white', // Add a solid background color
			opacity: 1, // Ensure the element is not transparent
			zIndex: 8,
		},

		// Cell, First column
		'.ms-DetailsRow > div:first-child': {
			position: 'sticky',
			left: '0px',
			backgroundColor: 'inherit', // Add a solid background color
			opacity: 1, // Ensure the element is not transparent
			zIndex: 10,
		},

		// Cell, Second column
		'.ms-DetailsRow-fields > div:nth-child(1)': {
			position: 'sticky',
			left: `${selectionWidth}px`,
			backgroundColor: 'white', // Add a solid background color
			opacity: 1, // Ensure the element is not transparent
			zIndex: 9,
		},

		// Cell, last column
		'.ms-DetailsRow-fields > div:last-child': {
			position: 'sticky',
			right: '0px',
			backgroundColor: 'white', // Add a solid background color
			opacity: 1, // Ensure the element is not transparent
			zIndex: 8,
		},

		// Cell, First column, Selected
		'.is-selected  .ms-DetailsRow > div:first-child': {
			backgroundColor: 'rgb(237, 235, 233);', // Add a solid background color
		},

		// Cell, Second column, Selected
		'.is-selected   .ms-DetailsRow-fields > div:nth-child(1)': {
			backgroundColor: 'rgb(237, 235, 233);', // Add a solid background color
		},

		// Cell, last column, Selected
		'.is-selected   .ms-DetailsRow-fields > div:last-child': {
			backgroundColor: 'rgb(237, 235, 233);', // Add a solid background color
		},

		// Cell, First column, Hover
		'.ms-DetailsRow:hover .ms-DetailsRow > div:first-child': {
			backgroundColor: 'rgb(243, 242, 241);', // Add a solid background color
		},

		// Cell, Second column, Hover
		'.ms-DetailsRow:hover .ms-DetailsRow-fields > div:nth-child(1)': {
			backgroundColor: 'rgb(243, 242, 241);', // Add a solid background color
		},

		// Cell, last column, Hover
		'.ms-DetailsRow:hover .ms-DetailsRow-fields > div:last-child': {
			backgroundColor: 'rgb(243, 242, 241);', // Add a solid background color
		},

		// Cell, First column, Selected & Hover
		'.is-selected.ms-DetailsRow:hover .ms-DetailsRow > div:first-child': {
			backgroundColor: 'rgb(225, 223, 221);', // Add a solid background color
		},

		// Cell, Second column, Selected & Hover
		'.is-selected.ms-DetailsRow:hover .ms-DetailsRow-fields > div:nth-child(1)':
			{
				backgroundColor: 'rgb(225, 223, 221);', // Add a solid background color
			},

		// Cell, last column, Selected & Hover
		'.is-selected.ms-DetailsRow:hover .ms-DetailsRow-fields > div:last-child': {
			backgroundColor: 'rgb(225, 223, 221);', // Add a solid background color
		},
	});
}
