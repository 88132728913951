import {EntityPage} from 'components';
import {ViewAuthorizer} from 'components/ViewAuthorizer';
import {LoadWrapper} from 'components/LoadWrapper';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {GearboxVariant, UserRole} from 'types';
import {
	useGetAllGearboxVariantsQuery,
	useCreateGearboxVariantMutation,
	useUpdateGearboxVariantMutation,
	useDeleteGearboxVariantMutation,
	GetAllGearboxVariantsDocument,
} from './hooks/gearboxVariants.generated';
import {GearboxVariantsTooltipTranslationProvider} from 'features/localizedTooltips';

const AdminGearboxVariantsPage: React.FC = () => {
	const {t} = useTranslation('features/adminsection', {
		keyPrefix: 'AdminGearboxVariantsPage',
	});

	const {loading, data} = useGetAllGearboxVariantsQuery();
	const [createGearboxVariantMutation] = useCreateGearboxVariantMutation();
	const [updateGearboxVariantMutation] = useUpdateGearboxVariantMutation();
	const [deleteGearboxVariantMutation] = useDeleteGearboxVariantMutation();

	const gearboxVariants = React.useMemo(
		() => data?.gearboxVariants ?? [],
		[data],
	);

	const refetchQueries = [GetAllGearboxVariantsDocument];

	const createGearboxVariant = React.useCallback(
		(gearboxVariant: GearboxVariant) => {
			createGearboxVariantMutation({
				variables: {
					input: {
						name: gearboxVariant.name,
					},
				},
				refetchQueries,
			});
		},
		[],
	);

	const updateGearboxVariant = React.useCallback(
		(gearboxVariant: GearboxVariant) => {
			updateGearboxVariantMutation({
				variables: {
					input: {id: gearboxVariant.id, name: gearboxVariant.name},
				},
				refetchQueries,
			});
		},
		[],
	);

	const deleteGearboxVariant = React.useCallback((id: string) => {
		deleteGearboxVariantMutation({
			variables: {
				input: {id},
			},
			refetchQueries,
		});
	}, []);

	return (
		<ViewAuthorizer roles={[UserRole.SystemAdministrator]}>
			<LoadWrapper loading={loading}>
				<GearboxVariantsTooltipTranslationProvider>
					<EntityPage
						items={gearboxVariants}
						entityDisplayName={t('GearboxVariants')}
						createEntity={createGearboxVariant}
						updateEntity={updateGearboxVariant}
						deleteEntity={deleteGearboxVariant}
					/>
				</GearboxVariantsTooltipTranslationProvider>
			</LoadWrapper>
		</ViewAuthorizer>
	);
};

export default AdminGearboxVariantsPage;
