import {
	$getRoot,
	EditorState,
	EditorThemeClasses,
	LexicalEditor,
} from 'lexical';
import React from 'react';
import {LexicalComposer} from '@lexical/react/LexicalComposer';
import {PlainTextPlugin} from '@lexical/react/LexicalPlainTextPlugin';
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import {$generateNodesFromDOM, $generateHtmlFromNodes} from '@lexical/html';
import {TableCellNode, TableNode, TableRowNode} from '@lexical/table';
import {LinkNode} from '@lexical/link';

import {TablePlugin} from '@lexical/react/LexicalTablePlugin';
import {LinkPlugin} from '@lexical/react/LexicalLinkPlugin';

import {NodeEventPlugin} from '@lexical/react/LexicalNodeEventPlugin';
import ImagePlugin from './LexicalEditorPlugin/ImagePlugin';
import ImagesPlugin from './LexicalEditorPlugin/ImagePlugin';
import {ImageNode} from './LexicalEditorPlugin/ImageNode';

export interface LexicalEditorElementProps {
	htmlTableText: string;
	themeClasss: EditorThemeClasses;
	clickEvent: (element: HTMLElement) => void;
	updateEvent: (doc: Element) => void;
	editable: boolean;
	addToolbar: boolean;
}

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error: any) {
	console.error(error);
}

export const LexicalEditorElement: React.FC<LexicalEditorElementProps> = ({
	htmlTableText,
	themeClasss,
	clickEvent,
	updateEvent,
	editable,
}) => {
	const initialConfig = {
		namespace: 'MyEditor',
		theme: themeClasss,
		editable,
		onError,

		nodes: [
			ImageNode,
			LinkNode,
			TableNode,
			TableCellNode,
			TableRowNode,

			/* Note: check this later for the css style	
			https://lexical.dev/docs/concepts/serialization#handling-extended-html-styling
			{
				replace: TextNode,
				with: (node: TextNode) => new ExtendedTextNode(node.__text),
			}, */
		],
	};

	const cleanCss = (dom: Element): void => {
		if (!dom) return;
		if (dom.getAttribute('style')) dom.setAttribute('style', '');
		if (dom.getAttribute('class')) dom.setAttribute('class', '');

		if ('children' in dom) {
			for (const child of dom.children) {
				cleanCss(child);
			}
		}
	};

	// When the editor changes, you can get notified via the
	// LexicalOnChangePlugin!
	function onChange(editorState: EditorState, editor: LexicalEditor) {
		editorState.read(() => {
			const htmlString = $generateHtmlFromNodes(editor);
			const parser = new DOMParser();
			const dom = parser.parseFromString(htmlString, 'text/html');
			const body = dom.documentElement.getElementsByTagName('body')[0];
			const res = body.children[0];

			cleanCss(res);
			updateEvent(res);
		});
	}

	return (
		<LexicalComposer initialConfig={initialConfig}>
			<LoadInitialContent initialContent={htmlTableText} />

			<PlainTextPlugin
				contentEditable={<ContentEditable />}
				placeholder={<></>}
				ErrorBoundary={LexicalErrorBoundary}
			/>

			<ImagesPlugin />
			<HistoryPlugin />
			<TablePlugin />
			<ImagePlugin />

			<LinkPlugin />
			<OnChangePlugin onChange={onChange} />
			<NodeEventPlugin
				nodeType={TableNode}
				eventType={'click'}
				eventListener={(e: Event) => {
					if (e.target === null) {
						return;
					}

					clickEvent(e.target as HTMLElement);
				}}
			/>
		</LexicalComposer>
	);
};

type LoadInitialContentProps = {initialContent?: string};

export const LoadInitialContent = ({
	initialContent,
}: LoadInitialContentProps) => {
	const [editor] = useLexicalComposerContext();

	React.useEffect(() => {
		if (!initialContent) {
			return;
		}

		editor.update(() => {
			const parser = new DOMParser();
			const dom = parser.parseFromString(initialContent, 'text/html');

			const nodes = $generateNodesFromDOM(editor, dom);
			$getRoot().clear();
			for (const node of nodes) {
				$getRoot().append(node);
			}
		});
	}, []);

	return null;
};
