import React from 'react';
import {useId} from '@fluentui/react-hooks';
import LocalizedTooltipHost, {
	LocalizedTooltipHostProps,
} from 'features/localizedTooltips/LocalizedTooltipHost';

export type RenderChildrenFromTooltipId = (
	tooltipId: string,
) => React.ReactNode;

export interface PropsForLocalizedTooltipHostWithId
	extends Omit<LocalizedTooltipHostProps, 'children'> {
	children: RenderChildrenFromTooltipId;
}

/**
 * This component is necessary because it allows other components to access the
 * ID, and not just the tooltip host. This enables us to set an
 * aria-describedby property on other components.
 */
export const LocalizedTooltipHostWithId = ({
	children: renderChildren,
	...other
}: PropsForLocalizedTooltipHostWithId): JSX.Element => {
	const id: string = useId('tooltip');
	const children: React.ReactNode = renderChildren(id);
	return (
		<LocalizedTooltipHost id={id} {...other}>
			{children}
		</LocalizedTooltipHost>
	);
};
