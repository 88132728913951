import {useTheme, Stack, FontIcon, Text} from '@fluentui/react';
import {getListClassNames} from 'styles';
import React from 'react';

export const WorkbenchHeader = (props: any) => {
	const theme = useTheme();
	const classNames = getListClassNames(theme, props.halfHeight);

	return (
		<Stack horizontal horizontalAlign='space-between'>
			<Stack horizontal>
				<Stack.Item>
					<Text className={classNames.workbench}>{props.title}</Text>
				</Stack.Item>
				<Stack.Item>
					<Text className={classNames.itemCount}>
						{props.items > 30 ? `30+` : props.items}
					</Text>
				</Stack.Item>
			</Stack>
			<Stack>
				<FontIcon
					iconName='OpenInNewWindow'
					className={classNames.iconStyles}
				/>
			</Stack>
		</Stack>
	);
};

export const NewsItemsHeader = (props: any) => {
	const theme = useTheme();
	const classNames = getListClassNames(theme, props.halfHeight);

	return (
		<div>
			<Stack horizontal horizontalAlign='space-between'>
				<Stack.Item>
					<Text className={classNames.activity}>{props.title}</Text>
				</Stack.Item>
				<Stack.Item>
					<FontIcon
						iconName='OpenInNewWindow'
						className={classNames.iconStyles}
					/>
				</Stack.Item>
			</Stack>
		</div>
	);
};
