import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateRequirementAttachmentsMutationVariables = Types.Exact<{
  input: Types.UpdateRequirementAttachmentsInput;
}>;


export type UpdateRequirementAttachmentsMutation = { __typename?: 'Mutation', updateRequirementAttachments: { __typename?: 'RequirementAttachmentsPayload', requirementAttachments?: { __typename?: 'UpdateEntityAttachmentsResult', added: number, deleted: number } | null } };


export const UpdateRequirementAttachmentsDocument = gql`
    mutation UpdateRequirementAttachments($input: UpdateRequirementAttachmentsInput!) {
  updateRequirementAttachments(input: $input) {
    requirementAttachments {
      added
      deleted
    }
  }
}
    `;
export type UpdateRequirementAttachmentsMutationFn = Apollo.MutationFunction<UpdateRequirementAttachmentsMutation, UpdateRequirementAttachmentsMutationVariables>;

/**
 * __useUpdateRequirementAttachmentsMutation__
 *
 * To run a mutation, you first call `useUpdateRequirementAttachmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRequirementAttachmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRequirementAttachmentsMutation, { data, loading, error }] = useUpdateRequirementAttachmentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRequirementAttachmentsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRequirementAttachmentsMutation, UpdateRequirementAttachmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRequirementAttachmentsMutation, UpdateRequirementAttachmentsMutationVariables>(UpdateRequirementAttachmentsDocument, options);
      }
export type UpdateRequirementAttachmentsMutationHookResult = ReturnType<typeof useUpdateRequirementAttachmentsMutation>;
export type UpdateRequirementAttachmentsMutationResult = Apollo.MutationResult<UpdateRequirementAttachmentsMutation>;
export type UpdateRequirementAttachmentsMutationOptions = Apollo.BaseMutationOptions<UpdateRequirementAttachmentsMutation, UpdateRequirementAttachmentsMutationVariables>;