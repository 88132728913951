import React, {useMemo} from 'react';
import {
	IRawStyle,
	IScrollablePaneStyles,
	IStyle,
	ScrollablePane,
	mergeStyles,
} from '@fluentui/react';
import {
	getParagraphsWrapperStyles,
	getStackHeight,
} from './paragraphsComparisonStyles';

interface Props {
	children: React.ReactNode;
	extraStyle?: IStyle;
}

export function SidePaneForParagraphs({extraStyle, children}: Props) {
	const getWrapperClass = (): string => {
		return mergeStyles(
			{
				/**
				 * We must set the height and position because the scrollable pane is
				 * relatively positioned, so the height cannot be auto set.
				 */
				height: getStackHeight(true),
				position: 'relative' as IRawStyle['position'],
			},
			extraStyle,
		);
	};

	const scrollablePaneStyles = useMemo((): Partial<IScrollablePaneStyles> => {
		return getParagraphsWrapperStyles('absolute');
	}, []);

	/**
	 * The Scrollable Pane is necessary here in case the paragraphs list, which
	 * will be passed to this component as a child, has a sticky header. If we did
	 * not use a Scrollable Pane, the header would not show at the page's top
	 * because the header would be relative to the paragraphs list. So, it would
	 * show at the list's top. However, if we scrolled the list's top out of view,
	 * we would not see the header. To prevent that, we use a Scrollable Pane to always
	 * keep the list's top in view.
	 */
	return (
		<div className={getWrapperClass()}>
			<ScrollablePane styles={scrollablePaneStyles}>{children}</ScrollablePane>
		</div>
	);
}
