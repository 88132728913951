import React from 'react';
import {
	Announced,
	Callout,
	DirectionalHint,
	IBasePickerStyleProps,
	IBasePickerStyles,
	ITagPickerProps,
	TagPickerBase,
	styled,
} from '@fluentui/react';
import {getStyles} from './CustomPicker.styles';
import {DefaultSuggestionModel} from './VehicleProjectsReferencePicker.types';
import {VehicleProjectSuggestion} from './VehicleProjectSuggestion/VehicleProjectSuggestion';
import {MsgForNoItemsFound} from './MsgForNoItemsFound';

type PossibleString = string | undefined;

/**
 * ! Important
 *
 * This might not support all of the Suggestions' props. However, we must
 * continue accepting them here so that the custom picker's type is compatible
 * with Controlled Tag Picker.
 *
 * * NOTE:
 *
 * For the sake of consistency, don't make any changes here if they can be made
 * from the Vehicle Projects' Reference Picker.
 */
class CustomTagPickerBase extends TagPickerBase {
	private renderSuggestion = (
		suggestion: DefaultSuggestionModel,
		index: number,
	): JSX.Element => {
		return (
			<VehicleProjectSuggestion
				suggestion={suggestion}
				index={index}
				// We assert this because we assume that this prop is set in the derived
				// component's defaultProps eslint-disable-next-line
				//
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				onRenderSuggestionsItem={this.props.onRenderSuggestionsItem!}
				onSuggestionClick={this.onSuggestionClick}
				/**
				 * We cannot use the index as the key because it does not produce the
				 * same effect. This is because two suggestions can have the same index,
				 * but not represent the same Vehicle Project. By using the item's key,
				 * we guarantee that the component's key will always change if the
				 * Vehicle Project is different.
				 */
				key={suggestion.item.key}
			/>
		);
	};

	private renderSuggestionsList = (
		suggestions: DefaultSuggestionModel[],
	): JSX.Element[] => {
		return suggestions.map(this.renderSuggestion);
	};

	private getMsgForNoResultsFound = (
		suggestions: DefaultSuggestionModel[],
	): PossibleString => {
		const noResultsFoundText: PossibleString =
			this.props.pickerSuggestionsProps?.noResultsFoundText;
		if (!suggestions.length && noResultsFoundText) return noResultsFoundText;
	};

	protected renderSuggestions(): JSX.Element | null {
		const suggestions: DefaultSuggestionModel[] =
			this.suggestionStore.getSuggestions();

		const msgForNoResultsFound: PossibleString =
			this.getMsgForNoResultsFound(suggestions);

		/**
		 * Note that some of this code was copied from Fluent UI.
		 */
		return this.state.suggestionsVisible && this.input ? (
			<Callout
				isBeakVisible={false}
				gapSpace={5}
				target={
					this.input.current ? this.input.current.inputElement : undefined
				}
				onDismiss={this.dismissSuggestions}
				directionalHint={DirectionalHint.bottomLeftEdge}
				directionalHintForRTL={DirectionalHint.bottomRightEdge}
				{...this.props.pickerCalloutProps}
			>
				<Announced message={msgForNoResultsFound} aria-live='polite' />
				{msgForNoResultsFound ? (
					<MsgForNoItemsFound msg={msgForNoResultsFound} />
				) : null}
				<div>{this.renderSuggestionsList(suggestions)}</div>
			</Callout>
		) : null;
	}
}

/**
 * ! Important
 *
 * If we rename this, change the scope to match the name. Otherwise, this
 * component won't support other components customizing its styles through the
 * "styles" property.
 */
export const CustomTagPicker = styled<
	ITagPickerProps,
	IBasePickerStyleProps,
	IBasePickerStyles
>(CustomTagPickerBase, getStyles, undefined, {
	scope: 'CustomTagPicker',
});
