import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllGearboxVariantsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllGearboxVariantsQuery = { __typename?: 'Query', gearboxVariants?: Array<{ __typename?: 'GearboxVariant', id: string, name: string, createdAt?: any | null, modifiedAt?: any | null, createdBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null }, modifiedBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null } }> | null };

export type CreateGearboxVariantMutationVariables = Types.Exact<{
  input: Types.CreateGearboxVariantInput;
}>;


export type CreateGearboxVariantMutation = { __typename?: 'Mutation', createGearboxVariant: { __typename?: 'CreateGearboxVariantPayload', gearboxVariant?: { __typename?: 'GearboxVariant', id: string } | null } };

export type UpdateGearboxVariantMutationVariables = Types.Exact<{
  input: Types.UpdateGearboxVariantInput;
}>;


export type UpdateGearboxVariantMutation = { __typename?: 'Mutation', updateGearboxVariant: { __typename?: 'UpdateGearboxVariantPayload', gearboxVariant?: { __typename?: 'GearboxVariant', id: string } | null } };

export type DeleteGearboxVariantMutationVariables = Types.Exact<{
  input: Types.DeleteGearboxVariantInput;
}>;


export type DeleteGearboxVariantMutation = { __typename?: 'Mutation', deleteGearboxVariant: { __typename?: 'DeleteGearboxVariantPayload', gearboxVariant?: { __typename?: 'GearboxVariant', id: string } | null } };


export const GetAllGearboxVariantsDocument = gql`
    query GetAllGearboxVariants {
  gearboxVariants {
    id
    name
    createdAt
    createdBy {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
    modifiedAt
    modifiedBy {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
  }
}
    `;

/**
 * __useGetAllGearboxVariantsQuery__
 *
 * To run a query within a React component, call `useGetAllGearboxVariantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllGearboxVariantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllGearboxVariantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllGearboxVariantsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllGearboxVariantsQuery, GetAllGearboxVariantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllGearboxVariantsQuery, GetAllGearboxVariantsQueryVariables>(GetAllGearboxVariantsDocument, options);
      }
export function useGetAllGearboxVariantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllGearboxVariantsQuery, GetAllGearboxVariantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllGearboxVariantsQuery, GetAllGearboxVariantsQueryVariables>(GetAllGearboxVariantsDocument, options);
        }
export type GetAllGearboxVariantsQueryHookResult = ReturnType<typeof useGetAllGearboxVariantsQuery>;
export type GetAllGearboxVariantsLazyQueryHookResult = ReturnType<typeof useGetAllGearboxVariantsLazyQuery>;
export type GetAllGearboxVariantsQueryResult = Apollo.QueryResult<GetAllGearboxVariantsQuery, GetAllGearboxVariantsQueryVariables>;
export const CreateGearboxVariantDocument = gql`
    mutation CreateGearboxVariant($input: CreateGearboxVariantInput!) {
  createGearboxVariant(input: $input) {
    gearboxVariant {
      id
    }
  }
}
    `;
export type CreateGearboxVariantMutationFn = Apollo.MutationFunction<CreateGearboxVariantMutation, CreateGearboxVariantMutationVariables>;

/**
 * __useCreateGearboxVariantMutation__
 *
 * To run a mutation, you first call `useCreateGearboxVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGearboxVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGearboxVariantMutation, { data, loading, error }] = useCreateGearboxVariantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGearboxVariantMutation(baseOptions?: Apollo.MutationHookOptions<CreateGearboxVariantMutation, CreateGearboxVariantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGearboxVariantMutation, CreateGearboxVariantMutationVariables>(CreateGearboxVariantDocument, options);
      }
export type CreateGearboxVariantMutationHookResult = ReturnType<typeof useCreateGearboxVariantMutation>;
export type CreateGearboxVariantMutationResult = Apollo.MutationResult<CreateGearboxVariantMutation>;
export type CreateGearboxVariantMutationOptions = Apollo.BaseMutationOptions<CreateGearboxVariantMutation, CreateGearboxVariantMutationVariables>;
export const UpdateGearboxVariantDocument = gql`
    mutation UpdateGearboxVariant($input: UpdateGearboxVariantInput!) {
  updateGearboxVariant(input: $input) {
    gearboxVariant {
      id
    }
  }
}
    `;
export type UpdateGearboxVariantMutationFn = Apollo.MutationFunction<UpdateGearboxVariantMutation, UpdateGearboxVariantMutationVariables>;

/**
 * __useUpdateGearboxVariantMutation__
 *
 * To run a mutation, you first call `useUpdateGearboxVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGearboxVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGearboxVariantMutation, { data, loading, error }] = useUpdateGearboxVariantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGearboxVariantMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGearboxVariantMutation, UpdateGearboxVariantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGearboxVariantMutation, UpdateGearboxVariantMutationVariables>(UpdateGearboxVariantDocument, options);
      }
export type UpdateGearboxVariantMutationHookResult = ReturnType<typeof useUpdateGearboxVariantMutation>;
export type UpdateGearboxVariantMutationResult = Apollo.MutationResult<UpdateGearboxVariantMutation>;
export type UpdateGearboxVariantMutationOptions = Apollo.BaseMutationOptions<UpdateGearboxVariantMutation, UpdateGearboxVariantMutationVariables>;
export const DeleteGearboxVariantDocument = gql`
    mutation DeleteGearboxVariant($input: DeleteGearboxVariantInput!) {
  deleteGearboxVariant(input: $input) {
    gearboxVariant {
      id
    }
  }
}
    `;
export type DeleteGearboxVariantMutationFn = Apollo.MutationFunction<DeleteGearboxVariantMutation, DeleteGearboxVariantMutationVariables>;

/**
 * __useDeleteGearboxVariantMutation__
 *
 * To run a mutation, you first call `useDeleteGearboxVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGearboxVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGearboxVariantMutation, { data, loading, error }] = useDeleteGearboxVariantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteGearboxVariantMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGearboxVariantMutation, DeleteGearboxVariantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGearboxVariantMutation, DeleteGearboxVariantMutationVariables>(DeleteGearboxVariantDocument, options);
      }
export type DeleteGearboxVariantMutationHookResult = ReturnType<typeof useDeleteGearboxVariantMutation>;
export type DeleteGearboxVariantMutationResult = Apollo.MutationResult<DeleteGearboxVariantMutation>;
export type DeleteGearboxVariantMutationOptions = Apollo.BaseMutationOptions<DeleteGearboxVariantMutation, DeleteGearboxVariantMutationVariables>;