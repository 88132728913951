import * as Types from '../types/types.generated';

import { gql } from '@apollo/client';
import { UserFieldsFragmentDoc } from './userFields.generated';
export type HistoryFieldsFragment = { __typename: 'AuditLog', entityId: string, entityNodeId: string, changeSetId: any, entityType: string, entityOperation: Types.EntityOperation, createdAt: any, id: string, entity?: { __typename: 'ActiveTypeMarket', id: string } | { __typename: 'AttachmentCategory', id: string } | { __typename: 'AuditLog', id: string } | { __typename: 'BaselineRequirement', id: string } | { __typename: 'BodyworkVariant', id: string } | { __typename: 'Category', id: string } | { __typename: 'Convolute', id: string } | { __typename: 'Country', id: string } | { __typename: 'DocumentSource', id: string } | { __typename: 'DriveVariant', id: string } | { __typename: 'EngineVariant', id: string } | { __typename: 'GdprRule', id: string } | { __typename: 'GearboxVariant', id: string } | { __typename: 'Keyword', id: string } | { __typename: 'Market', id: string } | { __typename: 'PogisDocument', id: string } | { __typename: 'Reference', id: string } | { __typename: 'Regulation', id: string } | { __typename: 'RegulationCluster', id: string } | { __typename: 'RegulatoryDocument', id: string } | { __typename: 'Requirement', id: string } | { __typename: 'Simulation', id: string } | { __typename: 'SimulationVehicleProject', id: string } | { __typename: 'StandardPlusPcmsCluster', id: string } | { __typename: 'Subscription', id: string } | { __typename: 'SystemLevel', id: string } | { __typename: 'Tag', id: string } | { __typename: 'User', id: string } | { __typename: 'VehicleCategory', id: string } | { __typename: 'VehicleProject', id: string } | { __typename: 'VexCluster', id: string } | null, change: { __typename: 'AuditLogChange', value?: any | null, oldValue?: any | null, nodeId?: string | null, oldNodeId?: string | null, type: string, key: string, path: string, valueType?: string | null }, createdBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null } };

export const HistoryFieldsFragmentDoc = gql`
    fragment HistoryFields on AuditLog {
  entityId
  entityNodeId
  changeSetId
  entity {
    id
    __typename
  }
  change {
    value
    oldValue
    nodeId
    oldNodeId
    type
    key
    path
    valueType
    __typename
  }
  entityType
  entityOperation
  createdAt
  createdBy {
    ...UserFields
  }
  id
  __typename
}
    ${UserFieldsFragmentDoc}`;