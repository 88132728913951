import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ClearFieldRegulatoryDocumentParagraphBatchMutationVariables = Types.Exact<{
  input: Types.ClearFieldRegulatoryDocumentParagraphBatchInput;
}>;


export type ClearFieldRegulatoryDocumentParagraphBatchMutation = { __typename?: 'Mutation', clearFieldRegulatoryDocumentParagraphBatch: { __typename?: 'ClearFieldRegulatoryDocumentParagraphBatchPayload', regulatoryDocument?: { __typename?: 'RegulatoryDocument', id: string } | null } };


export const ClearFieldRegulatoryDocumentParagraphBatchDocument = gql`
    mutation ClearFieldRegulatoryDocumentParagraphBatch($input: ClearFieldRegulatoryDocumentParagraphBatchInput!) {
  clearFieldRegulatoryDocumentParagraphBatch(input: $input) {
    regulatoryDocument {
      id
    }
  }
}
    `;
export type ClearFieldRegulatoryDocumentParagraphBatchMutationFn = Apollo.MutationFunction<ClearFieldRegulatoryDocumentParagraphBatchMutation, ClearFieldRegulatoryDocumentParagraphBatchMutationVariables>;

/**
 * __useClearFieldRegulatoryDocumentParagraphBatchMutation__
 *
 * To run a mutation, you first call `useClearFieldRegulatoryDocumentParagraphBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearFieldRegulatoryDocumentParagraphBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearFieldRegulatoryDocumentParagraphBatchMutation, { data, loading, error }] = useClearFieldRegulatoryDocumentParagraphBatchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClearFieldRegulatoryDocumentParagraphBatchMutation(baseOptions?: Apollo.MutationHookOptions<ClearFieldRegulatoryDocumentParagraphBatchMutation, ClearFieldRegulatoryDocumentParagraphBatchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearFieldRegulatoryDocumentParagraphBatchMutation, ClearFieldRegulatoryDocumentParagraphBatchMutationVariables>(ClearFieldRegulatoryDocumentParagraphBatchDocument, options);
      }
export type ClearFieldRegulatoryDocumentParagraphBatchMutationHookResult = ReturnType<typeof useClearFieldRegulatoryDocumentParagraphBatchMutation>;
export type ClearFieldRegulatoryDocumentParagraphBatchMutationResult = Apollo.MutationResult<ClearFieldRegulatoryDocumentParagraphBatchMutation>;
export type ClearFieldRegulatoryDocumentParagraphBatchMutationOptions = Apollo.BaseMutationOptions<ClearFieldRegulatoryDocumentParagraphBatchMutation, ClearFieldRegulatoryDocumentParagraphBatchMutationVariables>;