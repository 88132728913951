import React, {useCallback, useMemo} from 'react';
import {
	ChoiceGroup,
	IChoiceGroupOption,
	IChoiceGroupProps,
} from '@fluentui/react';
import {useController} from 'react-hook-form';
import {ControlOfSimulationsPage} from '../SimulationsPage.types';
import {
	FORM_KEY_PREFIX,
	useTranslationForSimulationsPageWithIncorrectKeyPrefixType,
} from '../SimulationsPage.utils';

interface Props extends IChoiceGroupProps {
	control: ControlOfSimulationsPage;
}

export const NAME_OF_REFERENCE_CHOICE_FIELD = 'referenceChoice';

export const KEY_FOR_OPTION_FOR_WITH_REFERENCE = 'withReference';

export const ReferenceChoice = ({control}: Props) => {
	const {t} = useTranslationForSimulationsPageWithIncorrectKeyPrefixType({
		keyPrefix: `${FORM_KEY_PREFIX}.SimulationOptions`,
	});

	const KEY_FOR_OPTION_FOR_NO_REFERENCE = 'noReference';

	const {
		field: {onChange, onBlur, value},
	} = useController({
		name: NAME_OF_REFERENCE_CHOICE_FIELD,
		control,
		defaultValue: KEY_FOR_OPTION_FOR_NO_REFERENCE,
	});

	type OnChange = Exclude<IChoiceGroupProps['onChange'], undefined>;

	const handleChange: OnChange = useCallback(
		(_, option) => {
			onChange(option?.key);
		},
		[onChange],
	);

	const options = useMemo((): IChoiceGroupOption[] => {
		return [
			{
				key: KEY_FOR_OPTION_FOR_NO_REFERENCE,
				text: t('NoReference'),
				styles: {root: {marginBottom: '1rem'}},
			},
			{key: KEY_FOR_OPTION_FOR_WITH_REFERENCE, text: t('WithReference')},
		];
	}, [t]);

	const styles = useMemo((): IChoiceGroupProps['styles'] => {
		return {root: {marginTop: '1.5rem'}};
	}, []);

	return (
		<ChoiceGroup
			onChange={handleChange}
			onBlur={onBlur}
			selectedKey={value}
			options={options}
			styles={styles}
		/>
	);
};
