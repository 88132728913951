import {
	Breadcrumb,
	IBreadcrumbItem,
	IScrollablePaneStyles,
	mergeStyleSets,
	ScrollablePane,
	Theme,
	useTheme,
} from '@fluentui/react';
import {PdfViewer} from 'components';
import {LoadWrapper} from 'components/LoadWrapper';
import {DocumentEditor} from 'features/RegulatoryDocuments/components/DocumentEditor';
import {EditorSelectionContextProvider} from 'features/RegulatoryDocuments/context';
import {useNotificationBar} from 'hooks';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import Split from 'react-split';
import {
	ImportRegulatoryDocumentParagraphInput,
	RegulatoryDocument,
} from 'types';
import {PdfPageContextProvider} from './context/PdfPageContextProvider';
import {
	GetRegulatoryDocumentDocument,
	useGetRegulatoryDocumentQuery,
	useImportRegulatoryDocumentParagraphsMutation,
} from './hooks';

const RegulatoryDocumentEditorPage = () => {
	const {t} = useTranslation('features/regulatorydocuments', {
		keyPrefix: 'RegulatoryDocumentEditorPage',
	});
	const theme = useTheme();
	const classNames = getClassNames(theme);
	const {scrollablePaneStyles} = getStyles(theme);

	const {regulatoryDocumentId} = useParams();

	const {loading, data} = useGetRegulatoryDocumentQuery({
		variables: {
			regulatoryDocumentId: regulatoryDocumentId ?? '',
		},
		fetchPolicy: 'network-only',
	});

	const regulatoryDocument =
		data?.regulatoryDocument ?? ({} as RegulatoryDocument);

	const {document, name, paragraphs, regulation} = regulatoryDocument;

	const [importParagraphs] = useImportRegulatoryDocumentParagraphsMutation();

	const {setMessage} = useNotificationBar();

	const onSave = React.useCallback(
		async (changedParagraphs: ImportRegulatoryDocumentParagraphInput[]) => {
			if (regulatoryDocumentId) {
				await importParagraphs({
					variables: {
						input: {
							regulatoryDocumentId,
							paragraphs: changedParagraphs,
						},
					},
					refetchQueries: [GetRegulatoryDocumentDocument],
				})
					.then(({data}) => {
						if (data) {
							setMessage(t('SaveMessage'));
						}
					})
					.catch(error => {
						// Handle any errors here
						console.error(error);
					});
			}
		},
		[importParagraphs, regulatoryDocumentId],
	);

	const navigate = useNavigate();
	const onBreadcrumbClick = React.useCallback(
		(
			ev?: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
			item?: IBreadcrumbItem | undefined,
		) => {
			ev?.preventDefault();
			if (item?.href) {
				navigate(item.href);
			}
		},
		[],
	);

	const itemsWithHref: IBreadcrumbItem[] = React.useMemo(
		() => [
			{
				text: t('Regulations'),
				key: 'regulations',
				href: '/regulations',
				onClick: onBreadcrumbClick,
			},
			{
				text: `${regulation?.regulationNumber}`,
				key: 'regulationName',
				href: `/regulations/${regulation?.id}`,
				onClick: onBreadcrumbClick,
			},
			{
				text: `${name}`,
				key: 'version',
				href: `/regulations/${regulation?.id}/${regulatoryDocumentId}/paragraphs`,
			},
		],
		[loading, data],
	);

	if (!regulatoryDocumentId) {
		return <></>;
	}

	return (
		<PdfPageContextProvider>
			<EditorSelectionContextProvider>
				<LoadWrapper loading={loading}>
					<div className={classNames.header}>
						<Breadcrumb
							items={itemsWithHref}
							ariaLabel='Breadcrumb with items rendered as links'
							overflowAriaLabel='More links'
							className={classNames.headerText}
						/>
					</div>
					<Split
						sizes={[50, 50]}
						minSize={240}
						className={classNames.splitContainer}
					>
						<ScrollablePane styles={scrollablePaneStyles}>
							<DocumentEditor
								paragraphs={paragraphs || []}
								onSave={onSave}
								workflowStatus={regulatoryDocument?.workflow?.status}
							/>
						</ScrollablePane>
						<div className={classNames.sizedContainer}>
							<PdfViewer url={document?.uri} />
						</div>
					</Split>
				</LoadWrapper>
			</EditorSelectionContextProvider>
		</PdfPageContextProvider>
	);
};

const getClassNames = (theme: Theme) =>
	mergeStyleSets({
		header: {
			marginLeft: 15,
		},
		headerText: {
			color: theme.palette.neutralDark,
			fontWeight: 600,
			fontSize: 18,
			lineHeight: 24,
			textTransform: 'capitalize',
		},
		sizedContainer: {width: '100%', height: '100%'},
		splitContainer: {
			position: 'relative',
			width: '100%',
			height: 'calc(100% - 50px)',
			display: 'flex',
			'.gutter-horizontal': {
				cursor: ' ew-resize',
			},
		},
	});

const getStyles = (theme: Theme) => ({
	scrollablePaneStyles: {
		root: {
			border: '1px solid ' + theme.palette.neutralLight,
			position: 'relative',
			width: '100%',
			height: '100%',
		},
		contentContainer: {
			overflowX: 'hidden',
		},
	} as Partial<IScrollablePaneStyles>,
});

export default RegulatoryDocumentEditorPage;
