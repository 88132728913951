import React, {useEffect, useMemo, useState} from 'react';
import {BaselineCmdsInfo} from '../baselines/baselineCmds';
import {
	Dialog,
	DialogFooter,
	DialogType,
	IDialogContentProps,
	PrimaryButton,
	TextField,
} from '@fluentui/react';
import {useVehicleProjectsTranslation} from 'features/VehicleProjects/hooks/vehicleProjectsTranslationHooks';
import {
	GetBaselinesByModelAndSeriesDocument,
	useCreateBaseLineByModelAndSeriesMutation,
	useGetBaseLineNameByModelAndSeriesMutation,
} from '../hooks/baseline.generated';
import {
	NavigateToBaselineListPage,
	useNavigateToBaselineListPage,
} from '../BaselineListPage/BaselinesPage.utils';

interface Props {
	generation: string;
	modelSeries: string;

	infoForIsOpen: BaselineCmdsInfo['infoForIsSuccessDialogOpen'];
}

export function BaselineCreateDialog({
	generation,
	modelSeries,
	infoForIsOpen: [isOpen, {setFalse}],
}: Props): JSX.Element {
	const {t} = useVehicleProjectsTranslation({
		keyPrefix: 'BaselineCreateDialog',
	});

	const [name, setName] = useState('');
	const [creating, setCreating] = useState(false);

	const navigateToBaselinesPage: NavigateToBaselineListPage =
		useNavigateToBaselineListPage({
			generation,
			modelSeries,
		});

	const [createBaseLineByModelAndSeriesMutation] =
		useCreateBaseLineByModelAndSeriesMutation();

	const [baseLineNameByModelAndSeriesMutation] =
		useGetBaseLineNameByModelAndSeriesMutation();

	useEffect(() => {
		if (isOpen) {
			baseLineNameByModelAndSeriesMutation({
				variables: {
					generation,
					modelSeries,
				},
				refetchQueries: undefined,
			})
				.then(e => {
					if (e.data?.baseLineNameByModelAndSeries)
						setName(e.data?.baseLineNameByModelAndSeries);
				})
				.catch(e => console.log(e));
		}
	}, [baseLineNameByModelAndSeriesMutation, generation, isOpen, modelSeries]);

	const onTitleChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string,
	) => {
		setName(newValue ?? '');
	};

	const handleClose = async () => {
		try {
			setCreating(true);
			await createBaseLineByModelAndSeriesMutation({
				variables: {
					generation,
					modelSeries,
					name,
				},
				refetchQueries: [GetBaselinesByModelAndSeriesDocument],
			});
		} catch (error) {
			console.error('Failed to rename baseline:', error);
		}

		setFalse();

		navigateToBaselinesPage();
		setCreating(false);
	};

	const handleCancel = async () => {
		setFalse();
	};

	const dialogContentProps: IDialogContentProps = useMemo(() => {
		return {
			type: DialogType.normal,
			title: t('Title'),
			styles: {title: {wordBreak: 'break-word'}},
		};
	}, [t]);

	const isNameEmpty = !name;

	return (
		<Dialog
			hidden={!isOpen}
			onDismiss={setFalse}
			dialogContentProps={dialogContentProps}
		>
			<TextField
				value={name}
				onChange={onTitleChange}
				multiline
				required
				styles={{fieldGroup: {minHeight: '200px'}}}
				errorMessage={isNameEmpty ? t('EmptyNameError') : undefined}
			/>
			<DialogFooter>
				<PrimaryButton onClick={handleCancel} disabled={false}>
					{t('CancelBtn')}
				</PrimaryButton>

				<PrimaryButton onClick={handleClose} disabled={isNameEmpty || creating}>
					{t('CloseBtn')}
				</PrimaryButton>
			</DialogFooter>
		</Dialog>
	);
}
