import React from 'react';
import {Command} from 'hooks';

type CommandContextType = {
	commands: Command[];

	addCommand: (command: Command) => () => void;
	removeCommand: (command: Command) => void;
};

/**
 * Context component that should be rendered somewhere on the top level of the app.
 * AppCommandBar will render all commands inside this context passed to it via the useCommand hook.
 */
export const CommandsContext = React.createContext({} as CommandContextType);
