import * as React from 'react';
import {HookFormProps} from './HookFormProps';
import {useFieldArray} from 'react-hook-form';
import {ReferenceType} from 'types';
import {
	IStackItemStyles,
	IconButton,
	Separator,
	Stack,
	TooltipHost,
} from '@fluentui/react';
import {ControlledTagPicker} from './ControlledTagPicker';
import {ControlledDropdown} from './ControlledDropdown';
import {
	GetAllReferencesQuery,
	useGetAllReferencesQuery,
} from 'features/AdminSection/hooks/references.generated';
import {useTranslation} from 'react-i18next';
import {
	GetReferencePickerRequirementsQuery,
	useGetReferencePickerRegulationsQuery,
	useGetReferencePickerRegulatoryDocumentsQuery,
	useGetReferencePickerRequirementsQuery,
} from './hooks/getReferencePickerData.generated';
import {v4 as uuidv4} from 'uuid';
import {AppendButton} from './AppendButton';
import {
	DocumentReferencesTooltipTranslationProvider,
	ReferencesTooltipTranslationProvider,
	ProviderThatEnablesGettingTooltipsFromContext,
} from 'features/localizedTooltips';

type ReferencePickerProps = {
	label: string;
	enumType:
		| typeof RequirementsEnum
		| typeof RegulatoryDocumentsEnum
		| typeof RegulatoryDocumentParagraphsEnum;
	referenceTypes: ReferenceType[];
	idsToExclude?: string[];
	disabled?: boolean;
};

type FieldObjectType = {
	id: string;
	referenceDocumentType: string;
	requirementReferences?: any[];
	regulationReference?: any[];
	regulatoryDocumentReference?: any[];
};

type FieldsArrayType = Record<'id', string> & Partial<FieldObjectType>;

export enum RequirementsEnum {
	Requirement = 'REQUIREMENT',
}

export enum RegulatoryDocumentsEnum {
	Regulation = 'REGULATION',
	RegulatoryDocument = 'REGULATORY_DOCUMENT',
}

export enum RegulatoryDocumentParagraphsEnum {
	Regulation = 'REGULATION',
	RegulatoryDocument = 'REGULATORY_DOCUMENT',
	RegulatoryDocumentParagraph = 'REGULATORY_DOCUMENT_PARAGRAPH',
}

type Reference = NonNullable<GetAllReferencesQuery['references']>[number];
type Requirement = GetReferencePickerRequirementsQuery['requirements'][number];

/*
 * Types Related to Reference Picker Value's Type:
 */

interface RefValueOfDocRef {
	id: Reference['id'];
}

/**
 * This is the type of value produced by this component.
 *
 * Note that a few fields might be missing here, but the types here so far
 * should be accurate.
 */
interface DocRef {
	referenceDocumentType: string;
	reference: RefValueOfDocRef;
	requirementReferences?: Requirement[];
}

/**
 * This is the type of value that is compatible with this component, so it
 * includes extra types than DocumentReference.
 */
export interface CompatibleDocRef
	extends Omit<DocRef, 'referenceDocumentType' | 'reference'> {
	referenceDocumentType?: DocRef['referenceDocumentType'] | null;
	reference?: RefValueOfDocRef | null;
}

export type CompatibleDocRefs = CompatibleDocRef[] | undefined | null;

export const ReferencePicker: React.FC<
	HookFormProps & ReferencePickerProps
> = ({
	control,
	name,
	label,
	enumType,
	referenceTypes,
	disabled = false,
	idsToExclude = [],
}) => {
	const {t} = useTranslation('components/referencepicker');

	const {fields, remove, append} = useFieldArray({
		control,
		name,
	});

	const [selectedDocumentTypes, setSelectedDocumentTypes] = React.useState<
		string[]
	>([]);

	const {data: referenceData} = useGetAllReferencesQuery();

	const references = React.useMemo(
		() =>
			referenceData?.references
				?.filter(ref =>
					ref.referenceTypes.some(type => referenceTypes.includes(type)),
				)
				.map(r => {
					return {key: r.id, text: r.name};
				}) ?? [],
		[referenceData],
	);

	const {data: regulationData} = useGetReferencePickerRegulationsQuery();

	const regulations = React.useMemo(
		() =>
			(regulationData?.regulations ?? []).filter(
				r => !idsToExclude?.includes(r.id),
			),
		[regulationData],
	);

	const {data: regulatoryDocumentdata} =
		useGetReferencePickerRegulatoryDocumentsQuery();

	const regulatoryDocuments = React.useMemo(
		() =>
			(
				regulatoryDocumentdata?.regulatoryDocuments?.edges?.map(e => e.node) ??
				[]
			).filter(r => !idsToExclude?.includes(r.id)),
		[regulatoryDocumentdata],
	);

	const {data: requirementData} = useGetReferencePickerRequirementsQuery();

	const requirements: Requirement[] = React.useMemo(
		() =>
			(requirementData?.requirements ?? []).filter(
				r => !idsToExclude?.includes(r.id),
			),
		[requirementData],
	);

	const getOptions = (enumType: any) => {
		const enumValues = Object.values(enumType);
		return enumValues.map(value => ({
			key: value as string,
			text: t(value as string),
		}));
	};

	const updateReferenceType = (index: number, newType: string) => {
		const updatedFields = fields.map((field: FieldsArrayType, i) => {
			if (i === index) {
				return {
					...field,
					referenceDocumentType: newType,
					requirementReferences:
						newType === 'REQUIREMENT' ? field.requirementReferences : [],
					regulationReference:
						newType === 'REGULATION' ? field.regulationReference : [],
					regulatoryDocumentReference:
						newType === 'REGULATORY_DOCUMENT'
							? field.regulatoryDocumentReference
							: [],
				};
			}

			return field;
		});

		remove();
		updatedFields.forEach(field => append(field));
	};

	/**
	 * We use the document references translation provider here instead of relying
	 * on this component to be wrapped in a provider. We do this because a
	 * document reference is an entity, which means it should have its own
	 * translation file and provider.
	 */
	return (
		<>
			{fields.map((_item: any, index: any) => {
				return (
					<DocumentReferencesTooltipTranslationProvider
						treatAsDottedFormFieldName
						key={uuidv4()}
					>
						<ProviderThatEnablesGettingTooltipsFromContext>
							<Stack horizontal tokens={{childrenGap: 8}}>
								<Stack.Item grow styles={stackItemStyles}>
									<ControlledDropdown
										control={control}
										name={`${name}[${index}].referenceDocumentType`}
										label={t('ReferenceDocumentType')}
										options={getOptions(enumType)}
										rules={{required: t('RequiredField')}}
										onChange={(_, item) => {
											const newType = item?.key as string;
											const newSelectedDocumentTypes = [
												...selectedDocumentTypes,
											];
											newSelectedDocumentTypes[index] = newType;
											setSelectedDocumentTypes(newSelectedDocumentTypes);
											updateReferenceType(index, newType);
										}}
									/>
								</Stack.Item>
								<Stack.Item grow styles={stackItemStyles}>
									<ReferencesTooltipTranslationProvider
										treatAsDottedFormFieldName
									>
										<ControlledDropdown
											control={control}
											name={`${name}.[${index}].reference.id`}
											label={t('ReferenceType')}
											options={references}
											rules={{required: t('RequiredField')}}
										/>
									</ReferencesTooltipTranslationProvider>
								</Stack.Item>
								<Stack.Item>
									<TooltipHost content={t('ToolTipDelete')}>
										<IconButton
											styles={{
												root: {marginTop: 28},
											}}
											iconProps={{iconName: 'Delete'}}
											onClick={() => remove(index)}
											disabled={disabled}
										/>
									</TooltipHost>
								</Stack.Item>
							</Stack>
							<Stack.Item>
								{(_item.referenceDocumentType === 'REQUIREMENT' ||
									selectedDocumentTypes[index] === 'REQUIREMENT') && (
									<ControlledTagPicker
										control={control}
										label={t('Reference')}
										name={`${name}.[${index}].requirementReferences`}
										selectableItems={requirements}
										getKey={item => (item as any)?.id}
										getName={item => (item as any)?.name}
										rules={{required: t('RequiredField')}}
										itemLimit={1}
									/>
								)}
								{(_item.referenceDocumentType === 'REGULATION' ||
									selectedDocumentTypes[index] === 'REGULATION') && (
									<ControlledTagPicker
										control={control}
										label={t('Reference')}
										name={`${name}.[${index}].regulationReferences`}
										selectableItems={regulations}
										getKey={item => (item as any)?.id}
										getName={item => (item as any)?.regulationNumber}
										rules={{required: t('RequiredField')}}
										itemLimit={1}
									/>
								)}
								{(_item.referenceDocumentType === 'REGULATORY_DOCUMENT' ||
									selectedDocumentTypes[index] === 'REGULATORY_DOCUMENT') && (
									<ControlledTagPicker
										control={control}
										label={t('Reference')}
										name={`${name}.[${index}].regulatoryDocumentReferences`}
										selectableItems={regulatoryDocuments}
										getKey={item => (item as any)?.id}
										getName={item => (item as any)?.name}
										rules={{required: t('RequiredField')}}
										itemLimit={1}
									/>
								)}
							</Stack.Item>
							<Separator />
						</ProviderThatEnablesGettingTooltipsFromContext>
					</DocumentReferencesTooltipTranslationProvider>
				);
			})}
			<Stack>
				<Stack.Item>
					<AppendButton
						onClick={() => {
							append({});
						}}
						label={label}
						name={name}
						disabled={disabled}
					/>
				</Stack.Item>
			</Stack>
		</>
	);
};

const stackItemStyles: IStackItemStyles = {
	root: {
		width: 100,
	},
};
