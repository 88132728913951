import React from 'react';
import {IndicatorDot} from './IndicatorDot';
import {
	Checkbox,
	ICheckboxProps,
	Label,
	Theme,
	useTheme,
} from '@fluentui/react';
import {
	getClassNames,
	useTranslationsOfCompareVersionPanel,
} from '../CompareVersionPanel.utils';
import {CompareResult} from '../../compareDocuments/compareDocuments';

const checkboxStyles = {
	root: {
		marginLeft: 8,
	},
};

interface Props {
	resultType: CompareResult;
	onChangeCheckbox: Exclude<ICheckboxProps['onChange'], undefined>;
	labelTranslationKey: string;
}

export function ResultTypeFilterOption({
	resultType,
	onChangeCheckbox,
	labelTranslationKey,
}: Props): JSX.Element {
	const theme: Theme = useTheme();
	const classNames = getClassNames(theme);
	const {t} = useTranslationsOfCompareVersionPanel();

	return (
		<div className={classNames.statusBox}>
			<IndicatorDot resultType={resultType} />
			<Label>{t(labelTranslationKey)}</Label>
			<Checkbox
				defaultChecked={false}
				styles={checkboxStyles}
				onChange={onChangeCheckbox}
			/>
		</div>
	);
}
