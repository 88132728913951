import {createTooltipTranslationProviderFromNamespace} from 'features/localizedTooltips';
import {REG_DOC_TRANSLATION_NAMESPACE} from './regDocConstants';
import {useTooltipTranslation} from 'features/localizedTooltips/translationHooks';

export const RegDocsTooltipTranslationProvider =
	createTooltipTranslationProviderFromNamespace(REG_DOC_TRANSLATION_NAMESPACE);

export const useRegDocsTooltipText = () => {
	return useTooltipTranslation(REG_DOC_TRANSLATION_NAMESPACE);
};
