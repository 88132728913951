import React from 'react';
import {useTranslation} from 'react-i18next';
import {FullTextSearchPogisQuery} from '../hooks';
import {SearchResultList} from './SearchResultList';
import {PogisDocumentsTooltipTranslationProvider} from 'features/Archive/components/PogisDocumentTranslationTooltipProvider';
import {getPogisArchiveColumns} from 'features/Archive/hooks/UsePogisArchiveColumns';
import {EntityListColumn} from 'components';

export const PogisArchiveResultsList: React.FC<{
	pogisDocuments: FullTextSearchPogisQuery['searchPogisDocuments']['pogisDocuments'];
}> = ({pogisDocuments}) => {
	const {t} = useTranslation('features/pogisdocuments', {
		keyPrefix: 'PogisDocumentsList',
	});

	const columns = React.useMemo(
		() =>
			[...getPogisArchiveColumns(t)].filter(
				column => column.key !== 'docStatus',
			) as EntityListColumn[],
		[t],
	);

	return (
		<PogisDocumentsTooltipTranslationProvider>
			<SearchResultList items={pogisDocuments} columns={columns} />
		</PogisDocumentsTooltipTranslationProvider>
	);
};
