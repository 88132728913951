import React from 'react';
import {KeywordFields} from '../keywordItem.types';
import {ArrayValues} from 'components/EntityList/ColumnRenderers';
import {KeywordTooltipHeading} from './KeywordTooltipHeading';
import {useKeywordsTranslations} from '../keywords.commonHooks';
import {FieldWrapper} from './FieldWrapper';

interface Props {
	vexClusters: KeywordFields['vexClusters'];
}

export function VexClusters({vexClusters}: Props): JSX.Element {
	const {t} = useKeywordsTranslations();

	return (
		<div>
			<KeywordTooltipHeading>{t('VexClusters')}</KeywordTooltipHeading>
			<FieldWrapper list={vexClusters}>
				<ArrayValues array={vexClusters} limit={4} />
			</FieldWrapper>
		</div>
	);
}
