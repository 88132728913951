import React, {useMemo} from 'react';
import {v4 as uuidv4} from 'uuid';
import {ActiveTypeMarketsPageActiveTypeMarket as Market} from '../activeTypeMarketsPageTypes';
import {
	ActiveTypeMarketItemProps,
	ActiveTypeMarketCell,
} from './ActiveTypeMarketCell';
import {EntityList, EntityListColumn, getNameColumnFields} from 'components';
import {useActiveTypeMarketsPageTranslations} from '../activeTypeMarketsPage.hooks';
import {ActiveTypeMarketsTooltipTranslationProvider} from 'features/localizedTooltips';
import {IDetailsListProps, SelectionMode} from '@fluentui/react';
import {
	NavigateToActiveTypeMarketDetails,
	useNavigateToActiveTypeMarketDetailsPage,
} from './activeTypeMarketsList.hooks';

interface Props extends Pick<ActiveTypeMarketItemProps, 'params'> {
	markets: Market[] | undefined;
}

export const ActiveTypeMarketsList = ({markets = [], params}: Props) => {
	const {t} = useActiveTypeMarketsPageTranslations();
	const navigateToDetailsPage: NavigateToActiveTypeMarketDetails =
		useNavigateToActiveTypeMarketDetailsPage(params);

	const renderField: IDetailsListProps['onRenderField'] = (
		props,
		defaultRender,
	) => {
		return (
			<ActiveTypeMarketCell
				originalComponentProps={props}
				defaultRender={defaultRender}
				params={params}
				key={uuidv4()}
			/>
		);
	};

	const getColumns = (): EntityListColumn[] => {
		return [
			getNameColumnFields({
				name: t('ActiveTypeMarket'),
				isResizable: true,
			}),
		];
	};

	const columns: EntityListColumn[] = useMemo(getColumns, []);

	/**
	 * Implementation notes:
	 *
	 * onItemInvoked allows users to navigate to the details page.
	 */
	return (
		<ActiveTypeMarketsTooltipTranslationProvider>
			<EntityList
				items={markets}
				columns={columns}
				selectionMode={SelectionMode.none}
				onRenderField={renderField}
				onItemInvoked={navigateToDetailsPage}
				sticky={true}
			/>
		</ActiveTypeMarketsTooltipTranslationProvider>
	);
};
