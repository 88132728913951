import {
	Stack,
	FontIcon,
	Spinner,
	Theme,
	mergeStyleSets,
	useTheme,
} from '@fluentui/react';
import React from 'react';
import {useTranslation} from 'react-i18next';

export const SplitDocumentLegend: React.FC = () => {
	const {t} = useTranslation('features/regulatorydocuments', {
		keyPrefix: 'SplitDocumentLegend',
	});

	const theme = useTheme();
	const classNames = getClassNames(theme);

	return (
		<Stack>
			<Stack
				horizontal
				styles={{root: {padding: 5}}}
				tokens={{childrenGap: 10}}
			>
				<Stack.Item>
					<FontIcon
						aria-label='Page'
						iconName='PDF'
						className={classNames.fileIconHeaderIconNotSplit}
					/>
				</Stack.Item>
				<Stack.Item>
					<div>{t('DocumentNotDismantled')}</div>
				</Stack.Item>
			</Stack>
			<Stack horizontal styles={{root: {padding: 5}}} tokens={{childrenGap: 6}}>
				<Stack.Item>
					<Spinner />
				</Stack.Item>
				<Stack.Item>
					<div>{t('DocumentIsDismantling')}</div>
				</Stack.Item>
			</Stack>
			<Stack
				horizontal
				styles={{root: {padding: 5}}}
				tokens={{childrenGap: 10}}
			>
				<Stack.Item>
					<FontIcon
						aria-label='Page'
						iconName='PDF'
						className={classNames.fileIconHeaderIcon}
					/>
				</Stack.Item>
				<Stack.Item>{t('DocumentIsDismantled')}</Stack.Item>
			</Stack>
			<Stack
				horizontal
				styles={{root: {padding: 5}}}
				tokens={{childrenGap: 10}}
			>
				<Stack.Item>
					<FontIcon
						aria-label='Page'
						iconName='StatusErrorFull'
						className={classNames.fileIconHeaderIconNotSplit}
					/>
				</Stack.Item>
				<Stack.Item>{t('DocumentError')}</Stack.Item>
			</Stack>
		</Stack>
	);
};

const getClassNames = (theme: Theme) =>
	mergeStyleSets({
		fileIconHeaderIcon: {
			padding: 0,
			fontSize: '16px',
			color: theme.palette.neutralPrimary,
		},
		fileIconHeaderIconNotSplit: {
			padding: 0,
			fontSize: '16px',
			color: theme.palette.red,
		},
	});
