import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import { ParagraphFieldsOfUpdateResponseFragmentDoc } from '../../../fragments/paragraphFieldsOfUpdateResponse.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateRegulatoryDocumentParagraphMutationVariables = Types.Exact<{
  input: Types.UpdateRegulatoryDocumentParagraphInput;
}>;


export type UpdateRegulatoryDocumentParagraphMutation = { __typename?: 'Mutation', updateRegulatoryDocumentParagraph: { __typename?: 'UpdateRegulatoryDocumentParagraphPayload', regulatoryDocument?: { __typename?: 'RegulatoryDocument', id: string, paragraphs: Array<{ __typename?: 'RegulatoryDocumentParagraph', id: string, comprehensive?: any | null, dateNewRegistration?: any | null, dateNewTypes?: any | null, modelYear?: number | null, summary?: string | null, categories: Array<{ __typename?: 'Category', id: string, name: string }>, driveVariants: Array<{ __typename?: 'DriveVariant', id: string, name: string }>, keywords: Array<{ __typename?: 'Keyword', id: string, name: string }>, phaseIn?: Array<{ __typename?: 'PhaseDate', date?: any | null, status?: string | null }> | null, phaseOut?: Array<{ __typename?: 'PhaseDate', date?: any | null, status?: string | null }> | null, tags: Array<{ __typename?: 'Tag', id: string, name: string }>, vehicleCategories: Array<{ __typename?: 'VehicleCategory', id: string, name: string }>, keywordAssignments?: Array<{ __typename?: 'KeywordAssignment', keyword: { __typename?: 'Keyword', id: string, name: string }, vexClusterAssignments: Array<{ __typename?: 'VexClusterAssignment', keywordStatus: Types.KeywordStatus, vexCluster: { __typename?: 'VexCluster', id: string, name: string } }> }> | null }> } | null } };


export const UpdateRegulatoryDocumentParagraphDocument = gql`
    mutation UpdateRegulatoryDocumentParagraph($input: UpdateRegulatoryDocumentParagraphInput!) {
  updateRegulatoryDocumentParagraph(input: $input) {
    regulatoryDocument {
      id
      paragraphs {
        ...ParagraphFieldsOfUpdateResponse
      }
    }
  }
}
    ${ParagraphFieldsOfUpdateResponseFragmentDoc}`;
export type UpdateRegulatoryDocumentParagraphMutationFn = Apollo.MutationFunction<UpdateRegulatoryDocumentParagraphMutation, UpdateRegulatoryDocumentParagraphMutationVariables>;

/**
 * __useUpdateRegulatoryDocumentParagraphMutation__
 *
 * To run a mutation, you first call `useUpdateRegulatoryDocumentParagraphMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRegulatoryDocumentParagraphMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRegulatoryDocumentParagraphMutation, { data, loading, error }] = useUpdateRegulatoryDocumentParagraphMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRegulatoryDocumentParagraphMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRegulatoryDocumentParagraphMutation, UpdateRegulatoryDocumentParagraphMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRegulatoryDocumentParagraphMutation, UpdateRegulatoryDocumentParagraphMutationVariables>(UpdateRegulatoryDocumentParagraphDocument, options);
      }
export type UpdateRegulatoryDocumentParagraphMutationHookResult = ReturnType<typeof useUpdateRegulatoryDocumentParagraphMutation>;
export type UpdateRegulatoryDocumentParagraphMutationResult = Apollo.MutationResult<UpdateRegulatoryDocumentParagraphMutation>;
export type UpdateRegulatoryDocumentParagraphMutationOptions = Apollo.BaseMutationOptions<UpdateRegulatoryDocumentParagraphMutation, UpdateRegulatoryDocumentParagraphMutationVariables>;