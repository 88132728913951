import React, {useCallback, useMemo} from 'react';
import {CommandButton, IButtonStyles, IIconProps} from '@fluentui/react';
import {useRegDocDetailsPageTranslations} from 'features/RegulatoryDocuments/RegDocDetailsPage/RegDocDetailsPage.commonHooks';
import {
	DocOfRegDocDetailsPageQuery,
	QueryParagraph,
} from 'features/RegulatoryDocuments/RegDocDetailsPage/RegDocDetailsPage.queryTypes';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import {Regulation} from 'types';
import {detailsSectionMargin} from 'components';

export interface ViewButtonProps {
	regulatoryDocument: DocOfRegDocDetailsPageQuery;
	paragraph: QueryParagraph;
}

export function ViewButton({
	regulatoryDocument,
	paragraph,
}: ViewButtonProps): JSX.Element {
	const navigate: NavigateFunction = useNavigate();
	const {t} = useRegDocDetailsPageTranslations();

	const openDetailsPage = useCallback(
		(regId: Regulation['id']): void => {
			navigate(
				`/regulations/${regId}/${regulatoryDocument.id}/paragraphs/${paragraph.id}`,
			);
		},
		[navigate, regulatoryDocument, paragraph],
	);

	const checkRegExistsAndOpenDetailsPage = useCallback((): void => {
		const {regulation} = regulatoryDocument;
		if (regulation) return openDetailsPage(regulation.id);
		throw new Error('Reg doc does not have a regulation.');
	}, [openDetailsPage, regulatoryDocument]);

	const styles = useMemo((): IButtonStyles => {
		/**
		 * We set the margin left so it is aligned with the details section.
		 */
		return {root: {marginLeft: detailsSectionMargin - 6}};
	}, []);

	const iconProps = useMemo((): IIconProps => {
		return {iconName: 'View'};
	}, []);

	return (
		<CommandButton
			iconProps={iconProps}
			onClick={checkRegExistsAndOpenDetailsPage}
			styles={styles}
		>
			{t('View')}
		</CommandButton>
	);
}
