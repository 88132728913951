import React, {useMemo} from 'react';
import {EntityList, EntityListColumn} from 'components';
import {useRequirementColumns} from 'features/Requirements/hooks/useRequirementColumns';
import {SelectionMode} from '@fluentui/react';
import {Baseline} from './BaselineDetailsPage.types';

interface Props {
	baseline: Baseline;
}

export function RequirementsList({baseline}: Props): JSX.Element {
	type BaselineRequirement = Baseline['requirements'][number];
	type Requirement = NonNullable<BaselineRequirement['requirement']>;

	const requirements = useMemo((): Requirement[] => {
		const addRequirement = (
			requirements: Requirement[],
			baselineRequirement: BaselineRequirement,
		): Requirement[] => {
			if (baselineRequirement.requirement)
				return [...requirements, baselineRequirement.requirement];
			return requirements;
		};

		return baseline.requirements.reduce(addRequirement, []);
	}, [baseline]);

	const requirementColumns: EntityListColumn[] = useRequirementColumns();

	return (
		<EntityList
			columns={requirementColumns}
			items={requirements}
			selectionMode={SelectionMode.none}
			sticky
		/>
	);
}
