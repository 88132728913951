import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReportsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ReportsQuery = { __typename?: 'Query', regulatoryDocuments?: { __typename?: 'RegulatoryDocumentsConnection', nodes?: Array<{ __typename?: 'RegulatoryDocument', id: string, name: string, status: Types.RegulatoryDocumentStatus, dateEffective?: any | null, modelYear?: number | null, summary?: string | null, dateNewRegistration?: any | null, dateNewTypes?: any | null, createdAt?: any | null, createdBy: { __typename?: 'User', name: string }, regulation?: { __typename?: 'Regulation', id: string, summary: string, regulationNumber: string, mainKeywords: Array<{ __typename?: 'Keyword', name: string }>, markets: Array<{ __typename?: 'Market', name: string }> } | null }> | null } | null };


export const ReportsDocument = gql`
    query Reports {
  regulatoryDocuments {
    nodes {
      id
      name
      status
      dateEffective
      modelYear
      summary
      dateNewRegistration
      dateNewTypes
      createdAt
      createdBy {
        name
      }
      regulation {
        id
        summary
        regulationNumber
        mainKeywords {
          name
        }
        markets {
          name
        }
      }
    }
  }
}
    `;

/**
 * __useReportsQuery__
 *
 * To run a query within a React component, call `useReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReportsQuery(baseOptions?: Apollo.QueryHookOptions<ReportsQuery, ReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportsQuery, ReportsQueryVariables>(ReportsDocument, options);
      }
export function useReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportsQuery, ReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportsQuery, ReportsQueryVariables>(ReportsDocument, options);
        }
export type ReportsQueryHookResult = ReturnType<typeof useReportsQuery>;
export type ReportsLazyQueryHookResult = ReturnType<typeof useReportsLazyQuery>;
export type ReportsQueryResult = Apollo.QueryResult<ReportsQuery, ReportsQueryVariables>;