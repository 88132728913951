import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetVehicleProjectFormDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetVehicleProjectFormDataQuery = { __typename?: 'Query', requirements: Array<{ __typename?: 'Requirement', id: string, name: string }>, activeTypeMarkets?: Array<{ __typename?: 'ActiveTypeMarket', id: string, name: string }> | null, driveVariants?: Array<{ __typename?: 'DriveVariant', id: string, name: string }> | null, engineVariants?: Array<{ __typename?: 'EngineVariant', id: string, name: string }> | null, bodyworkVariants?: Array<{ __typename?: 'BodyworkVariant', id: string, name: string }> | null, gearboxVariants?: Array<{ __typename?: 'GearboxVariant', id: string, name: string }> | null, markets?: Array<{ __typename?: 'Market', id: string, name: string }> | null, countries?: Array<{ __typename?: 'Country', id: string, name: string }> | null };


export const GetVehicleProjectFormDataDocument = gql`
    query GetVehicleProjectFormData {
  requirements {
    id
    name
  }
  activeTypeMarkets {
    id
    name
  }
  driveVariants {
    id
    name
  }
  engineVariants {
    id
    name
  }
  bodyworkVariants {
    id
    name
  }
  gearboxVariants {
    id
    name
  }
  markets {
    id
    name
  }
  countries {
    id
    name
  }
}
    `;

/**
 * __useGetVehicleProjectFormDataQuery__
 *
 * To run a query within a React component, call `useGetVehicleProjectFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleProjectFormDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleProjectFormDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVehicleProjectFormDataQuery(baseOptions?: Apollo.QueryHookOptions<GetVehicleProjectFormDataQuery, GetVehicleProjectFormDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVehicleProjectFormDataQuery, GetVehicleProjectFormDataQueryVariables>(GetVehicleProjectFormDataDocument, options);
      }
export function useGetVehicleProjectFormDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVehicleProjectFormDataQuery, GetVehicleProjectFormDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVehicleProjectFormDataQuery, GetVehicleProjectFormDataQueryVariables>(GetVehicleProjectFormDataDocument, options);
        }
export type GetVehicleProjectFormDataQueryHookResult = ReturnType<typeof useGetVehicleProjectFormDataQuery>;
export type GetVehicleProjectFormDataLazyQueryHookResult = ReturnType<typeof useGetVehicleProjectFormDataLazyQuery>;
export type GetVehicleProjectFormDataQueryResult = Apollo.QueryResult<GetVehicleProjectFormDataQuery, GetVehicleProjectFormDataQueryVariables>;