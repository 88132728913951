import {
	createLocalizedTooltipsNamespace,
	createTooltipTranslationProviderFromNamespace,
} from 'features/localizedTooltips';

const ADMIN_REGULATIONS_CLUSTERS_PAGE_TRANSLATION_NAMESPACE =
	createLocalizedTooltipsNamespace('regulationClusters');

export const AdminRegulationsClustersPageTooltipTranslationProvider =
	createTooltipTranslationProviderFromNamespace(
		ADMIN_REGULATIONS_CLUSTERS_PAGE_TRANSLATION_NAMESPACE,
	);
