import React, {ReactNode} from 'react';
import {mergeStyles, useTheme} from '@fluentui/react';

export type PillProps = {
	children: ReactNode;
	className?: string;
};

export const Pill = ({children, className}: PillProps) => {
	const theme = useTheme();
	const styles = [
		mergeStyles({
			borderRadius: '10px',
			color: theme?.palette.neutralLighter,
			background: theme?.palette.neutralTertiary,
			display: 'inline-flex',
			alignItems: 'center',
			lineHeight: 0,
			fontSize: 12,
			padding: '2px 7px',
			marginRight: 5,
			marginBottom: 10,
			whiteSpace: 'nowrap',
		}),
		className,
	];

	return <span className={styles.join(' ').trimEnd()}>{children}</span>;
};
