import {Baseline} from 'types';
import {
	FieldsToCreatePathOfBaselineDetailsPage,
	createPathOfBaselineDetailsPage,
} from '../BaselineDetailsPage/BaselineDetailsPage.utils';
import {createNamespaceForVehicleProjects} from '../hooks/vehicleProjectsTranslationHooks';

export interface FieldsToCreatePathOfBaselineComparisonPage
	extends FieldsToCreatePathOfBaselineDetailsPage {
	idOfBaselineToCompareTo: Baseline['id'];
}

export const createPathOfBaselinesComparisonPage = ({
	idOfBaselineToCompareTo,
	...other
}: FieldsToCreatePathOfBaselineComparisonPage): string => {
	const pathOfDetailsPage: string = createPathOfBaselineDetailsPage(other);
	return `${pathOfDetailsPage}/compare/${idOfBaselineToCompareTo}`;
};

export const NAMESPACE_OF_BASELINE_CHANGES: string =
	createNamespaceForVehicleProjects('baselineChanges');
