import React from 'react';
import {
	MessageInfo,
	NotificationContext,
	NotificationContextType,
} from 'context';
import {v4 as uuidv4} from 'uuid';
import {MessageBarType} from '@fluentui/react';

export const NotificationProvider: React.FC<{
	children: React.ReactNode;
}> = ({children}) => {
	const [message, setMsg] =
		React.useState<NotificationContextType['message']>(undefined);
	const [messageBarType, setMessageBarType] = React.useState<
		MessageBarType | undefined
	>(undefined);
	const [onShowMoreClick, setOnShowMoreClick] = React.useState<
		(() => void) | undefined
	>(undefined);
	const [messageId, setMsgId] =
		React.useState<NotificationContextType['messageId']>(undefined);

	const setMessage = React.useCallback(
		(message: NotificationContextType['message']) => {
			setMsg(message);
			setMsgId(uuidv4());
			setMessageBarType((message as MessageInfo)?.options?.messageBarType);
			setOnShowMoreClick(
				() => (message as MessageInfo)?.options?.onShowMoreClick,
			);
		},
		[setMsg, setMsgId],
	);

	const notificationContextValue = React.useMemo(
		() => ({
			message,
			messageId,
			messageBarType,
			onShowMoreClick,
			setMessage,
		}),
		[message, messageId, messageBarType, onShowMoreClick, setMessage],
	);

	return (
		<NotificationContext.Provider value={notificationContextValue}>
			{children}
		</NotificationContext.Provider>
	);
};
