import {RegulatoryDocumentParagraph} from 'types';
import {
	OptimisticParagraph,
	FieldsOfParagraphWithAttachmentsService,
	ParagraphWithAttachmentsService,
	OptimisticAttachment,
} from './ParagraphWithAttachments.service';
import {
	FieldsFromMutationKeyUtilsForMutationKeyService as BaseFieldsFromMutationKeyUtilsForMutationKeyService,
	MutationKeyOptimisticData as BaseMutationKeyOptimisticData,
	MutationKeyOptimisticDataUtils,
} from '../MutationKeyOptimisticData.utils';
import {QueryParagraph} from 'features/RegulatoryDocuments/RegDocDetailsPage/RegDocDetailsPage.queryTypes';

/**
 * * Service's fields
 */
type FieldsFromParagraphService = Omit<
	FieldsOfParagraphWithAttachmentsService,
	'paragraphId'
>;

interface FieldsFromMutationKeyUtils
	extends BaseFieldsFromMutationKeyUtilsForMutationKeyService<OptimisticParagraph> {
	paragraphs: QueryParagraph[];
}

type Fields = FieldsFromMutationKeyUtils & FieldsFromParagraphService;

/**
 * * Other
 */
export type AttachmentsMutationKeyOptimisticData =
	BaseMutationKeyOptimisticData<OptimisticParagraph>;

type QueryAttachments = QueryParagraph['attachments'];
type QueryAttachment = NonNullable<QueryAttachments>[number];

export class MutationKeyOptimisticDataService {
	// eslint-disable-next-line no-useless-constructor
	constructor(private fields: Fields) {}

	private createParagraphFromId = (
		id: RegulatoryDocumentParagraph['id'],
	): OptimisticParagraph => {
		return ParagraphWithAttachmentsService.createParagraph({
			paragraphId: id,
			...this.fields,
		});
	};

	private convertQueryAttachmentCategoryToOptimisticOne = (
		category: QueryAttachment['category'],
	): OptimisticAttachment['category'] => {
		if (category) return {...category, __typename: 'AttachmentCategory'};
		return null;
	};

	private convertQueryAttachmentToOptimisticOne = ({
		isVkoOnly,
		category,
		...other
	}: QueryAttachment): OptimisticAttachment => {
		return {
			__typename: 'AttachmentRef',
			isVkoOnly: isVkoOnly ?? null,
			category: this.convertQueryAttachmentCategoryToOptimisticOne(category),
			...other,
		};
	};

	private convertQueryAttachmentsToOptimisticOnes = (
		attachments: QueryAttachments,
	): OptimisticParagraph['attachments'] => {
		if (!attachments) return [];
		return attachments.map(this.convertQueryAttachmentToOptimisticOne);
	};

	private convertQueryParagraphToMutationOne = ({
		attachments,
		...other
	}: QueryParagraph): OptimisticParagraph => {
		return {
			__typename: 'RegulatoryDocumentParagraph',
			attachments: this.convertQueryAttachmentsToOptimisticOnes(attachments),
			...other,
		};
	};

	private convertQueryParagraphsToMutationOnes = (): OptimisticParagraph[] => {
		return this.fields.paragraphs.map(this.convertQueryParagraphToMutationOne);
	};

	private createMutationKeyOptimisticData =
		(): AttachmentsMutationKeyOptimisticData => {
			return MutationKeyOptimisticDataUtils.createMutationKeyOptimisticData({
				...this.fields,
				createParagraph: this.createParagraphFromId,
				paragraphs: this.convertQueryParagraphsToMutationOnes(),
			});
		};

	static createMutationKeyOptimisticData = (
		fields: Fields,
	): AttachmentsMutationKeyOptimisticData => {
		const service = new MutationKeyOptimisticDataService(fields);
		return service.createMutationKeyOptimisticData();
	};
}
