import {
	DocumentReference,
	Maybe,
	Origin,
	RegulatoryDocument,
	RegulatoryDocumentParagraph,
	WorkflowStatus,
} from 'types';

import _ from 'lodash';
import {TFunction} from 'i18next';
import {BasicDocFields} from './components/RegulatoryDocumentsForm/RegulatoryDocumentsForm.types';
import {KEY_FOR_NO_SELECTION} from './components/RegulatoryDocumentsForm/PredecessorOrSuccessor';
import {convertToRef, PossibleRef} from 'helpers';
import {DocumentSourceEnum} from 'features/Regulations/RegulationDetail/RegulationDetail.types';

type PredecessorOrSuccessor = RegulatoryDocument['predecessor' | 'successor'];

// For existing child regulation ( Modified), parent (Finalized) can't be edited.
// Modified regulation can be edited only by author of changed regulation or reapprovingVKO

export const couldEditRegulationWithModifiedVersions = (
	regulatory: Partial<RegulatoryDocument> | any,
	encodedUserId: string,
) => {
	const status = regulatory?.workflow?.status;
	const modifiedBy = regulatory?.modifiedBy?.id;
	const createdBy = regulatory?.createdBy?.id;

	const couldEdit =
		(status === WorkflowStatus.Modified &&
			(encodedUserId !== createdBy || encodedUserId !== modifiedBy)) ||
		(status === WorkflowStatus.Finalized &&
			regulatory?.childrenRefs?.length !== 0);

	return couldEdit;
};

export const isPogisDocument = (
	regulatory: Partial<RegulatoryDocument> | any,
): boolean => {
	return (
		regulatory?.documentSource?.name?.toLowerCase() ===
		DocumentSourceEnum.Pogis.toLowerCase()
	);
};

export const getDisabledEditionForMOdifiedVersionTooltip = (
	regulatory: Partial<RegulatoryDocument> | any,
	t: TFunction,
) => {
	return regulatory?.workflow?.status === WorkflowStatus.Finalized &&
		regulatory?.childrenRefs?.length !== 0
		? t('DisabledEditModifiedExist')
		: '';
};

export const getCorrectStatusName = (
	regulatoryDocumentDetails: any,
	t: TFunction,
	getWorkflowStatus: any,
) => {
	const status = regulatoryDocumentDetails?.workflow?.status;
	if (status !== WorkflowStatus.Modified) {
		return getWorkflowStatus(regulatoryDocumentDetails?.workflow?.status);
	}

	if (
		isApprovalModified(regulatoryDocumentDetails?.workflow?.reapprovingVKO?.id)
	) {
		return getWorkflowStatus('APPROVING_MODIFIED');
	}

	return regulatoryDocumentDetails?.workflow?.message
		? `${getWorkflowStatus(regulatoryDocumentDetails?.workflow?.status)} (${t(
				'Message',
		  )}: ${regulatoryDocumentDetails?.workflow?.message})`
		: getWorkflowStatus(regulatoryDocumentDetails?.workflow?.status);
};

export const isApprovalModified = (reapproverId: string) => {
	if (reapproverId) {
		const id = window.atob(reapproverId);
		return id !== 'User\nd00000000-0000-0000-0000-000000000000';
	}

	return false;
};

export const getValueForPredecessorOrSuccessor = (
	optionForPredecessorOrSuccessor: BasicDocFields | PredecessorOrSuccessor,
): PredecessorOrSuccessor | PossibleRef => {
	if (optionForPredecessorOrSuccessor?.id === KEY_FOR_NO_SELECTION) return null;
	return convertToRef(optionForPredecessorOrSuccessor);
};

export const mapDocumentReferences = (
	documentReferences?: Maybe<DocumentReference[]>,
): any[] => {
	if (!documentReferences) {
		return [];
	}

	return documentReferences.map((r: any) => {
		const isRegulation = r.referenceDocumentType === 'REGULATION';
		const isRegulatoryDocument =
			r.referenceDocumentType === 'REGULATORY_DOCUMENT';

		return {
			referenceDocumentType: r.referenceDocumentType,
			regulationReferences: isRegulation
				? r.regulationReferences?.map((regRef: any) => ({
						id: regRef.id,
				  }))
				: null,
			regulatoryDocumentReferences: isRegulatoryDocument
				? r.regulatoryDocumentReferences?.map((regDocRef: any) => ({
						id: regDocRef.id,
				  }))
				: null,
			reference: {id: r.reference.id},
		};
	});
};

export const getContentFinalizedInput = (
	data: any,
	regulatoryDocument: any,
	regulationId: string,
	showTopicManagementSummaryState: boolean,
	isVko: boolean,
	rule: any,
) => {
	return {
		summary: data.summary,
		regulationRef: regulationId ? {id: regulationId} : undefined,
		name: data.name,
		status: data.status,
		topicManagementSummary:
			data.topicManagementSummary?.quarter &&
			data.topicManagementSummary?.year &&
			showTopicManagementSummaryState
				? {
						quarter: data.topicManagementSummary?.quarter,
						year: data.topicManagementSummary?.year,
				  }
				: null,
		predecessorRef: getValueForPredecessorOrSuccessor(data.predecessor),
		successorRef: getValueForPredecessorOrSuccessor(data.successor),
		finalVersionRef: convertToRef(regulatoryDocument),
		documentSourceRef: convertToRef(data.documentSource),
		origin: isVko ? Origin.Internal : Origin.External,
		workflow: {
			status: WorkflowStatus.Modified,
		},
		dateEffective: data.dateEffective,
		dateExpiration: data.dateExpiration,
		willBeAnonymized: rule?.anonymize || false,
		deletionPeriod: rule?.deletionPeriod || 0,
		documentReferences: mapDocumentReferences(data.documentReferences),
	};
};
