import {ITextProps, ITextStyles, Text} from '@fluentui/react';
import React, {useMemo} from 'react';

export const GroupHeading = ({
	children,
}: Pick<ITextProps, 'children'>): JSX.Element => {
	const styles = useMemo((): ITextStyles => {
		return {
			root: {
				fontWeight: 'bold',
				padding: '0 0.75rem',
				margin: '1rem 0 0.4375rem',
			},
		};
	}, []);

	// Block and no wrap are enabled to allow ellipsis to show
	return (
		<Text as='h3' variant='medium' block nowrap styles={styles}>
			{children}
		</Text>
	);
};
