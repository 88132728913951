import React from 'react';
import {
	TooltipHost,
	ITooltipProps,
	TooltipDelay,
	DirectionalHint,
	Icon,
} from '@fluentui/react';

export const ItemSummaryTooltip = ({id, summary}: ItemSummaryTooltipProps) => {
	if (!summary) return null;

	const tooltipProps: ITooltipProps = {
		onRenderContent: () => <div dangerouslySetInnerHTML={{__html: summary}} />,
	};

	return (
		<TooltipHost
			tooltipProps={tooltipProps}
			delay={TooltipDelay.zero}
			id={id}
			directionalHint={DirectionalHint.rightCenter}
		>
			<Icon iconName='InfoSolid' />
		</TooltipHost>
	);
};

export type ItemSummaryTooltipProps = {
	id: string;
	summary?: string;
};
