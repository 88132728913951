import React, {useCallback, useMemo, useState} from 'react';
import {BaselineDetailsPageParams} from '../../BaselineDetailsPage.types';
import {PossibleSelectedBaseline} from '../BaselinesSelectionDialog.types';
import {
	DirectionalHint,
	IBasePickerSuggestionsProps,
	ICalloutProps,
	ITag,
	Label,
	TagPicker,
} from '@fluentui/react';
import {useTranslationsOfBaselinesSelectionDialog} from '../BaselinesSelectionDialog.utils';
import _, {ListIterateeCustom} from 'lodash';
import {BaselineTag} from './BaselineTag';
import {BaselineSuggestion} from './BaselineSuggestion';
import {useGetSearchBaselineLazyQuery} from '../../../hooks/baseline.generated';

interface Props {
	possibleSelectedBaseline: PossibleSelectedBaseline | null;
	params: BaselineDetailsPageParams;
	setBaseline: React.Dispatch<
		React.SetStateAction<PossibleSelectedBaseline | null>
	>;
}

export function BaselinesPicker({
	setBaseline,
	possibleSelectedBaseline,
}: Props): JSX.Element {
	const {t} = useTranslationsOfBaselinesSelectionDialog();

	const [projectName, setProjectName] = useState<string>('');
	const [baselines, setBaselines] = useState<Array<PossibleSelectedBaseline>>(
		[],
	);

	const [getSearchBaseline] = useGetSearchBaselineLazyQuery({
		variables: {projectName},
		fetchPolicy: 'no-cache',
	});

	const convertBaselineToTag = useCallback(
		({id, name}: PossibleSelectedBaseline): ITag => {
			return {key: id, name};
		},
		[],
	);

	const selectedItems =
		possibleSelectedBaseline === null
			? new Array<ITag>()
			: [convertBaselineToTag(possibleSelectedBaseline)];

	const resolveSuggestions = useCallback(
		async (projectNameX: string): Promise<ITag[]> => {
			const newResults = await getSearchBaseline({
				variables: {projectName: projectNameX},
			});

			const newBaselines =
				newResults === undefined ? [] : newResults.data?.searchBaseline ?? [];
			setBaselines(newBaselines);

			return newBaselines.map(convertBaselineToTag);
		},
		[getSearchBaseline, convertBaselineToTag],
	);

	const suggestionProps = useMemo((): IBasePickerSuggestionsProps => {
		return {
			noResultsFoundText: t('TextWhenNoBaselinesFound'),
		};
	}, [t]);

	const handleChange = useCallback(
		(tags?: ITag[]): void => {
			const findBaselineByKey = (
				tags: ITag[],
			): PossibleSelectedBaseline | undefined => {
				const predicate: ListIterateeCustom<PossibleSelectedBaseline, boolean> =
					{
						/**
						 * We get the first tag because the array will only have 1.
						 */
						id: tags[0].key.toString(),
					};
				return _.find(baselines, predicate);
			};

			const setNewBaseline = (tags: ITag[]): void => {
				const baseline: PossibleSelectedBaseline | undefined =
					findBaselineByKey(tags);
				if (baseline) return setBaseline(baseline);
				throw new Error('Baseline not found.');
			};

			if (tags?.length) return setNewBaseline(tags);
			setBaseline(null);
		},
		[setBaseline, baselines],
	);

	const onEmptyResolveSuggestions = useCallback(async (): Promise<ITag[]> => {
		const newResults = await getSearchBaseline({
			variables: {projectName: ''},
		});

		const newBaselines =
			newResults === undefined ? [] : newResults.data?.searchBaseline ?? [];
		setBaselines(newBaselines);

		return newBaselines.map(convertBaselineToTag);
	}, [convertBaselineToTag, getSearchBaseline]);

	const pickerCalloutProps = useMemo((): ICalloutProps => {
		return {
			directionalHint: DirectionalHint.bottomAutoEdge,
			/**
			 * If this is not provided, the callout might show on top anyways.
			 */
			directionalHintFixed: true,
		};
	}, []);

	return (
		<div>
			<Label>
				{t('Label')}
				<TagPicker
					itemLimit={1}
					selectedItems={selectedItems}
					onResolveSuggestions={resolveSuggestions}
					pickerSuggestionsProps={suggestionProps}
					selectionAriaLabel={t('LabelForSelectedBaselines')}
					removeButtonAriaLabel={t('LabelForRemoveBtn')}
					onChange={handleChange}
					onRenderItem={BaselineTag}
					onEmptyResolveSuggestions={onEmptyResolveSuggestions}
					onRenderSuggestionsItem={BaselineSuggestion}
					pickerCalloutProps={pickerCalloutProps}
				/>
			</Label>
		</div>
	);
}
