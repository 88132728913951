import React from 'react';
import {mergeStyles} from '@fluentui/react';
import {EntityListColumn as Column} from 'components';
import {
	renderParagraph,
	renderRequirements,
	renderArrayField,
	renderPhase,
} from 'components/EntityList/ColumnRenderers';
import {KeywordFields} from 'components/Keywords/keywordItem.types';
import {getDateColumn} from 'helpers';
import {DateTimeFormat, formatDateTime} from 'i18n/localeDateFormat';
import i18next from 'i18next';
import {TFunction} from 'react-i18next';
import {Keyword, PhaseDate} from 'types';
import {ParagraphListKeywords} from './ParagraphListKeywords';

export const getCreateParagraphFieldWithDefaults = (filterable: boolean) => {
	return (fields: Column): Column => {
		return {
			isMultiline: true,
			isResizable: true,
			filterable,
			...fields,
		};
	};
};

interface ParagraphListItem {
	keywords: KeywordFields[];
}

export const getParagraphListBaseColumns = (
	t: TFunction,
	filterable = true,
	myKeywords?: Keyword[] | undefined,
): Column[] => {
	const paragraphClassName = mergeStyles({
		paddingTop: '0px !important',
		paddingBottom: '0px !important',
		height: '100% !important',
	});

	const getExtraFieldsForColumns = (): Column[] => {
		return [
			{
				key: 'p-paragraph',
				name: t('Paragraph'),
				fieldName: 'name',
				minWidth: 400,
				className: paragraphClassName,
				filterable: false,
				onRender: item => renderParagraph(item, t),
			},
			{
				key: 'p-categories',
				name: t('Categories'),
				fieldName: 'categories',
				minWidth: 100,
				onRender: renderArrayField(),
			},
			{
				key: 'p-keywords',
				name: t('Keywords'),
				fieldName: 'keywords',
				minWidth: 100,
				onRender(item: ParagraphListItem): JSX.Element {
					return (
						<ParagraphListKeywords
							myKeywords={myKeywords}
							paragraphKeywords={item.keywords}
						/>
					);
				},
			},
			{
				key: 'p-requirements',
				name: t('Requirements'),
				fieldName: 'requirements',
				minWidth: 100,
				onRender: renderRequirements(),
			},
			{
				key: 'p-tags',
				name: t('Tags'),
				fieldName: 'tags',
				minWidth: 150,
				onRender: renderArrayField(),
			},
			{
				key: 'p-vehicle-category',
				name: t('VehicleCategory'),
				fieldName: 'vehicleCategories',
				minWidth: 100,
				onRender: renderArrayField(),
			},
			{
				key: 'p-drive-variants',
				name: t('DriveVariants'),
				fieldName: 'driveVariants',
				minWidth: 100,
				onRender: renderArrayField(),
			},
			getDateColumn(
				'p-dateNewTypes',
				t('DateNewTypes'),
				'dateNewTypes',
				filterable,
				true,
				100,
			),
			getDateColumn(
				'p-dateNewRegistration',
				t('DateNewRegistration'),
				'dateNewRegistration',
				filterable,
				true,
				100,
			),
			getDateColumn(
				'p-comprehensive',
				t('Comprehensive'),
				'comprehensive',
				filterable,
				true,
				100,
			),
			{
				key: 'p-modelYear',
				name: t('ModelYear'),
				fieldName: 'modelYear',
				minWidth: 100,
			},
			{
				key: 'p-phaseIn',
				name: t('PhaseIn'),
				fieldName: 'phaseIn',
				minWidth: 200,
				getFilterKey: getPhaseFilterKey,
				getFilterLabel: getPhaseFilterLabel,
				onRender: renderPhase(),
			},
			{
				key: 'p-phaseOut',
				name: t('PhaseOut'),
				fieldName: 'phaseOut',
				minWidth: 200,
				getFilterKey: getPhaseFilterKey,
				getFilterLabel: getPhaseFilterLabel,
				onRender: renderPhase(),
			},
		];
	};

	const getColumns = (): Column[] => {
		const extraFields: Column[] = getExtraFieldsForColumns();
		return extraFields.map(getCreateParagraphFieldWithDefaults(filterable));
	};

	return getColumns();
};

function getPhaseFilterKey(val: PhaseDate) {
	return `${val.date} ${val.status}`;
}

function getPhaseFilterLabel(val: PhaseDate) {
	return `${formatDateTime(
		new Date(val.date),
		i18next,
		DateTimeFormat.DateShort,
	)} (${val.status})`;
}
