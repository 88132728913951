import {EntityPage} from 'components';
import {ViewAuthorizer} from 'components/ViewAuthorizer';
import {LoadWrapper} from 'components/LoadWrapper';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {BodyworkVariant, UserRole} from 'types';
import {
	useGetAllBodyworkVariantsQuery,
	useCreateBodyworkVariantMutation,
	useUpdateBodyworkVariantMutation,
	useDeleteBodyworkVariantMutation,
	GetAllBodyworkVariantsDocument,
} from './hooks/bodyworkVariants.generated';
import {BodyworkVariantsTooltipTranslationProvider} from 'features/localizedTooltips';

const AdminBodyworkVariantsPage: React.FC = () => {
	const {t} = useTranslation('features/adminsection', {
		keyPrefix: 'AdminBodyworkVariantsPage',
	});

	const {loading, data} = useGetAllBodyworkVariantsQuery();
	const [createBodyworkVariantMutation] = useCreateBodyworkVariantMutation();
	const [updateBodyworkVariantMutation] = useUpdateBodyworkVariantMutation();
	const [deleteBodyworkVariantMutation] = useDeleteBodyworkVariantMutation();

	const bodyworkVariants = React.useMemo(
		() => data?.bodyworkVariants ?? [],
		[data],
	);

	const refetchQueries = [GetAllBodyworkVariantsDocument];

	const createBodyworkVariant = React.useCallback(
		(bodyworkVariant: BodyworkVariant) => {
			createBodyworkVariantMutation({
				variables: {
					input: {
						name: bodyworkVariant.name,
					},
				},
				refetchQueries,
			});
		},
		[],
	);

	const updateBodyworkVariant = React.useCallback(
		(bodyworkVariant: BodyworkVariant) => {
			updateBodyworkVariantMutation({
				variables: {
					input: {id: bodyworkVariant.id, name: bodyworkVariant.name},
				},
				refetchQueries,
			});
		},
		[],
	);

	const deleteBodyworkVariant = React.useCallback((id: string) => {
		deleteBodyworkVariantMutation({
			variables: {
				input: {id},
			},
			refetchQueries,
		});
	}, []);

	return (
		<ViewAuthorizer roles={[UserRole.SystemAdministrator]}>
			<LoadWrapper loading={loading}>
				<BodyworkVariantsTooltipTranslationProvider>
					<EntityPage
						items={bodyworkVariants}
						entityDisplayName={t('BodyworkVariants')}
						createEntity={createBodyworkVariant}
						updateEntity={updateBodyworkVariant}
						deleteEntity={deleteBodyworkVariant}
					/>
				</BodyworkVariantsTooltipTranslationProvider>
			</LoadWrapper>
		</ViewAuthorizer>
	);
};

export default AdminBodyworkVariantsPage;
