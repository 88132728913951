import React from 'react';
import {
	EditorSelectionContext,
	TextLineSelection,
} from './EditorSelectionContext';

export const EditorSelectionContextProvider: React.FC<{
	textLine?: TextLineSelection;
	children: React.ReactNode;
}> = ({textLine = null, children}) => {
	const [selectedTextLine, setSelectedTextLine] =
		React.useState<TextLineSelection>(textLine);

	return (
		<EditorSelectionContext.Provider
			value={{
				selectedTextLine,
				setSelectedTextLine,
			}}
		>
			{children}
		</EditorSelectionContext.Provider>
	);
};
