import {Panel, PanelType} from '@fluentui/react';
import {useBoolean} from '@fluentui/react-hooks';
import {UserRole} from 'types';

import {LoadWrapper} from 'components/LoadWrapper';
import {useRegulatoryDocumentsContext} from 'features/RegulatoryDocuments/context';
import {useGetCompareDocumentDataLazyQuery} from 'features/RegulatoryDocuments/hooks';
import {useCommand} from 'hooks';
import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {
	EditParagraphsFormSubmissionStatusContext,
	EditParagraphsFormSubmissionStatusContextValue,
} from 'features/RegulatoryDocuments/RegDocDetailsPage/EditParagraphsFormSubmissionStatusProvider';
import {ComparisonComponent} from './ComparisonComponent/ComparisonComponent';
import {isPogisDocument} from 'features/RegulatoryDocuments/regulatoryUtils';

export const CompareVersionPanel = () => {
	const {t} = useTranslation('features/regulatorydocuments', {
		keyPrefix: 'CompareVersionPanel',
	});
	const {selectedRegulatoryDocument, allSelectedRegulatoryDocuments} =
		useRegulatoryDocumentsContext();

	/**
	 * Queries
	 */
	const [
		loadOldParagraphData,
		{data: oldVersionData, loading: areOldParagraphsLoading},
	] = useGetCompareDocumentDataLazyQuery();

	const [
		loadNewParagraphData,
		{data: newVersionData, loading: areNewParagraphsLoading},
	] = useGetCompareDocumentDataLazyQuery();

	/**
	 * Other
	 */
	const submissionStatus: EditParagraphsFormSubmissionStatusContextValue =
		useContext(EditParagraphsFormSubmissionStatusContext);

	const oldVersion = oldVersionData?.regulatoryDocument;
	const newVersion = newVersionData?.regulatoryDocument;

	const [isOpen, {setTrue: openPanel, setFalse: dismissPanel}] =
		useBoolean(false);

	const handleCompareClick = React.useCallback(
		(oldVersionId: string | undefined, newVersionId: string | undefined) => {
			if (oldVersionId && newVersionId) {
				loadOldParagraphData({
					variables: {
						regulatoryDocumentId: oldVersionId ?? '',
					},
				});
				loadNewParagraphData({
					variables: {
						regulatoryDocumentId: newVersionId ?? '',
					},
				});
			}

			openPanel();
		},
		[],
	);

	const isSubmitting: boolean = submissionStatus?.isSubmitting ?? false;

	useCommand(
		{
			key: 'compare',
			text: t('CompareVersion'),
			ariaLabel: 'compare versions',
			priority: 3,
			iconProps: {
				iconName: 'BranchCompare',
			},
			disabled:
				!selectedRegulatoryDocument ||
				isPogisDocument(selectedRegulatoryDocument) ||
				(allSelectedRegulatoryDocuments ?? []).length > 1 ||
				isSubmitting,
			subMenuProps: {
				items: [
					{
						key: 'compareToPredeccessor',
						ariaLabel: 'compare to predecessor',
						text: t('ToPredecessor'),
						disabled: !selectedRegulatoryDocument?.predecessor,
						onClick() {
							handleCompareClick(
								selectedRegulatoryDocument?.predecessor?.id,
								selectedRegulatoryDocument?.id,
							);
						},
					},
					{
						key: 'compareToSuccessor',
						ariaLabel: 'compare to successor',
						text: t('ToSuccessor'),
						disabled: !selectedRegulatoryDocument?.successor,
						onClick() {
							handleCompareClick(
								selectedRegulatoryDocument?.id,
								selectedRegulatoryDocument?.successor?.id,
							);
						},
					},
				],
			},
			roles: [
				UserRole.SystemAdministrator,
				UserRole.Vko,
				UserRole.VkoExternal,
				UserRole.Vex,
				UserRole.ShApprover,
				UserRole.Readers,
				UserRole.RegulationReader,
			],
		},
		[selectedRegulatoryDocument, allSelectedRegulatoryDocuments, isSubmitting],
	);

	/**
	 * We wait for the paragraphs to load so that the user doesn't copy a
	 * paragraph's outdated metadata to another paragraph.
	 */
	return (
		<Panel
			isLightDismiss
			styles={{content: {paddingRight: 0}}}
			isOpen={isOpen}
			onDismiss={dismissPanel}
			closeButtonAriaLabel='Close'
			headerText={t('CompareVersions')}
			type={PanelType.extraLarge}
			isHiddenOnDismiss={false}
		>
			{oldVersion &&
			newVersion &&
			!areOldParagraphsLoading &&
			!areNewParagraphsLoading ? (
				<ComparisonComponent
					oldVersion={oldVersion}
					newVersion={newVersion}
					onDismiss={dismissPanel}
				/>
			) : (
				<LoadWrapper loading={true} />
			)}
		</Panel>
	);
};
