import React from 'react';
import {useCommand} from 'hooks';
import {useNavigate} from 'react-router-dom';
import {DialogInfo, useDialogState} from 'components/Dialogs';
import {
	RegulationFormMode,
	RegulationFormModeStateInfo,
	useRegulationFormMode,
} from './useRegulationFormMode';
import {RegulationsContextProps, useRegulationsContext} from '../context';
import {RegulationForm} from './RegulationsForm';
import {UserRole} from 'types';
import {useRegulationFormTranslation} from './useRegulationFormTranslation';
import {useEditRegulationCmd} from './useEditRegulationCmd';
import {GetAllRegulationsDocument} from '../hooks';

export const RegulationsPageRegulationsForm = () => {
	const [dialogState, showDialog, hideDialog]: DialogInfo = useDialogState();
	const [mode, setMode]: RegulationFormModeStateInfo = useRegulationFormMode();
	const {selectedRegulation} =
		useRegulationsContext() as RegulationsContextProps;
	const {t: getTranslation} = useRegulationFormTranslation();
	const navigate = useNavigate();

	useCommand(
		{
			key: 'view',
			text: getTranslation('View'),
			priority: 1,
			iconProps: {
				iconName: 'View',
			},
			disabled: !selectedRegulation,
			onClick() {
				if (selectedRegulation?.id) {
					navigate(`/regulations/${selectedRegulation.id}`);
				}
			},
			roles: [
				UserRole.SystemAdministrator,
				UserRole.Vko,
				UserRole.VkoExternal,
				UserRole.Vex,
				UserRole.ShApprover,
				UserRole.Readers,
				UserRole.RegulationReader,
			],
		},
		[selectedRegulation],
	);

	useCommand(
		{
			key: 'add',
			text: getTranslation('New'),
			priority: 1,
			iconProps: {
				iconName: 'Add',
			},
			onClick: () => setMode(RegulationFormMode.Create),
			disabled: Boolean(selectedRegulation),
			roles: [UserRole.SystemAdministrator, UserRole.Vko, UserRole.VkoExternal],
		},
		[mode, selectedRegulation],
	);

	useEditRegulationCmd(
		{
			key: 'edit',
			text: getTranslation('Edit'),
			priority: 2,
			disabled: !selectedRegulation,
			setMode,
			stopPolling: null,
		},
		[mode, selectedRegulation],
	);

	useCommand(
		{
			key: 'delete',
			text: getTranslation('Delete'),
			priority: 3,
			iconProps: {
				iconName: 'Delete',
			},
			onClick: () => showDialog(),
			roles: [UserRole.SystemAdministrator],
			disabled: !selectedRegulation,
		},
		[selectedRegulation],
	);

	return (
		<RegulationForm
			dialogState={dialogState}
			hideDialog={hideDialog}
			mode={mode}
			setMode={setMode}
			regulation={selectedRegulation}
			queriesToRefetchAfterSaveOrDelete={[GetAllRegulationsDocument]}
			startPolling={null}
		/>
	);
};
