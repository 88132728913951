import React, {createContext, useMemo} from 'react';
import {NetworkStatus} from '@apollo/client';
import {
	GetRegulatoryDocumentDetailsQueryResult,
	GetVexRegulatoryDocumentParagraphsQueryResult,
} from '../hooks/useGetRegulatoryDocumentDetails.generated';

export interface RegDocDetailsPageInfo {
	shouldShowRefetchIndicator: boolean;
	isLoadingFor1stTime: boolean;
}

type RegDocDetailsPageContextValue = RegDocDetailsPageInfo | null;

export const RegDocDetailsPageInfoContext =
	createContext<RegDocDetailsPageContextValue>(null);

interface Props {
	children: React.ReactNode;
	isFormRefetchingRegDoc: boolean;
	isRegDocOrVexItemsLoading: boolean;
	regDocNetworkStatus: NetworkStatus;
	vexItemsNetworkStatus: NetworkStatus;
	areVexItemsLoading: boolean;
	regDoc: GetRegulatoryDocumentDetailsQueryResult['data'];
	vexItems: GetVexRegulatoryDocumentParagraphsQueryResult['data'];
}

export const RegDocDetailsPageInfoProvider = ({
	children,
	isFormRefetchingRegDoc,
	isRegDocOrVexItemsLoading,
	regDocNetworkStatus,
	vexItemsNetworkStatus,
	areVexItemsLoading,
	regDoc,
	vexItems,
}: Props): JSX.Element => {
	/**
	 * We shouldn't only check whether the queryData exists because it might not
	 * exist when there is an error. So, we must check the network status and
	 * whether the query's data exists to know whether the query is being loaded
	 * for the first time.
	 */
	const getIfIsFetchingResourceFor1stTime = (
		networkStatus: NetworkStatus,
		queryData: Props['regDoc'] | Props['vexItems'],
	): boolean => {
		return networkStatus === NetworkStatus.loading && !queryData;
	};

	const getIfIsLoadingFor1stTime = (): boolean => {
		const initiallyLoadingRegDoc: boolean = getIfIsFetchingResourceFor1stTime(
			regDocNetworkStatus,
			regDoc,
		);
		const initiallyLoadingVexItems: boolean = getIfIsFetchingResourceFor1stTime(
			vexItemsNetworkStatus,
			vexItems,
		);

		return initiallyLoadingRegDoc || initiallyLoadingVexItems;
	};

	const isLoadingFor1stTime: boolean = getIfIsLoadingFor1stTime();

	const getIfIsRefetchingAfter1stLoad = (): boolean => {
		return !isLoadingFor1stTime && isRegDocOrVexItemsLoading;
	};

	const getIfShouldShowRefetchIndicator = (): boolean => {
		/**
		 * We don't show the loading indicator in this case because we want the user
		 * to be able to use the rest of the app. The app should already be
		 * displaying the paragraphs optimistically, so they don't need to wait for
		 * the refetch.
		 *
		 * We also check that the VEX items aren't loading because if they are not,
		 * it means only the reg doc is loading.
		 */
		const isRefetchingOnlyRegDocBecauseOfForm: boolean =
			isFormRefetchingRegDoc && !areVexItemsLoading;

		const isRefetchingAfter1stLoad: boolean = getIfIsRefetchingAfter1stLoad();

		return !isRefetchingOnlyRegDocBecauseOfForm && isRefetchingAfter1stLoad;
	};

	const getPageInfo = (): RegDocDetailsPageInfo => {
		return {
			shouldShowRefetchIndicator: getIfShouldShowRefetchIndicator(),
			isLoadingFor1stTime,
		};
	};

	const pageInfo: RegDocDetailsPageInfo = useMemo(getPageInfo, [
		isRegDocOrVexItemsLoading,
		isLoadingFor1stTime,
		isFormRefetchingRegDoc,
		areVexItemsLoading,
	]);

	return (
		<RegDocDetailsPageInfoContext.Provider value={pageInfo}>
			{children}
		</RegDocDetailsPageInfoContext.Provider>
	);
};
