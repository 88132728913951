import {FC} from 'react';
import {Controller} from 'react-hook-form';
import * as React from 'react';
import {HookFormProps} from './HookFormProps';
import {
	CheckboxWithTooltip,
	PropsOfCheckboxWithTooltip,
} from 'features/localizedTooltips';
import {DirectionalHint} from '@fluentui/react';

type ControlledCheckboxProps = Omit<
	PropsOfCheckboxWithTooltip,
	'defaultValue'
> &
	HookFormProps;

export const ControlledCheckbox: FC<ControlledCheckboxProps> = props => {
	return (
		<Controller
			name={props.name}
			control={props.control}
			rules={props.rules}
			defaultValue={props.defaultValue || false}
			render={({field: {onChange, value}}) => (
				<CheckboxWithTooltip
					{...props}
					onChange={(e, val) => {
						onChange(Boolean(val));
						props.onChange?.(e, val);
					}}
					tooltipHostProps={{directionalHint: DirectionalHint.topCenter}}
					checked={value || false}
				/>
			)}
		/>
	);
};
