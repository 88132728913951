import React from 'react';
import {
	ITag,
	ITagItemSuggestionStyles,
	TagItemSuggestion,
} from '@fluentui/react';

/**
 * This must not be memoized because the app throws an error if we try using
 * hooks.
 */
const styles: ITagItemSuggestionStyles = {
	suggestionTextOverflow: {maxWidth: '65vw'},
};

export function BaselineSuggestion(props: ITag): JSX.Element {
	return <TagItemSuggestion styles={styles}>{props.name}</TagItemSuggestion>;
}
