import * as React from 'react';
import {RegulatoryDocument} from 'types';
import {RegulatoryDocumentsContext} from '.';
import {SelectionMode} from '@fluentui/react';

export interface RegulatoryDocumentsContextProviderProps {
	regulatoryDocument?: Partial<RegulatoryDocument> | null;
	regulatoryDocuments?: Partial<RegulatoryDocument>[] | null;
	sMode?: SelectionMode | null;
	children: React.ReactNode;
}

export const RegulatoryDocumentsContextProvider: React.FC<
	RegulatoryDocumentsContextProviderProps
> = ({
	regulatoryDocument = null,
	regulatoryDocuments = null,
	sMode = SelectionMode.none,
	children,
}) => {
	const [selectedRegulatoryDocument, setSelectedRegulatoryDocument] =
		React.useState<Partial<RegulatoryDocument> | null>(regulatoryDocument);
	const [allSelectedRegulatoryDocuments, setAllSelectedRegulatoryDocuments] =
		React.useState<Partial<RegulatoryDocument>[] | null>(regulatoryDocuments);
	const [selectionMode, setSelectionMode] = React.useState<SelectionMode>(
		sMode as SelectionMode,
	);

	return (
		<RegulatoryDocumentsContext.Provider
			value={{
				selectedRegulatoryDocument,
				setSelectedRegulatoryDocument,
				allSelectedRegulatoryDocuments,
				setAllSelectedRegulatoryDocuments,
				selectionMode,
				setSelectionMode,
			}}
		>
			{children}
		</RegulatoryDocumentsContext.Provider>
	);
};
