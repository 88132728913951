import * as Types from '../types/types.generated';

import { gql } from '@apollo/client';
export type KeywordTagFieldsFragment = { __typename?: 'Keyword', id: string, name: string, vexClusters: Array<{ __typename?: 'VexCluster', id: string, name: string }>, vkos: Array<{ __typename?: 'User', id: string, name: string }> };

export const KeywordTagFieldsFragmentDoc = gql`
    fragment KeywordTagFields on Keyword {
  id
  name
  vexClusters {
    id
    name
  }
  vkos {
    id
    name
  }
}
    `;