import React from 'react';
import {ISpinnerStyles, mergeStyleSets} from '@fluentui/react';
import {CommonLoadWrapperProps} from 'components/LoadWrapper';
import {LoadSpinner} from 'components/LoadWrapper/LoadSpinner';

const spinnerStyles: ISpinnerStyles = {root: {position: 'fixed'}};

/**
 * This component uses CSS styles to hide content while it's loading instead of
 * rendering the content only after it has been loaded.
 */
export function CssLoadWrapper({
	children,
	loading,
}: CommonLoadWrapperProps): JSX.Element {
	/*
	 * Styles
	 */
	const getStyles = () => {
		/**
		 * We hide this when the resources are loading so that our load spinner can
		 * display correctly.
		 */
		return mergeStyleSets({
			content: {visibility: loading ? 'hidden' : 'visible'},
		});
	};

	const styles = getStyles();

	return (
		<>
			{loading ? <LoadSpinner extraStyles={spinnerStyles} /> : null}
			<div className={styles.content}>{children}</div>
		</>
	);
}
