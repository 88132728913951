import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import { UserFieldsFragmentDoc } from '../../../fragments/userFields.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SimulationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SimulationsQuery = { __typename?: 'Query', simulations: Array<{ __typename?: 'Simulation', id: string, name: string, modelSeriesReference?: string | null, generationReference?: string | null, modifiedAt?: any | null, createdBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null }, modifiedBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null } }> };

export type CreateSimulationMutationVariables = Types.Exact<{
  input: Types.CreateSimulationInput;
}>;


export type CreateSimulationMutation = { __typename?: 'Mutation', createSimulation: { __typename?: 'SimulationPayload', simulation: { __typename?: 'Simulation', id: string } } };

export type UpdateSimulationMutationVariables = Types.Exact<{
  input: Types.UpdateSimulationInput;
}>;


export type UpdateSimulationMutation = { __typename?: 'Mutation', updateSimulation: { __typename?: 'SimulationPayload', simulation: { __typename?: 'Simulation', id: string } } };

export type DeleteSimulationMutationVariables = Types.Exact<{
  input: Types.DeleteSimulationInput;
}>;


export type DeleteSimulationMutation = { __typename?: 'Mutation', deleteSimulation: { __typename?: 'DeleteSimulationPayload', simulation?: { __typename?: 'Simulation', id: string } | null } };

export type VehicleProjectsOfSimulationsPageQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type VehicleProjectsOfSimulationsPageQuery = { __typename?: 'Query', vehicleProjectOverviewPageData: Array<{ __typename?: 'VehicleProjectOverviewPageEntity', generation: string, modelSeries: string }> };


export const SimulationsDocument = gql`
    query Simulations {
  simulations {
    id
    name
    createdBy {
      ...UserFields
    }
    modelSeriesReference
    generationReference
    modifiedBy {
      ...UserFields
    }
    modifiedAt
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useSimulationsQuery__
 *
 * To run a query within a React component, call `useSimulationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimulationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimulationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSimulationsQuery(baseOptions?: Apollo.QueryHookOptions<SimulationsQuery, SimulationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SimulationsQuery, SimulationsQueryVariables>(SimulationsDocument, options);
      }
export function useSimulationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SimulationsQuery, SimulationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SimulationsQuery, SimulationsQueryVariables>(SimulationsDocument, options);
        }
export type SimulationsQueryHookResult = ReturnType<typeof useSimulationsQuery>;
export type SimulationsLazyQueryHookResult = ReturnType<typeof useSimulationsLazyQuery>;
export type SimulationsQueryResult = Apollo.QueryResult<SimulationsQuery, SimulationsQueryVariables>;
export const CreateSimulationDocument = gql`
    mutation CreateSimulation($input: CreateSimulationInput!) {
  createSimulation(input: $input) {
    simulation {
      id
    }
  }
}
    `;
export type CreateSimulationMutationFn = Apollo.MutationFunction<CreateSimulationMutation, CreateSimulationMutationVariables>;

/**
 * __useCreateSimulationMutation__
 *
 * To run a mutation, you first call `useCreateSimulationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSimulationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSimulationMutation, { data, loading, error }] = useCreateSimulationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSimulationMutation(baseOptions?: Apollo.MutationHookOptions<CreateSimulationMutation, CreateSimulationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSimulationMutation, CreateSimulationMutationVariables>(CreateSimulationDocument, options);
      }
export type CreateSimulationMutationHookResult = ReturnType<typeof useCreateSimulationMutation>;
export type CreateSimulationMutationResult = Apollo.MutationResult<CreateSimulationMutation>;
export type CreateSimulationMutationOptions = Apollo.BaseMutationOptions<CreateSimulationMutation, CreateSimulationMutationVariables>;
export const UpdateSimulationDocument = gql`
    mutation UpdateSimulation($input: UpdateSimulationInput!) {
  updateSimulation(input: $input) {
    simulation {
      id
    }
  }
}
    `;
export type UpdateSimulationMutationFn = Apollo.MutationFunction<UpdateSimulationMutation, UpdateSimulationMutationVariables>;

/**
 * __useUpdateSimulationMutation__
 *
 * To run a mutation, you first call `useUpdateSimulationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSimulationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSimulationMutation, { data, loading, error }] = useUpdateSimulationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSimulationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSimulationMutation, UpdateSimulationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSimulationMutation, UpdateSimulationMutationVariables>(UpdateSimulationDocument, options);
      }
export type UpdateSimulationMutationHookResult = ReturnType<typeof useUpdateSimulationMutation>;
export type UpdateSimulationMutationResult = Apollo.MutationResult<UpdateSimulationMutation>;
export type UpdateSimulationMutationOptions = Apollo.BaseMutationOptions<UpdateSimulationMutation, UpdateSimulationMutationVariables>;
export const DeleteSimulationDocument = gql`
    mutation DeleteSimulation($input: DeleteSimulationInput!) {
  deleteSimulation(input: $input) {
    simulation {
      id
    }
  }
}
    `;
export type DeleteSimulationMutationFn = Apollo.MutationFunction<DeleteSimulationMutation, DeleteSimulationMutationVariables>;

/**
 * __useDeleteSimulationMutation__
 *
 * To run a mutation, you first call `useDeleteSimulationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSimulationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSimulationMutation, { data, loading, error }] = useDeleteSimulationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSimulationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSimulationMutation, DeleteSimulationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSimulationMutation, DeleteSimulationMutationVariables>(DeleteSimulationDocument, options);
      }
export type DeleteSimulationMutationHookResult = ReturnType<typeof useDeleteSimulationMutation>;
export type DeleteSimulationMutationResult = Apollo.MutationResult<DeleteSimulationMutation>;
export type DeleteSimulationMutationOptions = Apollo.BaseMutationOptions<DeleteSimulationMutation, DeleteSimulationMutationVariables>;
export const VehicleProjectsOfSimulationsPageDocument = gql`
    query VehicleProjectsOfSimulationsPage {
  vehicleProjectOverviewPageData {
    generation
    modelSeries
  }
}
    `;

/**
 * __useVehicleProjectsOfSimulationsPageQuery__
 *
 * To run a query within a React component, call `useVehicleProjectsOfSimulationsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleProjectsOfSimulationsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleProjectsOfSimulationsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useVehicleProjectsOfSimulationsPageQuery(baseOptions?: Apollo.QueryHookOptions<VehicleProjectsOfSimulationsPageQuery, VehicleProjectsOfSimulationsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VehicleProjectsOfSimulationsPageQuery, VehicleProjectsOfSimulationsPageQueryVariables>(VehicleProjectsOfSimulationsPageDocument, options);
      }
export function useVehicleProjectsOfSimulationsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VehicleProjectsOfSimulationsPageQuery, VehicleProjectsOfSimulationsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VehicleProjectsOfSimulationsPageQuery, VehicleProjectsOfSimulationsPageQueryVariables>(VehicleProjectsOfSimulationsPageDocument, options);
        }
export type VehicleProjectsOfSimulationsPageQueryHookResult = ReturnType<typeof useVehicleProjectsOfSimulationsPageQuery>;
export type VehicleProjectsOfSimulationsPageLazyQueryHookResult = ReturnType<typeof useVehicleProjectsOfSimulationsPageLazyQuery>;
export type VehicleProjectsOfSimulationsPageQueryResult = Apollo.QueryResult<VehicleProjectsOfSimulationsPageQuery, VehicleProjectsOfSimulationsPageQueryVariables>;