import {FontIcon, Theme, mergeStyles, useTheme} from '@fluentui/react';
import React from 'react';
import {CompareResult} from '../../compareDocuments/compareDocuments';
import {getClassNames, getComparisonColor} from '../CompareVersionPanel.utils';

const getDotClassName = (result: CompareResult, theme: Theme) => {
	const color = getComparisonColor(result, theme);

	return mergeStyles({
		color,
		background: color,
	});
};

export const IndicatorDot: React.FC<{resultType: CompareResult}> = ({
	resultType,
}) => {
	const theme = useTheme();
	const classNames = getClassNames(theme);
	return (
		<FontIcon
			aria-label='StatusCircleInner'
			iconName='StatusCircleInner'
			className={`${getDotClassName(resultType, theme)} ${
				classNames.iconStyles
			}`}
		/>
	);
};
