import React from 'react';

export enum RegulatoryDocumentFormMode {
	None,
	Create,
	Copy,
	Update,
	View,
}

export interface RegulatoryDocumentFormModeInfo {
	mode: RegulatoryDocumentFormMode;
	setMode: React.Dispatch<React.SetStateAction<RegulatoryDocumentFormMode>>;
}

export const useRegulatoryDocumentFormMode =
	(): RegulatoryDocumentFormModeInfo => {
		const [mode, setMode] = React.useState<RegulatoryDocumentFormMode>(
			RegulatoryDocumentFormMode.None,
		);
		return {mode, setMode};
	};
