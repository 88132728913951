import {
	DialogType,
	IChoiceGroupOption,
	Dialog,
	ChoiceGroup,
	TextField,
	DialogFooter,
	PrimaryButton,
	DefaultButton,
	IIconStyles,
} from '@fluentui/react';
import {useBoolean} from '@fluentui/react-hooks';
import {useUserContext} from 'authentication/UserContext';
import {useEditorCommands} from 'features/RegulatoryDocuments/hooks';
import {useCommand} from 'hooks';
import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {NodeEntry, Editor, Node, BaseRange} from 'slate';
import {HistoryEditor} from 'slate-history';
import {useSlate, ReactEditor} from 'slate-react';
import {ImportRegulatoryDocumentParagraphInput, WorkflowStatus} from 'types';
import {useEditorContext} from './EditorContext';
import {InsertImageDialog} from './components/Dialogs';
import {documentEditorService} from './DocumentEditor.service';

export enum InsertionBtnTranslationKeys {
	Above = 'InsertAbove',
	Below = 'InsertBelow',
}

export const EditorCommands: React.FC<{
	onSave: (paragraphs: ImportRegulatoryDocumentParagraphInput[]) => void;
	workflowStatus: WorkflowStatus;
}> = ({onSave, workflowStatus}) => {
	const {t} = useTranslation('features/regulatorydocuments', {
		keyPrefix: 'DocumentEditor.EditorCommands',
	});
	const editor = useSlate();
	const {isMergedView, setIsMergedView} = useEditorContext();

	const {
		resetSelection,
		disableResetSelection,
		selectedItemsCount,
		removeHeader,
		disableRemoveHeader,
		declareHeader,
		disableDeclareHeader,
		declareFootnote,
		disableDeclateFootnote,
		splitText,
		disableSplitText,
		mergeLines,
		disableMergeLines,
		addParagraph,
		disableAddParagraph,
		addTextline,
		disableAddTextline,
		disableAddImages,
		incrementLevel,
		decrementLevel,
		disableLevelChange,
		toSubscript,
		toSuperscript,
		resetTextPosition,
		disableTextPositionChange,
		moveElementUp,
		moveElementDown,
		disableMoveElement,
		addNewLine,
		disableAddNewLine,
		mergeAllParagraphsLines,
		saveParagraphs,
		selectedItems,
	} = useEditorCommands(editor);

	const scrollSelectedNodeIntoView = React.useCallback((): void => {
		type PossibleNode = NodeEntry<Node> | null;

		const getNodeFromEditorSelection = (selection: BaseRange): NodeEntry => {
			return Editor.node(editor, {
				path: [selection.anchor.path[0]],
				offset: 0,
			});
		};

		const getLastSelectedItem = (): NodeEntry<Node> => {
			return selectedItems[selectedItems.length - 1];
		};

		const getSelectedNode = (): PossibleNode => {
			if (selectedItems?.length) return getLastSelectedItem();

			const {selection} = editor;
			if (selection) return getNodeFromEditorSelection(selection);

			return null;
		};

		const selectedNode: PossibleNode = getSelectedNode();
		documentEditorService.scrollNodeIntoViewIfPossible(editor, selectedNode);
	}, [editor, selectedItems]);

	React.useEffect(scrollSelectedNodeIntoView, [isMergedView]);

	const {undo, redo} = HistoryEditor;

	const [hideInsertParagraphDialog, {toggle: toggleHideInsertParagraphDialog}] =
		useBoolean(true);
	const [hideInsertTextlineDialog, {toggle: toggleHideInsertTextlineDialog}] =
		useBoolean(true);
	const [hideInsertImageDialog, {toggle: toggleHideInsertImageDialog}] =
		useBoolean(true);

	const {isVko} = useUserContext();

	const onCommandClick = React.useCallback(
		(cmdFn: () => void) => () => {
			cmdFn();
			resetSelection();
			ReactEditor.focus(editor);
		},
		[],
	);

	const [paragraphsSaving, setParagraphsSaving] = React.useState(false);

	const onSaveClick = React.useCallback(() => {
		setParagraphsSaving(true);
		saveParagraphs(onSave).then(() => setParagraphsSaving(false));
	}, [saveParagraphs]);

	useCommand(
		{
			key: 'declareHeader',
			text: t('DeclareHeader'),
			title: t('DeclareHeaderShortcut'),
			iconProps: {iconName: 'Dictionary'},
			onClick: onCommandClick(declareHeader),
			disabled: disableDeclareHeader || isMergedView,
			priority: 0,
		},
		[disableDeclareHeader, isMergedView],
	);

	useCommand(
		{
			key: 'declareFootnote',
			text: t('DeclareFootnote'),
			title: t('DeclareFootnoteShortcut'),
			iconProps: {iconName: 'Footer'},
			onClick: onCommandClick(declareFootnote),
			disabled: disableDeclateFootnote || isMergedView,
			priority: 1,
		},
		[disableDeclateFootnote, isMergedView],
	);

	useCommand(
		{
			key: 'removeHeader',
			text: t('RemoveHeader'),
			title: t('RemoveHeaderShortcut'),
			iconProps: {iconName: 'DictionaryRemove'},
			onClick: onCommandClick(removeHeader),
			disabled: disableRemoveHeader || isMergedView,
			priority: 2,
		},
		[disableRemoveHeader, isMergedView],
	);

	useCommand(
		{
			key: 'splitText',
			text: t('SplitText'),
			title: t('SplitTextShortcut'),
			iconProps: {
				iconName: 'Split',
				styles: rotatedIconStyles,
			},
			onClick: onCommandClick(splitText),
			disabled: disableSplitText || isMergedView,
			priority: 3,
		},
		[disableSplitText, isMergedView],
	);

	useCommand(
		{
			key: 'mergeLines',
			text: t('MergeLines'),
			title: t('MergeLinesShortcut'),
			iconProps: {
				iconName: 'MergeDuplicate',
				styles: rotatedIconStyles,
			},
			onClick: onCommandClick(mergeLines),
			disabled: disableMergeLines || isMergedView,
			priority: 4,
		},
		[disableMergeLines, isMergedView],
	);

	useCommand(
		{
			key: 'decrementLevel',
			iconOnly: true,
			title: t('DecrementLevelShortcut'),
			iconProps: {
				iconName: 'PaddingLeft',
			},
			onClick: decrementLevel,
			disabled: disableLevelChange || isMergedView,
			priority: 5,
		},
		[disableLevelChange, isMergedView],
	);

	useCommand(
		{
			key: 'incrementLevel',
			iconOnly: true,
			title: t('IncrementLevelShortcut'),
			iconProps: {
				iconName: 'PaddingRight',
			},
			onClick: incrementLevel,
			disabled: disableLevelChange || isMergedView,
			priority: 6,
		},
		[disableLevelChange, isMergedView],
	);

	useCommand(
		{
			key: 'changeTextPosition',
			iconOnly: true,
			title: t('ChangeTextPosition'),
			iconProps: {iconName: 'Font'},
			disabled: disableTextPositionChange || isMergedView,
			priority: 7,
			subMenuProps: {
				items: [
					{
						key: 'toSubscript',
						iconProps: {iconName: 'Subscript'},
						text: t('ToSubscript'),
						title: t('ToSubscriptShortcut'),
						onClick: onCommandClick(toSubscript),
						disabled: disableTextPositionChange,
					},
					{
						key: 'toSuperscript',
						iconProps: {iconName: 'Superscript'},
						text: t('ToSuperscript'),
						title: t('ToSuperscriptShortcut'),
						onClick: onCommandClick(toSuperscript),
						disabled: disableTextPositionChange,
					},
					{
						key: 'resetTextPosition',
						iconProps: {iconName: 'Clear'},
						text: t('ResetTextPosition'),
						title: t('ResetTextPositionShortcut'),
						onClick: onCommandClick(resetTextPosition),
						disabled: disableTextPositionChange,
					},
				],
			},
		},
		[disableTextPositionChange, isMergedView],
	);

	useCommand(
		{
			key: 'addElement',
			iconProps: {iconName: 'Add'},
			ariaLabel: 'Add Element',
			priority: 8,
			disabled:
				disableAddParagraph &&
				disableAddTextline &&
				disableAddImages &&
				disableAddNewLine,
			subMenuProps: {
				items: [
					{
						key: 'addParagraph',
						iconProps: {iconName: 'AddToShoppingList'},
						text: t('Paragraph'),
						onClick: toggleHideInsertParagraphDialog,
						disabled: disableAddParagraph,
					},
					{
						key: 'addLine',
						iconProps: {iconName: 'AddNotes'},
						text: t('TextLine'),
						title: t('TextLineShortcut'),
						onClick: toggleHideInsertTextlineDialog,
						disabled: disableAddTextline,
					},
					{
						key: 'addNewLine',
						iconProps: {iconName: 'ReturnKey'},
						text: t('NewLine'),
						title: t('NewLineShortcut'),
						onClick: addNewLine,
						disabled: disableAddNewLine,
					},
					{
						key: 'addImage',
						iconProps: {iconName: 'PictureFill'},
						text: t('Images'),
						onClick: toggleHideInsertImageDialog,
						disabled: disableAddImages,
					},
				],
			},
		},
		[disableAddTextline, disableAddParagraph, disableAddNewLine],
	);

	useCommand(
		{
			key: 'moveDown',
			iconOnly: true,
			title: t('MoveDownShortcut'),
			iconProps: {
				iconName: 'Down',
			},
			onClick: moveElementDown,
			disabled: disableMoveElement || isMergedView,
			priority: 9,
		},
		[disableMoveElement, isMergedView],
	);

	useCommand(
		{
			key: 'moveUp',
			iconOnly: true,
			title: t('MoveUpShortcut'),
			iconProps: {
				iconName: 'Up',
			},
			onClick: moveElementUp,
			disabled: disableMoveElement || isMergedView,
			priority: 10,
		},
		[disableMoveElement, isMergedView],
	);

	useCommand(
		{
			key: 'resetSelection',
			text: t('ResetSelection', {selectedItemsCount}),
			iconProps: {iconName: 'Cancel'},
			onClick: onCommandClick(resetSelection),
			hidden: disableResetSelection,
			farCommand: true,
			priority: 0,
		},
		[disableResetSelection, selectedItemsCount],
	);

	useCommand(
		{
			key: 'changeMode',
			text: isMergedView ? t('ChangeModeMerged') : t('ChangeMode'),
			farCommand: true,
			priority: 0.5,
			iconProps: {iconName: 'View'},
			onClick() {
				setIsMergedView(!isMergedView);
			},
		},
		[isMergedView],
	);

	useCommand(
		{
			key: 'mergeAll',
			text: t(`MergeAll`),
			iconProps: {iconName: 'SortLines'},
			onClick: () => mergeAllParagraphsLines(),
			farCommand: true,
			disabled: isMergedView,
			hidden: true,
			priority: 3,
		},
		[isMergedView],
	);

	useCommand(
		{
			key: 'undo',
			iconOnly: true,
			iconProps: {iconName: 'Undo'},
			onClick: onCommandClick(() => undo(editor)),
			disabled: isMergedView,
			farCommand: true,
			priority: 1,
		},
		[isMergedView],
	);

	useCommand(
		{
			key: 'redo',
			iconOnly: true,
			iconProps: {iconName: 'Redo'},
			onClick: onCommandClick(() => redo(editor)),
			disabled: isMergedView,
			farCommand: true,
			priority: 2,
		},
		[isMergedView],
	);

	const isButtonDisabledForVko = () => {
		if (isVko) {
			return (
				workflowStatus !== WorkflowStatus.QualityControlInternal &&
				workflowStatus !== WorkflowStatus.QualityControlExternal &&
				workflowStatus !== WorkflowStatus.New
			);
		}

		return false;
	};

	useCommand(
		{
			key: 'save',
			text: t('Save'),
			iconProps: {iconName: 'Save'},
			farCommand: true,
			priority: 4,
			onClick: onSaveClick,
			disabled: paragraphsSaving || isButtonDisabledForVko(),
		},
		[workflowStatus, isVko, paragraphsSaving],
	);

	const defaultShouldInsertAbove = false;
	const [insertAbove, setInsertAbove] = React.useState(
		defaultShouldInsertAbove,
	);
	const [enumeration, setEnumeration] = React.useState('');

	const insertParagraphDialogContentProps = {
		type: DialogType.largeHeader,
		title: t('InsertParagraph'),
	};
	const insertTextlineDialogContentProps = {
		type: DialogType.largeHeader,
		title: t('InsertLine'),
	};
	const insertElementModelProps = {
		isBlocking: false,
		styles: {main: {maxWidth: 450}},
	};

	enum InsertionDirections {
		Above = 'above',
		Below = 'below',
	}

	const insertElementOptions: IChoiceGroupOption[] = [
		{
			key: InsertionDirections.Below,
			text: t(InsertionBtnTranslationKeys.Below),
		},
		{
			key: InsertionDirections.Above,
			text: t(InsertionBtnTranslationKeys.Above),
		},
	];
	const onChoiceChange = React.useCallback(
		(_ev: any, option: IChoiceGroupOption | undefined) =>
			setInsertAbove(
				Boolean(option && option.key === InsertionDirections.Above),
			),
		[InsertionDirections.Above],
	);
	const onEnumLabelChange = React.useCallback(
		(_ev: any, value: string | undefined) => setEnumeration(value ?? ''),
		[],
	);

	/**
	 * This is necessary to prevent the default insertion direction from changing
	 * each time the user selects one from the ChoiceGroup. Note that the
	 * paragraph and text line dialogs share the same state, so failing to reset
	 * it could result in an incorrect default value for both dialogs.
	 */
	const resetInsertionDirection = useCallback((): void => {
		setInsertAbove(defaultShouldInsertAbove);
	}, [defaultShouldInsertAbove]);

	const closeParagraphDialogAndResetInsertionDirection =
		useCallback((): void => {
			toggleHideInsertParagraphDialog();
			resetInsertionDirection();
		}, [toggleHideInsertParagraphDialog, resetInsertionDirection]);

	const onInsertParagraphClick = React.useCallback(() => {
		addParagraph(enumeration, insertAbove);
		setEnumeration('');
		closeParagraphDialogAndResetInsertionDirection();
	}, [
		enumeration,
		insertAbove,
		addParagraph,
		closeParagraphDialogAndResetInsertionDirection,
	]);

	const closeTextLineDialog = useCallback((): void => {
		toggleHideInsertTextlineDialog();
		resetInsertionDirection();
	}, [toggleHideInsertTextlineDialog, resetInsertionDirection]);

	const onInsertTextlineClick = React.useCallback(() => {
		addTextline(insertAbove);
		setEnumeration('');
		closeTextLineDialog();
	}, [insertAbove, addTextline, closeTextLineDialog]);

	const getInsertionDirection = (): InsertionDirections => {
		return insertAbove ? InsertionDirections.Above : InsertionDirections.Below;
	};

	const insertionDirection: InsertionDirections = getInsertionDirection();

	return (
		<>
			<Dialog
				hidden={hideInsertParagraphDialog}
				onDismiss={closeParagraphDialogAndResetInsertionDirection}
				dialogContentProps={insertParagraphDialogContentProps}
				modalProps={insertElementModelProps}
			>
				<ChoiceGroup
					options={insertElementOptions}
					onChange={onChoiceChange}
					selectedKey={insertionDirection}
				/>
				<TextField label={t('EnumField')} onChange={onEnumLabelChange} />
				<DialogFooter>
					<PrimaryButton
						onClick={onInsertParagraphClick}
						text={t('AddButton')}
						disabled={!enumeration}
					/>
					<DefaultButton
						onClick={closeParagraphDialogAndResetInsertionDirection}
						text={t('CancelButton')}
					/>
				</DialogFooter>
			</Dialog>
			<Dialog
				hidden={hideInsertTextlineDialog}
				onDismiss={closeTextLineDialog}
				dialogContentProps={insertTextlineDialogContentProps}
				modalProps={insertElementModelProps}
			>
				<ChoiceGroup
					options={insertElementOptions}
					onChange={onChoiceChange}
					selectedKey={insertionDirection}
				/>
				<DialogFooter>
					<PrimaryButton
						onClick={onInsertTextlineClick}
						text={t('AddButton')}
					/>
					<DefaultButton
						onClick={closeTextLineDialog}
						text={t('CancelButton')}
					/>
				</DialogFooter>
			</Dialog>
			<InsertImageDialog
				hidden={hideInsertImageDialog}
				onDismiss={toggleHideInsertImageDialog}
			/>
		</>
	);
};

const rotatedIconStyles: IIconStyles = {
	root: {transform: 'rotate(90deg)'},
};
