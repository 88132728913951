import {DeepPartial} from '@fluentui/merge-styles';
import {IDetailsListStyles, IDetailsRowStyles} from '@fluentui/react';

type EntityListStyles = DeepPartial<IDetailsListStyles>;

export const entityListStylesWithAutoOverflowX: EntityListStyles = {
	root: {overflowX: 'auto'},
};

export const getStylesForRowBgColor = (
	backgroundColor: string | undefined,
): Partial<IDetailsRowStyles> => {
	return {root: {backgroundColor, '&:hover': {backgroundColor}}};
};
