import {
	QueryVehicleProjectsByModelSeriesGenerationActiveTypeMarketArgs,
	VehicleProject,
} from 'types';

export type ProjectDetailsPageParams = Pick<
	VehicleProject,
	'generation' | 'modelSeries'
>;

export const createGenPagePath = ({
	generation,
	modelSeries,
}: ProjectDetailsPageParams): string => {
	return `/vehicleProjects/${modelSeries}/${generation}`;
};

export const encodeGenAndModelSeries = ({
	generation,
	modelSeries,
}: ProjectDetailsPageParams): ProjectDetailsPageParams => {
	return {
		generation: encodeURIComponent(generation),
		modelSeries: encodeURIComponent(modelSeries),
	};
};

export type MarketPageParams = Pick<
	QueryVehicleProjectsByModelSeriesGenerationActiveTypeMarketArgs,
	'activeTypeMarketId' | 'generation' | 'modelSeries'
>;

export const getMarketPagePath = ({
	activeTypeMarketId,
	...other
}: MarketPageParams): string => {
	const genPath: string = createGenPagePath(other);
	return `${genPath}/${activeTypeMarketId}`;
};
