import {ISelectionOptions, Selection} from '@fluentui/react';
import * as React from 'react';

export type SettingsType = 'list';

export function useSelection<T>({
	getKey,
	...options
}: Omit<ISelectionOptions, 'getKey' | 'onSelectionChanged'> & {
	getKey?: (item: T) => string;
	onSelectionChanged?: (selectedItems: T[], selection: Selection) => void;
} = {}) {
	const [selectedItems, setSelectedItems] = React.useState<T[]>([]);
	const selection = React.useRef(
		(() => {
			const self = new Selection({
				...options,
				getKey: getKey ? (getKey as any) : undefined,
				onSelectionChanged() {
					setSelectedItems(self.getSelection() as T[]);
					if (options && options.onSelectionChanged) {
						options.onSelectionChanged(self.getSelection() as T[], self);
					}
				},
			});

			return self;
		})(),
	);

	return [selection.current, selectedItems] as [Selection, T[]];
}

export default useSelection;
