import React from 'react';
import {
	DetailsList,
	DetailsRow,
	IColumn,
	IDetailsRowProps,
	IDetailsRowStyles,
	IRenderFunction,
	SelectionMode,
	Theme,
	mergeStyles,
	useTheme,
} from '@fluentui/react';
import {useTranslation} from 'react-i18next';
import {NAMESPACE_OF_BASELINE_CHANGES} from './BaselineComparisonPage.utils';

const DetailListLeftPadding = 61;
type Renderer = IRenderFunction<IDetailsRowProps>;
type RendererParams = Parameters<Renderer>;
export type DefaultRowProps = RendererParams[0];
export type DefaultRowRender = RendererParams[1];
export type PossibleRenderedRow = JSX.Element | null;

interface Props {
	defaultProps: RendererParams[0];
	defaultRender: RendererParams[1];
	collapsible: boolean;
}

export function BaselineComparisonRow({
	defaultProps,
	defaultRender,
	collapsible,
}: Props): PossibleRenderedRow {
	const theme = useTheme();

	const {t: t1} = useTranslation(NAMESPACE_OF_BASELINE_CHANGES, {
		keyPrefix: '',
	});

	const {t: t2} = useTranslation(NAMESPACE_OF_BASELINE_CHANGES, {
		keyPrefix: 'subtablecolumn',
	});

	if (!defaultProps || !defaultRender) return null;

	const renderRow = (value: string, type: string) => {
		if (type === 'STRING_TYPE') {
			let v = JSON.parse(value)[0];
			v = v.replace('<p>', '');
			v = v.replace('</p>', '');
			return v;
		}
		if (type === 'DATE_TIME_TYPE') {
			const v = JSON.parse(value)[0];
			const date = new Date(Number(v));
			return date.toLocaleString();
		}
		if (type === 'LIST_TYPE') {
			if (!value) return '';

			const v = JSON.parse(value);

			return (
				<div>
					{v.map((item: string, index: number) => (
						<span
							key={index}
							style={{
								paddingLeft: '3px',
								paddingRight: '3px',
								paddingTop: '3px',
								paddingBottom: '3px',
								backgroundColor: '#ECEBE9',
								borderRadius: '5px',
								marginRight: '5px', // Optional: Add some space between spans
							}}
						>
							{item}
						</span>
					))}
				</div>
			);
		}

		return 'dsds';
	};
	const columns: IColumn[] = [
		{
			key: 'field',
			name: t2('field'),
			fieldName: 'field',
			minWidth: 100,
			maxWidth: 200,
			isResizable: true,
		},
		{
			key: 'oldValue',
			name: t2('old value'),
			fieldName: 'oldValue',
			minWidth: 100,
			maxWidth: 200,
			isResizable: true,
			onRender(item: any) {
				return renderRow(item.oldValue, item.type);
			},
		},
		{
			key: 'newValue',
			name: t2('new value'),
			fieldName: 'newValue',
			minWidth: 100,
			maxWidth: 200,
			isResizable: true,
			onRender(item: any) {
				return renderRow(item.newValue, item.type);
			},
		},
	];
	const items: Array<any> = [];

	for (let i = 0; i < defaultProps.item.changes.length; i++) {
		const change = defaultProps.item.changes[i];

		items.push({
			key: `item${i + 1}`,
			field: t1(change.field),
			newValue: change.newValue,
			oldValue: change.oldValue,
			type: change.type,
		});
	}

	const zz = createStyle(theme);

	return (
		<div>
			{defaultRender({
				...defaultProps,
				// Add Style// styles: getStylesForRowBgColor('#d9f2d0'),
			})}

			{collapsible ? (
				<></>
			) : (
				<div
					className={zz}
					style={{
						paddingLeft: DetailListLeftPadding,
						top: '-20px',
						marginBottom: '50px',
					}}
				>
					<DetailsList
						selectionMode={SelectionMode.none}
						items={items}
						columns={columns}
					/>
				</div>
			)}
		</div>
	);
}

function createStyle(theme: Theme): string {
	return mergeStyles({
		// Reverse the parent list custom css style
		'.ms-DetailsHeader-cell:nth-child(2)': {
			left: '0px',
		},
		// Reverse the parent list custom css style
		'.ms-DetailsRow-fields > div:nth-child(1)': {
			left: `0px`,
		},
		// Add more styles as needed
		'.ms-DetailsHeader': {
			paddingTop: '0px',
		},
	});
}
