import {useCommand} from 'hooks';
import {useVehicleProjectsTranslation} from '../hooks/vehicleProjectsTranslationHooks';
import {IContextualMenuItem as MenuItem} from '@fluentui/react';
import {useUserContext, UserContextType} from 'authentication/UserContext';
import {useMemo} from 'react';
import {useBoolean} from '@fluentui/react-hooks';
import {BooleanState} from 'types/fluentUi';

import {ProjectDetailsPageParams} from 'features/VehicleProjects/vehicleProjectPagePaths';
import {
	NavigateToBaselineListPage,
	useNavigateToBaselineListPage,
} from '../BaselineListPage/BaselinesPage.utils';

export interface BaselineCmdsInfo {
	infoForIsSuccessDialogOpen: BooleanState;
}

interface Options {
	params: ProjectDetailsPageParams;
	showOverviewCmd?: boolean;
	showCreationCmd?: boolean;
}

export const useBaselineCmds = ({
	params,
	showOverviewCmd = true,
}: Options): BaselineCmdsInfo => {
	const {isAdmin}: UserContextType = useUserContext();
	const {t} = useVehicleProjectsTranslation({keyPrefix: 'BaselineCmds'});
	const navigateToBaselinesPage: NavigateToBaselineListPage =
		useNavigateToBaselineListPage(params);

	const infoForIsSuccessDialogOpen: BooleanState = useBoolean(false);
	const [, {setTrue: setVisible}] = infoForIsSuccessDialogOpen;

	const subcommands = useMemo((): MenuItem[] => {
		/**
		 * We define another function here so that the return type is void. This is
		 * required to avoid type errors.
		 */
		const handleCreation = (): void => {
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			setVisible();
		};

		const createOverviewMenuItem = (): MenuItem => {
			return {
				key: 'Overview',
				text: t('ViewOverview'),
				onClick: navigateToBaselinesPage,
			};
		};

		const createCreateMenuItem = (): MenuItem => {
			return {
				key: 'Create',
				text: t('Create'),
				onClick() {
					handleCreation();
				},
			};
		};

		const getCmdsForAllRoles = (): MenuItem[] => {
			const overviewMenuItem: MenuItem = createOverviewMenuItem();
			const createMenuItem: MenuItem = createCreateMenuItem();

			if (showOverviewCmd) return [overviewMenuItem, createMenuItem];
			return [createMenuItem];
		};

		return getCmdsForAllRoles();
	}, [setVisible, t, showOverviewCmd, navigateToBaselinesPage]);

	useCommand(
		{
			key: 'baselines',
			text: t(showOverviewCmd ? 'ViewBaselineCmds' : 'Create'),
			iconProps: {iconName: showOverviewCmd ? 'History' : 'Add'},
			subMenuProps: showOverviewCmd ? {items: subcommands} : undefined,
			hidden: !isAdmin || (showOverviewCmd ? !subcommands.length : false),
			priority: 2,
			onClick() {
				setVisible();
			},
		},
		[subcommands],
	);

	return {
		infoForIsSuccessDialogOpen,
	};
};
