import {Command, useCommand} from 'hooks';
import {
	RegulationFormMode,
	SetRegulationFormMode,
} from './useRegulationFormMode';
import {UserRole} from 'types';
import {QueryResult} from '@apollo/client';

type OptionalFields = Partial<Omit<Command, 'onClick'>>;

type RequiredFields = Pick<Command, 'key'>;

type CommandFields = OptionalFields & RequiredFields;

interface Fields extends CommandFields {
	setMode: SetRegulationFormMode;
	stopPolling: QueryResult['stopPolling'] | null;
}

export const useEditRegulationCmd = (
	{setMode, stopPolling, ...other}: Fields,
	dependencies?: unknown[],
) => {
	return useCommand(
		{
			iconProps: {iconName: 'Edit'},
			onClick() {
				/**
				 * This prevents the regulation form from resetting each time the
				 * regulation changes. The regulation might change if its attachment
				 * URLs change.
				 */
				stopPolling?.();
				setMode(RegulationFormMode.Update);
			},
			roles: [UserRole.SystemAdministrator, UserRole.Vko],
			...other,
		},
		dependencies,
	);
};
