import {
	DefaultButton,
	FontIcon,
	Panel,
	Persona,
	PrimaryButton,
	Stack,
} from '@fluentui/react';
import {ControlledPeoplePicker} from 'components/hookForms/ControlledPeoplePicker';
import {useCommand} from 'hooks';
import React from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useAssignVehicleProjectPermissionsMutation} from '../../hooks/assignVehicleProjectPermissions.generated';
import {User, UserRole} from 'types';
import {useRemoveVehicleProjectPermissionsMutation} from '../../hooks/removeVehicleProjectPermissions.generated';
import {useParams} from 'react-router-dom';
import _ from 'lodash';
import {LoadWrapper} from 'components/LoadWrapper';
import {v4 as uuidv4} from 'uuid';
import {
	ProviderThatEnablesGettingTooltipsFromContext,
	createTooltipTranslationProviderFromNamespace,
} from 'features/localizedTooltips';

const TranslationProvider = createTooltipTranslationProviderFromNamespace(
	'features/VehicleProjects/permissionsForm',
);

interface Props {
	loading: boolean;
	userArray: Array<User>;
	refreshData: () => void;
}

export const PermissionsForm = ({loading, userArray, refreshData}: Props) => {
	const [isOpen, setIsOpen] = React.useState(false);
	const {t} = useTranslation('features/vehicleprojects', {
		keyPrefix: 'PermissionsForm',
	});
	const [assignVehicleProjectPermissions] =
		useAssignVehicleProjectPermissionsMutation();
	const [removeVehicleProjectPermissions] =
		useRemoveVehicleProjectPermissionsMutation();
	const {modelSeries} = useParams();

	const [users, setUsers] = React.useState<User[]>(userArray);

	useCommand(
		{
			key: 'permissions',
			text: t('Permissions'),
			iconProps: {iconName: 'Permissions'},
			priority: 4,
			onClick() {
				setIsOpen(true);
			},
			roles: [UserRole.SystemAdministrator],
		},
		[t],
	);

	const {handleSubmit, control, reset} = useForm<any>({
		reValidateMode: 'onSubmit',
		mode: 'all',
	});

	const handleSaveClick = React.useCallback(() => {
		const newUsers: User[] = [];
		handleSubmit(item => {
			item.userPermissions?.forEach((up: User) => {
				if (!users.find(u => u.id === up.id)) {
					newUsers.push(up);
				}
			});

			setUsers(prevUsers => prevUsers.concat(newUsers));
			if (item) {
				assignVehicleProjectPermissions({
					variables: {
						input: {
							modelSeries: modelSeries || '',
							users: item.userPermissions?.map((user: any) => user.id),
						},
					},
					refetchQueries: [],
				}).then(() => {
					refreshData();
				});
				reset();
				setIsOpen(false);
			}
		})();
	}, [modelSeries, users]);

	const handleCancelClick = React.useCallback(() => {
		reset();
		setIsOpen(false);
	}, [isOpen]);

	const onRenderFooterContent: any = React.useCallback(
		() => (
			<form>
				<PrimaryButton
					styles={{root: {marginRight: 8}}}
					onClick={handleSaveClick}
				>
					{t('AssignButton')}
				</PrimaryButton>
				<DefaultButton onClick={handleCancelClick}>
					{t('CancelButton')}
				</DefaultButton>
			</form>
		),
		[handleSaveClick, handleCancelClick],
	);

	const handleRemoveClick = React.useCallback(
		(user: User) => {
			setUsers(prevUsers => prevUsers.filter(u => u.id !== user.id));

			if (modelSeries && user.id) {
				removeVehicleProjectPermissions({
					variables: {input: {modelSeries: modelSeries || '', user: user.id}},
					refetchQueries: [],
				}).then(() => {
					refreshData();
				});
			}
		},
		[users, modelSeries],
	);

	const userPersonas = React.useMemo(
		() =>
			users.map((user: User) => (
				<Stack key={uuidv4()} horizontal styles={{root: {margin: 5}}}>
					<Persona key={uuidv4()} text={user.name || ''} />
					<FontIcon
						iconName='Cancel'
						style={{paddingTop: 17, color: 'red', cursor: 'pointer'}}
						key={uuidv4()}
						onClick={() => handleRemoveClick(user)}
					/>
				</Stack>
			)),
		[users],
	);

	return (
		<Panel
			isLightDismiss
			isOpen={isOpen}
			onDismiss={handleCancelClick}
			headerText={t('SetPermissionsHeaderText')}
			onRenderFooterContent={onRenderFooterContent}
			isFooterAtBottom={true}
		>
			<LoadWrapper loading={loading}>{userPersonas}</LoadWrapper>
			<hr />
			<TranslationProvider>
				<ProviderThatEnablesGettingTooltipsFromContext>
					<ControlledPeoplePicker
						control={control}
						label={t('PeoplePicker')}
						name={'userPermissions'}
						rules={{required: true}}
					/>
				</ProviderThatEnablesGettingTooltipsFromContext>
			</TranslationProvider>
		</Panel>
	);
};
