import {PersonaSize} from '@fluentui/react';
import {EntityListColumn, EntityPage} from 'components';
import {ViewAuthorizer} from 'components/ViewAuthorizer';
import {renderPersonas} from 'components/EntityList/ColumnRenderers';
import {ControlledPeoplePicker} from 'components/hookForms/ControlledPeoplePicker';
import {LoadWrapper} from 'components/LoadWrapper';
import {mapToRef} from 'helpers';
import React from 'react';
import {Control} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {VexCluster, UserRole} from 'types';
import {
	GetAllVexClustersDocument,
	useCreateVexClusterMutation,
	useDeleteVexClusterMutation,
	useGetAllVexClustersQuery,
	useUpdateVexClusterMutation,
} from './hooks/vexClusters.generated';
import {
	ProviderThatEnablesGettingTooltipsFromContext,
	VexClustersTooltipTranslationProvider,
} from 'features/localizedTooltips';

const AdminVexClustersPage: React.FC = () => {
	const {t} = useTranslation('features/adminsection', {
		keyPrefix: 'AdminVexClustersPage',
	});

	const {loading, data} = useGetAllVexClustersQuery();
	const [createVexClusterMutation] = useCreateVexClusterMutation();
	const [updateVexClusterMutation] = useUpdateVexClusterMutation();
	const [deleteVexClusterMutation] = useDeleteVexClusterMutation();

	const vexClusters = React.useMemo(() => data?.vexClusters ?? [], [data]);

	const refetchQueries = [GetAllVexClustersDocument];

	const createVexCluster = React.useCallback((vexCluster: VexCluster) => {
		createVexClusterMutation({
			variables: {
				input: {
					name: vexCluster.name,
					userRefs: mapToRef(vexCluster.users),
					approverRefs: mapToRef(vexCluster.approvers),
				},
			},
			refetchQueries,
		});
	}, []);

	const updateVexCluster = React.useCallback((vexCluster: VexCluster) => {
		updateVexClusterMutation({
			variables: {
				input: {
					id: vexCluster.id,
					name: vexCluster.name,
					userRefs: mapToRef(vexCluster.users),
					approverRefs: mapToRef(vexCluster.approvers),
				},
			},
			refetchQueries,
		});
	}, []);

	const deleteVexCluster = React.useCallback((id: string) => {
		deleteVexClusterMutation({
			variables: {
				input: {id},
			},
			refetchQueries,
		});
	}, []);

	const columns: EntityListColumn[] = React.useMemo(
		() => [
			{
				key: 'column2',
				name: t('Persons'),
				fieldName: 'users',
				minWidth: 200,
				maxWidth: 200,
				onRender: renderPersonas({size: PersonaSize.size8}),
			},
			{
				key: 'column3',
				name: t('SHApprovers'),
				fieldName: 'approvers',
				minWidth: 200,
				maxWidth: 200,
				onRender: renderPersonas({size: PersonaSize.size8}),
			},
		],
		[t],
	);

	const formElements = (control: Control<VexCluster>) => {
		return (
			<ProviderThatEnablesGettingTooltipsFromContext>
				<div>
					<ControlledPeoplePicker
						control={control}
						label={t('Persons')}
						name={'users'}
					/>
					<ControlledPeoplePicker
						control={control}
						label={t('SHApprovers')}
						name={'approvers'}
						roles={[UserRole.ShApprover]}
					/>
				</div>
			</ProviderThatEnablesGettingTooltipsFromContext>
		);
	};

	return (
		<ViewAuthorizer roles={[UserRole.SystemAdministrator]}>
			<LoadWrapper loading={loading}>
				<VexClustersTooltipTranslationProvider>
					<EntityPage
						items={vexClusters}
						entityDisplayName={t('VexClusters')}
						createEntity={createVexCluster}
						updateEntity={updateVexCluster}
						deleteEntity={deleteVexCluster}
						renderAdditionalFormElements={control => formElements(control)}
						additionalColumns={columns}
					/>
				</VexClustersTooltipTranslationProvider>
			</LoadWrapper>
		</ViewAuthorizer>
	);
};

export default AdminVexClustersPage;
