import {
	ISuggestionItemProps,
	ITagPickerProps,
	SuggestionsItem,
	TagPickerBase,
} from '@fluentui/react';
import React, {useCallback, useEffect, useRef} from 'react';
import {
	DefaultSuggestionModel,
	SuggestionModelOfCustomTagPicker,
	SuggestionWithGroupInfo,
} from '../VehicleProjectsReferencePicker.types';
import {GroupHeading} from './GroupHeading';

export interface PropsOfVehicleProjectSuggestion {
	suggestion: DefaultSuggestionModel;
	index: number;
	onRenderSuggestionsItem: NonNullable<
		ITagPickerProps['onRenderSuggestionsItem']
	>;
	onSuggestionClick: TagPickerBase['onSuggestionClick'];
}

export const VehicleProjectSuggestion = ({
	suggestion: suggestionWithIncorrectType,
	index,
	onRenderSuggestionsItem,
	onSuggestionClick,
}: PropsOfVehicleProjectSuggestion): JSX.Element => {
	const suggestion =
		suggestionWithIncorrectType as SuggestionModelOfCustomTagPicker;
	const {item} = suggestion;
	const {isNewGroup, modelSeries} = item;

	const ref = useRef<HTMLDivElement>(null);

	/**
	 * We don't need to implement this because we don't allow the user to remove
	 * suggestions.
	 */
	const handleRemoval = useCallback((): never => {
		throw new Error('Removing suggestions is not implemented.');
	}, []);

	type OnClick = ISuggestionItemProps<SuggestionWithGroupInfo>['onClick'];

	const getHandleSuggestionClick = useCallback(
		(item: SuggestionWithGroupInfo, index: number): OnClick => {
			return event => {
				return onSuggestionClick(event, item, index);
			};
		},
		[onSuggestionClick],
	);

	/**
	 * Suggested means that it was focused using the keyboard's arrow keys.
	 *
	 * We scroll to the suggestion so that the user can see it if they use the
	 * arrow keys to select suggestions, and if the selected suggestion is
	 * out of view.
	 */
	const scrollToSuggestionIfSelected = useCallback((): void => {
		if (!suggestion.selected) return;
		ref.current?.scrollIntoView({behavior: 'smooth', block: 'end'});
	}, [suggestion.selected, ref]);

	useEffect(scrollToSuggestionIfSelected, [scrollToSuggestionIfSelected]);

	return (
		<>
			{isNewGroup ? <GroupHeading>{modelSeries}</GroupHeading> : null}
			<div ref={ref}>
				<SuggestionsItem
					onClick={getHandleSuggestionClick(item, index)}
					onRemoveItem={handleRemoval}
					suggestionModel={suggestion}
					RenderSuggestion={onRenderSuggestionsItem}
				/>
			</div>
		</>
	);
};
