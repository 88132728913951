import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllVexClustersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllVexClustersQuery = { __typename?: 'Query', vexClusters?: Array<{ __typename?: 'VexCluster', id: string, name: string, createdAt?: any | null, modifiedAt?: any | null, createdBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null }, modifiedBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null }, userRefs: Array<{ __typename?: 'EntityRefOfUser', id: string }>, approverRefs: Array<{ __typename?: 'EntityRefOfUser', id: string }>, users: Array<{ __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null }>, approvers: Array<{ __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null }> }> | null };

export type CreateVexClusterMutationVariables = Types.Exact<{
  input: Types.CreateVexClusterInput;
}>;


export type CreateVexClusterMutation = { __typename?: 'Mutation', createVexCluster: { __typename?: 'CreateVexClusterPayload', vexCluster?: { __typename?: 'VexCluster', id: string } | null } };

export type UpdateVexClusterMutationVariables = Types.Exact<{
  input: Types.UpdateVexClusterInput;
}>;


export type UpdateVexClusterMutation = { __typename?: 'Mutation', updateVexCluster: { __typename?: 'UpdateVexClusterPayload', vexCluster?: { __typename?: 'VexCluster', id: string } | null } };

export type DeleteVexClusterMutationVariables = Types.Exact<{
  input: Types.DeleteVexClusterInput;
}>;


export type DeleteVexClusterMutation = { __typename?: 'Mutation', deleteVexCluster: { __typename?: 'DeleteVexClusterPayload', vexCluster?: { __typename?: 'VexCluster', id: string } | null } };

export type SyncNewVexClustersMutationVariables = Types.Exact<{
  input: Types.SyncNewVexClustersInput;
}>;


export type SyncNewVexClustersMutation = { __typename?: 'Mutation', syncNewVexClusters: { __typename?: 'SyncNewVexClustersPayload', taskId: string } };


export const GetAllVexClustersDocument = gql`
    query GetAllVexClusters {
  vexClusters {
    id
    name
    createdAt
    createdBy {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
    modifiedAt
    modifiedBy {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
    userRefs {
      id
    }
    approverRefs {
      id
    }
    users {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
    approvers {
      givenName
      id
      mail
      name
      surname
      userPrincipalName
    }
  }
}
    `;

/**
 * __useGetAllVexClustersQuery__
 *
 * To run a query within a React component, call `useGetAllVexClustersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllVexClustersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllVexClustersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllVexClustersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllVexClustersQuery, GetAllVexClustersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllVexClustersQuery, GetAllVexClustersQueryVariables>(GetAllVexClustersDocument, options);
      }
export function useGetAllVexClustersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllVexClustersQuery, GetAllVexClustersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllVexClustersQuery, GetAllVexClustersQueryVariables>(GetAllVexClustersDocument, options);
        }
export type GetAllVexClustersQueryHookResult = ReturnType<typeof useGetAllVexClustersQuery>;
export type GetAllVexClustersLazyQueryHookResult = ReturnType<typeof useGetAllVexClustersLazyQuery>;
export type GetAllVexClustersQueryResult = Apollo.QueryResult<GetAllVexClustersQuery, GetAllVexClustersQueryVariables>;
export const CreateVexClusterDocument = gql`
    mutation CreateVexCluster($input: CreateVexClusterInput!) {
  createVexCluster(input: $input) {
    vexCluster {
      id
    }
  }
}
    `;
export type CreateVexClusterMutationFn = Apollo.MutationFunction<CreateVexClusterMutation, CreateVexClusterMutationVariables>;

/**
 * __useCreateVexClusterMutation__
 *
 * To run a mutation, you first call `useCreateVexClusterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVexClusterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVexClusterMutation, { data, loading, error }] = useCreateVexClusterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVexClusterMutation(baseOptions?: Apollo.MutationHookOptions<CreateVexClusterMutation, CreateVexClusterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVexClusterMutation, CreateVexClusterMutationVariables>(CreateVexClusterDocument, options);
      }
export type CreateVexClusterMutationHookResult = ReturnType<typeof useCreateVexClusterMutation>;
export type CreateVexClusterMutationResult = Apollo.MutationResult<CreateVexClusterMutation>;
export type CreateVexClusterMutationOptions = Apollo.BaseMutationOptions<CreateVexClusterMutation, CreateVexClusterMutationVariables>;
export const UpdateVexClusterDocument = gql`
    mutation UpdateVexCluster($input: UpdateVexClusterInput!) {
  updateVexCluster(input: $input) {
    vexCluster {
      id
    }
  }
}
    `;
export type UpdateVexClusterMutationFn = Apollo.MutationFunction<UpdateVexClusterMutation, UpdateVexClusterMutationVariables>;

/**
 * __useUpdateVexClusterMutation__
 *
 * To run a mutation, you first call `useUpdateVexClusterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVexClusterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVexClusterMutation, { data, loading, error }] = useUpdateVexClusterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVexClusterMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVexClusterMutation, UpdateVexClusterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVexClusterMutation, UpdateVexClusterMutationVariables>(UpdateVexClusterDocument, options);
      }
export type UpdateVexClusterMutationHookResult = ReturnType<typeof useUpdateVexClusterMutation>;
export type UpdateVexClusterMutationResult = Apollo.MutationResult<UpdateVexClusterMutation>;
export type UpdateVexClusterMutationOptions = Apollo.BaseMutationOptions<UpdateVexClusterMutation, UpdateVexClusterMutationVariables>;
export const DeleteVexClusterDocument = gql`
    mutation DeleteVexCluster($input: DeleteVexClusterInput!) {
  deleteVexCluster(input: $input) {
    vexCluster {
      id
    }
  }
}
    `;
export type DeleteVexClusterMutationFn = Apollo.MutationFunction<DeleteVexClusterMutation, DeleteVexClusterMutationVariables>;

/**
 * __useDeleteVexClusterMutation__
 *
 * To run a mutation, you first call `useDeleteVexClusterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVexClusterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVexClusterMutation, { data, loading, error }] = useDeleteVexClusterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteVexClusterMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVexClusterMutation, DeleteVexClusterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVexClusterMutation, DeleteVexClusterMutationVariables>(DeleteVexClusterDocument, options);
      }
export type DeleteVexClusterMutationHookResult = ReturnType<typeof useDeleteVexClusterMutation>;
export type DeleteVexClusterMutationResult = Apollo.MutationResult<DeleteVexClusterMutation>;
export type DeleteVexClusterMutationOptions = Apollo.BaseMutationOptions<DeleteVexClusterMutation, DeleteVexClusterMutationVariables>;
export const SyncNewVexClustersDocument = gql`
    mutation SyncNewVexClusters($input: SyncNewVexClustersInput!) {
  syncNewVexClusters(input: $input) {
    taskId
  }
}
    `;
export type SyncNewVexClustersMutationFn = Apollo.MutationFunction<SyncNewVexClustersMutation, SyncNewVexClustersMutationVariables>;

/**
 * __useSyncNewVexClustersMutation__
 *
 * To run a mutation, you first call `useSyncNewVexClustersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncNewVexClustersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncNewVexClustersMutation, { data, loading, error }] = useSyncNewVexClustersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSyncNewVexClustersMutation(baseOptions?: Apollo.MutationHookOptions<SyncNewVexClustersMutation, SyncNewVexClustersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncNewVexClustersMutation, SyncNewVexClustersMutationVariables>(SyncNewVexClustersDocument, options);
      }
export type SyncNewVexClustersMutationHookResult = ReturnType<typeof useSyncNewVexClustersMutation>;
export type SyncNewVexClustersMutationResult = Apollo.MutationResult<SyncNewVexClustersMutation>;
export type SyncNewVexClustersMutationOptions = Apollo.BaseMutationOptions<SyncNewVexClustersMutation, SyncNewVexClustersMutationVariables>;