import {
	Panel,
	Separator,
	ActionButton,
	Stack,
	TextField,
	PrimaryButton,
	DefaultButton,
} from '@fluentui/react';
import {CommentLevel, CommentList} from 'components/CommentList';
import {GetRegulationDocument} from 'features/Regulations/hooks';
import {GetRegulatoryDocumentDetailsDocument} from 'features/RegulatoryDocuments/hooks/useGetRegulatoryDocumentDetails.generated';
import {useCommand} from 'hooks';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {Commentary} from 'types';
import {
	useAddRegulationCommentaryMutation,
	useAddRegulatoryDocumentCommentaryMutation,
	useAddRegulatoryDocumentParagraphCommentaryMutation,
} from './hooks/useAddCommentary.generated';

type CommentFormPropType = {
	comments: Commentary[];
	level: CommentLevel;
};

/**
 * This component appears to be always hidden.
 */
export const CommentForm: React.FC<CommentFormPropType> = ({
	comments,
	level,
}) => {
	const [showPanel, setShowPanel] = React.useState(false);
	const [showInput, setShowInput] = React.useState(false);
	const inputRef = React.useRef<any>();
	const [addRegulationCommentary] = useAddRegulationCommentaryMutation();
	const [addRegulatoryDocumentCommentary] =
		useAddRegulatoryDocumentCommentaryMutation();
	const [addRegulatoryDocumentParagraphCommentary] =
		useAddRegulatoryDocumentParagraphCommentaryMutation();
	const {regulationId, regulatoryDocumentId, paragraphId}: any = useParams();
	const {t} = useTranslation('features/regulatorydocuments', {
		keyPrefix: 'ParagraphCommentForm',
	});

	useCommand(
		{
			key: 'showComments',
			iconProps: {
				iconName: 'Comment',
			},
			farCommand: true,
			onClick() {
				setShowPanel(true);
			},
			title: t('Comments'),
			priority: 2,
			hidden: true,
		},
		[showPanel],
	);

	const handleCancelClick = React.useCallback(() => {
		setShowPanel(false);
		setShowInput(false);
	}, [showPanel, showInput]);

	const addRegulationComment = React.useCallback(
		() =>
			addRegulationCommentary({
				variables: {
					input: {
						id: regulationId,
						comment: inputRef.current.value,
					},
				},
				refetchQueries: [GetRegulationDocument],
			}),
		[addRegulationCommentary, level, regulationId],
	);

	const addRegulatoryDocumentComment = React.useCallback(
		() =>
			addRegulatoryDocumentCommentary({
				variables: {
					input: {
						id: regulatoryDocumentId,
						comment: inputRef.current.value,
					},
				},
				refetchQueries: [GetRegulatoryDocumentDetailsDocument],
			}),
		[addRegulatoryDocumentCommentary, regulatoryDocumentId],
	);

	const addParagraphComment = React.useCallback(
		() =>
			addRegulatoryDocumentParagraphCommentary({
				variables: {
					input: {
						commentary: {
							id: regulatoryDocumentId,
							comment: inputRef.current.value,
						},
						paragraphId,
					},
				},
				refetchQueries: [GetRegulatoryDocumentDetailsDocument],
			}),
		[
			addRegulatoryDocumentParagraphCommentary,
			regulatoryDocumentId,
			paragraphId,
		],
	);

	return (
		<Panel
			isOpen={showPanel}
			isLightDismiss
			onDismiss={handleCancelClick}
			headerText={t('CommentsHeader')}
		>
			<CommentList
				items={comments}
				regulationId={regulationId}
				regulatoryDocumentId={regulatoryDocumentId}
				paragraphId={paragraphId}
				level={level}
			/>
			<Separator />

			{!showInput && (
				<ActionButton
					iconProps={{iconName: 'Add'}}
					text={t('AddCommentButtonText')}
					onClick={() => setShowInput(true)}
				/>
			)}
			{showInput && (
				<Stack>
					<TextField label={t('AddCommentLabel')} componentRef={inputRef} />
					<Stack horizontal tokens={{childrenGap: 8}} styles={stackStyles}>
						<Stack.Item>
							<PrimaryButton
								text={t('Add')}
								onClick={() => {
									if (level === CommentLevel.Regulation) {
										addRegulationComment();
									}

									if (level === CommentLevel.RegulatoryDocument) {
										addRegulatoryDocumentComment();
									}

									if (level === CommentLevel.Paragraph) {
										addParagraphComment();
									}

									setShowInput(false);
								}}
								action={'enter'}
							/>
						</Stack.Item>
						<Stack.Item>
							<DefaultButton
								text={t('Cancel')}
								onClick={() => setShowInput(false)}
							/>
						</Stack.Item>
					</Stack>
				</Stack>
			)}
		</Panel>
	);
};

const stackStyles = {
	root: {paddingTop: 5},
};
