import React, {createContext} from 'react';

/**
 * This controls whether we show a loading indicator in ChangeHistory
 */
type AreAuditLogsLoading = boolean;

export type AuditLogsLoadingInfo = null | AreAuditLogsLoading;

export const AuditLogsLoadingStatusContext =
	createContext<AuditLogsLoadingInfo>(null);

interface Props {
	children: React.ReactNode;
	areAuditLogsLoading: AreAuditLogsLoading;
}

export const AuditLogsLoadingStatusProvider = ({
	children,
	areAuditLogsLoading,
}: Props): JSX.Element => {
	return (
		<AuditLogsLoadingStatusContext.Provider value={areAuditLogsLoading}>
			{children}
		</AuditLogsLoadingStatusContext.Provider>
	);
};
