import {
	DirectionalHint,
	IRenderFunction,
	ITextFieldProps,
	TextField,
} from '@fluentui/react';
import {
	TooltipOptionsForComponentOrColumn,
	TooltipWithComponentWithAriaDescribedBy,
} from 'features/localizedTooltips';
import {
	TooltipRendererCreatorService,
	TooltipRendererFields,
} from 'features/localizedTooltips/TooltipRendererCreator.service';
import React from 'react';

export type PropsOfTextFieldWithTooltip = Omit<
	ITextFieldProps,
	'onRenderLabel'
> &
	TooltipOptionsForComponentOrColumn;

export function TextFieldWithTooltip({
	tooltipHostProps,
	...other
}: PropsOfTextFieldWithTooltip) {
	const renderLabelUsingDefaultRenderer = ({
		defaultRender,
		props,
	}: TooltipRendererFields<null, ITextFieldProps>): JSX.Element => {
		return (
			<TooltipWithComponentWithAriaDescribedBy
				Component={defaultRender}
				tooltipHostProps={{
					directionalHint: DirectionalHint.leftCenter,
					...tooltipHostProps,
				}}
				{...props}
			/>
		);
	};

	const {getCallRendererIfDefaultOneExists} =
		new TooltipRendererCreatorService();

	const renderLabel: IRenderFunction<ITextFieldProps> =
		getCallRendererIfDefaultOneExists(null, renderLabelUsingDefaultRenderer);

	return <TextField {...other} onRenderLabel={renderLabel} />;
}
