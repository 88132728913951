import {format} from 'date-fns';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useReportsQuery} from './useReportsData.generated';
import {DEFAULT_DATE} from 'components/EntityList/EntityUtils';

export type Report = {
	id: string;
	name: string;
	regulationNumber: string;
	regulationId: string;
	dateEffective?: string;
	dateNewRegistration?: string;
	dateNewTypes?: string;
	modelYear?: number | null;
	keywords: string[];
	market: string[];
	status: string;
	createdAt: number;
	createdBy: string;
	summary?: string;
};

export const useReports = () => {
	const {data, loading} = useReportsQuery();
	const {t} = useTranslation('common/enums');

	const getFormattedDate = (date: any) =>
		date ? `${date}T00:00:00.000Z` : DEFAULT_DATE;

	const reports = useMemo(() => {
		const rawReports = data?.regulatoryDocuments?.nodes;

		if (!rawReports) return [];
		const sanitizedReports: Report[] = rawReports.map(report => ({
			id: report.id,
			name: report.name,
			summary: report.summary ?? undefined,
			regulationId: report.regulation?.id ?? '',
			regulationNumber: report.regulation?.regulationNumber ?? '',
			keywords: report.regulation?.mainKeywords.map(elem => elem.name) || [],
			market: report.regulation?.markets.map(elem => elem.name) || [],
			status: t(`RegulatoryDocumentStatus.${report.status}`),
			createdAt: report.createdAt ?? '',
			dateEffective: getFormattedDate(report.dateEffective),
			dateNewRegistration: getFormattedDate(report.dateNewRegistration),
			dateNewTypes: getFormattedDate(report.dateNewTypes),
			modelYear: report.modelYear ?? null,
			createdBy: report.createdBy.name ?? '',
		}));

		return sanitizedReports;
	}, [data]);

	return {
		reports,
		loading,
	};
};
