import React, {useCallback, useMemo} from 'react';
import {useSimulationDetailsQuery} from './simulationDetailsPage.generated';
import {
	DetailsPageForEntityLikeAProject,
	RenderRequirementsList,
} from '../../VehicleProjects/components/DetailsPageForEntityLikeAProject/DetailsPageForEntityLikeAProject';
import {IBreadcrumbItem} from '@fluentui/react';
import {CreateNavigator, useNavigatorCreator} from 'hooks/navigation';
import {PAGE_PATHS} from 'pages';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {createNamespaceForSimulationsFeature} from 'features/Simulations/simulations.utils';
import {RequirementsList} from 'features/VehicleProjects/components';
import {useCommand} from '../../../hooks';
import {
	ProjectDetailsPageParams,
	createGenPagePath,
	encodeGenAndModelSeries,
} from '../../VehicleProjects/vehicleProjectPagePaths';
import {EntityListContextProvider} from '../../../components/EntityList/EntityListContext';

const usePageTranslations = () => {
	const namespace: string = createNamespaceForSimulationsFeature(
		'simulationDetailsPage',
	);
	return useTranslation(namespace);
};

const defaultArray: never[] = [];

export function SimulationDetailsPage() {
	const createNavigator: CreateNavigator = useNavigatorCreator();
	const {t} = usePageTranslations();

	const navigate = useNavigate();

	interface PageParams {
		id: string;
	}

	const params = useParams() as unknown as PageParams;

	const {data, loading, refetch} = useSimulationDetailsQuery({
		variables: {id: params.id},
	});

	const generationOrDefaultValue = data?.simulation?.generationReference ?? '';
	const modelSeries = data?.simulation?.modelSeriesReference;

	const breadcrumbs = useMemo((): IBreadcrumbItem[] => {
		return [
			{
				key: 'simulations',
				text: t('TxtForSimulationsBreadcrumb'),
				onClick: createNavigator(PAGE_PATHS.simulations),
			},
			{
				key: 'simulation',
				/**
				 * The empty string is here just in case the simulation wasn't found.
				 */
				text: data?.simulation?.name ?? '',
			},
		];
	}, [data, createNavigator, t]);

	const createPathOfActiveTypeMarketsPage = useCallback((): string => {
		const encodeGenAndModelSeriesOrDefaults = (): ProjectDetailsPageParams => {
			return encodeGenAndModelSeries({
				generation: generationOrDefaultValue,
				modelSeries: modelSeries ?? '',
			});
		};

		const createGenPathWithEncodedParams = (): string => {
			const encodedFields: ProjectDetailsPageParams =
				encodeGenAndModelSeriesOrDefaults();
			return createGenPagePath(encodedFields);
		};

		const genPath: string = createGenPathWithEncodedParams();
		return `${genPath}/projects`;
	}, [generationOrDefaultValue, modelSeries]);

	useCommand(
		{
			key: 'regions',
			text: t('ActiveTypeMarketsButton'),
			iconProps: {iconName: 'Market'},
			priority: 3,
			onClick() {
				const path: string = createPathOfActiveTypeMarketsPage();
				navigate(path);
			},
		},
		[navigate, t, createPathOfActiveTypeMarketsPage],
	);

	const renderRequirements: RenderRequirementsList = useCallback(() => {
		/**
		 * We use a default array here in case there's an error.
		 */
		return (
			<RequirementsList
				items={data?.simulation?.assignedRequirements ?? defaultArray}
			/>
		);
	}, [data]);

	return (
		<EntityListContextProvider pageDetails={data?.simulation}>
			<DetailsPageForEntityLikeAProject
				refetchPageData={refetch}
				isLoading={loading}
				pageData={data?.simulation}
				breadcrumbs={breadcrumbs}
				generation={data?.simulation?.generationReference ?? ''}
				simulationName={data?.simulation?.name ?? ''}
				renderRequirementsList={renderRequirements}
				isSimulationDetailsPage
			/>
		</EntityListContextProvider>
	);
}
