import {RegulatoryDocument, RegulatoryDocumentParagraph} from 'types';

export type ParagraphWithId = Pick<RegulatoryDocumentParagraph, 'id'>;

type ParagraphId = ParagraphWithId['id'];

interface ServiceFields<
	MutationParagraph extends ParagraphWithId = ParagraphWithId,
> {
	/**
	 * Reasoning:
	 *
	 * Although we can derive these from the selected paragraphs, we already
	 * create them in the form's submission hook, so we may as well pass them here.
	 */
	idsToUpdate: ParagraphId[];
	regDocId: RegulatoryDocument['id'];
	paragraphs: MutationParagraph[];
	createParagraph: (paragraphId: ParagraphId) => MutationParagraph;
}

export type {ServiceFields as MutationKeyOptimisticDataUtilsFields};

export type FieldsFromMutationKeyUtilsForMutationKeyService<
	MutationParagraph extends ParagraphWithId,
> = Pick<ServiceFields<MutationParagraph>, 'idsToUpdate' | 'regDocId'>;

interface RegDocOptimisticData<Paragraph extends ParagraphWithId>
	extends Pick<RegulatoryDocument, '__typename' | 'id'> {
	paragraphs: Paragraph[];
}

export interface MutationKeyOptimisticData<Paragraph extends ParagraphWithId> {
	regulatoryDocument: RegDocOptimisticData<Paragraph>;
}

export class MutationKeyOptimisticDataUtils<Paragraph extends ParagraphWithId> {
	private idsToUpdate: ServiceFields['idsToUpdate'];
	private regDocId: RegulatoryDocument['id'];
	private paragraphs: ServiceFields<Paragraph>['paragraphs'];
	private createParagraph: ServiceFields<Paragraph>['createParagraph'];

	constructor({
		idsToUpdate,
		regDocId,
		paragraphs,
		createParagraph,
	}: ServiceFields<Paragraph>) {
		this.idsToUpdate = idsToUpdate;
		this.regDocId = regDocId;
		this.paragraphs = paragraphs;
		this.createParagraph = createParagraph;
	}

	private getParagraphOptimisticData = (paragraph: Paragraph): Paragraph => {
		const willBeUpdated: boolean = this.idsToUpdate.includes(paragraph.id);
		if (willBeUpdated) return this.createParagraph(paragraph.id);
		return paragraph;
	};

	private createParagraphsOptimisticData = (): Paragraph[] => {
		return this.paragraphs.map(this.getParagraphOptimisticData);
	};

	private createRegDocOptimisticData = (): RegDocOptimisticData<Paragraph> => {
		return {
			__typename: 'RegulatoryDocument',
			id: this.regDocId,
			paragraphs: this.createParagraphsOptimisticData(),
		};
	};

	/**
	 * Implementation notes:
	 *
	 * This does not generate the entire optimistic data because I couldn't find a
	 * good way to ensure the return type was accurate.
	 */
	public createMutationKeyOptimisticData =
		(): MutationKeyOptimisticData<Paragraph> => {
			return {regulatoryDocument: this.createRegDocOptimisticData()};
		};

	static createMutationKeyOptimisticData = <Paragraph extends ParagraphWithId>(
		fields: ServiceFields<Paragraph>,
	): MutationKeyOptimisticData<Paragraph> => {
		const service = new MutationKeyOptimisticDataUtils(fields);
		return service.createMutationKeyOptimisticData();
	};
}
