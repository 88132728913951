import {
	IconButton,
	IStackItemStyles,
	Label,
	Stack,
	TooltipHost,
} from '@fluentui/react';
import * as React from 'react';
import {useFieldArray} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {ControlledDatePicker} from './ControlledDatePicker';
import {ControlledTextField} from './ControlledTextField';
import {HookFormProps} from './HookFormProps';
import {AppendButton} from './AppendButton';
import {
	LabelWithTooltip,
	createSubfieldTranslationKey,
} from 'features/localizedTooltips';
import {PhaseDate} from 'types';
import {
	ActionTags,
	ActionTagsProps,
} from 'features/RegulatoryDocuments/components/DocumentDetails/ActionTags';

type PhasePickerProps = {
	label: string;
	defaultValueDate?: string;
	defaultValueStatus?: string;
	disabled?: boolean;
	actionTags?: ActionTagsProps['tags'];
};

/**
 * ! Important
 *
 * This component requires setting up translations for the fields. See this
 * component's definition to learn which fields need translation.
 */
export const PhasePicker: React.FC<HookFormProps & PhasePickerProps> = ({
	control,
	name,
	label,
	defaultValueDate,
	defaultValueStatus,
	disabled = false,
	actionTags,
	...props
}) => {
	const {t} = useTranslation('components/phasepicker', {
		keyPrefix: 'PhasePicker',
	});
	const {fields, remove, append} = useFieldArray({
		control,
		name,
	});

	const createTranslationKeyOfPhaseDate = (field: keyof PhaseDate): string => {
		return createSubfieldTranslationKey(`${name}.${field}`);
	};

	const checkEmpty = (date?: Date) => {
		return date ? true : t('EmptyValueValidation');
	};

	return (
		<Stack>
			<Stack horizontal tokens={{childrenGap: 5}}>
				<Label>{label}</Label>
				<ActionTags tags={actionTags} itemStyle={{paddingTop: 8}} />
			</Stack>
			{fields.map((_item: any, index: any) => {
				return (
					<Stack key={index} horizontal tokens={{childrenGap: 8}} {...props}>
						<Stack.Item grow styles={stackItemStyles}>
							{index === 0 && (
								<LabelWithTooltip
									translationKey={createTranslationKeyOfPhaseDate('date')}
									tooltipHostProps={{shouldGetTextFromContext: true}}
								>
									{t('DateLabel', {label})}
								</LabelWithTooltip>
							)}
							<ControlledDatePicker
								rules={{validate: {checkEmpty}}}
								control={control}
								name={`${name}[${index}].date`}
								ariaLabel={'phaseDatePicker'}
								disabled={disabled}
								defaultValue={defaultValueDate || undefined}
							/>
						</Stack.Item>
						<Stack.Item grow styles={stackItemStyles}>
							{index === 0 && (
								<LabelWithTooltip
									translationKey={createTranslationKeyOfPhaseDate('status')}
									tooltipHostProps={{shouldGetTextFromContext: true}}
								>
									{t('TextLabel', {label})}
								</LabelWithTooltip>
							)}
							<ControlledTextField
								control={control}
								name={`${name}[${index}].status`}
								ariaLabel={'phaseTextField'}
								disabled={disabled}
								defaultValue={defaultValueStatus || ''}
							/>
						</Stack.Item>
						<Stack.Item>
							<TooltipHost content={t('ToolTipDelete')}>
								<IconButton
									styles={{
										root: {marginTop: index === 0 ? 28 : 0},
									}}
									iconProps={{iconName: 'Delete'}}
									onClick={() => remove(index)}
									ariaLabel={'phaseRemoveButton'}
									disabled={disabled}
								/>
							</TooltipHost>
						</Stack.Item>
					</Stack>
				);
			})}
			<Stack>
				<Stack.Item>
					<AppendButton
						onClick={() => {
							append({});
						}}
						label={label}
						name={name}
						disabled={disabled}
					/>
				</Stack.Item>
			</Stack>
		</Stack>
	);
};

const stackItemStyles: IStackItemStyles = {
	root: {
		width: 100,
	},
};
