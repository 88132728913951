import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMarketFormDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMarketFormDataQuery = { __typename?: 'Query', countries?: Array<{ __typename?: 'Country', id: string, name: string }> | null };


export const GetMarketFormDataDocument = gql`
    query GetMarketFormData {
  countries {
    id
    name
  }
}
    `;

/**
 * __useGetMarketFormDataQuery__
 *
 * To run a query within a React component, call `useGetMarketFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMarketFormDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMarketFormDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMarketFormDataQuery(baseOptions?: Apollo.QueryHookOptions<GetMarketFormDataQuery, GetMarketFormDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMarketFormDataQuery, GetMarketFormDataQueryVariables>(GetMarketFormDataDocument, options);
      }
export function useGetMarketFormDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMarketFormDataQuery, GetMarketFormDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMarketFormDataQuery, GetMarketFormDataQueryVariables>(GetMarketFormDataDocument, options);
        }
export type GetMarketFormDataQueryHookResult = ReturnType<typeof useGetMarketFormDataQuery>;
export type GetMarketFormDataLazyQueryHookResult = ReturnType<typeof useGetMarketFormDataLazyQuery>;
export type GetMarketFormDataQueryResult = Apollo.QueryResult<GetMarketFormDataQuery, GetMarketFormDataQueryVariables>;