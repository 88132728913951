import {Breadcrumb, IBreadcrumbProps} from '@fluentui/react';
import {SideNavigationContext} from 'appShell/Layout/SideNavigation/SideNavigationProvider';
import {
	SidebarInfo,
	useSidebarInfoAndValidateIt,
} from 'context/SidebarInfoProvider';
import React from 'react';

type Props = Omit<IBreadcrumbProps, 'key'>;

export function AutoResizingBreadcrumbs(props: Props) {
	const {isOpen}: SidebarInfo = useSidebarInfoAndValidateIt(
		SideNavigationContext,
	);
	/**
	 * We set the key to whether or not it's open because the breadcrumbs must
	 * resize when the user opens or closes the sidebar. Otherwise, the
	 * breadcrumbs might overflow the page.
	 */
	return <Breadcrumb {...props} key={isOpen.toString()} />;
}
