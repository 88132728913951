/* eslint-disable complexity */
import {
	DefaultButton,
	Dialog,
	DialogFooter,
	DialogType,
	IDialogContentProps,
	TextField,
} from '@fluentui/react';
import {useUserContext} from 'authentication/UserContext';
import {PeoplePicker} from 'components/hookForms/ControlledPeoplePicker';
import {GetWorkbenchItemsDocument} from 'features/Dashboard/hooks/workbenchItems.generated';
import {GetAllRegulationsDocument} from 'features/Regulations/hooks';
import {useUpdateRegulatoryDocumentMutation} from 'features/RegulatoryDocuments/hooks';
import {useCreateInterimRequirementMutation} from 'features/RegulatoryDocuments/hooks/useCreateInterimRequirment.generated';
import {useDismantleDocumentMutation} from 'features/RegulatoryDocuments/hooks/useDismantleDocument.generated';
import {GetRegulatoryDocumentDetailsDocument} from 'features/RegulatoryDocuments/hooks/useGetRegulatoryDocumentDetails.generated';
import {convertToRef, mapToRef} from 'helpers';
import {useCommand, useNotificationBar} from 'hooks';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {
	Origin,
	RegulatoryDocument,
	WorkflowStatus,
	UserRole,
	UpdateRegulatoryDocumentInput,
} from 'types';
import {SplitDocumentLegend} from '../../SplitDocumentLegend';
import {
	LabelWithTooltip,
	ProviderThatEnablesGettingTooltipsFromContext,
	createTooltipTranslationProviderFromNamespace,
} from 'features/localizedTooltips';
import {ReasonField} from './ReasonField';
import {ActionButton} from 'components';
import {omit} from 'lodash';
import {isApprovalModified} from 'features/RegulatoryDocuments/regulatoryUtils';
import {useMergeReapprovalRegulatoryDocumentChangesMutation} from 'features/RegulatoryDocuments/hooks/useMergeReapprovalRegulatoryDocumentChanges.generated';

enum WorkflowDialogType {
	None,
	Archive,
	Dismantle,
	QualityControlInternal,
	QualityControlExternal,
	ProgressExternal,
	Examination,
	Clearing,
	ClearingNOK,
	ClearingOK,
	ClearingWithReservations,
	ReapprovalOK,
	ReappovalNOK,
	SendToReapprove,
}

const TooltipTranslationProvider =
	createTooltipTranslationProviderFromNamespace(
		'components/DocumentDetails/workflowDialog',
	);

/**
 * Implementation notes:
 *
 * We don't need to disable any commands here while the Edit Paragraphs Form is submitting
 * because the user is navigated to the homepage after submitting the mutation here.
 */
export const WorkflowDialog: React.FC<{
	regulatoryDocument: RegulatoryDocument;
	refetchAuditLogs?: () => void;
}> = ({regulatoryDocument, refetchAuditLogs}) => {
	const {t} = useTranslation('features/regulatorydocuments', {
		keyPrefix: 'WorkflowDialog',
	});

	const {username, isAdmin, encodedUserId} = useUserContext();
	const [mergeReapprovalRegulatoryDocumentChanges] =
		useMergeReapprovalRegulatoryDocumentChangesMutation();

	const navigate = useNavigate();

	const [users, setUsers] = React.useState([]);

	const onUserInputChange = React.useCallback(
		(items: any) => setUsers(items || []),
		[users],
	);

	const [showDialog, setShowDialog] = React.useState<WorkflowDialogType>(
		WorkflowDialogType.None,
	);

	const [internal, setInternal] = React.useState(true);

	// React.useEffect(() => setShowDialog(WorkflowDialogType.SendToReapprove), []);

	const showTitle = React.useCallback(() => {
		if (showDialog === WorkflowDialogType.Clearing) {
			return t('ConfirmClearing');
		}

		if (showDialog === WorkflowDialogType.ClearingOK) {
			return t('ClearingOKHeader');
		}

		if (showDialog === WorkflowDialogType.ClearingWithReservations) {
			return t('ClearingWithReservationsHeader');
		}

		if (showDialog === WorkflowDialogType.ClearingNOK) {
			return t('ClearingNOKHeader');
		}

		if (showDialog === WorkflowDialogType.ReapprovalOK) {
			return t('ReapprovinfOKHeader');
		}

		if (showDialog === WorkflowDialogType.ReappovalNOK) {
			return t('ReapprovinfNOKHeader');
		}

		if (showDialog === WorkflowDialogType.Archive) {
			return t('ConfirmArchive');
		}

		if (showDialog === WorkflowDialogType.Dismantle) {
			return internal
				? t('ConfirmDismantleInternal')
				: t('ConfirmDismantleExternal');
		}

		if (showDialog === WorkflowDialogType.QualityControlInternal) {
			return t('ConfirmFinishQualityControlInternal');
		}

		if (showDialog === WorkflowDialogType.QualityControlExternal) {
			return t('ConfirmFinishQualityControlExternal');
		}

		if (showDialog === WorkflowDialogType.ProgressExternal) {
			return t('ConfirmProgressExternal');
		}

		if (showDialog === WorkflowDialogType.Examination) {
			return t('ConfirmExamination');
		}

		if (showDialog === WorkflowDialogType.SendToReapprove) {
			return t('SendToReapprove');
		}
	}, [showDialog, t, internal]);

	const showSubText = React.useCallback(() => {
		if (showDialog === WorkflowDialogType.Clearing) {
			return t('ConfirmClearingText', {
				name: regulatoryDocument.name,
			});
		}

		if (showDialog === WorkflowDialogType.ClearingOK) {
			return t('ClearingOKText', {
				name: regulatoryDocument.name,
			});
		}

		if (showDialog === WorkflowDialogType.ClearingWithReservations) {
			return t('ClearingWithReservationsText', {
				name: regulatoryDocument.name,
			});
		}

		if (showDialog === WorkflowDialogType.ClearingNOK) {
			return t('ClearingNOKText', {
				name: regulatoryDocument.name,
			});
		}

		if (showDialog === WorkflowDialogType.Archive) {
			return t('ConfirmArchiveText', {
				name: regulatoryDocument.name,
			});
		}

		if (showDialog === WorkflowDialogType.Dismantle) {
			return internal
				? t('ConfirmDismantleInternalText', {name: regulatoryDocument.name})
				: t('ConfirmDismantleExternalText', {name: regulatoryDocument.name});
		}

		if (showDialog === WorkflowDialogType.QualityControlInternal) {
			return t('ConfirmFinishQualityControlText', {
				name: regulatoryDocument.name,
			});
		}

		if (showDialog === WorkflowDialogType.QualityControlExternal) {
			return t('ConfirmFinishQualityControlText', {
				name: regulatoryDocument.name,
			});
		}

		if (showDialog === WorkflowDialogType.ProgressExternal) {
			return t('ConfirmProgressExternalText', {
				name: regulatoryDocument.name,
			});
		}

		if (showDialog === WorkflowDialogType.Examination) {
			return t('ConfirmExaminationText', {
				name: regulatoryDocument.name,
			});
		}
	}, [showDialog, regulatoryDocument, t]);

	const dialogContentProps: IDialogContentProps = React.useMemo(
		() => ({
			type: DialogType.normal,
			title: showTitle(),
			subText: showSubText(),
		}),
		[showTitle, showSubText],
	);

	const [updateRegulatoryDocument] = useUpdateRegulatoryDocumentMutation();
	const [createInterimRequirement] = useCreateInterimRequirementMutation();
	const [dismantleDocument] = useDismantleDocumentMutation();

	const [reason, setReason] = React.useState('');

	const updateRegulatoryDocumentCommon = (
		workflowStatus: WorkflowStatus,
		extraWorkflowProps = {},
		clearingVKO?: any,
		reapprovingVKO?: any,
	) => {
		const input: UpdateRegulatoryDocumentInput = {
			id: regulatoryDocument.id,
			name: regulatoryDocument.name,
			status: regulatoryDocument.status,
			summary: regulatoryDocument.summary,
			dateEffective: regulatoryDocument.dateEffective,
			dateExpiration: regulatoryDocument.dateExpiration,
			predecessorRef: convertToRef(regulatoryDocument.predecessor),
			successorRef: convertToRef(regulatoryDocument.successor),
			documentStatus: regulatoryDocument.documentStatus,
			topicManagementSummary: regulatoryDocument.topicManagementSummary,
			workflow: {
				status: workflowStatus,
				...extraWorkflowProps,
			},
			regulationRef: convertToRef(regulatoryDocument.regulation),
			serviceProviderRefs: [],
		};
		if (regulatoryDocument.finalVersionRef) {
			input.finalVersionRef = omit(
				regulatoryDocument.finalVersionRef,
				'__typename',
			);
		}

		return updateRegulatoryDocument({
			variables: {
				input,
				clearingVKOs: clearingVKO,
				reapprovingVKO,
			},
			refetchQueries: [
				GetRegulatoryDocumentDetailsDocument,
				GetAllRegulationsDocument,
				GetWorkbenchItemsDocument,
			],
		});
	};

	const onArchiveClick = React.useCallback(async () => {
		await updateRegulatoryDocumentCommon(WorkflowStatus.Archived, {
			message: reason,
		})
			.then(() => {
				setShowDialog(WorkflowDialogType.None);
				setMessage(t('ArchiveSaveMessage'));
				navigate('/');
			})
			.catch(error => {
				console.error('Error:', error);
			});
	}, [regulatoryDocument, reason, showDialog]);

	const onDismantleClick = React.useCallback(async () => {
		await updateRegulatoryDocumentCommon(
			internal
				? WorkflowStatus.QualityControlInternal
				: WorkflowStatus.QualityControlExternal,
		)
			.then(() => {
				dismantleDocument({
					variables: {
						input: regulatoryDocument.id,
						fastTrack: false,
					},
				}).catch(error => {
					console.error('Error:', error);
				});
				setShowDialog(WorkflowDialogType.None);
				setMessage(t('DismantleSaveMessage'));
				navigate('/');
			})
			.catch(error => {
				console.error('Error:', error);
			});
	}, [regulatoryDocument, showDialog, internal]);

	const onFinishQualityControlInternalClick = React.useCallback(async () => {
		await updateRegulatoryDocumentCommon(WorkflowStatus.InProgressInternal)
			.then(() => {
				setShowDialog(WorkflowDialogType.None);
				setMessage(t('FinishQualityControlInternalSaveMessage'));
				navigate('/');
			})
			.catch(error => {
				console.error('Error:', error);
			});
	}, [regulatoryDocument, showDialog]);

	const onFinishQualityControlExternalClick = React.useCallback(async () => {
		await updateRegulatoryDocumentCommon(WorkflowStatus.InProgressExternal)
			.then(() => {
				setShowDialog(WorkflowDialogType.None);
				setMessage(t('FinishQualityControlExternalSaveMessage'));
				navigate('/');
			})
			.catch(error => {
				console.error('Error:', error);
			});
	}, [regulatoryDocument, showDialog]);

	const onProgressExternalClick = React.useCallback(async () => {
		await updateRegulatoryDocumentCommon(WorkflowStatus.InProgressExternal, {
			message: reason,
		})
			.then(() => {
				setShowDialog(WorkflowDialogType.None);
				setMessage(t('ProgressExternalSaveMessage'));
				navigate('/');
			})
			.catch(error => {
				console.error('Error:', error);
			});
	}, [regulatoryDocument, showDialog, reason]);

	const onDeclineReapproval = React.useCallback(async () => {
		await updateRegulatoryDocumentCommon(
			WorkflowStatus.Modified,
			{
				message: reason,
			},
			null,
			null,
		)
			.then(() => {
				setShowDialog(WorkflowDialogType.None);
				setMessage(t('ProgressExternalSaveMessage'));
				navigate('/');
			})
			.catch(error => {
				console.error('Error:', error);
			});
	}, [regulatoryDocument, showDialog, reason]);

	const onConfirmReapproval = React.useCallback(async () => {
		if (!regulatoryDocument?.finalVersionRef?.id) {
			console.error(`No final version regulation found!`);
			return;
		}

		await mergeReapprovalRegulatoryDocumentChanges({
			variables: {
				input: {
					finalRegulatoryDocumentId: regulatoryDocument.finalVersionRef.id,
					modifiedRegulatoryDocumentId: regulatoryDocument.id,
				},
			},
		})
			.then(() => {
				setShowDialog(WorkflowDialogType.None);
				navigate(`/regulations/${regulatoryDocument?.regulation?.id}`);
			})
			.catch(error => {
				console.error('Error:', error);
			});
	}, [regulatoryDocument, showDialog, reason]);

	const onClearingClick = React.useCallback(async () => {
		await updateRegulatoryDocumentCommon(
			WorkflowStatus.Clearing,
			{},
			mapToRef(users),
		)
			.then(() => {
				setShowDialog(WorkflowDialogType.None);
				setMessage(t('ClearingSaveMessage'));
				navigate('/');
			})
			.catch(error => {
				console.error('Error:', error);
			});
	}, [regulatoryDocument, users, showDialog]);

	const onSendToReapprove = React.useCallback(async () => {
		await updateRegulatoryDocumentCommon(
			WorkflowStatus.Modified,
			{
				reapprovingMessage: reason,
			},
			null,
			convertToRef(users[0]),
		)
			.then(() => {
				setShowDialog(WorkflowDialogType.None);
				refetchAuditLogs?.();
			})
			.catch(error => {
				console.error('Error:', error);
			});
	}, [regulatoryDocument, showDialog, users, reason]);

	const onClearingWithReservationsClick = React.useCallback(async () => {
		await updateRegulatoryDocumentCommon(
			WorkflowStatus.ClearingWithReservations,
			{},
			mapToRef(regulatoryDocument.workflow.clearingVKO),
		)
			.then(() => {
				setShowDialog(WorkflowDialogType.None);
				setMessage(t('ClearingSaveMessage'));
				navigate('/');
			})
			.catch(error => {
				console.error('Error:', error);
			});
	}, [regulatoryDocument, showDialog]);

	const onClearingNOKClick = React.useCallback(async () => {
		await updateRegulatoryDocumentCommon(WorkflowStatus.InProgressInternal, {
			message: reason,
		})
			.then(() => {
				setShowDialog(WorkflowDialogType.None);
				setMessage(t('ClearingNOKSaveMessage'));
				navigate('/');
			})
			.catch(error => {
				console.error('Error:', error);
			});
	}, [regulatoryDocument, reason, showDialog]);

	const onClearingOKClick = React.useCallback(async () => {
		await updateRegulatoryDocumentCommon(WorkflowStatus.Finalized, {}, null)
			.then(() =>
				createInterimRequirement({
					variables: {regulatoryDocumentId: regulatoryDocument.id},
				}),
			)
			.then(() => {
				setShowDialog(WorkflowDialogType.None);
				setMessage(t('ClearingOKSaveMessage'));
				navigate('/');
			})
			.catch(error => {
				console.error('Error:', error);
			});
	}, [regulatoryDocument, showDialog]);

	const onExaminationClick = React.useCallback(async () => {
		await updateRegulatoryDocumentCommon(WorkflowStatus.Examination)
			.then(() => {
				setShowDialog(WorkflowDialogType.None);
				setMessage(t('ExaminationSaveMessage'));
				navigate('/');
			})
			.catch(error => {
				console.error('Error:', error);
			});
	}, [regulatoryDocument, showDialog]);

	const onDismiss = React.useCallback(
		() => setShowDialog(WorkflowDialogType.None),
		[],
	);

	const onInputChange = React.useCallback(
		(_: any, val?: string) => setReason(val ?? ''),
		[reason],
	);

	const isClearingVKO = React.useMemo(
		() =>
			username ===
			regulatoryDocument.workflow?.clearingVKO[0]?.userPrincipalName,
		[regulatoryDocument, username],
	);

	useCommand(
		{
			key: 'archive',
			priority: 9,
			text: t('ArchiveRegulatoryDocument'),
			iconProps: {iconName: 'Archive'},
			onClick: () => setShowDialog(WorkflowDialogType.Archive),
			hidden:
				regulatoryDocument.workflow?.status !== WorkflowStatus.New ||
				regulatoryDocument.origin !== Origin.External,
			roles: [UserRole.Vko, UserRole.SystemAdministrator],
		},
		[showDialog, t, regulatoryDocument],
	);

	useCommand(
		{
			key: 'dismantle',
			priority: 10,
			text: t('DismantleRegulatoryDocument'),
			iconProps: {iconName: 'SplitObject'},
			hidden: regulatoryDocument.workflow?.status !== WorkflowStatus.New,
			disabled: !regulatoryDocument.document,
			roles: [UserRole.Vko, UserRole.SystemAdministrator],
			subMenuProps: {
				items: [
					{
						key: 'dismantleInternal',
						onClick() {
							setShowDialog(WorkflowDialogType.Dismantle);
							setInternal(true);
						},
						text: t('DismantleInternal'),
					},
					...(isAdmin
						? [
								{
									key: 'dismantleExternal',
									onClick() {
										setShowDialog(WorkflowDialogType.Dismantle);
										setInternal(false);
									},
									text: t('DismantleExternal'),
								},
						  ]
						: []),
				],
			},
		},
		[showDialog, t, regulatoryDocument, internal],
	);

	useCommand(
		{
			key: 'finishQualityControl',
			priority: 9,
			text: t('FinishQualityControlRegulatoryDocument'),
			iconProps: {iconName: 'ReleaseDefinition'},
			hidden:
				regulatoryDocument.workflow?.status !==
					WorkflowStatus.QualityControlInternal &&
				regulatoryDocument.workflow?.status !==
					WorkflowStatus.QualityControlExternal,
			roles: [
				UserRole.ServiceProvider,
				UserRole.SystemAdministrator,
				UserRole.Vko,
			],
			onClick: () =>
				regulatoryDocument.workflow?.status ===
				WorkflowStatus.QualityControlInternal
					? setShowDialog(WorkflowDialogType.QualityControlInternal)
					: setShowDialog(WorkflowDialogType.QualityControlExternal),
		},
		[showDialog, t, regulatoryDocument],
	);

	useCommand(
		{
			key: 'inProgressExternal',
			priority: 9,
			text: t('InProgressExternalRegulatoryDocument'),
			iconProps: {iconName: 'ReleaseDefinition'},
			onClick: () => setShowDialog(WorkflowDialogType.ProgressExternal),
			hidden:
				(regulatoryDocument.workflow?.status !==
					WorkflowStatus.InProgressInternal &&
					regulatoryDocument.workflow?.status !== WorkflowStatus.Examination) ||
				regulatoryDocument.workflow?.message === null,
			roles: [UserRole.Vko, UserRole.SystemAdministrator],
		},
		[showDialog, t, regulatoryDocument],
	);

	useCommand(
		{
			key: 'sentToClearing',
			priority: 9,
			text: t('Clearing'),
			iconProps: {iconName: 'ReleaseGate'},
			onClick: () => setShowDialog(WorkflowDialogType.Clearing),
			hidden:
				regulatoryDocument.workflow?.status !==
					WorkflowStatus.InProgressInternal &&
				regulatoryDocument.workflow?.status !== WorkflowStatus.Examination,
			roles: [UserRole.Vko, UserRole.SystemAdministrator],
		},
		[showDialog, t, regulatoryDocument],
	);

	useCommand(
		{
			key: 'sendToReapprove',
			priority: 10,
			text: t('SendToReapprove'),
			iconProps: {iconName: 'ReleaseGate'},
			onClick: () => setShowDialog(WorkflowDialogType.SendToReapprove),

			hidden:
				regulatoryDocument.workflow?.status !== WorkflowStatus.Modified ||
				regulatoryDocument?.createdBy?.id !== encodedUserId ||
				isApprovalModified(regulatoryDocument.workflow?.reapprovingVKO?.id),
			roles: [UserRole.Vko, UserRole.SystemAdministrator],
		},
		[showDialog, t, regulatoryDocument],
	);
	useCommand(
		{
			key: 'clearingOk',
			priority: 10,
			text: t('ClearingOK'),
			iconProps: {iconName: 'CheckMark'},
			onClick: () => setShowDialog(WorkflowDialogType.ClearingOK),
			hidden: !(
				(regulatoryDocument.workflow?.status === WorkflowStatus.Clearing ||
					regulatoryDocument.workflow?.status ===
						WorkflowStatus.ClearingWithReservations) &&
				(isClearingVKO || isAdmin)
			),
			roles: [UserRole.Vko, UserRole.SystemAdministrator],
		},
		[showDialog, t, regulatoryDocument, username, isClearingVKO],
	);

	useCommand(
		{
			key: 'ReapproveOk',
			priority: 10,
			text: t('ReapproveOk'),
			iconProps: {iconName: 'CheckMark'},
			onClick: () => setShowDialog(WorkflowDialogType.ReapprovalOK),
			hidden: !(
				regulatoryDocument.workflow?.status === WorkflowStatus.Modified &&
				regulatoryDocument.workflow?.reapprovingVKO?.id === encodedUserId
			),
			roles: [UserRole.Vko, UserRole.SystemAdministrator],
		},
		[showDialog, t, regulatoryDocument, username, isClearingVKO],
	);
	useCommand(
		{
			key: 'ReapproveNOk',
			priority: 10,
			text: t('ReapproveNOk'),
			iconProps: {iconName: 'CheckMark'},
			onClick: () => setShowDialog(WorkflowDialogType.ReappovalNOK),
			hidden: !(
				regulatoryDocument.workflow?.status === WorkflowStatus.Modified &&
				regulatoryDocument.workflow?.reapprovingVKO?.id === encodedUserId
			),
			roles: [UserRole.Vko, UserRole.SystemAdministrator],
		},
		[showDialog, t, regulatoryDocument, username, isClearingVKO],
	);

	useCommand(
		{
			key: 'clearingWithReservations',
			priority: 12,
			text: t('ClearingWithReservations'),
			iconProps: {iconName: 'ProgressRingDots'},
			onClick: () => setShowDialog(WorkflowDialogType.ClearingWithReservations),
			hidden: true,
			/* See ET164-1181: hide this button until further specification
					!(
				regulatoryDocument.workflow?.status === WorkflowStatus.Clearing &&
				username ===
					regulatoryDocument.workflow?.clearingVKO[0]?.userPrincipalName
			),
			*/
			roles: [UserRole.Vko, UserRole.SystemAdministrator],
		},
		[showDialog, t, regulatoryDocument, username],
	);

	useCommand(
		{
			key: 'clearingNotOk',
			priority: 12,
			text: t('ClearingNotOk'),
			iconProps: {iconName: 'Cancel'},
			onClick: () => setShowDialog(WorkflowDialogType.ClearingNOK),
			hidden: !(
				regulatoryDocument.workflow?.status === WorkflowStatus.Clearing &&
				(isClearingVKO || isAdmin)
			),
			roles: [UserRole.Vko, UserRole.SystemAdministrator],
		},
		[showDialog, t, regulatoryDocument, username, isClearingVKO],
	);

	useCommand(
		{
			key: 'examination',
			priority: 9,
			text: t('Examination'),
			iconProps: {iconName: 'ReleaseDefinition'},
			onClick: () => setShowDialog(WorkflowDialogType.Examination),
			hidden:
				regulatoryDocument.workflow?.status !==
				WorkflowStatus.InProgressExternal,
			roles: [UserRole.VkoExternal, UserRole.SystemAdministrator],
		},
		[showDialog, t, regulatoryDocument],
	);

	const {setMessage} = useNotificationBar();

	return (
		<TooltipTranslationProvider>
			<ProviderThatEnablesGettingTooltipsFromContext>
				{showDialog === WorkflowDialogType.Examination && (
					<Dialog
						hidden={!showDialog}
						dialogContentProps={dialogContentProps}
						modalProps={{
							isBlocking: true,
							styles: {main: {maxWidth: 450}},
						}}
						onDismiss={onDismiss}
					>
						<DialogFooter>
							<ActionButton onClick={onExaminationClick}>
								{t('ExaminationButton')}
							</ActionButton>
							<DefaultButton onClick={onDismiss} text={t('CancelButton')} />
						</DialogFooter>
					</Dialog>
				)}
				{showDialog === WorkflowDialogType.Archive && (
					<Dialog
						hidden={!showDialog}
						dialogContentProps={dialogContentProps}
						modalProps={{
							isBlocking: true,
							styles: {main: {maxWidth: 450}},
						}}
						onDismiss={onDismiss}
					>
						<ReasonField
							onChange={onInputChange}
							required
							multiline
							autoAdjustHeight
							label={t('ReasonLabel')}
						/>
						<DialogFooter>
							<ActionButton onClick={onArchiveClick} disabled={!reason}>
								{t('ArchiveButton')}
							</ActionButton>
							<DefaultButton onClick={onDismiss} text={t('CancelButton')} />
						</DialogFooter>
					</Dialog>
				)}
				{showDialog === WorkflowDialogType.Dismantle && (
					<Dialog
						hidden={!showDialog}
						dialogContentProps={dialogContentProps}
						modalProps={{
							isBlocking: true,
							styles: {main: {maxWidth: 450}},
						}}
						onDismiss={onDismiss}
					>
						<SplitDocumentLegend />

						<DialogFooter>
							<ActionButton onClick={onDismantleClick}>
								{t('DismantleButton')}
							</ActionButton>
							<DefaultButton onClick={onDismiss} text={t('CancelButton')} />
						</DialogFooter>
					</Dialog>
				)}
				{showDialog === WorkflowDialogType.QualityControlInternal && (
					<Dialog
						hidden={!showDialog}
						dialogContentProps={dialogContentProps}
						modalProps={{
							isBlocking: true,
							styles: {
								main: {width: 700},
							},
						}}
						onDismiss={onDismiss}
					>
						<DialogFooter>
							<ActionButton onClick={onFinishQualityControlInternalClick}>
								{t('FinishQualityControlButton')}
							</ActionButton>
							<DefaultButton onClick={onDismiss} text={t('CancelButton')} />
						</DialogFooter>
					</Dialog>
				)}
				{showDialog === WorkflowDialogType.QualityControlExternal && (
					<Dialog
						hidden={!showDialog}
						dialogContentProps={dialogContentProps}
						modalProps={{
							isBlocking: true,
							styles: {
								main: {width: 700},
							},
						}}
						onDismiss={onDismiss}
					>
						<DialogFooter>
							<ActionButton onClick={onFinishQualityControlExternalClick}>
								{t('FinishQualityControlButton')}
							</ActionButton>
							<DefaultButton onClick={onDismiss} text={t('CancelButton')} />
						</DialogFooter>
					</Dialog>
				)}
				{showDialog === WorkflowDialogType.ProgressExternal && (
					<Dialog
						hidden={!showDialog}
						dialogContentProps={dialogContentProps}
						modalProps={{
							isBlocking: true,
							styles: {main: {maxWidth: 450}},
						}}
						onDismiss={onDismiss}
					>
						<ReasonField
							onChange={onInputChange}
							required
							multiline
							autoAdjustHeight
							label={t('ReasonLabel')}
						/>
						<DialogFooter>
							<ActionButton
								onClick={onProgressExternalClick}
								disabled={!reason}
							>
								{t('InProgressExternalButton')}
							</ActionButton>
							<DefaultButton onClick={onDismiss} text={t('CancelButton')} />
						</DialogFooter>
					</Dialog>
				)}
				{showDialog === WorkflowDialogType.SendToReapprove && (
					<Dialog
						hidden={!showDialog}
						dialogContentProps={dialogContentProps}
						modalProps={{
							isBlocking: true,
							styles: {
								main: {
									maxWidth: '800px !important',
									width: '800px !important',
								},
							},
						}}
						onDismiss={onDismiss}
					>
						<ReasonField
							onChange={onInputChange}
							required
							multiline
							autoAdjustHeight
							maxLength={488}
							label={t('ReapprovalReasonLabel')}
						/>
						<LabelWithTooltip translationKey='users'>
							{t('ChoosePersonLabel')}
						</LabelWithTooltip>
						<PeoplePicker
							onChange={items => onUserInputChange(items ?? [])}
							itemLimit={1}
							excludeCurrentUser
							roles={[UserRole.Vko]}
						/>
						<DialogFooter>
							<ActionButton
								onClick={onSendToReapprove}
								disabled={users.length < 1 || !reason}
							>
								{t('ClearingButton')}
							</ActionButton>
							<DefaultButton onClick={onDismiss} text={t('CancelButton')} />
						</DialogFooter>
					</Dialog>
				)}
				{showDialog === WorkflowDialogType.Clearing && (
					<Dialog
						hidden={!showDialog}
						dialogContentProps={dialogContentProps}
						modalProps={{
							isBlocking: true,
							styles: {main: {maxWidth: 450}},
						}}
						onDismiss={onDismiss}
					>
						<LabelWithTooltip translationKey='users'>
							{t('ChoosePersonLabel')}
						</LabelWithTooltip>
						<PeoplePicker
							onChange={items => onUserInputChange(items ?? [])}
							itemLimit={1}
							excludeCurrentUser
							roles={[UserRole.Vko]}
						/>
						<DialogFooter>
							<ActionButton
								onClick={onClearingClick}
								disabled={users.length < 1}
							>
								{t('ClearingButton')}
							</ActionButton>
							<DefaultButton onClick={onDismiss} text={t('CancelButton')} />
						</DialogFooter>
					</Dialog>
				)}
				{showDialog === WorkflowDialogType.ClearingOK && (
					<Dialog
						hidden={!showDialog}
						dialogContentProps={dialogContentProps}
						modalProps={{
							isBlocking: true,
							styles: {main: {maxWidth: 450}},
						}}
						onDismiss={onDismiss}
					>
						<DialogFooter>
							<ActionButton onClick={onClearingOKClick}>
								{t('ClearingButton')}
							</ActionButton>
							<DefaultButton onClick={onDismiss} text={t('CancelButton')} />
						</DialogFooter>
					</Dialog>
				)}
				{showDialog === WorkflowDialogType.ReapprovalOK && (
					<Dialog
						hidden={!showDialog}
						dialogContentProps={dialogContentProps}
						modalProps={{
							isBlocking: true,
							styles: {main: {maxWidth: 450}},
						}}
						onDismiss={onDismiss}
					>
						<DialogFooter>
							<ActionButton onClick={onConfirmReapproval}>
								{t('ClearingButton')}
							</ActionButton>
							<DefaultButton onClick={onDismiss} text={t('CancelButton')} />
						</DialogFooter>
					</Dialog>
				)}
				{showDialog === WorkflowDialogType.ReappovalNOK && (
					<Dialog
						hidden={!showDialog}
						dialogContentProps={dialogContentProps}
						modalProps={{
							isBlocking: true,
							styles: {main: {maxWidth: 450}},
						}}
						onDismiss={onDismiss}
					>
						<ReasonField
							onChange={onInputChange}
							required
							multiline
							autoAdjustHeight
							label={t('ReasonLabel')}
						/>
						<DialogFooter>
							<ActionButton onClick={onDeclineReapproval} disabled={!reason}>
								{t('Decline')}
							</ActionButton>
							<DefaultButton onClick={onDismiss} text={t('CancelButton')} />
						</DialogFooter>
					</Dialog>
				)}
				{showDialog === WorkflowDialogType.ClearingWithReservations && (
					<Dialog
						hidden={!showDialog}
						dialogContentProps={dialogContentProps}
						modalProps={{
							isBlocking: true,
							styles: {main: {maxWidth: 450}},
						}}
						onDismiss={onDismiss}
					>
						<DialogFooter>
							<ActionButton onClick={onClearingWithReservationsClick}>
								{t('ClearingButton')}
							</ActionButton>
							<DefaultButton onClick={onDismiss} text={t('CancelButton')} />
						</DialogFooter>
					</Dialog>
				)}
				{showDialog === WorkflowDialogType.ClearingNOK && (
					<Dialog
						hidden={!showDialog}
						dialogContentProps={dialogContentProps}
						modalProps={{
							isBlocking: true,
							styles: {main: {maxWidth: 450}},
						}}
						onDismiss={onDismiss}
					>
						<ReasonField
							onChange={onInputChange}
							required
							multiline
							autoAdjustHeight
							label={t('ReasonLabel')}
						/>
						<DialogFooter>
							<ActionButton onClick={onClearingNOKClick} disabled={!reason}>
								{t('ClearingButton')}
							</ActionButton>
							<DefaultButton onClick={onDismiss} text={t('CancelButton')} />
						</DialogFooter>
					</Dialog>
				)}
			</ProviderThatEnablesGettingTooltipsFromContext>
		</TooltipTranslationProvider>
	);
};
