import {
	IDropdownOption,
	DialogType,
	Dialog,
	Dropdown,
	DialogFooter,
	PrimaryButton,
	DefaultButton,
} from '@fluentui/react';
import {LoadWrapper} from 'components/LoadWrapper';
import React from 'react';
import {useTranslation} from 'react-i18next';

export const ApplyOcrDialog: React.FC<{
	hidden: boolean;
	onDismiss: () => void;
	onConvertImageClick: () => Promise<void>;
	onLanguageChange: (ev: any, langOption?: IDropdownOption) => void;
}> = ({onDismiss, hidden, onConvertImageClick, onLanguageChange}) => {
	const {t} = useTranslation('features/regulatorydocuments', {
		keyPrefix: 'DocumentEditor.EditorContent',
	});

	const [loading, setLoading] = React.useState(false);
	const onSubmitClick = React.useCallback(async () => {
		setLoading(true);
		await onConvertImageClick().finally(() => {
			onDismiss();
			setLoading(false);
		});
	}, [onConvertImageClick]);

	const dialogContentProps = {
		type: DialogType.largeHeader,
		title: t('ApplyOcrHeader'),
		subText: t('ApplyOcrSubText'),
	};
	const modalProps = {
		isBlocking: false,
		styles: {main: {maxWidth: 450}},
	};

	const languageOptions: IDropdownOption[] = React.useMemo(
		() => [
			{
				key: 'eng',
				text: 'Englisch',
			},
			{
				key: 'deu',
				text: 'Deutsch',
			},
		],
		[],
	);

	return (
		<Dialog
			hidden={hidden}
			onDismiss={onDismiss}
			dialogContentProps={dialogContentProps}
			modalProps={modalProps}
		>
			<LoadWrapper loading={loading} text=''>
				<Dropdown
					options={languageOptions}
					onChange={onLanguageChange}
					defaultSelectedKey={'eng'}
				/>
			</LoadWrapper>
			<DialogFooter>
				<PrimaryButton
					onClick={onSubmitClick}
					text={t('ApplyOcrSubmit')}
					disabled={loading}
				/>
				<DefaultButton
					onClick={onDismiss}
					text={t('Cancel')}
					disabled={loading}
				/>
			</DialogFooter>
		</Dialog>
	);
};
