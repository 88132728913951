import React from 'react';
import {EntityListColumn} from 'components';
import {getDateColumn} from 'helpers';
import {TFunction} from 'react-i18next';
import {SetOptional} from 'type-fest';
import {
	RenderFn,
	StatusField,
	renderDate,
	renderPhase,
	renderRegulatoryDocumentStatus,
} from 'components/EntityList/ColumnRenderers';
import enums from '../../../../locales/common/enums.de.json';
import {createSubfieldTranslationKey} from 'features/localizedTooltips';
import {renderKeywordsCell} from 'components/Keywords/Keywords';
import {DATE_TYPE} from 'components/EntityList/EntityUtils';

export const getRegulatoryDocumentsListBaseColumns = (
	t: TFunction,
	tEnum: TFunction,
	showWorkflowStatus = false,
): EntityListColumn[] => {
	type Enums = typeof enums;
	type EnumKey = keyof Enums;

	type EntityListColumnFieldsWithOptionalOnes = SetOptional<
		EntityListColumn,
		'minWidth' | 'key'
	>;

	/**
	 * We shouldn't be able to provide the name because it doesn't make sense to
	 * provide a translation key for the name and provide the name simultaneously.
	 */
	type FieldsFromEntityListColumn = Omit<
		EntityListColumnFieldsWithOptionalOnes,
		'name'
	>;

	interface StatusColumnFields extends FieldsFromEntityListColumn {
		translationKeyForName: string;
		keyWithoutPrefix: string;
		/**
		 * The key of the object with the translations. The object must contain a
		 * translation for each possible field value.
		 */
		fieldPathToTranslationsInEnumTranslationFile: EnumKey;
	}

	const createCommonStatusColumnFields = ({
		translationKeyForName,
		keyWithoutPrefix,
		fieldPathToTranslationsInEnumTranslationFile: enumKey,
		...other
	}: StatusColumnFields): EntityListColumn => {
		return {
			key: `rd-${keyWithoutPrefix}`,
			name: t(translationKeyForName),
			filterable: true,
			getFilterLabel: value => tEnum(`${enumKey}.${value}`),
			isMultiline: true,
			minWidth: 150,
			maxWidth: 150,
			isResizable: true,
			sortable: true,
			...other,
		};
	};

	const workflowStatusEnumKey = 'WorkflowStatus';

	const renderWorkflowStatus: RenderFn = (item, index, column) => {
		return (
			<StatusField
				item={item}
				i={index}
				col={column}
				value={item?.workflow?.status}
				enumName={workflowStatusEnumKey}
			/>
		);
	};

	const createWorkflowStatusColumn = (): EntityListColumn => {
		return createCommonStatusColumnFields({
			translationKeyForName: 'WorkflowStatus',
			keyWithoutPrefix: 'workflowStatus',
			fieldPathToTranslationsInEnumTranslationFile: workflowStatusEnumKey,
			tooltipHostProps: {
				translationKey: createSubfieldTranslationKey('workflow.status'),
			},
			onRender: renderWorkflowStatus,
		});
	};

	const getColumnsBeforeWorkflowStatus = (): EntityListColumn[] => {
		return [
			{
				key: 'rd-name',
				name: t('Version'),
				fieldName: 'name',
				isMultiline: true,
				minWidth: 150,
				maxWidth: 150,
				isResizable: true,
				sortable: true,
			},
			{
				key: 'rd-modifiedAt',
				name: t('ModifiedAt'),
				fieldName: 'modifiedAt',
				filterable: true,
				isMultiline: false,
				minWidth: 100,
				maxWidth: 100,
				isResizable: true,
				onRender: renderDate(),
				dataType: DATE_TYPE,
				sortable: true,
			},
			createCommonStatusColumnFields({
				translationKeyForName: 'Status',
				keyWithoutPrefix: 'status',
				fieldPathToTranslationsInEnumTranslationFile:
					'RegulatoryDocumentStatus',
				fieldName: 'status',
				onRender: renderRegulatoryDocumentStatus(),
			}),
		];
	};

	const getInitialColumnsWithWorkflowStatus = (
		initialColumns: EntityListColumn[],
	): EntityListColumn[] => {
		const workflowStatusColumn: EntityListColumn = createWorkflowStatusColumn();
		return [...initialColumns, workflowStatusColumn];
	};

	const getColumnsUpToAndPossiblyIncludingWorkflowStatus =
		(): EntityListColumn[] => {
			const initialColumns: EntityListColumn[] =
				getColumnsBeforeWorkflowStatus();
			if (showWorkflowStatus)
				return getInitialColumnsWithWorkflowStatus(initialColumns);
			return initialColumns;
		};

	const initialColumnsWithPossibleWorkflowStatusColumn: EntityListColumn[] =
		getColumnsUpToAndPossiblyIncludingWorkflowStatus();

	return [
		...initialColumnsWithPossibleWorkflowStatusColumn,
		{
			key: 'rd-keywords',
			name: t('Keywords'),
			fieldName: 'keywords',
			minWidth: 300,
			maxWidth: 300,
			isResizable: true,
			filterable: true,
			onRender: renderKeywordsCell,
		},
		{
			key: 'rd-modelYear',
			name: t('ModelYear'),
			fieldName: 'modelYear',
			minWidth: 100,
			isResizable: true,
			filterable: true,
			sortable: true,
		},
		getDateColumn(
			'p-dateNewRegistration',
			t('DateNewRegistration'),
			'dateNewRegistration',
			true,
		),
		getDateColumn('p-dateNewTypes', t('DateNewTypes'), 'dateNewTypes', true),
		getDateColumn('p-dateEffective', t('DateEffective'), 'dateEffective', true),
		getDateColumn(
			'p-dateExpiration',
			t('DateExpiration'),
			'dateExpiration',
			true,
		),
		{
			key: 'rd-phaseIn',
			name: t('PhaseIn'),
			fieldName: 'phaseIn',
			minWidth: 200,
			maxWidth: 200,
			isResizable: true,
			onRender: renderPhase(),
		},
		{
			key: 'rd-phaseOut',
			name: t('PhaseOut'),
			fieldName: 'phaseOut',
			minWidth: 200,
			maxWidth: 200,
			isResizable: true,
			onRender: renderPhase(),
		},
	];
};
