import React, {useCallback, useMemo} from 'react';
import {
	CommandBarButton,
	IButtonStyles,
	IIconProps,
	TooltipHost,
} from '@fluentui/react';
import {useRegDocDetailsPageTranslations} from './RegDocDetailsPage.commonHooks';
import {IUseBooleanCallbacks} from '@fluentui/react-hooks';
import {RefToContainerOfMainParagraphs} from './RegDocDetailsPage.types';
import {DocOfRegDocDetailsPageQuery} from './RegDocDetailsPage.queryTypes';
import {RegDocOfRegDocsFormData} from '../components/DocumentDetails/CompareVersionForm.types';
import {ParagraphsContextProps} from '../components/DocumentDetails/ParagraphsContext';
import {paragraphVisibilityService} from './ParagraphVisibility.service';

interface Props {
	viewPdf: boolean;
	togglePdf: IUseBooleanCallbacks['toggle'];
	sideBySide: RegDocOfRegDocsFormData | undefined;
	doc: DocOfRegDocDetailsPageQuery['document'];
	refToContainerOfMainParagraphs: RefToContainerOfMainParagraphs;
	/**
	 * Note that this component cannot get this from the Paragraphs Context
	 * because this component doesn't have access to it. This is because it gets
	 * rendered by the command bar, which is outside of the Paragraph Context
	 * Provider's children.
	 */
	setIndexToScrollTo: ParagraphsContextProps['setIndexToScrollTo'];
}

export function ViewPdfButton({
	viewPdf,
	togglePdf,
	sideBySide,
	doc,
	refToContainerOfMainParagraphs,
	setIndexToScrollTo,
}: Props) {
	const {t} = useRegDocDetailsPageTranslations();

	const styles: IButtonStyles = useMemo(() => {
		return {root: {height: '100%'}};
	}, []);

	const iconProps: IIconProps = useMemo(() => {
		return {iconName: viewPdf ? 'Cancel' : 'PDF'};
	}, [viewPdf]);

	const isPdf: boolean = doc?.contentType === 'application/pdf';

	const getTooltipContent = (): string | undefined => {
		if (isPdf) return undefined;
		return t('MsgWhenPdfButtonDisabled');
	};

	const handleClick = useCallback((): void => {
		/**
		 * So we maintain the scroll position when switching to the PDF viewer
		 */
		const setVisibleParagraphAsThe1ToScrollTo = (): void => {
			const index: number =
				paragraphVisibilityService.findIndexOfVisibleParagraph(
					refToContainerOfMainParagraphs,
				);
			if (index === -1) return;
			/**
			 * At first glance, it might appear that this line might cause the main
			 * paragraphs list to scroll to a specific paragraph. However, this only
			 * affects the PDF viewer's Paragraphs List because it uses a timeout
			 * before it begins scrolling, which gives the main paragraphs' list time
			 * to unmount. So, only the PDF Viewer's paragraphs' list will handle this
			 * state update.
			 */
			setIndexToScrollTo(index);
		};

		const willViewPdf = !viewPdf;

		/**
		 * We must do this before we toggle the PDF. Otherwise, it won't be able to
		 * find the visible paragraph before we switch to the PDF viewer.
		 */
		if (willViewPdf) setVisibleParagraphAsThe1ToScrollTo();
		togglePdf();
	}, [refToContainerOfMainParagraphs, setIndexToScrollTo, togglePdf, viewPdf]);

	return (
		<TooltipHost content={getTooltipContent()}>
			<CommandBarButton
				text={t(viewPdf ? 'HidePdf' : 'ViewPdf')}
				styles={styles}
				iconProps={iconProps}
				onClick={handleClick}
				disabled={Boolean(sideBySide) || !isPdf}
			/>
		</TooltipHost>
	);
}
