import * as Types from '../types/types.generated';

import { gql } from '@apollo/client';
export type FieldsToSelectRegDocInDocFormFragment = { __typename?: 'RegulatoryDocument', id: string, name: string, workflow: { __typename?: 'Workflow', status: Types.WorkflowStatus } };

export const FieldsToSelectRegDocInDocFormFragmentDoc = gql`
    fragment FieldsToSelectRegDocInDocForm on RegulatoryDocument {
  id
  name
  workflow {
    status
  }
}
    `;