import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import { UserFieldsFragmentDoc } from '../../../fragments/userFields.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetArchivedRegulatoryDocumentsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetArchivedRegulatoryDocumentsQuery = { __typename?: 'Query', regulatoryDocuments?: { __typename?: 'RegulatoryDocumentsConnection', edges?: Array<{ __typename?: 'RegulatoryDocumentsEdge', node: { __typename?: 'RegulatoryDocument', id: string, name: string, status: Types.RegulatoryDocumentStatus, summary?: string | null, createdAt?: any | null, modifiedAt?: any | null, createdBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null }, modifiedBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null }, workflow: { __typename?: 'Workflow', status: Types.WorkflowStatus, message?: string | null }, document?: { __typename?: 'BlobRef', uri: any, contentType: string, fileName: string } | null } }> | null } | null };


export const GetArchivedRegulatoryDocumentsDocument = gql`
    query GetArchivedRegulatoryDocuments {
  regulatoryDocuments(where: {workflow: {status: {eq: ARCHIVED}}}) {
    edges {
      node {
        id
        name
        status
        summary
        createdAt
        createdBy {
          ...UserFields
        }
        modifiedAt
        modifiedBy {
          ...UserFields
        }
        workflow {
          status
          message
        }
        document {
          uri
          contentType
          fileName
        }
      }
    }
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useGetArchivedRegulatoryDocumentsQuery__
 *
 * To run a query within a React component, call `useGetArchivedRegulatoryDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArchivedRegulatoryDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArchivedRegulatoryDocumentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetArchivedRegulatoryDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<GetArchivedRegulatoryDocumentsQuery, GetArchivedRegulatoryDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArchivedRegulatoryDocumentsQuery, GetArchivedRegulatoryDocumentsQueryVariables>(GetArchivedRegulatoryDocumentsDocument, options);
      }
export function useGetArchivedRegulatoryDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArchivedRegulatoryDocumentsQuery, GetArchivedRegulatoryDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArchivedRegulatoryDocumentsQuery, GetArchivedRegulatoryDocumentsQueryVariables>(GetArchivedRegulatoryDocumentsDocument, options);
        }
export type GetArchivedRegulatoryDocumentsQueryHookResult = ReturnType<typeof useGetArchivedRegulatoryDocumentsQuery>;
export type GetArchivedRegulatoryDocumentsLazyQueryHookResult = ReturnType<typeof useGetArchivedRegulatoryDocumentsLazyQuery>;
export type GetArchivedRegulatoryDocumentsQueryResult = Apollo.QueryResult<GetArchivedRegulatoryDocumentsQuery, GetArchivedRegulatoryDocumentsQueryVariables>;