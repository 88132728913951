import {UseTranslationOptions, useTranslation} from 'react-i18next';

export const TOOLTIPS_TEXT_KEY = 'TooltipsText';

export const createPathWithTooltipsTextKey = (subPath: string): string => {
	return `${TOOLTIPS_TEXT_KEY}.${subPath}`;
};

export type TooltipTranslationOptions = UseTranslationOptions<string>;

export const useTooltipTranslation = (
	namespace: string,
	options?: TooltipTranslationOptions,
) => {
	return useTranslation(namespace, {
		keyPrefix: TOOLTIPS_TEXT_KEY,
		...options,
	});
};
