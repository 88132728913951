import React, {FC} from 'react';
import {Controller} from 'react-hook-form';
import {IToggleProps, Toggle} from '@fluentui/react';
import {HookFormProps} from '../HookFormProps';
import {ControlledToggleLabelWithTooltip} from './ControlledToggleLabel';

type ControlledToggleProps = Omit<IToggleProps, 'defaultValue'> & HookFormProps;

export const ControlledToggle: FC<ControlledToggleProps> = props => {
	return (
		<Controller
			name={props.name}
			control={props.control}
			rules={props.rules}
			defaultValue={props.defaultValue || false}
			render={({field: {onChange, onBlur, value}}) => (
				<Toggle
					{...props}
					onChange={(e, val) => {
						onChange(Boolean(val));
						props.onChange?.(e, val);
					}}
					checked={value || false}
					onBlur={onBlur}
					label={
						<ControlledToggleLabelWithTooltip
							name={props.name}
							label={props.label}
						/>
					}
				/>
			)}
		/>
	);
};
