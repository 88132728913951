import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateRequirementMutationVariables = Types.Exact<{
  input: Types.CreateRequirementInput;
}>;


export type CreateRequirementMutation = { __typename?: 'Mutation', createRequirement: { __typename?: 'RequirementPayload', requirement: { __typename?: 'Requirement', id: string } } };


export const CreateRequirementDocument = gql`
    mutation CreateRequirement($input: CreateRequirementInput!) {
  createRequirement(input: $input) {
    requirement {
      id
    }
  }
}
    `;
export type CreateRequirementMutationFn = Apollo.MutationFunction<CreateRequirementMutation, CreateRequirementMutationVariables>;

/**
 * __useCreateRequirementMutation__
 *
 * To run a mutation, you first call `useCreateRequirementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRequirementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRequirementMutation, { data, loading, error }] = useCreateRequirementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRequirementMutation(baseOptions?: Apollo.MutationHookOptions<CreateRequirementMutation, CreateRequirementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRequirementMutation, CreateRequirementMutationVariables>(CreateRequirementDocument, options);
      }
export type CreateRequirementMutationHookResult = ReturnType<typeof useCreateRequirementMutation>;
export type CreateRequirementMutationResult = Apollo.MutationResult<CreateRequirementMutation>;
export type CreateRequirementMutationOptions = Apollo.BaseMutationOptions<CreateRequirementMutation, CreateRequirementMutationVariables>;