import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateVexClusterAssignmentMutationVariables = Types.Exact<{
  input: Types.UpdateVexClusterAssignmentInput;
}>;


export type UpdateVexClusterAssignmentMutation = { __typename?: 'Mutation', updateVexClusterAssignment: { __typename?: 'UpdateVexClusterAssignmentPayload', regulatoryDocument?: { __typename?: 'RegulatoryDocument', id: string } | null } };

export type UpdateVexClusterAssignmentBatchMutationVariables = Types.Exact<{
  input: Types.UpdateVexClusterAssignmentBatchInput;
}>;


export type UpdateVexClusterAssignmentBatchMutation = { __typename?: 'Mutation', updateVexClusterAssignmentBatch: { __typename?: 'UpdateVexClusterAssignmentBatchPayload', regulatoryDocument?: { __typename?: 'RegulatoryDocument', id: string } | null } };


export const UpdateVexClusterAssignmentDocument = gql`
    mutation UpdateVexClusterAssignment($input: UpdateVexClusterAssignmentInput!) {
  updateVexClusterAssignment(input: $input) {
    regulatoryDocument {
      id
    }
  }
}
    `;
export type UpdateVexClusterAssignmentMutationFn = Apollo.MutationFunction<UpdateVexClusterAssignmentMutation, UpdateVexClusterAssignmentMutationVariables>;

/**
 * __useUpdateVexClusterAssignmentMutation__
 *
 * To run a mutation, you first call `useUpdateVexClusterAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVexClusterAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVexClusterAssignmentMutation, { data, loading, error }] = useUpdateVexClusterAssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVexClusterAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVexClusterAssignmentMutation, UpdateVexClusterAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVexClusterAssignmentMutation, UpdateVexClusterAssignmentMutationVariables>(UpdateVexClusterAssignmentDocument, options);
      }
export type UpdateVexClusterAssignmentMutationHookResult = ReturnType<typeof useUpdateVexClusterAssignmentMutation>;
export type UpdateVexClusterAssignmentMutationResult = Apollo.MutationResult<UpdateVexClusterAssignmentMutation>;
export type UpdateVexClusterAssignmentMutationOptions = Apollo.BaseMutationOptions<UpdateVexClusterAssignmentMutation, UpdateVexClusterAssignmentMutationVariables>;
export const UpdateVexClusterAssignmentBatchDocument = gql`
    mutation UpdateVexClusterAssignmentBatch($input: UpdateVexClusterAssignmentBatchInput!) {
  updateVexClusterAssignmentBatch(input: $input) {
    regulatoryDocument {
      id
    }
  }
}
    `;
export type UpdateVexClusterAssignmentBatchMutationFn = Apollo.MutationFunction<UpdateVexClusterAssignmentBatchMutation, UpdateVexClusterAssignmentBatchMutationVariables>;

/**
 * __useUpdateVexClusterAssignmentBatchMutation__
 *
 * To run a mutation, you first call `useUpdateVexClusterAssignmentBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVexClusterAssignmentBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVexClusterAssignmentBatchMutation, { data, loading, error }] = useUpdateVexClusterAssignmentBatchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVexClusterAssignmentBatchMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVexClusterAssignmentBatchMutation, UpdateVexClusterAssignmentBatchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVexClusterAssignmentBatchMutation, UpdateVexClusterAssignmentBatchMutationVariables>(UpdateVexClusterAssignmentBatchDocument, options);
      }
export type UpdateVexClusterAssignmentBatchMutationHookResult = ReturnType<typeof useUpdateVexClusterAssignmentBatchMutation>;
export type UpdateVexClusterAssignmentBatchMutationResult = Apollo.MutationResult<UpdateVexClusterAssignmentBatchMutation>;
export type UpdateVexClusterAssignmentBatchMutationOptions = Apollo.BaseMutationOptions<UpdateVexClusterAssignmentBatchMutation, UpdateVexClusterAssignmentBatchMutationVariables>;