import React from 'react';
import {
	ActiveTypeMarketsPageActiveTypeMarket as Market,
	ActiveTypeMarketsPageParams,
} from '../activeTypeMarketsPageTypes';
import {
	IRenderFunction,
	IDetailsColumnFieldProps,
	Theme,
	useTheme,
} from '@fluentui/react';
import {
	NavigateToActiveTypeMarketDetails,
	useNavigateToActiveTypeMarketDetailsPage,
} from './activeTypeMarketsList.hooks';
import {CellLink} from 'components/EntityList/CellLink';

export interface ActiveTypeMarketItemProps {
	params: ActiveTypeMarketsPageParams;
	defaultRender: IRenderFunction<IDetailsColumnFieldProps> | undefined;
	originalComponentProps: IDetailsColumnFieldProps | undefined;
}

export const ActiveTypeMarketCell = ({
	params,
	originalComponentProps,
	defaultRender,
}: ActiveTypeMarketItemProps) => {
	const navigateToDetailsPage: NavigateToActiveTypeMarketDetails =
		useNavigateToActiveTypeMarketDetailsPage(params);
	const theme: Theme = useTheme();

	const navigateToDetailsPageIfPossible = (): void => {
		const activeTypeMarket: Market | undefined = originalComponentProps?.item;
		if (!activeTypeMarket) return;
		navigateToDetailsPage(activeTypeMarket);
	};

	const cell: JSX.Element | null | undefined = defaultRender?.(
		originalComponentProps,
	);

	return (
		<CellLink
			onClick={navigateToDetailsPageIfPossible}
			styles={{
				root: {color: theme.semanticColors.listText},
			}}
		>
			{cell}
		</CellLink>
	);
};
